import { Image, Row, Col } from "antd";
import { FC } from "react";
import { connect } from "react-redux";
import { CompanyInfo } from "../../../constants/Constants";
import MikadaLogo from "../../../Images/mikada_logo.png";
const PrintHeaderComponent: FC<{
  type: string;
  pageNo: string;
  documentNo: string;
}> = ({ type, pageNo, documentNo }) => {
  return (
    <>
      <Row>
        <Col span={4} className="border border-dark justify-content-center">
          <Image
            src={MikadaLogo}
            height={100}
            width="190px"
            preview={false}
            className=""
          />
        </Col>
        <Col span={14} className=" border border-dark py-2">
          <div className="d-flex justify-content-center">
            <h3>{CompanyInfo.amh_name}</h3>
          </div>
          <div className="d-flex justify-content-center">
            <h4>{CompanyInfo.name}</h4>
          </div>
        </Col>
        <Col span={6} className="border border-dark">
          <h6 className="d-flex justify-content-center mt-4">Document No.</h6>
          <h5 className="d-flex justify-content-center">
            <b>OF/MIKADA/ECD/{documentNo}</b>
          </h5>
        </Col>
      </Row>
      <Row>
        <Col
          span={18}
          className="d-flex justify-content-center border border-dark py-2"
        >
          <h3>{type}</h3>
        </Col>
        <div className="col border border-dark">
          <h6 className="d-flex justify-content-center">Issue No:</h6>
          <h6 className="d-flex justify-content-center">1</h6>
        </div>
        <div className="col border border-dark">
          <h6 className="d-flex justify-content-center">Page No:</h6>
          <h6 className="d-flex justify-content-center">Page {pageNo}</h6>
        </div>
      </Row>
    </>
  );
};

/**
 * Map State to Props
 *
 * @param state
 */
const mapStateToProps = (state: any) => ({});

/**
 * Map Dispatch to Props
 *
 * @param dispatch
 */
const mapDispatchToProps = (dispatch: any) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrintHeaderComponent);
