import { ApiCallState } from "../Utils";

export type MikadaWeeklyEquipmentReport= {
  id: number;
  date: string;
  remark: string;
  equipment_id: number;
  user_id: number;
};

export type MikadaWeeklyEquipmentReportStateTypes = {
  fetchAll: ApiCallState<MikadaWeeklyEquipmentReport[]>;
  fetchOne: ApiCallState<MikadaWeeklyEquipmentReport | {}>;
};

export const MikadaWeeklyEquipmentReportActionTypes = {
  FETCH_ALL_MIKADA_WEEKLY_EQUIPMENT_REPORT: "FETCH_ALL_MIKADA_WEEKLY_EQUIPMENT_REPORT",
  FETCH_ALL_MIKADA_WEEKLY_EQUIPMENT_REPORT_RESET: "FETCH_ALL_MIKADA_WEEKLY_EQUIPMENT_REPORT_RESET",
  FETCH_ALL_MIKADA_WEEKLY_EQUIPMENT_REPORT_FAILURE: "FETCH_ALL_MIKADA_WEEKLY_EQUIPMENT_REPORT_FAILURE",
  FETCH_ALL_MIKADA_WEEKLY_EQUIPMENT_REPORT_SUCCESS: "FETCH_ALL_MIKADA_WEEKLY_EQUIPMENT_REPORT_SUCCESS",

  FETCH_ONE_MIKADA_WEEKLY_EQUIPMENT_REPORT: "FETCH_ONE_MIKADA_WEEKLY_EQUIPMENT_REPORT",
  FETCH_ONE_MIKADA_WEEKLY_EQUIPMENT_REPORT_RESET: "FETCH_ONE_MIKADA_WEEKLY_EQUIPMENT_REPORT_RESET",
  FETCH_ONE_MIKADA_WEEKLY_EQUIPMENT_REPORT_FAILURE: "FETCH_ONE_MIKADA_WEEKLY_EQUIPMENT_REPORT_FAILURE",
  FETCH_ONE_MIKADA_WEEKLY_EQUIPMENT_REPORT_SUCCESS: "FETCH_ONE_MIKADA_WEEKLY_EQUIPMENT_REPORT_SUCCESS",
};
