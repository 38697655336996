import { ApiCallState } from "../Utils";

export type PlaceOfWork = {
  id: number;
  user_id: number;

  place_of_work: string;
  location:string;
  in_amharic:string;
  location_in_amharic:string;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type PlaceOfWorkStateTypes = {
  fetchAll: ApiCallState<PlaceOfWork[]>;
  fetchOne: ApiCallState<PlaceOfWork | {}>;
};

export const PlaceOfWorkActionTypes = {
  FETCH_ALL_PLACE_OF_WORK: "FETCH_ALL_PLACE_OF_WORK",
  FETCH_ALL_PLACE_OF_WORK_RESET: "FETCH_ALL_PLACE_OF_WORK_RESET",
  FETCH_ALL_PLACE_OF_WORK_FAILURE: "FETCH_ALL_PLACE_OF_WORK_FAILURE",
  FETCH_ALL_PLACE_OF_WORK_SUCCESS: "FETCH_ALL_PLACE_OF_WORK_SUCCESS",

  FETCH_ONE_PLACE_OF_WORK: "FETCH_ONE_PLACE_OF_WORK",
  FETCH_ONE_PLACE_OF_WORK_RESET: "FETCH_ONE_PLACE_OF_WORK_RESET",
  FETCH_ONE_PLACE_OF_WORK_FAILURE: "FETCH_ONE_PLACE_OF_WORK_FAILURE",
  FETCH_ONE_PLACE_OF_WORK_SUCCESS: "FETCH_ONE_PLACE_OF_WORK_SUCCESS",
};
