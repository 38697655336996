import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SupplementaryBoqActionTypes } from "./SupplementaryBoq.type";

export function* fetchAllSupplementaryBoqs(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/supplementary-boq?project_id=${action.payload.project_id}`);
    yield put({
      type: SupplementaryBoqActionTypes.FETCH_ALL_SUPPLEMENTARY_BOQ_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SupplementaryBoqActionTypes.FETCH_ALL_SUPPLEMENTARY_BOQ_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSupplementaryBoqs(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/supplementary-boq/${action.payload}`
    );
    yield put({
      type: SupplementaryBoqActionTypes.FETCH_ONE_SUPPLEMENTARY_BOQ_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SupplementaryBoqActionTypes.FETCH_ONE_SUPPLEMENTARY_BOQ_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSupplementaryBoqs() {
  yield takeLatest(SupplementaryBoqActionTypes.FETCH_ALL_SUPPLEMENTARY_BOQ, fetchAllSupplementaryBoqs);
}

export function* watcherFetchOneSupplementaryBoqs() {
  yield takeLatest(SupplementaryBoqActionTypes.FETCH_ONE_SUPPLEMENTARY_BOQ, fetchOneSupplementaryBoqs);
}
