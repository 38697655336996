import { ApiCallState } from "../Utils";

export type StaffLanguage = {
  id: number;

  user_id: number;
  staff_id: number;

  language: string;

  listening: string;
  speaking: string;
  reading: string;
  writing: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type StaffLanguageStateTypes = {
  fetchAll: ApiCallState<StaffLanguage[]>;
};

export const StaffLanguageActionTypes = {
  FETCH_ALL_STAFF_LANGUAGE: "FETCH_ALL_STAFF_LANGUAGE",
  FETCH_ALL_STAFF_LANGUAGE_RESET: "FETCH_ALL_STAFF_LANGUAGE_RESET",
  FETCH_ALL_STAFF_LANGUAGE_FAILURE: "FETCH_ALL_STAFF_LANGUAGE_FAILURE",
  FETCH_ALL_STAFF_LANGUAGE_SUCCESS: "FETCH_ALL_STAFF_LANGUAGE_SUCCESS",
};
