import { GoodIssue } from "../GoodIssue/GoodIssue.type";
import { Material } from "../Material/Material.type";
import { Store } from "../Store/Store.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type GoodReturn = {
  id: number;
  date: string;
  good_issue_id: number;
  project_id: number;
  good_issue: GoodIssue;
  store_id: number;
  // department_id: number;
  gr_to: Store;
  gr_from: Store;
  // gr_department: Store;
  user_id: number;
  reason: string;
  good_return_items: GoodReturnItem[];
  gr_prepared_by?: User;
  gr_approved_by?: User;
  gr_checked_by?: User;
  is_done: boolean;
  good_return_statuses: GoodReturnStatus[];
  status: GoodReturnStatus[];
  key?: number;
  received_by: string;
  approved_by: string;
  pad_ref: string;
  updatedAt: string;
  is_void: boolean;
};

export type GoodReturnReport = {
  approved_by?: any;
  code: string;
  date: string;
  description: string;
  from: string;
  to: string;
  item_category: string;
  id: number;
  major_category: string;
  material_requisition_id: string;
  pad_ref?: any;
  prepared_by: string;
  quantity: number;
  received_by?: any;
  remark: string;
  total: number;
  unit: string;
};

export type GoodReturnStatus = {
  good_return_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  remark: string;
  id: number;
};

export type GoodReturnItem = {
  good_out_id: number;
  material: Material;
  unit: string;
  material_id: number;
  quantity: number;
  unit_price: number;
  remark: string;
  id?: number;
};

export type GoodReturnStateTypes = {
  fetchAll: ApiCallState<GoodReturn[]>;
  fetchOne: ApiCallState<GoodReturn | null>;
  fetchReport: ApiCallState<GoodReturnReport[]>;
};

export const GoodReturnActionTypes = {
  FETCH_ALL_GOOD_RETURN: "FETCH_ALL_GOOD_RETURN",
  FETCH_ALL_GOOD_RETURN_RESET: "FETCH_ALL_GOOD_RETURN_RESET",
  FETCH_ALL_GOOD_RETURN_FAILURE: "FETCH_ALL_GOOD_RETURN_FAILURE",
  FETCH_ALL_GOOD_RETURN_SUCCESS: "FETCH_ALL_GOOD_RETURN_SUCCESS",

  FETCH_ONE_GOOD_RETURN: "FETCH_ONE_GOOD_RETURN",
  FETCH_ONE_GOOD_RETURN_RESET: "FETCH_ONE_GOOD_RETURN_RESET",
  FETCH_ONE_GOOD_RETURN_FAILURE: "FETCH_ONE_GOOD_RETURN_FAILURE",
  FETCH_ONE_GOOD_RETURN_SUCCESS: "FETCH_ONE_GOOD_RETURN_SUCCESS",

  FETCH_REPORT_GOOD_RETURN: "FETCH_REPORT_GOOD_RETURN",
  FETCH_REPORT_GOOD_RETURN_RESET: "FETCH_REPORT_GOOD_RETURN_RESET",
  FETCH_REPORT_GOOD_RETURN_FAILURE: "FETCH_REPORT_GOOD_RETURN_FAILURE",
  FETCH_REPORT_GOOD_RETURN_SUCCESS: "FETCH_REPORT_GOOD_RETURN_SUCCESS",
};
