import { ApiCallState } from "../Utils";

export type SurveyingEquipmentCost = {
  id: number;
  start_date: string;
  end_date: string;
  project_id: number;
  user_id: number;

  surveying_equipment_cost_items: SurveyingEquipmentCostItem[];
  surveying_equipment_cost_others: SurveyingEquipmentCostOther[];
};

export type SurveyingEquipmentCostItem = {
  id: number;
  description: string;
  unit: string;
  quantity: number;
  delivery_date: string;
  purchasing_per_unit: number;
  previous_cost: number;
  this_period_cost: number;
  to_date_cost: number;
  surverying_equipment_cost_id: number;
};

export type SurveyingEquipmentCostOther = {
  id: number;
  description: string;
  unit: string;
  unit_price: number;
  previous_quantity: number;
  previous_amount: number;
  this_period_quantity: number;
  this_period_amount: number;
  up_to_date_quantity: number;
  up_to_date_amount: number;
  surverying_equipment_cost_id: number;
};

export type SurveyingEquipmentCostStateTypes = {
  fetchAll: ApiCallState<SurveyingEquipmentCost[]>;
  fetchOne: ApiCallState<SurveyingEquipmentCost | {}>;
};

export const SurveyingEquipmentCostActionTypes = {
  FETCH_ALL_SURVEYING_EQUIPMENT_COST: "FETCH_ALL_SURVEYING_EQUIPMENT_COST",
  FETCH_ALL_SURVEYING_EQUIPMENT_COST_RESET:
    "FETCH_ALL_SURVEYING_EQUIPMENT_COST_RESET",
  FETCH_ALL_SURVEYING_EQUIPMENT_COST_FAILURE:
    "FETCH_ALL_SURVEYING_EQUIPMENT_COST_FAILURE",
  FETCH_ALL_SURVEYING_EQUIPMENT_COST_SUCCESS:
    "FETCH_ALL_SURVEYING_EQUIPMENT_COST_SUCCESS",

  FETCH_ONE_SURVEYING_EQUIPMENT_COST: "FETCH_ONE_SURVEYING_EQUIPMENT_COST",
  FETCH_ONE_SURVEYING_EQUIPMENT_COST_RESET:
    "FETCH_ONE_SURVEYING_EQUIPMENT_COST_RESET",
  FETCH_ONE_SURVEYING_EQUIPMENT_COST_FAILURE:
    "FETCH_ONE_SURVEYING_EQUIPMENT_COST_FAILURE",
  FETCH_ONE_SURVEYING_EQUIPMENT_COST_SUCCESS:
    "FETCH_ONE_SURVEYING_EQUIPMENT_COST_SUCCESS",
};
