import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ProjectListActionTypes } from "./ProjectList.type";

export function* fetchAllProjectLists(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/project-list`);
    yield put({
      type: ProjectListActionTypes.FETCH_ALL_PROJECT_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectListActionTypes.FETCH_ALL_PROJECT_LIST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneProjectLists(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/project-list/${action.payload}`
    );
    yield put({
      type: ProjectListActionTypes.FETCH_ONE_PROJECT_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectListActionTypes.FETCH_ONE_PROJECT_LIST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllProjectLists() {
  yield takeLatest(ProjectListActionTypes.FETCH_ALL_PROJECT_LIST, fetchAllProjectLists);
}

export function* watcherFetchOneProjectLists() {
  yield takeLatest(ProjectListActionTypes.FETCH_ONE_PROJECT_LIST, fetchOneProjectLists);
}
