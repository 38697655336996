import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PartnerActionTypes } from "./Partner.type";

export function* fetchAllPartners(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/partner`);
    yield put({
      type: PartnerActionTypes.FETCH_ALL_PARTNER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PartnerActionTypes.FETCH_ALL_PARTNER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePartners(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/partner/${action.payload}`
    );
    yield put({
      type: PartnerActionTypes.FETCH_ONE_PARTNER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PartnerActionTypes.FETCH_ONE_PARTNER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPartners() {
  yield takeLatest(PartnerActionTypes.FETCH_ALL_PARTNER, fetchAllPartners);
}

export function* watcherFetchOnePartners() {
  yield takeLatest(PartnerActionTypes.FETCH_ONE_PARTNER, fetchOnePartners);
}
