import {
  StaffLanguageStateTypes,
  StaffLanguageActionTypes,
} from "./StaffLanguage.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: StaffLanguageStateTypes = {
  fetchAll: resetApiCallState([]),
};

const StaffLanguageReducer = (
  state: StaffLanguageStateTypes = INITIAL_STATE,
  action: any
): StaffLanguageStateTypes => {
  switch (action.type) {
    case StaffLanguageActionTypes.FETCH_ALL_STAFF_LANGUAGE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case StaffLanguageActionTypes.FETCH_ALL_STAFF_LANGUAGE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case StaffLanguageActionTypes.FETCH_ALL_STAFF_LANGUAGE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case StaffLanguageActionTypes.FETCH_ALL_STAFF_LANGUAGE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default StaffLanguageReducer;
