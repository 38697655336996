import { MasterList } from "../MasterList/MasterList.type";
import { ApiCallState } from "../Utils";

export type ServiceVoucher = {
  id: number;
  user_id: number;
  master_list_id: number;

  serviced_on: number;
  serviced_on_date: string;
  serviced_type_of_maintenance: string;

  next_service: number;
  next_service_date: string;
  next_service_type_of_maintenance: string;

  serviced_by: string;

  master_list: MasterList;
};

export type ServiceVoucherStateTypes = {
  fetchAll: ApiCallState<ServiceVoucher[]>;
  fetchOne: ApiCallState<ServiceVoucher | {}>;
};

export const ServiceVoucherActionTypes = {
  FETCH_ALL_SERVICE_VOUCHER: "FETCH_ALL_SERVICE_VOUCHER",
  FETCH_ALL_SERVICE_VOUCHER_RESET: "FETCH_ALL_SERVICE_VOUCHER_RESET",
  FETCH_ALL_SERVICE_VOUCHER_FAILURE: "FETCH_ALL_SERVICE_VOUCHER_FAILURE",
  FETCH_ALL_SERVICE_VOUCHER_SUCCESS: "FETCH_ALL_SERVICE_VOUCHER_SUCCESS",

  FETCH_ONE_SERVICE_VOUCHER: "FETCH_ONE_SERVICE_VOUCHER",
  FETCH_ONE_SERVICE_VOUCHER_RESET: "FETCH_ONE_SERVICE_VOUCHER_RESET",
  FETCH_ONE_SERVICE_VOUCHER_FAILURE: "FETCH_ONE_SERVICE_VOUCHER_FAILURE",
  FETCH_ONE_SERVICE_VOUCHER_SUCCESS: "FETCH_ONE_SERVICE_VOUCHER_SUCCESS",
};
