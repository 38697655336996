import _ from "lodash";
import { Units } from "../../constants/Constants";

class BuildingBudget {
  data: any[];
  sheet_name: string;
  type: any;
  parsed: any[];

  constructor(data: any, sheet_name: string) {
    this.data = data;
    this.type = "";
    this.parsed = [];
    this.sheet_name = sheet_name;
  }

  parseBoq() {
    let start = -1;
    this.data.forEach((col: any[], index) => {
      if (this.isTableState(col)) start = index;
      else if (start !== -1) {
        if (col[0] || col[1]) {
          this.parsed.push({
            budget_code:col[0],
            description: col[1],
            july: col[2],
            august:col[3],
            september:col[4],
            october:col[5],
            november:col[6],
            december:col[7],
            january:col[8],
            february:col[9],
            march:col[10],
            may:col[11],
            june:col[12],
            total:col[13],
            key: this.parsed.length,
          });
        }
      }
    });
    return this.parsed;
  }

  private isTableState(col: any[]) {
    return (
      _.isString(col[0]) &&
      _.isString(col[1]) &&
      _.isString(col[2]) &&
      _.isString(col[3]) &&
      _.isString(col[4]) &&
      _.isString(col[5]) &&
      _.isString(col[6]) &&
      _.isString(col[7]) &&
      _.isString(col[8]) &&
      _.isString(col[9]) 
    );
  }

}
export default BuildingBudget;
