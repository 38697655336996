import { BankAccount } from "../BankAccount/BankAccount.type";
import { ApiCallState } from "../Utils";

export type PostDateVoucher = {
  id: number;
  date: string;
  check_date:string;
  is_paid:boolean;
  voucher_number: number;
  bank_name: string;
  bank_account_id: number;
  branch: string;
  cheque_number: number;
  project: string;
  pay_to: string;
  amount: number;
  purpose: string;
  is_void: boolean;
  user_id: number;
  bank_account: BankAccount;
  post_date_voucher_items: PostDateVoucherItem[];
  post_date_voucher_statuses: PostDateVoucherStatus[];
};

export type PostDateVoucherItem = {
  id: number;
  post_date_voucher_id: number;
  account_no: string;
  account_description: string;
  debit: number;
  credit: number;
};

export type PostDateVoucherStatus = {
  post_date_voucher_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type PostDateVoucherStateTypes = {
  fetchAll: ApiCallState<PostDateVoucher[]>;
  fetchOne: ApiCallState<PostDateVoucher | {}>;
};

export const PostDateVoucherActionTypes = {
  FETCH_ALL_POST_DATE_VOUCHER: "FETCH_ALL_POST_DATE_VOUCHER",
  FETCH_ALL_POST_DATE_VOUCHER_RESET: "FETCH_ALL_POST_DATE_VOUCHER_RESET",
  FETCH_ALL_POST_DATE_VOUCHER_FAILURE: "FETCH_ALL_POST_DATE_VOUCHER_FAILURE",
  FETCH_ALL_POST_DATE_VOUCHER_SUCCESS: "FETCH_ALL_POST_DATE_VOUCHER_SUCCESS",

  FETCH_ONE_POST_DATE_VOUCHER: "FETCH_ONE_POST_DATE_VOUCHER",
  FETCH_ONE_POST_DATE_VOUCHER_RESET: "FETCH_ONE_POST_DATE_VOUCHER_RESET",
  FETCH_ONE_POST_DATE_VOUCHER_FAILURE: "FETCH_ONE_POST_DATE_VOUCHER_FAILURE",
  FETCH_ONE_POST_DATE_VOUCHER_SUCCESS: "FETCH_ONE_POST_DATE_VOUCHER_SUCCESS",
};
