import { ApiCallState } from "../Utils";

export type AdditionBoq= {
  id: number;
  project_id:number;
  date:string;
  description:string;
  total_amount:number;
  addition_boq_items:AdditionBoqItem[];
};

export type AdditionBoqItem={
    id: number;
    addition_boq_id: number;
    description: string;
    item_no: string;
    unit: string;
    quantity: number;
    unit_price: number;
    reference_id: number;
    sheet_name: string;
}

export type AdditionBoqStateTypes = {
  fetchAll: ApiCallState<AdditionBoq[]>;
  fetchOne: ApiCallState<AdditionBoq | {}>;
};

export const AdditionBoqActionTypes = {
  FETCH_ALL_ADDITION_BOQ: "FETCH_ALL_ADDITION_BOQ",
  FETCH_ALL_ADDITION_BOQ_RESET: "FETCH_ALL_ADDITION_BOQ_RESET",
  FETCH_ALL_ADDITION_BOQ_FAILURE: "FETCH_ALL_ADDITION_BOQ_FAILURE",
  FETCH_ALL_ADDITION_BOQ_SUCCESS: "FETCH_ALL_ADDITION_BOQ_SUCCESS",

  FETCH_ONE_ADDITION_BOQ: "FETCH_ONE_ADDITION_BOQ",
  FETCH_ONE_ADDITION_BOQ_RESET: "FETCH_ONE_ADDITION_BOQ_RESET",
  FETCH_ONE_ADDITION_BOQ_FAILURE: "FETCH_ONE_ADDITION_BOQ_FAILURE",
  FETCH_ONE_ADDITION_BOQ_SUCCESS: "FETCH_ONE_ADDITION_BOQ_SUCCESS",
};
