import { InitPagedData } from "../Utils";
import {
  FixedAssetIssueStateTypes,
  FixedAssetIssueActionTypes,
} from "./FixedAssetIssue.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: FixedAssetIssueStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchAllDetailed: resetApiCallState([]),

  fetchOne: resetApiCallState({}),
  fetchPaged: resetApiCallState(InitPagedData),
  fetchReport: resetApiCallState([]),
};

const FixedAssetIssueReducer = (
  state: FixedAssetIssueStateTypes = INITIAL_STATE,
  action: any
): FixedAssetIssueStateTypes => {
  switch (action.type) {
    case FixedAssetIssueActionTypes.FETCH_ALL_FIXED_ASSET_ISSUE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case FixedAssetIssueActionTypes.FETCH_ALL_FIXED_ASSET_ISSUE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case FixedAssetIssueActionTypes.FETCH_ALL_FIXED_ASSET_ISSUE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FixedAssetIssueActionTypes.FETCH_ALL_FIXED_ASSET_ISSUE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    // -------------------------

    case FixedAssetIssueActionTypes.FETCH_ALL_DETAILED_FIXED_ASSET_ISSUE:
      return {
        ...state,
        fetchAllDetailed: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case FixedAssetIssueActionTypes.FETCH_ALL_DETAILED_FIXED_ASSET_ISSUE_RESET:
      return {
        ...state,
        fetchAllDetailed: resetApiCallState([]),
      };
    case FixedAssetIssueActionTypes.FETCH_ALL_DETAILED_FIXED_ASSET_ISSUE_FAILURE:
      return {
        ...state,
        fetchAllDetailed: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FixedAssetIssueActionTypes.FETCH_ALL_DETAILED_FIXED_ASSET_ISSUE_SUCCESS:
      return {
        ...state,
        fetchAllDetailed: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    // -------------------------

    case FixedAssetIssueActionTypes.FETCH_REPORT_FIXED_ASSET_ISSUE:
      return {
        ...state,
        fetchReport: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case FixedAssetIssueActionTypes.FETCH_REPORT_FIXED_ASSET_ISSUE_RESET:
      return {
        ...state,
        fetchReport: resetApiCallState([]),
      };
    case FixedAssetIssueActionTypes.FETCH_REPORT_FIXED_ASSET_ISSUE_FAILURE:
      return {
        ...state,
        fetchReport: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FixedAssetIssueActionTypes.FETCH_REPORT_FIXED_ASSET_ISSUE_SUCCESS:
      return {
        ...state,
        fetchReport: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case FixedAssetIssueActionTypes.FETCH_PAGED_FIXED_ASSET_ISSUE:
      return {
        ...state,
        fetchPaged: {
          error: null,
          payload: InitPagedData,
          isPending: true,
          isSuccessful: false,
        },
      };
    case FixedAssetIssueActionTypes.FETCH_PAGED_FIXED_ASSET_ISSUE_RESET:
      return {
        ...state,
        fetchPaged: resetApiCallState(InitPagedData),
      };
    case FixedAssetIssueActionTypes.FETCH_PAGED_FIXED_ASSET_ISSUE_FAILURE:
      return {
        ...state,
        fetchPaged: {
          payload: InitPagedData,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FixedAssetIssueActionTypes.FETCH_PAGED_FIXED_ASSET_ISSUE_SUCCESS:
      return {
        ...state,
        fetchPaged: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case FixedAssetIssueActionTypes.FETCH_ONE_FIXED_ASSET_ISSUE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case FixedAssetIssueActionTypes.FETCH_ONE_FIXED_ASSET_ISSUE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case FixedAssetIssueActionTypes.FETCH_ONE_FIXED_ASSET_ISSUE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FixedAssetIssueActionTypes.FETCH_ONE_FIXED_ASSET_ISSUE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default FixedAssetIssueReducer;
