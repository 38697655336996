import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { OtherExpenseActionTypes } from "./OtherExpense.type";

export function* fetchAllOtherExpenses(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/other-expense?project_id=${action.payload?.project_id}`);
    yield put({
      type: OtherExpenseActionTypes.FETCH_ALL_OTHER_EXPENSE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: OtherExpenseActionTypes.FETCH_ALL_OTHER_EXPENSE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneOtherExpenses(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/other-expense/${action.payload}`
    );
    yield put({
      type: OtherExpenseActionTypes.FETCH_ONE_OTHER_EXPENSE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: OtherExpenseActionTypes.FETCH_ONE_OTHER_EXPENSE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllOtherExpenses() {
  yield takeLatest(OtherExpenseActionTypes.FETCH_ALL_OTHER_EXPENSE, fetchAllOtherExpenses);
}

export function* watcherFetchOneOtherExpenses() {
  yield takeLatest(OtherExpenseActionTypes.FETCH_ONE_OTHER_EXPENSE, fetchOneOtherExpenses);
}
