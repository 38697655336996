import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SocialCommitmentActionTypes } from "./SocialCommitment.type";

export function* fetchAllSocialCommitments(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/social-commitment`);
    yield put({
      type: SocialCommitmentActionTypes.FETCH_ALL_SOCIAL_COMMITMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SocialCommitmentActionTypes.FETCH_ALL_SOCIAL_COMMITMENT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSocialCommitments(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/social-commitment/${action.payload}`
    );
    yield put({
      type: SocialCommitmentActionTypes.FETCH_ONE_SOCIAL_COMMITMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SocialCommitmentActionTypes.FETCH_ONE_SOCIAL_COMMITMENT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSocialCommitments() {
  yield takeLatest(SocialCommitmentActionTypes.FETCH_ALL_SOCIAL_COMMITMENT, fetchAllSocialCommitments);
}

export function* watcherFetchOneSocialCommitments() {
  yield takeLatest(SocialCommitmentActionTypes.FETCH_ONE_SOCIAL_COMMITMENT, fetchOneSocialCommitments);
}
