import { ApiCallState } from "../Utils";

export type WeeklySiteReport= {
  id: number;
  start_date: string;
  end_date: string;
  createdAt: Date
};

export type WeeklySiteReportStateTypes = {
  fetchAll: ApiCallState<WeeklySiteReport[]>;
  fetchOne: ApiCallState<WeeklySiteReport | {}>;
};

export const WeeklySiteReportActionTypes = {
  FETCH_ALL_WEEKLY_SITE_REPORT: "FETCH_ALL_WEEKLY_SITE_REPORT",
  FETCH_ALL_WEEKLY_SITE_REPORT_RESET: "FETCH_ALL_WEEKLY_SITE_REPORT_RESET",
  FETCH_ALL_WEEKLY_SITE_REPORT_FAILURE: "FETCH_ALL_WEEKLY_SITE_REPORT_FAILURE",
  FETCH_ALL_WEEKLY_SITE_REPORT_SUCCESS: "FETCH_ALL_WEEKLY_SITE_REPORT_SUCCESS",

  FETCH_ONE_WEEKLY_SITE_REPORT: "FETCH_ONE_WEEKLY_SITE_REPORT",
  FETCH_ONE_WEEKLY_SITE_REPORT_RESET: "FETCH_ONE_WEEKLY_SITE_REPORT_RESET",
  FETCH_ONE_WEEKLY_SITE_REPORT_FAILURE: "FETCH_ONE_WEEKLY_SITE_REPORT_FAILURE",
  FETCH_ONE_WEEKLY_SITE_REPORT_SUCCESS: "FETCH_ONE_WEEKLY_SITE_REPORT_SUCCESS",
};
