import { PlaceOfWork } from "../PlaceOfWork/PlaceOfWork.type";
import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type ProjectPermanentAttendance= {
  id: number;
  month: string;
  place_of_work_id:number;
  place_of_work:PlaceOfWork;
  project_permanent_attendance_items:ProjectPermanentAttendanceItem[];
};

export type ProjectPermanentAttendanceItem= {
    id:number;
    project_permanent_attendance_id:number;
    staff_id:number;
    position:string;
    working_day:number;
    perdiem:number;
    desert_allowance:number;
    normal:number;
    sunday:number;
    holyday:number;
    remark:string;
    staff:Staff;
}

export type ProjectPermanentAttendanceStateTypes = {
  fetchAll: ApiCallState<ProjectPermanentAttendance[]>;
  fetchOne: ApiCallState<ProjectPermanentAttendance | {}>;
};

export const ProjectPermanentAttendanceActionTypes = {
  FETCH_ALL_PROJECT_PERMANENT_ATTENDANCE: "FETCH_ALL_PROJECT_PERMANENT_ATTENDANCE",
  FETCH_ALL_PROJECT_PERMANENT_ATTENDANCE_RESET: "FETCH_ALL_PROJECT_PERMANENT_ATTENDANCE_RESET",
  FETCH_ALL_PROJECT_PERMANENT_ATTENDANCE_FAILURE: "FETCH_ALL_PROJECT_PERMANENT_ATTENDANCE_FAILURE",
  FETCH_ALL_PROJECT_PERMANENT_ATTENDANCE_SUCCESS: "FETCH_ALL_PROJECT_PERMANENT_ATTENDANCE_SUCCESS",

  FETCH_ONE_PROJECT_PERMANENT_ATTENDANCE: "FETCH_ONE_PROJECT_PERMANENT_ATTENDANCE",
  FETCH_ONE_PROJECT_PERMANENT_ATTENDANCE_RESET: "FETCH_ONE_PROJECT_PERMANENT_ATTENDANCE_RESET",
  FETCH_ONE_PROJECT_PERMANENT_ATTENDANCE_FAILURE: "FETCH_ONE_PROJECT_PERMANENT_ATTENDANCE_FAILURE",
  FETCH_ONE_PROJECT_PERMANENT_ATTENDANCE_SUCCESS: "FETCH_ONE_PROJECT_PERMANENT_ATTENDANCE_SUCCESS",
};
