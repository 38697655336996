import { ApiCallState } from "../Utils";

export type SMSMessage = {
  id: number;
  user_id: number;

  staff_ids: number[];
  message: string;
  date: Date;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type SMSMessageStateTypes = {
  fetchAll: ApiCallState<SMSMessage[]>;
  fetchOne: ApiCallState<SMSMessage | {}>;
};

export const SMSMessageActionTypes = {
  FETCH_ALL_SMS_MESSAGE: "FETCH_ALL_SMS_MESSAGE",
  FETCH_ALL_SMS_MESSAGE_RESET: "FETCH_ALL_SMS_MESSAGE_RESET",
  FETCH_ALL_SMS_MESSAGE_FAILURE: "FETCH_ALL_SMS_MESSAGE_FAILURE",
  FETCH_ALL_SMS_MESSAGE_SUCCESS: "FETCH_ALL_SMS_MESSAGE_SUCCESS",

  FETCH_ONE_SMS_MESSAGE: "FETCH_ONE_SMS_MESSAGE",
  FETCH_ONE_SMS_MESSAGE_RESET: "FETCH_ONE_SMS_MESSAGE_RESET",
  FETCH_ONE_SMS_MESSAGE_FAILURE: "FETCH_ONE_SMS_MESSAGE_FAILURE",
  FETCH_ONE_SMS_MESSAGE_SUCCESS: "FETCH_ONE_SMS_MESSAGE_SUCCESS",
};
