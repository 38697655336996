import { ApiCallState } from "../Utils";

export type PeridiumRules = {
  id: number;
  date: string;
  user_id:number;
  peridium_rules_items:PeridiumRulesItem[];
  peridium_rules_statuses:PeridiumRulesStatus[];
};

export type PeridiumRulesItem = {
    id: number;
    peridium_rules_id: number;
    from:number;
    to:number;
    type:string;
    amount:number;
    special_type:string;
    special_peridium_amount:number;
};

export type PeridiumRulesStatus = {
  peridium_rules_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type PeridiumRulesStateTypes = {
  fetchAll: ApiCallState<PeridiumRules[]>;
  fetchOne: ApiCallState<PeridiumRules | {}>;
};

export const PeridiumRulesActionTypes = {
  FETCH_ALL_PERIDIUM_RULES: "FETCH_ALL_PERIDIUM_RULES",
  FETCH_ALL_PERIDIUM_RULES_RESET: "FETCH_ALL_PERIDIUM_RULES_RESET",
  FETCH_ALL_PERIDIUM_RULES_FAILURE: "FETCH_ALL_PERIDIUM_RULES_FAILURE",
  FETCH_ALL_PERIDIUM_RULES_SUCCESS: "FETCH_ALL_PERIDIUM_RULES_SUCCESS",

  FETCH_ONE_PERIDIUM_RULES: "FETCH_ONE_PERIDIUM_RULES",
  FETCH_ONE_PERIDIUM_RULES_RESET: "FETCH_ONE_PERIDIUM_RULES_RESET",
  FETCH_ONE_PERIDIUM_RULES_FAILURE: "FETCH_ONE_PERIDIUM_RULES_FAILURE",
  FETCH_ONE_PERIDIUM_RULES_SUCCESS: "FETCH_ONE_PERIDIUM_RULES_SUCCESS",
};
