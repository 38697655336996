import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ContractAdministrationActionTypes } from "./ContractAdministration.type";

export function* fetchAllContractAdministrations(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/contract-administration?${query}`
    );

    yield put({
      type: ContractAdministrationActionTypes.FETCH_ALL_CONTRACT_ADMINISTRATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ContractAdministrationActionTypes.FETCH_ALL_CONTRACT_ADMINISTRATION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneContractAdministrations(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/contract-administration/${action.payload}`
    );
    yield put({
      type: ContractAdministrationActionTypes.FETCH_ONE_CONTRACT_ADMINISTRATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ContractAdministrationActionTypes.FETCH_ONE_CONTRACT_ADMINISTRATION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllContractAdministrations() {
  yield takeLatest(
    ContractAdministrationActionTypes.FETCH_ALL_CONTRACT_ADMINISTRATION,
    fetchAllContractAdministrations
  );
}

export function* watcherFetchOneContractAdministrations() {
  yield takeLatest(
    ContractAdministrationActionTypes.FETCH_ONE_CONTRACT_ADMINISTRATION,
    fetchOneContractAdministrations
  );
}
