import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { DailyWorkStatusActionTypes } from "./DailyWorkStatus.type";

export function* fetchAllDailyWorkStatuses(action: any): any {
  let keys = Object.keys(action.payload);

  let assigns = keys.map((key) => `${key}=${action.payload[key]}`);
  let query = assigns.join("&&");

  try {
    const response = yield axios.get(
      `${API_BASE_URI}/daily-work-status?${query}`
    );
    yield put({
      type: DailyWorkStatusActionTypes.FETCH_ALL_DAILY_WORK_STATUS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DailyWorkStatusActionTypes.FETCH_ALL_DAILY_WORK_STATUS_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneDailyWorkStatus(action: any): any {
  try {
    let keys = Object.keys(action.payload);

    let assigns = keys.map((key) => `${key}=${action.payload[key]}`);
    let query = assigns.join("&&");

    const response = yield axios.get(
      `${API_BASE_URI}/daily-work-status/single?${query}`
    );
    yield put({
      type: DailyWorkStatusActionTypes.FETCH_ONE_DAILY_WORK_STATUS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DailyWorkStatusActionTypes.FETCH_ONE_DAILY_WORK_STATUS_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllDailyWorkStatuses() {
  yield takeLatest(
    DailyWorkStatusActionTypes.FETCH_ALL_DAILY_WORK_STATUS,
    fetchAllDailyWorkStatuses
  );
}

export function* watcherFetchOneDailyWorkStatuses() {
  yield takeLatest(
    DailyWorkStatusActionTypes.FETCH_ONE_DAILY_WORK_STATUS,
    fetchOneDailyWorkStatus
  );
}
