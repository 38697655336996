import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { WeeklySiteReportActionTypes } from "./WeeklySiteReport.type";

export function* fetchAllWeeklySiteReports(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/weekly-site-report`);
    yield put({
      type: WeeklySiteReportActionTypes.FETCH_ALL_WEEKLY_SITE_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WeeklySiteReportActionTypes.FETCH_ALL_WEEKLY_SITE_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneWeeklySiteReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/weekly-site-report/${action.payload}`
    );
    yield put({
      type: WeeklySiteReportActionTypes.FETCH_ONE_WEEKLY_SITE_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WeeklySiteReportActionTypes.FETCH_ONE_WEEKLY_SITE_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllWeeklySiteReports() {
  yield takeLatest(WeeklySiteReportActionTypes.FETCH_ALL_WEEKLY_SITE_REPORT, fetchAllWeeklySiteReports);
}

export function* watcherFetchOneWeeklySiteReports() {
  yield takeLatest(WeeklySiteReportActionTypes.FETCH_ONE_WEEKLY_SITE_REPORT, fetchOneWeeklySiteReports);
}
