import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { AttendanceLocationUserActionTypes } from "./AttendanceLocationUser.type";

export function* fetchAllAttendanceLocationUsers(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/attendance-location-user${query}`
    );
    yield put({
      type: AttendanceLocationUserActionTypes.FETCH_ALL_ATTENDANCE_LOCATION_USER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AttendanceLocationUserActionTypes.FETCH_ALL_ATTENDANCE_LOCATION_USER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneAttendanceLocationUsers(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/attendance-location-user/${action.payload}`
    );
    yield put({
      type: AttendanceLocationUserActionTypes.FETCH_ONE_ATTENDANCE_LOCATION_USER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AttendanceLocationUserActionTypes.FETCH_ONE_ATTENDANCE_LOCATION_USER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllAttendanceLocationUsers() {
  yield takeLatest(
    AttendanceLocationUserActionTypes.FETCH_ALL_ATTENDANCE_LOCATION_USER,
    fetchAllAttendanceLocationUsers
  );
}

export function* watcherFetchOneAttendanceLocationUsers() {
  yield takeLatest(
    AttendanceLocationUserActionTypes.FETCH_ONE_ATTENDANCE_LOCATION_USER,
    fetchOneAttendanceLocationUsers
  );
}
