import { PenalityStateTypes, PenalityActionTypes } from "./Penality.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: PenalityStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const PenalityReducer = (
  state: PenalityStateTypes = INITIAL_STATE,
  action: any
): PenalityStateTypes => {
  switch (action.type) {
    case PenalityActionTypes.FETCH_ALL_PENALITY:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case PenalityActionTypes.FETCH_ALL_PENALITY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case PenalityActionTypes.FETCH_ALL_PENALITY_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PenalityActionTypes.FETCH_ALL_PENALITY_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case PenalityActionTypes.FETCH_ONE_PENALITY:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case PenalityActionTypes.FETCH_ONE_PENALITY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case PenalityActionTypes.FETCH_ONE_PENALITY_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PenalityActionTypes.FETCH_ONE_PENALITY_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default PenalityReducer;
