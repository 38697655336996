import { InventoryStateTypes, InventoryActionTypes } from "./Inventory.type";
import { InitPagedData, resetApiCallState } from "../Utils";

const INITIAL_STATE: InventoryStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchAllQueried: resetApiCallState([]),
  fetchOne: resetApiCallState([]),
  fetchAnalysis: resetApiCallState(InitPagedData),
  fetchUnpagedAnalysis: resetApiCallState([]),
  fetchStockCard: resetApiCallState([]),
  fetchStockReport: resetApiCallState(InitPagedData),
};

const InventoryReducer = (
  state: InventoryStateTypes = INITIAL_STATE,
  action: any
): InventoryStateTypes => {
  switch (action.type) {
    case InventoryActionTypes.FETCH_ALL_INVENTORY:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case InventoryActionTypes.FETCH_ALL_INVENTORY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case InventoryActionTypes.FETCH_ALL_INVENTORY_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case InventoryActionTypes.FETCH_ALL_INVENTORY_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    // ----------------------------

    case InventoryActionTypes.FETCH_ALL_QUERIED_INVENTORY:
      return {
        ...state,
        fetchAllQueried: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case InventoryActionTypes.FETCH_ALL_QUERIED_INVENTORY_RESET:
      return {
        ...state,
        fetchAllQueried: resetApiCallState([]),
      };
    case InventoryActionTypes.FETCH_ALL_QUERIED_INVENTORY_FAILURE:
      return {
        ...state,
        fetchAllQueried: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case InventoryActionTypes.FETCH_ALL_QUERIED_INVENTORY_SUCCESS:
      return {
        ...state,
        fetchAllQueried: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    // ----------------------------

    case InventoryActionTypes.FETCH_ONE_INVENTORY:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case InventoryActionTypes.FETCH_ONE_INVENTORY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState({}),
      };
    case InventoryActionTypes.FETCH_ONE_INVENTORY_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case InventoryActionTypes.FETCH_ONE_INVENTORY_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    // ----------------------------

    case InventoryActionTypes.FETCH_ANALYSIS_INVENTORY:
      return {
        ...state,
        fetchAnalysis: {
          error: null,
          payload: InitPagedData,
          isPending: true,
          isSuccessful: false,
        },
      };
    case InventoryActionTypes.FETCH_ANALYSIS_INVENTORY_RESET:
      return {
        ...state,
        fetchAnalysis: resetApiCallState([]),
      };
    case InventoryActionTypes.FETCH_ANALYSIS_INVENTORY_FAILURE:
      return {
        ...state,
        fetchAnalysis: {
          payload: InitPagedData,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case InventoryActionTypes.FETCH_ANALYSIS_INVENTORY_SUCCESS:
      return {
        ...state,
        fetchAnalysis: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    // ----------------------------

    case InventoryActionTypes.FETCH_UNPAGED_ANALYSIS_INVENTORY:
      return {
        ...state,
        fetchUnpagedAnalysis: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case InventoryActionTypes.FETCH_UNPAGED_ANALYSIS_INVENTORY_RESET:
      return {
        ...state,
        fetchUnpagedAnalysis: resetApiCallState([]),
      };
    case InventoryActionTypes.FETCH_UNPAGED_ANALYSIS_INVENTORY_FAILURE:
      return {
        ...state,
        fetchUnpagedAnalysis: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case InventoryActionTypes.FETCH_UNPAGED_ANALYSIS_INVENTORY_SUCCESS:
      return {
        ...state,
        fetchUnpagedAnalysis: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    // ----------------------------

    case InventoryActionTypes.FETCH_INVENTORY_STOCK_CARD:
      return {
        ...state,
        fetchStockCard: {
          error: null,
          payload: {
            stock_card: [],
            summery: {
              total_issued: 0,
              total_received: 0,
              total_returned: 0,
              total_transferred: 0,
              unit_price: 0,
            },
          },
          isPending: true,
          isSuccessful: false,
        },
      };
    case InventoryActionTypes.FETCH_INVENTORY_STOCK_RESET:
      return {
        ...state,
        fetchStockCard: resetApiCallState([]),
      };
    case InventoryActionTypes.FETCH_INVENTORY_STOCK_FAILURE:
      return {
        ...state,
        fetchStockCard: {
          payload: {
            stock_card: [],
            summery: {
              total_issued: 0,
              total_received: 0,
              total_returned: 0,
              total_transferred: 0,
              unit_price: 0,
            },
          },
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case InventoryActionTypes.FETCH_INVENTORY_STOCK_SUCCESS:
      return {
        ...state,
        fetchStockCard: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    // -----------------------------

    case InventoryActionTypes.FETCH_INVENTORY_STOCK_REPORT:
      return {
        ...state,
        fetchStockReport: {
          error: null,
          payload: {
            count: 0,
            current_page: 0,
            page_size: 0,
            rows: [],
            summary: {
              totalIssued: 0,
              totalReceived: 0,
            },
          },
          isPending: true,
          isSuccessful: false,
        },
      };
    case InventoryActionTypes.FETCH_INVENTORY_STOCK_REPORT_RESET:
      return {
        ...state,
        fetchStockReport: resetApiCallState([]),
      };
    case InventoryActionTypes.FETCH_INVENTORY_STOCK_REPORT_FAILURE:
      return {
        ...state,
        fetchStockReport: {
          payload: {
            count: 0,
            current_page: 0,
            page_size: 0,
            rows: [],
            summary: {
              totalIssued: 0,
              totalReceived: 0,
            },
          },
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case InventoryActionTypes.FETCH_INVENTORY_STOCK_REPORT_SUCCESS:
      return {
        ...state,
        fetchStockReport: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default InventoryReducer;
