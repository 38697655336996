import { all, call } from "redux-saga/effects";
import {
  watcherFetchAllAccounts,
  watcherFetchOneAccounts,
} from "./Account/Account.saga";
import {
  watcherFetchAllBoq,
  watcherFetchBoqTotal,
  watcherFetchDetailBoq,
  watcherFetchOneBoq,
  watcherFetchPagedBoq,
  watcherFetchSheetNames,
  watcherFetchUnitBoq,
} from "./Boq/Boq.saga";
import { watcherFetchAllBoqActivityPlans } from "./BoqActivityPlan/BoqActivityPlan.saga";
import { watcherFetchAllBoqPlans } from "./BoqPlan/BoqPlan.saga";
import { watcherFetchAllConsultants } from "./Consultant/Consultant.saga";
import { watcherFetchAllContractors } from "./Contractor/Contractor.saga";
import { watcherFetchAllCustomers } from "./Customer/Customer.saga";
import { watcherFetchAllDepartments } from "./Department/Department.saga";
import { watcherFetchAllDocuments } from "./Document/Document.saga";
import { watcherFetchEquipmentInventories } from "./EquipmentInventory/EquipmentInventory.saga";
import { watcherFetchAllEquipmentPlans } from "./EquipmentPlan/EquipmentPlan.saga";
import { watcherFetchEquipmentTransfer } from "./EquipmentTransfer/EquipmentTransfer.saga";
import { watcherFetchAllEquipmentUsage } from "./EquipmentUsage/EquipmentUsage.saga";
import { watcherFetchAllEquipmentUsagePlans } from "./EquipmentUsagePlan/EquipmentUsagePlan.saga";
import { watcherFetchAllExpense } from "./Expense/Expense.saga";
import { watcherFetchAllInvoice } from "./Invoice/Invoice.saga";
import { watcherFetchAllLabour } from "./Labour/Labour.saga";
import { watcherFetchAllLabourPlans } from "./LabourPlan/LabourPlan.saga";
import { watcherFetchAllLabourUsage } from "./LabourUsage/LabourUsage.saga";
import {
  watcherFetchAllMaterials,
  watcherFetchAllFormMaterials,
  watcherFetchPagedMaterials,
  watcherFetchMaterialNumber,
} from "./Material/Material.saga";

import {
  watcherFetchMaterialInventories,
  watcherFetchMaterialInventoriesAnalysis,
} from "./MaterialInventory/MaterialInventory.saga";
import { watcherFetchAllMaterialPlans } from "./MaterialPlan/MaterialPlan.saga";
import { watcherFetchMaterialTransfer } from "./MaterialTransfer/MaterialTransfer.saga";
import { watcherFetchAllPayment } from "./Payment/Payment.saga";
import { watcherFetchAllPaymentRetention } from "./PaymentRetention/PaymentRetention.saga";
import {
  watcherFetchAllEmployeeAccommodationPlan,
  watcherFetchOneEmployeeAccommodationPlan,
} from "./EmployeeAccommodationPlan/EmployeeAccommodationPlan.saga";

import {
  watcherFetchAllPreProjects,
  watcherFetchAllProjects,
  watcherFetchOnePreProjects,
  watcherFetchOneProjects,
  watcherFetchAllListProjects,
  watcherFetchAllProjectsDetail,
  watcherFetchAllProjectReport,
} from "./Project/Project.saga";
import { watcherFetchAllProjectStaffs } from "./ProjectStaff/ProjectStaff.saga";
import { watcherFetchAllRebar } from "./Rebar/Rebar.saga";
import { watcherFetchAllServices } from "./Service/Service.saga";
import {
  watcherFetchAllStaffs,
  watcherFetchOneStaffs,
  watcherFetchAllStaffDetails,
} from "./Staff/Staff.saga";
import { watcherFetchAllStaffPlans } from "./StaffPlan/StaffPlan.saga";
import {
  watcherFetchAllSubContracts,
  watcherFetchOneSubContracts,
} from "./SubContract/SubContract.saga";
import { watcherFetchAllSubContractPlans } from "./SubContractPlan/SubContractPlan.saga";
import { watcherFetchSupplies } from "./Supplier/Supplier.saga";
import { watcherFetchAllTakeoffs } from "./Takeoff/Takeoff.saga";
import {
  watcherFetchAllUnitBreakdownsPost,
  watcherFetchAllUnitBreakdownsPre,
  watcherFetchOneUnitBreakdownsPre,
} from "./UnitBreakdown/UnitBreakdown.saga";
import { watcherFetchAllUsage } from "./Usage/Usage.saga";
import {
  watcherFetchAllVariation,
  watcherFetchBoqVariation,
  watcherFetchPIDVariation,
} from "./Variation/Variation.saga";
import { watcherFetchAllManpowerOvertime } from "./ManpowerOvertime/ManpowerOvertime.saga";
import { watcherFetchAllSchedules } from "./Schedule/Schedule.saga";
import { watcherFetchAllEmployeeExtras } from "./EmployeeExtra/EmployeeExtra.saga";
import {
  watcherFetchAllUser,
  watcherFetchFeatureUser,
  watcherFetchOneUser,
} from "./User/User.saga";
import { watcherFetchAllAttendances } from "./Attendance/Attendance.saga";
import {
  watcherFetchAllEmployeeRequests,
  watcherFetchEmployeeHealthBenefitRequestState,
  watcherFetchEmployeeLeaveRequestState,
} from "./EmployeeRequest/EmployeeRequest.saga";
import {
  watcherFetchAllMaterialRequisitions,
  watcherFetchOneMaterialRequisitions,
  watcherFetchPreviousMaterialRequisitionItems,
  watcherFetchPagedMaterialRequisitions,
  watcherFetchListMaterialRequisitions,
} from "./MaterialRequisition/MaterialRequisition.saga";
import {
  watcherFetchAllPurchaseRequisitions,
  watcherFetchOnePurchaseRequisitions,
  watcherFetchReportPurchaseRequisitions,
  watcherFetchPurchaseRequisitionTracking,
} from "./PurchaseRequisition/PurchaseRequisition.saga";
import {
  watcherFetchAllPurchaseOrders,
  watcherFetchOnePurchaseOrders,
} from "./PurchaseOrder/PurchaseOrder.saga";
import { watcherFetchAllPurchaseBillings } from "./PurchaseBilling/PurchaseBilling.saga";
import {
  watcherFetchAllGoodReceived,
  watcherFetchOneGoodReceived,
  watcherFetchGoodReceivedReports,
  watcherFetchPagedGoodReceived,
} from "./GoodReceived/GoodReceived.saga";

import { watcherFetchAllRenters } from "./Renter/Renter.saga";
import {
  watcherFetchAllGoodOut,
  watcherFetchReportGoodOut,
} from "./GoodOut/GoodOut.saga";
import {
  watcherFetchAllGoodReturn,
  watcherFetchOneGoodReturn,
  watcherFetchReportGoodReturn,
} from "./GoodReturn/GoodReturn.saga";
import { watcherFetchEmployeeHistory } from "./EmployeeHistory/EmployeeHistory.saga";
import { watcherFetchAllDocumentWorkOrders } from "./DocumentWorkOrder/DocumentWorkOrder.saga";
import { watcherFetchAllEquipmentWO } from "./EquipmentWO/EquipmentWO.saga";
import {
  watcherFetchAllHRPolicy,
  watcherFetchLeaveReport,
} from "./HRPolicy/HRPolicy.saga";
import { watcherFetchAllAbsences } from "./Absence/Absence.saga";
import { watcherFetchAllEmployeeDocuments } from "./EmployeeDocument/EmployeeDocument.saga";
import {
  watcherCalculatePayroll,
  watcherFetchAllPayrolls,
  watcherPayrollReport,
  watcherStaffPayrollReport,
  watcherPayrollByDate,
} from "./Payroll/Payroll.saga";

import { watcherFetchAllClients } from "./Client/Client.saga";
import { watcherFetchAllAllowances } from "./Allowance/Allowance.saga";
import { watcherFetchAllAllowancePolicy } from "./AllowancePolicy/AllowancePolicy.saga";
import {
  watcherFetchAllStandard,
  watcherFetchOneStandard,
} from "./Standard/Standard.saga";
import { watcherFetchAllLog, watcherFetchOneLog } from "./Log/Log.saga";
import {
  watcherFetchAllBoqStandard,
  watcherFetchOneBoqStandard,
} from "./BoqStandard/BoqStandard.saga";
import {
  watcherFetchAllResources,
  watcherFetchOneResources,
} from "./Resource/Resource.sage";

import { watcherFetchAllSiteDiary } from "./SiteDiary/SiteDiary.saga";
import { watcherFetchAllWeekReport } from "./WeekReport/WeekReport.saga";

import {
  watcherFetchAllMasterSchedules,
  watcherFetchOneMasterSchedules,
} from "./MasterSchedule/MasterSchedule.saga";
import { watcherFetchAllReviewForm } from "./ReviewForm/ReviewForm.saga";
import {
  watcherFetchAllEmployeeReviews,
  watcherFetchOneEmployeeReviews,
} from "./EmployeeReview/EmployeeReview.saga";
import { watcherFetchAllBonuses } from "./Bonus/Bonus.saga";

import {
  watcherFetchAllVacancies,
  watcherFetchOneVacancy,
} from "./Vacancy/Vacancy.saga";
import { watcherFetchAllApplications } from "./Application/Application.saga";
import { watcherFetchAllMedias } from "./Media/Media.saga";
import { watcherFetchAllSHEs } from "./SHE/SHE.saga";
import {
  watcherFetchAllSharedDocuments,
  watcherFetchAllSharedDocumentUsers,
} from "./SharedDocument/SharedDocument.saga";

import {
  watcherFetchAllBankAccounts,
  watcherFetchOneBankAccounts,
} from "./BankAccount/BankAccount.saga";

import { watcherFetchAllAccountTransactions } from "./AccountTransaction/AccountTransaction.saga";
import { watcherFetchAllRentContracts } from "./RentContract/RentContract.saga";
import {
  watcherFetchAllReceivables,
  watcherFetchOneReceivables,
} from "./Receivable/Receivable.saga";

import { watcherFetchAllAttachments } from "./Attachment/Attachment.saga";

import {
  watcherFetchAllPettyCashes,
  watcherFetchOnePettyCashes,
} from "./PettyCash/PettyCash.saga";

import {
  watcherFetchAllReplenishments,
  watcherFetchOneReplenishments,
} from "./Replenish/Replenishment.saga";

import {
  watcherFetchAllReplenishmentTransactions,
  watcherFetchOneReplenishmentTransactions,
} from "./ReplenishTransaction/ReplenishmentTransaction.saga";

import {
  watcherFetchAllReplenishmentRequests,
  watcherFetchOneReplenishmentRequests,
} from "./ReplenishRequest/ReplenishmentRequest.saga";

import {
  watcherFetchAllVariationFiles,
  watcherFetchOneVariationFiles,
} from "./VariationFile/VariationFile.saga";
import {
  watcherFetchAllCRVPayments,
  watcherFetchOneCRVPayments,
} from "./CRVPayment/CRVPayment.saga";

import {
  watcherFetchAllPostChecks,
  watcherFetchOnePostChecks,
} from "./PostCheck/PostCheck.saga";

import {
  watcherFetchAllBankLoans,
  watcherFetchOneBankLoans,
} from "./BankLoan/BankLoan/BankLoan.saga";

import {
  watcherFetchAllBankLoanPaymentSchedules,
  watcherFetchOneBankLoanPaymentSchedules,
} from "./BankLoan/BankLoanPaymentSchedule/BankLoanPaymentSchedule.saga";

import {
  watcherFetchAllEquipmentLists,
  watcherFetchOneEquipmentLists,
} from "./EquipmentList/EquipmentList.saga";

import {
  watcherFetchAllVehicleLists,
  watcherFetchOneVehicleLists,
} from "./VehicleList/VehicleList.saga";

// import {
//   watcherFetchAllMonthlyReports,
//   watcherFetchOneMonthlyReports,
// } from "./MonthlyReport/MonthlyReport.saga";
import { watcherFetchAllDailyReports } from "./DailyReport/DailyReport.saga";
import {
  watcherFetchAllMaterialUsages,
  watcherFetchOneMaterialUsages,
} from "./MaterialUsage/MaterialUsage.saga";
import { watcherFetchAllPayables } from "./Payable/Payable.saga";
import { watcherFetchAllBuildings } from "./Building/Building.saga";
import { watcherFetchAllApartments } from "./Apartment/Apartment.saga";
import { watcherFetchAllApartmentExpenses } from "./ApartmentExpense/ApartmentExpense.saga";
import { watcherFetchAllContractSaless } from "./ContractSales/ContractSales.saga";
import { watcherFetchAllEstimatedBuildingCosts } from "./EstimatedBuildingCost/EstimatedBuildingCost.saga";
import {
  watcherFetchAllProformas,
  watcherFetchOneProformas,
  watcherFetchTrendAnalysis,
} from "./Proforma/Proforma.saga";
import { watcherFetchAllCurrencies } from "./Currency/Currency.saga";
import {
  watcherFetchAllProcurementPlans,
  watcherFetchOneProcurementPlans,
} from "./ProcurementPlan/ProcurementPlan.saga";

import {
  watcherFetchAllPaymentSummarys,
  watcherFetchOnePaymentSummarys,
} from "./PaymentSummary/PaymentSummary.saga";

import {
  watcherFetchAllEquipmentRequests,
  watcherFetchOneEquipmentRequests,
} from "./EquipmentRequest/EquipmentRequest.saga";

import {
  watcherFetchAllEquipmentProductivitys,
  watcherFetchOneEquipmentProductivitys,
} from "./EquipmentProductivity/EquipmentProductivity.saga";

import {
  watcherFetchAllJustifications,
  watcherFetchOneJustifications,
} from "./Justification/Justification.saga";

import {
  watcherFetchAllBriefings,
  watcherFetchOneBriefings,
} from "./Briefing/Briefing.saga";

import {
  watcherFetchAllCriticalIssues,
  watcherFetchOneCriticalIssues,
} from "./CriticalIssue/CriticalIssue.saga";

import {
  watcherFetchAllBudgetRequests,
  watcherFetchOneBudgetRequests,
} from "./BudgetRequest/BudgetRequest.saga";

import {
  watcherFetchAllBudgetShiftingRequests,
  watcherFetchOneBudgetShiftingRequests,
} from "./BudgetShiftingRequest/BudgetShiftingRequest.saga";

import {
  watcherFetchAllBudgetUtilizations,
  watcherFetchOneBudgetUtilizations,
} from "./BudgetUtilization/BudgetUtilization.saga";

import {
  watcherFetchAllFuelRequests,
  watcherFetchOneFuelRequests,
} from "./FuelRequest/FuelRequest.saga";
import {
  watcherFetchAllFinanceDailyReports,
  watcherFetchFinanceDailyReports,
} from "./FinanceDailyReport/FinanceDailyReport.saga";

import {
  watcherFetchAllFuelUtilizations,
  watcherFetchOneFuelUtilizations,
} from "./FuelUtilization/FuelUtilization.saga";

import {
  watcherFetchAllPaymentFollowUps,
  watcherFetchOnePaymentFollowUps,
} from "./PaymentFollowUp/PaymentFollowUp.saga";

import {
  watcherFetchAllMonthlyProgressReports,
  watcherFetchOneMonthlyProgressReports,
} from "./MonthlyProgressReport/MonthlyProgressReport.saga";
import {
  watcherFetchAllSubContractorPerformances,
  watcherFetchOneSubContractorPerformances,
} from "./SubContractorPerformance/SubContractorPerformance.saga";
import {
  watcherFetchAllStores,
  watcherFetchOneStores,
} from "./Store/Store.saga";
import {
  watcherFetchAllProformaComparisons,
  watcherFetchOneProformaComparisons,
} from "./ProformaComparison/ProformaComparison.saga";

import { watcherFetchAllSubContractorPayments } from "./SubContractorPayment/SubContractorPayment.saga";
import { watcherFetchAllWeeklyProgressSummarys } from "./WeeklyProgressSummary/WeeklyProgressSummary.saga";

import { watcherFetchAllUncollectedIpcs } from "./UncollectedIpc/UncollectedIpc.saga";

import { watcherFetchAllProjectLists } from "./ProjectList/ProjectList.saga";

import {
  watcherFetchAllMasterLists,
  watcherFetchOneMasterLists,
} from "./MasterList/MasterList.saga";

import { watcherFetchAllFuelRequestVouchers } from "./FuelRequestVoucher/FuelRequestVoucher.saga";
import { watcherFetchAllFuelRecievingVouchers } from "./FuelRecievingVoucher/FuelRecievingVoucher.saga";
import { watcherFetchAllFuelIssueVouchers } from "./FuelIssueVoucher/FuelIssueVoucher.saga";

import {
  watcherFetchAllPayableV2s,
  watcherFetchOnePayableV2s,
} from "./PayableV2/PayableV2.saga";
import {
  watcherFetchAllPayableV2Payments,
  watcherFetchOnePayableV2Payments,
} from "./PayableV2Payment/PayableV2Payment.saga";
import { watcherFetchAllBiddingSchedules } from "./BiddingSchedule/BiddingSchedule.saga";

import { watcherFetchAllTasks, watcherFetchOneTasks } from "./Task/Task.saga";

import {
  watcherFetchAllUploadDocuments,
  watcherFetchOneUploadDocuments,
  watcherFetchUploadDocumentReferenceNumber,
} from "./UploadDocument/UploadDocument.saga";

import { watcherFetchAllProjectDailyReport } from "./Project/Project.saga";
import { watcherFetchAllMikadaDailyReports } from "./MikadaDailyReport/MikadaDailyReport.saga";
import { watcherFetchAllBriefingForms } from "./BriefingForm/BriefingForm.saga";
import { watcherFetchAllMikadaWeeklyProgresss } from "./MikadaWeeklyProgress/MikadaWeeklyProgress.saga";

import { watcherFetchAllMikadaWeeklyEquipmentReports } from "./MikadaWeeklyEquipmentReport/MikadaWeeklyEquipmentReport.saga";
import { watcherFetchAllMikadaWeeklyLabourReports } from "./MikadaWeeklyLabourReport/MikadaWeeklyLabourReport.saga";
import { watcherFetchAllMikadaWeeklyMaterialReports } from "./MikadaWeeklyMaterialReport/MikadaWeeklyMaterialReport.saga";
import { watcherFetchAllMikadaWeeklySubContractReports } from "./MikadaWeeklySubContractReport/MikadaWeeklySubContractReport.saga";
import { watcherFetchOneWorkEvaluations } from "./WorkEvaluation/WorkEvaluation.saga";
import { watcherFetchAllMikadaMonthlyReports } from "./MikadaMonthlyReport/MikadaMonthlyReport.saga";
import { watcherFetchAllMikadaAnnualReports } from "./MikadaAnnualReport/MikadaAnnualReport.saga";
import {
  watcherFetchAllBillSummarys,
  watcherFetchOneBillSummarys,
} from "./BillSummary/BillSummary.saga";
import {
  watcherFetchAllAdditionBoqs,
  watcherFetchOneAdditionBoqs,
} from "./AdditionBoq/AdditionBoq.saga";
import {
  watcherFetchAllOmissionBoqs,
  watcherFetchOneOmissionBoqs,
} from "./OmissionBoq/OmissionBoq.saga";
import {
  watcherFetchAllSummarys,
  watcherFetchOneSummarys,
} from "./Summary/Summary.saga";
import {
  watcherFetchAllSupplementaryBoqs,
  watcherFetchOneSupplementaryBoqs,
} from "./SupplementaryBoq/SupplementaryBoq.saga";
import { watcherFetchAllSiteVisits } from "./SiteVisit/SiteVisit.saga";
import {
  watcherFetchAllSubContractRegistrys,
  watcherFetchOneSubContractRegistrys,
} from "./SubContractRegistry/SubContractRegistry.saga";
import {
  watcherFetchAllSubContractPaymentUpdates,
  watcherFetchOneSubContractPaymentUpdates,
} from "./SubContractPaymentUpdate/SubContractPaymentUpdate.saga";

import {
  watcherFetchAllComments,
  watcherFetchOneComments,
} from "./Comment/Comment.saga";

import {
  watcherFetchAllMaintenanceVouchers,
  watcherFetchMaintenanceVoucherNumber,
} from "./MaintenanceVoucher/MaintenanceVoucher.saga";
import { watcherFetchAllMaintenanceRequests } from "./MaintenanceRequest/MaintenanceRequest.saga";
import { watcherFetchAllAccidentFollowUps } from "./AccidentFollowUp/AccidentFollowUp.saga";
import {
  watcherFetchAllDailyWorkStatuses,
  watcherFetchOneDailyWorkStatuses,
} from "./DailyWorkStatus/DailyWorkStatus.saga";

import { watcherFetchAllInsurances } from "./Insurance/Insurance.saga";
import { watcherFetchAllStoreRequisitions } from "./StoreRequisition/StoreRequisition.saga";
import { watcherFetchAllStoreRequisitionFollowUps } from "./StoreRequisitionFollowUp/StoreRequisitionFollowUp.saga";
import { watcherFetchAllServiceVouchers } from "./ServiceVoucher/ServiceVoucher.saga";
import { watcherFetchAllBudgetPlannings } from "./BudgetPlanning/BudgetPlanning.saga";
import { watcherFetchAllSubContractAgreements } from "./SubContractAgreement/SubContractAgreement.saga";
import { watcherFetchAllBudgetPlanningItems } from "./BudgetPlanningItem/BudgetPlanningItem.saga";
import { watcherFetchAllBoqItemGroups } from "./BoqItemGroup/BoqItemGroup.saga";
import { watcherFetchAllMannings } from "./Manning/Manning.saga";
import { watcherFetchAllHRRequests } from "./HRRequest/HRRequest.saga";
import {
  watcherFetchAllApplicantRegistrys,
  watcherFetchOneApplicantRegistrys,
} from "./ApplicantRegistry/ApplicantRegistry.saga";
import { watcherFetchAllAssessments } from "./Assessment/Assessment.saga";
import { watcherFetchOneApplicantRegistryItems } from "./ApplicantRegistryItem/ApplicantRegistryItem.saga";
import { watcherFetchAllProjectAllowances } from "./ProjectAllowance/ProjectAllowance.saga";
import { watcherFetchAllProbationReviews } from "./ProbationReview/ProbationReview.saga";
import { watcherFetchAllGrades } from "./Grade/Grade.saga";
import { watcherFetchAllScales } from "./Scale/Scale.saga";
import { watcherFetchAllScaleItems } from "./ScaleItem/ScaleItem.saga";
import {
  watcherFetchOneDepartmentRelationShips,
  watcherFetchAllDepartmentRelationShips,
} from "./DepartmentRelationShip/DepartmentRelationShip.saga";
import {
  watcherFetchOneAllowableScales,
  watcherFetchAllAllowableScales,
} from "./AllowableScale/AllowableScale.saga";
import {
  watcherFetchOneEmployeeAllowances,
  watcherFetchAllEmployeeAllowances,
} from "./EmployeeAllowance/EmployeeAllowance.saga";
import { watcherFetchAllManningCategorys } from "./ManningCategory/ManningCategory.saga";
import {
  watcherFetchAllDetailReportActivitys,
  watcherFetchOneDetailReportActivitys,
} from "./DetailReportActivity/DetailReportActivity.saga";
import {
  watcherFetchAllEquipmentCosts,
  watcherFetchOneEquipmentCosts,
} from "./EquipmentCost/EquipmentCost.saga";
import {
  watcherFetchAllFuelCosts,
  watcherFetchOneFuelCosts,
} from "./FuelCost/FuelCost.saga";
import {
  watcherFetchAllOilLubricantCosts,
  watcherFetchOneOilLubricantCosts,
} from "./OilLubricantCost/OilLubricantCost.saga";
import { watcherFetchAllTravelRegistry } from "./TravelRegistry/TravelRegistry.saga";
import { watcherFetchAllResignations } from "./Resignation/Resignation.saga";
import { watcherFetchAllStaffBios } from "./StaffBio/StaffBio.saga";
import {
  watcherFetchAllLoans,
  watcherFetchLoanStatuses,
} from "./Loan/Loan.saga";
import {
  watcherFetchAllLoanExtensions,
  watcherFetchOneLoanExtensions,
} from "./LoanExtension/LoanExtension.saga";
import {
  watcherFetchAllStaffMeals,
  watcherFetchOneStaffMeals,
} from "./StaffMeal/StaffMeal.saga";
import {
  watcherFetchAllFamilyAllotments,
  watcherFetchOneFamilyAllotments,
} from "./FamilyAllotment/FamilyAllotment.saga";
import {
  watcherFetchAllLoanRequests,
  watcherFetchOneLoanRequests,
} from "./LoanRequest/LoanRequest.saga";
import {
  watcherFetchAllEmployeePerformanceForms,
  watcherFetchOneEmployeePerformanceForms,
} from "./EmployeePerformanceForm/EmployeePerformanceForm.saga";
import {
  watcherFetchAllEmployeePerformances,
  watcherFetchOneEmployeePerformances,
} from "./EmployeePerformance/EmployeePerformance.saga";
import {
  watcherFetchAllDailyTasks,
  watcherFetchOneDailyTasks,
} from "./DailyTask/DailyTask.saga";
import {
  watcherFetchAllSelfAttendingUsers,
  watcherFetchOneSelfAttendingUsers,
} from "./SelfAttendingUser/SelfAttendingUser.saga";
import {
  watcherFetchAllNewsAndMotivations,
  watcherFetchOneNewsAndMotivations,
} from "./NewsAndMotivation/NewsAndMotivation.saga";
import {
  watcherFetchAllAutomaticMessages,
  watcherFetchOneAutomaticMessages,
} from "./AutomaticMessage/AutomaticMessage.saga";
import { watcherFetchAllAlerts } from "./Alert/Alert.saga";
import { watcherFetchAllAttendanceLocations } from "./AttendanceLocation/AttendanceLocation.saga";
import { watcherFetchAllAttendanceLocationUsers } from "./AttendanceLocationUser/AttendanceLocationUser.saga";
import { watcherFetchAllDisciplines } from "./Discipline/Discipline.saga";
import { watcherFetchAllDrafts } from "./Draft/Draft.saga";
import { watcherFetchAllEarlyLeaveRequests } from "./EarlyLeaveRequest/EarlyLeaveRequest.saga";
import {
  watcherFetchAllFeedBackForms,
  watcherFetchOneFeedBackForms,
} from "./FeedBackForm/FeedBackForm.saga";
import {
  watcherFetchAllFeedBackResponses,
  watcherFetchOneFeedBackResponses,
} from "./FeedBackResponse/FeedBackResponse.saga";
import { watcherFetchAllHolidays } from "./Holiday/Holiday.saga";
import { watcherFetchAllOvertimeRequest2s } from "./OvertimeRequest2/OvertimeRequest2.saga";
import { watcherFetchAllPartners } from "./Partner/Partner.saga";
import { watcherFetchAllPartnerMessages } from "./PartnerMessage/PartnerMessage.saga";

import { watcherFetchAllPerformancePointPolicies } from "./PerformancePointPolicy/PerformancePointPolicy.saga";
import {
  watcherFetchAllProjectTeams,
  watcherFetchOneProjectTeams,
} from "./ProjectTeam/ProjectTeam.saga";
import { watcherFetchAllPromotions } from "./Promotion/Promotion.saga";
import { watcherFetchAllSMSMessages } from "./SMSMessage/SMSMessage.saga";
import {
  watcherFetchAllTrainings,
  watcherFetchOneTrainings,
} from "./Training/Training.saga";

import { watcherFetchAllSubContractSecuritys } from "./SubContractSecurity/SubContractSecurity.saga";
import {
  watcherFetchAllDailyProductivityReports,
  watcherFetchOneDailyProductivityReports,
} from "./DailyProductivityReport/DailyProductivityReport.saga";
import { watcherFetchOneSparePartCosts } from "./SparePartCost/SparePartCost.saga";

import {
  watcherFetchAllContractorFacilityCosts,
  watcherFetchOneContractorFacilityCosts,
} from "./ContractorFacilityCost/ContractorFacilityCost.saga";
import {
  watcherFetchAllConsultantFacilityCosts,
  watcherFetchOneConsultantFacilityCosts,
} from "./ConsultantFacilityCost/ConsultantFacilityCost.saga";
import {
  watcherFetchAllLaboratoryEquipmentCosts,
  watcherFetchOneLaboratoryEquipmentCosts,
} from "./LaboratoryEquipmentCost/LaboratoryEquipmentCost.saga";
import {
  watcherFetchAllSurveyingEquipmentCosts,
  watcherFetchOneSurveyingEquipmentCosts,
} from "./SurveyingEquipmentCost/SurveyingEquipmentCost.saga";
import {
  watcherFetchAllOtherExpenses,
  watcherFetchOneOtherExpenses,
} from "./OtherExpense/OtherExpense.saga";
import {
  watcherFetchAllPlanExecutions,
  watcherFetchOnePlanExecutions,
} from "./PlanExecution/PlanExecution.saga";
import { watcherFetchOneActivityWaitages } from "./ActivityWaitage/ActivityWaitage.saga";
import { watcherFetchOneProblemEncountereds } from "./ProblemEncountered/ProblemEncountered.saga";
import {
  watcherFetchIncomeExpenseReport,
  watcherFetchProgressSummary,
  watcherFetchBillSummary,
  watcherFetchProjectIncome,
  watcherFetchIncomeExpense,
} from "./IncomeExpense/IncomeExpense.saga";
import { watcherFetchAllProgressSummarys } from "./ProgressSummary/ProgressSummary.saga";
import {
  watcherFetchAllSummaryOfCosts,
  watcherFetchOneSummaryOfCosts,
} from "./SummaryOfCost/SummaryOfCost.saga";
import {
  watcherFetchConsultantFacilityCostReport,
  watcherFetchContractorFacilityCostReport,
  watcherFetchEquipmentCostReport,
  watcherFetchLaboratoryEquipmentCostReport,
  watcherFetchOtherExpenseReport,
  watcherFetchSurveyingEquipmentCostReport,
  watcherFetchSummaryOfCostReport,
  watcherFetchDetailReportOfActivity,
  watcherFetchTotalProjectExpenseReport,
  watcherFetchMonthlyIncomeExpenseReport,
  watcherFetchTotalProjectIncomeReport,
  watcherFetchProgressByBillSummary,
  watcherFetchCriticalIssueReport,
} from "./MonthlySiteReport/MonthlySiteReport.saga";

import {
  watcherFetchAllStaffAttendances,
  watcherFetchAllMonthlyStaffAttendances,
} from "./StaffAttendance/StaffAttendance.saga";

import { watcherFetchAllStaffPlaceOfWorks } from "./StaffPlaceOfWork/StaffPlaceOfWork.saga";
import { watcherFetchAllStaffEducations } from "./StaffEducation/StaffEducation.saga";
import { watcherFetchAllStaffLanguages } from "./StaffLanguage/StaffLanguage.saga";

import {
  watcherFetchAllOperationStandards,
  watcherFetchOneOperationStandards,
} from "./OperationStandard/OperationStandard.saga";

import {
  watcherFetchPagedMaterialMajorCategorys,
  watcherFetchAllMaterialMajorCategorys,
  watcherFetchAllFormMaterialMajorCategorys,
  watcherFetchOneMaterialMajorCategorys,
} from "./MaterialMajorCategory/MaterialMajorCategory.saga";
import {
  watcherFetchPagedMaterialSubCategorys,
  watcherFetchAllMaterialSubCategorys,
  watcherFetchAllFormMaterialSubCategorys,
} from "./MaterialSubCategory/MaterialSubCategory.saga";

import { watcherFetchAllMaterialStartingBalances } from "./MaterialStartingBalance/MaterialStartingBalance.saga";

import {
  watcherFetchAllMaterialLimits,
  watcherFetchOneMaterialLimits,
} from "./MaterialLimit/MaterialLimit.saga";
import { watcherFetchAllOvertimes } from "./Overtime/Overtime.saga";
import { watcherFetchAllPlaceOfWorks } from "./PlaceOfWork/PlaceOfWork.saga";
import {
  watcherFetchAllDislocations,
  watcherFetchOneDislocations,
} from "./Dislocation/Dislocation.saga";

import {
  watcherFetchAllPeridiumRuless,
  watcherFetchOnePeridiumRuless,
} from "./PeridiumRules/PeridiumRules.saga";

import {
  watcherFetchAllPeridiumRequests,
  watcherFetchOnePeridiumRequests,
} from "./PeridiumRequest/PeridiumRequest.saga";

import {
  watcherFetchAllStaffWorkExperiences,
  watcherFetchOneStaffWorkExperiences,
} from "./StaffWorkExperience/StaffWorkExperience.saga";

import {
  watcherFetchAllStaffFamilys,
  watcherFetchOneStaffFamilys,
} from "./StaffFamily/StaffFamily.saga";

import {
  watcherFetchAllStaffDisciplines,
  watcherFetchOneStaffDisciplines,
} from "./StaffDiscipline/StaffDiscipline.saga";

import {
  watcherFetchAllStaffEmergencyContacts,
  watcherFetchOneStaffEmergencyContacts,
} from "./StaffEmergencyContact/StaffEmergencyContact.saga";

import {
  watcherFetchAllStaffTaxInformations,
  watcherFetchOneStaffTaxInformations,
} from "./StaffTaxInformation/StaffTaxInformation.saga";
import {
  watcherFetchAllOffers,
  watcherFetchOneOffers,
} from "./Offer/Offer.saga";
import { watcherFetchAllProjectTypes } from "./ProjectType/ProjectType.saga";

import {
  watcherFetchAllStaffReligions,
  watcherFetchOneStaffReligions,
} from "./StaffReligion/StaffReligion.saga";

import {
  watcherFetchAllStaffTransfers,
  watcherFetchOneStaffTransfers,
} from "./StaffTransfer/StaffTransfer.saga";

import {
  watcherFetchAllSiteAttendances,
  watcherFetchOneSiteAttendances,
} from "./SiteAttendance/SiteAttendance.saga";

import {
  watcherFetchAllStaffSalaryAdjustments,
  watcherFetchOneStaffSalaryAdjustments,
} from "./StaffSalaryAdjustment/StaffSalaryAdjustment.saga";

import {
  watcherFetchAllHRAllowances,
  watcherFetchOneHRAllowances,
} from "./HRAllowance/HRAllowance.saga";
import { watcherFetchAllDailySiteReports } from "./DailySiteReport/DailySiteReport.saga";
import { watcherFetchAllWeeklySiteReports } from "./WeeklySiteReport/WeeklySiteReport.saga";
import { watcherFetchOneMaterialDailySiteReports } from "./MaterialDailySiteReport/MaterialDailySiteReport.saga";
import { watcherFetchOneEquipmentOIDs } from "./EquipmentOID/EquipmentOID.saga";
import {
  watcherFetchAllCheckPaymentVouchers,
  watcherFetchOneCheckPaymentVouchers,
} from "./CheckPaymentVoucher/CheckPaymentVoucher.saga";

import {
  watcherFetchAllBudgets,
  watcherFetchOneBudgets,
} from "./Budget/Budget.saga";
import {
  watcherFetchAllContractAdministrations,
  watcherFetchOneContractAdministrations,
} from "./ContractAdministration/ContractAdministration.saga";

import {
  watcherFetchAllArchives,
  watcherFetchOneArchives,
} from "./Archive/Archive.saga";

import {
  watcherFetchAllIllegalReceipts,
  watcherFetchOneIllegalReceipts,
} from "./IllegalReceipt/IllegalReceipt.saga";
import {
  watcherFetchAllCVBanks,
  watcherFetchOneCVBanks,
} from "./CVBank/CVBank.saga";
import {
  watcherFetchAllPermanentEmployeeAttendances,
  watcherFetchOnePermanentEmployeeAttendances,
} from "./PermanentEmployeeAttendance/PermanentEmployeeAttendance.saga";
import {
  watcherFetchAllEmployeeTrainings,
  watcherFetchOneEmployeeTrainings,
} from "./EmployeeTraining/EmployeeTraining.saga";

import {
  watcherFetchAllPostDateVouchers,
  watcherFetchOnePostDateVouchers,
} from "./PostDateVoucher/PostDateVoucher.saga";
import { watcherFetchAllRGTSs, watcherFetchOneRGTSs } from "./RGTS/RGTS.saga";

import {
  watcherFetchAllTelegraphicTransfers,
  watcherFetchOneTelegraphicTransfers,
} from "./TelegraphicTransfer/TelegraphicTransfer/TelepraphicTransfer.saga";

import {
  watcherFetchAllJournalVouchers,
  watcherFetchOneJournalVouchers,
} from "./JournalVoucher/JournalVoucher.saga";

import {
  watcherFetchAllTransferRequests,
  watcherFetchOneTransferRequests,
} from "./TransferRequest/TransferRequest.saga";

import {
  watcherFetchAllCanteenDeductions,
  watcherFetchOneCanteenDeductions,
} from "./CanteenDeduction/CanteenDeduction.saga";

import {
  watcherFetchAllSocialCommitments,
  watcherFetchOneSocialCommitments,
} from "./SocialCommitment/SocialCommitment.saga";

import { watcherFetchAllPenalitys } from "./Penality/Penality.saga";
import { watcherFetchAllBankContacts } from "./BankContact/BankContact.saga";
import {
  watcherFetchAllStaffPerformances,
  watcherFetchOneStaffPerformances,
} from "./StaffPerformance/StaffPerformance.saga";

import { watcherFetchAllChartsOfAccounts } from "./ChartsOfAccount/ChartsOfAccount.saga";
import {
  watcherFetchAllUserSeens,
  watcherSetUserSeens,
} from "./UserSeen/UserSeen.saga";

import {
  watcherFetchAllTireRequests,
  watcherFetchOneTireRequests,
} from "./TireRequest/TireRequest.saga";

import {
  watcherFetchAllManufacturingOrders,
  watcherFetchOneManufacturingOrders,
  watcherFetchPagedManufacturingOrders,
  watcherFetchReportManufacturingOrders,
} from "./ManufacturingOrder/ManufacturingOrder.saga";

import {
  watcherFetchAllInventories,
  watcherFetchAllQueriedInventories,
  watcherFetchAnalysisInventory,
  watcherUnpagedFetchAnalysisInventory,
  watcherFetchInventoryStockCard,
  watcherFetchOneInventory,
  watcherFetchInventoryStockReport,
} from "./Inventory/Inventory.saga";

import {
  watcherFetchAllStoreCommunication,
  watcherFetchStoreCommunicationReports,
} from "./StoreCommunication/StoreCommunication.saga";

import {
  watcherFetchAllGoodIns,
  watcherFetchOneGoodIns,
  watcherFetchPagedGoodIns,
  watcherFetchReportGoodIns,
} from "./GoodIn/GoodIn.saga";

import {
  watcherFetchAllGoodIssues,
  watcherFetchOneGoodIssues,
  watcherFetchPagedGoodIssues,
  watcherFetchReportGoodIssues,
  watcherFetchVehicleReportGoodIssue,
  watcherFetchFormanReportGoodIssue,
} from "./GoodIssue/GoodIssue.saga";

import {
  watcherFetchAllDispatches,
  watcherFetchOneDispatches,
  watcherFetchPagedDispatches,
  watcherFetchReportDispatches,
} from "./Dispatch/Dispatch.saga";

import {
  watcherFetchAllDamageReturns,
  watcherFetchOneDamageReturns,
  watcherFetchPagedDamageReturns,
  watcherFetchReportDamageReturns,
} from "./DamageReturn/DamageReturn.saga";

import {
  watcherFetchAllTemporaryDeliveryNote,
  watcherFetchOneTemporaryDeliveryNote,
  watcherFetchReportTemporaryDeliveryNote,
} from "./TemporaryDeliveryNote/TemporaryDeliveryNote.saga";

import {
  watcherFetchAllMaterialInspectionRepairs,
  watcherFetchOneMaterialInspectionRepairs,
  watcherFetchMaterialInspectionRepairNumber,
} from "./MaterialInspectionRepair/MaterialInspectionRepair.saga";

import {
  watcherFetchAllDocumentTransmital,
  watcherFetchOneDocumentTransmitals,
} from "./DocumentTransmital/DocumentTransmital.saga";

import {
  watcherFetchAllOutsourcingMaintenances,
  watcherFetchOneOutsourcingMaintenances,
} from "./OutsourcingMaintenance/OutsourcingMaintenance.saga";

import {
  watcherFetchAllFuelReceives,
  watcherFetchFuelReceiveNumber,
  watcherFetchOneFuelReceives,
} from "./FuelReceive/FuelReceive.saga";

import {
  watcherFetchAllFuelAndLubiconRequests,
  watcherFetchOneFuelAndLubiconRequests,
} from "./FuelAndLubiconRequest/FuelAndLubiconRequest.saga";

import {
  watcherFetchAllGatePasss,
  watcherFetchOneGatePasss,
} from "./GatePass/GatePass.saga";

import {
  watcherFetchAllFixedAssetReceived,
  watcherFetchFixedAssetReceivedReports,
  watcherFetchFixedAssetPlateNumberList,
} from "./FixedAssetReceived/FixedAssetReceived.saga";

import {
  watcherFetchAllFixedAssetReturn,
  watcherFetchReportFixedAssetReturn,
} from "./FixedAssetReturn/FixedAssetReturn.saga";

import {
  watcherFetchAllFixedAssetIssues,
  watcherFetchAllDetailedFixedAssetIssues,
  watcherFetchOneFixedAssetIssues,
  watcherFetchPagedFixedAssetIssues,
  watcherFetchReportFixedAssetIssues,
} from "./FixedAssetIssue/FixedAssetIssue.saga";

import {
  watcherFetchAllFixedAssetOut,
  watcherFetchReportFixedAssetOut,
} from "./FixedAssetOut/FixedAssetOut.saga";

import {
  watcherFetchAllFixedAssetIns,
  watcherFetchOneFixedAssetIns,
  watcherFetchPagedFixedAssetIns,
  watcherFetchReportFixedAssetIns,
} from "./FixedAssetIn/FixedAssetIn.saga";

import {
  watcherFetchAllFixedAssetTransfers,
  watcherFetchOneFixedAssetTransfers,
  watcherFetchPagedFixedAssetTransfers,
} from "./FixedAssetTransfer/FixedAssetTransfer.saga";

import {
  watcherFetchAllFuelIssues,
  watcherFetchOneFuelIssues,
  watcherFetchPagedFuelIssues,
  watcherFetchReportFuelIssues,
} from "./FuelIssue/FuelIssue.saga";

import {
  watcherFetchAllFuelReceiving,
  watcherFetchFuelReceivingReports,
} from "./FuelReceiving/FuelReceiving.saga";

import {
  watcherFetchAllProjectFuelRequests,
  watcherFetchOneProjectFuelRequests,
} from "./ProjectFuelRequest/ProjectFuelRequest.saga";

import {
  watcherFetchAllServiceRequests,
  watcherFetchOneServiceRequests,
} from "./ServiceRequest/ServiceRequest.saga";

import {
  watcherFetchAllFinishedProducts,
  watcherFetchOneFinishedProducts,
  watcherFetchPagedFinishedProducts,
  watcherFetchReportFinishedProducts,
} from "./FinishedProduct/FinishedProduct.saga";

import {
  watcherFetchAllSubContractFollowUps,
  watcherFetchOneSubContractFollowUps,
} from "./SubContractFollowUp/SubContractFollowUp.saga";

import {
  watcherFetchAllOperationDailyReports,
  watcherFetchOneOperationDailyReports,
  watcherFetchPagedOperationDailyReports,
  watcherFetchSelectorOperationDailyReports,
} from "./OperationDailyReport/OperationDailyReport.saga";

import {
  watcherFetchAllOwnVehicles,
  watcherFetchOneOwnVehicles,
  watcherFetchPagedOwnVehicles,
  watcherFetchProjectAllOwnVehicles,
} from "./OwnVehicle/OwnVehicle.saga";

import {
  watcherFetchAllMaintenanceCosts,
  watcherFetchOneMaintenanceCosts,
  watcherFetchPagedMaintenanceCosts,
  watcherFetchListMaintenanceCosts,
} from "./MaintenanceCost/MaintenanceCost.saga";

import {
  watcherFetchAllDailyStatusReports,
  watcherFetchReportDailyStatusReports,
} from "./DailyStatusReport/DailyStatusReport.saga";

import {
  watcherFetchAllFuelUtilizationReports,
  watcherFetchFuelUtilizationReports,
} from "./FuelUtilizationReport/FuelUtilizationReport.saga";

import {
  watcherFetchAllTimeUtilizationReports,
  watcherFetchTimeUtilizationReports,
} from "./TimeUtilizationReport/TimeUtilizationReport.saga";

import {
  watcherFetchAllMaterialDeliveries,
  watcherFetchMaterialDeliveries,
} from "./MaterialDelivery/MaterialDelivery.saga";

import {
  watcherFetchAllOperationMonthlyReports,
  watcherFetchOneOperationMonthlyReports,
  watcherFetchPagedOperationMonthlyReports,
} from "./OperationMonthlyReport/OperationMonthlyReport.saga";

import {
  watcherFetchAllProjectPermanentAttendances,
  watcherFetchOneProjectPermanentAttendances,
} from "./ProjectPermanentAttendance/ProjectPermanentAttendance.saga";

import {
  watcherFetchAllWorkExperienceSummarys,
  watcherFetchOneWorkExperienceSummarys,
} from "./WorkExperienceSummary/WorkExperienceSummary.saga";

import {
  watcherFetchAllUserRoles,
  watcherFetchOneUserRoles,
  watcherFetchPagedUserRoles,
} from "./UserRole/UserRole.saga";
import {
  watcherFetchAllDiemPolicys,
  watcherFetchOneDiemPolicys,
  watcherFetchPagedDiemPolicys,
} from "./DiemPolicy/DiemPolicy.saga";

import {
  watcherFetchAllPaymentRequisitions,
  watcherFetchOnePaymentRequisition
} from "./PaymentRequisition/PaymentRequisition.saga";

import {
  watcherFetchAllTemporaryAttendances,
  watcherFetchOneTemporaryAttendance
} from "./TemporaryAttendance/TemporaryAttendance.saga";

import {
  watcherFetchAllExternalRepairOrders,
  watcherFetchOneExternalRepairOrders
} from "./ExternalRepairOrder/ExternalRepairOrder.saga";

export default function* RootSaga() {
  yield all([
    call(watcherFetchAllBankContacts),
    call(watcherPayrollByDate),
    call(watcherStaffPayrollReport),
    call(watcherPayrollReport),
    call(watcherCalculatePayroll),
    call(watcherFetchAllPayrolls),
    call(watcherFetchAllPenalitys),
    call(watcherFetchOneEquipmentOIDs),
    call(watcherFetchOneMaterialDailySiteReports),
    call(watcherFetchAllWeeklySiteReports),
    call(watcherFetchAllProjectTypes),
    call(watcherFetchAllPlaceOfWorks),
    call(watcherFetchAllOvertimes),
    call(watcherFetchAllOffers),
    call(watcherFetchOneOffers),
    call(watcherFetchAllStaffPlaceOfWorks),
    call(watcherFetchAllStaffEducations),
    call(watcherFetchAllStaffLanguages),

    call(watcherFetchAllStaffAttendances),
    call(watcherFetchAllMonthlyStaffAttendances),

    call(watcherFetchAllSummaryOfCosts),
    call(watcherFetchOneSummaryOfCosts),
    call(watcherFetchBillSummary),
    call(watcherFetchProjectIncome),
    call(watcherFetchAllProjects),
    call(watcherFetchOneProjects),
    call(watcherFetchAllListProjects),
    call(watcherFetchAllProjectsDetail),
    call(watcherFetchAllContractors),
    call(watcherFetchAllConsultants),
    call(watcherFetchAllTakeoffs),
    call(watcherFetchAllRebar),
    call(watcherFetchAllBoq),
    call(watcherFetchOneBoq),
    call(watcherFetchPagedBoq),
    call(watcherFetchSheetNames),
    call(watcherFetchBoqTotal),
    call(watcherFetchAllBoqPlans),
    call(watcherFetchAllBoqActivityPlans),
    call(watcherFetchAllSubContractPlans),
    call(watcherFetchAllMaterials),
    call(watcherFetchAllFormMaterials),
    call(watcherFetchPagedMaterials),
    call(watcherFetchMaterialNumber),
    call(watcherFetchSupplies),
    call(watcherFetchMaterialInventories),
    call(watcherFetchMaterialTransfer),
    call(watcherFetchAllUsage),
    call(watcherFetchEquipmentInventories),
    call(watcherFetchEquipmentTransfer),
    call(watcherFetchAllEquipmentUsage),
    call(watcherFetchAllLabourUsage),
    call(watcherFetchAllLabour),
    call(watcherFetchAllEquipmentPlans),
    call(watcherFetchAllEquipmentUsagePlans),
    call(watcherFetchAllMaterialPlans),
    call(watcherFetchAllLabourPlans),
    call(watcherFetchDetailBoq),
    call(watcherFetchAllDepartments),
    call(watcherFetchAllStaffs),
    call(watcherFetchAllStaffDetails),
    call(watcherFetchAllStaffPlans),
    call(watcherFetchAllAccounts),
    call(watcherFetchAllCustomers),
    call(watcherFetchAllServices),
    call(watcherFetchAllUnitBreakdownsPost),
    call(watcherFetchAllUnitBreakdownsPre),
    call(watcherFetchOneUnitBreakdownsPre),
    call(watcherFetchAllVariation),
    call(watcherFetchBoqVariation),
    call(watcherFetchAllSubContracts),
    call(watcherFetchOneSubContracts),
    call(watcherFetchAllExpense),
    call(watcherFetchAllPayment),
    call(watcherFetchAllInvoice),
    call(watcherFetchAllPaymentRetention),
    call(watcherFetchOneAccounts),
    call(watcherFetchAllDocuments),
    call(watcherFetchAllProjectStaffs),
    call(watcherFetchAllManpowerOvertime),
    call(watcherFetchAllSchedules),
    call(watcherFetchAllPayrolls),
    call(watcherFetchAllEmployeeExtras),
    call(watcherFetchOneUser),
    call(watcherFetchAllAttendances),
    call(watcherFetchAllEmployeeRequests),
    call(watcherFetchAllMaterialRequisitions),
    call(watcherFetchListMaterialRequisitions),
    call(watcherFetchAllPurchaseRequisitions),
    call(watcherFetchPurchaseRequisitionTracking),
    call(watcherFetchAllPurchaseOrders),
    call(watcherFetchOnePurchaseOrders),
    call(watcherFetchAllPurchaseBillings),
    call(watcherFetchAllGoodReceived),
    call(watcherFetchOneGoodReceived),
    call(watcherFetchPagedGoodReceived),
    call(watcherFetchGoodReceivedReports),
    call(watcherFetchAllRenters),
    call(watcherFetchAllGoodOut),
    call(watcherFetchReportGoodOut),
    call(watcherFetchAllGoodReturn),
    call(watcherFetchReportGoodReturn),
    call(watcherFetchOneGoodReturn),
    call(watcherFetchEmployeeHistory),
    call(watcherFetchAllDocumentWorkOrders),
    call(watcherFetchAllEquipmentWO),
    call(watcherFetchAllHRPolicy),
    call(watcherFetchAllAbsences),
    call(watcherFetchOneStaffs),
    call(watcherFetchAllEmployeeDocuments),
    call(watcherFetchAllClients),
    call(watcherFetchAllAllowances),
    call(watcherFetchAllAllowancePolicy),
    call(watcherFetchAllPreProjects),
    call(watcherFetchOnePreProjects),
    call(watcherFetchAllStandard),
    call(watcherFetchOneStandard),
    call(watcherFetchAllUser),
    call(watcherFetchOneUser),
    call(watcherFetchFeatureUser),
    call(watcherFetchAllLog),
    call(watcherFetchOneLog),
    call(watcherFetchAllEmployeeAccommodationPlan),
    call(watcherFetchOneEmployeeAccommodationPlan),
    call(watcherFetchAllBoqStandard),
    call(watcherFetchOneBoqStandard),
    call(watcherFetchAllResources),
    call(watcherFetchOneResources),
    call(watcherFetchPIDVariation),
    call(watcherFetchAllSiteDiary),
    call(watcherFetchAllWeekReport),
    call(watcherFetchAllMasterSchedules),
    call(watcherFetchOneMasterSchedules),
    call(watcherFetchAllReviewForm),
    call(watcherFetchAllEmployeeReviews),
    call(watcherFetchOneEmployeeReviews),
    call(watcherFetchAllBonuses),
    call(watcherFetchAllVacancies),
    call(watcherFetchOneVacancy),
    call(watcherFetchAllApplications),
    call(watcherFetchAllMedias),
    call(watcherFetchAllSHEs),
    call(watcherFetchAllSharedDocuments),
    call(watcherFetchAllBankAccounts),
    call(watcherFetchOneBankAccounts),
    call(watcherFetchAllAccountTransactions),
    call(watcherFetchAllRentContracts),
    call(watcherFetchAllReceivables),
    call(watcherFetchOneReceivables),
    call(watcherFetchAllAttachments),
    call(watcherFetchMaterialInventoriesAnalysis),
    call(watcherFetchAllPettyCashes),
    call(watcherFetchOnePettyCashes),
    call(watcherFetchAllReplenishments),
    call(watcherFetchOneReplenishments),
    call(watcherFetchAllReplenishmentTransactions),
    call(watcherFetchOneReplenishmentTransactions),
    call(watcherFetchAllReplenishmentRequests),
    call(watcherFetchOneReplenishmentRequests),
    call(watcherFetchAllVariationFiles),
    call(watcherFetchOneVariationFiles),
    call(watcherFetchAllCRVPayments),
    call(watcherFetchOneCRVPayments),
    call(watcherFetchAllPostChecks),
    call(watcherFetchOnePostChecks),
    // call(watcherFetchAllMonthlyReports),
    // call(watcherFetchOneMonthlyReports),
    call(watcherFetchAllDailyReports),
    call(watcherFetchAllMaterialUsages),
    call(watcherFetchOneMaterialUsages),
    call(watcherFetchAllPayables),
    call(watcherFetchAllBuildings),
    call(watcherFetchAllApartments),
    call(watcherFetchAllApartmentExpenses),
    call(watcherFetchAllContractSaless),
    call(watcherFetchAllEstimatedBuildingCosts),
    call(watcherFetchOneMaterialRequisitions),
    call(watcherFetchPreviousMaterialRequisitionItems),
    call(watcherFetchPagedMaterialRequisitions),
    call(watcherFetchOnePurchaseRequisitions),
    call(watcherFetchReportPurchaseRequisitions),
    call(watcherFetchAllProformas),
    call(watcherFetchOneProformas),
    call(watcherFetchTrendAnalysis),
    call(watcherFetchAllCurrencies),
    call(watcherFetchAllProcurementPlans),
    call(watcherFetchOneProcurementPlans),
    call(watcherFetchAllPaymentSummarys),
    call(watcherFetchOnePaymentSummarys),
    call(watcherFetchAllEquipmentRequests),
    call(watcherFetchOneEquipmentRequests),
    call(watcherFetchAllEquipmentProductivitys),
    call(watcherFetchOneEquipmentProductivitys),
    call(watcherFetchAllJustifications),
    call(watcherFetchOneJustifications),
    call(watcherFetchAllBriefings),
    call(watcherFetchOneBriefings),
    call(watcherFetchAllCriticalIssues),
    call(watcherFetchOneCriticalIssues),
    call(watcherFetchAllBudgetRequests),
    call(watcherFetchOneBudgetRequests),
    call(watcherFetchAllBudgetShiftingRequests),
    call(watcherFetchOneBudgetShiftingRequests),
    call(watcherFetchAllBudgetUtilizations),
    call(watcherFetchOneBudgetUtilizations),
    call(watcherFetchAllFuelRequests),
    call(watcherFetchOneFuelRequests),
    call(watcherFetchAllFuelUtilizations),
    call(watcherFetchOneFuelUtilizations),
    call(watcherFetchAllFinanceDailyReports),
    call(watcherFetchAllPaymentFollowUps),
    call(watcherFetchOnePaymentFollowUps),
    call(watcherFetchAllMonthlyProgressReports),
    call(watcherFetchOneMonthlyProgressReports),
    call(watcherFetchAllSubContractorPerformances),
    call(watcherFetchOneSubContractorPerformances),
    call(watcherFetchAllStores),
    call(watcherFetchOneStores),
    call(watcherFetchAllBankLoans),
    call(watcherFetchOneBankLoans),
    call(watcherFetchAllBankLoanPaymentSchedules),
    call(watcherFetchOneBankLoanPaymentSchedules),
    call(watcherFetchAllProformaComparisons),
    call(watcherFetchOneProformaComparisons),
    call(watcherFetchAllSubContractorPayments),
    call(watcherFetchAllWeeklyProgressSummarys),
    call(watcherFetchFinanceDailyReports),
    call(watcherFetchAllUncollectedIpcs),
    call(watcherFetchAllProjectReport),
    call(watcherFetchAllProjectLists),
    call(watcherFetchAllMasterLists),
    call(watcherFetchOneMasterLists),
    call(watcherFetchAllFuelRequestVouchers),
    call(watcherFetchAllFuelRecievingVouchers),
    call(watcherFetchAllFuelIssueVouchers),
    call(watcherFetchAllPayableV2s),
    call(watcherFetchOnePayableV2s),
    call(watcherFetchAllPayableV2Payments),
    call(watcherFetchOnePayableV2Payments),
    call(watcherFetchAllTasks),
    call(watcherFetchOneTasks),
    call(watcherFetchAllBiddingSchedules),
    call(watcherFetchAllUploadDocuments),
    call(watcherFetchOneUploadDocuments),
    call(watcherFetchUploadDocumentReferenceNumber),
    call(watcherFetchAllProjectDailyReport),
    call(watcherFetchAllMikadaDailyReports),
    call(watcherFetchAllBriefingForms),
    call(watcherFetchAllMikadaWeeklyProgresss),
    call(watcherFetchAllMikadaWeeklyEquipmentReports),
    call(watcherFetchAllMikadaWeeklyLabourReports),
    call(watcherFetchAllMikadaWeeklyMaterialReports),
    call(watcherFetchAllMikadaWeeklySubContractReports),
    call(watcherFetchOneWorkEvaluations),
    call(watcherFetchAllMikadaMonthlyReports),
    call(watcherFetchAllMikadaAnnualReports),
    call(watcherFetchAllBillSummarys),
    call(watcherFetchOneBillSummarys),
    call(watcherFetchAllAdditionBoqs),
    call(watcherFetchOneAdditionBoqs),
    call(watcherFetchAllOmissionBoqs),
    call(watcherFetchOneOmissionBoqs),
    call(watcherFetchAllSummarys),
    call(watcherFetchOneSummarys),
    call(watcherFetchAllSupplementaryBoqs),
    call(watcherFetchOneSupplementaryBoqs),
    call(watcherFetchAllSiteVisits),
    call(watcherFetchAllSubContractRegistrys),
    call(watcherFetchOneSubContractRegistrys),
    call(watcherFetchAllSubContractPaymentUpdates),
    call(watcherFetchOneSubContractPaymentUpdates),
    call(watcherFetchAllBudgetPlannings),
    call(watcherFetchAllSubContractAgreements),
    call(watcherFetchAllBudgetPlanningItems),
    call(watcherFetchAllBoqItemGroups),
    call(watcherFetchAllMannings),
    call(watcherFetchAllHRRequests),
    call(watcherFetchAllApplicantRegistrys),
    call(watcherFetchOneApplicantRegistrys),
    call(watcherFetchAllAssessments),
    call(watcherFetchOneApplicantRegistryItems),
    call(watcherFetchAllComments),
    call(watcherFetchOneComments),
    call(watcherFetchAllMaintenanceRequests),
    call(watcherFetchAllMaintenanceVouchers),
    call(watcherFetchMaintenanceVoucherNumber),
    call(watcherFetchAllAccidentFollowUps),
    call(watcherFetchAllDailyWorkStatuses),
    call(watcherFetchOneDailyWorkStatuses),
    call(watcherFetchAllInsurances),
    call(watcherFetchAllStoreRequisitions),
    call(watcherFetchAllStoreRequisitionFollowUps),
    call(watcherFetchAllServiceVouchers),
    call(watcherFetchAllProjectAllowances),
    call(watcherFetchAllProbationReviews),
    call(watcherFetchAllGrades),
    call(watcherFetchAllScales),
    call(watcherFetchAllScaleItems),
    call(watcherFetchOneDepartmentRelationShips),
    call(watcherFetchAllDepartmentRelationShips),
    call(watcherFetchOneAllowableScales),
    call(watcherFetchAllAllowableScales),
    call(watcherFetchOneEmployeeAllowances),
    call(watcherFetchAllEmployeeAllowances),
    call(watcherFetchAllManningCategorys),
    call(watcherFetchAllDetailReportActivitys),
    call(watcherFetchOneDetailReportActivitys),
    call(watcherFetchAllEquipmentCosts),
    call(watcherFetchOneEquipmentCosts),
    call(watcherFetchAllFuelCosts),
    call(watcherFetchOneFuelCosts),
    call(watcherFetchAllOilLubricantCosts),
    call(watcherFetchOneOilLubricantCosts),

    // ===================================================== \\

    call(watcherFetchEmployeeLeaveRequestState),
    call(watcherFetchEmployeeHealthBenefitRequestState),
    call(watcherFetchAllSharedDocumentUsers),
    call(watcherFetchAllTravelRegistry),
    call(watcherFetchAllResignations),
    call(watcherFetchAllStaffBios),
    call(watcherFetchAllLoans),
    call(watcherFetchLoanStatuses),
    call(watcherFetchAllLoanExtensions),
    call(watcherFetchOneLoanExtensions),
    call(watcherFetchAllStaffMeals),
    call(watcherFetchOneStaffMeals),
    call(watcherFetchAllFamilyAllotments),
    call(watcherFetchOneFamilyAllotments),
    call(watcherFetchAllLoanRequests),
    call(watcherFetchOneLoanRequests),
    call(watcherFetchAllEmployeePerformanceForms),
    call(watcherFetchOneEmployeePerformanceForms),
    call(watcherFetchAllEmployeePerformances),
    call(watcherFetchOneEmployeePerformances),
    call(watcherFetchAllDailyTasks),
    call(watcherFetchOneDailyTasks),
    call(watcherFetchAllSelfAttendingUsers),
    call(watcherFetchOneSelfAttendingUsers),
    call(watcherFetchAllNewsAndMotivations),
    call(watcherFetchOneNewsAndMotivations),
    call(watcherFetchAllAutomaticMessages),
    call(watcherFetchOneAutomaticMessages),
    call(watcherFetchAllTrainings),
    call(watcherFetchOneTrainings),
    call(watcherFetchAllProjectTeams),
    call(watcherFetchOneProjectTeams),
    call(watcherFetchAllAttendanceLocations),
    call(watcherFetchAllAttendanceLocationUsers),
    call(watcherFetchAllAlerts),
    call(watcherFetchAllDisciplines),
    call(watcherFetchAllSMSMessages),
    call(watcherFetchAllEarlyLeaveRequests),
    call(watcherFetchAllDrafts),
    call(watcherFetchAllPerformancePointPolicies),
    call(watcherFetchAllPromotions),
    call(watcherFetchAllPartners),
    call(watcherFetchAllOvertimeRequest2s),
    call(watcherFetchAllPartnerMessages),
    call(watcherFetchAllHolidays),
    call(watcherFetchAllFeedBackForms),
    call(watcherFetchOneFeedBackForms),
    call(watcherFetchAllFeedBackResponses),
    call(watcherFetchOneFeedBackResponses),
    call(watcherFetchAllSubContractSecuritys),
    call(watcherFetchAllDailyProductivityReports),
    call(watcherFetchOneDailyProductivityReports),
    call(watcherFetchOneSparePartCosts),
    call(watcherFetchAllContractorFacilityCosts),
    call(watcherFetchOneContractorFacilityCosts),
    call(watcherFetchAllConsultantFacilityCosts),
    call(watcherFetchOneConsultantFacilityCosts),

    call(watcherFetchAllLaboratoryEquipmentCosts),
    call(watcherFetchOneLaboratoryEquipmentCosts),
    call(watcherFetchAllSurveyingEquipmentCosts),
    call(watcherFetchOneSurveyingEquipmentCosts),
    call(watcherFetchAllOtherExpenses),
    call(watcherFetchOneOtherExpenses),
    call(watcherFetchAllPlanExecutions),
    call(watcherFetchOnePlanExecutions),
    call(watcherFetchOneActivityWaitages),
    call(watcherFetchOneProblemEncountereds),
    call(watcherFetchIncomeExpenseReport),
    call(watcherFetchProgressSummary),
    call(watcherFetchAllProgressSummarys),
    call(watcherFetchIncomeExpense),
    call(watcherFetchConsultantFacilityCostReport),
    call(watcherFetchContractorFacilityCostReport),
    call(watcherFetchEquipmentCostReport),
    call(watcherFetchLaboratoryEquipmentCostReport),
    call(watcherFetchOtherExpenseReport),
    call(watcherFetchSurveyingEquipmentCostReport),
    call(watcherFetchSummaryOfCostReport),
    call(watcherFetchDetailReportOfActivity),
    call(watcherFetchTotalProjectExpenseReport),
    call(watcherFetchMonthlyIncomeExpenseReport),
    call(watcherFetchTotalProjectIncomeReport),
    call(watcherFetchProgressByBillSummary),
    call(watcherFetchAllOperationStandards),
    call(watcherFetchOneOperationStandards),
    call(watcherFetchPagedMaterialMajorCategorys),
    call(watcherFetchPagedMaterialSubCategorys),
    call(watcherFetchAllMaterialMajorCategorys),
    call(watcherFetchAllFormMaterialMajorCategorys),
    call(watcherFetchOneMaterialMajorCategorys),
    call(watcherFetchAllMaterialSubCategorys),
    call(watcherFetchAllFormMaterialSubCategorys),
    call(watcherFetchAllMaterialStartingBalances),
    call(watcherFetchAllMaterialLimits),
    call(watcherFetchOneMaterialLimits),
    call(watcherFetchAllDislocations),
    call(watcherFetchOneDislocations),

    call(watcherFetchAllDiemPolicys),
    call(watcherFetchPagedDiemPolicys),
    call(watcherFetchOneDiemPolicys),

    call(watcherFetchCriticalIssueReport),
    call(watcherFetchAllPeridiumRuless),
    call(watcherFetchOnePeridiumRuless),

    call(watcherFetchAllPeridiumRequests),
    call(watcherFetchOnePeridiumRequests),
    call(watcherFetchAllStaffWorkExperiences),
    call(watcherFetchOneStaffWorkExperiences),

    call(watcherFetchAllStaffFamilys),
    call(watcherFetchOneStaffFamilys),
    call(watcherFetchAllStaffDisciplines),
    call(watcherFetchOneStaffDisciplines),
    call(watcherFetchAllStaffEmergencyContacts),
    call(watcherFetchOneStaffEmergencyContacts),
    call(watcherFetchAllStaffTaxInformations),
    call(watcherFetchOneStaffTaxInformations),
    call(watcherFetchAllStaffReligions),
    call(watcherFetchOneStaffReligions),
    call(watcherFetchAllStaffTransfers),
    call(watcherFetchOneStaffTransfers),
    call(watcherFetchAllSiteAttendances),
    call(watcherFetchOneSiteAttendances),
    call(watcherFetchAllStaffSalaryAdjustments),
    call(watcherFetchOneStaffSalaryAdjustments),
    call(watcherFetchAllHRAllowances),
    call(watcherFetchOneHRAllowances),
    call(watcherFetchAllDailySiteReports),
    call(watcherFetchAllCheckPaymentVouchers),
    call(watcherFetchOneCheckPaymentVouchers),
    call(watcherFetchAllBudgets),
    call(watcherFetchOneBudgets),
    call(watcherFetchAllArchives),
    call(watcherFetchOneArchives),
    call(watcherFetchAllContractAdministrations),
    call(watcherFetchOneContractAdministrations),
    call(watcherFetchAllIllegalReceipts),
    call(watcherFetchOneIllegalReceipts),
    call(watcherFetchAllCVBanks),
    call(watcherFetchOneCVBanks),
    call(watcherFetchAllPermanentEmployeeAttendances),
    call(watcherFetchOnePermanentEmployeeAttendances),
    call(watcherFetchAllEmployeeTrainings),
    call(watcherFetchOneEmployeeTrainings),
    call(watcherFetchAllPostDateVouchers),
    call(watcherFetchOnePostDateVouchers),

    call(watcherFetchAllTelegraphicTransfers),
    call(watcherFetchOneTelegraphicTransfers),
    call(watcherFetchAllRGTSs),
    call(watcherFetchOneRGTSs),

    call(watcherFetchAllJournalVouchers),
    call(watcherFetchOneJournalVouchers),

    call(watcherFetchAllTransferRequests),
    call(watcherFetchOneTransferRequests),

    call(watcherFetchAllCanteenDeductions),
    call(watcherFetchOneCanteenDeductions),

    call(watcherFetchAllSocialCommitments),
    call(watcherFetchOneSocialCommitments),

    call(watcherFetchAllStaffPerformances),
    call(watcherFetchOneStaffPerformances),
    call(watcherFetchLeaveReport),
    call(watcherFetchAllChartsOfAccounts),

    call(watcherFetchAllUserSeens),
    call(watcherSetUserSeens),

    call(watcherFetchAllTireRequests),
    call(watcherFetchOneTireRequests),

    call(watcherFetchAllManufacturingOrders),
    call(watcherFetchOneManufacturingOrders),
    call(watcherFetchPagedManufacturingOrders),
    call(watcherFetchReportManufacturingOrders),

    call(watcherFetchAllInventories),
    call(watcherFetchAllQueriedInventories),
    call(watcherFetchAnalysisInventory),
    call(watcherUnpagedFetchAnalysisInventory),
    call(watcherFetchInventoryStockCard),
    call(watcherFetchOneInventory),
    call(watcherFetchInventoryStockReport),

    call(watcherFetchAllStoreCommunication),
    call(watcherFetchStoreCommunicationReports),

    call(watcherFetchAllGoodIns),
    call(watcherFetchOneGoodIns),
    call(watcherFetchPagedGoodIns),
    call(watcherFetchReportGoodIns),

    call(watcherFetchAllGoodIssues),
    call(watcherFetchOneGoodIssues),
    call(watcherFetchPagedGoodIssues),
    call(watcherFetchReportGoodIssues),
    call(watcherFetchVehicleReportGoodIssue),
    call(watcherFetchFormanReportGoodIssue),

    call(watcherFetchAllDispatches),
    call(watcherFetchOneDispatches),
    call(watcherFetchPagedDispatches),
    call(watcherFetchReportDispatches),

    call(watcherFetchAllDamageReturns),
    call(watcherFetchOneDamageReturns),
    call(watcherFetchPagedDamageReturns),
    call(watcherFetchReportDamageReturns),

    call(watcherFetchAllTemporaryDeliveryNote),
    call(watcherFetchOneTemporaryDeliveryNote),
    call(watcherFetchReportTemporaryDeliveryNote),

    call(watcherFetchAllMaterialInspectionRepairs),
    call(watcherFetchOneMaterialInspectionRepairs),
    call(watcherFetchMaterialInspectionRepairNumber),

    call(watcherFetchAllDocumentTransmital),
    call(watcherFetchOneDocumentTransmitals),

    call(watcherFetchAllOutsourcingMaintenances),
    call(watcherFetchOneOutsourcingMaintenances),

    call(watcherFetchAllFuelReceives),
    call(watcherFetchFuelReceiveNumber),
    call(watcherFetchOneFuelReceives),

    call(watcherFetchAllFuelAndLubiconRequests),
    call(watcherFetchOneFuelAndLubiconRequests),

    call(watcherFetchAllGatePasss),
    call(watcherFetchOneGatePasss),

    call(watcherFetchAllFixedAssetReceived),
    call(watcherFetchFixedAssetReceivedReports),
    call(watcherFetchFixedAssetPlateNumberList),

    call(watcherFetchAllFixedAssetReturn),
    call(watcherFetchReportFixedAssetReturn),

    call(watcherFetchAllFixedAssetIssues),
    call(watcherFetchAllDetailedFixedAssetIssues),
    call(watcherFetchOneFixedAssetIssues),
    call(watcherFetchPagedFixedAssetIssues),
    call(watcherFetchReportFixedAssetIssues),

    call(watcherFetchAllFixedAssetOut),
    call(watcherFetchReportFixedAssetOut),

    call(watcherFetchAllFixedAssetIns),
    call(watcherFetchOneFixedAssetIns),
    call(watcherFetchPagedFixedAssetIns),
    call(watcherFetchReportFixedAssetIns),

    call(watcherFetchAllFixedAssetTransfers),
    call(watcherFetchOneFixedAssetTransfers),
    call(watcherFetchPagedFixedAssetTransfers),

    call(watcherFetchAllFuelIssues),
    call(watcherFetchOneFuelIssues),
    call(watcherFetchPagedFuelIssues),
    call(watcherFetchReportFuelIssues),

    call(watcherFetchAllFuelReceiving),
    call(watcherFetchFuelReceivingReports),

    call(watcherFetchAllProjectFuelRequests),
    call(watcherFetchOneProjectFuelRequests),

    call(watcherFetchAllServiceRequests),
    call(watcherFetchOneServiceRequests),

    call(watcherFetchAllFinishedProducts),
    call(watcherFetchOneFinishedProducts),
    call(watcherFetchPagedFinishedProducts),
    call(watcherFetchReportFinishedProducts),

    call(watcherFetchAllSubContractFollowUps),
    call(watcherFetchOneSubContractFollowUps),

    call(watcherFetchAllOperationDailyReports),
    call(watcherFetchOneOperationDailyReports),
    call(watcherFetchPagedOperationDailyReports),
    call(watcherFetchSelectorOperationDailyReports),

    call(watcherFetchAllOwnVehicles),
    call(watcherFetchOneOwnVehicles),
    call(watcherFetchPagedOwnVehicles),
    call(watcherFetchProjectAllOwnVehicles),

    call(watcherFetchAllMaintenanceCosts),
    call(watcherFetchOneMaintenanceCosts),
    call(watcherFetchPagedMaintenanceCosts),
    call(watcherFetchListMaintenanceCosts),

    call(watcherFetchReportDailyStatusReports),
    call(watcherFetchAllDailyStatusReports),

    call(watcherFetchAllFuelUtilizationReports),
    call(watcherFetchFuelUtilizationReports),

    call(watcherFetchAllTimeUtilizationReports),
    call(watcherFetchTimeUtilizationReports),

    call(watcherFetchAllMaterialDeliveries),
    call(watcherFetchMaterialDeliveries),

    call(watcherFetchAllOperationMonthlyReports),
    call(watcherFetchOneOperationMonthlyReports),
    call(watcherFetchPagedOperationMonthlyReports),

    call(watcherFetchAllProjectPermanentAttendances),
    call(watcherFetchOneProjectPermanentAttendances),

    call(watcherFetchAllWorkExperienceSummarys),
    call(watcherFetchOneWorkExperienceSummarys),

    call(watcherFetchAllUserRoles),
    call(watcherFetchOneUserRoles),
    call(watcherFetchPagedUserRoles),

    call(watcherFetchAllPaymentRequisitions),
    call(watcherFetchOnePaymentRequisition),

    call(watcherFetchAllEquipmentLists),
    call(watcherFetchOneEquipmentLists),

    call(watcherFetchAllVehicleLists),
    call(watcherFetchOneVehicleLists),
    call(watcherFetchAllTemporaryAttendances),
    call(watcherFetchOneTemporaryAttendance),

    call(watcherFetchAllExternalRepairOrders),
    call(watcherFetchOneExternalRepairOrders),
  ]);
}
