import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { GatePassActionTypes } from "./GatePass.type";

export function* fetchAllGatePasss(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/gate-pass`);
    yield put({
      type: GatePassActionTypes.FETCH_ALL_GATE_PASS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GatePassActionTypes.FETCH_ALL_GATE_PASS_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneGatePasss(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/gate-pass/${action.payload}`
    );
    yield put({
      type: GatePassActionTypes.FETCH_ONE_GATE_PASS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GatePassActionTypes.FETCH_ONE_GATE_PASS_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllGatePasss() {
  yield takeLatest(GatePassActionTypes.FETCH_ALL_GATE_PASS, fetchAllGatePasss);
}

export function* watcherFetchOneGatePasss() {
  yield takeLatest(GatePassActionTypes.FETCH_ONE_GATE_PASS, fetchOneGatePasss);
}
