import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SubContractFollowUpActionTypes } from "./SubContractFollowUp.type";

export function* fetchAllSubContractFollowUps(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/sub-contract-followup?${query}`
    );
    yield put({
      type: SubContractFollowUpActionTypes.FETCH_ALL_SUBCONTRACT_FOLLOWUP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubContractFollowUpActionTypes.FETCH_ALL_SUBCONTRACT_FOLLOWUP_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedSubContractFollowUps(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/sub-contract-followup?${query}`
    );
    yield put({
      type: SubContractFollowUpActionTypes.FETCH_PAGED_SUBCONTRACT_FOLLOWUP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubContractFollowUpActionTypes.FETCH_PAGED_SUBCONTRACT_FOLLOWUP_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSubContractFollowUps(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/sub-contract-followup/${action.payload}`
    );
    yield put({
      type: SubContractFollowUpActionTypes.FETCH_ONE_SUBCONTRACT_FOLLOWUP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubContractFollowUpActionTypes.FETCH_ONE_SUBCONTRACT_FOLLOWUP_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSubContractFollowUps() {
  yield takeLatest(
    SubContractFollowUpActionTypes.FETCH_ALL_SUBCONTRACT_FOLLOWUP,
    fetchAllSubContractFollowUps
  );
}

export function* watcherFetchPagedSubContractFollowUps() {
  yield takeLatest(
    SubContractFollowUpActionTypes.FETCH_PAGED_SUBCONTRACT_FOLLOWUP,
    fetchPagedSubContractFollowUps
  );
}

export function* watcherFetchOneSubContractFollowUps() {
  yield takeLatest(
    SubContractFollowUpActionTypes.FETCH_ONE_SUBCONTRACT_FOLLOWUP,
    fetchOneSubContractFollowUps
  );
}
