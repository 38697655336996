import { ApiCallState } from "../Utils";

export type SummaryOfCost = {
  id: number;
  start_date: string;
  end_date: string;
  project_id: number;
  user_id: number;
  summary_of_cost_items: SummaryOfCostItem[];
};

export type SummaryOfCostItem = {
  id: number;
  type: string;
  actual_previous_amount: number;
  actual_this_period_amount: number;
  actual_to_date_amount: number;
  description: string;
  remark: string;
  this_period_plan: number;
  summary_of_cost_id: number;
};

export type SummaryOfCostStateTypes = {
  fetchAll: ApiCallState<SummaryOfCost[]>;
  fetchOne: ApiCallState<SummaryOfCost | {}>;
};

export const SummaryOfCostActionTypes = {
  FETCH_ALL_SUMMARY_OF_COST: "FETCH_ALL_SUMMARY_OF_COST",
  FETCH_ALL_SUMMARY_OF_COST_RESET: "FETCH_ALL_SUMMARY_OF_COST_RESET",
  FETCH_ALL_SUMMARY_OF_COST_FAILURE: "FETCH_ALL_SUMMARY_OF_COST_FAILURE",
  FETCH_ALL_SUMMARY_OF_COST_SUCCESS: "FETCH_ALL_SUMMARY_OF_COST_SUCCESS",

  FETCH_ONE_SUMMARY_OF_COST: "FETCH_ONE_SUMMARY_OF_COST",
  FETCH_ONE_SUMMARY_OF_COST_RESET: "FETCH_ONE_SUMMARY_OF_COST_RESET",
  FETCH_ONE_SUMMARY_OF_COST_FAILURE: "FETCH_ONE_SUMMARY_OF_COST_FAILURE",
  FETCH_ONE_SUMMARY_OF_COST_SUCCESS: "FETCH_ONE_SUMMARY_OF_COST_SUCCESS",
};
