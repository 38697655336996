import { Boq } from "../Boq/Boq.type";
import { ApiCallState } from "../Utils";

export type Takeoff = {
  id: number | null;
  key: number | null;
  project_id: number | null;
  boq_id: number | null;
  item_no: string;
  timesing: number;
  length: number;
  width: number;
  height: number;
  total: number;
  approval: number;
  sheet_name: string;
  description: string;
  remark: string;
  pid: number;
  unit: string;
  date: string;
  deduction: boolean;
  boq: Boq | null;
  type: "sub-contract" | "progress" | "variation";
};

export type TakeoffStateTypes = {
  fetchAll: ApiCallState<Takeoff[]>;
};

export const TakeoffActionTypes = {
  FETCH_ALL_TAKEOFF: "FETCH_ALL_TAKEOFF",
  FETCH_ALL_TAKEOFF_RESET: "FETCH_ALL_TAKEOFF_RESET",
  FETCH_ALL_TAKEOFF_FAILURE: "FETCH_ALL_TAKEOFF_FAILURE",
  FETCH_ALL_TAKEOFF_SUCCESS: "FETCH_ALL_TAKEOFF_SUCCESS",
};
