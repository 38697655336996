import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { StaffEmergencyContactActionTypes } from "./StaffEmergencyContact.type";

export function* fetchAllStaffEmergencyContacts(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/staff-emergency-contact?staff_id=${action.payload?.staff_id}`);
    yield put({
      type: StaffEmergencyContactActionTypes.FETCH_ALL_STAFF_EMERGENCY_CONTACT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffEmergencyContactActionTypes.FETCH_ALL_STAFF_EMERGENCY_CONTACT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneStaffEmergencyContacts(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/staff-emergency-contact/${action.payload}`
    );
    yield put({
      type: StaffEmergencyContactActionTypes.FETCH_ONE_STAFF_EMERGENCY_CONTACT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffEmergencyContactActionTypes.FETCH_ONE_STAFF_EMERGENCY_CONTACT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllStaffEmergencyContacts() {
  yield takeLatest(StaffEmergencyContactActionTypes.FETCH_ALL_STAFF_EMERGENCY_CONTACT, fetchAllStaffEmergencyContacts);
}

export function* watcherFetchOneStaffEmergencyContacts() {
  yield takeLatest(StaffEmergencyContactActionTypes.FETCH_ONE_STAFF_EMERGENCY_CONTACT, fetchOneStaffEmergencyContacts);
}
