import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { FixedAssetTransferActionTypes } from "./FixedAssetTransfer.type";
import { formatQuery } from "../Utils";

export function* fetchAllFixedAssetTransfers(action: any): any {
  try {
    let query = formatQuery(action);

    const response = yield axios.get(
      `${API_BASE_URI}/fixed-asset-transfer?${query}`
    );
    yield put({
      type: FixedAssetTransferActionTypes.FETCH_ALL_FIXED_ASSET_TRANSFER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FixedAssetTransferActionTypes.FETCH_ALL_FIXED_ASSET_TRANSFER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedFixedAssetTransfers(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/fixed-asset-transfer?${query}`
    );
    yield put({
      type: FixedAssetTransferActionTypes.FETCH_PAGED_FIXED_ASSET_TRANSFER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FixedAssetTransferActionTypes.FETCH_PAGED_FIXED_ASSET_TRANSFER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneFixedAssetTransfers(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/fixed-asset-transfer/${action.payload}`
    );
    yield put({
      type: FixedAssetTransferActionTypes.FETCH_ONE_FIXED_ASSET_TRANSFER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FixedAssetTransferActionTypes.FETCH_ONE_FIXED_ASSET_TRANSFER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllFixedAssetTransfers() {
  yield takeLatest(
    FixedAssetTransferActionTypes.FETCH_ALL_FIXED_ASSET_TRANSFER,
    fetchAllFixedAssetTransfers
  );
}

export function* watcherFetchPagedFixedAssetTransfers() {
  yield takeLatest(
    FixedAssetTransferActionTypes.FETCH_PAGED_FIXED_ASSET_TRANSFER,
    fetchPagedFixedAssetTransfers
  );
}

export function* watcherFetchOneFixedAssetTransfers() {
  yield takeLatest(
    FixedAssetTransferActionTypes.FETCH_ONE_FIXED_ASSET_TRANSFER,
    fetchOneFixedAssetTransfers
  );
}
