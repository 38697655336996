import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type Assessment = {
  id: number;
  date: string;
  sex: string;
  age: number;
  marital_status: string;
  job_title: string;
  profession: string;
  willing_to_relocate: boolean;
  present_last_salary: number;
  other_perks: string;
  expected_salary: number;
  work_place: string;
  type_of_work_desire: string;
  date_of_commencement: string;
  recommendation: string;
  evaluated_by_id: number;
  title:string;
  applicant_registry_item_id: number;
  assessment_evaluations: AssessmentEvaluation[];
  user: User
};

type AssessmentEvaluation = {
  id: number,
  criteria: string,
  total_point: number,
  point_given: number,
  remark: string,
};

export type AssessmentStateTypes = {
  fetchAll: ApiCallState<Assessment[]>;
  fetchOne: ApiCallState<Assessment | {}>;
};

export const AssessmentActionTypes = {
  FETCH_ALL_ASSESSMENT: "FETCH_ALL_ASSESSMENT",
  FETCH_ALL_ASSESSMENT_RESET: "FETCH_ALL_ASSESSMENT_RESET",
  FETCH_ALL_ASSESSMENT_FAILURE: "FETCH_ALL_ASSESSMENT_FAILURE",
  FETCH_ALL_ASSESSMENT_SUCCESS: "FETCH_ALL_ASSESSMENT_SUCCESS",

  FETCH_ONE_ASSESSMENT: "FETCH_ONE_ASSESSMENT",
  FETCH_ONE_ASSESSMENT_RESET: "FETCH_ONE_ASSESSMENT_RESET",
  FETCH_ONE_ASSESSMENT_FAILURE: "FETCH_ONE_ASSESSMENT_FAILURE",
  FETCH_ONE_ASSESSMENT_SUCCESS: "FETCH_ONE_ASSESSMENT_SUCCESS",
};
