import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { DispatchActionTypes } from "./Dispatch.type";
import { formatQuery } from "../Utils";

export function* fetchAllDispatches(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/dispatch?${formatQuery(action)}`);
    yield put({
      type: DispatchActionTypes.FETCH_ALL_DISPATCH_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DispatchActionTypes.FETCH_ALL_DISPATCH_FAILURE,
      payload: error,
    });
  }
}

export function* fetchReportDispatches(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/dispatch/report?${query}`
    );
    yield put({
      type: DispatchActionTypes.FETCH_REPORT_DISPATCH_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DispatchActionTypes.FETCH_REPORT_DISPATCH_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedDispatches(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/dispatch?${query}`);
    yield put({
      type: DispatchActionTypes.FETCH_PAGED_DISPATCH_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DispatchActionTypes.FETCH_PAGED_DISPATCH_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneDispatches(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/dispatch/${action.payload}`
    );
    yield put({
      type: DispatchActionTypes.FETCH_ONE_DISPATCH_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DispatchActionTypes.FETCH_ONE_DISPATCH_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllDispatches() {
  yield takeLatest(DispatchActionTypes.FETCH_ALL_DISPATCH, fetchAllDispatches);
}

export function* watcherFetchReportDispatches() {
  yield takeLatest(
    DispatchActionTypes.FETCH_REPORT_DISPATCH,
    fetchReportDispatches
  );
}

export function* watcherFetchPagedDispatches() {
  yield takeLatest(
    DispatchActionTypes.FETCH_PAGED_DISPATCH,
    fetchPagedDispatches
  );
}

export function* watcherFetchOneDispatches() {
  yield takeLatest(DispatchActionTypes.FETCH_ONE_DISPATCH, fetchOneDispatches);
}
