import { ApiCallState } from "../Utils";

export type JournalVoucher= {
  id: number;
  date:string;
  project_name:string;
  customer_name:string;
  user_id:number;
  journal_voucher_items:JournalVoucherItem[];
  journal_voucher_statuses:JournalVoucherStatus[];
};

export type JournalVoucherItem= {
  id: number;
  journal_voucher_id:number;
  account_description:string;
  account_no:string;
  credit:number;
  debit:number;
}

export type JournalVoucherStatus = {
  journal_voucher_id:number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type JournalVoucherStateTypes = {
  fetchAll: ApiCallState<JournalVoucher[]>;
  fetchOne: ApiCallState<JournalVoucher | {}>;
};

export const JournalVoucherActionTypes = {
  FETCH_ALL_JOURNAL_VOUCHER: "FETCH_ALL_JOURNAL_VOUCHER",
  FETCH_ALL_JOURNAL_VOUCHER_RESET: "FETCH_ALL_JOURNAL_VOUCHER_RESET",
  FETCH_ALL_JOURNAL_VOUCHER_FAILURE: "FETCH_ALL_JOURNAL_VOUCHER_FAILURE",
  FETCH_ALL_JOURNAL_VOUCHER_SUCCESS: "FETCH_ALL_JOURNAL_VOUCHER_SUCCESS",

  FETCH_ONE_JOURNAL_VOUCHER: "FETCH_ONE_JOURNAL_VOUCHER",
  FETCH_ONE_JOURNAL_VOUCHER_RESET: "FETCH_ONE_JOURNAL_VOUCHER_RESET",
  FETCH_ONE_JOURNAL_VOUCHER_FAILURE: "FETCH_ONE_JOURNAL_VOUCHER_FAILURE",
  FETCH_ONE_JOURNAL_VOUCHER_SUCCESS: "FETCH_ONE_JOURNAL_VOUCHER_SUCCESS",
};
