import { Document } from "../Document/Document.type";
import { ApiCallState } from "../Utils";

export type EquipmentList= {
  id: number;
  date:string;
  description:string;
  code:string;
  color_coding:string;
  location:string;
  prepared_by:number;
  document:Document; 
};

export type EquipmentListStateTypes = {
  fetchAll: ApiCallState<EquipmentList[]>;
  fetchOne: ApiCallState<EquipmentList | {}>;
};

export const EquipmentListActionTypes = {
  FETCH_ALL_EQUIPMENT_LIST: "FETCH_ALL_EQUIPMENT_LIST",
  FETCH_ALL_EQUIPMENT_LIST_RESET: "FETCH_ALL_EQUIPMENT_LIST_RESET",
  FETCH_ALL_EQUIPMENT_LIST_FAILURE: "FETCH_ALL_EQUIPMENT_LIST_FAILURE",
  FETCH_ALL_EQUIPMENT_LIST_SUCCESS: "FETCH_ALL_EQUIPMENT_LIST_SUCCESS",

  FETCH_ONE_EQUIPMENT_LIST: "FETCH_ONE_EQUIPMENT_LIST",
  FETCH_ONE_EQUIPMENT_LIST_RESET: "FETCH_ONE_EQUIPMENT_LIST_RESET",
  FETCH_ONE_EQUIPMENT_LIST_FAILURE: "FETCH_ONE_EQUIPMENT_LIST_FAILURE",
  FETCH_ONE_EQUIPMENT_LIST_SUCCESS: "FETCH_ONE_EQUIPMENT_LIST_SUCCESS",
};
