import { Staff } from "../Staff/Staff.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type ProbationReview= {
  id: number;
  date: string;
  recommendation: string;
  manager_id: number;
  general_manager: string;
  manager: User,
  staff: Staff,
  probation_review_items: ProbationReviewItem[]
};

type ProbationReviewItem = {
  id: number;
  description: string;
  value: number;
}

export type ProbationReviewStateTypes = {
  fetchAll: ApiCallState<ProbationReview[]>;
  fetchOne: ApiCallState<ProbationReview | {}>;
};

export const ProbationReviewActionTypes = {
  FETCH_ALL_PROBATION_REVIEW: "FETCH_ALL_PROBATION_REVIEW",
  FETCH_ALL_PROBATION_REVIEW_RESET: "FETCH_ALL_PROBATION_REVIEW_RESET",
  FETCH_ALL_PROBATION_REVIEW_FAILURE: "FETCH_ALL_PROBATION_REVIEW_FAILURE",
  FETCH_ALL_PROBATION_REVIEW_SUCCESS: "FETCH_ALL_PROBATION_REVIEW_SUCCESS",

  FETCH_ONE_PROBATION_REVIEW: "FETCH_ONE_PROBATION_REVIEW",
  FETCH_ONE_PROBATION_REVIEW_RESET: "FETCH_ONE_PROBATION_REVIEW_RESET",
  FETCH_ONE_PROBATION_REVIEW_FAILURE: "FETCH_ONE_PROBATION_REVIEW_FAILURE",
  FETCH_ONE_PROBATION_REVIEW_SUCCESS: "FETCH_ONE_PROBATION_REVIEW_SUCCESS",
};
