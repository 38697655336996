import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { formatQuery } from "../Utils";
import { GoodOutActionTypes } from "./GoodOut.type";

export function* fetchAllGoodOut(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/good-out?${formatQuery(action)}`
    );
    yield put({
      type: GoodOutActionTypes.FETCH_ALL_GOOD_OUT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GoodOutActionTypes.FETCH_ALL_GOOD_OUT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchReportGoodOut(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/good-out/report?${formatQuery(action)}`
    );
    yield put({
      type: GoodOutActionTypes.FETCH_REPORT_GOOD_OUT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GoodOutActionTypes.FETCH_REPORT_GOOD_OUT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllGoodOut() {
  yield takeLatest(GoodOutActionTypes.FETCH_ALL_GOOD_OUT, fetchAllGoodOut);
}

export function* watcherFetchReportGoodOut() {
  yield takeLatest(
    GoodOutActionTypes.FETCH_REPORT_GOOD_OUT,
    fetchReportGoodOut
  );
}
