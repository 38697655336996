import {
  MaintenanceVoucherStateTypes,
  MaintenanceVoucherActionTypes,
} from "./MaintenanceVoucher.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: MaintenanceVoucherStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchNumber: resetApiCallState(null),
};

const MaintenanceVoucherReducer = (
  state: MaintenanceVoucherStateTypes = INITIAL_STATE,
  action: any
): MaintenanceVoucherStateTypes => {
  switch (action.type) {
    case MaintenanceVoucherActionTypes.FETCH_ALL_MAINTENANCE_VOUCHER:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaintenanceVoucherActionTypes.FETCH_ALL_MAINTENANCE_VOUCHER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MaintenanceVoucherActionTypes.FETCH_ALL_MAINTENANCE_VOUCHER_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaintenanceVoucherActionTypes.FETCH_ALL_MAINTENANCE_VOUCHER_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MaintenanceVoucherActionTypes.FETCH_ONE_MAINTENANCE_VOUCHER:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaintenanceVoucherActionTypes.FETCH_ONE_MAINTENANCE_VOUCHER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MaintenanceVoucherActionTypes.FETCH_ONE_MAINTENANCE_VOUCHER_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaintenanceVoucherActionTypes.FETCH_ONE_MAINTENANCE_VOUCHER_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MaintenanceVoucherActionTypes.FETCH_MAINTENANCE_VOUCHER_NUMBER:
      return {
        ...state,
        fetchNumber: {
          error: null,
          payload: null,
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaintenanceVoucherActionTypes.FETCH_MAINTENANCE_VOUCHER_NUMBER_RESET:
      return {
        ...state,
        fetchNumber: resetApiCallState(null),
      };
    case MaintenanceVoucherActionTypes.FETCH_MAINTENANCE_VOUCHER_NUMBER_FAILURE:
      return {
        ...state,
        fetchNumber: {
          payload: null,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaintenanceVoucherActionTypes.FETCH_MAINTENANCE_VOUCHER_NUMBER_SUCCESS:
      return {
        ...state,
        fetchNumber: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default MaintenanceVoucherReducer;
