import { ApiCallState } from "../../Utils";

export type TelegraphicTransfer = {
  id: number;
  date: Date;
  sender_bank: string;
  account_number: string;
  account_holder: string;
  reciever_bank_account_no: string;
  reciever_name: string;
  amount: number;
  reason: string;
  user_id: number;
  telegraphic_transfer_statuses:TelegraphicTransferStatus[];
};

export type TelegraphicTransferStatus = {
  telegraphic_transfer_id:number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type TelegraphicTransferStateTypes = {
  fetchAll: ApiCallState<TelegraphicTransfer[]>;
  fetchOne: ApiCallState<TelegraphicTransfer | {}>;
};

export const TelegraphicTransferActionTypes = {
  FETCH_ALL_TELEGRAPHIC_TRANSFER: "FETCH_ALL_TELEGRAPHIC_TRANSFER",
  FETCH_ALL_TELEGRAPHIC_TRANSFER_RESET: "FETCH_ALL_TELEGRAPHIC_TRANSFER_RESET",
  FETCH_ALL_TELEGRAPHIC_TRANSFER_FAILURE: "FETCH_ALL_TELEGRAPHIC_TRANSFER_FAILURE",
  FETCH_ALL_TELEGRAPHIC_TRANSFER_SUCCESS: "FETCH_ALL_TELEGRAPHIC_TRANSFER_SUCCESS",

  FETCH_ONE_TELEGRAPHIC_TRANSFER: "FETCH_ONE_TELEGRAPHIC_TRANSFER",
  FETCH_ONE_TELEGRAPHIC_TRANSFER_RESET: "FETCH_ONE_TELEGRAPHIC_TRANSFER_RESET",
  FETCH_ONE_TELEGRAPHIC_TRANSFER_FAILURE: "FETCH_ONE_TELEGRAPHIC_TRANSFER_FAILURE",
  FETCH_ONE_TELEGRAPHIC_TRANSFER_SUCCESS: "FETCH_ONE_BANK_LOAN_SUCCESS",
};
