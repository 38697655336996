import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { AdditionBoqActionTypes } from "./AdditionBoq.type";

export function* fetchAllAdditionBoqs(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/addition-boq?project_id=${action.payload.project_id}`);
    yield put({
      type: AdditionBoqActionTypes.FETCH_ALL_ADDITION_BOQ_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AdditionBoqActionTypes.FETCH_ALL_ADDITION_BOQ_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneAdditionBoqs(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/addition-boq/${action.payload}`
    );
    yield put({
      type: AdditionBoqActionTypes.FETCH_ONE_ADDITION_BOQ_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AdditionBoqActionTypes.FETCH_ONE_ADDITION_BOQ_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllAdditionBoqs() {
  yield takeLatest(AdditionBoqActionTypes.FETCH_ALL_ADDITION_BOQ, fetchAllAdditionBoqs);
}

export function* watcherFetchOneAdditionBoqs() {
  yield takeLatest(AdditionBoqActionTypes.FETCH_ONE_ADDITION_BOQ, fetchOneAdditionBoqs);
}
