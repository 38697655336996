import { SocialCommitmentStateTypes, SocialCommitmentActionTypes } from "./SocialCommitment.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: SocialCommitmentStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const SocialCommitmentReducer = (
  state: SocialCommitmentStateTypes = INITIAL_STATE,
  action: any
): SocialCommitmentStateTypes => {
  switch (action.type) {
    case SocialCommitmentActionTypes.FETCH_ALL_SOCIAL_COMMITMENT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case SocialCommitmentActionTypes.FETCH_ALL_SOCIAL_COMMITMENT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SocialCommitmentActionTypes.FETCH_ALL_SOCIAL_COMMITMENT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SocialCommitmentActionTypes.FETCH_ALL_SOCIAL_COMMITMENT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case SocialCommitmentActionTypes.FETCH_ONE_SOCIAL_COMMITMENT:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case SocialCommitmentActionTypes.FETCH_ONE_SOCIAL_COMMITMENT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SocialCommitmentActionTypes.FETCH_ONE_SOCIAL_COMMITMENT_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SocialCommitmentActionTypes.FETCH_ONE_SOCIAL_COMMITMENT_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default SocialCommitmentReducer;
