import { AssessmentStateTypes, AssessmentActionTypes } from "./Assessment.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: AssessmentStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const AssessmentReducer = (
  state: AssessmentStateTypes = INITIAL_STATE,
  action: any
): AssessmentStateTypes => {
  switch (action.type) {
    case AssessmentActionTypes.FETCH_ALL_ASSESSMENT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case AssessmentActionTypes.FETCH_ALL_ASSESSMENT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case AssessmentActionTypes.FETCH_ALL_ASSESSMENT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case AssessmentActionTypes.FETCH_ALL_ASSESSMENT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case AssessmentActionTypes.FETCH_ONE_ASSESSMENT:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case AssessmentActionTypes.FETCH_ONE_ASSESSMENT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case AssessmentActionTypes.FETCH_ONE_ASSESSMENT_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case AssessmentActionTypes.FETCH_ONE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default AssessmentReducer;
