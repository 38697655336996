import { ApiCallState } from "../Utils";
import { SubContractRegistry, SubContractRegistryItem } from "../SubContractRegistry/SubContractRegistry.type";

export type SubContractPaymentUpdate= {
  id: number;
  project_id:number;
  month:string;
  period:string;
  deductable_description: string;
  deductable_amount: number;
  loan: number;
  sub_contract_registry_id: number;
  prepared_by:number;
  checked_by:number;
  approved_by:number;
  is_checked:boolean;
  is_approved:boolean;
  user_id: number;
  sub_contract_payment_update_items:SubContractPaymentUpdateItem[];
  sub_contract_registry: SubContractRegistry
};

export type SubContractPaymentUpdateItem={
    id: number;
    sub_contract_payment_update_id:number;
    sub_contract_registry_item_id:number;
    previous_quantity:number;
    previous_amount:number;
    this_executed_quantity:number;
    this_executed_amount:number;
    todate_executed_quantity:number;
    todate_executed_amount:number;
    retantion_amount:number;
    loan_advance_amount:number;
    net_this_payable_amount:number;
    sub_contract_registry_item: SubContractRegistryItem;
}

export type SubContractPaymentUpdateStateTypes = {
  fetchAll: ApiCallState<SubContractPaymentUpdate[]>;
  fetchOne: ApiCallState<SubContractPaymentUpdate | {}>;
};

export const SubContractPaymentUpdateActionTypes = {
  FETCH_ALL_SUB_CONTRACT_PAYMENT_UPDATE: "FETCH_ALL_SUB_CONTRACT_PAYMENT_UPDATE",
  FETCH_ALL_SUB_CONTRACT_PAYMENT_UPDATE_RESET: "FETCH_ALL_SUB_CONTRACT_PAYMENT_UPDATE_RESET",
  FETCH_ALL_SUB_CONTRACT_PAYMENT_UPDATE_FAILURE: "FETCH_ALL_SUB_CONTRACT_PAYMENT_UPDATE_FAILURE",
  FETCH_ALL_SUB_CONTRACT_PAYMENT_UPDATE_SUCCESS: "FETCH_ALL_SUB_CONTRACT_PAYMENT_UPDATE_SUCCESS",

  FETCH_ONE_SUB_CONTRACT_PAYMENT_UPDATE: "FETCH_ONE_SUB_CONTRACT_PAYMENT_UPDATE",
  FETCH_ONE_SUB_CONTRACT_PAYMENT_UPDATE_RESET: "FETCH_ONE_SUB_CONTRACT_PAYMENT_UPDATE_RESET",
  FETCH_ONE_SUB_CONTRACT_PAYMENT_UPDATE_FAILURE: "FETCH_ONE_SUB_CONTRACT_PAYMENT_UPDATE_FAILURE",
  FETCH_ONE_SUB_CONTRACT_PAYMENT_UPDATE_SUCCESS: "FETCH_ONE_SUB_CONTRACT_PAYMENT_UPDATE_SUCCESS",
};
