import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type EmployeeReview = {
  id: number;
  user_id: number;
  staff_id: number;
  department_id: number;
  reviewer_id: number;
  reviewer_manager_id: number;
  company_manager_id: number;

  evaluation_name: string;
  review_recurrence: string;
  job_description: string;

  reviewer_comment: string;
  reviewer_commented_date: string;

  staff_comment: string;
  staff_commented_date: string;

  reviewer_manager_comment: string;
  reviewer_manager_commented_date: string;

  company_manager_comment: string;
  company_manager_commented_date: string;

  start_date: string;
  end_date: string;

  type: string;

  employee_review_items: EmployeeReviewItem[];
  staff: Staff;
};

export type EmployeeReviewItem = {
  id: number;
  employee_review_id: number;
  item_of: number;

  is_subtitle: boolean;
  value: number;
  result: number;
  description: string;
  weight: number;

  employee_review_items: EmployeeReviewItem[];
};

export type EmployeeReviewStateTypes = {
  fetchAll: ApiCallState<EmployeeReview[]>;
  fetchOne: ApiCallState<EmployeeReview | {}>;
};

export const EmployeeReviewActionTypes = {
  FETCH_ALL_EMPLOYEE_REVIEW: "FETCH_ALL_EMPLOYEE_REVIEW",
  FETCH_ALL_EMPLOYEE_REVIEW_RESET: "FETCH_ALL_EMPLOYEE_REVIEW_RESET",
  FETCH_ALL_EMPLOYEE_REVIEW_FAILURE: "FETCH_ALL_EMPLOYEE_REVIEW_FAILURE",
  FETCH_ALL_EMPLOYEE_REVIEW_SUCCESS: "FETCH_ALL_EMPLOYEE_REVIEW_SUCCESS",

  FETCH_ONE_EMPLOYEE_REVIEW: "FETCH_ONE_EMPLOYEE_REVIEW",
  FETCH_ONE_EMPLOYEE_REVIEW_RESET: "FETCH_ONE_EMPLOYEE_REVIEW_RESET",
  FETCH_ONE_EMPLOYEE_REVIEW_FAILURE: "FETCH_ONE_EMPLOYEE_REVIEW_FAILURE",
  FETCH_ONE_EMPLOYEE_REVIEW_SUCCESS: "FETCH_ONE_EMPLOYEE_REVIEW_SUCCESS",
};
