import { Document } from "../Document/Document.type";
import { ApiCallState } from "../Utils";

export type SubContractSecurity= {
  id: number;
  identification_number: string;
  contract_end_date: string;
  subcontractor: string;
  contract_date: string;
  retention_percentage: number;
  sub_contract_securities: SubContractSecurityItem[],
  type: string,
  sub_contract_agreement_id: number,
  rebate_percentage: number
  sub_contract_agreement: SubContractSecurity
};

type SubContractSecurityItem = {
  id: number;
  type: string;
  expiry: string;
  description: string;
  document: Document
}

export type SubContractSecurityStateTypes = {
  fetchAll: ApiCallState<SubContractSecurity[]>;
  fetchOne: ApiCallState<SubContractSecurity | {}>;
};

export const SubContractSecurityActionTypes = {
  FETCH_ALL_SUB_CONTRACT_SECURITY: "FETCH_ALL_SUB_CONTRACT_SECURITY",
  FETCH_ALL_SUB_CONTRACT_SECURITY_RESET: "FETCH_ALL_SUB_CONTRACT_SECURITY_RESET",
  FETCH_ALL_SUB_CONTRACT_SECURITY_FAILURE: "FETCH_ALL_SUB_CONTRACT_SECURITY_FAILURE",
  FETCH_ALL_SUB_CONTRACT_SECURITY_SUCCESS: "FETCH_ALL_SUB_CONTRACT_SECURITY_SUCCESS",

  FETCH_ONE_SUB_CONTRACT_SECURITY: "FETCH_ONE_SUB_CONTRACT_SECURITY",
  FETCH_ONE_SUB_CONTRACT_SECURITY_RESET: "FETCH_ONE_SUB_CONTRACT_SECURITY_RESET",
  FETCH_ONE_SUB_CONTRACT_SECURITY_FAILURE: "FETCH_ONE_SUB_CONTRACT_SECURITY_FAILURE",
  FETCH_ONE_SUB_CONTRACT_SECURITY_SUCCESS: "FETCH_ONE_SUB_CONTRACT_SECURITY_SUCCESS",
};
