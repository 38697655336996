import { ApiCallState } from "../Utils";

export type SubContractorPayment= {
  id: number;
  date: string;
  sub_contractor_payment_items: SubContractorPaymentItems[],
  sub_contractor_payment_statuses: any
};

type SubContractorPaymentStatus = {
  id: number,
  user_id: number,
  sub_contractor_payment_id: number,
  status: number,
  assigned_by: number
}

export type SubContractorPaymentItems = {
  id: number;
  net_payment: number;
  type: string;
  sub_contractor_name: string;
  type_of_work: string;
}

export type SubContractorPaymentStateTypes = {
  fetchAll: ApiCallState<SubContractorPayment[]>;
  fetchOne: ApiCallState<SubContractorPayment | {}>;
};

export const SubContractorPaymentActionTypes = {
  FETCH_ALL_SUB_CONTRACTOR_PAYMENT: "FETCH_ALL_SUB_CONTRACTOR_PAYMENT",
  FETCH_ALL_SUB_CONTRACTOR_PAYMENT_RESET: "FETCH_ALL_SUB_CONTRACTOR_PAYMENT_RESET",
  FETCH_ALL_SUB_CONTRACTOR_PAYMENT_FAILURE: "FETCH_ALL_SUB_CONTRACTOR_PAYMENT_FAILURE",
  FETCH_ALL_SUB_CONTRACTOR_PAYMENT_SUCCESS: "FETCH_ALL_SUB_CONTRACTOR_PAYMENT_SUCCESS",

  FETCH_ONE_SUB_CONTRACTOR_PAYMENT: "FETCH_ONE_SUB_CONTRACTOR_PAYMENT",
  FETCH_ONE_SUB_CONTRACTOR_PAYMENT_RESET: "FETCH_ONE_SUB_CONTRACTOR_PAYMENT_RESET",
  FETCH_ONE_SUB_CONTRACTOR_PAYMENT_FAILURE: "FETCH_ONE_SUB_CONTRACTOR_PAYMENT_FAILURE",
  FETCH_ONE_SUB_CONTRACTOR_PAYMENT_SUCCESS: "FETCH_ONE_SUB_CONTRACTOR_PAYMENT_SUCCESS",
};
