import { ApiCallState } from "../Utils";

export type ChartsOfAccount= {
  id: number;
  name: string;
    parent_account: string;
    type: string;
    rate: number;
    balance: string;
    currency: string;
};

export type ChartsOfAccountStateTypes = {
  fetchAll: ApiCallState<ChartsOfAccount[]>;
  fetchOne: ApiCallState<ChartsOfAccount | {}>;
};

export const ChartsOfAccountActionTypes = {
  FETCH_ALL_CHARTS_OF_ACCOUNT: "FETCH_ALL_CHARTS_OF_ACCOUNT",
  FETCH_ALL_CHARTS_OF_ACCOUNT_RESET: "FETCH_ALL_CHARTS_OF_ACCOUNT_RESET",
  FETCH_ALL_CHARTS_OF_ACCOUNT_FAILURE: "FETCH_ALL_CHARTS_OF_ACCOUNT_FAILURE",
  FETCH_ALL_CHARTS_OF_ACCOUNT_SUCCESS: "FETCH_ALL_CHARTS_OF_ACCOUNT_SUCCESS",

  FETCH_ONE_CHARTS_OF_ACCOUNT: "FETCH_ONE_CHARTS_OF_ACCOUNT",
  FETCH_ONE_CHARTS_OF_ACCOUNT_RESET: "FETCH_ONE_CHARTS_OF_ACCOUNT_RESET",
  FETCH_ONE_CHARTS_OF_ACCOUNT_FAILURE: "FETCH_ONE_CHARTS_OF_ACCOUNT_FAILURE",
  FETCH_ONE_CHARTS_OF_ACCOUNT_SUCCESS: "FETCH_ONE_CHARTS_OF_ACCOUNT_SUCCESS",
};
