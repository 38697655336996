import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { DailyStatusReportActionTypes } from "./DailyStatusReport.type";

export function* fetchAllDailyStatusReports(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/daily-status-report?${query}`);
    yield put({
      type: DailyStatusReportActionTypes.FETCH_ALL_DAILY_STATUS_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DailyStatusReportActionTypes.FETCH_ALL_DAILY_STATUS_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchReportDailyStatusReports(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/daily-status-report/report?${query}`
    );
    yield put({
      type: DailyStatusReportActionTypes.FETCH_REPORT_DAILY_STATUS_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DailyStatusReportActionTypes.FETCH_REPORT_DAILY_STATUS_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllDailyStatusReports() {
  yield takeLatest(DailyStatusReportActionTypes.FETCH_ALL_DAILY_STATUS_REPORT, fetchAllDailyStatusReports);
}

export function* watcherFetchReportDailyStatusReports() {
  yield takeLatest(DailyStatusReportActionTypes.FETCH_REPORT_DAILY_STATUS_REPORT, fetchReportDailyStatusReports);
}
