import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { BudgetUtilizationActionTypes } from "./BudgetUtilization.type";

export function* fetchAllBudgetUtilizations(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/budget-utilization?project_id=${action.payload.project_id}`);
    yield put({
      type: BudgetUtilizationActionTypes.FETCH_ALL_BUDGET_UTILIZATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BudgetUtilizationActionTypes.FETCH_ALL_BUDGET_UTILIZATION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneBudgetUtilizations(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/budget-utilization/${action.payload}`
    );
    yield put({
      type: BudgetUtilizationActionTypes.FETCH_ONE_BUDGET_UTILIZATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BudgetUtilizationActionTypes.FETCH_ONE_BUDGET_UTILIZATION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllBudgetUtilizations() {
  yield takeLatest(BudgetUtilizationActionTypes.FETCH_ALL_BUDGET_UTILIZATION, fetchAllBudgetUtilizations);
}

export function* watcherFetchOneBudgetUtilizations() {
  yield takeLatest(BudgetUtilizationActionTypes.FETCH_ONE_BUDGET_UTILIZATION, fetchOneBudgetUtilizations);
}
