import {
  StaffAttendanceStateTypes,
  StaffAttendanceActionTypes,
} from "./StaffAttendance.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: StaffAttendanceStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchAllMonthly: resetApiCallState([]),
};

const StaffAttendanceReducer = (
  state: StaffAttendanceStateTypes = INITIAL_STATE,
  action: any
): StaffAttendanceStateTypes => {
  switch (action.type) {
    case StaffAttendanceActionTypes.FETCH_ALL_STAFF_ATTENDANCE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case StaffAttendanceActionTypes.FETCH_ALL_STAFF_ATTENDANCE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case StaffAttendanceActionTypes.FETCH_ALL_STAFF_ATTENDANCE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case StaffAttendanceActionTypes.FETCH_ALL_STAFF_ATTENDANCE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    //---------------------

    case StaffAttendanceActionTypes.FETCH_ALL_MONTHLY_STAFF_ATTENDANCE:
      return {
        ...state,
        fetchAllMonthly: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case StaffAttendanceActionTypes.FETCH_ALL_MONTHLY_STAFF_ATTENDANCE_RESET:
      return {
        ...state,
        fetchAllMonthly: resetApiCallState([]),
      };
    case StaffAttendanceActionTypes.FETCH_ALL_MONTHLY_STAFF_ATTENDANCE_FAILURE:
      return {
        ...state,
        fetchAllMonthly: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case StaffAttendanceActionTypes.FETCH_ALL_MONTHLY_STAFF_ATTENDANCE_SUCCESS:
      return {
        ...state,
        fetchAllMonthly: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default StaffAttendanceReducer;
