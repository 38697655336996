import { ApiCallState } from "../Utils";

export type FuelCost = {
  id: number;
  date: string;
  project_id: number;
  fuel_unit_rate: number;
  fuel_cost_items: FuelCostItem[];
};

export type FuelCostItem = {
  description: string;
  plate_number: string;
  owner: string;
  previous_fuel_consumed: number;
  this_period_fuel_consumed: number;
  to_date_fuel_consumed: number;
  previous_fuel_cost: number;
  this_period_fuel_cost: number;
  to_date_fuel_cost: number;
};

export type FuelCostStateTypes = {
  fetchAll: ApiCallState<FuelCost[]>;
  fetchOne: ApiCallState<FuelCost | {}>;
};

export const FuelCostActionTypes = {
  FETCH_ALL_FUEL_COST: "FETCH_ALL_FUEL_COST",
  FETCH_ALL_FUEL_COST_RESET: "FETCH_ALL_FUEL_COST_RESET",
  FETCH_ALL_FUEL_COST_FAILURE: "FETCH_ALL_FUEL_COST_FAILURE",
  FETCH_ALL_FUEL_COST_SUCCESS: "FETCH_ALL_FUEL_COST_SUCCESS",

  FETCH_ONE_FUEL_COST: "FETCH_ONE_FUEL_COST",
  FETCH_ONE_FUEL_COST_RESET: "FETCH_ONE_FUEL_COST_RESET",
  FETCH_ONE_FUEL_COST_FAILURE: "FETCH_ONE_FUEL_COST_FAILURE",
  FETCH_ONE_FUEL_COST_SUCCESS: "FETCH_ONE_FUEL_COST_SUCCESS",
};
