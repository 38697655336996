import { Document } from "../Document/Document.type";
import { ApiCallState } from "../Utils";

export type SiteVisit= {
    id: number;
    date: string;
    report: string;
    file: string;
    project_id: number;
    user_id: number;
    document: Document;
    document_id: number;
    site_visit_statuses: any[]
};

type SiteVisitStatus = {
  id: number,
  user_id: number,
  status: number,
  type: string,
  assigned_by: string,
}


export type SiteVisitStateTypes = {
  fetchAll: ApiCallState<SiteVisit[]>;
  fetchOne: ApiCallState<SiteVisit | {}>;
};

export const SiteVisitActionTypes = {
  FETCH_ALL_SITE_VISIT: "FETCH_ALL_SITE_VISIT",
  FETCH_ALL_SITE_VISIT_RESET: "FETCH_ALL_SITE_VISIT_RESET",
  FETCH_ALL_SITE_VISIT_FAILURE: "FETCH_ALL_SITE_VISIT_FAILURE",
  FETCH_ALL_SITE_VISIT_SUCCESS: "FETCH_ALL_SITE_VISIT_SUCCESS",

  FETCH_ONE_SITE_VISIT: "FETCH_ONE_SITE_VISIT",
  FETCH_ONE_SITE_VISIT_RESET: "FETCH_ONE_SITE_VISIT_RESET",
  FETCH_ONE_SITE_VISIT_FAILURE: "FETCH_ONE_SITE_VISIT_FAILURE",
  FETCH_ONE_SITE_VISIT_SUCCESS: "FETCH_ONE_SITE_VISIT_SUCCESS",
};
