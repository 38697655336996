import { ApiCallState } from "../Utils";

export type ReviewForm = {
  id: number;
  user_id: number;

  name: string;
  type: string;
  review_recurrence: string;

  review_form_items: ReviewFormItem[];
};

export type ReviewFormItem = {
  id: number;
  review_form_id: number;
  item_of: number;

  is_subtitle: boolean;
  description: string;
  weight: number;

  review_form_items: ReviewFormItem[];
};

export type ReviewFormStateTypes = {
  fetchAll: ApiCallState<ReviewForm[]>;
};

export const ReviewFormActionTypes = {
  FETCH_ALL_REVIEW_FORM: "FETCH_ALL_REVIEW_FORM",
  FETCH_ALL_REVIEW_FORM_RESET: "FETCH_ALL_REVIEW_FORM_RESET",
  FETCH_ALL_REVIEW_FORM_FAILURE: "FETCH_ALL_REVIEW_FORM_FAILURE",
  FETCH_ALL_REVIEW_FORM_SUCCESS: "FETCH_ALL_REVIEW_FORM_SUCCESS",
};
