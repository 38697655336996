import { ApiCallState } from "../Utils";

export type BillSummary= {
  id: number;
  project_id:number;
  description: string;
  unit: string;
  amount: number;
  user_id: number;
};

export type BillSummaryStateTypes = {
  fetchAll: ApiCallState<BillSummary[]>;
  fetchOne: ApiCallState<BillSummary | {}>;
};

export const BillSummaryActionTypes = {
  FETCH_ALL_BILL_SUMMARY: "FETCH_ALL_BILL_SUMMARY",
  FETCH_ALL_BILL_SUMMARY_RESET: "FETCH_ALL_BILL_SUMMARY_RESET",
  FETCH_ALL_BILL_SUMMARY_FAILURE: "FETCH_ALL_BILL_SUMMARY_FAILURE",
  FETCH_ALL_BILL_SUMMARY_SUCCESS: "FETCH_ALL_BILL_SUMMARY_SUCCESS",

  FETCH_ONE_BILL_SUMMARY: "FETCH_ONE_BILL_SUMMARY",
  FETCH_ONE_BILL_SUMMARY_RESET: "FETCH_ONE_BILL_SUMMARY_RESET",
  FETCH_ONE_BILL_SUMMARY_FAILURE: "FETCH_ONE_BILL_SUMMARY_FAILURE",
  FETCH_ONE_BILL_SUMMARY_SUCCESS: "FETCH_ONE_BILL_SUMMARY_SUCCESS",
};
