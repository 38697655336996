import { Department } from "../Department/Department.type";
import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type EmployeePerformance = {
  id: number;
  user_id: number;
  staff_id: number;

  name: string;
  start_date: string;
  end_date: string;

  readonly createdAt?: Date;
  readonly updatedAt?: Date;

  staff: Staff;
  employee_performance_items: EmployeePerformanceItem[];
  employee_performance_departments: EmployeePerformanceDepartment[];
};

export type EmployeePerformanceItem = {
  id: number;
  employee_performance_id: number;
  parent_id: number;

  is_title: boolean;
  weight: number;
  description: string;

  readonly createdAt?: Date;
  readonly updatedAt?: Date;

  employee_performance_departments: EmployeePerformanceDepartment[];
};

export type EmployeePerformanceDepartment = {
  id: number;
  employee_performance_id: number;
  employee_performance_item_id: number;
  department_id: number;

  value: number;

  readonly createdAt?: Date;
  readonly updatedAt?: Date;

  department: Department;
};

export type EmployeePerformanceStateTypes = {
  fetchAll: ApiCallState<EmployeePerformance[]>;
  fetchOne: ApiCallState<EmployeePerformance | {}>;
};

export const EmployeePerformanceActionTypes = {
  FETCH_ALL_EMPLOYEE_PERFORMANCE: "FETCH_ALL_EMPLOYEE_PERFORMANCE",
  FETCH_ALL_EMPLOYEE_PERFORMANCE_RESET: "FETCH_ALL_EMPLOYEE_PERFORMANCE_RESET",
  FETCH_ALL_EMPLOYEE_PERFORMANCE_FAILURE:
    "FETCH_ALL_EMPLOYEE_PERFORMANCE_FAILURE",
  FETCH_ALL_EMPLOYEE_PERFORMANCE_SUCCESS:
    "FETCH_ALL_EMPLOYEE_PERFORMANCE_SUCCESS",

  FETCH_ONE_EMPLOYEE_PERFORMANCE: "FETCH_ONE_EMPLOYEE_PERFORMANCE",
  FETCH_ONE_EMPLOYEE_PERFORMANCE_RESET: "FETCH_ONE_EMPLOYEE_PERFORMANCE_RESET",
  FETCH_ONE_EMPLOYEE_PERFORMANCE_FAILURE:
    "FETCH_ONE_EMPLOYEE_PERFORMANCE_FAILURE",
  FETCH_ONE_EMPLOYEE_PERFORMANCE_SUCCESS:
    "FETCH_ONE_EMPLOYEE_PERFORMANCE_SUCCESS",
};
