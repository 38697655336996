import { HRAllowance } from "../HRAllowance/HRAllowance.type";
import { StaffTransfer } from "../StaffTransfer/StaffTransfer.type";
import { ApiCallState } from "../Utils";

export type StaffSalaryAdjustment= {
  id: number;
  staff_id:number;
  date:string;
  staff_transfer_id: number;
  new_title:string;
  current_title:string;
  transfer_date: string;
  salary_date: string;
  user_id:number;
  staff_transfer:StaffTransfer;
  staff_salary_adjustment_items:StaffSalaryAdjustmentItem[];
  staff_salary_adjustment_details:StaffSalaryAdjustmentDetail[];
};

export type StaffSalaryAdjustmentItem= {
    id: number;
    staff_salary_adjustment_id: number;
    hr_allowance_id: number;
    description:string;
    current_amount: number;
    prev_amount:number;
    hr_allowance: HRAllowance;
    createdAt:Date;
}

export type StaffSalaryAdjustmentDetail = {
  id: number;
  staff_salary_adjustment_id: number;
  department_to:string;
  department_cc:string;
}

export type StaffSalaryAdjustmentStateTypes = {
  fetchAll: ApiCallState<StaffSalaryAdjustment[]>;
  fetchOne: ApiCallState<StaffSalaryAdjustment | {}>;
};

export const StaffSalaryAdjustmentActionTypes = {
  FETCH_ALL_STAFF_SALARY_ADJUSTMENT: "FETCH_ALL_STAFF_SALARY_ADJUSTMENT",
  FETCH_ALL_STAFF_SALARY_ADJUSTMENT_RESET: "FETCH_ALL_STAFF_SALARY_ADJUSTMENT_RESET",
  FETCH_ALL_STAFF_SALARY_ADJUSTMENT_FAILURE: "FETCH_ALL_STAFF_SALARY_ADJUSTMENT_FAILURE",
  FETCH_ALL_STAFF_SALARY_ADJUSTMENT_SUCCESS: "FETCH_ALL_STAFF_SALARY_ADJUSTMENT_SUCCESS",

  FETCH_ONE_STAFF_SALARY_ADJUSTMENT: "FETCH_ONE_STAFF_SALARY_ADJUSTMENT",
  FETCH_ONE_STAFF_SALARY_ADJUSTMENT_RESET: "FETCH_ONE_STAFF_SALARY_ADJUSTMENT_RESET",
  FETCH_ONE_STAFF_SALARY_ADJUSTMENT_FAILURE: "FETCH_ONE_STAFF_SALARY_ADJUSTMENT_FAILURE",
  FETCH_ONE_STAFF_SALARY_ADJUSTMENT_SUCCESS: "FETCH_ONE_STAFF_SALARY_ADJUSTMENT_SUCCESS",
};
