import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PermanentEmployeeAttendanceActionTypes } from "./PermanentEmployeeAttendance.type";

export function* fetchAllPermanentEmployeeAttendances(action: any): any {
  try {
    // let query = "";
    // if (action.payload) {
    //   const keys = Object.keys(action.payload);
    //   query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    // }
    const response = yield axios.get(
      `${API_BASE_URI}/permanent_employee_attendance`
    );
    yield put({
      type: PermanentEmployeeAttendanceActionTypes.FETCH_ALL_PERMANENT_EMPLOYEE_ATTENDANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PermanentEmployeeAttendanceActionTypes.FETCH_ALL_PERMANENT_EMPLOYEE_ATTENDANCE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedPermanentEmployeeAttendances(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/permanent_employee_attendance?${query}`
    );
    yield put({
      type: PermanentEmployeeAttendanceActionTypes.FETCH_PAGED_PERMANENT_EMPLOYEE_ATTENDANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PermanentEmployeeAttendanceActionTypes.FETCH_PAGED_PERMANENT_EMPLOYEE_ATTENDANCE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePermanentEmployeeAttendances(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/permanent_employee_attendance/${action.payload}`
    );
    yield put({
      type: PermanentEmployeeAttendanceActionTypes.FETCH_ONE_PERMANENT_EMPLOYEE_ATTENDANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PermanentEmployeeAttendanceActionTypes.FETCH_ONE_PERMANENT_EMPLOYEE_ATTENDANCE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPermanentEmployeeAttendances() {
  yield takeLatest(
    PermanentEmployeeAttendanceActionTypes.FETCH_ALL_PERMANENT_EMPLOYEE_ATTENDANCE,
    fetchAllPermanentEmployeeAttendances
  );
}

export function* watcherFetchPagedPermanentEmployeeAttendances() {
  yield takeLatest(
    PermanentEmployeeAttendanceActionTypes.FETCH_PAGED_PERMANENT_EMPLOYEE_ATTENDANCE,
    fetchPagedPermanentEmployeeAttendances
  );
}

export function* watcherFetchOnePermanentEmployeeAttendances() {
  yield takeLatest(
    PermanentEmployeeAttendanceActionTypes.FETCH_ONE_PERMANENT_EMPLOYEE_ATTENDANCE,
    fetchOnePermanentEmployeeAttendances
  );
}
