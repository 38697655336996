import { ApiCallState } from "../Utils";

export type BudgetPlanningItem= {
  id: number;
  description: string;
  total: number;
  remark: string;
  budget_planning_item_details: BudgetPlanningItemDetail[];
};

type BudgetPlanningItemDetail = {
  id: number,
  month: string,
  amount: number
}

export type BudgetPlanningItemStateTypes = {
  fetchAll: ApiCallState<BudgetPlanningItem[]>;
  fetchOne: ApiCallState<BudgetPlanningItem | {}>;
};

export const BudgetPlanningItemActionTypes = {
  FETCH_ALL_BUDGET_PLANNING_ITEM: "FETCH_ALL_BUDGET_PLANNING_ITEM",
  FETCH_ALL_BUDGET_PLANNING_ITEM_RESET: "FETCH_ALL_BUDGET_PLANNING_ITEM_RESET",
  FETCH_ALL_BUDGET_PLANNING_ITEM_FAILURE: "FETCH_ALL_BUDGET_PLANNING_ITEM_FAILURE",
  FETCH_ALL_BUDGET_PLANNING_ITEM_SUCCESS: "FETCH_ALL_BUDGET_PLANNING_ITEM_SUCCESS",

  FETCH_ONE_BUDGET_PLANNING_ITEM: "FETCH_ONE_BUDGET_PLANNING_ITEM",
  FETCH_ONE_BUDGET_PLANNING_ITEM_RESET: "FETCH_ONE_BUDGET_PLANNING_ITEM_RESET",
  FETCH_ONE_BUDGET_PLANNING_ITEM_FAILURE: "FETCH_ONE_BUDGET_PLANNING_ITEM_FAILURE",
  FETCH_ONE_BUDGET_PLANNING_ITEM_SUCCESS: "FETCH_ONE_BUDGET_PLANNING_ITEM_SUCCESS",
};
