import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { FinanceDailyReportActionTypes } from "./FinanceDailyReport.type";

export function* fetchAllFinanceDailyReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/finance-daily-report?start_date=${action?.payload?.start_date}&end_date=${action?.payload?.end_date}&boq=${action?.payload?.boq}&date=${action?.payload?.date}&project_id=${action?.payload?.project_id}&before_date=${action?.payload?.before_date}`
    );
    yield put({
      type: FinanceDailyReportActionTypes.FETCH_ALL_FINANCE_DAILY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FinanceDailyReportActionTypes.FETCH_ALL_FINANCE_DAILY_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchFinanceDailyReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/finance-daily-report?project_id=${action?.payload?.project_id}&before_date=${action?.payload?.before_date}`
    );
    yield put({
      type: FinanceDailyReportActionTypes.FETCH_FINANCE_DAILY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FinanceDailyReportActionTypes.FETCH_FINANCE_DAILY_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneFinanceDailyReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/finance-daily-report/${action.payload}`
    );
    yield put({
      type: FinanceDailyReportActionTypes.FETCH_ONE_FINANCE_DAILY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FinanceDailyReportActionTypes.FETCH_ONE_FINANCE_DAILY_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllFinanceDailyReports() {
  yield takeLatest(
    FinanceDailyReportActionTypes.FETCH_ALL_FINANCE_DAILY_REPORT,
    fetchAllFinanceDailyReports
  );
}

export function* watcherFetchOneFinanceDailyReports() {
  yield takeLatest(
    FinanceDailyReportActionTypes.FETCH_ONE_FINANCE_DAILY_REPORT,
    fetchOneFinanceDailyReports
  );
}

export function* watcherFetchFinanceDailyReports() {
  yield takeLatest(
    FinanceDailyReportActionTypes.FETCH_FINANCE_DAILY_REPORT,
    fetchFinanceDailyReports
  );
}
