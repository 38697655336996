import { Moment } from "moment";
import { ApiCallState, PagedData } from "../Utils";

export type TireRequestForm = {
  key: any;
  plate_number: string;
  type_of_vehicle: string;
  serial_of_changed_tire: string;
  no_of_tires: number;
  date_of_change: Moment;
  no_of_km_tire_used: number;
  remark: string;
};
export type TireRequest = {
  id?: number;
  date: Moment;
  tire_request_form: TireRequestForm[];
  request_number?: string;

  request_by: string;
  project_manager: string;
  machinery_admin: string;
  project_property_admin: string;
  tire_request_no: number;
};

export type TireRequestStateTypes = {
  fetchAll: ApiCallState<TireRequest[]>;
  fetchOne: ApiCallState<TireRequest | {}>;
  fetchPaged: ApiCallState<PagedData<TireRequest[]>>;
};

export const TireRequestActionTypes = {
  FETCH_ALL_TIRE_REQUEST: "FETCH_ALL_TIRE_REQUEST",
  FETCH_ALL_TIRE_REQUEST_RESET: "FETCH_ALL_TIRE_REQUEST_RESET",
  FETCH_ALL_TIRE_REQUEST_FAILURE: "FETCH_ALL_TIRE_REQUEST_FAILURE",
  FETCH_ALL_TIRE_REQUEST_SUCCESS: "FETCH_ALL_TIRE_REQUEST_SUCCESS",

  FETCH_PAGED_TIRE_REQUEST: "FETCH_PAGED_TIRE_REQUEST",
  FETCH_PAGED_TIRE_REQUEST_RESET: "FETCH_PAGED_TIRE_REQUEST_RESET",
  FETCH_PAGED_TIRE_REQUEST_FAILURE: "FETCH_PAGED_TIRE_REQUEST_FAILURE",
  FETCH_PAGED_TIRE_REQUEST_SUCCESS: "FETCH_PAGED_TIRE_REQUEST_SUCCESS",

  FETCH_ONE_TIRE_REQUEST: "FETCH_ONE_TIRE_REQUEST",
  FETCH_ONE_TIRE_REQUEST_RESET: "FETCH_ONE_TIRE_REQUEST_RESET",
  FETCH_ONE_TIRE_REQUEST_FAILURE: "FETCH_ONE_TIRE_REQUEST_FAILURE",
  FETCH_ONE_TIRE_REQUEST_SUCCESS: "FETCH_ONE_TIRE_REQUEST_SUCCESS",
};
