import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { StaffLanguageActionTypes } from "./StaffLanguage.type";

export function* fetchAllStaffLanguages(action: any): any {
  try {
    let query = "";

    if (action.payload && Object.keys(action.payload).length > 0) {
      query =
        "?" +
        Object.keys(action.payload)
          .map((key) => `${key}=${action.payload[key]}`)
          .join("&");
    }

    const response = yield axios.get(`${API_BASE_URI}/staff-language${query}`);
    yield put({
      type: StaffLanguageActionTypes.FETCH_ALL_STAFF_LANGUAGE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffLanguageActionTypes.FETCH_ALL_STAFF_LANGUAGE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllStaffLanguages() {
  yield takeLatest(
    StaffLanguageActionTypes.FETCH_ALL_STAFF_LANGUAGE,
    fetchAllStaffLanguages
  );
}
