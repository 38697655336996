import { ApiCallState } from "../Utils";

export type BudgetPlanning= {
  id: number;
  start_month: string;
  end_month: string;
  project_id: number;
  material_budget_plannings: MaterialBudgetPlanning[],
  equipment_budget_plannings: EquipmentBudgetPlanning[],
  direct_labour_budget_plannings: DirectLabourBudgetPlanning[],
  indirect_labour_budget_plannings: InDirectLabourBudgetPlanning[],
  sub_contract_budget_plannings: SubContractBudgetPlanning[],
  indirect_cost_budget_plannings: IndirectCostBudgetPlanning[],
  budget_planning_items: BudgetPlanningItem[],
  budget_planning_statuses: any[]
};

export type BudgetPlanningItem = {
    id?: number,
    description: string,
    total: number,
    remark: string,
    budget_planning_id: number;
    budget_planning_item_details: BudgetPlanningItemDetail[]
}
export type BudgetPlanningItemDetail = {
    id?: number,
    month: string,
    amount: number
}

export type MaterialBudgetPlanning = {
    id?: number,
    description: string,
    total: number,
    remark: string,
    budget_planning_id: number;
    material_budget_planning_items: MaterialBudgetPlanningItem[]
}
export type MaterialBudgetPlanningItem = {
    id?: number,
    month: string,
    amount: number
}

export type EquipmentBudgetPlanning = {
    id?: number,
    description: string,
    total: number,
    remark: string,
    rate: number,
    budget_planning_id: number;
    equipment_budget_planning_items: EquipmentBudgetPlanningItem[]
}
export type EquipmentBudgetPlanningItem = {
    id?: number,
    month: string,
    amount: number
}

export type DirectLabourBudgetPlanning = {
    id?: number,
    description: string,
    total: number,
    remark: string,
    rate: number,
    budget_planning_id: number;
    direct_labour_budget_planning_items: DirectLabourBudgetPlanningItem[]
}
export type DirectLabourBudgetPlanningItem = {
    id?: number,
    month: string,
    amount: number
}

export type InDirectLabourBudgetPlanning = {
    id?: number,
    description: string,
    total: number,
    remark: string,
    rate: number,
    budget_planning_id: number;
    indirect_labour_budget_planning_items: InDirectLabourBudgetPlanningItem[]
}
export type InDirectLabourBudgetPlanningItem = {
    id?: number,
    month: string,
    amount: number
}

export type SubContractBudgetPlanning = {
    id?: number,
    description: string,
    total: number,
    remark: string,
    rate: number,
    budget_planning_id: number;
    sub_contract_budget_planning_items: SubContractBudgetPlanningItem[]
}
export type SubContractBudgetPlanningItem = {
    id?: number,
    month: string,
    amount: number
}

export type IndirectCostBudgetPlanning = {
    id?: number,
    description: string,
    total: number,
    remark: string,
    rate: number,
    budget_planning_id: number;
    indirect_cost_budget_planning_items: IndirectCostBudgetPlanningItem[]
}
export type IndirectCostBudgetPlanningItem = {
    id?: number,
    month: string,
    amount: number
}

export type BudgetPlanningStateTypes = {
  fetchAll: ApiCallState<BudgetPlanning[]>;
  fetchOne: ApiCallState<BudgetPlanning | {}>;
};

export const BudgetPlanningActionTypes = {
  FETCH_ALL_BUDGET_PLANNING: "FETCH_ALL_BUDGET_PLANNING",
  FETCH_ALL_BUDGET_PLANNING_RESET: "FETCH_ALL_BUDGET_PLANNING_RESET",
  FETCH_ALL_BUDGET_PLANNING_FAILURE: "FETCH_ALL_BUDGET_PLANNING_FAILURE",
  FETCH_ALL_BUDGET_PLANNING_SUCCESS: "FETCH_ALL_BUDGET_PLANNING_SUCCESS",

  FETCH_ONE_BUDGET_PLANNING: "FETCH_ONE_BUDGET_PLANNING",
  FETCH_ONE_BUDGET_PLANNING_RESET: "FETCH_ONE_BUDGET_PLANNING_RESET",
  FETCH_ONE_BUDGET_PLANNING_FAILURE: "FETCH_ONE_BUDGET_PLANNING_FAILURE",
  FETCH_ONE_BUDGET_PLANNING_SUCCESS: "FETCH_ONE_BUDGET_PLANNING_SUCCESS",
};
