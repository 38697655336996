import { InitPagedData } from "./../Utils";
import {
  PermanentEmployeeAttendanceStateTypes,
  PermanentEmployeeAttendanceActionTypes,
} from "./PermanentEmployeeAttendance.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: PermanentEmployeeAttendanceStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchPaged: resetApiCallState(InitPagedData),
};

const PermanentEmployeeAttendanceReducer = (
  state: PermanentEmployeeAttendanceStateTypes = INITIAL_STATE,
  action: any
): PermanentEmployeeAttendanceStateTypes => {
  switch (action.type) {
    case PermanentEmployeeAttendanceActionTypes.FETCH_ALL_PERMANENT_EMPLOYEE_ATTENDANCE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case PermanentEmployeeAttendanceActionTypes.FETCH_ALL_PERMANENT_EMPLOYEE_ATTENDANCE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case PermanentEmployeeAttendanceActionTypes.FETCH_ALL_PERMANENT_EMPLOYEE_ATTENDANCE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PermanentEmployeeAttendanceActionTypes.FETCH_ALL_PERMANENT_EMPLOYEE_ATTENDANCE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };
    case PermanentEmployeeAttendanceActionTypes.FETCH_PAGED_PERMANENT_EMPLOYEE_ATTENDANCE:
      return {
        ...state,
        fetchPaged: {
          error: null,
          payload: InitPagedData,
          isPending: true,
          isSuccessful: false,
        },
      };
    case PermanentEmployeeAttendanceActionTypes.FETCH_PAGED_PERMANENT_EMPLOYEE_ATTENDANCE_RESET:
      return {
        ...state,
        fetchPaged: resetApiCallState(InitPagedData),
      };
    case PermanentEmployeeAttendanceActionTypes.FETCH_PAGED_PERMANENT_EMPLOYEE_ATTENDANCE_FAILURE:
      return {
        ...state,
        fetchPaged: {
          payload: InitPagedData,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PermanentEmployeeAttendanceActionTypes.FETCH_PAGED_PERMANENT_EMPLOYEE_ATTENDANCE_SUCCESS:
      return {
        ...state,
        fetchPaged: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case PermanentEmployeeAttendanceActionTypes.FETCH_ONE_PERMANENT_EMPLOYEE_ATTENDANCE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case PermanentEmployeeAttendanceActionTypes.FETCH_ONE_PERMANENT_EMPLOYEE_ATTENDANCE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case PermanentEmployeeAttendanceActionTypes.FETCH_ONE_PERMANENT_EMPLOYEE_ATTENDANCE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PermanentEmployeeAttendanceActionTypes.FETCH_ONE_PERMANENT_EMPLOYEE_ATTENDANCE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default PermanentEmployeeAttendanceReducer;
