import { CanteenDeductionStateTypes, CanteenDeductionActionTypes } from "./CanteenDeduction.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: CanteenDeductionStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const CanteenDeductionReducer = (
  state: CanteenDeductionStateTypes = INITIAL_STATE,
  action: any
): CanteenDeductionStateTypes => {
  switch (action.type) {
    case CanteenDeductionActionTypes.FETCH_ALL_CANTEEN_DEDUCTION:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case CanteenDeductionActionTypes.FETCH_ALL_CANTEEN_DEDUCTION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case CanteenDeductionActionTypes.FETCH_ALL_CANTEEN_DEDUCTION_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case CanteenDeductionActionTypes.FETCH_ALL_CANTEEN_DEDUCTION_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case CanteenDeductionActionTypes.FETCH_ONE_CANTEEN_DEDUCTION:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case CanteenDeductionActionTypes.FETCH_ONE_CANTEEN_DEDUCTION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case CanteenDeductionActionTypes.FETCH_ONE_CANTEEN_DEDUCTION_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case CanteenDeductionActionTypes.FETCH_ONE_CANTEEN_DEDUCTION_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default CanteenDeductionReducer;
