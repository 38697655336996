import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { UncollectedIpcActionTypes } from "./UncollectedIpc.type";

export function* fetchAllUncollectedIpcs(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/uncollected-ipc`);
    yield put({
      type: UncollectedIpcActionTypes.FETCH_ALL_UNCOLLECTED_IPC_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: UncollectedIpcActionTypes.FETCH_ALL_UNCOLLECTED_IPC_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneUncollectedIpcs(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/uncollected-ipc/${action.payload}`
    );
    yield put({
      type: UncollectedIpcActionTypes.FETCH_ONE_UNCOLLECTED_IPC_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: UncollectedIpcActionTypes.FETCH_ONE_UNCOLLECTED_IPC_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllUncollectedIpcs() {
  yield takeLatest(UncollectedIpcActionTypes.FETCH_ALL_UNCOLLECTED_IPC, fetchAllUncollectedIpcs);
}

export function* watcherFetchOneUncollectedIpcs() {
  yield takeLatest(UncollectedIpcActionTypes.FETCH_ONE_UNCOLLECTED_IPC, fetchOneUncollectedIpcs);
}
