import { Material } from "../Material/Material.type";
import { Store } from "../Store/Store.type";
import { User } from "../User/User.type";
import { ApiCallState, PagedData } from "../Utils";

export type GoodIssue = {
  id: number;
  date: string;
  warehouse_id: number;
  project_id: number;
  material_requisition_id: number;
  user_id: number;
  warehouse: Store;
  project: Store;
  received_by: string;
  purpose: string;
  issued_to_house: string;
  issued_by: string;
  department: string;
  approved_by: number;
  checked_by: number;
  pad_ref: string;
  good_issue_remarks: GoodIssueRemark[];
  good_issue_statuses: GoodIssueStatus[];
  status: GoodIssueStatus[];
  good_issue_items: GoodIssueItem[];
  good_issue_prepared_by: User;
  good_issue_approved_by: User;
  good_issue_checked_by: User;
  good_issue_received_by: User;

  updatedAt: string;
  is_void: boolean;
};

export type GoodIssueReport = {
  approved_by: string;
  code: string;
  date: string;
  description: string;
  from: string;
  to: string;
  item_category: string;
  id: number;
  major_category: string;
  material_requisition_id: any;
  pad_ref?: any;
  prepared_by: string;
  quantity: number;
  received_by: string;
  remark: string;
  total: number;
  unit: string;
};

export type GoodIssueRemark = {
  time: string;
  remark: string;
  user_id: number;
  document?: Document;
};

export type GoodIssueStatus = {
  good_issue_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type GoodIssueItem = {
  material_requisition_item_id: number;
  good_issue_id?: number;
  material_id?: number;
  receiving_invoice_no?: number;
  receiving_invoice_type: string;
  quantity_issued: number;
  quantity_received: number;
  unit_price: number;
  unit: string;
  remark: string;
  available: number;
  material?: Material;
  key: number;
  id?: number;
};

export type GoodIssueStateTypes = {
  fetchAll: ApiCallState<GoodIssue[]>;
  fetchOne: ApiCallState<GoodIssue | {}>;
  fetchPaged: ApiCallState<PagedData<GoodIssue[]>>;
  fetchReport: ApiCallState<any[]>;
  fetchVehicleReport: ApiCallState<any[]>;
  fetchFormanReport: ApiCallState<any[]>;
};

export const GoodIssueActionTypes = {
  FETCH_ALL_GOOD_ISSUE: "FETCH_ALL_GOOD_ISSUE",
  FETCH_ALL_GOOD_ISSUE_RESET: "FETCH_ALL_GOOD_ISSUE_RESET",
  FETCH_ALL_GOOD_ISSUE_FAILURE: "FETCH_ALL_GOOD_ISSUE_FAILURE",
  FETCH_ALL_GOOD_ISSUE_SUCCESS: "FETCH_ALL_GOOD_ISSUE_SUCCESS",

  FETCH_REPORT_GOOD_ISSUE: "FETCH_REPORT_GOOD_ISSUE",
  FETCH_REPORT_GOOD_ISSUE_RESET: "FETCH_REPORT_GOOD_ISSUE_RESET",
  FETCH_REPORT_GOOD_ISSUE_FAILURE: "FETCH_REPORT_GOOD_ISSUE_FAILURE",
  FETCH_REPORT_GOOD_ISSUE_SUCCESS: "FETCH_REPORT_GOOD_ISSUE_SUCCESS",

  FETCH_PAGED_GOOD_ISSUE: "FETCH_PAGED_GOOD_ISSUE",
  FETCH_PAGED_GOOD_ISSUE_RESET: "FETCH_PAGED_GOOD_ISSUE_RESET",
  FETCH_PAGED_GOOD_ISSUE_FAILURE: "FETCH_PAGED_GOOD_ISSUE_FAILURE",
  FETCH_PAGED_GOOD_ISSUE_SUCCESS: "FETCH_PAGED_GOOD_ISSUE_SUCCESS",

  FETCH_ONE_GOOD_ISSUE: "FETCH_ONE_GOOD_ISSUE",
  FETCH_ONE_GOOD_ISSUE_RESET: "FETCH_ONE_GOOD_ISSUE_RESET",
  FETCH_ONE_GOOD_ISSUE_FAILURE: "FETCH_ONE_GOOD_ISSUE_FAILURE",
  FETCH_ONE_GOOD_ISSUE_SUCCESS: "FETCH_ONE_GOOD_ISSUE_SUCCESS",

  FETCH_VEHICLE_REPORT_GOOD_ISSUE: "FETCH_VEHICLE_REPORT_GOOD_ISSUE",
  FETCH_VEHICLE_REPORT_GOOD_ISSUE_RESET:
    "FETCH_VEHICLE_REPORT_GOOD_ISSUE_RESET",
  FETCH_VEHICLE_REPORT_GOOD_ISSUE_FAILURE:
    "FETCH_VEHICLE_REPORT_GOOD_ISSUE_FAILURE",
  FETCH_VEHICLE_REPORT_GOOD_ISSUE_SUCCESS:
    "FETCH_VEHICLE_REPORT_GOOD_ISSUE_SUCCESS",

  FETCH_GOOD_ISSUE_FORMAN_REPORT: "FETCH_GOOD_ISSUE_FORMAN_REPORT",
  FETCH_GOOD_ISSUE_FORMAN_REPORT_RESET: "FETCH_GOOD_ISSUE_FORMAN_REPORT_RESET",
  FETCH_GOOD_ISSUE_FORMAN_REPORT_FAILURE:
    "FETCH_GOOD_ISSUE_FORMAN_REPORT_FAILURE",
  FETCH_GOOD_ISSUE_FORMAN_REPORT_SUCCESS:
    "FETCH_GOOD_ISSUE_FORMAN_REPORT_SUCCESS",
};
