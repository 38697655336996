import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type OvertimeRequest2 = {
  id: number;
  user_id: number;
  staff_id: number;

  approved_by_id: number;

  request_date: string;
  overtime_date: string;
  request_start_time: string;
  request_end_time: string;
  reason: string;
  facility: string;
  is_approved: boolean;

  report: string;
  report_start_time: string;
  report_end_time: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  staff: Staff;
};

export type OvertimeRequest2StateTypes = {
  fetchAll: ApiCallState<OvertimeRequest2[]>;
  fetchOne: ApiCallState<OvertimeRequest2 | {}>;
};

export const OvertimeRequest2ActionTypes = {
  FETCH_ALL_OVERTIME_REQUEST2: "FETCH_ALL_OVERTIME_REQUEST2",
  FETCH_ALL_OVERTIME_REQUEST2_RESET: "FETCH_ALL_OVERTIME_REQUEST2_RESET",
  FETCH_ALL_OVERTIME_REQUEST2_FAILURE: "FETCH_ALL_OVERTIME_REQUEST2_FAILURE",
  FETCH_ALL_OVERTIME_REQUEST2_SUCCESS: "FETCH_ALL_OVERTIME_REQUEST2_SUCCESS",

  FETCH_ONE_OVERTIME_REQUEST2: "FETCH_ONE_OVERTIME_REQUEST2",
  FETCH_ONE_OVERTIME_REQUEST2_RESET: "FETCH_ONE_OVERTIME_REQUEST2_RESET",
  FETCH_ONE_OVERTIME_REQUEST2_FAILURE: "FETCH_ONE_OVERTIME_REQUEST2_FAILURE",
  FETCH_ONE_OVERTIME_REQUEST2_SUCCESS: "FETCH_ONE_OVERTIME_REQUEST2_SUCCESS",
};
