import { JustificationStateTypes, JustificationActionTypes } from "./Justification.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: JustificationStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const JustificationReducer = (
  state: JustificationStateTypes = INITIAL_STATE,
  action: any
): JustificationStateTypes => {
  switch (action.type) {
    case JustificationActionTypes.FETCH_ALL_JUSTIFICATION:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case JustificationActionTypes.FETCH_ALL_JUSTIFICATION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case JustificationActionTypes.FETCH_ALL_JUSTIFICATION_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case JustificationActionTypes.FETCH_ALL_JUSTIFICATION_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case JustificationActionTypes.FETCH_ONE_JUSTIFICATION:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case JustificationActionTypes.FETCH_ONE_JUSTIFICATION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case JustificationActionTypes.FETCH_ONE_JUSTIFICATION_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case JustificationActionTypes.FETCH_ONE_JUSTIFICATION_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default JustificationReducer;
