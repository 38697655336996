import { Absence } from "../Absence/Absence.type";
import { Department } from "../Department/Department.type";
import { ManpowerOvertime } from "../ManpowerOvertime/ManpowerOvertime.type";
import { ProjectStaff } from "../ProjectStaff/ProjectStaff.type";
import { ApiCallState } from "../Utils";
import { EmployeeAllowance } from "../EmployeeAllowance/EmployeeAllowance.type";
import { ProjectTeam } from "../ProjectTeam/ProjectTeam.type";
import { StaffEducation } from "../StaffEducation/StaffEducation.type";
import { Promotion } from "../Promotion/Promotion.type";
import { StaffPlaceOfWork } from "../StaffPlaceOfWork/StaffPlaceOfWork.type";
import { Grade } from "../Grade/Grade.type";
import { ScaleItem } from "../ScaleItem/ScaleItem.type";
import { StaffWorkExperience } from "../StaffWorkExperience/StaffWorkExperience.type";
import { StaffTaxInformation } from "../StaffTaxInformation/StaffTaxInformation.type";
import { StaffReligion } from "../StaffReligion/StaffReligion.type";
import { StaffTransfer } from "../StaffTransfer/StaffTransfer.type";
import { StaffSalaryAdjustment } from "../StaffSalaryAdjustment/StaffSalaryAdjustment.type";
import { ApplicantRegistryItem } from "../ApplicantRegistryItem/ApplicantRegistryItem.type";
import { StaffFamily } from "../StaffFamily/StaffFamily.type";
import { StaffEmergencyContact } from "../StaffEmergencyContact/StaffEmergencyContact.type";

export type Staff = {
  id: any;
  department_id: number;
  name: string;
  name_am:string;
  sex: string;
  description: string;
  salary: number;
  status: string;
  type: string;
  woreda: number;
  email: string;
  city: string;
  sub_city:string;
  house_number: string;
  termination_date: string;
  department: Department | null;
  projects: { name: string; id: number; project_staff: ProjectStaff }[] | null;
  date: string;
  end_date: string;
  birth_day: string;
  key: number;
  grade_id: number;
  scale_item_id: number;
  manpower_overtimes: ManpowerOvertime[];
  emergency_contact: EmergencyContact;
  employee_histories: EmployeeHistory[];
  employee_allowances: EmployeeAllowance[];
  tin_number: string;
  pension_number: string;
  account_number: string;
  spouse_name: string;
  employee_requests: {
    request_type: string;
    date: string;
    leave_request: {
      paid: number;
      unpaid: number;
      type: string;
      description: string;
      start_date: string;
      end_date: string;
      leave_finance: string;
    } | null;
    loan_request: {
      repayment_type: string;
      repayment_amount: number;
      amount: number;
      paid: number;
      reason: string;
    } | null;
    overtime_request: {
      description: string;
      date: string;
      overtime_start: string;
      overtime_end: string;
    } | null;
    travel_request: null;
    benefit_request: {
      type: string;
      description: string;
      amount: number;
    } | null;
  }[];
  equipment_wo_staff: { task: string };
  phone_number: string;
  period: string;
  createdAt: string;
  photo: string;
  unique_uuid: string;
  work_location: string;

  religion: string;
  marital_status: string;
  blood_type: string;
  mother_full_name: string;


  absences: Absence[];

  project_teams: ProjectTeam[];
  staff_educations: StaffEducation[];
  promotions: Promotion[];
  staff_place_of_works: StaffPlaceOfWork[];
  grade:Grade;
  scale_item:ScaleItem;
  staff_work_experiences:StaffWorkExperience[];
  staff_tax_informations:StaffTaxInformation[];
  staff_religions:StaffReligion[];
  staff_transfers:StaffTransfer[];
  staff_salary_adjustments:StaffSalaryAdjustment[];

  government_id: string;
  file_location:string;
  physical_condition:string;
  nationality:string;
  id_no:string;
  work_location_type:string;

  applicant_registry_item_id: number;
  sex_am:string;
  city_am:string;
  sub_city_am:string;
  religion_am: string;
  marital_status_am: string;
  citizenship:string;
  citizenship_am:string;
  living_status:string;
  living_status_am:string;
  hobby:string;
  hobby_am:string;
  owner_name:string;
  owner_occupation:string;
  owner_occupation_am:string;
  owner_city:string;
  owner_city_am:string;
  owner_woreda:number;
  owner_sub_city:string;
  owner_sub_city_am:string;
  owner_house_no:string;
  owner_phone_no:string;
  pension_identification_no:string;
  probation_period:string;
  applicant_registry_item:ApplicantRegistryItem;
  staff_families:StaffFamily[];
  staff_emergency_contacts:StaffEmergencyContact[];
};

export type EmergencyContact = {
  id: number;
  name: string;
  phone_number: string;
  relation: string;
};

export type EmployeeHistory = {
  id: number;
  staff_id: number;
  department_id: number;
  salary: number;
  allowance: number;
  overtime_salary: number;
  type: string;
  change: string;
  date: string;
  createdAt: "2021-04-21T15:02:37.000Z";
  updatedAt: "2021-04-21T15:02:37.000Z";
};

export type StaffStateTypes = {
  fetchAll: ApiCallState<Staff[]>;
  fetchOne: ApiCallState<Staff | null>;
  fetchAllDetail: ApiCallState<Staff[]>;
};

export const StaffActionTypes = {
  FETCH_ALL_STAFF: "FETCH_ALL_STAFF",
  FETCH_ALL_STAFF_RESET: "FETCH_ALL_STAFF_RESET",
  FETCH_ALL_STAFF_FAILURE: "FETCH_ALL_STAFF_FAILURE",
  FETCH_ALL_STAFF_SUCCESS: "FETCH_ALL_STAFF_SUCCESS",

  FETCH_ALL_DETAIL_STAFF: "FETCH_ALL_DETAIL_STAFF",
  FETCH_ALL_STAFF_DETAIL_RESET: "FETCH_ALL_STAFF_DETAIL_RESET",
  FETCH_ALL_STAFF_DETAIL_FAILURE: "FETCH_ALL_STAFF_DETAIL_FAILURE",
  FETCH_ALL_STAFF_DETAIL_SUCCESS: "FETCH_ALL_STAFF_DETAIL_SUCCESS",

  FETCH_ONE_STAFF: "FETCH_ONE_STAFF",
  FETCH_ONE_STAFF_RESET: "FETCH_ONE_STAFF_RESET",
  FETCH_ONE_STAFF_FAILURE: "FETCH_ONE_STAFF_FAILURE",
  FETCH_ONE_STAFF_SUCCESS: "FETCH_ONE_STAFF_SUCCESS",
};
