import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { JournalVoucherActionTypes } from "./JournalVoucher.type";

export function* fetchAllJournalVouchers(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/journal-voucher`);
    yield put({
      type: JournalVoucherActionTypes.FETCH_ALL_JOURNAL_VOUCHER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: JournalVoucherActionTypes.FETCH_ALL_JOURNAL_VOUCHER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneJournalVouchers(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/journal-voucher/${action.payload}`
    );
    yield put({
      type: JournalVoucherActionTypes.FETCH_ONE_JOURNAL_VOUCHER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: JournalVoucherActionTypes.FETCH_ONE_JOURNAL_VOUCHER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllJournalVouchers() {
  yield takeLatest(JournalVoucherActionTypes.FETCH_ALL_JOURNAL_VOUCHER, fetchAllJournalVouchers);
}

export function* watcherFetchOneJournalVouchers() {
  yield takeLatest(JournalVoucherActionTypes.FETCH_ONE_JOURNAL_VOUCHER, fetchOneJournalVouchers);
}
