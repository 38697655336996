import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ManningActionTypes } from "./Manning.type";

export function* fetchAllMannings(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/manning?manning_category_id=${action.payload?.manning_category_id}`);
    yield put({
      type: ManningActionTypes.FETCH_ALL_MANNING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ManningActionTypes.FETCH_ALL_MANNING_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMannings(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/manning/${action.payload}`
    );
    yield put({
      type: ManningActionTypes.FETCH_ONE_MANNING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ManningActionTypes.FETCH_ONE_MANNING_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMannings() {
  yield takeLatest(ManningActionTypes.FETCH_ALL_MANNING, fetchAllMannings);
}

export function* watcherFetchOneMannings() {
  yield takeLatest(ManningActionTypes.FETCH_ONE_MANNING, fetchOneMannings);
}
