import { WeeklyProgressSummaryStateTypes, WeeklyProgressSummaryActionTypes } from "./WeeklyProgressSummary.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: WeeklyProgressSummaryStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const WeeklyProgressSummaryReducer = (
  state: WeeklyProgressSummaryStateTypes = INITIAL_STATE,
  action: any
): WeeklyProgressSummaryStateTypes => {
  switch (action.type) {
    case WeeklyProgressSummaryActionTypes.FETCH_ALL_WEEKLY_PROGRESS_SUMMARY:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case WeeklyProgressSummaryActionTypes.FETCH_ALL_WEEKLY_PROGRESS_SUMMARY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case WeeklyProgressSummaryActionTypes.FETCH_ALL_WEEKLY_PROGRESS_SUMMARY_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case WeeklyProgressSummaryActionTypes.FETCH_ALL_WEEKLY_PROGRESS_SUMMARY_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case WeeklyProgressSummaryActionTypes.FETCH_ONE_WEEKLY_PROGRESS_SUMMARY:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case WeeklyProgressSummaryActionTypes.FETCH_ONE_WEEKLY_PROGRESS_SUMMARY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case WeeklyProgressSummaryActionTypes.FETCH_ONE_WEEKLY_PROGRESS_SUMMARY_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case WeeklyProgressSummaryActionTypes.FETCH_ONE_WEEKLY_PROGRESS_SUMMARY_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default WeeklyProgressSummaryReducer;
