import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { BudgetActionTypes } from "./Budget.type";

export function* fetchAllBudgets(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/budget`);
    yield put({
      type: BudgetActionTypes.FETCH_ALL_BUDGET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BudgetActionTypes.FETCH_ALL_BUDGET_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneBudgets(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/budget/one?project_id=${action.payload?.project_id}&year=${action.payload?.year}`
    );
    yield put({
      type: BudgetActionTypes.FETCH_ONE_BUDGET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BudgetActionTypes.FETCH_ONE_BUDGET_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllBudgets() {
  yield takeLatest(BudgetActionTypes.FETCH_ALL_BUDGET, fetchAllBudgets);
}

export function* watcherFetchOneBudgets() {
  yield takeLatest(BudgetActionTypes.FETCH_ONE_BUDGET, fetchOneBudgets);
}
