import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { DiemPolicyActionTypes } from "./DiemPolicy.type";
import { formatQuery } from "../Utils";

export function* fetchAllDiemPolicys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/diem-policy?${formatQuery(action)}`
    );
    yield put({
      type: DiemPolicyActionTypes.FETCH_ALL_DIEM_POLICY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DiemPolicyActionTypes.FETCH_ALL_DIEM_POLICY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedDiemPolicys(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/diem-policy?${query}`);
    yield put({
      type: DiemPolicyActionTypes.FETCH_PAGED_DIEM_POLICY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DiemPolicyActionTypes.FETCH_PAGED_DIEM_POLICY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneDiemPolicys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/diem-policy/${action.payload}`
    );
    yield put({
      type: DiemPolicyActionTypes.FETCH_ONE_DIEM_POLICY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DiemPolicyActionTypes.FETCH_ONE_DIEM_POLICY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllDiemPolicys() {
  yield takeLatest(
    DiemPolicyActionTypes.FETCH_ALL_DIEM_POLICY,
    fetchAllDiemPolicys
  );
}

export function* watcherFetchPagedDiemPolicys() {
  yield takeLatest(
    DiemPolicyActionTypes.FETCH_PAGED_DIEM_POLICY,
    fetchPagedDiemPolicys
  );
}

export function* watcherFetchOneDiemPolicys() {
  yield takeLatest(
    DiemPolicyActionTypes.FETCH_ONE_DIEM_POLICY,
    fetchOneDiemPolicys
  );
}
