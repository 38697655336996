import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { IncomeExpenseActionTypes } from "./IncomeExpense.type";


export function* fetchIncomeExpenseReport(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/income-expense/report?project_id=${action.payload?.project_id}&date=${action.payload?.date}`
    );
    yield put({
      type: IncomeExpenseActionTypes.FETCH_INCOME_EXPENSE_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: IncomeExpenseActionTypes.FETCH_INCOME_EXPENSE_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchProgressSummary(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/income-expense/progress-summary?project_id=${action.payload?.project_id}&date=${action.payload?.date}`
    );
    yield put({
      type: IncomeExpenseActionTypes.FETCH_PROGRESS_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: IncomeExpenseActionTypes.FETCH_PROGRESS_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchProjectIncome(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/income-expense/project-income?project_id=${action.payload?.project_id}&start_date=${action.payload?.start_date}&end_date=${action.payload?.end_date}`
    );
    yield put({
      type: IncomeExpenseActionTypes.FETCH_PROJECT_INCOME_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: IncomeExpenseActionTypes.FETCH_PROJECT_INCOME_FAILURE,
      payload: error,
    });
  }
}

export function* fetchBillSummary(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/income-expense/bill-summary?project_id=${action.payload?.project_id}&start_date=${action.payload?.start_date}&end_date=${action.payload?.end_date}`
    );
    yield put({
      type: IncomeExpenseActionTypes.FETCH_BILL_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: IncomeExpenseActionTypes.FETCH_BILL_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchIncomeExpense(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/income-expense/summary?project_id=${action.payload?.project_id}&start_date=${action.payload?.start_date}&end_date=${action.payload?.end_date}`
    );
    yield put({
      type: IncomeExpenseActionTypes.FETCH_INCOME_EXPENSE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: IncomeExpenseActionTypes.FETCH_INCOME_EXPENSE_FAILURE,
      payload: error,
    });
  }
}


export function* watcherFetchIncomeExpenseReport() {
  yield takeLatest(IncomeExpenseActionTypes.FETCH_INCOME_EXPENSE_REPORT, fetchIncomeExpenseReport);
}

export function* watcherFetchProgressSummary() {
  yield takeLatest(IncomeExpenseActionTypes.FETCH_PROGRESS_SUMMARY, fetchProgressSummary);
}

export function* watcherFetchProjectIncome() {
  yield takeLatest(IncomeExpenseActionTypes.FETCH_PROJECT_INCOME, fetchProjectIncome);
}

export function* watcherFetchBillSummary() {
  yield takeLatest(IncomeExpenseActionTypes.FETCH_BILL_SUMMARY, fetchBillSummary);
}

export function* watcherFetchIncomeExpense() {
  yield takeLatest(IncomeExpenseActionTypes.FETCH_INCOME_EXPENSE, fetchIncomeExpense);
}