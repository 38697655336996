import { PeridiumRulesItem } from "../PeridiumRules/PeridiumRules.type";
import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type PeridiumRequest = {
  id: number;
  date: string;
  staff_id: number;
  travel_from: string;
  travel_to: string;
  date_from: string;
  date_to: string;
  peridium_rules_item_id: number;
  reason:string;
  deduction_month: number;
  deduction_from: number;
  deduction_to: number;
  transportation_from: number;
  transportation_to: number;
  daily_allowance_amount: number;
  daily_allowance_days: number;
  perdium_allowance_amount: number;
  perdium_allowance_days: number;
  bed_allowance_amount: number;
  bed_allowance_days: number;
  with_tariff: number;
  transportation_fee: number;
  other_cost: number;

  requested_by:number;
  reviewed_by:number;
  checked_by:number;
  approved_by:number;
  user_id: number;
  staff: Staff;
  peridium_rules_item: PeridiumRulesItem;
  peridium_request_statuses: PeridiumRequestStatus[];
};

export type PeridiumRequestStatus = {
  peridium_request_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type PeridiumRequestStateTypes = {
  fetchAll: ApiCallState<PeridiumRequest[]>;
  fetchOne: ApiCallState<PeridiumRequest | {}>;
};

export const PeridiumRequestActionTypes = {
  FETCH_ALL_PERIDIUM_REQUEST: "FETCH_ALL_PERIDIUM_REQUEST",
  FETCH_ALL_PERIDIUM_REQUEST_RESET: "FETCH_ALL_PERIDIUM_REQUEST_RESET",
  FETCH_ALL_PERIDIUM_REQUEST_FAILURE: "FETCH_ALL_PERIDIUM_REQUEST_FAILURE",
  FETCH_ALL_PERIDIUM_REQUEST_SUCCESS: "FETCH_ALL_PERIDIUM_REQUEST_SUCCESS",

  FETCH_ONE_PERIDIUM_REQUEST: "FETCH_ONE_PERIDIUM_REQUEST",
  FETCH_ONE_PERIDIUM_REQUEST_RESET: "FETCH_ONE_PERIDIUM_REQUEST_RESET",
  FETCH_ONE_PERIDIUM_REQUEST_FAILURE: "FETCH_ONE_PERIDIUM_REQUEST_FAILURE",
  FETCH_ONE_PERIDIUM_REQUEST_SUCCESS: "FETCH_ONE_PERIDIUM_REQUEST_SUCCESS",
};
