import { Material } from "../Material/Material.type";

import { PurchaseOrder } from "../PurchaseOrder/PurchaseOrder.type";
import { PurchaseRequisition } from "../PurchaseRequisition/PurchaseRequisition.type";
import { Store } from "../Store/Store.type";
import { Supplier } from "../Supplier/Supplier.type";
import { User } from "../User/User.type";
import { ApiCallState, PagedData } from "../Utils";

export type GoodReceived = {
  id: number;
  type: string;
  supplier_id: number;
  purchase_order?: PurchaseOrder;
  supplier?: Supplier;
  purchase_order_id: number;
  supplier_invoice: string;
  purchase_requisition_id: number;
  purchase_requisition: PurchaseRequisition;
  received_by: string;
  gr_receiving_warehouse: Store;
  prepared_by: string;
  authorized_by: string;
  driver_name: string;
  delivered_by: string;
  date: string;
  inventory_type: string;
  purchase_type: string;
  store_id: number;
  good_received_type: string;
  good_received_items: GoodReceivedItem[];
  is_done: boolean;
  gr_prepared_by?: User;
  gr_inspected_by?: User;
  gr_approved_by?: User;
  good_received_statuses: GoodReceivedStatus[];
  status: GoodReceivedStatus[];
  store: Store;
  approved_by: string;
  pad_ref: string;
  has_vat: boolean;
  has_withhold: boolean;
  updatedAt: Date;
  receiving_type: string;
  donation: string;
  waybill_no: string;
  user_id: number;
  ref_no: string;
  is_void: boolean;
};

export type GoodReceivedReport = {
  approved_by?: any;
  code: string;
  date: string;
  description: string;
  from: string;
  to: string;
  item_category: string;
  id: number;
  major_category: string;
  purchase_requisition_id: string;
  pad_ref: string;
  prepared_by: string;
  quantity: number;
  received_by?: any;
  remark: string;
  total: number;
  unit: string;
};

export type GoodReceivedStatus = {
  good_out_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type GoodReceivedItem = {
  material: Material;
  material_id: number;
  unit_price: number;
  quantity: number;
  damaged_quantity: number;
  unit: string;
  identification_number: number;
  id: any;
};

export type GoodReceivedStateTypes = {
  fetchAll: ApiCallState<GoodReceived[]>;
  fetchOne: ApiCallState<GoodReceived | null>;
  fetchReport: ApiCallState<GoodReceivedReport[]>;
  fetchPaged: ApiCallState<PagedData<GoodReceived[]>>;
};

export const GoodReceivedActionTypes = {
  FETCH_ALL_GOOD_RECEIVED: "FETCH_ALL_GOOD_RECEIVED",
  FETCH_ALL_GOOD_RECEIVED_RESET: "FETCH_ALL_GOOD_RECEIVED_RESET",
  FETCH_ALL_GOOD_RECEIVED_FAILURE: "FETCH_ALL_GOOD_RECEIVED_FAILURE",
  FETCH_ALL_GOOD_RECEIVED_SUCCESS: "FETCH_ALL_GOOD_RECEIVED_SUCCESS",

  FETCH_ONE_GOOD_RECEIVED: "FETCH_ONE_GOOD_RECEIVED",
  FETCH_ONE_GOOD_RECEIVED_RESET: "FETCH_ONE_GOOD_RECEIVED_RESET",
  FETCH_ONE_GOOD_RECEIVED_FAILURE: "FETCH_ONE_GOOD_RECEIVED_FAILURE",
  FETCH_ONE_GOOD_RECEIVED_SUCCESS: "FETCH_ONE_GOOD_RECEIVED_SUCCESS",

  FETCH_PAGED_GOOD_RECEIVED: "FETCH_PAGED_GOOD_RECEIVED",
  FETCH_PAGED_GOOD_RECEIVED_RESET: "FETCH_PAGED_GOOD_RECEIVED_RESET",
  FETCH_PAGED_GOOD_RECEIVED_FAILURE: "FETCH_PAGED_GOOD_RECEIVED_FAILURE",
  FETCH_PAGED_GOOD_RECEIVED_SUCCESS: "FETCH_PAGED_GOOD_RECEIVED_SUCCESS",

  FETCH_GOOD_RECEIVED_REPORT: "FETCH_GOOD_RECEIVED_REPORT",
  FETCH_GOOD_RECEIVED_REPORT_RESET: "FETCH_GOOD_RECEIVED_REPORT_RESET",
  FETCH_GOOD_RECEIVED_REPORT_FAILURE: "FETCH_GOOD_RECEIVED_REPORT_FAILURE",
  FETCH_GOOD_RECEIVED_REPORT_SUCCESS: "FETCH_GOOD_RECEIVED_REPORT_SUCCESS",
};
