import { lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import LoadingIndicator from "../components/common/Loading";
import AssessmentSummary from "../components/HR/AssessmentSummary";

import ErrorBoundary from "../containers/Errors/ErrorBoundary/ErrorBoundary.component";
import Layout from "../containers/Layouts/Layout/Layout.component";
import { checkAuthorization, isLoggedIn } from "../utilities/utilities";
import { RouteConstants } from "./Constants";

const ClientSummaryComponent = lazy(
  () => import("../containers/ClientSummary/ClientSummary.component")
);

const ProjectListComponent = lazy(
  () => import("../containers/Project/List.component")
);
const EnterpriseLoginComponent = lazy(() => import("../components/Login"));

const SignupComponent = lazy(() => import("../components/Signup/index"));

const RegisterProjectComponent = lazy(
  () => import("../components/Project/ProjectRegistration")
);

const ReportComponent = lazy(
  () => import("../containers/MikadaReport/MikadaReport.component")
);

const HRComponent = lazy(() => import("../containers/HR/HR.component"));
const AssessmentComponent = lazy(() => import("../components/HR/Assessment"));

const WorkExperienceSummaryComponent = lazy(() => import("../components/HR/WorkExperienceSummary"));

const ProcurementComponent = lazy(
  () => import("../containers/Procurement/Procurement.component")
);

const EmployeeComponent = lazy(
  () => import("../containers/Employee/Employee.component")
);

const OperationComponent = lazy(
  () => import("../containers/Operation/Operation.container")
);

const FinanceComponent = lazy(
  () => import("../containers/Finance/Finance.component")
);

const InventoryComponent = lazy(
  () => import("../containers/Inventory/Inventory.component")
);

const FixedAssetComponent = lazy(
  () => import("../containers/FixedAsset/FixedAsset.component")
);

const UserManagementComponent = lazy(
  () => import("../containers/UserManagement")
);

const Routes = () => {
  const PrivateRoute = ({ ...props }: any) => {
    const { children, ...rest } = props;
    const isAuthenticated = isLoggedIn();

    let isAuthorized: any = false;
    if (isAuthenticated) {
      isAuthorized = checkAuthorization(rest.path);
    }

    return (
      <Route
        {...rest}
        render={({ location }) =>
          isAuthenticated ? (
            children
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: location } }} />
          )
        }
      />
    );
  };

  return (
    <>
      <ErrorBoundary>
        <Switch>
          <Route exact path={RouteConstants.LOGIN}>
            <Suspense fallback={<LoadingIndicator />}>
              <EnterpriseLoginComponent />
            </Suspense>
          </Route>
          <Route exact path={RouteConstants.SIGN_UP}>
            <Suspense fallback={<LoadingIndicator />}>
              <SignupComponent />
            </Suspense>
          </Route>
          <PrivateRoute exact path={RouteConstants.DASHBOARD}>
            <Redirect to={{ pathname: RouteConstants.PROJECTS }} />
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.HUMAN_RESOURCE_INDEX}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <HRComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.HUMAN_RESOURCE}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <HRComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.HUMAN_RESOURCE_DATA_ENTRY}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <HRComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.EMPLOYEE}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <EmployeeComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute
            exact
            path={RouteConstants.HR_ASSESSMENT}
          >
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <AssessmentComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={RouteConstants.HR_ASSESSMENT_SUMMARY}
          >
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <AssessmentSummary />
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={RouteConstants.HR_WORK_EXPERIENCE_SUMMARY}
          >
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <WorkExperienceSummaryComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.PROJECT_LIST}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <ProjectListComponent key="1" project_type="post-contract" />
              </Suspense>
            </Layout>
          </PrivateRoute>

          <PrivateRoute exact path={RouteConstants.CLIENT_SUMMARY}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <ClientSummaryComponent key="15" location="" />
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.PROJECTS}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <ProjectListComponent key="9" project_type="post-contract" />
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.REGISTER_PROJECT}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <RegisterProjectComponent project_type="post-contract" />
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.REPORT}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <ReportComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.PROCUREMENT}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <ProcurementComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.OPERATION}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <OperationComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.FINANCE}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <FinanceComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.INVENTORY}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <InventoryComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.FIXED_ASSET}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <FixedAssetComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path={RouteConstants.USER_MANAGEMENT}>
            <Layout>
              <Suspense fallback={<LoadingIndicator />}>
                <UserManagementComponent />
              </Suspense>
            </Layout>
          </PrivateRoute>
        </Switch>
      </ErrorBoundary>
    </>
  );
};

/**
 * Map State to Props
 *
 * @param state
 */
const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(Routes);