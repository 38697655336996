import { Boq } from "../Boq/Boq.type";
import { ApiCallState } from "../Utils";

export type DailyProductivityReport = {
  id: number;
  date: string;
  daily_productivity_report_items: DailyProductivityReportItem[];
};

export type DailyProductivityReportItem = {
  id: number;
  hauling_distance_for_material_dumping: number;
  amount_net_profit_loss: number;
  amount_net_profit_loss_percentage: number;
  status: string;
  loss_justification: string;
  boq_id: number;
  daily_productivity_report_id: number;
  boq: Boq,
  daily_productivity_report_details: DailyProductivityReportDetail[];
};

export type DailyProductivityReportDetail = {
  id: number;
  from_station: string;
  to_station: string;
  side: string;
  unit: string;
  unit_rate: number;
  unit_rate_factor: number;
  actual_executed_quantity: number;
  actual_executed_amount: number;
  amec_description: string;
  amec_plate_number: string;
  amec_down_hour: number;
  amec_cause: string;
  amec_number: number;
  amec_rate: number;
  amec_operational_hour: number;
  amec_idle_hour: number;
  amec_oid_case: number;
  amec_total_cost: number;
  fc_quantity: number;
  fc_unit_price: number;
  fc_total_cost: number;
  bmcof_description: string;
  bmcof_unit: string;
  bmcof_quantity: number;
  bmcof_unit_price: number;
  bmcof_total_cost: number;
  cmlc_description: string;
  cmlc_number: number;
  cmlc_daily_rate: number;
  cmlc_duration: number;
  cmlc_ut_factor: number;
  cmlc_wage: number;
  cmlc_total_cost: number;
  total_expense: number;
  daily_productivity_report_item_id: number;
};

export type DailyProductivityReportStateTypes = {
  fetchAll: ApiCallState<DailyProductivityReport[]>;
  fetchOne: ApiCallState<DailyProductivityReport | {}>;
};

export const DailyProductivityReportActionTypes = {
  FETCH_ALL_DAILY_PRODUCTIVITY_REPORT: "FETCH_ALL_DAILY_PRODUCTIVITY_REPORT",
  FETCH_ALL_DAILY_PRODUCTIVITY_REPORT_RESET:
    "FETCH_ALL_DAILY_PRODUCTIVITY_REPORT_RESET",
  FETCH_ALL_DAILY_PRODUCTIVITY_REPORT_FAILURE:
    "FETCH_ALL_DAILY_PRODUCTIVITY_REPORT_FAILURE",
  FETCH_ALL_DAILY_PRODUCTIVITY_REPORT_SUCCESS:
    "FETCH_ALL_DAILY_PRODUCTIVITY_REPORT_SUCCESS",

  FETCH_ONE_DAILY_PRODUCTIVITY_REPORT: "FETCH_ONE_DAILY_PRODUCTIVITY_REPORT",
  FETCH_ONE_DAILY_PRODUCTIVITY_REPORT_RESET:
    "FETCH_ONE_DAILY_PRODUCTIVITY_REPORT_RESET",
  FETCH_ONE_DAILY_PRODUCTIVITY_REPORT_FAILURE:
    "FETCH_ONE_DAILY_PRODUCTIVITY_REPORT_FAILURE",
  FETCH_ONE_DAILY_PRODUCTIVITY_REPORT_SUCCESS:
    "FETCH_ONE_DAILY_PRODUCTIVITY_REPORT_SUCCESS",
};
