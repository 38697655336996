import { Form, Table } from "antd";
import PrintHeader from "../../../common/PrintHeader/PrintHeader";
import { FC, useEffect } from "react";
import { Department } from "../../../../redux/Department/Department.type";
import {
  formatAssessmentSummary,
  formatEvaluator,
} from "../util/AssessmentSummary.util";
import { formatNumber } from "../../../../utilities/utilities";
const PrintAssessmentSummary: FC<{
  department: Department;
  date: String;
  dataAction: [any, React.Dispatch<React.SetStateAction<any>>];
  visibilityAction: [any, React.Dispatch<React.SetStateAction<any>>];
}> = ({ department, date, dataAction, visibilityAction }) => {
  const [applicantRegistry, setApplicantRegistry] = dataAction;
  const [visibility, setVisibility] = visibilityAction;

  window.onafterprint = () => {
    setVisibility(false);
    setApplicantRegistry(null);
  };

  useEffect(() => {
    if (applicantRegistry && visibility) window.print();
  }, [applicantRegistry, visibility]);

  return (
    <>
      <style type="text/css" media="print">
        {
          "\
      @page {size: A4 landscape}\
      "
        }
      </style>
      <div className="visible-print assessment-print">
        <div className="col-md-12">
          <PrintHeader
            type="Candidate assessment summary form"
            pageNo="1 of 1"
            documentNo="01"
          />
          <div className="row justify-content-center">
            <div
              className="contract-footer text-center"
              style={{ fontSize: "20px", fontStyle: "italic" }}
            >
              Aspiring to go beyond limits & limitations!
            </div>
          </div>
        </div>
        <div className="row pt-4">
          <div className="col-md-4">
            <h6>
              Department: <b>{department}</b>{" "}
            </h6>
            <h6>መደቡ የሚገኝበት መምሪያ</h6>
          </div>
          <div className="col-md-4">
            <h6>
              Position: <b>{applicantRegistry?.vacancy_item?.position}</b>
            </h6>
            <h6>የሥራ መደብ መጠሪያ</h6>
          </div>
          <div className="col-md-4">
            <h6>
              Date: <b>{date}</b>
            </h6>{" "}
            <h6>
              ቀን፡ <b>{date}</b>
            </h6>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 pt-2">
            <Table
              className="assessment-table"
              size="small"
              columns={[
                {
                  title: (
                    <>
                      <span>የተወዳዳሪዎች ሥም ዝርዝር</span> <br />
                      <span>Name of Candidates</span>
                    </>
                  ),
                  dataIndex: "name",
                  width: "15%",
                },
                {
                  title: (
                    <>
                      <span>የትምህርት ዝግጅትና የዕውቀት ደረጃ</span> <br />
                      <span>Educational Level (30%)</span>
                    </>
                  ),
                  dataIndex: "educational_level",
                  width: "8%",
                  render: (record) => formatNumber(record),
                },
                {
                  title: (
                    <>
                      <span>የሥራ ልምድ</span> <br />
                      <span>Work Experience (35%)</span>
                    </>
                  ),
                  dataIndex: "work_experience",
                  width: "8%",
                  render: (record) => formatNumber(record),
                },
                {
                  title: (
                    <>
                      <span>የመረዳት የመገንዘብና ሃሳብ የመግለጽ ችሎታ</span> <br />
                      <span>Understand & Respond Ability (15%)</span>
                    </>
                  ),
                  dataIndex: "understand_responsibility",
                  width: "8%",
                  render: (record) => formatNumber(record),
                },
                {
                  title: (
                    <>
                      <span>ለችግሮች መፍትሔ የመስጠት ችሎታ</span> <br />
                      <span>Problem Solving Ability (10%)</span>
                    </>
                  ),
                  dataIndex: "problem_solving_ability",
                  width: "8%",
                  render: (record) => formatNumber(record),
                },
                {
                  title: (
                    <>
                      <span>የስነልቦና ብስለትና ዝግጁነት</span> <br />
                      <span>Personality (10%)</span>
                    </>
                  ),
                  dataIndex: "personality",
                  width: "8%",
                  render: (record) => formatNumber(record),
                },
                {
                  title: (
                    <>
                      <span>ልዪ ክህሎትና ችሎታ</span> <br />
                      <span>Special Skill (20%)</span>
                    </>
                  ),
                  dataIndex: "special_skill",
                  width: "8%",
                  render: (record) => formatNumber(record),
                },
                {
                  title: (
                    <>
                      <span>የተግባር ችሎታ</span> <br />
                      <span>Technical Skill (80%)</span>
                    </>
                  ),
                  dataIndex: "technical_skill",
                  width: "8%",
                  render: (record) => formatNumber(record),
                },
                {
                  title: (
                    <>
                      <span>ድምር</span> <br />
                      <span>Total result (100-200)</span>
                    </>
                  ),
                  dataIndex: "total",
                  width: "8%",
                  render: (record) => formatNumber(record),
                },
                {
                  title: (
                    <>
                      <span>አማካኝ ውጤት</span> <br />
                      <span>Average Result (100%)</span>
                    </>
                  ),
                  dataIndex: "average_result",
                  width: "8%",
                  render: (record) => formatNumber(record),
                },
                {
                  title: (
                    <>
                      <span>ደረጃ</span> <br />
                      <span>Rank</span>
                    </>
                  ),
                  dataIndex: "rank",
                  width: "5%",
                  render: (record) => (
                    <div className="font-weight-bold">{record}</div>
                  ),
                },
                {
                  title: "Status",
                  dataIndex: "status",
                  width: "8%",
                  render: (record) => (
                    <div style={{ color: "green" }}>{record}</div>
                  ),
                },
                {
                  title: (
                    <>
                      <span>ምርመራ</span> <br />
                      <span>Remark</span>
                    </>
                  ),
                  width: "8%",
                },
              ]}
              pagination={false}
              bordered
              dataSource={formatAssessmentSummary(applicantRegistry ?? {})}
            />
          </div>
          <div className="col-md-12 pt-2">
            <div>
              Comment:
              _____________________________________________________________________________
            </div>
          </div>
          <div className="col-md-12 pt-2">
            <div className="row">
              {formatEvaluator(applicantRegistry ?? {}).map((evaluator) => (
                <>
                  <div className="col-md-4">Name: {evaluator}</div>
                  <div className="col-md-4">
                    Signature: ____________________
                  </div>
                  <div className="col-md-4">
                    Date: _________________________
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className="col-md-12 pt-2">
            <div>
              Approval:
              _____________________________________________________________________________
            </div>
          </div>
          <div className="col-md-12 pt-2 d-flex justify-content-end align-items-center">
            <div>Signature: _________________</div>
            <div>Date: ______________________</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintAssessmentSummary;
