import { ApiCallState } from "../Utils";

export type WorkEvaluation= {
  id: number;
  remark: string,
  project_id: number,
  date: string
};

export type WorkEvaluationStateTypes = {
  fetchAll: ApiCallState<WorkEvaluation[]>;
  fetchOne: ApiCallState<WorkEvaluation | {}>;
};

export const WorkEvaluationActionTypes = {
  FETCH_ALL_WORK_EVALUATION: "FETCH_ALL_WORK_EVALUATION",
  FETCH_ALL_WORK_EVALUATION_RESET: "FETCH_ALL_WORK_EVALUATION_RESET",
  FETCH_ALL_WORK_EVALUATION_FAILURE: "FETCH_ALL_WORK_EVALUATION_FAILURE",
  FETCH_ALL_WORK_EVALUATION_SUCCESS: "FETCH_ALL_WORK_EVALUATION_SUCCESS",

  FETCH_ONE_WORK_EVALUATION: "FETCH_ONE_WORK_EVALUATION",
  FETCH_ONE_WORK_EVALUATION_RESET: "FETCH_ONE_WORK_EVALUATION_RESET",
  FETCH_ONE_WORK_EVALUATION_FAILURE: "FETCH_ONE_WORK_EVALUATION_FAILURE",
  FETCH_ONE_WORK_EVALUATION_SUCCESS: "FETCH_ONE_WORK_EVALUATION_SUCCESS",
};
