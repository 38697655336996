import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ProjectTypeActionTypes } from "./ProjectType.type";

export function* fetchAllProjectTypes(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/project-type?${query}`);
    yield put({
      type: ProjectTypeActionTypes.FETCH_ALL_PROJECT_TYPE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectTypeActionTypes.FETCH_ALL_PROJECT_TYPE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneProjectTypes(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/project-type/${action.payload}`
    );
    yield put({
      type: ProjectTypeActionTypes.FETCH_ONE_PROJECT_TYPE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectTypeActionTypes.FETCH_ONE_PROJECT_TYPE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllProjectTypes() {
  yield takeLatest(
    ProjectTypeActionTypes.FETCH_ALL_PROJECT_TYPE,
    fetchAllProjectTypes
  );
}

export function* watcherFetchOneProjectTypes() {
  yield takeLatest(
    ProjectTypeActionTypes.FETCH_ONE_PROJECT_TYPE,
    fetchOneProjectTypes
  );
}
