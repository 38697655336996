import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ContractSalesActionTypes } from "./ContractSales.type";

export function* fetchAllContractSaless(action: any): any {
  try {
  const response = yield axios.get(`${API_BASE_URI}/contract-sales`);
    yield put({
      type: ContractSalesActionTypes.FETCH_ALL_CONTRACT_SALES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ContractSalesActionTypes.FETCH_ALL_CONTRACT_SALES_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneContractSaless(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/contract-sales/${action.payload}`
    );
    yield put({
      type: ContractSalesActionTypes.FETCH_ONE_CONTRACT_SALES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ContractSalesActionTypes.FETCH_ONE_CONTRACT_SALES_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllContractSaless() {
  yield takeLatest(ContractSalesActionTypes.FETCH_ALL_CONTRACT_SALES, fetchAllContractSaless);
}

export function* watcherFetchOneContractSaless() {
  yield takeLatest(ContractSalesActionTypes.FETCH_ONE_CONTRACT_SALES, fetchOneContractSaless);
}
