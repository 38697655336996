import { Material } from "../Material/Material.type";
import { Supplier } from "../Supplier/Supplier.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type PurchaseOrder = {
  id: number;
  user_id: number;
  purchase_requisition_id: number;
  requested_to: string;
  department_id?: number;
  project_id?: number;
  supplier: Supplier;
  proforma_id: number;
  type: string;
  currency: string;
  date: string;
  remark: string;
  purchaser_id: number;
  shipment: string;
  payment_method: string;
  partial_shipment: string;
  trans_shipment: string;
  delivery_place: number;
  port_of_loading: string;
  port_of_destination: string;
  purchase_status: string;
  freight_charge: number;
  document_receiving_bank: string;
  purchase_order_items: PurchaseOrderItem[];
  po_prepared_by?: User;
  po_checked_by?: User;
  po_approved_by?: User;
  purchase_order_statuses: PurchaseOrderStatus[];
  status: PurchaseOrderStatus[];
  supplier_id: number;
  is_done: boolean;
  with_hold: boolean;
  tax: number;
  order_and_condition: string;
  on_before: string;
  additional_comments: string;
  updatedAt: string;
  is_void: boolean;

  purchaser?: User;

  received_date?: Date;
};

export type PurchaseOrderStatus = {
  purchase_requisition_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type PurchaseOrderItem = {
  material_id: number;
  material: Material;
  quantity: number;
  received: number;
  unit_price: number;
  unit: string;
  total: number;
  key: number;
  purchase_item_id: number;
  purchase_requisition_id: number;
  id: number;
  part_no: string;
  equipment_code: string;
  model_no: string;
  engine_no: string;
  chassis_no: string;
};

export type PurchaseOrderStateTypes = {
  fetchAll: ApiCallState<PurchaseOrder[]>;
  fetchOne: ApiCallState<PurchaseOrder | {}>;
};

export const PurchaseOrderActionTypes = {
  FETCH_ALL_PURCHASE_ORDER: "FETCH_ALL_PURCHASE_ORDER",
  FETCH_ALL_PURCHASE_ORDER_RESET: "FETCH_ALL_PURCHASE_ORDER_RESET",
  FETCH_ALL_PURCHASE_ORDER_FAILURE: "FETCH_ALL_PURCHASE_ORDER_FAILURE",
  FETCH_ALL_PURCHASE_ORDER_SUCCESS: "FETCH_ALL_PURCHASE_ORDER_SUCCESS",

  FETCH_ONE_PURCHASE_ORDER: "FETCH_ONE_PURCHASE_ORDER",
  FETCH_ONE_PURCHASE_ORDER_RESET: "FETCH_ONE_PURCHASE_ORDER_RESET",
  FETCH_ONE_PURCHASE_ORDER_FAILURE: "FETCH_ONE_PURCHASE_ORDER_FAILURE",
  FETCH_ONE_PURCHASE_ORDER_SUCCESS: "FETCH_ONE_PURCHASE_ORDER_SUCCESS",
};
