import {
  SubContractorPerformanceStateTypes,
  SubContractorPerformanceActionTypes,
} from "./SubContractorPerformance.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: SubContractorPerformanceStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const SubContractorPerformanceReducer = (
  state: SubContractorPerformanceStateTypes = INITIAL_STATE,
  action: any
): SubContractorPerformanceStateTypes => {
  switch (action.type) {
    case SubContractorPerformanceActionTypes.FETCH_ALL_SUB_CONTRACTOR_PERFORMANCE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case SubContractorPerformanceActionTypes.FETCH_ALL_SUB_CONTRACTOR_PERFORMANCE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SubContractorPerformanceActionTypes.FETCH_ALL_SUB_CONTRACTOR_PERFORMANCE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SubContractorPerformanceActionTypes.FETCH_ALL_SUB_CONTRACTOR_PERFORMANCE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case SubContractorPerformanceActionTypes.FETCH_ONE_SUB_CONTRACTOR_PERFORMANCE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case SubContractorPerformanceActionTypes.FETCH_ONE_SUB_CONTRACTOR_PERFORMANCE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SubContractorPerformanceActionTypes.FETCH_ONE_SUB_CONTRACTOR_PERFORMANCE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SubContractorPerformanceActionTypes.FETCH_ONE_SUB_CONTRACTOR_PERFORMANCE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default SubContractorPerformanceReducer;
