import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { FuelCostActionTypes } from "./FuelCost.type";

export function* fetchAllFuelCosts(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/fuel-cost`);
    yield put({
      type: FuelCostActionTypes.FETCH_ALL_FUEL_COST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FuelCostActionTypes.FETCH_ALL_FUEL_COST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneFuelCosts(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/fuel-cost/date?project_id=${action.payload?.project_id}&date=${action.payload?.date}`
    );
    yield put({
      type: FuelCostActionTypes.FETCH_ONE_FUEL_COST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FuelCostActionTypes.FETCH_ONE_FUEL_COST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllFuelCosts() {
  yield takeLatest(FuelCostActionTypes.FETCH_ALL_FUEL_COST, fetchAllFuelCosts);
}

export function* watcherFetchOneFuelCosts() {
  yield takeLatest(FuelCostActionTypes.FETCH_ONE_FUEL_COST, fetchOneFuelCosts);
}
