import { ApiCallState, PagedData } from "../Utils";

export type RGTS = {
  id?: number;
  date: string;
  sender_bank: string;
  sender_account: string;
  account_holder: string;
  receiving_bank: string;
  receiving_account: string;
  receiver_name: string;
  receiver_bank_branch:string;
  amount: number;
  reason?: string;
  rgts_statuses: RGTSStatus[];
};

export type RGTSStatus = {
  rgts_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type RGTSStateTypes = {
  fetchAll: ApiCallState<RGTS[]>;
  fetchOne: ApiCallState<RGTS | {}>;
  fetchPaged: ApiCallState<PagedData<RGTS[]>>;
};

export const RGTSActionTypes = {
  FETCH_ALL_RGTS: "FETCH_ALL_RGTS",
  FETCH_ALL_RGTS_RESET: "FETCH_ALL_RGTS_RESET",
  FETCH_ALL_RGTS_FAILURE: "FETCH_ALL_RGTS_FAILURE",
  FETCH_ALL_RGTS_SUCCESS: "FETCH_ALL_RGTS_SUCCESS",

  FETCH_PAGED_RGTS: "FETCH_PAGED_RGTS",
  FETCH_PAGED_RGTS_RESET: "FETCH_PAGED_RGTS_RESET",
  FETCH_PAGED_RGTS_FAILURE: "FETCH_PAGED_RGTS_FAILURE",
  FETCH_PAGED_RGTS_SUCCESS: "FETCH_PAGED_RGTS_SUCCESS",

  FETCH_ONE_RGTS: "FETCH_ONE_RGTS",
  FETCH_ONE_RGTS_RESET: "FETCH_ONE_RGTS_RESET",
  FETCH_ONE_RGTS_FAILURE: "FETCH_ONE_RGTS_FAILURE",
  FETCH_ONE_RGTS_SUCCESS: "FETCH_ONE_RGTS_SUCCESS",
};
