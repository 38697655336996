import { ApiCallState } from "../Utils";

export type StaffFamily= {
  id: number;
  staff_id:number;
  full_name:string;
  full_name_am:string;
  relation:string;
  sex:string;
  remark:string;
  phone_no:string;
  user_id:number;
};

export type StaffFamilyStateTypes = {
  fetchAll: ApiCallState<StaffFamily[]>;
  fetchOne: ApiCallState<StaffFamily | {}>;
};

export const StaffFamilyActionTypes = {
  FETCH_ALL_STAFF_FAMILY: "FETCH_ALL_STAFF_FAMILY",
  FETCH_ALL_STAFF_FAMILY_RESET: "FETCH_ALL_STAFF_FAMILY_RESET",
  FETCH_ALL_STAFF_FAMILY_FAILURE: "FETCH_ALL_STAFF_FAMILY_FAILURE",
  FETCH_ALL_STAFF_FAMILY_SUCCESS: "FETCH_ALL_STAFF_FAMILY_SUCCESS",

  FETCH_ONE_STAFF_FAMILY: "FETCH_ONE_STAFF_FAMILY",
  FETCH_ONE_STAFF_FAMILY_RESET: "FETCH_ONE_STAFF_FAMILY_RESET",
  FETCH_ONE_STAFF_FAMILY_FAILURE: "FETCH_ONE_STAFF_FAMILY_FAILURE",
  FETCH_ONE_STAFF_FAMILY_SUCCESS: "FETCH_ONE_STAFF_FAMILY_SUCCESS",
};
