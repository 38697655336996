import { FuelUtilizationStateTypes, FuelUtilizationActionTypes } from "./FuelUtilization.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: FuelUtilizationStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const FuelUtilizationReducer = (
  state: FuelUtilizationStateTypes = INITIAL_STATE,
  action: any
): FuelUtilizationStateTypes => {
  switch (action.type) {
    case FuelUtilizationActionTypes.FETCH_ALL_FUEL_UTILIZATION:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case FuelUtilizationActionTypes.FETCH_ALL_FUEL_UTILIZATION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case FuelUtilizationActionTypes.FETCH_ALL_FUEL_UTILIZATION_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FuelUtilizationActionTypes.FETCH_ALL_FUEL_UTILIZATION_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case FuelUtilizationActionTypes.FETCH_ONE_FUEL_UTILIZATION:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case FuelUtilizationActionTypes.FETCH_ONE_FUEL_UTILIZATION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case FuelUtilizationActionTypes.FETCH_ONE_FUEL_UTILIZATION_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FuelUtilizationActionTypes.FETCH_ONE_FUEL_UTILIZATION_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default FuelUtilizationReducer;
