import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SubContractSecurityActionTypes } from "./SubContractSecurity.type";

export function* fetchAllSubContractSecuritys(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/sub-contract?project_id=${action.payload.project_id}`);
    yield put({
      type: SubContractSecurityActionTypes.FETCH_ALL_SUB_CONTRACT_SECURITY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubContractSecurityActionTypes.FETCH_ALL_SUB_CONTRACT_SECURITY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSubContractSecuritys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/sub-contract/${action.payload}`
    );
    yield put({
      type: SubContractSecurityActionTypes.FETCH_ONE_SUB_CONTRACT_SECURITY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubContractSecurityActionTypes.FETCH_ONE_SUB_CONTRACT_SECURITY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSubContractSecuritys() {
  yield takeLatest(SubContractSecurityActionTypes.FETCH_ALL_SUB_CONTRACT_SECURITY, fetchAllSubContractSecuritys);
}

export function* watcherFetchOneSubContractSecuritys() {
  yield takeLatest(SubContractSecurityActionTypes.FETCH_ONE_SUB_CONTRACT_SECURITY, fetchOneSubContractSecuritys);
}
