import { ApiCallState } from "../Utils";

export type ConsultantFacilityCost= {
  id: number;
  start_date: string;
  end_date: string;
  project_id: number;
  user_id: number;

  consultant_accomodations: ConsultantAccomodation[],
  consultant_camp_facilities: ConsultantCampFacility[],
  consultant_kitchen_tool_equipments: ConsultantKicthenToolEquipment[],
  consultant_office_furnitures: ConsultantOfficeFurniture[],
  consultant_office_supplies_facilitys: ConsultantOfficeSuppliesFacility[],
  consultant_other_facility_for_engineers: ConsultantOtherFacilityForEngineer[],
  consultant_temporary_office_rents: ConsultantTemporaryOfficeRent[]
};

export type ConsultantAccomodation = {
  id: number;
  description: string;
  unit: string;
  quantity: number;
  delivery_date: string;
  purchasing_price_per_unit: number;
  previous_cost: number;
  this_period_cost: number;
  to_date_cost: number;
  consultant_facility_cost_id: number;
}

export type ConsultantCampFacility = {
  id: number;
  description: string;
  unit: string;
  quantity: number;
  delivery_date: string;
  purchasing_price_per_unit: number;
  previous_cost: number;
  this_period_cost: number;
  to_date_cost: number;
  consultant_facility_cost_id: number;
}

export type ConsultantKicthenToolEquipment = {
  id: number;
  description: string;
  unit: string;
  quantity: number;
  delivery_date: string;
  purchasing_price_per_unit: number;
  previous_cost: number;
  this_period_cost: number;
  to_date_cost: number;
  consultant_facility_cost_id: number;
}

export type ConsultantOfficeFurniture = {
  id: number;
  description: string;
  unit: string;
  quantity: number;
  delivery_date: string;
  purchasing_price_per_unit: number;
  previous_cost: number;
  this_period_cost: number;
  to_date_cost: number;
  consultant_facility_cost_id: number;
}

export type ConsultantOfficeSuppliesFacility = {
  id: number;
  description: string;
  unit: string;
  quantity: number;
  delivery_date: string;
  purchasing_price_per_unit: number;
  previous_cost: number;
  this_period_cost: number;
  to_date_cost: number;
  consultant_facility_cost_id: number;
}

export type ConsultantOtherFacilityForEngineer = {
  id: number;
  description: string;
  previous_amount: number;
  this_period_amount: number;
  to_date_amount: number;
  consultant_facility_cost_id: number;
}

export type ConsultantTemporaryOfficeRent = {
  id: number;
  description: string;
  previous_amount: number;
  this_period_amount: number;
  to_date_amount: number;
  consultant_facility_cost_id: number;
}

export type ConsultantFacilityCostStateTypes = {
  fetchAll: ApiCallState<ConsultantFacilityCost[]>;
  fetchOne: ApiCallState<ConsultantFacilityCost | {}>;
};

export const ConsultantFacilityCostActionTypes = {
  FETCH_ALL_CONSULTANT_FACILITY_COST: "FETCH_ALL_CONSULTANT_FACILITY_COST",
  FETCH_ALL_CONSULTANT_FACILITY_COST_RESET: "FETCH_ALL_CONSULTANT_FACILITY_COST_RESET",
  FETCH_ALL_CONSULTANT_FACILITY_COST_FAILURE: "FETCH_ALL_CONSULTANT_FACILITY_COST_FAILURE",
  FETCH_ALL_CONSULTANT_FACILITY_COST_SUCCESS: "FETCH_ALL_CONSULTANT_FACILITY_COST_SUCCESS",

  FETCH_ONE_CONSULTANT_FACILITY_COST: "FETCH_ONE_CONSULTANT_FACILITY_COST",
  FETCH_ONE_CONSULTANT_FACILITY_COST_RESET: "FETCH_ONE_CONSULTANT_FACILITY_COST_RESET",
  FETCH_ONE_CONSULTANT_FACILITY_COST_FAILURE: "FETCH_ONE_CONSULTANT_FACILITY_COST_FAILURE",
  FETCH_ONE_CONSULTANT_FACILITY_COST_SUCCESS: "FETCH_ONE_CONSULTANT_FACILITY_COST_SUCCESS",
};
