import { TimeUtilizationReportStateTypes, TimeUtilizationReportActionTypes } from "./TimeUtilizationReport.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: TimeUtilizationReportStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchReport: resetApiCallState([]),
};

const TimeUtilizationReportReducer = (
  state: TimeUtilizationReportStateTypes = INITIAL_STATE,
  action: any
): TimeUtilizationReportStateTypes => {
  switch (action.type) {
    case TimeUtilizationReportActionTypes.FETCH_ALL_TIME_UTILIZATION_REPORT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case TimeUtilizationReportActionTypes.FETCH_ALL_TIME_UTILIZATION_REPORT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case TimeUtilizationReportActionTypes.FETCH_ALL_TIME_UTILIZATION_REPORT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case TimeUtilizationReportActionTypes.FETCH_ALL_TIME_UTILIZATION_REPORT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case TimeUtilizationReportActionTypes.FETCH_TIME_UTILIZATION_REPORT:
      return {
        ...state,
        fetchReport: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case TimeUtilizationReportActionTypes.FETCH_TIME_UTILIZATION_REPORT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case TimeUtilizationReportActionTypes.FETCH_TIME_UTILIZATION_REPORT_FAILURE:
      return {
        ...state,
        fetchReport: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case TimeUtilizationReportActionTypes.FETCH_TIME_UTILIZATION_REPORT_SUCCESS:
      return {
        ...state,
        fetchReport: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default TimeUtilizationReportReducer;
