import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { TaskActionTypes } from "./Task.type";

export function* fetchAllTasks(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/task`);
    yield put({
      type: TaskActionTypes.FETCH_ALL_TASK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TaskActionTypes.FETCH_ALL_TASK_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneTasks(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/task/${action.payload}`
    );
    yield put({
      type: TaskActionTypes.FETCH_ONE_TASK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TaskActionTypes.FETCH_ONE_TASK_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllTasks() {
  yield takeLatest(TaskActionTypes.FETCH_ALL_TASK, fetchAllTasks);
}

export function* watcherFetchOneTasks() {
  yield takeLatest(TaskActionTypes.FETCH_ONE_TASK, fetchOneTasks);
}
