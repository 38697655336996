import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { JustificationActionTypes } from "./Justification.type";

export function* fetchAllJustifications(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/justification?project_id=${action.payload.project_id}`);
    yield put({
      type: JustificationActionTypes.FETCH_ALL_JUSTIFICATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: JustificationActionTypes.FETCH_ALL_JUSTIFICATION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneJustifications(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/justification/${action.payload}`
    );
    yield put({
      type: JustificationActionTypes.FETCH_ONE_JUSTIFICATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: JustificationActionTypes.FETCH_ONE_JUSTIFICATION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllJustifications() {
  yield takeLatest(JustificationActionTypes.FETCH_ALL_JUSTIFICATION, fetchAllJustifications);
}

export function* watcherFetchOneJustifications() {
  yield takeLatest(JustificationActionTypes.FETCH_ONE_JUSTIFICATION, fetchOneJustifications);
}
