import { ApiCallState } from "../Utils";
import { User } from "../User/User.type";
import { StoreRequisition } from "../StoreRequisition/StoreRequisition.type";
import { MasterList } from "../MasterList/MasterList.type";

export type StoreRequisitionFollowUp = {
  id: number;

  user_id: number;
  store_requisition_id: number;

  description: string;
  brand: string;
  unit: string;
  quantity: number;
  unit_price: number;
  vat: number;
  total: number;
  remark: string;

  user: User;
  store_requisition: StoreRequisition;
};

export type StoreRequisitionFollowUpStateTypes = {
  fetchAll: ApiCallState<StoreRequisitionFollowUp[]>;
  fetchOne: ApiCallState<StoreRequisitionFollowUp | {}>;
};

export const StoreRequisitionFollowUpActionTypes = {
  FETCH_ALL_STORE_REQUISITION_FOLLOW_UP:
    "FETCH_ALL_STORE_REQUISITION_FOLLOW_UP",
  FETCH_ALL_STORE_REQUISITION_FOLLOW_UP_RESET:
    "FETCH_ALL_STORE_REQUISITION_FOLLOW_UP_RESET",
  FETCH_ALL_STORE_REQUISITION_FOLLOW_UP_FAILURE:
    "FETCH_ALL_STORE_REQUISITION_FOLLOW_UP_FAILURE",
  FETCH_ALL_STORE_REQUISITION_FOLLOW_UP_SUCCESS:
    "FETCH_ALL_STORE_REQUISITION_FOLLOW_UP_SUCCESS",

  FETCH_ONE_STORE_REQUISITION_FOLLOW_UP:
    "FETCH_ONE_STORE_REQUISITION_FOLLOW_UP",
  FETCH_ONE_STORE_REQUISITION_FOLLOW_UP_RESET:
    "FETCH_ONE_STORE_REQUISITION_FOLLOW_UP_RESET",
  FETCH_ONE_STORE_REQUISITION_FOLLOW_UP_FAILURE:
    "FETCH_ONE_STORE_REQUISITION_FOLLOW_UP_FAILURE",
  FETCH_ONE_STORE_REQUISITION_FOLLOW_UP_SUCCESS:
    "FETCH_ONE_STORE_REQUISITION_FOLLOW_UP_SUCCESS",
};
