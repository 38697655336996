import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type UploadDocument = {
  id: number;
  user_id: number;

  reference_number: string;
  is_public: boolean;
  type: string;
  remark: string;

  name: string;
  url: string;
  size: number;

  date: Date;

  user: User;
};

export type UploadDocumentStateTypes = {
  fetchAll: ApiCallState<UploadDocument[]>;
  fetchOne: ApiCallState<UploadDocument | {}>;
  fetchReference: ApiCallState<string | null>;
};

export const UploadDocumentActionTypes = {
  FETCH_ALL_UPLOAD_DOCUMENT: "FETCH_ALL_UPLOAD_DOCUMENT",
  FETCH_ALL_UPLOAD_DOCUMENT_RESET: "FETCH_ALL_UPLOAD_DOCUMENT_RESET",
  FETCH_ALL_UPLOAD_DOCUMENT_FAILURE: "FETCH_ALL_UPLOAD_DOCUMENT_FAILURE",
  FETCH_ALL_UPLOAD_DOCUMENT_SUCCESS: "FETCH_ALL_UPLOAD_DOCUMENT_SUCCESS",

  FETCH_ONE_UPLOAD_DOCUMENT: "FETCH_ONE_UPLOAD_DOCUMENT",
  FETCH_ONE_UPLOAD_DOCUMENT_RESET: "FETCH_ONE_UPLOAD_DOCUMENT_RESET",
  FETCH_ONE_UPLOAD_DOCUMENT_FAILURE: "FETCH_ONE_UPLOAD_DOCUMENT_FAILURE",
  FETCH_ONE_UPLOAD_DOCUMENT_SUCCESS: "FETCH_ONE_UPLOAD_DOCUMENT_SUCCESS",

  FETCH_UPLOAD_DOCUMENT_REFERENCE_NUMBER:
    "FETCH_UPLOAD_DOCUMENT_REFERENCE_NUMBER",
  FETCH_UPLOAD_DOCUMENT_REFERENCE_NUMBER_RESET:
    "FETCH_UPLOAD_DOCUMENT_REFERENCE_NUMBER_RESET",
  FETCH_UPLOAD_DOCUMENT_REFERENCE_NUMBER_FAILURE:
    "FETCH_UPLOAD_DOCUMENT_REFERENCE_NUMBER_FAILURE",
  FETCH_UPLOAD_DOCUMENT_REFERENCE_NUMBER_SUCCESS:
    "FETCH_UPLOAD_DOCUMENT_REFERENCE_NUMBER_SUCCESS",
};
