import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { BudgetRequestActionTypes } from "./BudgetRequest.type";

export function* fetchAllBudgetRequests(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/budget-request?project_id=${action.payload.project_id}`);
    yield put({
      type: BudgetRequestActionTypes.FETCH_ALL_BUDGET_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BudgetRequestActionTypes.FETCH_ALL_BUDGET_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneBudgetRequests(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/budget-request/${action.payload}`
    );
    yield put({
      type: BudgetRequestActionTypes.FETCH_ONE_BUDGET_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BudgetRequestActionTypes.FETCH_ONE_BUDGET_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllBudgetRequests() {
  yield takeLatest(BudgetRequestActionTypes.FETCH_ALL_BUDGET_REQUEST, fetchAllBudgetRequests);
}

export function* watcherFetchOneBudgetRequests() {
  yield takeLatest(BudgetRequestActionTypes.FETCH_ONE_BUDGET_REQUEST, fetchOneBudgetRequests);
}
