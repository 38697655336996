import { ApiCallState } from "../Utils";

export type StaffEmergencyContact= {
  id: number;
  staff_id:number;
  name:string;
  name_am:string;
  relation:string;
  sex:string;
  phone_number:string;
  email:string;
  user_id:number;
};

export type StaffEmergencyContactStateTypes = {
  fetchAll: ApiCallState<StaffEmergencyContact[]>;
  fetchOne: ApiCallState<StaffEmergencyContact | {}>;
};

export const StaffEmergencyContactActionTypes = {
  FETCH_ALL_STAFF_EMERGENCY_CONTACT: "FETCH_ALL_STAFF_EMERGENCY_CONTACT",
  FETCH_ALL_STAFF_EMERGENCY_CONTACT_RESET: "FETCH_ALL_STAFF_EMERGENCY_CONTACT_RESET",
  FETCH_ALL_STAFF_EMERGENCY_CONTACT_FAILURE: "FETCH_ALL_STAFF_EMERGENCY_CONTACT_FAILURE",
  FETCH_ALL_STAFF_EMERGENCY_CONTACT_SUCCESS: "FETCH_ALL_STAFF_EMERGENCY_CONTACT_SUCCESS",

  FETCH_ONE_STAFF_EMERGENCY_CONTACT: "FETCH_ONE_STAFF_EMERGENCY_CONTACT",
  FETCH_ONE_STAFF_EMERGENCY_CONTACT_RESET: "FETCH_ONE_STAFF_EMERGENCY_CONTACT_RESET",
  FETCH_ONE_STAFF_EMERGENCY_CONTACT_FAILURE: "FETCH_ONE_STAFF_EMERGENCY_CONTACT_FAILURE",
  FETCH_ONE_STAFF_EMERGENCY_CONTACT_SUCCESS: "FETCH_ONE_STAFF_EMERGENCY_CONTACT_SUCCESS",
};
