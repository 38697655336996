import { ApiCallState } from "../Utils";

export type BiddingSchedule= {
   id?: number;
   project_type: string;
   location: string;
   amount_of_bid_bond: number;
   client: string;
   owner: string;
   deadline: string;
   status: string;
   remark: string;
};

export type BiddingScheduleStateTypes = {
  fetchAll: ApiCallState<BiddingSchedule[]>;
  fetchOne: ApiCallState<BiddingSchedule | {}>;
};

export const BiddingScheduleActionTypes = {
  FETCH_ALL_BIDDING_SCHEDULE: "FETCH_ALL_BIDDING_SCHEDULE",
  FETCH_ALL_BIDDING_SCHEDULE_RESET: "FETCH_ALL_BIDDING_SCHEDULE_RESET",
  FETCH_ALL_BIDDING_SCHEDULE_FAILURE: "FETCH_ALL_BIDDING_SCHEDULE_FAILURE",
  FETCH_ALL_BIDDING_SCHEDULE_SUCCESS: "FETCH_ALL_BIDDING_SCHEDULE_SUCCESS",

  FETCH_ONE_BIDDING_SCHEDULE: "FETCH_ONE_BIDDING_SCHEDULE",
  FETCH_ONE_BIDDING_SCHEDULE_RESET: "FETCH_ONE_BIDDING_SCHEDULE_RESET",
  FETCH_ONE_BIDDING_SCHEDULE_FAILURE: "FETCH_ONE_BIDDING_SCHEDULE_FAILURE",
  FETCH_ONE_BIDDING_SCHEDULE_SUCCESS: "FETCH_ONE_BIDDING_SCHEDULE_SUCCESS",
};
