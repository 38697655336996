import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ApplicantRegistryItemActionTypes } from "./ApplicantRegistryItem.type";

export function* fetchAllApplicantRegistryItems(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/applicant-registry-item`);
    yield put({
      type: ApplicantRegistryItemActionTypes.FETCH_ALL_APPLICANT_REGISTRY_ITEM_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ApplicantRegistryItemActionTypes.FETCH_ALL_APPLICANT_REGISTRY_ITEM_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneApplicantRegistryItems(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/applicant-registry-item/${action.payload}`
    );
    yield put({
      type: ApplicantRegistryItemActionTypes.FETCH_ONE_APPLICANT_REGISTRY_ITEM_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ApplicantRegistryItemActionTypes.FETCH_ONE_APPLICANT_REGISTRY_ITEM_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllApplicantRegistryItems() {
  yield takeLatest(ApplicantRegistryItemActionTypes.FETCH_ALL_APPLICANT_REGISTRY_ITEM, fetchAllApplicantRegistryItems);
}

export function* watcherFetchOneApplicantRegistryItems() {
  yield takeLatest(ApplicantRegistryItemActionTypes.FETCH_ONE_APPLICANT_REGISTRY_ITEM, fetchOneApplicantRegistryItems);
}
