import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { AttendanceLocationActionTypes } from "./AttendanceLocation.type";

export function* fetchAllAttendanceLocations(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query =
        "?" + keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/attendance-location${query}`
    );
    yield put({
      type: AttendanceLocationActionTypes.FETCH_ALL_ATTENDANCE_LOCATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AttendanceLocationActionTypes.FETCH_ALL_ATTENDANCE_LOCATION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneAttendanceLocations(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/attendance-location/${action.payload}`
    );
    yield put({
      type: AttendanceLocationActionTypes.FETCH_ONE_ATTENDANCE_LOCATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AttendanceLocationActionTypes.FETCH_ONE_ATTENDANCE_LOCATION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllAttendanceLocations() {
  yield takeLatest(
    AttendanceLocationActionTypes.FETCH_ALL_ATTENDANCE_LOCATION,
    fetchAllAttendanceLocations
  );
}

export function* watcherFetchOneAttendanceLocations() {
  yield takeLatest(
    AttendanceLocationActionTypes.FETCH_ONE_ATTENDANCE_LOCATION,
    fetchOneAttendanceLocations
  );
}
