import { Material } from "../Material/Material.type";
import { ApiCallState } from "../Utils";

export type TemporaryDeliveryNote = {
  id: number;
  user_id: number;
  prepared_by_id: number;
  approved_by_id: number;
  delivered_by_id: number;
  received_by_id: number;
  delivery_number: number;
  date: Date;
  to: number;
  from: number;
  temporary_delivery_note_items: TemporaryDeliveryNoteItem[];
  createdAt: Date;
  updatedAt: Date;
};

export type TemporaryDeliveryNoteItem = {
  id?: number;
  key?: string;
  temporary_delivery_note_id: number;
  material_id: string;
  quantity: number;
  unit: string;
  unit_price: number;
  remark: string;
  material: Material;
};

export type TemporarayDeliveryNoteReport = {
  approved_by?: any;
  code: string;
  date: string;
  description: string;
  from: string;
  to: string;
  delivery_number: string;
  id: number;
  prepared_by: string;
  quantity: number;
  received_by?: any;
  remark: string;
  total: number;
  unit: string;
};

export type TemporaryDeliveryNoteStateTypes = {
  fetchOne: ApiCallState<TemporaryDeliveryNote | {}>;
  fetchAll: ApiCallState<TemporaryDeliveryNote[]>;
  fetchReport: ApiCallState<TemporarayDeliveryNoteReport[]>;
};

export const TemporaryDeliveryNoteActionTypes = {
  FETCH_ALL_TEMPORARY_DELIVERY_NOTE: "FETCH_ALL_TEMPORARY_DELIVERY_NOTE",
  FETCH_ALL_TEMPORARY_DELIVERY_NOTE_RESET:
    "FETCH_ALL_TEMPORARY_DELIVERY_NOTE_RESET",
  FETCH_ALL_TEMPORARY_DELIVERY_NOTE_FAILURE:
    "FETCH_ALL_TEMPORARY_DELIVERY_NOTE_FAILURE",
  FETCH_ALL_TEMPORARY_DELIVERY_NOTE_SUCCESS:
    "FETCH_ALL_TEMPORARY_DELIVERY_NOTE_SUCCESS",

  FETCH_ONE_TEMPORARY_DELIVERY_NOTE: "FETCH_ONE_TEMPORARY_DELIVERY_NOTE",
  FETCH_ONE_TEMPORARY_DELIVERY_NOTE_RESET:
    "FETCH_ONE_TEMPORARY_DELIVERY_NOTE_RESET",
  FETCH_ONE_TEMPORARY_DELIVERY_NOTE_FAILURE:
    "FETCH_ONE_TEMPORARY_DELIVERY_NOTE_FAILURE",
  FETCH_ONE_TEMPORARY_DELIVERY_NOTE_SUCCESS:
    "FETCH_ONE_TEMPORARY_DELIVERY_NOTE_SUCCESS",

  FETCH_REPORT_TEMPORARY_DELIVERY_NOTE: "FETCH_REPORT_TEMPORARY_DELIVERY_NOTE",
  FETCH_REPORT_TEMPORARY_DELIVERY_NOTE_RESET:
    "FETCH_REPORT_TEMPORARY_DELIVERY_NOTE_RESET",
  FETCH_REPORT_TEMPORARY_DELIVERY_NOTE_FAILURE:
    "FETCH_REPORT_TEMPORARY_DELIVERY_NOTE_FAILURE",
  FETCH_REPORT_TEMPORARY_DELIVERY_NOTE_SUCCESS:
    "FETCH_REPORT_TEMPORARY_DELIVERY_NOTE_SUCCESS",
};
