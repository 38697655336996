import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SurveyingEquipmentCostActionTypes } from "./SurveyingEquipmentCost.type";

export function* fetchAllSurveyingEquipmentCosts(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/surveying-equipment-cost?project_id=${action.payload?.project_id}&month=${action.payload?.month}`);
    yield put({
      type: SurveyingEquipmentCostActionTypes.FETCH_ALL_SURVEYING_EQUIPMENT_COST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SurveyingEquipmentCostActionTypes.FETCH_ALL_SURVEYING_EQUIPMENT_COST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSurveyingEquipmentCosts(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/surveying-equipment-cost/${action.payload}`
    );
    yield put({
      type: SurveyingEquipmentCostActionTypes.FETCH_ONE_SURVEYING_EQUIPMENT_COST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SurveyingEquipmentCostActionTypes.FETCH_ONE_SURVEYING_EQUIPMENT_COST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSurveyingEquipmentCosts() {
  yield takeLatest(SurveyingEquipmentCostActionTypes.FETCH_ALL_SURVEYING_EQUIPMENT_COST, fetchAllSurveyingEquipmentCosts);
}

export function* watcherFetchOneSurveyingEquipmentCosts() {
  yield takeLatest(SurveyingEquipmentCostActionTypes.FETCH_ONE_SURVEYING_EQUIPMENT_COST, fetchOneSurveyingEquipmentCosts);
}
