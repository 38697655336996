import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SummaryOfCostActionTypes } from "./SummaryOfCost.type";

export function* fetchAllSummaryOfCosts(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/summary-of-cost?project_id=${action.payload?.project_id}`);
    yield put({
      type: SummaryOfCostActionTypes.FETCH_ALL_SUMMARY_OF_COST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SummaryOfCostActionTypes.FETCH_ALL_SUMMARY_OF_COST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSummaryOfCosts(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/summary-of-cost/${action.payload}`
    );
    yield put({
      type: SummaryOfCostActionTypes.FETCH_ONE_SUMMARY_OF_COST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SummaryOfCostActionTypes.FETCH_ONE_SUMMARY_OF_COST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSummaryOfCosts() {
  yield takeLatest(SummaryOfCostActionTypes.FETCH_ALL_SUMMARY_OF_COST, fetchAllSummaryOfCosts);
}

export function* watcherFetchOneSummaryOfCosts() {
  yield takeLatest(SummaryOfCostActionTypes.FETCH_ONE_SUMMARY_OF_COST, fetchOneSummaryOfCosts);
}
