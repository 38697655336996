import { ApiCallState } from "../Utils";

export type Manning= {
  id: number;
  description: string;
  education: string;
  profession:string;
  experience: string;
  other_requirements: string;
  manning_category_id: number;
};

export type ManningStateTypes = {
  fetchAll: ApiCallState<Manning[]>;
  fetchOne: ApiCallState<Manning | {}>;
};

export const ManningActionTypes = {
  FETCH_ALL_MANNING: "FETCH_ALL_MANNING",
  FETCH_ALL_MANNING_RESET: "FETCH_ALL_MANNING_RESET",
  FETCH_ALL_MANNING_FAILURE: "FETCH_ALL_MANNING_FAILURE",
  FETCH_ALL_MANNING_SUCCESS: "FETCH_ALL_MANNING_SUCCESS",

  FETCH_ONE_MANNING: "FETCH_ONE_MANNING",
  FETCH_ONE_MANNING_RESET: "FETCH_ONE_MANNING_RESET",
  FETCH_ONE_MANNING_FAILURE: "FETCH_ONE_MANNING_FAILURE",
  FETCH_ONE_MANNING_SUCCESS: "FETCH_ONE_MANNING_SUCCESS",
};
