import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MikadaDailyReportActionTypes } from "./MikadaDailyReport.type";

export function* fetchAllMikadaDailyReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/mikada-daily-report?date=${action.payload?.date}`
    );
    yield put({
      type: MikadaDailyReportActionTypes.FETCH_ALL_MIKADA_DAILY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MikadaDailyReportActionTypes.FETCH_ALL_MIKADA_DAILY_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMikadaDailyReports(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/memo/${action.payload}`);
    yield put({
      type: MikadaDailyReportActionTypes.FETCH_ONE_MIKADA_DAILY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MikadaDailyReportActionTypes.FETCH_ONE_MIKADA_DAILY_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchCountMikadaDailyReports(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/memo/count`);
    yield put({
      type: MikadaDailyReportActionTypes.FETCH_COUNT_MIKADA_DAILY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MikadaDailyReportActionTypes.FETCH_COUNT_MIKADA_DAILY_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMikadaDailyReports() {
  yield takeLatest(MikadaDailyReportActionTypes.FETCH_ALL_MIKADA_DAILY_REPORT, fetchAllMikadaDailyReports);
}

export function* watcherFetchOneMikadaDailyReports() {
  yield takeLatest(MikadaDailyReportActionTypes.FETCH_ONE_MIKADA_DAILY_REPORT, fetchOneMikadaDailyReports);
}

export function* watcherFetchCountMikadaDailyReports() {
  yield takeLatest(MikadaDailyReportActionTypes.FETCH_COUNT_MIKADA_DAILY_REPORT, fetchCountMikadaDailyReports);
}
