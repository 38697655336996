import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { LaboratoryEquipmentCostActionTypes } from "./LaboratoryEquipmentCost.type";

export function* fetchAllLaboratoryEquipmentCosts(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/laboratory-equipment-cost?project_id=${action.payload?.project_id}`);
    yield put({
      type: LaboratoryEquipmentCostActionTypes.FETCH_ALL_LABORATORY_EQUIPMENT_COST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: LaboratoryEquipmentCostActionTypes.FETCH_ALL_LABORATORY_EQUIPMENT_COST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneLaboratoryEquipmentCosts(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/laboratory-equipment-cost/${action.payload}`
    );
    yield put({
      type: LaboratoryEquipmentCostActionTypes.FETCH_ONE_LABORATORY_EQUIPMENT_COST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: LaboratoryEquipmentCostActionTypes.FETCH_ONE_LABORATORY_EQUIPMENT_COST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllLaboratoryEquipmentCosts() {
  yield takeLatest(LaboratoryEquipmentCostActionTypes.FETCH_ALL_LABORATORY_EQUIPMENT_COST, fetchAllLaboratoryEquipmentCosts);
}

export function* watcherFetchOneLaboratoryEquipmentCosts() {
  yield takeLatest(LaboratoryEquipmentCostActionTypes.FETCH_ONE_LABORATORY_EQUIPMENT_COST, fetchOneLaboratoryEquipmentCosts);
}
