import { OperationStandardStateTypes, OperationStandardActionTypes } from "./OperationStandard.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: OperationStandardStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const OperationStandardReducer = (
  state: OperationStandardStateTypes = INITIAL_STATE,
  action: any
): OperationStandardStateTypes => {
  switch (action.type) {
    case OperationStandardActionTypes.FETCH_ALL_OPERATION_STANDARD:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case OperationStandardActionTypes.FETCH_ALL_OPERATION_STANDARD_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case OperationStandardActionTypes.FETCH_ALL_OPERATION_STANDARD_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case OperationStandardActionTypes.FETCH_ALL_OPERATION_STANDARD_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case OperationStandardActionTypes.FETCH_ONE_OPERATION_STANDARD:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case OperationStandardActionTypes.FETCH_ONE_OPERATION_STANDARD_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case OperationStandardActionTypes.FETCH_ONE_OPERATION_STANDARD_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case OperationStandardActionTypes.FETCH_ONE_OPERATION_STANDARD_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default OperationStandardReducer;
