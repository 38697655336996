import { Document } from "../Document/Document.type";
import { PlaceOfWork } from "../PlaceOfWork/PlaceOfWork.type";
import { ApiCallState } from "../Utils";

export type SiteAttendance= {
  id: number;
  date:string;
  period_start:string;
  period_end:string;
  place_of_work_id:number;
  user_id:number;
  document:Document;
  place_of_work:PlaceOfWork;
  site_attendance_items:SiteAttendanceItem[];
};

export type SiteAttendanceItem= {
    id: number;
    site_attendance_id:number;
    is_title:boolean;
    title:string;
    id_no:string;
    full_name:string;
    position:string;
    daily_wage:number;
    working_days:number;
    normal:number;
    sunday:number;
    holyday:number;
}

export type SiteAttendanceStateTypes = {
  fetchAll: ApiCallState<SiteAttendance[]>;
  fetchOne: ApiCallState<SiteAttendance | {}>;
};

export const SiteAttendanceActionTypes = {
  FETCH_ALL_SITE_ATTENDANCE: "FETCH_ALL_SITE_ATTENDANCE",
  FETCH_ALL_SITE_ATTENDANCE_RESET: "FETCH_ALL_SITE_ATTENDANCE_RESET",
  FETCH_ALL_SITE_ATTENDANCE_FAILURE: "FETCH_ALL_SITE_ATTENDANCE_FAILURE",
  FETCH_ALL_SITE_ATTENDANCE_SUCCESS: "FETCH_ALL_SITE_ATTENDANCE_SUCCESS",

  FETCH_ONE_SITE_ATTENDANCE: "FETCH_ONE_SITE_ATTENDANCE",
  FETCH_ONE_SITE_ATTENDANCE_RESET: "FETCH_ONE_SITE_ATTENDANCE_RESET",
  FETCH_ONE_SITE_ATTENDANCE_FAILURE: "FETCH_ONE_SITE_ATTENDANCE_FAILURE",
  FETCH_ONE_SITE_ATTENDANCE_SUCCESS: "FETCH_ONE_SITE_ATTENDANCE_SUCCESS",
};
