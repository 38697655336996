import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { StaffAttendanceActionTypes } from "./StaffAttendance.type";

export function* fetchAllStaffAttendances(action: any): any {
  try {
    let query = "";

    if (action.payload && Object.keys(action.payload).length > 0) {
      query =
        "?" +
        Object.keys(action.payload)
          .map((key: any) => `${key}=${action.payload[key]}`)
          .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/staff-attendance${query}`
    );
    yield put({
      type: StaffAttendanceActionTypes.FETCH_ALL_STAFF_ATTENDANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffAttendanceActionTypes.FETCH_ALL_STAFF_ATTENDANCE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchAllMonthlyStaffAttendances(action: any): any {
  try {
    let query = "";

    if (action.payload && Object.keys(action.payload).length > 0) {
      query =
        "?" +
        Object.keys(action.payload)
          .map((key: any) => `${key}=${action.payload[key]}`)
          .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/staff-attendance${query}`
    );
    yield put({
      type: StaffAttendanceActionTypes.FETCH_ALL_MONTHLY_STAFF_ATTENDANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffAttendanceActionTypes.FETCH_ALL_MONTHLY_STAFF_ATTENDANCE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllStaffAttendances() {
  yield takeLatest(
    StaffAttendanceActionTypes.FETCH_ALL_STAFF_ATTENDANCE,
    fetchAllStaffAttendances
  );
}

export function* watcherFetchAllMonthlyStaffAttendances() {
  yield takeLatest(
    StaffAttendanceActionTypes.FETCH_ALL_MONTHLY_STAFF_ATTENDANCE,
    fetchAllMonthlyStaffAttendances
  );
}
