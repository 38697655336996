import { ApiCallState, PagedData } from "../Utils";

export type MaterialMajorCategory = {
  id?: number;
  key: number;
  description: string;
  code: string;
  type: string;
};

export type MaterialMajorCategoryStateTypes = {
  fetchAll: ApiCallState<MaterialMajorCategory[]>;
  fetchAllForm: ApiCallState<MaterialMajorCategory[]>;
  fetchOne: ApiCallState<MaterialMajorCategory | {}>;
  fetchPaged: ApiCallState<PagedData<MaterialMajorCategory[]>>;
};

export const MaterialMajorCategoryActionTypes = {
  FETCH_ALL_MATERIAL_MAJOR_CATEGORY: "FETCH_ALL_MATERIAL_MAJOR_CATEGORY",
  FETCH_ALL_MATERIAL_MAJOR_CATEGORY_RESET:
    "FETCH_ALL_MATERIAL_MAJOR_CATEGORY_RESET",
  FETCH_ALL_MATERIAL_MAJOR_CATEGORY_FAILURE:
    "FETCH_ALL_MATERIAL_MAJOR_CATEGORY_FAILURE",
  FETCH_ALL_MATERIAL_MAJOR_CATEGORY_SUCCESS:
    "FETCH_ALL_MATERIAL_MAJOR_CATEGORY_SUCCESS",

  FETCH_ALL_FORM_MATERIAL_MAJOR_CATEGORY:
    "FETCH_ALL_FORM_MATERIAL_MAJOR_CATEGORY",
  FETCH_ALL_FORM_MATERIAL_MAJOR_CATEGORY_RESET:
    "FETCH_ALL_FORM_MATERIAL_MAJOR_CATEGORY_RESET",
  FETCH_ALL_FORM_MATERIAL_MAJOR_CATEGORY_FAILURE:
    "FETCH_ALL_FORM_MATERIAL_MAJOR_CATEGORY_FAILURE",
  FETCH_ALL_FORM_MATERIAL_MAJOR_CATEGORY_SUCCESS:
    "FETCH_ALL_FORM_MATERIAL_MAJOR_CATEGORY_SUCCESS",

  FETCH_PAGED_MATERIAL_MAJOR_CATEGORY: "FETCH_PAGED_MATERIAL_MAJOR_CATEGORY",
  FETCH_PAGED_MATERIAL_MAJOR_CATEGORY_RESET:
    "FETCH_PAGED_MATERIAL_MAJOR_CATEGORY_RESET",
  FETCH_PAGED_MATERIAL_MAJOR_CATEGORY_FAILURE:
    "FETCH_PAGED_MATERIAL_MAJOR_CATEGORY_FAILURE",
  FETCH_PAGED_MATERIAL_MAJOR_CATEGORY_SUCCESS:
    "FETCH_PAGED_MATERIAL_MAJOR_CATEGORY_SUCCESS",

  FETCH_ONE_MATERIAL_MAJOR_CATEGORY: "FETCH_ONE_MATERIAL_MAJOR_CATEGORY",
  FETCH_ONE_MATERIAL_MAJOR_CATEGORY_RESET:
    "FETCH_ONE_MATERIAL_MAJOR_CATEGORY_RESET",
  FETCH_ONE_MATERIAL_MAJOR_CATEGORY_FAILURE:
    "FETCH_ONE_MATERIAL_MAJOR_CATEGORY_FAILURE",
  FETCH_ONE_MATERIAL_MAJOR_CATEGORY_SUCCESS:
    "FETCH_ONE_MATERIAL_MAJOR_CATEGORY_SUCCESS",
};
