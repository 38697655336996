import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MaterialActionTypes } from "./Material.type";
import { formatQuery } from "../Utils";

export function* fetchAllMaterials(action: any): any {
  try {
    const query = formatQuery(action);

    const response = yield axios.get(`${API_BASE_URI}/material?${query}`);
    yield put({
      type: MaterialActionTypes.FETCH_ALL_MATERIAL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialActionTypes.FETCH_ALL_MATERIAL_FAILURE,
      payload: error,
    });
  }
}

export function* fetchAllFormMaterials(action: any): any {
  try {
    const query = formatQuery(action);

    const response = yield axios.get(`${API_BASE_URI}/material?${query}`);
    yield put({
      type: MaterialActionTypes.FETCH_ALL_FORM_MATERIAL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialActionTypes.FETCH_ALL_FORM_MATERIAL_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedMaterials(action: any): any {
  try {
    const query = formatQuery(action);

    const response = yield axios.get(`${API_BASE_URI}/material/paged?${query}`);
    yield put({
      type: MaterialActionTypes.FETCH_PAGED_MATERIAL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialActionTypes.FETCH_PAGED_MATERIAL_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMaterials(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/material/${action.payload}`
    );
    yield put({
      type: MaterialActionTypes.FETCH_ONE_MATERIAL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialActionTypes.FETCH_ONE_MATERIAL_FAILURE,
      payload: error,
    });
  }
}

export function* fetchMaterialNumber(action: any): any {
  try {
    const query = formatQuery(action);

    const response = yield axios.get(
      `${API_BASE_URI}/material/code-number?${query}`
    );
    yield put({
      type: MaterialActionTypes.FETCH_MATERIAL_NUMBER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialActionTypes.FETCH_MATERIAL_NUMBER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMaterials() {
  yield takeLatest(MaterialActionTypes.FETCH_ALL_MATERIAL, fetchAllMaterials);
}

export function* watcherFetchAllFormMaterials() {
  yield takeLatest(
    MaterialActionTypes.FETCH_ALL_FORM_MATERIAL,
    fetchAllFormMaterials
  );
}

export function* watcherFetchPagedMaterials() {
  yield takeLatest(
    MaterialActionTypes.FETCH_PAGED_MATERIAL,
    fetchPagedMaterials
  );
}

export function* watcherFetchOneMaterials() {
  yield takeLatest(MaterialActionTypes.FETCH_ONE_MATERIAL, fetchOneMaterials);
}

export function* watcherFetchMaterialNumber() {
  yield takeLatest(
    MaterialActionTypes.FETCH_MATERIAL_NUMBER,
    fetchMaterialNumber
  );
}
