import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { FuelIssueVoucherActionTypes } from "./FuelIssueVoucher.type";

export function* fetchAllFuelIssueVouchers(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/fuel-issue-voucher`);
    yield put({
      type: FuelIssueVoucherActionTypes.FETCH_ALL_FUEL_ISSUE_VOUCHER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FuelIssueVoucherActionTypes.FETCH_ALL_FUEL_ISSUE_VOUCHER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneFuelIssueVouchers(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/fuel-issue-voucher/${action.payload}`
    );
    yield put({
      type: FuelIssueVoucherActionTypes.FETCH_ONE_FUEL_ISSUE_VOUCHER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FuelIssueVoucherActionTypes.FETCH_ONE_FUEL_ISSUE_VOUCHER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllFuelIssueVouchers() {
  yield takeLatest(FuelIssueVoucherActionTypes.FETCH_ALL_FUEL_ISSUE_VOUCHER, fetchAllFuelIssueVouchers);
}

export function* watcherFetchOneFuelIssueVouchers() {
  yield takeLatest(FuelIssueVoucherActionTypes.FETCH_ONE_FUEL_ISSUE_VOUCHER, fetchOneFuelIssueVouchers);
}
