import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { TireRequestActionTypes } from "./TireRequest.type";

export function* fetchAllTireRequests(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/tire-request?${query}`);
    yield put({
      type: TireRequestActionTypes.FETCH_ALL_TIRE_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TireRequestActionTypes.FETCH_ALL_TIRE_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedTireRequests(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/tire-request?${query}`);
    yield put({
      type: TireRequestActionTypes.FETCH_PAGED_TIRE_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TireRequestActionTypes.FETCH_PAGED_TIRE_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneTireRequests(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/tire-request/${action.payload}`
    );
    yield put({
      type: TireRequestActionTypes.FETCH_ONE_TIRE_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TireRequestActionTypes.FETCH_ONE_TIRE_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllTireRequests() {
  yield takeLatest(
    TireRequestActionTypes.FETCH_ALL_TIRE_REQUEST,
    fetchAllTireRequests
  );
}

export function* watcherFetchPagedTireRequests() {
  yield takeLatest(
    TireRequestActionTypes.FETCH_PAGED_TIRE_REQUEST,
    fetchPagedTireRequests
  );
}

export function* watcherFetchOneTireRequests() {
  yield takeLatest(
    TireRequestActionTypes.FETCH_ONE_TIRE_REQUEST,
    fetchOneTireRequests
  );
}
