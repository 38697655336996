import { ConsultantFacilityCostStateTypes, ConsultantFacilityCostActionTypes } from "./ConsultantFacilityCost.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ConsultantFacilityCostStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const ConsultantFacilityCostReducer = (
  state: ConsultantFacilityCostStateTypes = INITIAL_STATE,
  action: any
): ConsultantFacilityCostStateTypes => {
  switch (action.type) {
    case ConsultantFacilityCostActionTypes.FETCH_ALL_CONSULTANT_FACILITY_COST:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ConsultantFacilityCostActionTypes.FETCH_ALL_CONSULTANT_FACILITY_COST_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ConsultantFacilityCostActionTypes.FETCH_ALL_CONSULTANT_FACILITY_COST_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ConsultantFacilityCostActionTypes.FETCH_ALL_CONSULTANT_FACILITY_COST_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ConsultantFacilityCostActionTypes.FETCH_ONE_CONSULTANT_FACILITY_COST:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ConsultantFacilityCostActionTypes.FETCH_ONE_CONSULTANT_FACILITY_COST_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ConsultantFacilityCostActionTypes.FETCH_ONE_CONSULTANT_FACILITY_COST_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ConsultantFacilityCostActionTypes.FETCH_ONE_CONSULTANT_FACILITY_COST_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ConsultantFacilityCostReducer;
