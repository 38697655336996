import { Boq } from "../Boq/Boq.type";
import { ApiCallState } from "../Utils";

export type FinanceDailyReport= {
  id: number,
  description: string,
  date: string,
  quantity_performed: number,
  weather: string,
  forman: string,
  report_equipment_items: ReportEquipmentItem[],
  report_labour_items: ReportLabourItem[],
  report_material_items: ReportMaterialItem[],
  report_contract_items: ReportContractItem[],
  report_fuel_lubricants: ReportFuelLubricantItem[],
  report_material_delivery_items: ReportMaterialDeliveryItem[],
  report_petty_cash_expense_items: ReportPettyCashExpenseItem[],
  report_income_work_items: ReportIncomeWorkItem[],
  finance_daily_report_item:FinanceDailyReportItem,
  finance_daily_report_checkeds:FinanceDailyReportChecked[],
  finance_daily_report_approveds:FinanceDailyReportApproved[],
  finance_daily_report_views:FinanceDailyReportView[],
  boq_id: number,
  boq?: Boq,
  qtyp: boolean,
  user_id:number,
  createdAt: any,
  updatedAt: any,
  finance_daily_report_statuses: any[]
};


export type FinanceDailyReportView ={
  id:number;
  finance_daily_report_id:number;
  user_id: number;
  sender_id:number;
  createdAt: any;
  updatedAt: any;
}

export type FinanceDailyReportChecked ={
  id:number;
  finance_daily_report_id:number;
  user_id: number;
  sender_id:number;
  is_checked:boolean;
  is_revised:boolean;
  createdAt: any;
  updatedAt: any;
}

export type FinanceDailyReportApproved ={
  id:number;
  finance_daily_report_id:number;
  user_id: number;
  sender_id:number;
  is_approved:boolean;
  is_revised:boolean;
  createdAt: any;
  updatedAt: any;
}

export type FinanceDailyReportItem ={
  id?:number,
  description: string,
  unit: string,
  quantity: number,
  unit_price: number,
  planned_amount:number,
  createdAt: any,
  updatedAt: any
}

export type ReportEquipmentItem = {
  id?: number,
  amount: number,
  duration: number,
  rate: number,
  type: string,
  dialy_report_id: number,
  createdAt: any,
  updatedAt: any
};

export type ReportLabourItem = {
  id?: number,
  amount: number,
  duration: number,
  holiday_overtime: number,
  normal_overtime: number,
  rate: number,
  type: string,
  quantity: number,
  dialy_report_id: number,
  createdAt: any,
  updatedAt: any
};

export type ReportMaterialItem = {
  id?: number,
  amount: number,
  quantity: number,
  rate: number,
  type: string,
  unit: string,
  dialy_report_id: number,
  createdAt: any,
  updatedAt: any
};

export type ReportContractItem = {
  id?: number,
  type: string,
  amount: number,
  quantity: number,
  rate: number,
  description: string,
  dialy_report_id: number,
  createdAt: any,
  updatedAt: any
};

export type ReportFuelLubricantItem = {
  id?: number,
  description: string,
  unit: string;
  quantity: number,
  rate: number,
  dialy_report_id: number,
  createdAt: any,
  updatedAt: any
};

export type ReportMaterialDeliveryItem = {
  id?: number,
  description: string,
  unit: string;
  quantity: number,
  rate: number,
  dialy_report_id: number,
  createdAt: any,
  updatedAt: any
};

export type ReportIncomeWorkItem = {
  id?: number,
  description: string,
  unit: string;
  quantity: number,
  rate: number,
  dialy_report_id: number,
  createdAt: any,
  updatedAt: any
};

export type ReportPettyCashExpenseItem = {
  id?: number,
  description: string,
  quantity: number,
  rate: number,
  dialy_report_id: number,
  createdAt: any,
  updatedAt: any
};

export type FinanceDailyReportStateTypes = {
  fetchAll: ApiCallState<FinanceDailyReport[]>;
  fetchBeforeDate: ApiCallState<FinanceDailyReport[]>;
  fetchOne: ApiCallState<FinanceDailyReport | {}>;
};

export const FinanceDailyReportActionTypes = {
  FETCH_ALL_FINANCE_DAILY_REPORT: "FETCH_ALL_FINANCE_DAILY_REPORT",
  FETCH_ALL_FINANCE_DAILY_REPORT_RESET: "FETCH_ALL_FINANCE_DAILY_REPORT_RESET",
  FETCH_ALL_FINANCE_DAILY_REPORT_FAILURE: "FETCH_ALL_FINANCE_DAILY_REPORT_FAILURE",
  FETCH_ALL_FINANCE_DAILY_REPORT_SUCCESS: "FETCH_ALL_FINANCE_DAILY_REPORT_SUCCESS",

  FETCH_FINANCE_DAILY_REPORT: "FETCH_FINANCE_DAILY_REPORT",
  FETCH_FINANCE_DAILY_REPORT_RESET: "FETCH_FINANCE_DAILY_REPORT_RESET",
  FETCH_FINANCE_DAILY_REPORT_FAILURE: "FETCH_FINANCE_DAILY_REPORT_FAILURE",
  FETCH_FINANCE_DAILY_REPORT_SUCCESS: "FETCH_FINANCE_DAILY_REPORT_SUCCESS",

  FETCH_ONE_FINANCE_DAILY_REPORT: "FETCH_ONE_FINANCE_DAILY_REPORT",
  FETCH_ONE_FINANCE_DAILY_REPORT_RESET: "FETCH_ONE_FINANCE_DAILY_REPORT_RESET",
  FETCH_ONE_FINANCE_DAILY_REPORT_FAILURE: "FETCH_ONE_FINANCE_DAILY_REPORT_FAILURE",
  FETCH_ONE_FINANCE_DAILY_REPORT_SUCCESS: "FETCH_ONE_FINANCE_DAILY_REPORT_SUCCESS",
};
