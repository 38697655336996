import { Form, Input } from "antd";
import { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { NotificationType } from "../../../../constants/Constants";
import { fetchOneUser } from "../../../../redux/User/User.action";
import { ErrorHandler, getUserData } from "../../../../utilities/utilities";
import { OpenNotification } from "../../../common/Notification/Notification.component";
import {
  CredentialPropType,
  sendPasswordData,
  RandomPasswordGenerator,
} from "../../util/Setting.util";
import PasswordChecklist from "react-password-checklist";

const CredentialComponent: FC<CredentialPropType> = ({
  fetchUser,
  user,
  form,
  loadingAction,
}) => {
  const [loading, setLoading] = loadingAction;
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");

  useEffect(() => {
    let random_password = RandomPasswordGenerator();
    if (random_password) {
      setPassword(random_password);
      form.setFieldsValue({
        new_password: random_password,
      });
    }
  }, [form]);

  const submit = (value: any) => {
    setLoading(true);
    delete value["confirmPassword"];

    sendPasswordData(value)
      .then(() => {
        fetchUser(getUserData().id);

        setLoading(false);
        OpenNotification(NotificationType.SUCCESS, "Password Updated", "");
      })
      .catch((error) => {
        setLoading(false);
        ErrorHandler(error).map((e: any) =>
          OpenNotification(
            NotificationType.ERROR,
            "Failed to Update User",
            e.message
          )
        );
      });
  };

  return (
    <Form layout="vertical" form={form} onFinish={submit}>
      <div className="row">
        <div className="col-md-12">
          <Form.Item
            label="Old Password"
            name="old_password"
            rules={[{ required: true, message: "Old Password Required" }]}
          >
            <Input type={"password"} placeholder="password" />
          </Form.Item>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Form.Item
            label="New Password"
            name="new_password"
            rules={[{ required: true, message: "Password Required" }]}
          >
            <Input.Password
              placeholder="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Item>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Form.Item
            label="Confirm password"
            name="confirmPassword"
            dependencies={["new_password"]}
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              // ({ getFieldValue }) => ({
              //   validator(_, value) {
              //     if (!value || getFieldValue("new_password") === value) {
              //       return Promise.resolve();
              //     }
              //     return Promise.reject(
              //       new Error("The two passwords do not match!")
              //     );
              //   },
              // }),
            ]}
          >
            <Input.Password
              placeholder="password"
              onChange={(e) => setPasswordAgain(e.target.value)}
            />
          </Form.Item>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <PasswordChecklist
            rules={[
              "minLength",
              "specialChar",
              "number",
              "capital",
              "lowercase",
              "match",
            ]}
            minLength={10}
            value={password}
            valueAgain={passwordAgain}
            messages={{
              minLength: "Minimum length of characters should be 10 ",
              specialChar: "Symbols (*@#$%&!)",
              number: "Numbers (0-9)",
              capital: "Upercase Letters (A-Z)",
              lowercase: "Lowercase Letters (a-z)",
              match: "Please confirm your password!",
            }}
          />
        </div>
      </div>
    </Form>
  );
};

/**
 * Map State to Props
 *
 * @param state
 */
const mapStateToProps = (state: any) => ({
  user: state.user.fetchOne,
});

/**
 * Map Dispatch to Props
 *
 * @param dispatch
 */
const mapDispatchToProps = (dispatch: any) => ({
  fetchUser: (action: any) => dispatch(fetchOneUser(action)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CredentialComponent);
