import { MaintenanceRequest } from "../MaintenanceRequest/MaintenanceRequest.type";
import { Staff } from "../Staff/Staff.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type MaintenanceVoucher = {
  id: number;
  user_id: number;
  maintenance_request_id: number;

  maintenance_voucher_number: string;
  date: string;

  maintained_by_id: number;
  maintained_by_name: string;
  maintainer_date: string;

  operator_id: number;
  operator_name: string;
  operator_date: string;

  supervisor_id: number;
  supervisor_name: string;
  supervisor_date: string;

  vehicle_type: string;

  date_of_entry: string;
  hour_of_entry: string;

  date_of_release: string;
  hour_of_release: string;

  maintenance_made: string;
  changed_items: string;

  paid_price: number;

  maintenance_request: MaintenanceRequest;
  maintained_by: Staff | null;
  operator: Staff | null;
  supervisor: User | null;

  maintenance_voucher_items: MaintenanceVoucherItem[];
};

export type MaintenanceVoucherItem = {
  id: number;
  maintenance_voucher_id: number;

  description: string;
  unit: string;
  quantity: number;
};

export type MaintenanceVoucherStateTypes = {
  fetchAll: ApiCallState<MaintenanceVoucher[]>;
  fetchOne: ApiCallState<MaintenanceVoucher | {}>;
  fetchNumber: ApiCallState<string | null>;
};

export const MaintenanceVoucherActionTypes = {
  FETCH_ALL_MAINTENANCE_VOUCHER: "FETCH_ALL_MAINTENANCE_VOUCHER",
  FETCH_ALL_MAINTENANCE_VOUCHER_RESET: "FETCH_ALL_MAINTENANCE_VOUCHER_RESET",
  FETCH_ALL_MAINTENANCE_VOUCHER_FAILURE:
    "FETCH_ALL_MAINTENANCE_VOUCHER_FAILURE",
  FETCH_ALL_MAINTENANCE_VOUCHER_SUCCESS:
    "FETCH_ALL_MAINTENANCE_VOUCHER_SUCCESS",

  FETCH_ONE_MAINTENANCE_VOUCHER: "FETCH_ONE_MAINTENANCE_VOUCHER",
  FETCH_ONE_MAINTENANCE_VOUCHER_RESET: "FETCH_ONE_MAINTENANCE_VOUCHER_RESET",
  FETCH_ONE_MAINTENANCE_VOUCHER_FAILURE:
    "FETCH_ONE_MAINTENANCE_VOUCHER_FAILURE",
  FETCH_ONE_MAINTENANCE_VOUCHER_SUCCESS:
    "FETCH_ONE_MAINTENANCE_VOUCHER_SUCCESS",

  FETCH_MAINTENANCE_VOUCHER_NUMBER: "FETCH_MAINTENANCE_VOUCHER_NUMBER",
  FETCH_MAINTENANCE_VOUCHER_NUMBER_RESET:
    "FETCH_MAINTENANCE_VOUCHER_NUMBER_RESET",
  FETCH_MAINTENANCE_VOUCHER_NUMBER_FAILURE:
    "FETCH_MAINTENANCE_VOUCHER_NUMBER_FAILURE",
  FETCH_MAINTENANCE_VOUCHER_NUMBER_SUCCESS:
    "FETCH_MAINTENANCE_VOUCHER_NUMBER_SUCCESS",
};
