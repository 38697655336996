import { ApiCallState } from "../Utils";

export type Budget= {
  id: number;
  project_id:number;
  year:string;
  user_id:number;
  budget_items:BudgetItem[];
};

export type BudgetItem={
    id:number;
    budget_id:number;
    budget_code:string;
    description:string;
    july:number;
    august:number;
    september:number;
    october:number;
    november:number;
    december:number;
    january:number;
    february:number;
    march:number;
    may:number;
    june:number;
    total:number;
}

export type BudgetStateTypes = {
  fetchAll: ApiCallState<Budget[]>;
  fetchOne: ApiCallState<Budget | {}>;
};

export const BudgetActionTypes = {
  FETCH_ALL_BUDGET: "FETCH_ALL_BUDGET",
  FETCH_ALL_BUDGET_RESET: "FETCH_ALL_BUDGET_RESET",
  FETCH_ALL_BUDGET_FAILURE: "FETCH_ALL_BUDGET_FAILURE",
  FETCH_ALL_BUDGET_SUCCESS: "FETCH_ALL_BUDGET_SUCCESS",

  FETCH_ONE_BUDGET: "FETCH_ONE_BUDGET",
  FETCH_ONE_BUDGET_RESET: "FETCH_ONE_BUDGET_RESET",
  FETCH_ONE_BUDGET_FAILURE: "FETCH_ONE_BUDGET_FAILURE",
  FETCH_ONE_BUDGET_SUCCESS: "FETCH_ONE_BUDGET_SUCCESS",
};
