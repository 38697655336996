import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../../ApiCall";
import { BankLoanActionTypes } from "./BankLoan.type";

export function* fetchAllBankLoans(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys
        .map((key) =>
          action.payload[key] ? `${key}=${action.payload[key]}` : null
        )
        .join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/bank-loan?${query}`
    );
    yield put({
      type: BankLoanActionTypes.FETCH_ALL_BANK_LOAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BankLoanActionTypes.FETCH_ALL_BANK_LOAN_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneBankLoans(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/bank-loan/${action.payload}`
    );
    yield put({
      type: BankLoanActionTypes.FETCH_ONE_BANK_LOAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BankLoanActionTypes.FETCH_ONE_BANK_LOAN_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllBankLoans() {
  yield takeLatest(
    BankLoanActionTypes.FETCH_ALL_BANK_LOAN,
    fetchAllBankLoans
  );
}

export function* watcherFetchOneBankLoans() {
  yield takeLatest(
    BankLoanActionTypes.FETCH_ONE_BANK_LOAN,
    fetchOneBankLoans
  );
}
