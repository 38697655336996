import { UncollectedIpcStateTypes, UncollectedIpcActionTypes } from "./UncollectedIpc.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: UncollectedIpcStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const UncollectedIpcReducer = (
  state: UncollectedIpcStateTypes = INITIAL_STATE,
  action: any
): UncollectedIpcStateTypes => {
  switch (action.type) {
    case UncollectedIpcActionTypes.FETCH_ALL_UNCOLLECTED_IPC:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case UncollectedIpcActionTypes.FETCH_ALL_UNCOLLECTED_IPC_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case UncollectedIpcActionTypes.FETCH_ALL_UNCOLLECTED_IPC_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case UncollectedIpcActionTypes.FETCH_ALL_UNCOLLECTED_IPC_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case UncollectedIpcActionTypes.FETCH_ONE_UNCOLLECTED_IPC:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case UncollectedIpcActionTypes.FETCH_ONE_UNCOLLECTED_IPC_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case UncollectedIpcActionTypes.FETCH_ONE_UNCOLLECTED_IPC_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case UncollectedIpcActionTypes.FETCH_ONE_UNCOLLECTED_IPC_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default UncollectedIpcReducer;
