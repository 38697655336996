import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../../ApiCall";
import { TelegraphicTransferActionTypes } from "./TelegraphicTransfer.type";

export function* fetchAllTelegraphicTransfers(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys
        .map((key) =>
          action.payload[key] ? `${key}=${action.payload[key]}` : null
        )
        .join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/telegraphic-transfer?${query}`
    );
    yield put({
      type: TelegraphicTransferActionTypes.FETCH_ALL_TELEGRAPHIC_TRANSFER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TelegraphicTransferActionTypes.FETCH_ALL_TELEGRAPHIC_TRANSFER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneTelegraphicTransfers(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/telegraphic-transfer/${action.payload}`
    );
    yield put({
      type: TelegraphicTransferActionTypes.FETCH_ONE_TELEGRAPHIC_TRANSFER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TelegraphicTransferActionTypes.FETCH_ONE_TELEGRAPHIC_TRANSFER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllTelegraphicTransfers() {
  yield takeLatest(
    TelegraphicTransferActionTypes.FETCH_ALL_TELEGRAPHIC_TRANSFER,
    fetchAllTelegraphicTransfers
  );
}

export function* watcherFetchOneTelegraphicTransfers() {
  yield takeLatest(
    TelegraphicTransferActionTypes.FETCH_ONE_TELEGRAPHIC_TRANSFER,
    fetchOneTelegraphicTransfers
  );
}
