import { IllegalReceiptStateTypes, IllegalReceiptActionTypes } from "./IllegalReceipt.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: IllegalReceiptStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const IllegalReceiptReducer = (
  state: IllegalReceiptStateTypes = INITIAL_STATE,
  action: any
): IllegalReceiptStateTypes => {
  switch (action.type) {
    case IllegalReceiptActionTypes.FETCH_ALL_ILLEGAL_RECEIPT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case IllegalReceiptActionTypes.FETCH_ALL_ILLEGAL_RECEIPT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case IllegalReceiptActionTypes.FETCH_ALL_ILLEGAL_RECEIPT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case IllegalReceiptActionTypes.FETCH_ALL_ILLEGAL_RECEIPT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case IllegalReceiptActionTypes.FETCH_ONE_ILLEGAL_RECEIPT:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case IllegalReceiptActionTypes.FETCH_ONE_ILLEGAL_RECEIPT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case IllegalReceiptActionTypes.FETCH_ONE_ILLEGAL_RECEIPT_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case IllegalReceiptActionTypes.FETCH_ONE_ILLEGAL_RECEIPT_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default IllegalReceiptReducer;
