import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { formatQuery } from "../Utils";
import { SupplierActions } from "./Supplier.type";

export function* fetchSupplies(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/supplier?${formatQuery(action)}`
    );
    yield put({
      type: SupplierActions.FETCH_SUPPLIER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SupplierActions.FETCH_SUPPLIER_ERROR,
      payload: error,
    });
  }
}

export function* watcherFetchSupplies() {
  yield takeLatest(SupplierActions.FETCH_SUPPLIER, fetchSupplies);
}
