import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { TrainingActionTypes } from "./Training.type";

export function* fetchAllTrainings(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/training?${query}`);
    yield put({
      type: TrainingActionTypes.FETCH_ALL_TRAINING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TrainingActionTypes.FETCH_ALL_TRAINING_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneTrainings(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/training/${action.payload}`
    );
    yield put({
      type: TrainingActionTypes.FETCH_ONE_TRAINING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TrainingActionTypes.FETCH_ONE_TRAINING_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllTrainings() {
  yield takeLatest(TrainingActionTypes.FETCH_ALL_TRAINING, fetchAllTrainings);
}

export function* watcherFetchOneTrainings() {
  yield takeLatest(TrainingActionTypes.FETCH_ONE_TRAINING, fetchOneTrainings);
}
