import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ApplicantRegistryActionTypes } from "./ApplicantRegistry.type";

export function* fetchAllApplicantRegistrys(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/applicant-registry`);
    yield put({
      type: ApplicantRegistryActionTypes.FETCH_ALL_APPLICANT_REGISTRY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ApplicantRegistryActionTypes.FETCH_ALL_APPLICANT_REGISTRY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneApplicantRegistry(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/applicant-registry/${action.payload}`
    );
    yield put({
      type: ApplicantRegistryActionTypes.FETCH_ONE_APPLICANT_REGISTRY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ApplicantRegistryActionTypes.FETCH_ONE_APPLICANT_REGISTRY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllApplicantRegistrys() {
  yield takeLatest(ApplicantRegistryActionTypes.FETCH_ALL_APPLICANT_REGISTRY, fetchAllApplicantRegistrys);
}

export function* watcherFetchOneApplicantRegistrys() {
  yield takeLatest(ApplicantRegistryActionTypes.FETCH_ONE_APPLICANT_REGISTRY, fetchOneApplicantRegistry);
}
