import {
  NewsAndMotivationStateTypes,
  NewsAndMotivationActionTypes,
} from "./NewsAndMotivation.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: NewsAndMotivationStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const NewsAndMotivationReducer = (
  state: NewsAndMotivationStateTypes = INITIAL_STATE,
  action: any
): NewsAndMotivationStateTypes => {
  switch (action.type) {
    case NewsAndMotivationActionTypes.FETCH_ALL_NEWS_AND_MOTIVATION:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case NewsAndMotivationActionTypes.FETCH_ALL_NEWS_AND_MOTIVATION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case NewsAndMotivationActionTypes.FETCH_ALL_NEWS_AND_MOTIVATION_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case NewsAndMotivationActionTypes.FETCH_ALL_NEWS_AND_MOTIVATION_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case NewsAndMotivationActionTypes.FETCH_ONE_NEWS_AND_MOTIVATION:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case NewsAndMotivationActionTypes.FETCH_ONE_NEWS_AND_MOTIVATION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case NewsAndMotivationActionTypes.FETCH_ONE_NEWS_AND_MOTIVATION_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case NewsAndMotivationActionTypes.FETCH_ONE_NEWS_AND_MOTIVATION_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default NewsAndMotivationReducer;
