import { ArchiveStateTypes, ArchiveActionTypes } from "./Archive.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ArchiveStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const ArchiveReducer = (
  state: ArchiveStateTypes = INITIAL_STATE,
  action: any
): ArchiveStateTypes => {
  switch (action.type) {
    case ArchiveActionTypes.FETCH_ALL_ARCHIVE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ArchiveActionTypes.FETCH_ALL_ARCHIVE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ArchiveActionTypes.FETCH_ALL_ARCHIVE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ArchiveActionTypes.FETCH_ALL_ARCHIVE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ArchiveActionTypes.FETCH_ONE_ARCHIVE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ArchiveActionTypes.FETCH_ONE_ARCHIVE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ArchiveActionTypes.FETCH_ONE_ARCHIVE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ArchiveActionTypes.FETCH_ONE_ARCHIVE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ArchiveReducer;
