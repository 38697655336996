import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MikadaWeeklyEquipmentReportActionTypes } from "./MikadaWeeklyEquipmentReport.type";

export function* fetchAllMikadaWeeklyEquipmentReports(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/mikada-weekly-equipment-report?date=${action.payload?.date}`);
    yield put({
      type: MikadaWeeklyEquipmentReportActionTypes.FETCH_ALL_MIKADA_WEEKLY_EQUIPMENT_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MikadaWeeklyEquipmentReportActionTypes.FETCH_ALL_MIKADA_WEEKLY_EQUIPMENT_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMikadaWeeklyEquipmentReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/mikada-weekly-equipment-report/${action.payload}`
    );
    yield put({
      type: MikadaWeeklyEquipmentReportActionTypes.FETCH_ONE_MIKADA_WEEKLY_EQUIPMENT_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MikadaWeeklyEquipmentReportActionTypes.FETCH_ONE_MIKADA_WEEKLY_EQUIPMENT_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMikadaWeeklyEquipmentReports() {
  yield takeLatest(MikadaWeeklyEquipmentReportActionTypes.FETCH_ALL_MIKADA_WEEKLY_EQUIPMENT_REPORT, fetchAllMikadaWeeklyEquipmentReports);
}

export function* watcherFetchOneMikadaWeeklyEquipmentReports() {
  yield takeLatest(MikadaWeeklyEquipmentReportActionTypes.FETCH_ONE_MIKADA_WEEKLY_EQUIPMENT_REPORT, fetchOneMikadaWeeklyEquipmentReports);
}
