import { FixedAssetIssue } from "../FixedAssetIssue/FixedAssetIssue.type";
import { Material } from "../Material/Material.type";
import { Store } from "../Store/Store.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type FixedAssetReturn = {
  id: number;
  date: string;
  fixed_asset_issue_id: number;
  project_id: number;
  fixed_asset_issue: FixedAssetIssue;
  store_id: number;
  department_id: number;
  far_to: Store;
  far_from: Store;
  far_department: Store;
  user_id: number;
  reason: string;
  fixed_asset_return_items: FixedAssetReturnItem[];
  far_prepared_by?: User;
  is_done: boolean;
  fixed_asset_return_statuses: FixedAssetReturnStatus[];
  status: FixedAssetReturnStatus[];
  key?: number;
  received_by: string;
  approved_by: string;
  pad_ref: string;
  employee: string;
  department: string;
  is_void: boolean;
  far_checked_by?: User;
  far_issued_by?: User;
  far_approved_by?: User;
  far_received_by?: User;
  updatedAt: string;
};

export type FixedAssetReturnReport = {
  approved_by?: any;
  code: string;
  date: string;
  description: string;
  from: string;
  to: string;
  item_category: string;
  id: number;
  major_category: string;
  material_requisition_id: string;
  pad_ref?: any;
  prepared_by: string;
  quantity: number;
  received_by?: any;
  remark: string;
  total: number;
  unit: string;
};

export type FixedAssetReturnStatus = {
  fixed_asset_return_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  remark: string;
  id: number;
};

export type FixedAssetReturnItem = {
  fixed_asset_out_id: number;
  material: Material;
  unit: string;
  material_id: number;
  serial_number: string;
  quantity: number;
  unit_price: number;
  remark: string;
  id?: number;
};

export type FixedAssetReturnStateTypes = {
  fetchAll: ApiCallState<FixedAssetReturn[]>;
  fetchReport: ApiCallState<FixedAssetReturnReport[]>;
};

export const FixedAssetReturnActionTypes = {
  FETCH_ALL_FIXED_ASSET_RETURN: "FETCH_ALL_FIXED_ASSET_RETURN",
  FETCH_ALL_FIXED_ASSET_RETURN_RESET: "FETCH_ALL_FIXED_ASSET_RETURN_RESET",
  FETCH_ALL_FIXED_ASSET_RETURN_FAILURE: "FETCH_ALL_FIXED_ASSET_RETURN_FAILURE",
  FETCH_ALL_FIXED_ASSET_RETURN_SUCCESS: "FETCH_ALL_FIXED_ASSET_RETURN_SUCCESS",

  FETCH_REPORT_FIXED_ASSET_RETURN: "FETCH_REPORT_FIXED_ASSET_RETURN",
  FETCH_REPORT_FIXED_ASSET_RETURN_RESET:
    "FETCH_REPORT_FIXED_ASSET_RETURN_RESET",
  FETCH_REPORT_FIXED_ASSET_RETURN_FAILURE:
    "FETCH_REPORT_FIXED_ASSET_RETURN_FAILURE",
  FETCH_REPORT_FIXED_ASSET_RETURN_SUCCESS:
    "FETCH_REPORT_FIXED_ASSET_RETURN_SUCCESS",
};
