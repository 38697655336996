import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PayableV2PaymentActionTypes } from "./PayableV2Payment.type";

export function* fetchAllPayableV2Payments(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/payable-v2-payment?payable_v2_id=${action.payload.payable_v2_id}`
    );
    yield put({
      type: PayableV2PaymentActionTypes.FETCH_ALL_PAYABLE_V2_PAYMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PayableV2PaymentActionTypes.FETCH_ALL_PAYABLE_V2_PAYMENT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePayableV2Payments(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/payable-v2-payment/${action.payload}`
    );
    yield put({
      type: PayableV2PaymentActionTypes.FETCH_ONE_PAYABLE_V2_PAYMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PayableV2PaymentActionTypes.FETCH_ONE_PAYABLE_V2_PAYMENT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPayableV2Payments() {
  yield takeLatest(
    PayableV2PaymentActionTypes.FETCH_ALL_PAYABLE_V2_PAYMENT,
    fetchAllPayableV2Payments
  );
}

export function* watcherFetchOnePayableV2Payments() {
  yield takeLatest(
    PayableV2PaymentActionTypes.FETCH_ONE_PAYABLE_V2_PAYMENT,
    fetchOnePayableV2Payments
  );
}
