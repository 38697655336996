import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { formatQuery } from "../Utils";
import { TemporaryDeliveryNoteActionTypes } from "./TemporaryDeliveryNote.type";

export function* fetchAllTemporaryDeliveryNote(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/temporary-delivery-note?${formatQuery(action)}`
    );
    yield put({
      type: TemporaryDeliveryNoteActionTypes.FETCH_ALL_TEMPORARY_DELIVERY_NOTE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TemporaryDeliveryNoteActionTypes.FETCH_ALL_TEMPORARY_DELIVERY_NOTE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchReportTemporaryDeliveryNote(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/temporary-delivery-note/report?${formatQuery(action)}`
    );
    yield put({
      type: TemporaryDeliveryNoteActionTypes.FETCH_REPORT_TEMPORARY_DELIVERY_NOTE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TemporaryDeliveryNoteActionTypes.FETCH_ALL_TEMPORARY_DELIVERY_NOTE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneTemporaryDeliveryNote(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/temporary-delivery-note/${action.payload}`
    );
    yield put({
      type: TemporaryDeliveryNoteActionTypes.FETCH_ONE_TEMPORARY_DELIVERY_NOTE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TemporaryDeliveryNoteActionTypes.FETCH_ONE_TEMPORARY_DELIVERY_NOTE_FAILURE,
      payload: error,
    });
  }
}



export function* watcherFetchAllTemporaryDeliveryNote() {
  yield takeLatest(TemporaryDeliveryNoteActionTypes.FETCH_ALL_TEMPORARY_DELIVERY_NOTE, fetchAllTemporaryDeliveryNote);
}

export function* watcherFetchReportTemporaryDeliveryNote() {
  yield takeLatest(
    TemporaryDeliveryNoteActionTypes.FETCH_REPORT_TEMPORARY_DELIVERY_NOTE,
    fetchReportTemporaryDeliveryNote
  );
}

export function* watcherFetchOneTemporaryDeliveryNote() {
  yield takeLatest(
    TemporaryDeliveryNoteActionTypes.FETCH_ONE_TEMPORARY_DELIVERY_NOTE,
    fetchOneTemporaryDeliveryNote
  );
}
