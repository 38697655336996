import { ApiCallState } from "../Utils";

export type PayableV2Payment = {
  id?: number;
  date: any;
  amount: number;
  payable_v2_id: number;
  key: number;
};

export type PayableV2PaymentStateTypes = {
  fetchAll: ApiCallState<PayableV2Payment[]>;
  fetchOne: ApiCallState<PayableV2Payment | {}>;
};

export const PayableV2PaymentActionTypes = {
  FETCH_ALL_PAYABLE_V2_PAYMENT: "FETCH_ALL_PAYABLE_V2_PAYMENT",
  FETCH_ALL_PAYABLE_V2_PAYMENT_RESET: "FETCH_ALL_PAYABLE_V2_PAYMENT_RESET",
  FETCH_ALL_PAYABLE_V2_PAYMENT_FAILURE: "FETCH_ALL_PAYABLE_V2_PAYMENT_FAILURE",
  FETCH_ALL_PAYABLE_V2_PAYMENT_SUCCESS: "FETCH_ALL_PAYABLE_V2_PAYMENT_SUCCESS",

  FETCH_ONE_PAYABLE_V2_PAYMENT: "FETCH_ONE_PAYABLE_V2_PAYMENT",
  FETCH_ONE_PAYABLE_V2_PAYMENT_RESET: "FETCH_ONE_PAYABLE_V2_PAYMENT_RESET",
  FETCH_ONE_PAYABLE_V2_PAYMENT_FAILURE: "FETCH_ONE_PAYABLE_V2_PAYMENT_FAILURE",
  FETCH_ONE_PAYABLE_V2_PAYMENT_SUCCESS: "FETCH_ONE_PAYABLE_V2_PAYMENT_SUCCESS",
};
