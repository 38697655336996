import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ManufacturingOrderActionTypes } from "./ManufacturingOrder.type";
import { formatQuery } from "../Utils";

export function* fetchAllManufacturingOrders(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/manufacturing-order?${formatQuery(action)}`
    );
    yield put({
      type: ManufacturingOrderActionTypes.FETCH_ALL_MANUFACTURING_ORDER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ManufacturingOrderActionTypes.FETCH_ALL_MANUFACTURING_ORDER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchReportManufacturingOrders(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/manufacturing-order/report?${formatQuery(action)}`
    );
    yield put({
      type: ManufacturingOrderActionTypes.FETCH_REPORT_MANUFACTURING_ORDER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ManufacturingOrderActionTypes.FETCH_REPORT_MANUFACTURING_ORDER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedManufacturingOrders(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/manufacturing-order?${formatQuery(action)}`
    );
    yield put({
      type: ManufacturingOrderActionTypes.FETCH_PAGED_MANUFACTURING_ORDER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ManufacturingOrderActionTypes.FETCH_PAGED_MANUFACTURING_ORDER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneManufacturingOrders(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/manufacturing-order/${action.payload}`
    );
    yield put({
      type: ManufacturingOrderActionTypes.FETCH_ONE_MANUFACTURING_ORDER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ManufacturingOrderActionTypes.FETCH_ONE_MANUFACTURING_ORDER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllManufacturingOrders() {
  yield takeLatest(
    ManufacturingOrderActionTypes.FETCH_ALL_MANUFACTURING_ORDER,
    fetchAllManufacturingOrders
  );
}

export function* watcherFetchReportManufacturingOrders() {
  yield takeLatest(
    ManufacturingOrderActionTypes.FETCH_REPORT_MANUFACTURING_ORDER,
    fetchReportManufacturingOrders
  );
}

export function* watcherFetchPagedManufacturingOrders() {
  yield takeLatest(
    ManufacturingOrderActionTypes.FETCH_PAGED_MANUFACTURING_ORDER,
    fetchPagedManufacturingOrders
  );
}

export function* watcherFetchOneManufacturingOrders() {
  yield takeLatest(
    ManufacturingOrderActionTypes.FETCH_ONE_MANUFACTURING_ORDER,
    fetchOneManufacturingOrders
  );
}
