import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SubContractActionTypes } from "./SubContract.type";

export function* fetchAllSubContracts(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/project/sub_contract?project_id=${action.payload?.project_id}&type=${action.payload?.type}`
    );
    yield put({
      type: SubContractActionTypes.FETCH_ALL_SUB_CONTRACT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubContractActionTypes.FETCH_ALL_SUB_CONTRACT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSubContracts(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/project/sub_contract/${action.payload}`
    );
    yield put({
      type: SubContractActionTypes.FETCH_ONE_SUB_CONTRACT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubContractActionTypes.FETCH_ONE_SUB_CONTRACT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSubContracts() {
  yield takeLatest(
    SubContractActionTypes.FETCH_ALL_SUB_CONTRACT,
    fetchAllSubContracts
  );
}

export function* watcherFetchOneSubContracts() {
  yield takeLatest(
    SubContractActionTypes.FETCH_ONE_SUB_CONTRACT,
    fetchOneSubContracts
  );
}
