import { FuelRequestVoucher } from "../FuelRequestVoucher/FuelRequestVoucher.type";
import { ApiCallState } from "../Utils";

export type FuelRecievingVoucher= {
  id: number;
  date: string;
  plate_no: string;
  coupon_no: string;
  reciever_name: string;
  issued_by_id: number;
  approved_by_id: number;
  is_approved: boolean;
  fuel_recieving_voucher_items: FuelRecievingVoucherItems[],
  fuel_request_voucher: FuelRequestVoucher
};

export type FuelRecievingVoucherItems = {
    id?:number,
    description: string,
    unit: string,
    quantity: number,
    unit_price: number
}

export type FuelRecievingVoucherStateTypes = {
  fetchAll: ApiCallState<FuelRecievingVoucher[]>;
  fetchOne: ApiCallState<FuelRecievingVoucher | {}>;
};

export const FuelRecievingVoucherActionTypes = {
  FETCH_ALL_FUEL_RECIEVING_VOUCHER: "FETCH_ALL_FUEL_RECIEVING_VOUCHER",
  FETCH_ALL_FUEL_RECIEVING_VOUCHER_RESET: "FETCH_ALL_FUEL_RECIEVING_VOUCHER_RESET",
  FETCH_ALL_FUEL_RECIEVING_VOUCHER_FAILURE: "FETCH_ALL_FUEL_RECIEVING_VOUCHER_FAILURE",
  FETCH_ALL_FUEL_RECIEVING_VOUCHER_SUCCESS: "FETCH_ALL_FUEL_RECIEVING_VOUCHER_SUCCESS",

  FETCH_ONE_FUEL_RECIEVING_VOUCHER: "FETCH_ONE_FUEL_RECIEVING_VOUCHER",
  FETCH_ONE_FUEL_RECIEVING_VOUCHER_RESET: "FETCH_ONE_FUEL_RECIEVING_VOUCHER_RESET",
  FETCH_ONE_FUEL_RECIEVING_VOUCHER_FAILURE: "FETCH_ONE_FUEL_RECIEVING_VOUCHER_FAILURE",
  FETCH_ONE_FUEL_RECIEVING_VOUCHER_SUCCESS: "FETCH_ONE_FUEL_RECIEVING_VOUCHER_SUCCESS",
};
