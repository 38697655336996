import { ApiCallState, PagedData } from "../Utils";

export type OperationMonthlyReport = {
  id?: number;
  key: number;
  project_id: number;
  own_vehicle_id: number;
  date: string;
  remark: string;
};

export type OperationMonthlyReportStateTypes = {
  fetchAll: ApiCallState<OperationMonthlyReport[]>;
  fetchOne: ApiCallState<OperationMonthlyReport | {}>;
  fetchPaged: ApiCallState<PagedData<OperationMonthlyReport[]>>;
};

export const OperationMonthlyReportActionTypes = {
  FETCH_ALL_OPERATION_MONTHLY_REPORT: "FETCH_ALL_OPERATION_MONTHLY_REPORT",
  FETCH_ALL_OPERATION_MONTHLY_REPORT_RESET:
    "FETCH_ALL_OPERATION_MONTHLY_REPORT_RESET",
  FETCH_ALL_OPERATION_MONTHLY_REPORT_FAILURE:
    "FETCH_ALL_OPERATION_MONTHLY_REPORT_FAILURE",
  FETCH_ALL_OPERATION_MONTHLY_REPORT_SUCCESS:
    "FETCH_ALL_OPERATION_MONTHLY_REPORT_SUCCESS",

  FETCH_PAGED_OPERATION_MONTHLY_REPORT: "FETCH_PAGED_OPERATION_MONTHLY_REPORT",
  FETCH_PAGED_OPERATION_MONTHLY_REPORT_RESET:
    "FETCH_PAGED_OPERATION_MONTHLY_REPORT_RESET",
  FETCH_PAGED_OPERATION_MONTHLY_REPORT_FAILURE:
    "FETCH_PAGED_OPERATION_MONTHLY_REPORT_FAILURE",
  FETCH_PAGED_OPERATION_MONTHLY_REPORT_SUCCESS:
    "FETCH_PAGED_OPERATION_MONTHLY_REPORT_SUCCESS",

  FETCH_ONE_OPERATION_MONTHLY_REPORT: "FETCH_ONE_OPERATION_MONTHLY_REPORT",
  FETCH_ONE_OPERATION_MONTHLY_REPORT_RESET:
    "FETCH_ONE_OPERATION_MONTHLY_REPORT_RESET",
  FETCH_ONE_OPERATION_MONTHLY_REPORT_FAILURE:
    "FETCH_ONE_OPERATION_MONTHLY_REPORT_FAILURE",
  FETCH_ONE_OPERATION_MONTHLY_REPORT_SUCCESS:
    "FETCH_ONE_OPERATION_MONTHLY_REPORT_SUCCESS",
};
