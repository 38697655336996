import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { BoqItemGroupActionTypes } from "./BoqItemGroup.type";

export function* fetchAllBoqItemGroups(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/boq-item-group?${query}`);
    yield put({
      type: BoqItemGroupActionTypes.FETCH_ALL_BOQ_ITEM_GROUP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BoqItemGroupActionTypes.FETCH_ALL_BOQ_ITEM_GROUP_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneBoqItemGroups(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/boq-item-group/${action.payload}`
    );
    yield put({
      type: BoqItemGroupActionTypes.FETCH_ONE_BOQ_ITEM_GROUP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BoqItemGroupActionTypes.FETCH_ONE_BOQ_ITEM_GROUP_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllBoqItemGroups() {
  yield takeLatest(
    BoqItemGroupActionTypes.FETCH_ALL_BOQ_ITEM_GROUP,
    fetchAllBoqItemGroups
  );
}

export function* watcherFetchOneBoqItemGroups() {
  yield takeLatest(
    BoqItemGroupActionTypes.FETCH_ONE_BOQ_ITEM_GROUP,
    fetchOneBoqItemGroups
  );
}
