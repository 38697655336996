import { Assessment } from "../Assessment/Assessment.type";
import { ApiCallState } from "../Utils";
import { WorkExperienceSummary } from "../WorkExperienceSummary/WorkExperienceSummary.type";

export type ApplicantRegistryItem = {
    id: number;
    applicant_registry_id:number;
    name: string;
    field: string;
    education_level: string;
    experience: string;
    related_experience:string;
    phone: string;
    work_experience_summary:WorkExperienceSummary;
    assessments?: Assessment[]
  };

export type ApplicantRegistryItemStateTypes = {
  fetchAll: ApiCallState<ApplicantRegistryItem[]>;
  fetchOne: ApiCallState<ApplicantRegistryItem | {}>;
};

export const ApplicantRegistryItemActionTypes = {
  FETCH_ALL_APPLICANT_REGISTRY_ITEM: "FETCH_ALL_APPLICANT_REGISTRY_ITEM",
  FETCH_ALL_APPLICANT_REGISTRY_ITEM_RESET: "FETCH_ALL_APPLICANT_REGISTRY_ITEM_RESET",
  FETCH_ALL_APPLICANT_REGISTRY_ITEM_FAILURE: "FETCH_ALL_APPLICANT_REGISTRY_ITEM_FAILURE",
  FETCH_ALL_APPLICANT_REGISTRY_ITEM_SUCCESS: "FETCH_ALL_APPLICANT_REGISTRY_ITEM_SUCCESS",

  FETCH_ONE_APPLICANT_REGISTRY_ITEM: "FETCH_ONE_APPLICANT_REGISTRY_ITEM",
  FETCH_ONE_APPLICANT_REGISTRY_ITEM_RESET: "FETCH_ONE_APPLICANT_REGISTRY_ITEM_RESET",
  FETCH_ONE_APPLICANT_REGISTRY_ITEM_FAILURE: "FETCH_ONE_APPLICANT_REGISTRY_ITEM_FAILURE",
  FETCH_ONE_APPLICANT_REGISTRY_ITEM_SUCCESS: "FETCH_ONE_APPLICANT_REGISTRY_ITEM_SUCCESS",
};
