import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type Bonus = {
  id: number;
  user_id: number;

  date: string;
  bonus_amount: number;
  reason: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  bonus_items: BonusItem[];
};

export type BonusItem = {
  id: number;
  bonus_id: number;
  staff_id: number;

  no_working_month: number;
  active_project: number;
  paused_project: number;
  salary_factor: number;
  experience_factor: number;
  family_factor: number;

  gross_salary: number;

  bonus_amount: number;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  staff: Staff;
};

export type BonusStateTypes = {
  fetchAll: ApiCallState<Bonus[]>;
};

export const BonusActionTypes = {
  FETCH_ALL_BONUS: "FETCH_ALL_BONUS",
  FETCH_ALL_BONUS_RESET: "FETCH_ALL_BONUS_RESET",
  FETCH_ALL_BONUS_FAILURE: "FETCH_ALL_BONUS_FAILURE",
  FETCH_ALL_BONUS_SUCCESS: "FETCH_ALL_BONUS_SUCCESS",
};
