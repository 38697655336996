import { MasterList } from "../MasterList/MasterList.type";
import { ApiCallState } from "../Utils";

export type FuelAndLubiconRequest= {
  id: number;
  voucher_no:number;
  date:string;
  master_list_id:number;
  no_of_km_traveled:number;
  average_km_per_l:number;
  currently_traveling_to:string;
  km_distance:number;
  fuel_quantity:number;
  back_up_quantity:number;
  requested_by:number;
  checked_by: number;
  approved_by: number;
  master_list:MasterList;
  fuel_and_lubicon_request_items:FuelAndLubiconRequestItem[];
  fuel_and_lubicon_request_statuses:FuelAndLubiconRequestStatus[];
  user_id:number;
};

export type FuelAndLubiconRequestItem= {
    id: number;
    fuel_and_lubicon_request_id:number;
    is_title:boolean;
    description:string;
    type:string;
    unit:string;
    requested_quantity:number;
    filled_quantity:number; 
    amount:number;
    remark:string;
}

export type FuelAndLubiconRequestStatus = {
    fuel_and_lubicon_request_id:number;
    type: "View" | "Check" | "Approve";
    status: number;
    assigned_by: number;
    user_id: number;
    id: number;
  };

export type FuelAndLubiconRequestStateTypes = {
  fetchAll: ApiCallState<FuelAndLubiconRequest[]>;
  fetchOne: ApiCallState<FuelAndLubiconRequest | {}>;
};

export const FuelAndLubiconRequestActionTypes = {
  FETCH_ALL_FUEL_AND_LUBICON_REQUEST: "FETCH_ALL_FUEL_AND_LUBICON_REQUEST",
  FETCH_ALL_FUEL_AND_LUBICON_REQUEST_RESET: "FETCH_ALL_FUEL_AND_LUBICON_REQUEST_RESET",
  FETCH_ALL_FUEL_AND_LUBICON_REQUEST_FAILURE: "FETCH_ALL_FUEL_AND_LUBICON_REQUEST_FAILURE",
  FETCH_ALL_FUEL_AND_LUBICON_REQUEST_SUCCESS: "FETCH_ALL_FUEL_AND_LUBICON_REQUEST_SUCCESS",

  FETCH_ONE_FUEL_AND_LUBICON_REQUEST: "FETCH_ONE_FUEL_AND_LUBICON_REQUEST",
  FETCH_ONE_FUEL_AND_LUBICON_REQUEST_RESET: "FETCH_ONE_FUEL_AND_LUBICON_REQUEST_RESET",
  FETCH_ONE_FUEL_AND_LUBICON_REQUEST_FAILURE: "FETCH_ONE_FUEL_AND_LUBICON_REQUEST_FAILURE",
  FETCH_ONE_FUEL_AND_LUBICON_REQUEST_SUCCESS: "FETCH_ONE_FUEL_AND_LUBICON_REQUEST_SUCCESS",
};
