import { OwnVehicle } from "../OwnVehicle/OwnVehicle.type";
import { Project } from "../Project/Project.type";
import { ApiCallState } from "../Utils";

export type MaterialDeliveryItem = {
  id: number;
  date: string;
  received_quantity: number;
  received_date: string;
  received_grn_no: string;
  project_id: number;
  issue_quantity: number;
  issue_date: string;
  ordered_quantity: number;
  ordered_date: string;
  ordered_coupon_number: string;
  transported_by: string;
  supplier_name: string;
  description: string;
  unit_rate: number;
  remark: string;
  own_vehicle_id: number;
  material_delivery_id: number;
  project: Project;
  own_vehicle: OwnVehicle;
};

export type MaterialDeliveryMaterial = {
  id: number;
  name: string;
  unit: string;
};

export type MaterialDeliveryStateTypes = {
  fetchAll: ApiCallState<MaterialDeliveryItem[]>;
  fetchMaterial: ApiCallState<MaterialDeliveryMaterial[]>;
};

export const MaterialDeliveryActionTypes = {
  FETCH_ALL_MATERIAL_DELIVERY: "FETCH_ALL_MATERIAL_DELIVERY",
  FETCH_ALL_MATERIAL_DELIVERY_RESET: "FETCH_ALL_MATERIAL_DELIVERY_RESET",
  FETCH_ALL_MATERIAL_DELIVERY_FAILURE: "FETCH_ALL_MATERIAL_DELIVERY_FAILURE",
  FETCH_ALL_MATERIAL_DELIVERY_SUCCESS: "FETCH_ALL_MATERIAL_DELIVERY_SUCCESS",

  FETCH_MATERIAL_DELIVERY: "FETCH_MATERIAL_DELIVERY",
  FETCH_MATERIAL_DELIVERY_RESET: "FETCH_MATERIAL_DELIVERY_RESET",
  FETCH_MATERIAL_DELIVERY_FAILURE: "FETCH_MATERIAL_DELIVERY_FAILURE",
  FETCH_MATERIAL_DELIVERY_SUCCESS: "FETCH_MATERIAL_DELIVERY_SUCCESS",
};
