import { InitPagedData } from "./../Utils";
import { MaterialStateTypes, MaterialActionTypes } from "./Material.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: MaterialStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchAllForm: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchPaged: resetApiCallState(InitPagedData),
  fetchNumber: resetApiCallState(null),
};

const MaterialReducer = (
  state: MaterialStateTypes = INITIAL_STATE,
  action: any
): MaterialStateTypes => {
  switch (action.type) {
    case MaterialActionTypes.FETCH_ALL_MATERIAL:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaterialActionTypes.FETCH_ALL_MATERIAL_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MaterialActionTypes.FETCH_ALL_MATERIAL_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaterialActionTypes.FETCH_ALL_MATERIAL_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    // ---------------------------------

    case MaterialActionTypes.FETCH_ALL_FORM_MATERIAL:
      return {
        ...state,
        fetchAllForm: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaterialActionTypes.FETCH_ALL_FORM_MATERIAL_RESET:
      return {
        ...state,
        fetchAllForm: resetApiCallState([]),
      };
    case MaterialActionTypes.FETCH_ALL_FORM_MATERIAL_FAILURE:
      return {
        ...state,
        fetchAllForm: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaterialActionTypes.FETCH_ALL_FORM_MATERIAL_SUCCESS:
      return {
        ...state,
        fetchAllForm: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    // ---------------------------------

    case MaterialActionTypes.FETCH_PAGED_MATERIAL:
      return {
        ...state,
        fetchPaged: {
          error: null,
          payload: InitPagedData,
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaterialActionTypes.FETCH_PAGED_MATERIAL_RESET:
      return {
        ...state,
        fetchPaged: resetApiCallState(InitPagedData),
      };
    case MaterialActionTypes.FETCH_PAGED_MATERIAL_FAILURE:
      return {
        ...state,
        fetchPaged: {
          payload: InitPagedData,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaterialActionTypes.FETCH_PAGED_MATERIAL_SUCCESS:
      return {
        ...state,
        fetchPaged: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    // ---------------------------------

    case MaterialActionTypes.FETCH_ONE_MATERIAL:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaterialActionTypes.FETCH_ONE_MATERIAL_RESET:
      return {
        ...state,
        fetchOne: resetApiCallState({}),
      };
    case MaterialActionTypes.FETCH_ONE_MATERIAL_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaterialActionTypes.FETCH_ONE_MATERIAL_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    // ---------------------------------

    case MaterialActionTypes.FETCH_MATERIAL_NUMBER:
      return {
        ...state,
        fetchNumber: {
          error: null,
          payload: null,
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaterialActionTypes.FETCH_MATERIAL_NUMBER_RESET:
      return {
        ...state,
        fetchNumber: resetApiCallState(null),
      };
    case MaterialActionTypes.FETCH_MATERIAL_NUMBER_FAILURE:
      return {
        ...state,
        fetchNumber: {
          payload: null,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaterialActionTypes.FETCH_MATERIAL_NUMBER_SUCCESS:
      return {
        ...state,
        fetchNumber: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default MaterialReducer;
