import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PlanExecutionActionTypes } from "./PlanExecution.type";

export function* fetchAllPlanExecutions(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/daily-plan-execution`);
    yield put({
      type: PlanExecutionActionTypes.FETCH_ALL_PLAN_EXECUTION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PlanExecutionActionTypes.FETCH_ALL_PLAN_EXECUTION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePlanExecutions(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/daily-plan-execution/date?project_id=${action.payload?.project_id}&date=${action.payload?.date}`
    );
    yield put({
      type: PlanExecutionActionTypes.FETCH_ONE_PLAN_EXECUTION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PlanExecutionActionTypes.FETCH_ONE_PLAN_EXECUTION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPlanExecutions() {
  yield takeLatest(PlanExecutionActionTypes.FETCH_ALL_PLAN_EXECUTION, fetchAllPlanExecutions);
}

export function* watcherFetchOnePlanExecutions() {
  yield takeLatest(PlanExecutionActionTypes.FETCH_ONE_PLAN_EXECUTION, fetchOnePlanExecutions);
}
