import { Document } from "../Document/Document.type";
import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type Resignation = {
  id: number;
  staff_id: number;
  document_id: number;

  date: string;
  remark: string;

  staff: Staff;
  document: Document;
};

export type ResignationStateTypes = {
  fetchAll: ApiCallState<Resignation[]>;
  fetchOne: ApiCallState<Resignation | {}>;
};

export const ResignationActionTypes = {
  FETCH_ALL_RESIGNATION: "FETCH_ALL_RESIGNATION",
  FETCH_ALL_RESIGNATION_RESET: "FETCH_ALL_RESIGNATION_RESET",
  FETCH_ALL_RESIGNATION_FAILURE: "FETCH_ALL_RESIGNATION_FAILURE",
  FETCH_ALL_RESIGNATION_SUCCESS: "FETCH_ALL_RESIGNATION_SUCCESS",

  FETCH_ONE_RESIGNATION: "FETCH_ONE_RESIGNATION",
  FETCH_ONE_RESIGNATION_RESET: "FETCH_ONE_RESIGNATION_RESET",
  FETCH_ONE_RESIGNATION_FAILURE: "FETCH_ONE_RESIGNATION_FAILURE",
  FETCH_ONE_RESIGNATION_SUCCESS: "FETCH_ONE_RESIGNATION_SUCCESS",
};
