import { ApiCallState } from "../Utils";

export type ProjectType = {
  id: number;
  name: string;
};

export type ProjectTypeStateTypes = {
  fetchAll: ApiCallState<ProjectType[]>;
  fetchOne: ApiCallState<ProjectType | {}>;
};

export const ProjectTypeActionTypes = {
  FETCH_ALL_PROJECT_TYPE: "FETCH_ALL_PROJECT_TYPE",
  FETCH_ALL_PROJECT_TYPE_RESET: "FETCH_ALL_PROJECT_TYPE_RESET",
  FETCH_ALL_PROJECT_TYPE_FAILURE: "FETCH_ALL_PROJECT_TYPE_FAILURE",
  FETCH_ALL_PROJECT_TYPE_SUCCESS: "FETCH_ALL_PROJECT_TYPE_SUCCESS",

  FETCH_ONE_PROJECT_TYPE: "FETCH_ONE_PROJECT_TYPE",
  FETCH_ONE_PROJECT_TYPE_RESET: "FETCH_ONE_PROJECT_TYPE_RESET",
  FETCH_ONE_PROJECT_TYPE_FAILURE: "FETCH_ONE_PROJECT_TYPE_FAILURE",
  FETCH_ONE_PROJECT_TYPE_SUCCESS: "FETCH_ONE_PROJECT_TYPE_SUCCESS",
};
