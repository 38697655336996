import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MasterListActionTypes } from "./MasterList.type";

export function* fetchAllMasterLists(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      query = Object.keys(action.payload)
        .map((key) => `${key}=${action.payload[key]}`)
        .join("&");
    }

    const response = yield axios.get(`${API_BASE_URI}/master-list?${query}`);
    yield put({
      type: MasterListActionTypes.FETCH_ALL_MASTER_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MasterListActionTypes.FETCH_ALL_MASTER_LIST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMasterLists(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/master-list/${action.payload}`
    );
    yield put({
      type: MasterListActionTypes.FETCH_ONE_MASTER_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MasterListActionTypes.FETCH_ONE_MASTER_LIST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMasterLists() {
  yield takeLatest(
    MasterListActionTypes.FETCH_ALL_MASTER_LIST,
    fetchAllMasterLists
  );
}

export function* watcherFetchOneMasterLists() {
  yield takeLatest(
    MasterListActionTypes.FETCH_ONE_MASTER_LIST,
    fetchOneMasterLists
  );
}
