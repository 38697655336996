import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";
import { API_BASE_URI } from "../ApiCall";
import { SharedDocumentActionTypes } from "./SharedDocument.type";

export function* fetchAllSharedDocuments(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/shared_document/documents`
    );
    yield put({
      type: SharedDocumentActionTypes.FETCH_ALL_SHARED_DOCUMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SharedDocumentActionTypes.FETCH_ALL_SHARED_DOCUMENT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchAllSharedDocumentUsers(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/shared_document/${action.payload}/users`
    );
    yield put({
      type: SharedDocumentActionTypes.FETCH_ALL_SHARED_USER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SharedDocumentActionTypes.FETCH_ALL_SHARED_USER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSharedDocuments() {
  yield takeLatest(
    SharedDocumentActionTypes.FETCH_ALL_SHARED_DOCUMENT,
    fetchAllSharedDocuments
  );
}

export function* watcherFetchAllSharedDocumentUsers() {
  yield takeLatest(
    SharedDocumentActionTypes.FETCH_ALL_SHARED_USER,
    fetchAllSharedDocumentUsers
  );
}
