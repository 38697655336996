import axios from "axios";
import { API_BASE_URI } from "../../../redux/ApiCall";
import { User } from "../../../redux/User/User.type";
import { ApiCallState } from "../../../redux/Utils";

export type UserComponentPropType = {
  fetchUser: Function;
  user: ApiCallState<User>;
  form: any;
  setIsModalVisible: Function;
  setLoading: Function;
  loading: boolean;
};

export type CredentialPropType = {
  fetchUser: Function;
  loadingAction: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  user: ApiCallState<User>;
  form: any;
};

export const sendPasswordData = (data: any) =>
  axios.put(`${API_BASE_URI}/user/change-password`, data);

export const sendUserOnlyData = (data: any) =>
  axios.put(`${API_BASE_URI}/user`, data);

export const sendUserSignature = (data: any) =>
  axios.put(`${API_BASE_URI}/user/signature`, data, {
    headers: {
      "Content-type": "multipart/form-data",
    },
  });

export const removeData = (id: any) =>
  axios.delete(`${API_BASE_URI}/signature/${id}`);

const getRandomChar = (charSet: string): string => {
  return charSet.charAt(Math.floor(Math.random() * charSet.length));
};

export const RandomPasswordGenerator = () => {
  let password = "";
  const passwordLength = 10; 
  const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
  const uppercaseChars = lowercaseChars.toUpperCase();
  const numbers = "0123456789";
  const symbols = "!@#$%^&*()_+-=[]{};':\"\\|,.<>/?";

   // Include at least one character from each set
   password += getRandomChar(lowercaseChars);
   password += getRandomChar(uppercaseChars);
   password += getRandomChar(numbers);
   password += getRandomChar(symbols);
 
   // Fill remaining characters with any combination
   for (let i = password.length; i < passwordLength; i++) {
     const charSet = `${lowercaseChars}${uppercaseChars}${numbers}${symbols}`;
     password += getRandomChar(charSet);
   }
 
   // Shuffle the characters for better randomness
   return password.split("").sort(() => Math.random() - 0.5).join("");
};