import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MikadaAnnualReportActionTypes } from "./MikadaAnnualReport.type";

export function* fetchAllMikadaAnnualReports(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/mikada-annual-report?date=${action.payload?.date}`);
    yield put({
      type: MikadaAnnualReportActionTypes.FETCH_ALL_MIKADA_ANNUAL_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MikadaAnnualReportActionTypes.FETCH_ALL_MIKADA_ANNUAL_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMikadaAnnualReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/mikada-annual-report/${action.payload}`
    );
    yield put({
      type: MikadaAnnualReportActionTypes.FETCH_ONE_MIKADA_ANNUAL_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MikadaAnnualReportActionTypes.FETCH_ONE_MIKADA_ANNUAL_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMikadaAnnualReports() {
  yield takeLatest(MikadaAnnualReportActionTypes.FETCH_ALL_MIKADA_ANNUAL_REPORT, fetchAllMikadaAnnualReports);
}

export function* watcherFetchOneMikadaAnnualReports() {
  yield takeLatest(MikadaAnnualReportActionTypes.FETCH_ONE_MIKADA_ANNUAL_REPORT, fetchOneMikadaAnnualReports);
}
