import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ProbationReviewActionTypes } from "./ProbationReview.type";

export function* fetchAllProbationReviews(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/probation-review`);
    yield put({
      type: ProbationReviewActionTypes.FETCH_ALL_PROBATION_REVIEW_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProbationReviewActionTypes.FETCH_ALL_PROBATION_REVIEW_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneProbationReviews(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/probation-review/${action.payload}`
    );
    yield put({
      type: ProbationReviewActionTypes.FETCH_ONE_PROBATION_REVIEW_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProbationReviewActionTypes.FETCH_ONE_PROBATION_REVIEW_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllProbationReviews() {
  yield takeLatest(ProbationReviewActionTypes.FETCH_ALL_PROBATION_REVIEW, fetchAllProbationReviews);
}

export function* watcherFetchOneProbationReviews() {
  yield takeLatest(ProbationReviewActionTypes.FETCH_ONE_PROBATION_REVIEW, fetchOneProbationReviews);
}
