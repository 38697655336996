import {
  MonthlySiteReportStateTypes,
  MonthlySiteReportActionTypes,
} from "./MonthlySiteReport.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: MonthlySiteReportStateTypes = {
  fetchSurveyingEquipmentCostReport: resetApiCallState({}),
  fetchLaboratoryEquipmentCostReport: resetApiCallState({}),
  fetchConsultantFacilityCostReport: resetApiCallState([]),
  fetchContractorFacilityCostReport: resetApiCallState([]),
  fetchEquipmentCostReport: resetApiCallState([]),
  fetchOtherExpenseReport: resetApiCallState([]),
  fetchSummaryOfCost: resetApiCallState([]),
  fetchDetailReportOfActivity: resetApiCallState([]),
  fetchTotalProjectExpenseReport: resetApiCallState([]),
  fetchIncomeExpenseReport: resetApiCallState([]),
  fetchTotalProjectIncomeReport: resetApiCallState({}),
  fetchProgressBillSummary: resetApiCallState([]),
  fetchCriticalIssueReport: resetApiCallState([]),
};

const MonthlySiteReportReducer = (
  state: MonthlySiteReportStateTypes = INITIAL_STATE,
  action: any
): MonthlySiteReportStateTypes => {
  switch (action.type) {
    case MonthlySiteReportActionTypes.FETCH_SURVEYING_EQUIPMENT_COST_REPORT:
      return {
        ...state,
        fetchSurveyingEquipmentCostReport: {
          error: null,
          payload: { items: [], other: [] },
          isPending: true,
          isSuccessful: false,
        },
      };
    case MonthlySiteReportActionTypes.FETCH_SURVEYING_EQUIPMENT_COST_REPORT_RESET:
      return {
        ...state,
        fetchSurveyingEquipmentCostReport: resetApiCallState([]),
      };
    case MonthlySiteReportActionTypes.FETCH_SURVEYING_EQUIPMENT_COST_REPORT_FAILURE:
      return {
        ...state,
        fetchSurveyingEquipmentCostReport: {
          payload: { items: [], other: [] },
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MonthlySiteReportActionTypes.FETCH_SURVEYING_EQUIPMENT_COST_REPORT_SUCCESS:
      return {
        ...state,
        fetchSurveyingEquipmentCostReport: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MonthlySiteReportActionTypes.FETCH_LABORATORY_EQUIPMENT_COST_REPORT:
      return {
        ...state,
        fetchLaboratoryEquipmentCostReport: {
          error: null,
          payload: { items: [], other: [] },
          isPending: true,
          isSuccessful: false,
        },
      };
    case MonthlySiteReportActionTypes.FETCH_LABORATORY_EQUIPMENT_COST_REPORT_RESET:
      return {
        ...state,
        fetchLaboratoryEquipmentCostReport: resetApiCallState([]),
      };
    case MonthlySiteReportActionTypes.FETCH_LABORATORY_EQUIPMENT_COST_REPORT_FAILURE:
      return {
        ...state,
        fetchLaboratoryEquipmentCostReport: {
          payload: { items: [], other: [] },
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MonthlySiteReportActionTypes.FETCH_LABORATORY_EQUIPMENT_COST_REPORT_SUCCESS:
      return {
        ...state,
        fetchLaboratoryEquipmentCostReport: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MonthlySiteReportActionTypes.FETCH_CONSULTANT_FACILITY_COST_REPORT:
      return {
        ...state,
        fetchConsultantFacilityCostReport: {
          error: null,
          payload: {
            consultant_accomodations: [],
            consultant_camp_facilities: [],
            consultant_kitchen_tool_equipments: [],
            consultant_office_furnitures: [],
            consultant_office_supplies_facilitys: [],
            consultant_other_facility_for_engineers: [],
            consultant_temporary_office_rents: [],
          },
          isPending: true,
          isSuccessful: false,
        },
      };
    case MonthlySiteReportActionTypes.FETCH_CONSULTANT_FACILITY_COST_REPORT_RESET:
      return {
        ...state,
        fetchConsultantFacilityCostReport: resetApiCallState([]),
      };
    case MonthlySiteReportActionTypes.FETCH_CONSULTANT_FACILITY_COST_REPORT_FAILURE:
      return {
        ...state,
        fetchConsultantFacilityCostReport: {
          payload: {
            consultant_accomodations: [],
            consultant_camp_facilities: [],
            consultant_kitchen_tool_equipments: [],
            consultant_office_furnitures: [],
            consultant_office_supplies_facilitys: [],
            consultant_other_facility_for_engineers: [],
            consultant_temporary_office_rents: [],
          },
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MonthlySiteReportActionTypes.FETCH_CONSULTANT_FACILITY_COST_REPORT_SUCCESS:
      return {
        ...state,
        fetchConsultantFacilityCostReport: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MonthlySiteReportActionTypes.FETCH_CONTRACTOR_FACILITY_COST_REPORT:
      return {
        ...state,
        fetchContractorFacilityCostReport: {
          error: null,
          payload: {
            contractor_camp_facilities: [],
            contractor_kitchen_tool_equipments: [],
            contractor_mobile_card_allowances: [],
            contractor_office_furnitures: [],
            contractor_office_supplies_facilities: [],
            contractor_other_facilities: [],
            contractor_temporary_office_rents: [],
          },
          isPending: true,
          isSuccessful: false,
        },
      };
    case MonthlySiteReportActionTypes.FETCH_CONTRACTOR_FACILITY_COST_REPORT_RESET:
      return {
        ...state,
        fetchContractorFacilityCostReport: resetApiCallState([]),
      };
    case MonthlySiteReportActionTypes.FETCH_CONTRACTOR_FACILITY_COST_REPORT_FAILURE:
      return {
        ...state,
        fetchContractorFacilityCostReport: {
          payload: {
            contractor_camp_facilities: [],
            contractor_kitchen_tool_equipments: [],
            contractor_mobile_card_allowances: [],
            contractor_office_furnitures: [],
            contractor_office_supplies_facilities: [],
            contractor_other_facilities: [],
            contractor_temporary_office_rents: [],
          },
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MonthlySiteReportActionTypes.FETCH_CONTRACTOR_FACILITY_COST_REPORT_SUCCESS:
      return {
        ...state,
        fetchContractorFacilityCostReport: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MonthlySiteReportActionTypes.FETCH_EQUIPMENT_COST_REPORT:
      return {
        ...state,
        fetchEquipmentCostReport: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MonthlySiteReportActionTypes.FETCH_EQUIPMENT_COST_REPORT_RESET:
      return {
        ...state,
        fetchEquipmentCostReport: resetApiCallState([]),
      };
    case MonthlySiteReportActionTypes.FETCH_EQUIPMENT_COST_REPORT_FAILURE:
      return {
        ...state,
        fetchEquipmentCostReport: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MonthlySiteReportActionTypes.FETCH_EQUIPMENT_COST_REPORT_SUCCESS:
      return {
        ...state,
        fetchEquipmentCostReport: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MonthlySiteReportActionTypes.FETCH_OTHER_EXPENSE_REPORT:
      return {
        ...state,
        fetchOtherExpenseReport: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MonthlySiteReportActionTypes.FETCH_OTHER_EXPENSE_REPORT_RESET:
      return {
        ...state,
        fetchOtherExpenseReport: resetApiCallState([]),
      };
    case MonthlySiteReportActionTypes.FETCH_OTHER_EXPENSE_REPORT_FAILURE:
      return {
        ...state,
        fetchOtherExpenseReport: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MonthlySiteReportActionTypes.FETCH_OTHER_EXPENSE_REPORT_SUCCESS:
      return {
        ...state,
        fetchOtherExpenseReport: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MonthlySiteReportActionTypes.FETCH_SUMMARY_OF_COST:
      return {
        ...state,
        fetchSummaryOfCost: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MonthlySiteReportActionTypes.FETCH_SUMMARY_OF_COST_RESET:
      return {
        ...state,
        fetchSummaryOfCost: resetApiCallState([]),
      };
    case MonthlySiteReportActionTypes.FETCH_SUMMARY_OF_COST_FAILURE:
      return {
        ...state,
        fetchSummaryOfCost: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MonthlySiteReportActionTypes.FETCH_SUMMARY_OF_COST_SUCCESS:
      return {
        ...state,
        fetchSummaryOfCost: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MonthlySiteReportActionTypes.FETCH_DETAIL_REPORT_OF_ACTIVITY:
      return {
        ...state,
        fetchDetailReportOfActivity: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MonthlySiteReportActionTypes.FETCH_DETAIL_REPORT_OF_ACTIVITY_RESET:
      return {
        ...state,
        fetchDetailReportOfActivity: resetApiCallState([]),
      };
    case MonthlySiteReportActionTypes.FETCH_DETAIL_REPORT_OF_ACTIVITY_FAILURE:
      return {
        ...state,
        fetchDetailReportOfActivity: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MonthlySiteReportActionTypes.FETCH_DETAIL_REPORT_OF_ACTIVITY_SUCCESS:
      return {
        ...state,
        fetchDetailReportOfActivity: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MonthlySiteReportActionTypes.FETCH_TOTAL_PROJECT_EXPENSE_REPORT:
      return {
        ...state,
        fetchTotalProjectExpenseReport: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MonthlySiteReportActionTypes.FETCH_TOTAL_PROJECT_EXPENSE_REPORT_RESET:
      return {
        ...state,
        fetchTotalProjectExpenseReport: resetApiCallState([]),
      };
    case MonthlySiteReportActionTypes.FETCH_TOTAL_PROJECT_EXPENSE_REPORT_FAILURE:
      return {
        ...state,
        fetchTotalProjectExpenseReport: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MonthlySiteReportActionTypes.FETCH_TOTAL_PROJECT_EXPENSE_REPORT_SUCCESS:
      return {
        ...state,
        fetchTotalProjectExpenseReport: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MonthlySiteReportActionTypes.FETCH_MONTHLY_INCOME_EXPENES_REPORT:
      return {
        ...state,
        fetchIncomeExpenseReport: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MonthlySiteReportActionTypes.FETCH_MONTHLY_INCOME_EXPENES_REPORT_RESET:
      return {
        ...state,
        fetchIncomeExpenseReport: resetApiCallState([]),
      };
    case MonthlySiteReportActionTypes.FETCH_MONTHLY_INCOME_EXPENES_REPORT_FAILURE:
      return {
        ...state,
        fetchIncomeExpenseReport: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MonthlySiteReportActionTypes.FETCH_MONTHLY_INCOME_EXPENES_REPORT_SUCCESS:
      return {
        ...state,
        fetchIncomeExpenseReport: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MonthlySiteReportActionTypes.FETCH_TOTAL_PROJECT_INCOME_REPORT:
      return {
        ...state,
        fetchTotalProjectIncomeReport: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MonthlySiteReportActionTypes.FETCH_TOTAL_PROJECT_INCOME_REPORT_RESET:
      return {
        ...state,
        fetchTotalProjectIncomeReport: resetApiCallState([]),
      };
    case MonthlySiteReportActionTypes.FETCH_TOTAL_PROJECT_INCOME_REPORT_FAILURE:
      return {
        ...state,
        fetchTotalProjectIncomeReport: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MonthlySiteReportActionTypes.FETCH_TOTAL_PROJECT_INCOME_REPORT_SUCCESS:
      return {
        ...state,
        fetchTotalProjectIncomeReport: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MonthlySiteReportActionTypes.FETCH_PROGRESS_BILL_SUMMARY:
      return {
        ...state,
        fetchProgressBillSummary: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MonthlySiteReportActionTypes.FETCH_PROGRESS_BILL_SUMMARY_RESET:
      return {
        ...state,
        fetchProgressBillSummary: resetApiCallState([]),
      };
    case MonthlySiteReportActionTypes.FETCH_PROGRESS_BILL_SUMMARY_FAILURE:
      return {
        ...state,
        fetchProgressBillSummary: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MonthlySiteReportActionTypes.FETCH_PROGRESS_BILL_SUMMARY_SUCCESS:
      return {
        ...state,
        fetchProgressBillSummary: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MonthlySiteReportActionTypes.FETCH_CRITICAL_ISSUE_REPORT:
      return {
        ...state,
        fetchCriticalIssueReport: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MonthlySiteReportActionTypes.FETCH_CRITICAL_ISSUE_REPORT_RESET:
      return {
        ...state,
        fetchCriticalIssueReport: resetApiCallState([]),
      };
    case MonthlySiteReportActionTypes.FETCH_CRITICAL_ISSUE_REPORT_FAILURE:
      return {
        ...state,
        fetchCriticalIssueReport: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MonthlySiteReportActionTypes.FETCH_CRITICAL_ISSUE_REPORT_SUCCESS:
      return {
        ...state,
        fetchCriticalIssueReport: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default MonthlySiteReportReducer;
