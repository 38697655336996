import { TrainingStateTypes, TrainingActionTypes } from "./Training.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: TrainingStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const TrainingReducer = (
  state: TrainingStateTypes = INITIAL_STATE,
  action: any
): TrainingStateTypes => {
  switch (action.type) {
    case TrainingActionTypes.FETCH_ALL_TRAINING:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case TrainingActionTypes.FETCH_ALL_TRAINING_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case TrainingActionTypes.FETCH_ALL_TRAINING_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case TrainingActionTypes.FETCH_ALL_TRAINING_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case TrainingActionTypes.FETCH_ONE_TRAINING:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case TrainingActionTypes.FETCH_ONE_TRAINING_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case TrainingActionTypes.FETCH_ONE_TRAINING_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case TrainingActionTypes.FETCH_ONE_TRAINING_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default TrainingReducer;
