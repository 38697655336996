import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { AllowanceActionTypes } from "./Allowance.type";

export function* fetchAllAllowances(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query =
        "?" + keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }

    const response = yield axios.get(`${API_BASE_URI}/allowance${query}`);
    yield put({
      type: AllowanceActionTypes.FETCH_ALL_ALLOWANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AllowanceActionTypes.FETCH_ALL_ALLOWANCE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneAllowances(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/allowance/${action.payload}`
    );
    yield put({
      type: AllowanceActionTypes.FETCH_ONE_ALLOWANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AllowanceActionTypes.FETCH_ONE_ALLOWANCE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllAllowances() {
  yield takeLatest(
    AllowanceActionTypes.FETCH_ALL_ALLOWANCE,
    fetchAllAllowances
  );
}

export function* watcherFetchOneAllowances() {
  yield takeLatest(
    AllowanceActionTypes.FETCH_ONE_ALLOWANCE,
    fetchOneAllowances
  );
}
