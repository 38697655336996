import { WorkEvaluationStateTypes, WorkEvaluationActionTypes } from "./WorkEvaluation.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: WorkEvaluationStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const WorkEvaluationReducer = (
  state: WorkEvaluationStateTypes = INITIAL_STATE,
  action: any
): WorkEvaluationStateTypes => {
  switch (action.type) {
    case WorkEvaluationActionTypes.FETCH_ALL_WORK_EVALUATION:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case WorkEvaluationActionTypes.FETCH_ALL_WORK_EVALUATION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case WorkEvaluationActionTypes.FETCH_ALL_WORK_EVALUATION_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case WorkEvaluationActionTypes.FETCH_ALL_WORK_EVALUATION_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case WorkEvaluationActionTypes.FETCH_ONE_WORK_EVALUATION:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case WorkEvaluationActionTypes.FETCH_ONE_WORK_EVALUATION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case WorkEvaluationActionTypes.FETCH_ONE_WORK_EVALUATION_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case WorkEvaluationActionTypes.FETCH_ONE_WORK_EVALUATION_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default WorkEvaluationReducer;
