import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { EarlyLeaveRequestActionTypes } from "./EarlyLeaveRequest.type";

export function* fetchAllEarlyLeaveRequests(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/early-leave-request`);
    yield put({
      type: EarlyLeaveRequestActionTypes.FETCH_ALL_EARLY_LEAVE_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EarlyLeaveRequestActionTypes.FETCH_ALL_EARLY_LEAVE_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneEarlyLeaveRequests(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/early-leave-request/${action.payload}`
    );
    yield put({
      type: EarlyLeaveRequestActionTypes.FETCH_ONE_EARLY_LEAVE_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EarlyLeaveRequestActionTypes.FETCH_ONE_EARLY_LEAVE_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllEarlyLeaveRequests() {
  yield takeLatest(
    EarlyLeaveRequestActionTypes.FETCH_ALL_EARLY_LEAVE_REQUEST,
    fetchAllEarlyLeaveRequests
  );
}

export function* watcherFetchOneEarlyLeaveRequests() {
  yield takeLatest(
    EarlyLeaveRequestActionTypes.FETCH_ONE_EARLY_LEAVE_REQUEST,
    fetchOneEarlyLeaveRequests
  );
}
