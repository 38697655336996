import { ExternalRepairOrderStateTypes, ExternalRepairOrderActionTypes } from "./ExternalRepairOrder.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ExternalRepairOrderStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const ExternalRepairOrderReducer = (
  state: ExternalRepairOrderStateTypes = INITIAL_STATE,
  action: any
): ExternalRepairOrderStateTypes => {
  switch (action.type) {
    case ExternalRepairOrderActionTypes.FETCH_ALL_EXTERNAL_REPAIR_ORDER:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ExternalRepairOrderActionTypes.FETCH_ALL_EXTERNAL_REPAIR_ORDER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ExternalRepairOrderActionTypes.FETCH_ALL_EXTERNAL_REPAIR_ORDER_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ExternalRepairOrderActionTypes.FETCH_ALL_EXTERNAL_REPAIR_ORDER_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ExternalRepairOrderActionTypes.FETCH_ONE_EXTERNAL_REPAIR_ORDER:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ExternalRepairOrderActionTypes.FETCH_ONE_EXTERNAL_REPAIR_ORDER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ExternalRepairOrderActionTypes.FETCH_ONE_EXTERNAL_REPAIR_ORDER_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ExternalRepairOrderActionTypes.FETCH_ONE_EXTERNAL_REPAIR_ORDER_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ExternalRepairOrderReducer;
