import axios from "axios";
import { isNil } from "lodash";
import moment from "moment";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ProjectActionTypes } from "./Project.type";

export function* fetchAllProjects(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/project?previous_progress_month=${!isNil(action.payload?.progress_month) ? moment(action.payload?.progress_month).subtract(1, "month").format("YYYY-MM") : undefined}&progress_month=${action.payload?.progress_month}&schedule_date=${action.payload?.schedule_date}&report_date=${action.payload?.report_date}&report_start_date=${action.payload?.report_start_date}&report_end_date=${action.payload?.report_end_date}`
    );
    yield put({
      type: ProjectActionTypes.FETCH_ALL_PROJECT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectActionTypes.FETCH_ALL_PROJECT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchAllProjectReport(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/project/report?date=${action.payload?.date}`
    );
    yield put({
      type: ProjectActionTypes.FETCH_ALL_PROJECT_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectActionTypes.FETCH_ALL_PROJECT_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchAllProjectsDetail(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/project/detail`);
    yield put({
      type: ProjectActionTypes.FETCH_ALL_PROJECT_DETIAL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectActionTypes.FETCH_ALL_PROJECT_DETIAL_FAILURE,
      payload: error,
    });
  }
}

export function* fetchAllListProjects(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/project/list`);
    yield put({
      type: ProjectActionTypes.FETCH_ALL_PROJECT_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectActionTypes.FETCH_ALL_PROJECT_LIST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneProjects(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/project/${action.payload}`
    );
    yield put({
      type: ProjectActionTypes.FETCH_ONE_PROJECT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectActionTypes.FETCH_ONE_PROJECT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchAllPreProjects(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/project/pre_contract`);
    yield put({
      type: ProjectActionTypes.FETCH_ALL_PRE_PROJECT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectActionTypes.FETCH_ALL_PRE_PROJECT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePreProjects(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/project/${action.payload}`
    );
    yield put({
      type: ProjectActionTypes.FETCH_ONE_PRE_PROJECT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectActionTypes.FETCH_ONE_PRE_PROJECT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchAllProjectDailyReport(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/project/daily_report?start_date=${action.payload?.start_date}&end_date=${action.payload?.end_date}&date=${action.payload?.date}`
    );
    yield put({
      type: ProjectActionTypes.FETCH_ALL_PROJECT_DAILY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectActionTypes.FETCH_ALL_PROJECT_DAILY_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllProjects() {
  yield takeLatest(ProjectActionTypes.FETCH_ALL_PROJECT, fetchAllProjects);
}

export function* watcherFetchAllProjectsDetail() {
  yield takeLatest(
    ProjectActionTypes.FETCH_ALL_PROJECT_DETIAL,
    fetchAllProjectsDetail
  );
}

export function* watcherFetchAllListProjects() {
  yield takeLatest(
    ProjectActionTypes.FETCH_ALL_PROJECT_LIST,
    fetchAllListProjects
  );
}

export function* watcherFetchOneProjects() {
  yield takeLatest(ProjectActionTypes.FETCH_ONE_PROJECT, fetchOneProjects);
}

export function* watcherFetchAllPreProjects() {
  yield takeLatest(
    ProjectActionTypes.FETCH_ALL_PRE_PROJECT,
    fetchAllPreProjects
  );
}

export function* watcherFetchOnePreProjects() {
  yield takeLatest(
    ProjectActionTypes.FETCH_ONE_PRE_PROJECT,
    fetchOnePreProjects
  );


  
};

export function* watcherFetchAllProjectReport() {
  yield takeLatest(
    ProjectActionTypes.FETCH_ALL_PROJECT_REPORT,
    fetchAllProjectReport
  );


  
}

export function* watcherFetchAllProjectDailyReport() {
  yield takeLatest(
    ProjectActionTypes.FETCH_ALL_PROJECT_DAILY_REPORT,
    fetchAllProjectDailyReport
  );


  
}
