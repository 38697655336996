import { ApiCallState } from "../Utils";

export type MikadaMonthlyReport = {
  id: number;
  date: string;
  remark: string;
  project_id: number;
  user_id: number;
};

export type MikadaMonthlyReportStateTypes = {
  fetchAll: ApiCallState<MikadaMonthlyReport[]>;
  fetchOne: ApiCallState<MikadaMonthlyReport | {}>;
};

export const MikadaMonthlyReportActionTypes = {
  FETCH_ALL_MIKADA_MONTHLY_REPORT: "FETCH_ALL_MIKADA_MONTHLY_REPORT",
  FETCH_ALL_MIKADA_MONTHLY_REPORT_RESET:
    "FETCH_ALL_MIKADA_MONTHLY_REPORT_RESET",
  FETCH_ALL_MIKADA_MONTHLY_REPORT_FAILURE:
    "FETCH_ALL_MIKADA_MONTHLY_REPORT_FAILURE",
  FETCH_ALL_MIKADA_MONTHLY_REPORT_SUCCESS:
    "FETCH_ALL_MIKADA_MONTHLY_REPORT_SUCCESS",

  FETCH_ONE_MIKADA_MONTHLY_REPORT: "FETCH_ONE_MIKADA_MONTHLY_REPORT",
  FETCH_ONE_MIKADA_MONTHLY_REPORT_RESET:
    "FETCH_ONE_MIKADA_MONTHLY_REPORT_RESET",
  FETCH_ONE_MIKADA_MONTHLY_REPORT_FAILURE:
    "FETCH_ONE_MIKADA_MONTHLY_REPORT_FAILURE",
  FETCH_ONE_MIKADA_MONTHLY_REPORT_SUCCESS:
    "FETCH_ONE_MIKADA_MONTHLY_REPORT_SUCCESS",
};
