import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MaterialMajorCategoryActionTypes } from "./MaterialMajorCategory.type";

export function* fetchAllMaterialMajorCategorys(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/material-major-category?${query}`
    );
    yield put({
      type: MaterialMajorCategoryActionTypes.FETCH_ALL_MATERIAL_MAJOR_CATEGORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialMajorCategoryActionTypes.FETCH_ALL_MATERIAL_MAJOR_CATEGORY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchAllFormMaterialMajorCategorys(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/material-major-category?${query}`
    );
    yield put({
      type: MaterialMajorCategoryActionTypes.FETCH_ALL_FORM_MATERIAL_MAJOR_CATEGORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialMajorCategoryActionTypes.FETCH_ALL_FORM_MATERIAL_MAJOR_CATEGORY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedMaterialMajorCategorys(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/material-major-category?${query}`
    );
    yield put({
      type: MaterialMajorCategoryActionTypes.FETCH_PAGED_MATERIAL_MAJOR_CATEGORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialMajorCategoryActionTypes.FETCH_PAGED_MATERIAL_MAJOR_CATEGORY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMaterialMajorCategorys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/material-major-category/${action.payload}`
    );
    yield put({
      type: MaterialMajorCategoryActionTypes.FETCH_ONE_MATERIAL_MAJOR_CATEGORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialMajorCategoryActionTypes.FETCH_ONE_MATERIAL_MAJOR_CATEGORY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMaterialMajorCategorys() {
  yield takeLatest(
    MaterialMajorCategoryActionTypes.FETCH_ALL_MATERIAL_MAJOR_CATEGORY,
    fetchAllMaterialMajorCategorys
  );
}

export function* watcherFetchAllFormMaterialMajorCategorys() {
  yield takeLatest(
    MaterialMajorCategoryActionTypes.FETCH_ALL_FORM_MATERIAL_MAJOR_CATEGORY,
    fetchAllFormMaterialMajorCategorys
  );
}

export function* watcherFetchPagedMaterialMajorCategorys() {
  yield takeLatest(
    MaterialMajorCategoryActionTypes.FETCH_PAGED_MATERIAL_MAJOR_CATEGORY,
    fetchPagedMaterialMajorCategorys
  );
}

export function* watcherFetchOneMaterialMajorCategorys() {
  yield takeLatest(
    MaterialMajorCategoryActionTypes.FETCH_ONE_MATERIAL_MAJOR_CATEGORY,
    fetchOneMaterialMajorCategorys
  );
}
