import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MikadaWeeklyLabourReportActionTypes } from "./MikadaWeeklyLabourReport.type";

export function* fetchAllMikadaWeeklyLabourReports(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/mikada-weekly-labour-report?date=${action.payload?.date}`);
    yield put({
      type: MikadaWeeklyLabourReportActionTypes.FETCH_ALL_MIKADA_WEEKLY_LABOUR_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MikadaWeeklyLabourReportActionTypes.FETCH_ALL_MIKADA_WEEKLY_LABOUR_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMikadaWeeklyLabourReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/mikada-weekly-labour-report/${action.payload}`
    );
    yield put({
      type: MikadaWeeklyLabourReportActionTypes.FETCH_ONE_MIKADA_WEEKLY_LABOUR_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MikadaWeeklyLabourReportActionTypes.FETCH_ONE_MIKADA_WEEKLY_LABOUR_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMikadaWeeklyLabourReports() {
  yield takeLatest(MikadaWeeklyLabourReportActionTypes.FETCH_ALL_MIKADA_WEEKLY_LABOUR_REPORT, fetchAllMikadaWeeklyLabourReports);
}

export function* watcherFetchOneMikadaWeeklyLabourReports() {
  yield takeLatest(MikadaWeeklyLabourReportActionTypes.FETCH_ONE_MIKADA_WEEKLY_LABOUR_REPORT, fetchOneMikadaWeeklyLabourReports);
}
