import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { EmployeeReviewActionTypes } from "./EmployeeReview.type";

export function* fetchAllEmployeeReviews(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query =
        "?" + keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }

    const response = yield axios.get(`${API_BASE_URI}/employee-review${query}`);
    yield put({
      type: EmployeeReviewActionTypes.FETCH_ALL_EMPLOYEE_REVIEW_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EmployeeReviewActionTypes.FETCH_ALL_EMPLOYEE_REVIEW_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneEmployeeReviews(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/employee-review/${action.payload}`
    );
    yield put({
      type: EmployeeReviewActionTypes.FETCH_ONE_EMPLOYEE_REVIEW_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EmployeeReviewActionTypes.FETCH_ONE_EMPLOYEE_REVIEW_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllEmployeeReviews() {
  yield takeLatest(
    EmployeeReviewActionTypes.FETCH_ALL_EMPLOYEE_REVIEW,
    fetchAllEmployeeReviews
  );
}

export function* watcherFetchOneEmployeeReviews() {
  yield takeLatest(
    EmployeeReviewActionTypes.FETCH_ONE_EMPLOYEE_REVIEW,
    fetchOneEmployeeReviews
  );
}
