import {
  EarlyLeaveRequestStateTypes,
  EarlyLeaveRequestActionTypes,
} from "./EarlyLeaveRequest.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: EarlyLeaveRequestStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const EarlyLeaveRequestReducer = (
  state: EarlyLeaveRequestStateTypes = INITIAL_STATE,
  action: any
): EarlyLeaveRequestStateTypes => {
  switch (action.type) {
    case EarlyLeaveRequestActionTypes.FETCH_ALL_EARLY_LEAVE_REQUEST:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case EarlyLeaveRequestActionTypes.FETCH_ALL_EARLY_LEAVE_REQUEST_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case EarlyLeaveRequestActionTypes.FETCH_ALL_EARLY_LEAVE_REQUEST_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case EarlyLeaveRequestActionTypes.FETCH_ALL_EARLY_LEAVE_REQUEST_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case EarlyLeaveRequestActionTypes.FETCH_ONE_EARLY_LEAVE_REQUEST:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case EarlyLeaveRequestActionTypes.FETCH_ONE_EARLY_LEAVE_REQUEST_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState({}),
      };
    case EarlyLeaveRequestActionTypes.FETCH_ONE_EARLY_LEAVE_REQUEST_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case EarlyLeaveRequestActionTypes.FETCH_ONE_EARLY_LEAVE_REQUEST_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default EarlyLeaveRequestReducer;
