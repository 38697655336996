import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PeridiumRulesActionTypes } from "./PeridiumRules.type";

export function* fetchAllPeridiumRuless(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/peridium-rules`);
    yield put({
      type: PeridiumRulesActionTypes.FETCH_ALL_PERIDIUM_RULES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PeridiumRulesActionTypes.FETCH_ALL_PERIDIUM_RULES_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePeridiumRuless(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/peridium-rules/${action.payload}`
    );
    yield put({
      type: PeridiumRulesActionTypes.FETCH_ONE_PERIDIUM_RULES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PeridiumRulesActionTypes.FETCH_ONE_PERIDIUM_RULES_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPeridiumRuless() {
  yield takeLatest(PeridiumRulesActionTypes.FETCH_ALL_PERIDIUM_RULES, fetchAllPeridiumRuless);
}

export function* watcherFetchOnePeridiumRuless() {
  yield takeLatest(PeridiumRulesActionTypes.FETCH_ONE_PERIDIUM_RULES, fetchOnePeridiumRuless);
}
