import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { GoodInActionTypes } from "./GoodIn.type";
import { formatQuery } from "../Utils";

export function* fetchAllGoodIns(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/good-in?${formatQuery(action)}`
    );
    yield put({
      type: GoodInActionTypes.FETCH_ALL_GOOD_IN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GoodInActionTypes.FETCH_ALL_GOOD_IN_FAILURE,
      payload: error,
    });
  }
}

export function* fetchReportGoodIns(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/good-in/report?${formatQuery(action)}`
    );
    yield put({
      type: GoodInActionTypes.FETCH_REPORT_GOOD_IN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GoodInActionTypes.FETCH_REPORT_GOOD_IN_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedGoodIns(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/good-in?${query}`);
    yield put({
      type: GoodInActionTypes.FETCH_PAGED_GOOD_IN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GoodInActionTypes.FETCH_PAGED_GOOD_IN_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneGoodIns(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/good-in/${action.payload}`
    );
    yield put({
      type: GoodInActionTypes.FETCH_ONE_GOOD_IN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GoodInActionTypes.FETCH_ONE_GOOD_IN_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllGoodIns() {
  yield takeLatest(GoodInActionTypes.FETCH_ALL_GOOD_IN, fetchAllGoodIns);
}

export function* watcherFetchReportGoodIns() {
  yield takeLatest(GoodInActionTypes.FETCH_REPORT_GOOD_IN, fetchReportGoodIns);
}

export function* watcherFetchPagedGoodIns() {
  yield takeLatest(GoodInActionTypes.FETCH_PAGED_GOOD_IN, fetchPagedGoodIns);
}

export function* watcherFetchOneGoodIns() {
  yield takeLatest(GoodInActionTypes.FETCH_ONE_GOOD_IN, fetchOneGoodIns);
}
