import { ApiCallState } from "../Utils";

export type IllegalReceipt= {
  id: number;
  company_name:string;
  tin:string;
  machine_no:string;
  remark:string;
  user_id:number;
};

export type IllegalReceiptStateTypes = {
  fetchAll: ApiCallState<IllegalReceipt[]>;
  fetchOne: ApiCallState<IllegalReceipt | {}>;
};

export const IllegalReceiptActionTypes = {
  FETCH_ALL_ILLEGAL_RECEIPT: "FETCH_ALL_ILLEGAL_RECEIPT",
  FETCH_ALL_ILLEGAL_RECEIPT_RESET: "FETCH_ALL_ILLEGAL_RECEIPT_RESET",
  FETCH_ALL_ILLEGAL_RECEIPT_FAILURE: "FETCH_ALL_ILLEGAL_RECEIPT_FAILURE",
  FETCH_ALL_ILLEGAL_RECEIPT_SUCCESS: "FETCH_ALL_ILLEGAL_RECEIPT_SUCCESS",

  FETCH_ONE_ILLEGAL_RECEIPT: "FETCH_ONE_ILLEGAL_RECEIPT",
  FETCH_ONE_ILLEGAL_RECEIPT_RESET: "FETCH_ONE_ILLEGAL_RECEIPT_RESET",
  FETCH_ONE_ILLEGAL_RECEIPT_FAILURE: "FETCH_ONE_ILLEGAL_RECEIPT_FAILURE",
  FETCH_ONE_ILLEGAL_RECEIPT_SUCCESS: "FETCH_ONE_ILLEGAL_RECEIPT_SUCCESS",
};
