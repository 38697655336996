import { MaterialMajorCategory } from "../MaterialMajorCategory/MaterialMajorCategory.type";
import { ApiCallState, PagedData } from "../Utils";

export type MaterialSubCategory = {
  id?: number;
  major_category_id?: number;
  key: number;
  description: string;
  code: string;
  material_major_category?: MaterialMajorCategory;
  type: string;
};

export type MaterialSubCategoryStateTypes = {
  fetchAll: ApiCallState<MaterialSubCategory[]>;
  fetchAllForm: ApiCallState<MaterialSubCategory[]>;
  fetchOne: ApiCallState<MaterialSubCategory | {}>;
  fetchPaged: ApiCallState<PagedData<MaterialSubCategory[]>>;
};

export const MaterialSubCategoryActionTypes = {
  FETCH_ALL_MATERIAL_SUB_CATEGORY: "FETCH_ALL_MATERIAL_SUB_CATEGORY",
  FETCH_ALL_MATERIAL_SUB_CATEGORY_RESET:
    "FETCH_ALL_MATERIAL_SUB_CATEGORY_RESET",
  FETCH_ALL_MATERIAL_SUB_CATEGORY_FAILURE:
    "FETCH_ALL_MATERIAL_SUB_CATEGORY_FAILURE",
  FETCH_ALL_MATERIAL_SUB_CATEGORY_SUCCESS:
    "FETCH_ALL_MATERIAL_SUB_CATEGORY_SUCCESS",

  FETCH_ALL_FORM_MATERIAL_SUB_CATEGORY: "FETCH_ALL_FORM_MATERIAL_SUB_CATEGORY",
  FETCH_ALL_FORM_MATERIAL_SUB_CATEGORY_RESET:
    "FETCH_ALL_FORM_MATERIAL_SUB_CATEGORY_RESET",
  FETCH_ALL_FORM_MATERIAL_SUB_CATEGORY_FAILURE:
    "FETCH_ALL_FORM_MATERIAL_SUB_CATEGORY_FAILURE",
  FETCH_ALL_FORM_MATERIAL_SUB_CATEGORY_SUCCESS:
    "FETCH_ALL_FORM_MATERIAL_SUB_CATEGORY_SUCCESS",

  FETCH_PAGED_MATERIAL_SUB_CATEGORY: "FETCH_PAGED_MATERIAL_SUB_CATEGORY",
  FETCH_PAGED_MATERIAL_SUB_CATEGORY_RESET:
    "FETCH_PAGED_MATERIAL_SUB_CATEGORY_RESET",
  FETCH_PAGED_MATERIAL_SUB_CATEGORY_FAILURE:
    "FETCH_PAGED_MATERIAL_SUB_CATEGORY_FAILURE",
  FETCH_PAGED_MATERIAL_SUB_CATEGORY_SUCCESS:
    "FETCH_PAGED_MATERIAL_SUB_CATEGORY_SUCCESS",

  FETCH_ONE_MATERIAL_SUB_CATEGORY: "FETCH_ONE_MATERIAL_SUB_CATEGORY",
  FETCH_ONE_MATERIAL_SUB_CATEGORY_RESET:
    "FETCH_ONE_MATERIAL_SUB_CATEGORY_RESET",
  FETCH_ONE_MATERIAL_SUB_CATEGORY_FAILURE:
    "FETCH_ONE_MATERIAL_SUB_CATEGORY_FAILURE",
  FETCH_ONE_MATERIAL_SUB_CATEGORY_SUCCESS:
    "FETCH_ONE_MATERIAL_SUB_CATEGORY_SUCCESS",
};
