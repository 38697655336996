import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { DisciplineActionTypes } from "./Discipline.type";

export function* fetchAllDisciplines(action: any): any {
  try {
    let query = "";

    if (action?.payload && Object.keys(action.payload).length > 0) {
      query +=
        "?" +
        Object.keys(action.payload)
          .map((key) => `${key}=${action?.payload[key]}`)
          .join("&");
    }

    const response = yield axios.get(`${API_BASE_URI}/discipline${query}`);
    yield put({
      type: DisciplineActionTypes.FETCH_ALL_DISCIPLINE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DisciplineActionTypes.FETCH_ALL_DISCIPLINE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneDisciplines(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/discipline/${action.payload}`
    );
    yield put({
      type: DisciplineActionTypes.FETCH_ONE_DISCIPLINE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DisciplineActionTypes.FETCH_ONE_DISCIPLINE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllDisciplines() {
  yield takeLatest(
    DisciplineActionTypes.FETCH_ALL_DISCIPLINE,
    fetchAllDisciplines
  );
}

export function* watcherFetchOneDisciplines() {
  yield takeLatest(
    DisciplineActionTypes.FETCH_ONE_DISCIPLINE,
    fetchOneDisciplines
  );
}
