import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MaterialInspectionRepairActionTypes } from "./MaterialInspectionRepair.type";
import { formatQuery } from "../Utils";

export function* fetchAllMaterialInspectionRepairs(action: any): any {
  try {
    const query = formatQuery(action);

    const response = yield axios.get(
      `${API_BASE_URI}/material-inspection-repair?${query}`
    );
    yield put({
      type: MaterialInspectionRepairActionTypes.FETCH_ALL_MATERIAL_INSPECTION_REPAIR_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialInspectionRepairActionTypes.FETCH_ALL_MATERIAL_INSPECTION_REPAIR_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMaterialInspectionRepairs(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/material-inspection-repair/${action.payload}`
    );
    yield put({
      type: MaterialInspectionRepairActionTypes.FETCH_ONE_MATERIAL_INSPECTION_REPAIR_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialInspectionRepairActionTypes.FETCH_ONE_MATERIAL_INSPECTION_REPAIR_FAILURE,
      payload: error,
    });
  }
}

export function* fetchMaterialInspectionRepairNumber(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/material-inspection-repair/number`
    );
    yield put({
      type: MaterialInspectionRepairActionTypes.FETCH_MATERIAL_INSPECTION_REPAIR_NUMBER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialInspectionRepairActionTypes.FETCH_MATERIAL_INSPECTION_REPAIR_NUMBER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMaterialInspectionRepairs() {
  yield takeLatest(
    MaterialInspectionRepairActionTypes.FETCH_ALL_MATERIAL_INSPECTION_REPAIR,
    fetchAllMaterialInspectionRepairs
  );
}

export function* watcherFetchOneMaterialInspectionRepairs() {
  yield takeLatest(
    MaterialInspectionRepairActionTypes.FETCH_ONE_MATERIAL_INSPECTION_REPAIR,
    fetchOneMaterialInspectionRepairs
  );
}

export function* watcherFetchMaterialInspectionRepairNumber() {
  yield takeLatest(
    MaterialInspectionRepairActionTypes.FETCH_MATERIAL_INSPECTION_REPAIR_NUMBER,
    fetchMaterialInspectionRepairNumber
  );
}
