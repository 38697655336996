import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type StaffMeal = {
  id: number;

  user_id: number;
  staff_id: number;
  date: string;
  amount: number;

  staff: Staff;
};

export type StaffMealStateTypes = {
  fetchAll: ApiCallState<StaffMeal[]>;
  fetchOne: ApiCallState<StaffMeal | {}>;
};

export const StaffMealActionTypes = {
  FETCH_ALL_STAFF_MEAL: "FETCH_ALL_STAFF_MEAL",
  FETCH_ALL_STAFF_MEAL_RESET: "FETCH_ALL_STAFF_MEAL_RESET",
  FETCH_ALL_STAFF_MEAL_FAILURE: "FETCH_ALL_STAFF_MEAL_FAILURE",
  FETCH_ALL_STAFF_MEAL_SUCCESS: "FETCH_ALL_STAFF_MEAL_SUCCESS",

  FETCH_ONE_STAFF_MEAL: "FETCH_ONE_STAFF_MEAL",
  FETCH_ONE_STAFF_MEAL_RESET: "FETCH_ONE_STAFF_MEAL_RESET",
  FETCH_ONE_STAFF_MEAL_FAILURE: "FETCH_ONE_STAFF_MEAL_FAILURE",
  FETCH_ONE_STAFF_MEAL_SUCCESS: "FETCH_ONE_STAFF_MEAL_SUCCESS",
};
