import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type AutomaticMessage = {
  id: number;
  user_id: number;

  user_ids: number[];
  recurrence: string;
  staring_time: string;
  staring_date: string;
  status: string;

  message: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type AutomaticMessageStateTypes = {
  fetchAll: ApiCallState<AutomaticMessage[]>;
  fetchOne: ApiCallState<AutomaticMessage | {}>;
};

export const AutomaticMessageActionTypes = {
  FETCH_ALL_AUTOMATIC_MESSAGE: "FETCH_ALL_AUTOMATIC_MESSAGE",
  FETCH_ALL_AUTOMATIC_MESSAGE_RESET: "FETCH_ALL_AUTOMATIC_MESSAGE_RESET",
  FETCH_ALL_AUTOMATIC_MESSAGE_FAILURE: "FETCH_ALL_AUTOMATIC_MESSAGE_FAILURE",
  FETCH_ALL_AUTOMATIC_MESSAGE_SUCCESS: "FETCH_ALL_AUTOMATIC_MESSAGE_SUCCESS",

  FETCH_ONE_AUTOMATIC_MESSAGE: "FETCH_ONE_AUTOMATIC_MESSAGE",
  FETCH_ONE_AUTOMATIC_MESSAGE_RESET: "FETCH_ONE_AUTOMATIC_MESSAGE_RESET",
  FETCH_ONE_AUTOMATIC_MESSAGE_FAILURE: "FETCH_ONE_AUTOMATIC_MESSAGE_FAILURE",
  FETCH_ONE_AUTOMATIC_MESSAGE_SUCCESS: "FETCH_ONE_AUTOMATIC_MESSAGE_SUCCESS",
};
