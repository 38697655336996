import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { DailySiteReportActionTypes } from "./DailySiteReport.type";

export function* fetchAllDailySiteReports(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/daily-site-report?project_id=${action.payload?.project_id}`);
    yield put({
      type: DailySiteReportActionTypes.FETCH_ALL_DAILY_SITE_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DailySiteReportActionTypes.FETCH_ALL_DAILY_SITE_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneDailySiteReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/daily-site-report/${action.payload}`
    );
    yield put({
      type: DailySiteReportActionTypes.FETCH_ONE_DAILY_SITE_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DailySiteReportActionTypes.FETCH_ONE_DAILY_SITE_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllDailySiteReports() {
  yield takeLatest(DailySiteReportActionTypes.FETCH_ALL_DAILY_SITE_REPORT, fetchAllDailySiteReports);
}

export function* watcherFetchOneDailySiteReports() {
  yield takeLatest(DailySiteReportActionTypes.FETCH_ONE_DAILY_SITE_REPORT, fetchOneDailySiteReports);
}
