import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { BudgetPlanningItemActionTypes } from "./BudgetPlanningItem.type";

export function* fetchAllBudgetPlanningItems(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/budget-planning-item`);
    yield put({
      type: BudgetPlanningItemActionTypes.FETCH_ALL_BUDGET_PLANNING_ITEM_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BudgetPlanningItemActionTypes.FETCH_ALL_BUDGET_PLANNING_ITEM_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneBudgetPlanningItems(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/budget-planning-item/${action.payload}`
    );
    yield put({
      type: BudgetPlanningItemActionTypes.FETCH_ONE_BUDGET_PLANNING_ITEM_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BudgetPlanningItemActionTypes.FETCH_ONE_BUDGET_PLANNING_ITEM_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllBudgetPlanningItems() {
  yield takeLatest(BudgetPlanningItemActionTypes.FETCH_ALL_BUDGET_PLANNING_ITEM, fetchAllBudgetPlanningItems);
}

export function* watcherFetchOneBudgetPlanningItems() {
  yield takeLatest(BudgetPlanningItemActionTypes.FETCH_ONE_BUDGET_PLANNING_ITEM, fetchOneBudgetPlanningItems);
}
