import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { CheckPaymentVoucherActionTypes } from "./CheckPaymentVoucher.type";

export function* fetchAllCheckPaymentVouchers(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/check-payment-voucher`);
    yield put({
      type: CheckPaymentVoucherActionTypes.FETCH_ALL_CHECK_PAYMENT_VOUCHER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: CheckPaymentVoucherActionTypes.FETCH_ALL_CHECK_PAYMENT_VOUCHER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneCheckPaymentVouchers(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/check-payment-voucher/${action.payload}`
    );
    yield put({
      type: CheckPaymentVoucherActionTypes.FETCH_ONE_CHECK_PAYMENT_VOUCHER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: CheckPaymentVoucherActionTypes.FETCH_ONE_CHECK_PAYMENT_VOUCHER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllCheckPaymentVouchers() {
  yield takeLatest(CheckPaymentVoucherActionTypes.FETCH_ALL_CHECK_PAYMENT_VOUCHER, fetchAllCheckPaymentVouchers);
}

export function* watcherFetchOneCheckPaymentVouchers() {
  yield takeLatest(CheckPaymentVoucherActionTypes.FETCH_ONE_CHECK_PAYMENT_VOUCHER, fetchOneCheckPaymentVouchers);
}
