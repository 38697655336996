import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { StoreRequisitionActionTypes } from "./StoreRequisition.type";

export function* fetchAllStoreRequisitions(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/store-requisition`);
    yield put({
      type: StoreRequisitionActionTypes.FETCH_ALL_STORE_REQUISITION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StoreRequisitionActionTypes.FETCH_ALL_STORE_REQUISITION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneStoreRequisitions(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/store-requisition/${action.payload}`
    );
    yield put({
      type: StoreRequisitionActionTypes.FETCH_ONE_STORE_REQUISITION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StoreRequisitionActionTypes.FETCH_ONE_STORE_REQUISITION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllStoreRequisitions() {
  yield takeLatest(
    StoreRequisitionActionTypes.FETCH_ALL_STORE_REQUISITION,
    fetchAllStoreRequisitions
  );
}

export function* watcherFetchOneStoreRequisitions() {
  yield takeLatest(
    StoreRequisitionActionTypes.FETCH_ONE_STORE_REQUISITION,
    fetchOneStoreRequisitions
  );
}
