import { ApiCallState } from "../Utils";
import { Moment } from "moment";

export type CriticalIssue= {
    project_id: number | null;
    id: number | null;
    date: Moment;
    week:Moment;
    month:Moment;
    critical_issue_material_details:CriticalIssueMaterialDetail[];
    critical_issue_budget_details:CriticalIssueBudgetDetail[];
    critical_issue_equipment_details:CriticalIssueEquipmentDetail[];
    critical_issue_subcontract_details:CriticalIssueSubcontractDetail[];
    critical_issue_approval_details:CriticalIssueApprovalDetail[];
    critical_issue_consultant_details:CriticalIssueConsultantDetail[];
    critical_issue_other_details:CriticalIssueOtherDetail[];
    critical_issue_statuses: any
};

export type CriticalIssueMaterialDetail={
    critical_issue_id: number | null;
    id: number | null;
    description:string;
    unit:string;
    u_rate:number;
    quantity:number;
    amount:number;
    mr_number:string;
    requested_date:string;
    responsible_person:string;
    status:string;
    remark:string;
}

export type CriticalIssueBudgetDetail={
    critical_issue_id: number | null;
    id: number | null;
    description:string;
    budget_no:string;
    request_date:string;
    amount:number;
    responsible_person:number;
    status:string;
    remark:string;
}

export type CriticalIssueEquipmentDetail={
    critical_issue_id: number | null;
    id: number | null;
    description:string;
    unit:string;
    quantity:number;
    capacity:number;
    model:string;
    owned_rental:string;
    responsible_person:number;
    status:string;
    remark:string;
}

export type CriticalIssueSubcontractDetail={
    critical_issue_id: number | null;
    id: number | null;
    description:string;
    responsible_person:number;
    status:string;
    remark:string;
    
}

export type CriticalIssueApprovalDetail={
    critical_issue_id: number | null;
    id: number | null;
    description:string;
    responsible_person:number;
    status:string;
    remark:string;
    
}

export type CriticalIssueConsultantDetail={
    critical_issue_id: number | null;
    id: number | null;
    description:string;
    responsible_person:number;
    status:string;
    remark:string;
    
}

export type CriticalIssueOtherDetail={
    critical_issue_id: number | null;
    id: number | null;
    description:string;
    responsible_person:string;
    status:string;
    remark:string;
    
}

export type CriticalIssueStateTypes = {
  fetchAll: ApiCallState<CriticalIssue[]>;
  fetchOne: ApiCallState<CriticalIssue | {}>;
};

export type CriticalIssueActionType = {
    project_id: number;
    date: string;
};

export const CriticalIssueActionTypes = {
  FETCH_ALL_CRITICAL_ISSUE: "FETCH_ALL_CRITICAL_ISSUE",
  FETCH_ALL_CRITICAL_ISSUE_RESET: "FETCH_ALL_CRITICAL_ISSUE_RESET",
  FETCH_ALL_CRITICAL_ISSUE_FAILURE: "FETCH_ALL_CRITICAL_ISSUE_FAILURE",
  FETCH_ALL_CRITICAL_ISSUE_SUCCESS: "FETCH_ALL_CRITICAL_ISSUE_SUCCESS",

  FETCH_ONE_CRITICAL_ISSUE: "FETCH_ONE_CRITICAL_ISSUE",
  FETCH_ONE_CRITICAL_ISSUE_RESET: "FETCH_ONE_CRITICAL_ISSUE_RESET",
  FETCH_ONE_CRITICAL_ISSUE_FAILURE: "FETCH_ONE_CRITICAL_ISSUE_FAILURE",
  FETCH_ONE_CRITICAL_ISSUE_SUCCESS: "FETCH_ONE_CRITICAL_ISSUE_SUCCESS",
};
