import {
  MaintenanceRequestStateTypes,
  MaintenanceRequestActionTypes,
} from "./MaintenanceRequest.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: MaintenanceRequestStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const MaintenanceRequestReducer = (
  state: MaintenanceRequestStateTypes = INITIAL_STATE,
  action: any
): MaintenanceRequestStateTypes => {
  switch (action.type) {
    case MaintenanceRequestActionTypes.FETCH_ALL_MAINTENANCE_REQUEST:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaintenanceRequestActionTypes.FETCH_ALL_MAINTENANCE_REQUEST_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MaintenanceRequestActionTypes.FETCH_ALL_MAINTENANCE_REQUEST_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaintenanceRequestActionTypes.FETCH_ALL_MAINTENANCE_REQUEST_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MaintenanceRequestActionTypes.FETCH_ONE_MAINTENANCE_REQUEST:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaintenanceRequestActionTypes.FETCH_ONE_MAINTENANCE_REQUEST_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MaintenanceRequestActionTypes.FETCH_ONE_MAINTENANCE_REQUEST_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaintenanceRequestActionTypes.FETCH_ONE_MAINTENANCE_REQUEST_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default MaintenanceRequestReducer;
