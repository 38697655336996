import { FeedBackForm } from "../FeedBackForm/FeedBackForm.type";
import { ApiCallState } from "../Utils";

export type FeedBackResponse = {
  id: number;
  feed_back_form_id: number;

  token: string;
  name: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  feed_back_form: FeedBackForm;
  feed_back_response_items: FeedBackResponseItem[];
};

export type FeedBackResponseItem = {
  id: number;
  feed_back_response_id: number;
  feed_back_form_question_id: number;

  answer: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type FeedBackResponseStateTypes = {
  fetchAll: ApiCallState<FeedBackResponse[]>;
  fetchOne: ApiCallState<FeedBackResponse | {}>;
};

export const FeedBackResponseActionTypes = {
  FETCH_ALL_FEED_BACK_RESPONSE: "FETCH_ALL_FEED_BACK_RESPONSE",
  FETCH_ALL_FEED_BACK_RESPONSE_RESET: "FETCH_ALL_FEED_BACK_RESPONSE_RESET",
  FETCH_ALL_FEED_BACK_RESPONSE_FAILURE: "FETCH_ALL_FEED_BACK_RESPONSE_FAILURE",
  FETCH_ALL_FEED_BACK_RESPONSE_SUCCESS: "FETCH_ALL_FEED_BACK_RESPONSE_SUCCESS",

  FETCH_ONE_FEED_BACK_RESPONSE: "FETCH_ONE_FEED_BACK_RESPONSE",
  FETCH_ONE_FEED_BACK_RESPONSE_RESET: "FETCH_ONE_FEED_BACK_RESPONSE_RESET",
  FETCH_ONE_FEED_BACK_RESPONSE_FAILURE: "FETCH_ONE_FEED_BACK_RESPONSE_FAILURE",
  FETCH_ONE_FEED_BACK_RESPONSE_SUCCESS: "FETCH_ONE_FEED_BACK_RESPONSE_SUCCESS",
};
