import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { BiddingScheduleActionTypes } from "./BiddingSchedule.type";

export function* fetchAllBiddingSchedules(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/bidding-schedule`);
    yield put({
      type: BiddingScheduleActionTypes.FETCH_ALL_BIDDING_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BiddingScheduleActionTypes.FETCH_ALL_BIDDING_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneBiddingSchedules(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/bidding-schedule/${action.payload}`
    );
    yield put({
      type: BiddingScheduleActionTypes.FETCH_ONE_BIDDING_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BiddingScheduleActionTypes.FETCH_ONE_BIDDING_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllBiddingSchedules() {
  yield takeLatest(BiddingScheduleActionTypes.FETCH_ALL_BIDDING_SCHEDULE, fetchAllBiddingSchedules);
}

export function* watcherFetchOneBiddingSchedules() {
  yield takeLatest(BiddingScheduleActionTypes.FETCH_ONE_BIDDING_SCHEDULE, fetchOneBiddingSchedules);
}
