import { number } from "mathjs";
import { ApiCallState, PagedData } from "../Utils";

export type FuelRequest = {
  previous_km_reading: number;
  previous_loaded_fuel: number;
  km_per_liter: number;
  km_traveled: number;
  id?: number;
  date: Date;
  request_number: string;
  machine: string;
  fixed_asset_transfer_number: string;
  project: string;
  driver: string;
  plate_number: string;
  jm_reading: string;
  requested_fuel_quantity: number;
  filled_fuel_quantity: number;
  destination: string;
  is_tanker_full: boolean;
  approved_by: string;
  checked_by: string;
  requested_by: string;
  fuel_request_statuses: FuelRequestStatus[]
};

export type FuelRequestStatus = {
  id: number;
  user_id: number;
  fuel_receive_id: number;
  status: number;
  type: "View" | "Check" | "Approve";
  assigned_by: number;
  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type FuelRequestStateTypes = {
  fetchAll: ApiCallState<FuelRequest[]>;
  fetchOne: ApiCallState<FuelRequest | {}>;
  fetchPaged: ApiCallState<PagedData<FuelRequest[]>>;
};

export const FuelRequestActionTypes = {
  FETCH_ALL_FUEL_REQUEST: "FETCH_ALL_FUEL_REQUEST",
  FETCH_ALL_FUEL_REQUEST_RESET: "FETCH_ALL_FUEL_REQUEST_RESET",
  FETCH_ALL_FUEL_REQUEST_FAILURE: "FETCH_ALL_FUEL_REQUEST_FAILURE",
  FETCH_ALL_FUEL_REQUEST_SUCCESS: "FETCH_ALL_FUEL_REQUEST_SUCCESS",

  FETCH_PAGED_FUEL_REQUEST: "FETCH_PAGED_FUEL_REQUEST",
  FETCH_PAGED_FUEL_REQUEST_RESET: "FETCH_PAGED_FUEL_REQUEST_RESET",
  FETCH_PAGED_FUEL_REQUEST_FAILURE: "FETCH_PAGED_FUEL_REQUEST_FAILURE",
  FETCH_PAGED_FUEL_REQUEST_SUCCESS: "FETCH_PAGED_FUEL_REQUEST_SUCCESS",

  FETCH_ONE_FUEL_REQUEST: "FETCH_ONE_FUEL_REQUEST",
  FETCH_ONE_FUEL_REQUEST_RESET: "FETCH_ONE_FUEL_REQUEST_RESET",
  FETCH_ONE_FUEL_REQUEST_FAILURE: "FETCH_ONE_FUEL_REQUEST_FAILURE",
  FETCH_ONE_FUEL_REQUEST_SUCCESS: "FETCH_ONE_FUEL_REQUEST_SUCCESS",
};
