import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ArchiveActionTypes } from "./Archive.type";

export function* fetchAllArchives(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/archive`);
    yield put({
      type: ArchiveActionTypes.FETCH_ALL_ARCHIVE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ArchiveActionTypes.FETCH_ALL_ARCHIVE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneArchives(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/archive/${action.payload}`
    );
    yield put({
      type: ArchiveActionTypes.FETCH_ONE_ARCHIVE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ArchiveActionTypes.FETCH_ONE_ARCHIVE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllArchives() {
  yield takeLatest(ArchiveActionTypes.FETCH_ALL_ARCHIVE, fetchAllArchives);
}

export function* watcherFetchOneArchives() {
  yield takeLatest(ArchiveActionTypes.FETCH_ONE_ARCHIVE, fetchOneArchives);
}
