import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MaintenanceVoucherActionTypes } from "./MaintenanceVoucher.type";

export function* fetchAllMaintenanceVouchers(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/maintenance-voucher`);
    yield put({
      type: MaintenanceVoucherActionTypes.FETCH_ALL_MAINTENANCE_VOUCHER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaintenanceVoucherActionTypes.FETCH_ALL_MAINTENANCE_VOUCHER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMaintenanceVouchers(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/maintenance-voucher${action.payload}`
    );
    yield put({
      type: MaintenanceVoucherActionTypes.FETCH_ONE_MAINTENANCE_VOUCHER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaintenanceVoucherActionTypes.FETCH_ONE_MAINTENANCE_VOUCHER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchMaintenanceVoucherNumber(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/maintenance-voucher/number`
    );
    yield put({
      type: MaintenanceVoucherActionTypes.FETCH_MAINTENANCE_VOUCHER_NUMBER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaintenanceVoucherActionTypes.FETCH_MAINTENANCE_VOUCHER_NUMBER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMaintenanceVouchers() {
  yield takeLatest(
    MaintenanceVoucherActionTypes.FETCH_ALL_MAINTENANCE_VOUCHER,
    fetchAllMaintenanceVouchers
  );
}

export function* watcherFetchOneMaintenanceVouchers() {
  yield takeLatest(
    MaintenanceVoucherActionTypes.FETCH_ONE_MAINTENANCE_VOUCHER,
    fetchOneMaintenanceVouchers
  );
}

export function* watcherFetchMaintenanceVoucherNumber() {
  yield takeLatest(
    MaintenanceVoucherActionTypes.FETCH_MAINTENANCE_VOUCHER_NUMBER,
    fetchMaintenanceVoucherNumber
  );
}
