import { ApiCallState } from "../Utils";

export type ProblemEncountered = {
  id: number;
  date: string;
  problem_encountered_items: ProblemEncounteredItem[];
};

type ProblemEncounteredItem = {
  id: number;
  no: string;
  description: string;
  affected_work_due_to_problem: string;
  reported_to: string;
  reported_date: string;
  action_taken: string;
  action_taken_date: string;
  no_of_days_between_reported_date: number;
  current_status: string;
  problem_encountered_id: number;
};

export type ProblemEncounteredStateTypes = {
  fetchAll: ApiCallState<ProblemEncountered[]>;
  fetchOne: ApiCallState<ProblemEncountered | {}>;
};

export const ProblemEncounteredActionTypes = {
  FETCH_ALL_PROBLEM_ENCOUNTERED: "FETCH_ALL_PROBLEM_ENCOUNTERED",
  FETCH_ALL_PROBLEM_ENCOUNTERED_RESET: "FETCH_ALL_PROBLEM_ENCOUNTERED_RESET",
  FETCH_ALL_PROBLEM_ENCOUNTERED_FAILURE:
    "FETCH_ALL_PROBLEM_ENCOUNTERED_FAILURE",
  FETCH_ALL_PROBLEM_ENCOUNTERED_SUCCESS:
    "FETCH_ALL_PROBLEM_ENCOUNTERED_SUCCESS",

  FETCH_ONE_PROBLEM_ENCOUNTERED: "FETCH_ONE_PROBLEM_ENCOUNTERED",
  FETCH_ONE_PROBLEM_ENCOUNTERED_RESET: "FETCH_ONE_PROBLEM_ENCOUNTERED_RESET",
  FETCH_ONE_PROBLEM_ENCOUNTERED_FAILURE:
    "FETCH_ONE_PROBLEM_ENCOUNTERED_FAILURE",
  FETCH_ONE_PROBLEM_ENCOUNTERED_SUCCESS:
    "FETCH_ONE_PROBLEM_ENCOUNTERED_SUCCESS",
};
