import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ProjectFuelRequestActionTypes } from "./ProjectFuelRequest.type";

export function* fetchAllProjectFuelRequests(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/project-fuel-request?project_id=${action.payload?.project_id}`);
    yield put({
      type: ProjectFuelRequestActionTypes.FETCH_ALL_PROJECT_FUEL_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectFuelRequestActionTypes.FETCH_ALL_PROJECT_FUEL_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneProjectFuelRequests(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/project-fuel-request/${action.payload}`
    );
    yield put({
      type: ProjectFuelRequestActionTypes.FETCH_ONE_PROJECT_FUEL_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectFuelRequestActionTypes.FETCH_ONE_PROJECT_FUEL_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllProjectFuelRequests() {
  yield takeLatest(ProjectFuelRequestActionTypes.FETCH_ALL_PROJECT_FUEL_REQUEST, fetchAllProjectFuelRequests);
}

export function* watcherFetchOneProjectFuelRequests() {
  yield takeLatest(ProjectFuelRequestActionTypes.FETCH_ONE_PROJECT_FUEL_REQUEST, fetchOneProjectFuelRequests);
}
