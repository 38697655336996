import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { EmployeeTrainingActionTypes } from "./EmployeeTraining.type";

export function* fetchAllEmployeeTrainings(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/employee_training?${query}`
    );
    yield put({
      type: EmployeeTrainingActionTypes.FETCH_ALL_EMPLOYEE_TRAINING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EmployeeTrainingActionTypes.FETCH_ALL_EMPLOYEE_TRAINING_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedEmployeeTrainings(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/employee_training?${query}`
    );
    yield put({
      type: EmployeeTrainingActionTypes.FETCH_PAGED_EMPLOYEE_TRAINING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EmployeeTrainingActionTypes.FETCH_PAGED_EMPLOYEE_TRAINING_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneEmployeeTrainings(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/employee_training/${action.payload}`
    );
    yield put({
      type: EmployeeTrainingActionTypes.FETCH_ONE_EMPLOYEE_TRAINING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EmployeeTrainingActionTypes.FETCH_ONE_EMPLOYEE_TRAINING_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllEmployeeTrainings() {
  yield takeLatest(
    EmployeeTrainingActionTypes.FETCH_ALL_EMPLOYEE_TRAINING,
    fetchAllEmployeeTrainings
  );
}

export function* watcherFetchPagedEmployeeTrainings() {
  yield takeLatest(
    EmployeeTrainingActionTypes.FETCH_PAGED_EMPLOYEE_TRAINING,
    fetchPagedEmployeeTrainings
  );
}

export function* watcherFetchOneEmployeeTrainings() {
  yield takeLatest(
    EmployeeTrainingActionTypes.FETCH_ONE_EMPLOYEE_TRAINING,
    fetchOneEmployeeTrainings
  );
}
