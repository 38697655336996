import {
  ServiceRequestStateTypes,
  ServiceRequestActionTypes,
} from "./ServiceRequest.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ServiceRequestStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const ServiceRequestReducer = (
  state: ServiceRequestStateTypes = INITIAL_STATE,
  action: any
): ServiceRequestStateTypes => {
  switch (action.type) {
    case ServiceRequestActionTypes.FETCH_ALL_SERVICE_REQUEST:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ServiceRequestActionTypes.FETCH_ALL_SERVICE_REQUEST_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ServiceRequestActionTypes.FETCH_ALL_SERVICE_REQUEST_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ServiceRequestActionTypes.FETCH_ALL_SERVICE_REQUEST_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ServiceRequestActionTypes.FETCH_ONE_SERVICE_REQUEST:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ServiceRequestActionTypes.FETCH_ONE_SERVICE_REQUEST_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState({}),
      };
    case ServiceRequestActionTypes.FETCH_ONE_SERVICE_REQUEST_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ServiceRequestActionTypes.FETCH_ONE_SERVICE_REQUEST_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ServiceRequestReducer;
