import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MaterialStartingBalanceActionTypes } from "./MaterialStartingBalance.type";

export function* fetchAllMaterialStartingBalances(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      query =
        "?" +
        Object.keys(action.payload)
          .map((key) => `${key}=${action.payload[key]}`)
          .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/material-starting-balance${query}`
    );
    yield put({
      type: MaterialStartingBalanceActionTypes.FETCH_ALL_MATERIAL_STARTING_BALANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialStartingBalanceActionTypes.FETCH_ALL_MATERIAL_STARTING_BALANCE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMaterialStartingBalances(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/material-starting-balance/${action.payload}`
    );
    yield put({
      type: MaterialStartingBalanceActionTypes.FETCH_ONE_MATERIAL_STARTING_BALANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialStartingBalanceActionTypes.FETCH_ONE_MATERIAL_STARTING_BALANCE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMaterialStartingBalances() {
  yield takeLatest(
    MaterialStartingBalanceActionTypes.FETCH_ALL_MATERIAL_STARTING_BALANCE,
    fetchAllMaterialStartingBalances
  );
}

export function* watcherFetchOneMaterialStartingBalances() {
  yield takeLatest(
    MaterialStartingBalanceActionTypes.FETCH_ONE_MATERIAL_STARTING_BALANCE,
    fetchOneMaterialStartingBalances
  );
}
