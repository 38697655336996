import {
  PartnerMessageStateTypes,
  PartnerMessageActionTypes,
} from "./PartnerMessage.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: PartnerMessageStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const PartnerMessageReducer = (
  state: PartnerMessageStateTypes = INITIAL_STATE,
  action: any
): PartnerMessageStateTypes => {
  switch (action.type) {
    case PartnerMessageActionTypes.FETCH_ALL_PARTNER_MESSAGE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case PartnerMessageActionTypes.FETCH_ALL_PARTNER_MESSAGE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case PartnerMessageActionTypes.FETCH_ALL_PARTNER_MESSAGE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PartnerMessageActionTypes.FETCH_ALL_PARTNER_MESSAGE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case PartnerMessageActionTypes.FETCH_ONE_PARTNER_MESSAGE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case PartnerMessageActionTypes.FETCH_ONE_PARTNER_MESSAGE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState({}),
      };
    case PartnerMessageActionTypes.FETCH_ONE_PARTNER_MESSAGE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PartnerMessageActionTypes.FETCH_ONE_PARTNER_MESSAGE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default PartnerMessageReducer;
