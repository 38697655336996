import { SubContractSecurity } from "../SubContractSecurity/SubContractSecurity.type";
import { ApiCallState } from "../Utils";

export type SubContractRegistry= {
  id: number;
  project_id:number;
  date:string;
  sub_contract_id: number;
  work_title:string;
  work_item: string;
  sub_contract_registry_items:SubContractRegistryItem[];
  sub_contract: SubContractSecurity
};

export type SubContractRegistryItem= {
    id: number;
    sub_contract_registry_id:number;
    description:string;
    unit:string;
    contract_quantity:number;
    unit_price:number;
    is_title: boolean;
  };

export type SubContractRegistryStateTypes = {
  fetchAll: ApiCallState<SubContractRegistry[]>;
  fetchOne: ApiCallState<SubContractRegistry | {}>;
};

export const SubContractRegistryActionTypes = {
  FETCH_ALL_SUB_CONTRACT_REGISTRY: "FETCH_ALL_SUB_CONTRACT_REGISTRY",
  FETCH_ALL_SUB_CONTRACT_REGISTRY_RESET: "FETCH_ALL_SUB_CONTRACT_REGISTRY_RESET",
  FETCH_ALL_SUB_CONTRACT_REGISTRY_FAILURE: "FETCH_ALL_SUB_CONTRACT_REGISTRY_FAILURE",
  FETCH_ALL_SUB_CONTRACT_REGISTRY_SUCCESS: "FETCH_ALL_SUB_CONTRACT_REGISTRY_SUCCESS",

  FETCH_ONE_SUB_CONTRACT_REGISTRY: "FETCH_ONE_SUB_CONTRACT_REGISTRY",
  FETCH_ONE_SUB_CONTRACT_REGISTRY_RESET: "FETCH_ONE_SUB_CONTRACT_REGISTRY_RESET",
  FETCH_ONE_SUB_CONTRACT_REGISTRY_FAILURE: "FETCH_ONE_SUB_CONTRACT_REGISTRY_FAILURE",
  FETCH_ONE_SUB_CONTRACT_REGISTRY_SUCCESS: "FETCH_ONE_SUB_CONTRACT_REGISTRY_SUCCESS",
};
