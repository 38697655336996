import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { BonusActionTypes } from "./Bonus.type";
import { authHeader } from "../../utilities/utilities";

export function* fetchAllBonuses(action: any): any {
  try {
    let query = "";

    if (action?.payload && Object.keys(action.payload).length > 0) {
      query += Object.keys(action.payload)
        .map((key) => `${key}=${action.payload[key]}`)
        .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/bonus?${query}`,
      authHeader()
    );
    yield put({
      type: BonusActionTypes.FETCH_ALL_BONUS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BonusActionTypes.FETCH_ALL_BONUS_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllBonuses() {
  yield takeLatest(BonusActionTypes.FETCH_ALL_BONUS, fetchAllBonuses);
}
