import { ApiCallState } from "../Utils";

export type SubContractorPerformance = {
  id: any;
  project_id: number;
  isApproved: boolean;
  sub_contract_name: string;
  type_of_sub_contract_work: string;
  type_of_contract: string;
  name_of_contract_person: string;
  address: string;
  performance: number;
  tax: number;
  adequate_man_power: string;
  quality_of_work: string;
  honesty: string;
  supervisor: string;
  remark: string;
  key: number;
  sub_contractor_performance_rate: SubContractorPerformanceRate;
  approved_by_id: number;
};

type SubContractorPerformanceRate = {
  id?: number;
  quality_of_work: number;
  adequate_man_power: number;
  supervision: number;
  honesty: number;
};

export type SubContractorPerformanceStateTypes = {
  fetchAll: ApiCallState<SubContractorPerformance[]>;
  fetchOne: ApiCallState<SubContractorPerformance | {}>;
};

export const SubContractorPerformanceActionTypes = {
  FETCH_ALL_SUB_CONTRACTOR_PERFORMANCE: "FETCH_ALL_SUB_CONTRACTOR_PERFORMANCE",
  FETCH_ALL_SUB_CONTRACTOR_PERFORMANCE_RESET:
    "FETCH_ALL_SUB_CONTRACTOR_PERFORMANCE_RESET",
  FETCH_ALL_SUB_CONTRACTOR_PERFORMANCE_FAILURE:
    "FETCH_ALL_SUB_CONTRACTOR_PERFORMANCE_FAILURE",
  FETCH_ALL_SUB_CONTRACTOR_PERFORMANCE_SUCCESS:
    "FETCH_ALL_SUB_CONTRACTOR_PERFORMANCE_SUCCESS",

  FETCH_ONE_SUB_CONTRACTOR_PERFORMANCE: "FETCH_ONE_SUB_CONTRACTOR_PERFORMANCE",
  FETCH_ONE_SUB_CONTRACTOR_PERFORMANCE_RESET:
    "FETCH_ONE_SUB_CONTRACTOR_PERFORMANCE_RESET",
  FETCH_ONE_SUB_CONTRACTOR_PERFORMANCE_FAILURE:
    "FETCH_ONE_SUB_CONTRACTOR_PERFORMANCE_FAILURE",
  FETCH_ONE_SUB_CONTRACTOR_PERFORMANCE_SUCCESS:
    "FETCH_ONE_SUB_CONTRACTOR_PERFORMANCE_SUCCESS",
};
