import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { DamageReturnActionTypes } from "./DamageReturn.type";
import { formatQuery } from "../Utils";

export function* fetchAllDamageReturns(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/damage-return?${formatQuery(action)}`);
    yield put({
      type: DamageReturnActionTypes.FETCH_ALL_DAMAGE_RETURN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DamageReturnActionTypes.FETCH_ALL_DAMAGE_RETURN_FAILURE,
      payload: error,
    });
  }
}

export function* fetchReportDamageReturns(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/damage-return/report?${query}`
    );
    yield put({
      type: DamageReturnActionTypes.FETCH_REPORT_DAMAGE_RETURN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DamageReturnActionTypes.FETCH_REPORT_DAMAGE_RETURN_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedDamageReturns(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/damage-return?${query}`);
    yield put({
      type: DamageReturnActionTypes.FETCH_PAGED_DAMAGE_RETURN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DamageReturnActionTypes.FETCH_PAGED_DAMAGE_RETURN_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneDamageReturns(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/damage-return/${action.payload}`
    );
    yield put({
      type: DamageReturnActionTypes.FETCH_ONE_DAMAGE_RETURN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DamageReturnActionTypes.FETCH_ONE_DAMAGE_RETURN_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllDamageReturns() {
  yield takeLatest(
    DamageReturnActionTypes.FETCH_ALL_DAMAGE_RETURN,
    fetchAllDamageReturns
  );
}

export function* watcherFetchReportDamageReturns() {
  yield takeLatest(
    DamageReturnActionTypes.FETCH_REPORT_DAMAGE_RETURN,
    fetchReportDamageReturns
  );
}

export function* watcherFetchPagedDamageReturns() {
  yield takeLatest(
    DamageReturnActionTypes.FETCH_PAGED_DAMAGE_RETURN,
    fetchPagedDamageReturns
  );
}

export function* watcherFetchOneDamageReturns() {
  yield takeLatest(
    DamageReturnActionTypes.FETCH_ONE_DAMAGE_RETURN,
    fetchOneDamageReturns
  );
}
