import { EquipmentInventory } from "../EquipmentInventory/EquipmentInventory.type";
import { Project, Store } from "../Project/Project.type";
import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type EquipmentWO = {
  id: number;
  description: string;
  project: Project;
  store: Store;
  location: string;
  date: string;
  vat: boolean;
  mileage: number;
  next_service: number;
  equipment_inventory: EquipmentInventory;
  staffs: Staff[];
  // equipment_wo_items: ExpenseTableData[];
};

export type EquipmentWOStateTypes = {
  fetchAll: ApiCallState<EquipmentWO[]>;
};

export const EquipmentWOActionTypes = {
  FETCH_ALL_EQUIPMENT_WO: "FETCH_ALL_EQUIPMENT_WO",
  FETCH_ALL_EQUIPMENT_WO_RESET: "FETCH_ALL_EQUIPMENT_WO_RESET",
  FETCH_ALL_EQUIPMENT_WO_FAILURE: "FETCH_ALL_EQUIPMENT_WO_FAILURE",
  FETCH_ALL_EQUIPMENT_WO_SUCCESS: "FETCH_ALL_EQUIPMENT_WO_SUCCESS",
};
