import { formatQuery } from "../Utils";
import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { FixedAssetIssueActionTypes } from "./FixedAssetIssue.type";

export function* fetchAllFixedAssetIssues(action: any): any {
  try {
    let query = formatQuery(action);

    const response = yield axios.get(
      `${API_BASE_URI}/fixed-asset-issue?${query}`
    );
    yield put({
      type: FixedAssetIssueActionTypes.FETCH_ALL_FIXED_ASSET_ISSUE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FixedAssetIssueActionTypes.FETCH_ALL_FIXED_ASSET_ISSUE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchAllDetailedFixedAssetIssues(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/fixed-asset-issue/detailed?${query}`
    );
    yield put({
      type: FixedAssetIssueActionTypes.FETCH_ALL_DETAILED_FIXED_ASSET_ISSUE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FixedAssetIssueActionTypes.FETCH_ALL_DETAILED_FIXED_ASSET_ISSUE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchReportFixedAssetIssues(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/fixed-asset-issue/report?${formatQuery(action)}`
    );
    yield put({
      type: FixedAssetIssueActionTypes.FETCH_REPORT_FIXED_ASSET_ISSUE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FixedAssetIssueActionTypes.FETCH_REPORT_FIXED_ASSET_ISSUE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedFixedAssetIssues(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/fixed-asset-issue?${query}`
    );
    yield put({
      type: FixedAssetIssueActionTypes.FETCH_PAGED_FIXED_ASSET_ISSUE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FixedAssetIssueActionTypes.FETCH_PAGED_FIXED_ASSET_ISSUE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneFixedAssetIssues(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/fixed-asset-issue/${action.payload}`
    );
    yield put({
      type: FixedAssetIssueActionTypes.FETCH_ONE_FIXED_ASSET_ISSUE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FixedAssetIssueActionTypes.FETCH_ONE_FIXED_ASSET_ISSUE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllFixedAssetIssues() {
  yield takeLatest(
    FixedAssetIssueActionTypes.FETCH_ALL_FIXED_ASSET_ISSUE,
    fetchAllFixedAssetIssues
  );
}

export function* watcherFetchAllDetailedFixedAssetIssues() {
  yield takeLatest(
    FixedAssetIssueActionTypes.FETCH_ALL_DETAILED_FIXED_ASSET_ISSUE,
    fetchAllDetailedFixedAssetIssues
  );
}

export function* watcherFetchReportFixedAssetIssues() {
  yield takeLatest(
    FixedAssetIssueActionTypes.FETCH_REPORT_FIXED_ASSET_ISSUE,
    fetchReportFixedAssetIssues
  );
}

export function* watcherFetchPagedFixedAssetIssues() {
  yield takeLatest(
    FixedAssetIssueActionTypes.FETCH_PAGED_FIXED_ASSET_ISSUE,
    fetchPagedFixedAssetIssues
  );
}

export function* watcherFetchOneFixedAssetIssues() {
  yield takeLatest(
    FixedAssetIssueActionTypes.FETCH_ONE_FIXED_ASSET_ISSUE,
    fetchOneFixedAssetIssues
  );
}
