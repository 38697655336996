import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MaintenanceCostActionTypes } from "./MaintenanceCost.type";

export function* fetchAllMaintenanceCosts(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/maintenance-cost?${query}`
    );
    yield put({
      type: MaintenanceCostActionTypes.FETCH_ALL_MAINTENANCE_COST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaintenanceCostActionTypes.FETCH_ALL_MAINTENANCE_COST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchListMaintenanceCosts(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/maintenance-cost/list?${query}`
    );
    yield put({
      type: MaintenanceCostActionTypes.FETCH_LIST_MAINTENANCE_COST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaintenanceCostActionTypes.FETCH_LIST_MAINTENANCE_COST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedMaintenanceCosts(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/maintenance-cost?${query}`
    );
    yield put({
      type: MaintenanceCostActionTypes.FETCH_PAGED_MAINTENANCE_COST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaintenanceCostActionTypes.FETCH_PAGED_MAINTENANCE_COST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMaintenanceCosts(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/maintenance-cost/${action.payload}`
    );
    yield put({
      type: MaintenanceCostActionTypes.FETCH_ONE_MAINTENANCE_COST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaintenanceCostActionTypes.FETCH_ONE_MAINTENANCE_COST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMaintenanceCosts() {
  yield takeLatest(
    MaintenanceCostActionTypes.FETCH_ALL_MAINTENANCE_COST,
    fetchAllMaintenanceCosts
  );
}

export function* watcherFetchListMaintenanceCosts() {
  yield takeLatest(
    MaintenanceCostActionTypes.FETCH_LIST_MAINTENANCE_COST,
    fetchListMaintenanceCosts
  );
}

export function* watcherFetchPagedMaintenanceCosts() {
  yield takeLatest(
    MaintenanceCostActionTypes.FETCH_PAGED_MAINTENANCE_COST,
    fetchPagedMaintenanceCosts
  );
}

export function* watcherFetchOneMaintenanceCosts() {
  yield takeLatest(
    MaintenanceCostActionTypes.FETCH_ONE_MAINTENANCE_COST,
    fetchOneMaintenanceCosts
  );
}
