import { ApiCallState } from "../Utils";
import { Vacancy, VacancyItem } from "../Vacancy/Vacancy.type";
import {ApplicantRegistryItem} from "../ApplicantRegistryItem/ApplicantRegistryItem.type";

export type ApplicantRegistry = {
  id: number;
  vacancy_id: number;
  vacancy_item_id: number;
  vacancy: Vacancy;
  vacancy_item: VacancyItem;
  applicant_registry_items: ApplicantRegistryItem[];
  applicant_registry_statuses: any[]
};

export type ApplicantRegistryStateTypes = {
  fetchAll: ApiCallState<ApplicantRegistry[]>;
  fetchOne: ApiCallState<ApplicantRegistry | {}>;
};

export const ApplicantRegistryActionTypes = {
  FETCH_ALL_APPLICANT_REGISTRY: "FETCH_ALL_APPLICANT_REGISTRY",
  FETCH_ALL_APPLICANT_REGISTRY_RESET: "FETCH_ALL_APPLICANT_REGISTRY_RESET",
  FETCH_ALL_APPLICANT_REGISTRY_FAILURE: "FETCH_ALL_APPLICANT_REGISTRY_FAILURE",
  FETCH_ALL_APPLICANT_REGISTRY_SUCCESS: "FETCH_ALL_APPLICANT_REGISTRY_SUCCESS",

  FETCH_ONE_APPLICANT_REGISTRY: "FETCH_ONE_APPLICANT_REGISTRY",
  FETCH_ONE_APPLICANT_REGISTRY_RESET: "FETCH_ONE_APPLICANT_REGISTRY_RESET",
  FETCH_ONE_APPLICANT_REGISTRY_FAILURE: "FETCH_ONE_APPLICANT_REGISTRY_FAILURE",
  FETCH_ONE_APPLICANT_REGISTRY_SUCCESS: "FETCH_ONE_APPLICANT_REGISTRY_SUCCESS",
};
