import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { AlertActionTypes } from "./Alert.type";

export function* fetchAllAlerts(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/alert`);
    yield put({
      type: AlertActionTypes.FETCH_ALL_ALERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AlertActionTypes.FETCH_ALL_ALERT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneAlerts(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/alert/${action.payload}`);
    yield put({
      type: AlertActionTypes.FETCH_ONE_ALERT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AlertActionTypes.FETCH_ONE_ALERT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllAlerts() {
  yield takeLatest(AlertActionTypes.FETCH_ALL_ALERT, fetchAllAlerts);
}

export function* watcherFetchOneAlerts() {
  yield takeLatest(AlertActionTypes.FETCH_ONE_ALERT, fetchOneAlerts);
}
