import { MasterListStateTypes, MasterListActionTypes } from "./MasterList.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: MasterListStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const MasterListReducer = (
  state: MasterListStateTypes = INITIAL_STATE,
  action: any
): MasterListStateTypes => {
  switch (action.type) {
    case MasterListActionTypes.FETCH_ALL_MASTER_LIST:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MasterListActionTypes.FETCH_ALL_MASTER_LIST_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MasterListActionTypes.FETCH_ALL_MASTER_LIST_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MasterListActionTypes.FETCH_ALL_MASTER_LIST_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MasterListActionTypes.FETCH_ONE_MASTER_LIST:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case MasterListActionTypes.FETCH_ONE_MASTER_LIST_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MasterListActionTypes.FETCH_ONE_MASTER_LIST_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MasterListActionTypes.FETCH_ONE_MASTER_LIST_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default MasterListReducer;
