import {
    BoqItemGroupStateTypes,
    BoqItemGroupActionTypes,
  } from "./BoqItemGroup.type";
  import { resetApiCallState } from "../Utils";
  
  const INITIAL_STATE: BoqItemGroupStateTypes = {
    fetchAll: resetApiCallState([]),
    fetchOne: resetApiCallState({}),
  };
  
  const BoqItemGroupReducer = (
    state: BoqItemGroupStateTypes = INITIAL_STATE,
    action: any
  ): BoqItemGroupStateTypes => {
    switch (action.type) {
      case BoqItemGroupActionTypes.FETCH_ALL_BOQ_ITEM_GROUP:
        return {
          ...state,
          fetchAll: {
            error: null,
            payload: [],
            isPending: true,
            isSuccessful: false,
          },
        };
      case BoqItemGroupActionTypes.FETCH_ALL_BOQ_ITEM_GROUP_RESET:
        return {
          ...state,
          fetchAll: resetApiCallState([]),
        };
      case BoqItemGroupActionTypes.FETCH_ALL_BOQ_ITEM_GROUP_FAILURE:
        return {
          ...state,
          fetchAll: {
            payload: [],
            isPending: false,
            isSuccessful: false,
            error: action.payload,
          },
        };
      case BoqItemGroupActionTypes.FETCH_ALL_BOQ_ITEM_GROUP_SUCCESS:
        return {
          ...state,
          fetchAll: {
            error: null,
            isPending: false,
            isSuccessful: true,
            payload: action.payload,
          },
        };
  
      case BoqItemGroupActionTypes.FETCH_ONE_BOQ_ITEM_GROUP:
        return {
          ...state,
          fetchOne: {
            error: null,
            payload: {},
            isPending: true,
            isSuccessful: false,
          },
        };
      case BoqItemGroupActionTypes.FETCH_ONE_BOQ_ITEM_GROUP_RESET:
        return {
          ...state,
          fetchAll: resetApiCallState([]),
        };
      case BoqItemGroupActionTypes.FETCH_ONE_BOQ_ITEM_GROUP_FAILURE:
        return {
          ...state,
          fetchOne: {
            payload: {},
            isPending: false,
            isSuccessful: false,
            error: action.payload,
          },
        };
      case BoqItemGroupActionTypes.FETCH_ONE_BOQ_ITEM_GROUP_SUCCESS:
        return {
          ...state,
          fetchOne: {
            error: null,
            isPending: false,
            isSuccessful: true,
            payload: action.payload,
          },
        };
  
      default:
        return state;
    }
  };
  
  export default BoqItemGroupReducer;
  