import { ApiCallState } from "../Utils";

export type UncollectedIpc= {
  id?: number;
  payment_number: number;
  current_payment_executed: number;
  amount_of_ipc_not_collected_client: number;
  amount_of_ipc_under_review: number;
  amount_of_ipc_not_collected_under_review: number;
  advanced_payment: number;
  remark: number;
  project_id:number;
};

export type UncollectedIpcStateTypes = {
  fetchAll: ApiCallState<UncollectedIpc[]>;
  fetchOne: ApiCallState<UncollectedIpc | {}>;
};

export const UncollectedIpcActionTypes = {
  FETCH_ALL_UNCOLLECTED_IPC: "FETCH_ALL_UNCOLLECTED_IPC",
  FETCH_ALL_UNCOLLECTED_IPC_RESET: "FETCH_ALL_UNCOLLECTED_IPC_RESET",
  FETCH_ALL_UNCOLLECTED_IPC_FAILURE: "FETCH_ALL_UNCOLLECTED_IPC_FAILURE",
  FETCH_ALL_UNCOLLECTED_IPC_SUCCESS: "FETCH_ALL_UNCOLLECTED_IPC_SUCCESS",

  FETCH_ONE_UNCOLLECTED_IPC: "FETCH_ONE_UNCOLLECTED_IPC",
  FETCH_ONE_UNCOLLECTED_IPC_RESET: "FETCH_ONE_UNCOLLECTED_IPC_RESET",
  FETCH_ONE_UNCOLLECTED_IPC_FAILURE: "FETCH_ONE_UNCOLLECTED_IPC_FAILURE",
  FETCH_ONE_UNCOLLECTED_IPC_SUCCESS: "FETCH_ONE_UNCOLLECTED_IPC_SUCCESS",
};
