import { ProjectAllowanceStateTypes, ProjectAllowanceActionTypes } from "./ProjectAllowance.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ProjectAllowanceStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const ProjectAllowanceReducer = (
  state: ProjectAllowanceStateTypes = INITIAL_STATE,
  action: any
): ProjectAllowanceStateTypes => {
  switch (action.type) {
    case ProjectAllowanceActionTypes.FETCH_ALL_PROJECT_ALLOWANCE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ProjectAllowanceActionTypes.FETCH_ALL_PROJECT_ALLOWANCE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ProjectAllowanceActionTypes.FETCH_ALL_PROJECT_ALLOWANCE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ProjectAllowanceActionTypes.FETCH_ALL_PROJECT_ALLOWANCE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ProjectAllowanceActionTypes.FETCH_ONE_PROJECT_ALLOWANCE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ProjectAllowanceActionTypes.FETCH_ONE_PROJECT_ALLOWANCE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ProjectAllowanceActionTypes.FETCH_ONE_PROJECT_ALLOWANCE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ProjectAllowanceActionTypes.FETCH_ONE_PROJECT_ALLOWANCE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ProjectAllowanceReducer;
