import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { OperationDailyReportActionTypes } from "./OperationDailyReport.type";

export function* fetchAllOperationDailyReports(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/operation-daily-report?${query}`
    );
    yield put({
      type: OperationDailyReportActionTypes.FETCH_ALL_OPERATION_DAILY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: OperationDailyReportActionTypes.FETCH_ALL_OPERATION_DAILY_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchSelectorOperationDailyReports(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/operation-daily-report?${query}`
    );
    yield put({
      type: OperationDailyReportActionTypes.FETCH_SELECTOR_OPERATION_DAILY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: OperationDailyReportActionTypes.FETCH_SELECTOR_OPERATION_DAILY_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedOperationDailyReports(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/operation-daily-report?${query}`
    );
    yield put({
      type: OperationDailyReportActionTypes.FETCH_PAGED_OPERATION_DAILY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: OperationDailyReportActionTypes.FETCH_PAGED_OPERATION_DAILY_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneOperationDailyReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/operation-daily-report/${action.payload}`
    );
    yield put({
      type: OperationDailyReportActionTypes.FETCH_ONE_OPERATION_DAILY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: OperationDailyReportActionTypes.FETCH_ONE_OPERATION_DAILY_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllOperationDailyReports() {
  yield takeLatest(
    OperationDailyReportActionTypes.FETCH_ALL_OPERATION_DAILY_REPORT,
    fetchAllOperationDailyReports
  );
}

export function* watcherFetchSelectorOperationDailyReports() {
  yield takeLatest(
    OperationDailyReportActionTypes.FETCH_SELECTOR_OPERATION_DAILY_REPORT,
    fetchSelectorOperationDailyReports
  );
}

export function* watcherFetchPagedOperationDailyReports() {
  yield takeLatest(
    OperationDailyReportActionTypes.FETCH_PAGED_OPERATION_DAILY_REPORT,
    fetchPagedOperationDailyReports
  );
}

export function* watcherFetchOneOperationDailyReports() {
  yield takeLatest(
    OperationDailyReportActionTypes.FETCH_ONE_OPERATION_DAILY_REPORT,
    fetchOneOperationDailyReports
  );
}
