import { MikadaWeeklyMaterialReportStateTypes, MikadaWeeklyMaterialReportActionTypes } from "./MikadaWeeklyMaterialReport.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: MikadaWeeklyMaterialReportStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const MikadaWeeklyMaterialReportReducer = (
  state: MikadaWeeklyMaterialReportStateTypes = INITIAL_STATE,
  action: any
): MikadaWeeklyMaterialReportStateTypes => {
  switch (action.type) {
    case MikadaWeeklyMaterialReportActionTypes.FETCH_ALL_MIKADA_WEEKLY_MATERIAL_REPORT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MikadaWeeklyMaterialReportActionTypes.FETCH_ALL_MIKADA_WEEKLY_MATERIAL_REPORT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MikadaWeeklyMaterialReportActionTypes.FETCH_ALL_MIKADA_WEEKLY_MATERIAL_REPORT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MikadaWeeklyMaterialReportActionTypes.FETCH_ALL_MIKADA_WEEKLY_MATERIAL_REPORT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MikadaWeeklyMaterialReportActionTypes.FETCH_ONE_MIKADA_WEEKLY_MATERIAL_REPORT:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case MikadaWeeklyMaterialReportActionTypes.FETCH_ONE_MIKADA_WEEKLY_MATERIAL_REPORT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MikadaWeeklyMaterialReportActionTypes.FETCH_ONE_MIKADA_WEEKLY_MATERIAL_REPORT_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MikadaWeeklyMaterialReportActionTypes.FETCH_ONE_MIKADA_WEEKLY_MATERIAL_REPORT_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default MikadaWeeklyMaterialReportReducer;
