import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { EmployeePerformanceFormActionTypes } from "./EmployeePerformanceForm.type";

export function* fetchAllEmployeePerformanceForms(action: any): any {
  try {
    let query: any = "";

    if (action?.payload && Object.keys(action?.payload).length > 0) {
      let keys: any[] = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/employee-performance-form?${query}`
    );
    yield put({
      type: EmployeePerformanceFormActionTypes.FETCH_ALL_EMPLOYEE_PERFORMANCE_FORM_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EmployeePerformanceFormActionTypes.FETCH_ALL_EMPLOYEE_PERFORMANCE_FORM_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneEmployeePerformanceForms(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/employee-performance-form/${action.payload}`
    );
    yield put({
      type: EmployeePerformanceFormActionTypes.FETCH_ONE_EMPLOYEE_PERFORMANCE_FORM_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EmployeePerformanceFormActionTypes.FETCH_ONE_EMPLOYEE_PERFORMANCE_FORM_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllEmployeePerformanceForms() {
  yield takeLatest(
    EmployeePerformanceFormActionTypes.FETCH_ALL_EMPLOYEE_PERFORMANCE_FORM,
    fetchAllEmployeePerformanceForms
  );
}

export function* watcherFetchOneEmployeePerformanceForms() {
  yield takeLatest(
    EmployeePerformanceFormActionTypes.FETCH_ONE_EMPLOYEE_PERFORMANCE_FORM,
    fetchOneEmployeePerformanceForms
  );
}
