import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SubContractorPaymentActionTypes } from "./SubContractorPayment.type";

export function* fetchAllSubContractorPayments(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/sub-contractor-payment`);
    yield put({
      type: SubContractorPaymentActionTypes.FETCH_ALL_SUB_CONTRACTOR_PAYMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubContractorPaymentActionTypes.FETCH_ALL_SUB_CONTRACTOR_PAYMENT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSubContractorPayments(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/sub-contractor-payment/${action.payload}`
    );
    yield put({
      type: SubContractorPaymentActionTypes.FETCH_ONE_SUB_CONTRACTOR_PAYMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubContractorPaymentActionTypes.FETCH_ONE_SUB_CONTRACTOR_PAYMENT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSubContractorPayments() {
  yield takeLatest(SubContractorPaymentActionTypes.FETCH_ALL_SUB_CONTRACTOR_PAYMENT, fetchAllSubContractorPayments);
}

export function* watcherFetchOneSubContractorPayments() {
  yield takeLatest(SubContractorPaymentActionTypes.FETCH_ONE_SUB_CONTRACTOR_PAYMENT, fetchOneSubContractorPayments);
}
