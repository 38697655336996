import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { formatQuery } from "../Utils";
import { FixedAssetReceivedActionTypes } from "./FixedAssetReceived.type";

export function* fetchAllFixedAssetReceived(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/fixed-asset-received?${formatQuery(action)}`
    );
    yield put({
      type: FixedAssetReceivedActionTypes.FETCH_ALL_FIXED_ASSET_RECEIVED_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FixedAssetReceivedActionTypes.FETCH_ALL_FIXED_ASSET_RECEIVED_FAILURE,
      payload: error,
    });
  }
}

export function* fetchFixedAssetReceivedReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/fixed-asset-received/report?${formatQuery(action)}`
    );
    yield put({
      type: FixedAssetReceivedActionTypes.FETCH_FIXED_ASSET_RECEIVED_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FixedAssetReceivedActionTypes.FETCH_FIXED_ASSET_RECEIVED_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchFixedAssetPlateNumberList(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/fixed-asset-received/item/plate-number-list?${formatQuery(
        action
      )}`
    );
    yield put({
      type: FixedAssetReceivedActionTypes.FETCH_FIXED_PLATE_NUMBER_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FixedAssetReceivedActionTypes.FETCH_FIXED_PLATE_NUMBER_LIST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllFixedAssetReceived() {
  yield takeLatest(
    FixedAssetReceivedActionTypes.FETCH_ALL_FIXED_ASSET_RECEIVED,
    fetchAllFixedAssetReceived
  );
}

export function* watcherFetchFixedAssetReceivedReports() {
  yield takeLatest(
    FixedAssetReceivedActionTypes.FETCH_FIXED_ASSET_RECEIVED_REPORT,
    fetchFixedAssetReceivedReports
  );
}

export function* watcherFetchFixedAssetPlateNumberList() {
  yield takeLatest(
    FixedAssetReceivedActionTypes.FETCH_FIXED_PLATE_NUMBER_LIST,
    fetchFixedAssetPlateNumberList
  );
}
