import { Department } from "../Department/Department.type";
import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type Promotion = {
  id: number;
  user_id: number;

  staff_id: number;
  date: string;

  previous_department_id: number;
  department_id: number;

  previous_salary: number;
  salary_rate: number;
  salary: number;

  previous_role: string;
  role: string;

  reason: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  staff: Staff;
  previous_department: Department;
  department: Department;
};

export type PromotionStateTypes = {
  fetchAll: ApiCallState<Promotion[]>;
  fetchOne: ApiCallState<Promotion | {}>;
};

export const PromotionActionTypes = {
  FETCH_ALL_PROMOTION: "FETCH_ALL_PROMOTION",
  FETCH_ALL_PROMOTION_RESET: "FETCH_ALL_PROMOTION_RESET",
  FETCH_ALL_PROMOTION_FAILURE: "FETCH_ALL_PROMOTION_FAILURE",
  FETCH_ALL_PROMOTION_SUCCESS: "FETCH_ALL_PROMOTION_SUCCESS",

  FETCH_ONE_PROMOTION: "FETCH_ONE_PROMOTION",
  FETCH_ONE_PROMOTION_RESET: "FETCH_ONE_PROMOTION_RESET",
  FETCH_ONE_PROMOTION_FAILURE: "FETCH_ONE_PROMOTION_FAILURE",
  FETCH_ONE_PROMOTION_SUCCESS: "FETCH_ONE_PROMOTION_SUCCESS",
};
