import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { TimeUtilizationReportActionTypes } from "./TimeUtilizationReport.type";
import { formatQuery } from "../Utils";

export function* fetchAllTimeUtilizationReports(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/time-utilization-report?${formatQuery(action)}`);
    yield put({
      type: TimeUtilizationReportActionTypes.FETCH_ALL_TIME_UTILIZATION_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TimeUtilizationReportActionTypes.FETCH_ALL_TIME_UTILIZATION_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchTimeUtilizationReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/time-utilization-report/report?${formatQuery(action)}`
    );
    yield put({
      type: TimeUtilizationReportActionTypes.FETCH_TIME_UTILIZATION_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TimeUtilizationReportActionTypes.FETCH_TIME_UTILIZATION_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllTimeUtilizationReports() {
  yield takeLatest(TimeUtilizationReportActionTypes.FETCH_ALL_TIME_UTILIZATION_REPORT, fetchAllTimeUtilizationReports);
}

export function* watcherFetchTimeUtilizationReports() {
  yield takeLatest(TimeUtilizationReportActionTypes.FETCH_TIME_UTILIZATION_REPORT, fetchTimeUtilizationReports);
}
