import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { EmployeePerformanceActionTypes } from "./EmployeePerformance.type";

export function* fetchAllEmployeePerformances(action: any): any {
  try {
    let query: any = "";

    if (action.payload) {
      let keys: any[] = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/employee-performance?${query}`
    );
    yield put({
      type: EmployeePerformanceActionTypes.FETCH_ALL_EMPLOYEE_PERFORMANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EmployeePerformanceActionTypes.FETCH_ALL_EMPLOYEE_PERFORMANCE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneEmployeePerformances(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/employee-performance/${action.payload}`
    );
    yield put({
      type: EmployeePerformanceActionTypes.FETCH_ONE_EMPLOYEE_PERFORMANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EmployeePerformanceActionTypes.FETCH_ONE_EMPLOYEE_PERFORMANCE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllEmployeePerformances() {
  yield takeLatest(
    EmployeePerformanceActionTypes.FETCH_ALL_EMPLOYEE_PERFORMANCE,
    fetchAllEmployeePerformances
  );
}

export function* watcherFetchOneEmployeePerformances() {
  yield takeLatest(
    EmployeePerformanceActionTypes.FETCH_ONE_EMPLOYEE_PERFORMANCE,
    fetchOneEmployeePerformances
  );
}
