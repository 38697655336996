import { ChartsOfAccountStateTypes, ChartsOfAccountActionTypes } from "./ChartsOfAccount.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ChartsOfAccountStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const ChartsOfAccountReducer = (
  state: ChartsOfAccountStateTypes = INITIAL_STATE,
  action: any
): ChartsOfAccountStateTypes => {
  switch (action.type) {
    case ChartsOfAccountActionTypes.FETCH_ALL_CHARTS_OF_ACCOUNT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ChartsOfAccountActionTypes.FETCH_ALL_CHARTS_OF_ACCOUNT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ChartsOfAccountActionTypes.FETCH_ALL_CHARTS_OF_ACCOUNT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ChartsOfAccountActionTypes.FETCH_ALL_CHARTS_OF_ACCOUNT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ChartsOfAccountActionTypes.FETCH_ONE_CHARTS_OF_ACCOUNT:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ChartsOfAccountActionTypes.FETCH_ONE_CHARTS_OF_ACCOUNT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ChartsOfAccountActionTypes.FETCH_ONE_CHARTS_OF_ACCOUNT_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ChartsOfAccountActionTypes.FETCH_ONE_CHARTS_OF_ACCOUNT_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ChartsOfAccountReducer;
