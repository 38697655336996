import { FuelRequestVoucher } from "../FuelRequestVoucher/FuelRequestVoucher.type";
import { ApiCallState } from "../Utils";

export type FuelIssueVoucher= {
  id: number;
  date: string;
  plate_no: string;
  coupon_no: string;
  reciever_name: string;
  issued_by_id: number;
  approved_by_id: number;
  is_approved: boolean;
  fuel_issue_voucher_items: FuelIssueVoucherItems[],
  fuel_request_voucher: FuelRequestVoucher
};

export type FuelIssueVoucherItems = {
    id?:number,
    description: string,
    unit: string,
    quantity: number,
    unit_price: number
}

export type FuelIssueVoucherStateTypes = {
  fetchAll: ApiCallState<FuelIssueVoucher[]>;
  fetchOne: ApiCallState<FuelIssueVoucher | {}>;
};

export const FuelIssueVoucherActionTypes = {
  FETCH_ALL_FUEL_ISSUE_VOUCHER: "FETCH_ALL_FUEL_ISSUE_VOUCHER",
  FETCH_ALL_FUEL_ISSUE_VOUCHER_RESET: "FETCH_ALL_FUEL_ISSUE_VOUCHER_RESET",
  FETCH_ALL_FUEL_ISSUE_VOUCHER_FAILURE: "FETCH_ALL_FUEL_ISSUE_VOUCHER_FAILURE",
  FETCH_ALL_FUEL_ISSUE_VOUCHER_SUCCESS: "FETCH_ALL_FUEL_ISSUE_VOUCHER_SUCCESS",

  FETCH_ONE_FUEL_ISSUE_VOUCHER: "FETCH_ONE_FUEL_ISSUE_VOUCHER",
  FETCH_ONE_FUEL_ISSUE_VOUCHER_RESET: "FETCH_ONE_FUEL_ISSUE_VOUCHER_RESET",
  FETCH_ONE_FUEL_ISSUE_VOUCHER_FAILURE: "FETCH_ONE_FUEL_ISSUE_VOUCHER_FAILURE",
  FETCH_ONE_FUEL_ISSUE_VOUCHER_SUCCESS: "FETCH_ONE_FUEL_ISSUE_VOUCHER_SUCCESS",
};
