import { Material } from "./../Material/Material.type";
import { ApiCallState, PagedData } from "../Utils";
import { User } from "../User/User.type";
import { FixedAssetIssueItem } from "../FixedAssetIssue/FixedAssetIssue.type";

export type FixedAssetTransfer = {
  id?: number;
  user_id: number;
  key: number;
  date: string;
  from_employee: string;
  receiving_employee: string;
  fixed_asset_transfer_items: FixedAssetTransferItem[];
  fixed_asset_transfer_statuses: FixedAssetTransferStatus[];
  status: FixedAssetTransferStatus[];
  is_void: boolean;
  approved_by: number;
  checked_by: number;
  fixed_asset_transfer_checked_by: User;
  fixed_asset_transfer_approved_by: User;
  fixed_asset_transfer_prepared_by: User;
};

export type FixedAssetTransferItem = {
  fixed_asset_transfer_id?: number;

  fixed_asset_issue_item_id?: number;
  quantity: number;
  fixed_asset_issue_item: FixedAssetIssueItem;
  key: number;
  id?: number;
  fixed_asset_transfer?: FixedAssetTransfer;
};

export type FixedAssetTransferStatus = {
  fixed_asset_transfer_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  remark: string;
  id: number;
};

export type FixedAssetTransferStateTypes = {
  fetchAll: ApiCallState<FixedAssetTransfer[]>;
  fetchOne: ApiCallState<FixedAssetTransfer | {}>;
  fetchPaged: ApiCallState<PagedData<FixedAssetTransfer[]>>;
};

export const FixedAssetTransferActionTypes = {
  FETCH_ALL_FIXED_ASSET_TRANSFER: "FETCH_ALL_FIXED_ASSET_TRANSFER",
  FETCH_ALL_FIXED_ASSET_TRANSFER_RESET: "FETCH_ALL_FIXED_ASSET_TRANSFER_RESET",
  FETCH_ALL_FIXED_ASSET_TRANSFER_FAILURE:
    "FETCH_ALL_FIXED_ASSET_TRANSFER_FAILURE",
  FETCH_ALL_FIXED_ASSET_TRANSFER_SUCCESS:
    "FETCH_ALL_FIXED_ASSET_TRANSFER_SUCCESS",

  FETCH_PAGED_FIXED_ASSET_TRANSFER: "FETCH_PAGED_FIXED_ASSET_TRANSFER",
  FETCH_PAGED_FIXED_ASSET_TRANSFER_RESET:
    "FETCH_PAGED_FIXED_ASSET_TRANSFER_RESET",
  FETCH_PAGED_FIXED_ASSET_TRANSFER_FAILURE:
    "FETCH_PAGED_FIXED_ASSET_TRANSFER_FAILURE",
  FETCH_PAGED_FIXED_ASSET_TRANSFER_SUCCESS:
    "FETCH_PAGED_FIXED_ASSET_TRANSFER_SUCCESS",

  FETCH_ONE_FIXED_ASSET_TRANSFER: "FETCH_ONE_FIXED_ASSET_TRANSFER",
  FETCH_ONE_FIXED_ASSET_TRANSFER_RESET: "FETCH_ONE_FIXED_ASSET_TRANSFER_RESET",
  FETCH_ONE_FIXED_ASSET_TRANSFER_FAILURE:
    "FETCH_ONE_FIXED_ASSET_TRANSFER_FAILURE",
  FETCH_ONE_FIXED_ASSET_TRANSFER_SUCCESS:
    "FETCH_ONE_FIXED_ASSET_TRANSFER_SUCCESS",
};
