import { DraftStateTypes, DraftActionTypes } from "./Draft.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: DraftStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const DraftReducer = (
  state: DraftStateTypes = INITIAL_STATE,
  action: any
): DraftStateTypes => {
  switch (action.type) {
    case DraftActionTypes.FETCH_ALL_DRAFT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case DraftActionTypes.FETCH_ALL_DRAFT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case DraftActionTypes.FETCH_ALL_DRAFT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case DraftActionTypes.FETCH_ALL_DRAFT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case DraftActionTypes.FETCH_ONE_DRAFT:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case DraftActionTypes.FETCH_ONE_DRAFT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState({}),
      };
    case DraftActionTypes.FETCH_ONE_DRAFT_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case DraftActionTypes.FETCH_ONE_DRAFT_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default DraftReducer;
