import {
  TravelRegistryStateTypes,
  TravelRegistryActionTypes,
} from "./TravelRegistry.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: TravelRegistryStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const TravelRegistryReducer = (
  state: TravelRegistryStateTypes = INITIAL_STATE,
  action: any
): TravelRegistryStateTypes => {
  switch (action.type) {
    case TravelRegistryActionTypes.FETCH_ALL_TRAVEL_REGISTRY:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case TravelRegistryActionTypes.FETCH_ALL_TRAVEL_REGISTRY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case TravelRegistryActionTypes.FETCH_ALL_TRAVEL_REGISTRY_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case TravelRegistryActionTypes.FETCH_ALL_TRAVEL_REGISTRY_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case TravelRegistryActionTypes.FETCH_ONE_TRAVEL_REGISTRY:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case TravelRegistryActionTypes.FETCH_ONE_TRAVEL_REGISTRY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case TravelRegistryActionTypes.FETCH_ONE_TRAVEL_REGISTRY_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case TravelRegistryActionTypes.FETCH_ONE_TRAVEL_REGISTRY_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default TravelRegistryReducer;
