import { Moment } from "moment";
import { ApiCallState } from "../Utils";

export type BoqActivityPlan = {
  project_id: number | null;
  id: number | null;
  super_title: string;
  title: string | null;
  sub_title: string | null;
  task_name: string;
  item_no: string;
  unit: string;
  quantity: number;
  unit_price: number;
  current_quantity: number;
  previous_quantity: number;
  total: number;
  sheet_name: string;
  boq_plans:
    | []
    | { quantity: number; date_type: string; date: Moment; id: number }[];
  boq_activity_plans: {
    quantity: number;
    date_type: string;
    date: Moment;
    id: number;
    description: string | null;
    length: number | null;
    side: string | number;
    location: string | null;
  }[];
};

export type BoqActivityPlanStateTypes = {
  fetchAll: ApiCallState<BoqActivityPlan[]>;
};

export type BoqActivityPlanActionType = {
  project_id: number;
  date: string;
  date_type: string;
};

export const BoqActivityPlanActionTypes = {
  FETCH_ALL_BOQ_ACTIVITY_PLAN: "FETCH_ALL_BOQ_ACTIVITY_PLAN",
  FETCH_ALL_BOQ_ACTIVITY_PLAN_RESET: "FETCH_ALL_BOQ_ACTIVITY_PLAN_RESET",
  FETCH_ALL_BOQ_ACTIVITY_PLAN_FAILURE: "FETCH_ALL_BOQ_ACTIVITY_PLAN_FAILURE",
  FETCH_ALL_BOQ_ACTIVITY_PLAN_SUCCESS: "FETCH_ALL_BOQ_ACTIVITY_PLAN_SUCCESS",
};
