import { ApiCallState } from "../Utils";

export type Summary= {
  id: number;
  project_id:number;
  contract_duration:string;
  signing_date:string;
  client:string;
  contractor:string;
  contract_period:string;
  user_id: number;
};

export type SummaryStateTypes = {
  fetchAll: ApiCallState<Summary[]>;
  fetchOne: ApiCallState<Summary | {}>;
};

export const SummaryActionTypes = {
  FETCH_ALL_SUMMARY: "FETCH_ALL_SUMMARY",
  FETCH_ALL_SUMMARY_RESET: "FETCH_ALL_SUMMARY_RESET",
  FETCH_ALL_SUMMARY_FAILURE: "FETCH_ALL_SUMMARY_FAILURE",
  FETCH_ALL_SUMMARY_SUCCESS: "FETCH_ALL_SUMMARY_SUCCESS",

  FETCH_ONE_SUMMARY: "FETCH_ONE_SUMMARY",
  FETCH_ONE_SUMMARY_RESET: "FETCH_ONE_SUMMARY_RESET",
  FETCH_ONE_SUMMARY_FAILURE: "FETCH_ONE_SUMMARY_FAILURE",
  FETCH_ONE_SUMMARY_SUCCESS: "FETCH_ONE_SUMMARY_SUCCESS",
};
