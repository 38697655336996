import { PlaceOfWork } from "../PlaceOfWork/PlaceOfWork.type";
import { ApiCallState } from "../Utils";

export type StaffPlaceOfWork = {
  id: number;

  user_id: number;
  staff_id: number;
  place_of_work_id: number;

  branch: string;
  date: string;
  reason: string;
  remark: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  place_of_work: PlaceOfWork;
};

export type StaffPlaceOfWorkStateTypes = {
  fetchAll: ApiCallState<StaffPlaceOfWork[]>;
};

export const StaffPlaceOfWorkActionTypes = {
  FETCH_ALL_STAFF_PLACE_OF_WORK: "FETCH_ALL_STAFF_PLACE_OF_WORK",
  FETCH_ALL_STAFF_PLACE_OF_WORK_RESET: "FETCH_ALL_STAFF_PLACE_OF_WORK_RESET",
  FETCH_ALL_STAFF_PLACE_OF_WORK_FAILURE:
    "FETCH_ALL_STAFF_PLACE_OF_WORK_FAILURE",
  FETCH_ALL_STAFF_PLACE_OF_WORK_SUCCESS:
    "FETCH_ALL_STAFF_PLACE_OF_WORK_SUCCESS",
};
