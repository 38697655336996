import { ApiCallState } from "../Utils";
import { Moment } from "moment";

export type BudgetRequest = {
  project_id: number | null;
  id: number | null;
  date: Moment;
  budget_month: string;
  budget_request: string;
  request_no:string;
  requested_by: string;
  checked_by: string;
  approved_by: string;
  certified_by: string;
  is_approved: boolean;
  is_checked: boolean;
  is_certified: boolean;
  total_request_amount: number;
  total_approved_amount:number;
  budget_request_details: BudgetRequestDetail[];
  budget_request_statuses: BriefingFormStatus[]
};

export type BriefingFormStatus = {
  id: number,
  user_id: number,
  status: number,
  type: string,
  assigned_by: string,
}

export type BudgetRequestDetail = {
  id: number | null;
  budget_request_id: number;
  description: string;
  monthly_budget?: string;
  unit: string;
  previous_rate: number;
  current_rate: number;
  pre_req_quantity: number;
  pre_req_amount: number;
  pre_approved_quantity: number;
  pre_approved_amount: number;
  current_req_quantity: number;
  current_req_amount: number;
  current_approved_quantity: number;
  current_approved_amount: number;
  todate_req_quantity: number;
  todate_approved_quantity: number;
  todate_req_amount: number;
  todate_approved_amount: number;
  remark: string;
};

export type BudgetRequestStateTypes = {
  fetchAll: ApiCallState<BudgetRequest[]>;
  fetchOne: ApiCallState<BudgetRequest | {}>;
};

export const BudgetRequestActionTypes = {
  FETCH_ALL_BUDGET_REQUEST: "FETCH_ALL_BUDGET_REQUEST",
  FETCH_ALL_BUDGET_REQUEST_RESET: "FETCH_ALL_BUDGET_REQUEST_RESET",
  FETCH_ALL_BUDGET_REQUEST_FAILURE: "FETCH_ALL_BUDGET_REQUEST_FAILURE",
  FETCH_ALL_BUDGET_REQUEST_SUCCESS: "FETCH_ALL_BUDGET_REQUEST_SUCCESS",

  FETCH_ONE_BUDGET_REQUEST: "FETCH_ONE_BUDGET_REQUEST",
  FETCH_ONE_BUDGET_REQUEST_RESET: "FETCH_ONE_BUDGET_REQUEST_RESET",
  FETCH_ONE_BUDGET_REQUEST_FAILURE: "FETCH_ONE_BUDGET_REQUEST_FAILURE",
  FETCH_ONE_BUDGET_REQUEST_SUCCESS: "FETCH_ONE_BUDGET_REQUEST_SUCCESS",
};
