import { ApiCallState } from "../../Utils";
import { BankLoanPaymentSchedule } from "../BankLoanPaymentSchedule/BankLoanPaymentSchedule.type";
import { Document } from "../../Document/Document.type";

export type BankLoan = {
  id: number;
  date: Date;
  description: string;
  bank: string;
  amount: number;
  interest_rate: number;
  payment_occurrence: string;
  payment_duration: number;
  payment_start: Date;
  bank_loan_payment_schedules?: BankLoanPaymentSchedule[];
  user_id: number;
  agreement_date?:Date;
  loan_starting_month?:Date;
  no_of_months?:number;
  monthly_payment_amount?:number;
  loan_type:string;
  yearly_amortization_amount:number;
  grace_period:number;
  loan_agreement:Document;
  wul:Document;
  collateral_agreement:Document;
};

export type BankLoanStateTypes = {
  fetchAll: ApiCallState<BankLoan[]>;
  fetchOne: ApiCallState<BankLoan | {}>;
};

export const BankLoanActionTypes = {
  FETCH_ALL_BANK_LOAN: "FETCH_ALL_BANK_LOAN",
  FETCH_ALL_BANK_LOAN_RESET: "FETCH_ALL_BANK_LOAN_RESET",
  FETCH_ALL_BANK_LOAN_FAILURE: "FETCH_ALL_BANK_LOAN_FAILURE",
  FETCH_ALL_BANK_LOAN_SUCCESS: "FETCH_ALL_BANK_LOAN_SUCCESS",

  FETCH_ONE_BANK_LOAN: "FETCH_ONE_BANK_LOAN",
  FETCH_ONE_BANK_LOAN_RESET: "FETCH_ONE_BANK_LOAN_RESET",
  FETCH_ONE_BANK_LOAN_FAILURE: "FETCH_ONE_BANK_LOAN_FAILURE",
  FETCH_ONE_BANK_LOAN_SUCCESS: "FETCH_ONE_BANK_LOAN_SUCCESS",
};
