import { ApiCallState } from "../Utils";

export type StaffReligion= {
  id: number;
  staff_id:number;
  religion:string;
  user_id:number;
};

export type StaffReligionStateTypes = {
  fetchAll: ApiCallState<StaffReligion[]>;
  fetchOne: ApiCallState<StaffReligion | {}>;
};

export const StaffReligionActionTypes = {
  FETCH_ALL_STAFF_RELIGION: "FETCH_ALL_STAFF_RELIGION",
  FETCH_ALL_STAFF_RELIGION_RESET: "FETCH_ALL_STAFF_RELIGION_RESET",
  FETCH_ALL_STAFF_RELIGION_FAILURE: "FETCH_ALL_STAFF_RELIGION_FAILURE",
  FETCH_ALL_STAFF_RELIGION_SUCCESS: "FETCH_ALL_STAFF_RELIGION_SUCCESS",

  FETCH_ONE_STAFF_RELIGION: "FETCH_ONE_STAFF_RELIGION",
  FETCH_ONE_STAFF_RELIGION_RESET: "FETCH_ONE_STAFF_RELIGION_RESET",
  FETCH_ONE_STAFF_RELIGION_FAILURE: "FETCH_ONE_STAFF_RELIGION_FAILURE",
  FETCH_ONE_STAFF_RELIGION_SUCCESS: "FETCH_ONE_STAFF_RELIGION_SUCCESS",
};
