import { ApiCallState } from "../Utils";

export type FuelUtilization = {
  id: number | null,
  description: string;
  plateNumber: string;
  supplierName: string;
  plannedHour: number;
  availableHours: number;
  utilizedHours: number;
  fuelConsumed: number;
  fuelConsumedInBirr: number;
  fuelConsumption: number;
  standardFuelConsumptionRate: number;
  efficiency: number;
  difference: number;
  lessQuantity: number;
  lessAmount: number;
  fuelUnitPrice: number;
  excessQuantity: number;
  excessAmount: number;
  remark: string;
  own_vehicle_id: number;
};

export type UpdatedFuelUtilizationReport = {
  id: number,
  start_date: string,
  end_date: string,
  report_date: string,
  prepared_by: string,
  items: FuelUtilization[]
};

export type FuelUtilizationReportStateTypes = {
  fetchAll: ApiCallState<UpdatedFuelUtilizationReport[]>;
  fetchReport: ApiCallState<FuelUtilization[]>;
};

export const FuelUtilizationReportActionTypes = {
  FETCH_ALL_FUEL_UTILIZATION_REPORT: "FETCH_ALL_FUEL_UTILIZATION_REPORT",
  FETCH_ALL_FUEL_UTILIZATION_REPORT_RESET: "FETCH_ALL_FUEL_UTILIZATION_REPORT_RESET",
  FETCH_ALL_FUEL_UTILIZATION_REPORT_FAILURE: "FETCH_ALL_FUEL_UTILIZATION_REPORT_FAILURE",
  FETCH_ALL_FUEL_UTILIZATION_REPORT_SUCCESS: "FETCH_ALL_FUEL_UTILIZATION_REPORT_SUCCESS",

  FETCH_FUEL_UTILIZATION_REPORT: "FETCH_FUEL_UTILIZATION_REPORT",
  FETCH_FUEL_UTILIZATION_REPORT_RESET: "FETCH_FUEL_UTILIZATION_REPORT_RESET",
  FETCH_FUEL_UTILIZATION_REPORT_FAILURE: "FETCH_FUEL_UTILIZATION_REPORT_FAILURE",
  FETCH_FUEL_UTILIZATION_REPORT_SUCCESS: "FETCH_FUEL_UTILIZATION_REPORT_SUCCESS",
};
