import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ManningCategoryActionTypes } from "./ManningCategory.type";

export function* fetchAllManningCategorys(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/manning-category`);
    yield put({
      type: ManningCategoryActionTypes.FETCH_ALL_MANNING_CATEGORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ManningCategoryActionTypes.FETCH_ALL_MANNING_CATEGORY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneManningCategorys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/manning-category/${action.payload}`
    );
    yield put({
      type: ManningCategoryActionTypes.FETCH_ONE_MANNING_CATEGORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ManningCategoryActionTypes.FETCH_ONE_MANNING_CATEGORY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllManningCategorys() {
  yield takeLatest(ManningCategoryActionTypes.FETCH_ALL_MANNING_CATEGORY, fetchAllManningCategorys);
}

export function* watcherFetchOneManningCategorys() {
  yield takeLatest(ManningCategoryActionTypes.FETCH_ONE_MANNING_CATEGORY, fetchOneManningCategorys);
}
