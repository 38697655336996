import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type FamilyAllotment = {
  id: number;

  user_id: number;

  staff_id: number;
  amount: number;
  date: string;
  staff: Staff;
};

export type FamilyAllotmentStateTypes = {
  fetchAll: ApiCallState<FamilyAllotment[]>;
  fetchOne: ApiCallState<FamilyAllotment | {}>;
};

export const FamilyAllotmentActionTypes = {
  FETCH_ALL_FAMILY_ALLOTMENT: "FETCH_ALL_FAMILY_ALLOTMENT",
  FETCH_ALL_FAMILY_ALLOTMENT_RESET: "FETCH_ALL_FAMILY_ALLOTMENT_RESET",
  FETCH_ALL_FAMILY_ALLOTMENT_FAILURE: "FETCH_ALL_FAMILY_ALLOTMENT_FAILURE",
  FETCH_ALL_FAMILY_ALLOTMENT_SUCCESS: "FETCH_ALL_FAMILY_ALLOTMENT_SUCCESS",

  FETCH_ONE_FAMILY_ALLOTMENT: "FETCH_ONE_FAMILY_ALLOTMENT",
  FETCH_ONE_FAMILY_ALLOTMENT_RESET: "FETCH_ONE_FAMILY_ALLOTMENT_RESET",
  FETCH_ONE_FAMILY_ALLOTMENT_FAILURE: "FETCH_ONE_FAMILY_ALLOTMENT_FAILURE",
  FETCH_ONE_FAMILY_ALLOTMENT_SUCCESS: "FETCH_ONE_FAMILY_ALLOTMENT_SUCCESS",
};
