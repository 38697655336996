import { ApiCallState } from "../Utils";

export type WeeklyProgressSummary= {
  id: number;
  date: string;
  remark: string;
  project_id: number;
};

export type WeeklyProgressSummaryStateTypes = {
  fetchAll: ApiCallState<WeeklyProgressSummary[]>;
  fetchOne: ApiCallState<WeeklyProgressSummary | {}>;
};

export const WeeklyProgressSummaryActionTypes = {
  FETCH_ALL_WEEKLY_PROGRESS_SUMMARY: "FETCH_ALL_WEEKLY_PROGRESS_SUMMARY",
  FETCH_ALL_WEEKLY_PROGRESS_SUMMARY_RESET: "FETCH_ALL_WEEKLY_PROGRESS_SUMMARY_RESET",
  FETCH_ALL_WEEKLY_PROGRESS_SUMMARY_FAILURE: "FETCH_ALL_WEEKLY_PROGRESS_SUMMARY_FAILURE",
  FETCH_ALL_WEEKLY_PROGRESS_SUMMARY_SUCCESS: "FETCH_ALL_WEEKLY_PROGRESS_SUMMARY_SUCCESS",

  FETCH_ONE_WEEKLY_PROGRESS_SUMMARY: "FETCH_ONE_WEEKLY_PROGRESS_SUMMARY",
  FETCH_ONE_WEEKLY_PROGRESS_SUMMARY_RESET: "FETCH_ONE_WEEKLY_PROGRESS_SUMMARY_RESET",
  FETCH_ONE_WEEKLY_PROGRESS_SUMMARY_FAILURE: "FETCH_ONE_WEEKLY_PROGRESS_SUMMARY_FAILURE",
  FETCH_ONE_WEEKLY_PROGRESS_SUMMARY_SUCCESS: "FETCH_ONE_WEEKLY_PROGRESS_SUMMARY_SUCCESS",
};
