import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SMSMessageActionTypes } from "./SMSMessage.type";

export function* fetchAllSMSMessages(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/sms-message`);
    yield put({
      type: SMSMessageActionTypes.FETCH_ALL_SMS_MESSAGE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SMSMessageActionTypes.FETCH_ALL_SMS_MESSAGE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSMSMessages(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/sms-message/${action.payload}`
    );
    yield put({
      type: SMSMessageActionTypes.FETCH_ONE_SMS_MESSAGE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SMSMessageActionTypes.FETCH_ONE_SMS_MESSAGE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSMSMessages() {
  yield takeLatest(
    SMSMessageActionTypes.FETCH_ALL_SMS_MESSAGE,
    fetchAllSMSMessages
  );
}

export function* watcherFetchOneSMSMessages() {
  yield takeLatest(
    SMSMessageActionTypes.FETCH_ONE_SMS_MESSAGE,
    fetchOneSMSMessages
  );
}
