import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SubContractAgreementActionTypes } from "./SubContractAgreement.type";

export function* fetchAllSubContractAgreements(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/sub-contract-agreement?project_id=${action.payload?.project_id}`);
    yield put({
      type: SubContractAgreementActionTypes.FETCH_ALL_SUB_CONTRACT_AGREEMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubContractAgreementActionTypes.FETCH_ALL_SUB_CONTRACT_AGREEMENT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSubContractAgreements(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/sub-contract-agreement/${action.payload}`
    );
    yield put({
      type: SubContractAgreementActionTypes.FETCH_ONE_SUB_CONTRACT_AGREEMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubContractAgreementActionTypes.FETCH_ONE_SUB_CONTRACT_AGREEMENT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSubContractAgreements() {
  yield takeLatest(SubContractAgreementActionTypes.FETCH_ALL_SUB_CONTRACT_AGREEMENT, fetchAllSubContractAgreements);
}

export function* watcherFetchOneSubContractAgreements() {
  yield takeLatest(SubContractAgreementActionTypes.FETCH_ONE_SUB_CONTRACT_AGREEMENT, fetchOneSubContractAgreements);
}
