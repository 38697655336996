import { ApiCallState } from "../Utils";
import { User } from "../User/User.type";
import { Document } from "../Document/Document.type";
import { MasterList } from "../MasterList/MasterList.type";
import { MaintenanceVoucher } from "../MaintenanceVoucher/MaintenanceVoucher.type";

export type AccidentFollowUp = {
  id: number;
  user_id: number;
  master_list_id: number;
  maintenance_voucher_id: number;

  plate_number: string;
  accident_type: string;
  accident_date: string;
  police_report_date: string;
  maintenance_order: string;

  master_list: MasterList;
  maintenance_voucher: MaintenanceVoucher;
  accident_follow_up_survey_reports: AccidentFollowUpSurveyReport[];
  accident_follow_up_proformas: AccidentFollowUpProforma[];
  accident_followUp_work_orders: AccidentFollowUpWorkOrder[];
  user: User;
};

export type AccidentFollowUpSurveyReport = {
  id: number;
  accident_follow_up_id: number;
  document_id: number;
  date: string;

  document: Document;
};

export type AccidentFollowUpProforma = {
  id: number;
  accident_follow_up_id: number;
  document_id: number;
  company_name: string;
  date: string;

  document: Document;
};

export type AccidentFollowUpWorkOrder = {
  id: number;
  accident_follow_up_id: number;
  document_id: number;
  company_name: string;
  date: string;

  document: Document;
};

export type AccidentFollowUpStateTypes = {
  fetchAll: ApiCallState<AccidentFollowUp[]>;
  fetchOne: ApiCallState<AccidentFollowUp | {}>;
};

export const AccidentFollowUpActionTypes = {
  FETCH_ALL_ACCIDENT_FOLLOW_UP: "FETCH_ALL_ACCIDENT_FOLLOW_UP",
  FETCH_ALL_ACCIDENT_FOLLOW_UP_RESET: "FETCH_ALL_ACCIDENT_FOLLOW_UP_RESET",
  FETCH_ALL_ACCIDENT_FOLLOW_UP_FAILURE: "FETCH_ALL_ACCIDENT_FOLLOW_UP_FAILURE",
  FETCH_ALL_ACCIDENT_FOLLOW_UP_SUCCESS: "FETCH_ALL_ACCIDENT_FOLLOW_UP_SUCCESS",

  FETCH_ONE_ACCIDENT_FOLLOW_UP: "FETCH_ONE_ACCIDENT_FOLLOW_UP",
  FETCH_ONE_ACCIDENT_FOLLOW_UP_RESET: "FETCH_ONE_ACCIDENT_FOLLOW_UP_RESET",
  FETCH_ONE_ACCIDENT_FOLLOW_UP_FAILURE: "FETCH_ONE_ACCIDENT_FOLLOW_UP_FAILURE",
  FETCH_ONE_ACCIDENT_FOLLOW_UP_SUCCESS: "FETCH_ONE_ACCIDENT_FOLLOW_UP_SUCCESS",
};
