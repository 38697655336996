import { MasterList } from "../MasterList/MasterList.type";
import { Project } from "../Project/Project.type";
import { ApiCallState } from "../Utils";

export type DailyWorkStatus = {
  id: number;
  user_id: number;

  project_id: number;
  prepared_by_id: number;
  approved_by_id: number;
  prepared_by_signature_id: number;
  approved_by_signature_id: number;

  prepared_by_date: string;
  approved_by_date: string;

  date: string;
  remark: string;

  project: Project;

  daily_equipment_work_statuses: DailyEquipmentWorkStatus[];
  daily_engine_work_statuses: DailyEngineWorkStatus[];
  daily_man_power_work_statuses: DailyManPowerWorkStatus[];
  daily_material_work_statuses: DailyMaterialWorkStatus[];
};

export type DailyEquipmentWorkStatus = {
  id: number;
  daily_work_status_id: number;

  activity_description: string;

  daily_equipment_work_status_items: DailyEquipmentWorkStatusItem[];
};

export type DailyEquipmentWorkStatusItem = {
  id: number;
  daily_equipment_work_status_id: number;

  master_list_id: number;
  plate_number: string;
  equipment_type: string;

  wh_crew_one_from: string;
  wh_crew_one_to: string;

  wh_crew_two_from: string;
  wh_crew_two_to: string;

  ih_dh: string;
  ih_dh_reason: string;

  crew_one_production: number;
  crew_two_production: number;

  fuel_per_litter: number;
  fuel_issue_hour: number;

  remark: string;

  master_list: MasterList;
};

export type DailyEngineWorkStatus = {
  id: number;
  daily_work_status_id: number;

  master_list_id: number;
  plate_number: string;
  equipment_type: string;

  oil_and_lubricant: number;
  engine_hour_start: number;
  engine_hour_end: number;
  master_list: MasterList;
};

export type DailyManPowerWorkStatus = {
  id: number;

  daily_work_status_id: number;
  profession: string;
  number: number;
};

export type DailyMaterialWorkStatus = {
  id: number;
  daily_work_status_id: number;

  material_type: string;
  balance: number;
  taken_from_dce: number;
};

export type DailyWorkStatusStateTypes = {
  fetchAll: ApiCallState<DailyWorkStatus[]>;
  fetchOne: ApiCallState<DailyWorkStatus | {}>;
};

export const DailyWorkStatusActionTypes = {
  FETCH_ALL_DAILY_WORK_STATUS: "FETCH_ALL_DAILY_WORK_STATUS",
  FETCH_ALL_DAILY_WORK_STATUS_RESET: "FETCH_ALL_DAILY_WORK_STATUS_RESET",
  FETCH_ALL_DAILY_WORK_STATUS_FAILURE: "FETCH_ALL_DAILY_WORK_STATUS_FAILURE",
  FETCH_ALL_DAILY_WORK_STATUS_SUCCESS: "FETCH_ALL_DAILY_WORK_STATUS_SUCCESS",

  FETCH_ONE_DAILY_WORK_STATUS: "FETCH_ONE_DAILY_WORK_STATUS",
  FETCH_ONE_DAILY_WORK_STATUS_RESET: "FETCH_ONE_DAILY_WORK_STATUS_RESET",
  FETCH_ONE_DAILY_WORK_STATUS_FAILURE: "FETCH_ONE_DAILY_WORK_STATUS_FAILURE",
  FETCH_ONE_DAILY_WORK_STATUS_SUCCESS: "FETCH_ONE_DAILY_WORK_STATUS_SUCCESS",
};
