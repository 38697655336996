import { Moment } from "moment";
import { AggregateTakeoff, RoadTakeoff } from "../Boq/Boq.type";
import { Rebar } from "../Rebar/Rebar.type";
import { Takeoff } from "../Takeoff/Takeoff.type";
import { ApiCallState } from "../Utils";

export type BoqPlan = {
  project_id: number | null;
  id: number | null;
  super_title: string;
  title: string | null;
  sub_title: string | null;
  task_name: string;
  item_no: string;
  unit: string;
  quantity: number;
  unit_price: number;
  current_quantity: number;
  previous_quantity: number;
  total: number;
  sheet_name: string;
  boq_plans:
    | []
    | { quantity: number; date_type: string; date: Moment; id: number }[];
  take_offs: Takeoff[] | [];
  rebars: Rebar[] | [];
  aggregate_takeoff: AggregateTakeoff | null;
  road_take_offs: RoadTakeoff[] | [];
};

export type BoqPlanStateTypes = {
  fetchAll: ApiCallState<BoqPlan[]>;
};

export type BoqPlanActionType = {
  project_id: number;
  date: string;
  date_type: string;
};

export const BoqPlanActionTypes = {
  FETCH_ALL_BOQ_PLAN: "FETCH_ALL_BOQ_PLAN",
  FETCH_ALL_BOQ_PLAN_RESET: "FETCH_ALL_BOQ_PLAN_RESET",
  FETCH_ALL_BOQ_PLAN_FAILURE: "FETCH_ALL_BOQ_PLAN_FAILURE",
  FETCH_ALL_BOQ_PLAN_SUCCESS: "FETCH_ALL_BOQ_PLAN_SUCCESS",
};
