import { ApiCallState } from "../Utils";

export type MasterSchedule = {
  id: any;
  task_name: string;
  start_date: string;
  end_date: string;
  finished_date: string | null;
  duration: number;
  progress: number;
  is_done: boolean;
  is_title: boolean;
  dependency_id: number | null;
  parent_id: number | null;
  key: number;
  current_quantity: number;
  children?: MasterSchedule[];
  assigned_users: AssignedUser[];
  dependant_on: MasterSchedule | null;
  outline_level: number | null;
  project_id: number;
  unit: string;
  unit_price: number;
  quantity: number;
  amount: number;

  master_schedule_reports: MasterScheduleReport[];
  user_id: any;
};

export type AssignedUser = {
  full_name: string;
  role: string;
  access_type: string;
  id: number;
  master_schedule_user: {
    id: number;
    action: string;
    approved: boolean;
  };
};

export type MasterScheduleReport = {
  id: any;
  description: string;
  quantity: number;
  approval: number;
  date: string;
  private: boolean;
  user_id: number;
};

export type MasterScheduleResource = {
  name: string;
  unit: string;
  quantity: number;
  unit_price: number;
  amount: number;
};

export type MasterScheduleStateTypes = {
  fetchAll: ApiCallState<MasterSchedule[]>;
  fetchOne: ApiCallState<MasterSchedule | {}>;
};

export const MasterScheduleActionTypes = {
  FETCH_ALL_MASTER_SCHEDULE: "FETCH_ALL_MASTER_SCHEDULE",
  FETCH_ALL_MASTER_SCHEDULE_RESET: "FETCH_ALL_MASTER_SCHEDULE_RESET",
  FETCH_ALL_MASTER_SCHEDULE_FAILURE: "FETCH_ALL_MASTER_SCHEDULE_FAILURE",
  FETCH_ALL_MASTER_SCHEDULE_SUCCESS: "FETCH_ALL_MASTER_SCHEDULE_SUCCESS",

  FETCH_ONE_MASTER_SCHEDULE: "FETCH_ONE_MASTER_SCHEDULE",
  FETCH_ONE_MASTER_SCHEDULE_RESET: "FETCH_ONE_MASTER_SCHEDULE_RESET",
  FETCH_ONE_MASTER_SCHEDULE_FAILURE: "FETCH_ONE_MASTER_SCHEDULE_FAILURE",
  FETCH_ONE_MASTER_SCHEDULE_SUCCESS: "FETCH_ONE_MASTER_SCHEDULE_SUCCESS",
};
