import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { AttendanceActionTypes } from "./Attendance.type";

export function* fetchAllAttendances(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/attendance/range?start_date=${action.payload.start_date}&end_date=${action.payload.end_date}`
    );
    yield put({
      type: AttendanceActionTypes.FETCH_ALL_ATTENDANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AttendanceActionTypes.FETCH_ALL_ATTENDANCE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllAttendances() {
  yield takeLatest(
    AttendanceActionTypes.FETCH_ALL_ATTENDANCE,
    fetchAllAttendances
  );
}
