import { Material } from "../Material/Material.type";
import { ApiCallState } from "../Utils";

export type MaterialLimit= {
  id: number;
  project_id:number;
  material_id:number;
  quantity_limit:number;
  alert_quantity:number;
  alert_user:string;
  user_id:number;
  material:Material
};

export type MaterialLimitStateTypes = {
  fetchAll: ApiCallState<MaterialLimit[]>;
  fetchOne: ApiCallState<MaterialLimit | {}>;
};

export const MaterialLimitActionTypes = {
  FETCH_ALL_MATERIAL_LIMIT: "FETCH_ALL_MATERIAL_LIMIT",
  FETCH_ALL_MATERIAL_LIMIT_RESET: "FETCH_ALL_MATERIAL_LIMIT_RESET",
  FETCH_ALL_MATERIAL_LIMIT_FAILURE: "FETCH_ALL_MATERIAL_LIMIT_FAILURE",
  FETCH_ALL_MATERIAL_LIMIT_SUCCESS: "FETCH_ALL_MATERIAL_LIMIT_SUCCESS",

  FETCH_ONE_MATERIAL_LIMIT: "FETCH_ONE_MATERIAL_LIMIT",
  FETCH_ONE_MATERIAL_LIMIT_RESET: "FETCH_ONE_MATERIAL_LIMIT_RESET",
  FETCH_ONE_MATERIAL_LIMIT_FAILURE: "FETCH_ONE_MATERIAL_LIMIT_FAILURE",
  FETCH_ONE_MATERIAL_LIMIT_SUCCESS: "FETCH_ONE_MATERIAL_LIMIT_SUCCESS",
};
