import { ApiCallState } from "../Utils";

export type LaboratoryEquipmentCost = {
  id: number;
  start_date: string;
  end_date: string;
  project_id: number;
  user_id: number;

  laboratory_equipment_cost_items: LaboratoryEquipmentCostItem[];
  laboratory_equipment_cost_others: LaboratoryEquipmentCostOther[];
};

export type LaboratoryEquipmentCostItem = {
  id: number;
  description: string;
  unit: string;
  quantity: number;
  delivery_date: string;
  purchasing_per_unit: number;
  previous_cost: number;
  this_period_cost: number;
  to_date_cost: number;
  laboratory_equipment_cost_id: number;
};

export type LaboratoryEquipmentCostOther = {
  id: number;
  description: string;
  unit: string;
  unit_price: number;
  previous_quantity: number;
  previous_amount: number;
  this_period_quantity: number;
  this_period_amount: number;
  up_to_date_quantity: number;
  up_to_date_amount: number;
  laboratory_equipment_cost_id: number;
};

export type LaboratoryEquipmentCostStateTypes = {
  fetchAll: ApiCallState<LaboratoryEquipmentCost[]>;
  fetchOne: ApiCallState<LaboratoryEquipmentCost | {}>;
};

export const LaboratoryEquipmentCostActionTypes = {
  FETCH_ALL_LABORATORY_EQUIPMENT_COST: "FETCH_ALL_LABORATORY_EQUIPMENT_COST",
  FETCH_ALL_LABORATORY_EQUIPMENT_COST_RESET:
    "FETCH_ALL_LABORATORY_EQUIPMENT_COST_RESET",
  FETCH_ALL_LABORATORY_EQUIPMENT_COST_FAILURE:
    "FETCH_ALL_LABORATORY_EQUIPMENT_COST_FAILURE",
  FETCH_ALL_LABORATORY_EQUIPMENT_COST_SUCCESS:
    "FETCH_ALL_LABORATORY_EQUIPMENT_COST_SUCCESS",

  FETCH_ONE_LABORATORY_EQUIPMENT_COST: "FETCH_ONE_LABORATORY_EQUIPMENT_COST",
  FETCH_ONE_LABORATORY_EQUIPMENT_COST_RESET:
    "FETCH_ONE_LABORATORY_EQUIPMENT_COST_RESET",
  FETCH_ONE_LABORATORY_EQUIPMENT_COST_FAILURE:
    "FETCH_ONE_LABORATORY_EQUIPMENT_COST_FAILURE",
  FETCH_ONE_LABORATORY_EQUIPMENT_COST_SUCCESS:
    "FETCH_ONE_LABORATORY_EQUIPMENT_COST_SUCCESS",
};
