import { ApiCallState } from "../Utils";

export type DailyTask = {
  id: number;
  user_id: number;

  date: string;

  readonly createdAt?: Date;
  readonly updatedAt?: Date;

  daily_task_items: DailyTaskItem[];
};

export type DailyTaskItem = {
  id: number;
  daily_task_id: number;

  title: string;
  name: string;
  date: string;
  time: string;
  description: string;
  contact_person: string;
  deliberated: string;

  readonly createdAt?: Date;
  readonly updatedAt?: Date;
};

export type DailyTaskStateTypes = {
  fetchAll: ApiCallState<DailyTask[]>;
  fetchOne: ApiCallState<DailyTask | {}>;
};

export const DailyTaskActionTypes = {
  FETCH_ALL_DAILY_TASK: "FETCH_ALL_DAILY_TASK",
  FETCH_ALL_DAILY_TASK_RESET: "FETCH_ALL_DAILY_TASK_RESET",
  FETCH_ALL_DAILY_TASK_FAILURE: "FETCH_ALL_DAILY_TASK_FAILURE",
  FETCH_ALL_DAILY_TASK_SUCCESS: "FETCH_ALL_DAILY_TASK_SUCCESS",

  FETCH_ONE_DAILY_TASK: "FETCH_ONE_DAILY_TASK",
  FETCH_ONE_DAILY_TASK_RESET: "FETCH_ONE_DAILY_TASK_RESET",
  FETCH_ONE_DAILY_TASK_FAILURE: "FETCH_ONE_DAILY_TASK_FAILURE",
  FETCH_ONE_DAILY_TASK_SUCCESS: "FETCH_ONE_DAILY_TASK_SUCCESS",
};
