import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";
import { formatQuery } from "../Utils";
import { API_BASE_URI } from "../ApiCall";
import { FixedAssetReturnActionTypes } from "./FixedAssetReturn.type";

export function* fetchAllFixedAssetReturn(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/fixed-asset-return?${formatQuery(action)}`
    );
    yield put({
      type: FixedAssetReturnActionTypes.FETCH_ALL_FIXED_ASSET_RETURN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FixedAssetReturnActionTypes.FETCH_ALL_FIXED_ASSET_RETURN_FAILURE,
      payload: error,
    });
  }
}

export function* fetchReportFixedAssetReturn(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/fixed-asset-return/report?${formatQuery(action)}`
    );
    yield put({
      type: FixedAssetReturnActionTypes.FETCH_REPORT_FIXED_ASSET_RETURN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FixedAssetReturnActionTypes.FETCH_REPORT_FIXED_ASSET_RETURN_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllFixedAssetReturn() {
  yield takeLatest(
    FixedAssetReturnActionTypes.FETCH_ALL_FIXED_ASSET_RETURN,
    fetchAllFixedAssetReturn
  );
}

export function* watcherFetchReportFixedAssetReturn() {
  yield takeLatest(
    FixedAssetReturnActionTypes.FETCH_REPORT_FIXED_ASSET_RETURN,
    fetchReportFixedAssetReturn
  );
}
