import { ApiCallState } from "../Utils";

export type Discipline = {
  id: number;

  user_id: number;
  staff_ids: number[];

  date: Date;
  type: string;
  performance: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type DisciplineStateTypes = {
  fetchAll: ApiCallState<Discipline[]>;
  fetchOne: ApiCallState<Discipline | {}>;
};

export const DisciplineActionTypes = {
  FETCH_ALL_DISCIPLINE: "FETCH_ALL_DISCIPLINE",
  FETCH_ALL_DISCIPLINE_RESET: "FETCH_ALL_DISCIPLINE_RESET",
  FETCH_ALL_DISCIPLINE_FAILURE: "FETCH_ALL_DISCIPLINE_FAILURE",
  FETCH_ALL_DISCIPLINE_SUCCESS: "FETCH_ALL_DISCIPLINE_SUCCESS",

  FETCH_ONE_DISCIPLINE: "FETCH_ONE_DISCIPLINE",
  FETCH_ONE_DISCIPLINE_RESET: "FETCH_ONE_DISCIPLINE_RESET",
  FETCH_ONE_DISCIPLINE_FAILURE: "FETCH_ONE_DISCIPLINE_FAILURE",
  FETCH_ONE_DISCIPLINE_SUCCESS: "FETCH_ONE_DISCIPLINE_SUCCESS",
};
