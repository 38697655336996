import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type EarlyLeaveRequest = {
  id: number;
  user_id: number;
  staff_id: number;

  approved_by_id: number;

  date: string;
  time: string;
  request: string;

  is_approved: boolean;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  staff: Staff;
};

export type EarlyLeaveRequestStateTypes = {
  fetchAll: ApiCallState<EarlyLeaveRequest[]>;
  fetchOne: ApiCallState<EarlyLeaveRequest | {}>;
};

export const EarlyLeaveRequestActionTypes = {
  FETCH_ALL_EARLY_LEAVE_REQUEST: "FETCH_ALL_EARLY_LEAVE_REQUEST",
  FETCH_ALL_EARLY_LEAVE_REQUEST_RESET: "FETCH_ALL_EARLY_LEAVE_REQUEST_RESET",
  FETCH_ALL_EARLY_LEAVE_REQUEST_FAILURE:
    "FETCH_ALL_EARLY_LEAVE_REQUEST_FAILURE",
  FETCH_ALL_EARLY_LEAVE_REQUEST_SUCCESS:
    "FETCH_ALL_EARLY_LEAVE_REQUEST_SUCCESS",

  FETCH_ONE_EARLY_LEAVE_REQUEST: "FETCH_ONE_EARLY_LEAVE_REQUEST",
  FETCH_ONE_EARLY_LEAVE_REQUEST_RESET: "FETCH_ONE_EARLY_LEAVE_REQUEST_RESET",
  FETCH_ONE_EARLY_LEAVE_REQUEST_FAILURE:
    "FETCH_ONE_EARLY_LEAVE_REQUEST_FAILURE",
  FETCH_ONE_EARLY_LEAVE_REQUEST_SUCCESS:
    "FETCH_ONE_EARLY_LEAVE_REQUEST_SUCCESS",
};
