import { ApiCallState } from "../Utils";

export type DepartmentRelationShip = {
  id: number;
  title: string;
  department_id: number;
  reports_to: string;
  createdAt?: Date;
  updateddAt?: Date;
};

export type DepartmentRelationShipStateTypes = {
  fetchAll: ApiCallState<{count: number, rows: DepartmentRelationShip[]}>;
  fetchOne: ApiCallState<DepartmentRelationShip | {}>;
};

export const DepartmentRelationShipActionTypes = {
  FETCH_ALL_DEPARTMENT_RELATION_SHIP: "FETCH_ALL_DEPARTMENT_RELATION_SHIP",
  FETCH_ALL_DEPARTMENT_RELATION_SHIP_RESET: "FETCH_ALL_DEPARTMENT_RELATION_SHIP_RESET",
  FETCH_ALL_DEPARTMENT_RELATION_SHIP_FAILURE: "FETCH_ALL_DEPARTMENT_RELATION_SHIP_FAILURE",
  FETCH_ALL_DEPARTMENT_RELATION_SHIP_SUCCESS: "FETCH_ALL_DEPARTMENT_RELATION_SHIP_SUCCESS",

  FETCH_ONE_DEPARTMENT_RELATION_SHIP: "FETCH_ONE_DEPARTMENT_RELATION_SHIP",
  FETCH_ONE_DEPARTMENT_RELATION_SHIP_RESET: "FETCH_ONE_DEPARTMENT_RELATION_SHIP_RESET",
  FETCH_ONE_DEPARTMENT_RELATION_SHIP_FAILURE: "FETCH_ONE_DEPARTMENT_RELATION_SHIP_FAILURE",
  FETCH_ONE_DEPARTMENT_RELATION_SHIP_SUCCESS: "FETCH_ONE_DEPARTMENT_RELATION_SHIP_SUCCESS",
};
