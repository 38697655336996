import { ApiCallState } from "../Utils";

export type DailySiteReport= {
    id: number;
    date: string;
    project_id: number;
    user_id: number;
};

export type DailySiteReportStateTypes = {
  fetchAll: ApiCallState<DailySiteReport[]>;
  fetchOne: ApiCallState<DailySiteReport | {}>;
};

export const DailySiteReportActionTypes = {
  FETCH_ALL_DAILY_SITE_REPORT: "FETCH_ALL_DAILY_SITE_REPORT",
  FETCH_ALL_DAILY_SITE_REPORT_RESET: "FETCH_ALL_DAILY_SITE_REPORT_RESET",
  FETCH_ALL_DAILY_SITE_REPORT_FAILURE: "FETCH_ALL_DAILY_SITE_REPORT_FAILURE",
  FETCH_ALL_DAILY_SITE_REPORT_SUCCESS: "FETCH_ALL_DAILY_SITE_REPORT_SUCCESS",

  FETCH_ONE_DAILY_SITE_REPORT: "FETCH_ONE_DAILY_SITE_REPORT",
  FETCH_ONE_DAILY_SITE_REPORT_RESET: "FETCH_ONE_DAILY_SITE_REPORT_RESET",
  FETCH_ONE_DAILY_SITE_REPORT_FAILURE: "FETCH_ONE_DAILY_SITE_REPORT_FAILURE",
  FETCH_ONE_DAILY_SITE_REPORT_SUCCESS: "FETCH_ONE_DAILY_SITE_REPORT_SUCCESS",
};
