import { PlaceOfWork } from "../PlaceOfWork/PlaceOfWork.type";
import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type Overtime = {
  id: number;
  user_id: number;

  date: Date;
  year: string;
  month: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  overtime_items: OvertimeItem[];
};

export type OvertimeItem = {
  id: number;
  overtime_id: number;
  staff_id: number;
  place_of_work_id: number;

  normal_hour: number;
  night_hour: number;
  sunday_hour: number;
  holiday_hour: number;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  staff: Staff;
  place_of_work: PlaceOfWork;
};

export type OvertimeStateTypes = {
  fetchAll: ApiCallState<Overtime[]>;
};

export const OvertimeActionTypes = {
  FETCH_ALL_OVERTIME: "FETCH_ALL_OVERTIME",
  FETCH_ALL_OVERTIME_RESET: "FETCH_ALL_OVERTIME_RESET",
  FETCH_ALL_OVERTIME_FAILURE: "FETCH_ALL_OVERTIME_FAILURE",
  FETCH_ALL_OVERTIME_SUCCESS: "FETCH_ALL_OVERTIME_SUCCESS",
};
