import { ApiCallState } from "../Utils";
import { Moment } from "moment";

export type BudgetUtilization= {
    project_id: number | null;
    id: number | null;
    date: Moment;
    request_no:string;
    prepared_by: string;
    checked_by: string;
    approved_by: string;
    is_approved: boolean;
    is_checked: boolean;
    approved_amount: number;
    utilized_amount:number;
    budget_utilization_details: BudgetUtilizationDetail[];
};

export type  BudgetUtilizationDetail = {
    id: number | null;
    budget_utilization_id:number | null;
    description:string;
    unit:string;
    previous_rate:number;
    current_rate:number;
    approved_req_quantity:number;
    approved_req_amount:number;
    utilized_rate:number;
    utilized_quantity:number;
    utilized_amount:number;
    grv_no:string;
    supplier:string;
    vehicle:string;
    next_rate:number;
    next_quantity:number;
    next_amount:number;
    variance_amount:number;
    remark:string;
}

export type BudgetUtilizationStateTypes = {
  fetchAll: ApiCallState<BudgetUtilization[]>;
  fetchOne: ApiCallState<BudgetUtilization | {}>;
};

export const BudgetUtilizationActionTypes = {
  FETCH_ALL_BUDGET_UTILIZATION: "FETCH_ALL_BUDGET_UTILIZATION",
  FETCH_ALL_BUDGET_UTILIZATION_RESET: "FETCH_ALL_BUDGET_UTILIZATION_RESET",
  FETCH_ALL_BUDGET_UTILIZATION_FAILURE: "FETCH_ALL_BUDGET_UTILIZATION_FAILURE",
  FETCH_ALL_BUDGET_UTILIZATION_SUCCESS: "FETCH_ALL_BUDGET_UTILIZATION_SUCCESS",

  FETCH_ONE_BUDGET_UTILIZATION: "FETCH_ONE_BUDGET_UTILIZATION",
  FETCH_ONE_BUDGET_UTILIZATION_RESET: "FETCH_ONE_BUDGET_UTILIZATION_RESET",
  FETCH_ONE_BUDGET_UTILIZATION_FAILURE: "FETCH_ONE_BUDGET_UTILIZATION_FAILURE",
  FETCH_ONE_BUDGET_UTILIZATION_SUCCESS: "FETCH_ONE_BUDGET_UTILIZATION_SUCCESS",
};
