import {
  OvertimeRequest2StateTypes,
  OvertimeRequest2ActionTypes,
} from "./OvertimeRequest2.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: OvertimeRequest2StateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const OvertimeRequest2Reducer = (
  state: OvertimeRequest2StateTypes = INITIAL_STATE,
  action: any
): OvertimeRequest2StateTypes => {
  switch (action.type) {
    case OvertimeRequest2ActionTypes.FETCH_ALL_OVERTIME_REQUEST2:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case OvertimeRequest2ActionTypes.FETCH_ALL_OVERTIME_REQUEST2_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case OvertimeRequest2ActionTypes.FETCH_ALL_OVERTIME_REQUEST2_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case OvertimeRequest2ActionTypes.FETCH_ALL_OVERTIME_REQUEST2_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case OvertimeRequest2ActionTypes.FETCH_ONE_OVERTIME_REQUEST2:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case OvertimeRequest2ActionTypes.FETCH_ONE_OVERTIME_REQUEST2_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState({}),
      };
    case OvertimeRequest2ActionTypes.FETCH_ONE_OVERTIME_REQUEST2_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case OvertimeRequest2ActionTypes.FETCH_ONE_OVERTIME_REQUEST2_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default OvertimeRequest2Reducer;
