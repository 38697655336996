import { Document } from "../Document/Document.type";
import { Material } from "../Material/Material.type";
import { Store } from "../Store/Store.type";
import { User } from "../User/User.type";
import { ApiCallState, PagedData } from "../Utils";

export type ManufacturingOrder = {
  id: number;
  user_id: number;
  project_id: number;
  from_id: number;
  to_id: number;
  date: string;

  checked_by_id: number;
  approved_by_id: number;

  project: Store;
  from: Store;
  to: Store;

  manufacturing_order_remarks: ManufacturingOrderRemark[];
  manufacturing_order_statuses: ManufacturingOrderStatus[];
  status: ManufacturingOrderStatus[];

  prepared_by: User;
  checked_by: User;
  approved_by: User;

  manufacturing_order_items: ManufacturingOrderItem[];

  readonly createdAt: Date;
  readonly updatedAt: Date;

  is_void: boolean;
};

export type ManufacturingOrderReport = {
  approved_by?: any;
  code: string;
  date: string;
  description: string;
  from: string;
  to: string;
  item_category: string;
  id: number;
  major_category: string;
  material_requisition_id: string;
  pad_ref?: any;
  prepared_by: string;
  quantity: number;
  received_by?: any;
  remark: string;
  total: number;
  unit: string;
};

export type ManufacturingOrderRemark = {
  time: string;
  remark: string;
  user_id: number;
  document?: Document;
};

export type ManufacturingOrderStatus = {
  manufacturing_order_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type ManufacturingOrderItem = {
  id: number;
  manufacturing_order_id: number;
  material_id: number;

  identification: string;
  unit: string;
  quantity: number;
  remark: string;

  material: Material;
  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type ManufacturingOrderStateTypes = {
  fetchAll: ApiCallState<ManufacturingOrder[]>;
  fetchOne: ApiCallState<ManufacturingOrder | {}>;
  fetchPaged: ApiCallState<PagedData<ManufacturingOrder[]>>;
  fetchReport: ApiCallState<ManufacturingOrderReport[]>;
};

export const ManufacturingOrderActionTypes = {
  FETCH_ALL_MANUFACTURING_ORDER: "FETCH_ALL_MANUFACTURING_ORDER",
  FETCH_ALL_MANUFACTURING_ORDER_RESET: "FETCH_ALL_MANUFACTURING_ORDER_RESET",
  FETCH_ALL_MANUFACTURING_ORDER_FAILURE:
    "FETCH_ALL_MANUFACTURING_ORDER_FAILURE",
  FETCH_ALL_MANUFACTURING_ORDER_SUCCESS:
    "FETCH_ALL_MANUFACTURING_ORDER_SUCCESS",

  FETCH_PAGED_MANUFACTURING_ORDER: "FETCH_PAGED_MANUFACTURING_ORDER",
  FETCH_PAGED_MANUFACTURING_ORDER_RESET:
    "FETCH_PAGED_MANUFACTURING_ORDER_RESET",
  FETCH_PAGED_MANUFACTURING_ORDER_FAILURE:
    "FETCH_PAGED_MANUFACTURING_ORDER_FAILURE",
  FETCH_PAGED_MANUFACTURING_ORDER_SUCCESS:
    "FETCH_PAGED_MANUFACTURING_ORDER_SUCCESS",

  FETCH_ONE_MANUFACTURING_ORDER: "FETCH_ONE_MANUFACTURING_ORDER",
  FETCH_ONE_MANUFACTURING_ORDER_RESET: "FETCH_ONE_MANUFACTURING_ORDER_RESET",
  FETCH_ONE_MANUFACTURING_ORDER_FAILURE:
    "FETCH_ONE_MANUFACTURING_ORDER_FAILURE",
  FETCH_ONE_MANUFACTURING_ORDER_SUCCESS:
    "FETCH_ONE_MANUFACTURING_ORDER_SUCCESS",

  FETCH_REPORT_MANUFACTURING_ORDER: "FETCH_REPORT_MANUFACTURING_ORDER",
  FETCH_REPORT_MANUFACTURING_ORDER_RESET:
    "FETCH_REPORT_MANUFACTURING_ORDER_RESET",
  FETCH_REPORT_MANUFACTURING_ORDER_FAILURE:
    "FETCH_REPORT_MANUFACTURING_ORDER_FAILURE",
  FETCH_REPORT_MANUFACTURING_ORDER_SUCCESS:
    "FETCH_REPORT_MANUFACTURING_ORDER_SUCCESS",
};
