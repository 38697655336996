import { ApiCallState } from "../Utils";

export type MaterialDailySiteReport = {
  id: number;
  date: string;
  project_id: number;
  daily_site_report_id: number;
  material_daily_site_report_items: MaterialDailySiteReportItem[]
};

export type MaterialDailySiteReportItem = {
  id: number;
  begining_balance: number;
  delivered_quantity: number;
  description: string;
  ending_balance: number;
  for: string;
  issued_amount: number;
  issued_quantity: number;
  remark: string;
  type: string;
  unit: string;
  unit_price: number;
};

export type MaterialDailySiteReportStateTypes = {
  fetchAll: ApiCallState<MaterialDailySiteReport[]>;
  fetchOne: ApiCallState<MaterialDailySiteReport | {}>;
};

export const MaterialDailySiteReportActionTypes = {
  FETCH_ALL_MATERIAL_DAILY_SITE_REPORT: "FETCH_ALL_MATERIAL_DAILY_SITE_REPORT",
  FETCH_ALL_MATERIAL_DAILY_SITE_REPORT_RESET:
    "FETCH_ALL_MATERIAL_DAILY_SITE_REPORT_RESET",
  FETCH_ALL_MATERIAL_DAILY_SITE_REPORT_FAILURE:
    "FETCH_ALL_MATERIAL_DAILY_SITE_REPORT_FAILURE",
  FETCH_ALL_MATERIAL_DAILY_SITE_REPORT_SUCCESS:
    "FETCH_ALL_MATERIAL_DAILY_SITE_REPORT_SUCCESS",

  FETCH_ONE_MATERIAL_DAILY_SITE_REPORT: "FETCH_ONE_MATERIAL_DAILY_SITE_REPORT",
  FETCH_ONE_MATERIAL_DAILY_SITE_REPORT_RESET:
    "FETCH_ONE_MATERIAL_DAILY_SITE_REPORT_RESET",
  FETCH_ONE_MATERIAL_DAILY_SITE_REPORT_FAILURE:
    "FETCH_ONE_MATERIAL_DAILY_SITE_REPORT_FAILURE",
  FETCH_ONE_MATERIAL_DAILY_SITE_REPORT_SUCCESS:
    "FETCH_ONE_MATERIAL_DAILY_SITE_REPORT_SUCCESS",
};
