import { CriticalIssueStateTypes, CriticalIssueActionTypes } from "./CriticalIssue.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: CriticalIssueStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const CriticalIssueReducer = (
  state: CriticalIssueStateTypes = INITIAL_STATE,
  action: any
): CriticalIssueStateTypes => {
  switch (action.type) {
    case CriticalIssueActionTypes.FETCH_ALL_CRITICAL_ISSUE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case CriticalIssueActionTypes.FETCH_ALL_CRITICAL_ISSUE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case CriticalIssueActionTypes.FETCH_ALL_CRITICAL_ISSUE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case CriticalIssueActionTypes.FETCH_ALL_CRITICAL_ISSUE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case CriticalIssueActionTypes.FETCH_ONE_CRITICAL_ISSUE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case CriticalIssueActionTypes.FETCH_ONE_CRITICAL_ISSUE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case CriticalIssueActionTypes.FETCH_ONE_CRITICAL_ISSUE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case CriticalIssueActionTypes.FETCH_ONE_CRITICAL_ISSUE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default CriticalIssueReducer;
