import { Material } from "../Material/Material.type";
import { MaterialRequisition } from "../MaterialRequisition/MaterialRequisition.type";
import { Store } from "../Store/Store.type";
import { User } from "../User/User.type";
import { ApiCallState, PagedData } from "../Utils";

export type FuelIssue = {
  id: number;
  date: string;
  project_id: number;
  received_by: string;
  approved_by: string;
  pad_ref: string;
  user_id: number;
  purpose: string;
  project: Store;
  fuel_issue_remarks: FuelIssueRemark[];
  fuel_issue_statuses: FuelIssueStatus[];
  fuel_issue_items: FuelIssueItem[];
  status: FuelIssueStatus[];
  fuel_issue_prepared_by: User;
  fuel_issue_approved_by: User;
  fuel_issue_delivered_by: User;
  updatedAt: string;
  is_void: boolean;
};

export type FuelIssueRemark = {
  time: string;
  remark: string;
  user_id: number;
  document?: Document;
};

export type FuelIssueStatus = {
  fuel_issue_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type FuelIssueItem = {
  id?: number;
  fuel_issue_id: number;
  material?: Material;
  material_id: number;
  unit: string;
  quantity: number;
  unit_price: number;
  driver: string;
  plate_no: string;
  last_kmh: number;
  types_of_machine: string;
  material_requisition_id?: number;
  material_requisition?: MaterialRequisition;
  key: number;
  isDeleted?: boolean;
};

export type FuelIssueStateTypes = {
  fetchAll: ApiCallState<FuelIssue[]>;
  fetchOne: ApiCallState<FuelIssue | {}>;
  fetchPaged: ApiCallState<PagedData<FuelIssue[]>>;
  fetchReport: ApiCallState<any[]>;
};

export const FuelIssueActionTypes = {
  FETCH_ALL_FUEL_ISSUE: "FETCH_ALL_FUEL_ISSUE",
  FETCH_ALL_FUEL_ISSUE_RESET: "FETCH_ALL_FUEL_ISSUE_RESET",
  FETCH_ALL_FUEL_ISSUE_FAILURE: "FETCH_ALL_FUEL_ISSUE_FAILURE",
  FETCH_ALL_FUEL_ISSUE_SUCCESS: "FETCH_ALL_FUEL_ISSUE_SUCCESS",

  FETCH_REPORT_FUEL_ISSUE: "FETCH_REPORT_FUEL_ISSUE",
  FETCH_REPORT_FUEL_ISSUE_RESET: "FETCH_REPORT_FUEL_ISSUE_RESET",
  FETCH_REPORT_FUEL_ISSUE_FAILURE: "FETCH_REPORT_FUEL_ISSUE_FAILURE",
  FETCH_REPORT_FUEL_ISSUE_SUCCESS: "FETCH_REPORT_FUEL_ISSUE_SUCCESS",

  FETCH_PAGED_FUEL_ISSUE: "FETCH_PAGED_FUEL_ISSUE",
  FETCH_PAGED_FUEL_ISSUE_RESET: "FETCH_PAGED_FUEL_ISSUE_RESET",
  FETCH_PAGED_FUEL_ISSUE_FAILURE: "FETCH_PAGED_FUEL_ISSUE_FAILURE",
  FETCH_PAGED_FUEL_ISSUE_SUCCESS: "FETCH_PAGED_FUEL_ISSUE_SUCCESS",

  FETCH_ONE_FUEL_ISSUE: "FETCH_ONE_FUEL_ISSUE",
  FETCH_ONE_FUEL_ISSUE_RESET: "FETCH_ONE_FUEL_ISSUE_RESET",
  FETCH_ONE_FUEL_ISSUE_FAILURE: "FETCH_ONE_FUEL_ISSUE_FAILURE",
  FETCH_ONE_FUEL_ISSUE_SUCCESS: "FETCH_ONE_FUEL_ISSUE_SUCCESS",
};
