import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { FeedBackFormActionTypes } from "./FeedBackForm.type";

export function* fetchAllFeedBackForms(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      query =
        "?" +
        Object.keys(action.payload)
          .map((key) => `${key}=${action.payload[key]}`)
          .join("&");
    }

    const response = yield axios.get(`${API_BASE_URI}/feed-back-form${query}`);
    yield put({
      type: FeedBackFormActionTypes.FETCH_ALL_FEED_BACK_FORM_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FeedBackFormActionTypes.FETCH_ALL_FEED_BACK_FORM_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneFeedBackForms(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/feed-back-form/${action.payload}`
    );
    yield put({
      type: FeedBackFormActionTypes.FETCH_ONE_FEED_BACK_FORM_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FeedBackFormActionTypes.FETCH_ONE_FEED_BACK_FORM_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllFeedBackForms() {
  yield takeLatest(
    FeedBackFormActionTypes.FETCH_ALL_FEED_BACK_FORM,
    fetchAllFeedBackForms
  );
}

export function* watcherFetchOneFeedBackForms() {
  yield takeLatest(
    FeedBackFormActionTypes.FETCH_ONE_FEED_BACK_FORM,
    fetchOneFeedBackForms
  );
}
