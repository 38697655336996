import { ApiCallState } from "../Utils";
import { Moment } from "moment";

export type Briefing= {
    project_id: number | null;
    id: number | null;
    date:Moment;
    brief_description: string;
    current_status: string;
    key_considerations: string;
    responsible_person: number;
    progress: string;
    progress_percentage: string;
    recommendations: string;
};

export type BriefingStateTypes = {
  fetchAll: ApiCallState<Briefing[]>;
  fetchOne: ApiCallState<Briefing | {}>;
};

export type BriefingActionType = {
    project_id: number;
    date: string;
};

export const BriefingActionTypes = {
  FETCH_ALL_BRIEFING: "FETCH_ALL_BRIEFING",
  FETCH_ALL_BRIEFING_RESET: "FETCH_ALL_BRIEFING_RESET",
  FETCH_ALL_BRIEFING_FAILURE: "FETCH_ALL_BRIEFING_FAILURE",
  FETCH_ALL_BRIEFING_SUCCESS: "FETCH_ALL_BRIEFING_SUCCESS",

  FETCH_ONE_BRIEFING: "FETCH_ONE_BRIEFING",
  FETCH_ONE_BRIEFING_RESET: "FETCH_ONE_BRIEFING_RESET",
  FETCH_ONE_BRIEFING_FAILURE: "FETCH_ONE_BRIEFING_FAILURE",
  FETCH_ONE_BRIEFING_SUCCESS: "FETCH_ONE_BRIEFING_SUCCESS",
};
