import { ApiCallState } from "../Utils";

export type SparePartCost = {
  id: number;
  date: string;
  spare_part_cost_items: SparePartCostItem[];
};

export type SparePartCostItem = {
  id: number;
  type: string;
  equipment_type: string;
  plate_number: string;
  owner: string;
  description: string;
  previous_cost: number;
  this_period_cost: number;
  to_date_cost: number;
  remark: string;
  spare_part_cost_id: number;
};

export type SparePartCostStateTypes = {
  fetchAll: ApiCallState<SparePartCost[]>;
  fetchOne: ApiCallState<SparePartCost | {}>;
};

export const SparePartCostActionTypes = {
  FETCH_ALL_SPARE_PART_COST: "FETCH_ALL_SPARE_PART_COST",
  FETCH_ALL_SPARE_PART_COST_RESET: "FETCH_ALL_SPARE_PART_COST_RESET",
  FETCH_ALL_SPARE_PART_COST_FAILURE: "FETCH_ALL_SPARE_PART_COST_FAILURE",
  FETCH_ALL_SPARE_PART_COST_SUCCESS: "FETCH_ALL_SPARE_PART_COST_SUCCESS",

  FETCH_ONE_SPARE_PART_COST: "FETCH_ONE_SPARE_PART_COST",
  FETCH_ONE_SPARE_PART_COST_RESET: "FETCH_ONE_SPARE_PART_COST_RESET",
  FETCH_ONE_SPARE_PART_COST_FAILURE: "FETCH_ONE_SPARE_PART_COST_FAILURE",
  FETCH_ONE_SPARE_PART_COST_SUCCESS: "FETCH_ONE_SPARE_PART_COST_SUCCESS",
};
