import { RebarStateTypes, RebarActionTypes } from "./Rebar.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: RebarStateTypes = {
  fetchAll: resetApiCallState([]),
};

const RebarReducer = (
  state: RebarStateTypes = INITIAL_STATE,
  action: any
): RebarStateTypes => {
  switch (action.type) {
    case RebarActionTypes.FETCH_ALL_REBAR:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case RebarActionTypes.FETCH_ALL_REBAR_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case RebarActionTypes.FETCH_ALL_REBAR_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case RebarActionTypes.FETCH_ALL_REBAR_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default RebarReducer;
