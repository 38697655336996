import _ from "lodash";

class BuildingChartsOfAccount {
  data: any[];
  sheet_name: string;
  type: any;
  parsed: any[];

  constructor(data: any, sheet_name: string) {
    this.data = data;
    this.type = "";
    this.parsed = [];
    this.sheet_name = sheet_name;
  }

  parseBoq() {
    let start = -1;
    this.data.forEach((col: any[], index) => {
      if (this.isTableState(col)) start = index;
      else if (start !== -1) {
        if (col[0]) {
          this.parsed.push({
            type:col[0],
            name: col[1],
            key: this.parsed.length,
          });
        }
      }
    });
    return this.parsed;
  }

  private isTableState(col: any[]) {
    return (
      _.isString(col[0]) &&
      _.isString(col[1]) &&
      _.isNil(col[2])
    );
  }

}
export default BuildingChartsOfAccount;
