import { BankAccount } from "../BankAccount/BankAccount.type";
import { ApiCallState } from "../Utils";

export type AccountTransaction = {
  id: number;
  bank_account_id: number;
  payee: string;
  pv_crv_number: string;
  transaction_type: string;
  amount: number;
  description: string;
  date: Date;

  bank_account: BankAccount;
};

export type AccountTransactionStateTypes = {
  fetchAll: ApiCallState<AccountTransaction[]>;
};

export const AccountTransactionActionTypes = {
  FETCH_ALL_ACCOUNT_TRANSACTION: "FETCH_ALL_ACCOUNT_TRANSACTION",
  FETCH_ALL_ACCOUNT_TRANSACTION_RESET: "FETCH_ALL_ACCOUNT_TRANSACTION_RESET",
  FETCH_ALL_ACCOUNT_TRANSACTION_FAILURE:
    "FETCH_ALL_ACCOUNT_TRANSACTION_FAILURE",
  FETCH_ALL_ACCOUNT_TRANSACTION_SUCCESS:
    "FETCH_ALL_ACCOUNT_TRANSACTION_SUCCESS",
};
