import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { formatQuery } from "../Utils";
import { PaymentRequisitionActionTypes } from "./PaymentRequisition.type";

export function* fetchAllPaymentRequisitions(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/payment-requisition?${formatQuery(action)}`
    );
    yield put({
      type: PaymentRequisitionActionTypes.FETCH_ALL_PAYMENT_REQUISITION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PaymentRequisitionActionTypes.FETCH_ALL_PAYMENT_REQUISITION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePaymentRequisition(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/payment-requisition/${action.payload}`
    );
    yield put({
      type: PaymentRequisitionActionTypes.FETCH_ONE_PAYMENT_REQUISITION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PaymentRequisitionActionTypes.FETCH_ONE_PAYMENT_REQUISITION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPaymentRequisitions() {
  yield takeLatest(
    PaymentRequisitionActionTypes.FETCH_ALL_PAYMENT_REQUISITION,
    fetchAllPaymentRequisitions
  );
}

export function* watcherFetchOnePaymentRequisition() {
  yield takeLatest(
    PaymentRequisitionActionTypes.FETCH_ONE_PAYMENT_REQUISITION,
    fetchOnePaymentRequisition
  );
}
