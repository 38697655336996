import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { formatQuery } from "../Utils";
import { GoodReceivedActionTypes } from "./GoodReceived.type";

export function* fetchAllGoodReceived(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/good-received?${formatQuery(action)}`
    );
    yield put({
      type: GoodReceivedActionTypes.FETCH_ALL_GOOD_RECEIVED_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GoodReceivedActionTypes.FETCH_ALL_GOOD_RECEIVED_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneGoodReceived(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/good-received/${action.payload}`
    );
    yield put({
      type: GoodReceivedActionTypes.FETCH_ONE_GOOD_RECEIVED_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GoodReceivedActionTypes.FETCH_ONE_GOOD_RECEIVED_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedGoodReceived(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/good-received/paged?${formatQuery(action)}`
    );
    yield put({
      type: GoodReceivedActionTypes.FETCH_PAGED_GOOD_RECEIVED_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GoodReceivedActionTypes.FETCH_PAGED_GOOD_RECEIVED_FAILURE,
      payload: error,
    });
  }
}

export function* fetchGoodReceivedReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/good-received/report?${formatQuery(action)}`
    );
    yield put({
      type: GoodReceivedActionTypes.FETCH_GOOD_RECEIVED_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GoodReceivedActionTypes.FETCH_GOOD_RECEIVED_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllGoodReceived() {
  yield takeLatest(
    GoodReceivedActionTypes.FETCH_ALL_GOOD_RECEIVED,
    fetchAllGoodReceived
  );
}

export function* watcherFetchOneGoodReceived() {
  yield takeLatest(
    GoodReceivedActionTypes.FETCH_ONE_GOOD_RECEIVED,
    fetchOneGoodReceived
  );
}

export function* watcherFetchPagedGoodReceived() {
  yield takeLatest(
    GoodReceivedActionTypes.FETCH_PAGED_GOOD_RECEIVED,
    fetchPagedGoodReceived
  );
}

export function* watcherFetchGoodReceivedReports() {
  yield takeLatest(
    GoodReceivedActionTypes.FETCH_GOOD_RECEIVED_REPORT,
    fetchGoodReceivedReports
  );
}
