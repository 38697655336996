import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { FuelAndLubiconRequestActionTypes } from "./FuelAndLubiconRequest.type";

export function* fetchAllFuelAndLubiconRequests(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/fuel-and-lubicon-request`);
    yield put({
      type: FuelAndLubiconRequestActionTypes.FETCH_ALL_FUEL_AND_LUBICON_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FuelAndLubiconRequestActionTypes.FETCH_ALL_FUEL_AND_LUBICON_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneFuelAndLubiconRequests(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/fuel-and-lubicon-request/${action.payload}`
    );
    yield put({
      type: FuelAndLubiconRequestActionTypes.FETCH_ONE_FUEL_AND_LUBICON_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FuelAndLubiconRequestActionTypes.FETCH_ONE_FUEL_AND_LUBICON_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllFuelAndLubiconRequests() {
  yield takeLatest(FuelAndLubiconRequestActionTypes.FETCH_ALL_FUEL_AND_LUBICON_REQUEST, fetchAllFuelAndLubiconRequests);
}

export function* watcherFetchOneFuelAndLubiconRequests() {
  yield takeLatest(FuelAndLubiconRequestActionTypes.FETCH_ONE_FUEL_AND_LUBICON_REQUEST, fetchOneFuelAndLubiconRequests);
}
