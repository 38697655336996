import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MonthlySiteReportActionTypes } from "./MonthlySiteReport.type";

export function* fetchSurveyingEquipmentCostReport(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/monthly-site-report/surveying-equipment-cost?project_id=${action.payload?.project_id}&month=${action.payload?.month}`
    );
    yield put({
      type: MonthlySiteReportActionTypes.FETCH_SURVEYING_EQUIPMENT_COST_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MonthlySiteReportActionTypes.FETCH_SURVEYING_EQUIPMENT_COST_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchLaboratoryEquipmentCostReport(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/monthly-site-report/laboratory-equipment-cost?project_id=${action.payload?.project_id}&month=${action.payload?.month}`
    );
    yield put({
      type: MonthlySiteReportActionTypes.FETCH_LABORATORY_EQUIPMENT_COST_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MonthlySiteReportActionTypes.FETCH_LABORATORY_EQUIPMENT_COST_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchConsultantFacilityCostReport(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/monthly-site-report/consultant-facility-cost?project_id=${action.payload?.project_id}&month=${action.payload?.month}`
    );
    yield put({
      type: MonthlySiteReportActionTypes.FETCH_CONSULTANT_FACILITY_COST_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MonthlySiteReportActionTypes.FETCH_CONSULTANT_FACILITY_COST_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchContractorFacilityCostReport(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/monthly-site-report/contractor-facility-cost?project_id=${action.payload?.project_id}&month=${action.payload?.month}`
    );
    yield put({
      type: MonthlySiteReportActionTypes.FETCH_CONTRACTOR_FACILITY_COST_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MonthlySiteReportActionTypes.FETCH_CONTRACTOR_FACILITY_COST_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchEquipmentCostReport(action: any): any {
  console.log({payload: action.payload});
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/monthly-site-report/equipment-cost?project_id=${action.payload?.project_id}&month=${action.payload?.month}`
    );
    yield put({
      type: MonthlySiteReportActionTypes.FETCH_EQUIPMENT_COST_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MonthlySiteReportActionTypes.FETCH_EQUIPMENT_COST_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOtherExpenseReport(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/monthly-site-report/other-expense-cost?project_id=${action.payload?.project_id}&month=${action.payload?.month}`
    );
    yield put({
      type: MonthlySiteReportActionTypes.FETCH_OTHER_EXPENSE_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MonthlySiteReportActionTypes.FETCH_OTHER_EXPENSE_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchSummaryOfCostReport(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/monthly-site-report/summary-of-cost?project_id=${action.payload?.project_id}&month=${action.payload?.month}`
    );
    yield put({
      type: MonthlySiteReportActionTypes.FETCH_SUMMARY_OF_COST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MonthlySiteReportActionTypes.FETCH_SUMMARY_OF_COST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchDetailReportOfActivity(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/monthly-site-report/detail-report-of-activity?project_id=${action.payload?.project_id}&month=${action.payload?.month}`
    );
    yield put({
      type: MonthlySiteReportActionTypes.FETCH_DETAIL_REPORT_OF_ACTIVITY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MonthlySiteReportActionTypes.FETCH_DETAIL_REPORT_OF_ACTIVITY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchTotalProjectExpenseReport(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/monthly-site-report/total-project-expense?project_id=${action.payload?.project_id}&month=${action.payload?.month}`
    );
    yield put({
      type: MonthlySiteReportActionTypes.FETCH_TOTAL_PROJECT_EXPENSE_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MonthlySiteReportActionTypes.FETCH_TOTAL_PROJECT_EXPENSE_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchMonthlyIncomeExpenseReport(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/monthly-site-report/income-vs-expense-report?project_id=${action.payload?.project_id}&month=${action.payload?.month}`
    );
    yield put({
      type: MonthlySiteReportActionTypes.FETCH_MONTHLY_INCOME_EXPENES_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MonthlySiteReportActionTypes.FETCH_MONTHLY_INCOME_EXPENES_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchTotalProjectIncomeReport(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/monthly-site-report/total-project-income-report?project_id=${action.payload?.project_id}&month=${action.payload?.month}`
    );
    yield put({
      type: MonthlySiteReportActionTypes.FETCH_TOTAL_PROJECT_INCOME_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MonthlySiteReportActionTypes.FETCH_TOTAL_PROJECT_INCOME_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchProgressByBillSummary(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/monthly-site-report/progress-by-bill-summary?project_id=${action.payload?.project_id}&month=${action.payload?.month}`
    );
    yield put({
      type: MonthlySiteReportActionTypes.FETCH_PROGRESS_BILL_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MonthlySiteReportActionTypes.FETCH_PROGRESS_BILL_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchCriticalIssueReport(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/monthly-site-report/critical-issue?project_id=${action.payload?.project_id}&start_date=${action.payload?.start_date}&end_date=${action.payload?.end_date}`
    );
    yield put({
      type: MonthlySiteReportActionTypes.FETCH_CRITICAL_ISSUE_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MonthlySiteReportActionTypes.FETCH_CRITICAL_ISSUE_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchSurveyingEquipmentCostReport() {
  yield takeLatest(
    MonthlySiteReportActionTypes.FETCH_SURVEYING_EQUIPMENT_COST_REPORT,
    fetchSurveyingEquipmentCostReport
  );
}

export function* watcherFetchLaboratoryEquipmentCostReport() {
  yield takeLatest(
    MonthlySiteReportActionTypes.FETCH_LABORATORY_EQUIPMENT_COST_REPORT,
    fetchLaboratoryEquipmentCostReport
  );
}

export function* watcherFetchConsultantFacilityCostReport() {
  yield takeLatest(
    MonthlySiteReportActionTypes.FETCH_CONSULTANT_FACILITY_COST_REPORT,
    fetchConsultantFacilityCostReport
  );
}

export function* watcherFetchContractorFacilityCostReport() {
  yield takeLatest(
    MonthlySiteReportActionTypes.FETCH_CONTRACTOR_FACILITY_COST_REPORT,
    fetchContractorFacilityCostReport
  );
}

export function* watcherFetchEquipmentCostReport() {
  yield takeLatest(
    MonthlySiteReportActionTypes.FETCH_EQUIPMENT_COST_REPORT,
    fetchEquipmentCostReport
  );
}

export function* watcherFetchOtherExpenseReport() {
  yield takeLatest(
    MonthlySiteReportActionTypes.FETCH_OTHER_EXPENSE_REPORT,
    fetchOtherExpenseReport
  );
}

export function* watcherFetchSummaryOfCostReport() {
  yield takeLatest(
    MonthlySiteReportActionTypes.FETCH_SUMMARY_OF_COST,
    fetchSummaryOfCostReport
  );
}


export function* watcherFetchDetailReportOfActivity() {
  yield takeLatest(
    MonthlySiteReportActionTypes.FETCH_DETAIL_REPORT_OF_ACTIVITY,
    fetchDetailReportOfActivity
  );
}

export function* watcherFetchTotalProjectExpenseReport() {
  yield takeLatest(
    MonthlySiteReportActionTypes.FETCH_TOTAL_PROJECT_EXPENSE_REPORT,
    fetchTotalProjectExpenseReport
  );
}

export function* watcherFetchMonthlyIncomeExpenseReport() {
  yield takeLatest(
    MonthlySiteReportActionTypes.FETCH_MONTHLY_INCOME_EXPENES_REPORT,
    fetchMonthlyIncomeExpenseReport
  );
}

export function* watcherFetchTotalProjectIncomeReport() {
  yield takeLatest(
    MonthlySiteReportActionTypes.FETCH_TOTAL_PROJECT_INCOME_REPORT,
    fetchTotalProjectIncomeReport
  );
}

export function* watcherFetchProgressByBillSummary() {
  yield takeLatest(
    MonthlySiteReportActionTypes.FETCH_PROGRESS_BILL_SUMMARY,
    fetchProgressByBillSummary
  );
}

export function* watcherFetchCriticalIssueReport() {
  yield takeLatest(
    MonthlySiteReportActionTypes.FETCH_CRITICAL_ISSUE_REPORT,
    fetchCriticalIssueReport
  );
}