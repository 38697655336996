import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";
import { API_BASE_URI } from "../ApiCall";
import { UploadDocumentActionTypes } from "./UploadDocument.type";

export function* fetchAllUploadDocuments(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/upload-document`);
    yield put({
      type: UploadDocumentActionTypes.FETCH_ALL_UPLOAD_DOCUMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: UploadDocumentActionTypes.FETCH_ALL_UPLOAD_DOCUMENT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneUploadDocuments(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/upload-document/${action.payload}`
    );
    yield put({
      type: UploadDocumentActionTypes.FETCH_ONE_UPLOAD_DOCUMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: UploadDocumentActionTypes.FETCH_ONE_UPLOAD_DOCUMENT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchReferenceNumber(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/upload-document/reference-number`
    );
    yield put({
      type: UploadDocumentActionTypes.FETCH_UPLOAD_DOCUMENT_REFERENCE_NUMBER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: UploadDocumentActionTypes.FETCH_UPLOAD_DOCUMENT_REFERENCE_NUMBER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllUploadDocuments() {
  yield takeLatest(
    UploadDocumentActionTypes.FETCH_ALL_UPLOAD_DOCUMENT,
    fetchAllUploadDocuments
  );
}

export function* watcherFetchOneUploadDocuments() {
  yield takeLatest(
    UploadDocumentActionTypes.FETCH_ONE_UPLOAD_DOCUMENT,
    fetchOneUploadDocuments
  );
}

export function* watcherFetchUploadDocumentReferenceNumber() {
  yield takeLatest(
    UploadDocumentActionTypes.FETCH_UPLOAD_DOCUMENT_REFERENCE_NUMBER,
    fetchReferenceNumber
  );
}
