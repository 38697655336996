import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { OutsourcingMaintenanceActionTypes } from "./OutsourcingMaintenance.type";
import { formatQuery } from "../Utils";

export function* fetchAllOutsourcingMaintenances(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/outsourcing-maintenance?${formatQuery(action)}`);
    yield put({
      type: OutsourcingMaintenanceActionTypes.FETCH_ALL_OUTSOURCING_MAINTENANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: OutsourcingMaintenanceActionTypes.FETCH_ALL_OUTSOURCING_MAINTENANCE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneOutsourcingMaintenances(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/outsourcing-maintenance/${action.payload}`
    );
    yield put({
      type: OutsourcingMaintenanceActionTypes.FETCH_ONE_OUTSOURCING_MAINTENANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: OutsourcingMaintenanceActionTypes.FETCH_ONE_OUTSOURCING_MAINTENANCE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllOutsourcingMaintenances() {
  yield takeLatest(OutsourcingMaintenanceActionTypes.FETCH_ALL_OUTSOURCING_MAINTENANCE, fetchAllOutsourcingMaintenances);
}

export function* watcherFetchOneOutsourcingMaintenances() {
  yield takeLatest(OutsourcingMaintenanceActionTypes.FETCH_ONE_OUTSOURCING_MAINTENANCE, fetchOneOutsourcingMaintenances);
}
