import { Material } from "../Material/Material.type";

import { PurchaseOrder } from "../PurchaseOrder/PurchaseOrder.type";
import { PurchaseRequisition } from "../PurchaseRequisition/PurchaseRequisition.type";
import { Store } from "../Store/Store.type";
import { Supplier } from "../Supplier/Supplier.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type DocumentTransmital = {
  updatedAt: any;
  id: number;
  date: string;
  no: string;
  from_id: number;
  from?: User;
  to_id: number;
  to?: User;
  amounting_birr: number;
  issued_by_id: number;
  issued_by?: User;
  delivered_by_id: number;
  delivered_by?: User;
  recieved_by_id: number;
  recieved_by?: User;
  document_transmital_items: DocumentTransmitalItem[];
};

export type DocumentTransmitalReport = {
  approved_by?: any;
  code: string;
  date: string;
  description: string;
  from: string;
  to: string;
  item_category: string;
  id: number;
  major_category: string;
  purchase_requisition_id: string;
  pad_ref: string;
  prepared_by: string;
  quantity: number;
  received_by?: any;
  remark: string;
  total: number;
  unit: string;
};

export type DocumentTransmitalStatus = {
  good_out_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type DocumentTransmitalItem = {
  id: number;
  date: string;
  type: string;
  document_transmital_id: number;
  invoice_amount: number;
  invoice_number: string;
  remark: string;
};

export type DocumentTransmitalStateTypes = {
  fetchAll: ApiCallState<DocumentTransmital[]>;
  fetchReport: ApiCallState<DocumentTransmitalReport[]>;
};

export const DocumentTransmitalActionTypes = {
  FETCH_ALL_DOCUMENT_TRANSMITAL: "FETCH_ALL_DOCUMENT_TRANSMITAL",
  FETCH_ALL_DOCUMENT_TRANSMITAL_RESET: "FETCH_ALL_DOCUMENT_TRANSMITAL_RESET",
  FETCH_ALL_DOCUMENT_TRANSMITAL_FAILURE:
    "FETCH_ALL_DOCUMENT_TRANSMITAL_FAILURE",
  FETCH_ALL_DOCUMENT_TRANSMITAL_SUCCESS:
    "FETCH_ALL_DOCUMENT_TRANSMITAL_SUCCESS",

  FETCH_ONE_DOCUMENT_TRANSMITAL: "FETCH_ONE_DOCUMENT_TRANSMITAL",
  FETCH_ONE_DOCUMENT_TRANSMITAL_RESET: "FETCH_ONE_DOCUMENT_TRANSMITAL_RESET",
  FETCH_ONE_DOCUMENT_TRANSMITAL_FAILURE:
    "FETCH_ONE_DOCUMENT_TRANSMITAL_FAILURE",
  FETCH_ONE_DOCUMENT_TRANSMITAL_SUCCESS:
    "FETCH_DOCUMENT_TRANSMITAL_REPORT_SUCCESS",

  FETCH_DOCUMENT_TRANSMITAL_REPORT: "FETCH_DOCUMENT_TRANSMITAL_REPORT",
  FETCH_DOCUMENT_TRANSMITAL_REPORT_RESET:
    "FETCH_DOCUMENT_TRANSMITAL_REPORT_RESET",
  FETCH_DOCUMENT_TRANSMITAL_REPORT_FAILURE:
    "FETCH_DOCUMENT_TRANSMITAL_REPORT_FAILURE",
  FETCH_DOCUMENT_TRANSMITAL_REPORT_SUCCESS:
    "FETCH_DOCUMENT_TRANSMITAL_REPORT_SUCCESS",
};
