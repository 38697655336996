import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { FamilyAllotmentActionTypes } from "./FamilyAllotment.type";

export function* fetchAllFamilyAllotments(action: any): any {
  try {
    let keys: any[] = Object.keys(action?.payload);

    let query: string = keys
      ?.map((key: any) => `${key}=${action?.payload[key]}`)
      ?.join("&&");

    const response = yield axios.get(
      `${API_BASE_URI}/family-allotment?${query}`
    );
    yield put({
      type: FamilyAllotmentActionTypes.FETCH_ALL_FAMILY_ALLOTMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FamilyAllotmentActionTypes.FETCH_ALL_FAMILY_ALLOTMENT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneFamilyAllotments(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/family-allotment/${action.payload}`
    );
    yield put({
      type: FamilyAllotmentActionTypes.FETCH_ONE_FAMILY_ALLOTMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FamilyAllotmentActionTypes.FETCH_ONE_FAMILY_ALLOTMENT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllFamilyAllotments() {
  yield takeLatest(
    FamilyAllotmentActionTypes.FETCH_ALL_FAMILY_ALLOTMENT,
    fetchAllFamilyAllotments
  );
}

export function* watcherFetchOneFamilyAllotments() {
  yield takeLatest(
    FamilyAllotmentActionTypes.FETCH_ONE_FAMILY_ALLOTMENT,
    fetchOneFamilyAllotments
  );
}
