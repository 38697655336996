import { ApiCallState } from "../Utils";

export type HRAllowance= {
  id: number;
  description:string;
  in_amharic:string;
  tax_status:string;
  min_taxable_amount:number;
  user_id:number;
};

export type HRAllowanceStateTypes = {
  fetchAll: ApiCallState<HRAllowance[]>;
  fetchOne: ApiCallState<HRAllowance | {}>;
};

export const HRAllowanceActionTypes = {
  FETCH_ALL_HR_ALLOWANCE: "FETCH_ALL_HR_ALLOWANCE",
  FETCH_ALL_HR_ALLOWANCE_RESET: "FETCH_ALL_HR_ALLOWANCE_RESET",
  FETCH_ALL_HR_ALLOWANCE_FAILURE: "FETCH_ALL_HR_ALLOWANCE_FAILURE",
  FETCH_ALL_HR_ALLOWANCE_SUCCESS: "FETCH_ALL_HR_ALLOWANCE_SUCCESS",

  FETCH_ONE_HR_ALLOWANCE: "FETCH_ONE_HR_ALLOWANCE",
  FETCH_ONE_HR_ALLOWANCE_RESET: "FETCH_ONE_HR_ALLOWANCE_RESET",
  FETCH_ONE_HR_ALLOWANCE_FAILURE: "FETCH_ONE_HR_ALLOWANCE_FAILURE",
  FETCH_ONE_HR_ALLOWANCE_SUCCESS: "FETCH_ONE_HR_ALLOWANCE_SUCCESS",
};
