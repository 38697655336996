import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type SelfAttendingUser = {
  id: number;
  user_id: number;

  user: User;
};

export type SelfAttendingUserStateTypes = {
  fetchAll: ApiCallState<SelfAttendingUser[]>;
  fetchOne: ApiCallState<SelfAttendingUser | {}>;
};

export const SelfAttendingUserActionTypes = {
  FETCH_ALL_SELF_ATTENDING_USER: "FETCH_ALL_SELF_ATTENDING_USER",
  FETCH_ALL_SELF_ATTENDING_USER_RESET: "FETCH_ALL_SELF_ATTENDING_USER_RESET",
  FETCH_ALL_SELF_ATTENDING_USER_FAILURE:
    "FETCH_ALL_SELF_ATTENDING_USER_FAILURE",
  FETCH_ALL_SELF_ATTENDING_USER_SUCCESS:
    "FETCH_ALL_SELF_ATTENDING_USER_SUCCESS",

  FETCH_ONE_SELF_ATTENDING_USER: "FETCH_ONE_SELF_ATTENDING_USER",
  FETCH_ONE_SELF_ATTENDING_USER_RESET: "FETCH_ONE_SELF_ATTENDING_USER_RESET",
  FETCH_ONE_SELF_ATTENDING_USER_FAILURE:
    "FETCH_ONE_SELF_ATTENDING_USER_FAILURE",
  FETCH_ONE_SELF_ATTENDING_USER_SUCCESS:
    "FETCH_ONE_SELF_ATTENDING_USER_SUCCESS",
};
