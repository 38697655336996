import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MikadaWeeklyMaterialReportActionTypes } from "./MikadaWeeklyMaterialReport.type";

export function* fetchAllMikadaWeeklyMaterialReports(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/mikada-weekly-material-report?date=${action.payload?.date}`);
    yield put({
      type: MikadaWeeklyMaterialReportActionTypes.FETCH_ALL_MIKADA_WEEKLY_MATERIAL_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MikadaWeeklyMaterialReportActionTypes.FETCH_ALL_MIKADA_WEEKLY_MATERIAL_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMikadaWeeklyMaterialReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/mikada-weekly-material-report/${action.payload}`
    );
    yield put({
      type: MikadaWeeklyMaterialReportActionTypes.FETCH_ONE_MIKADA_WEEKLY_MATERIAL_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MikadaWeeklyMaterialReportActionTypes.FETCH_ONE_MIKADA_WEEKLY_MATERIAL_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMikadaWeeklyMaterialReports() {
  yield takeLatest(MikadaWeeklyMaterialReportActionTypes.FETCH_ALL_MIKADA_WEEKLY_MATERIAL_REPORT, fetchAllMikadaWeeklyMaterialReports);
}

export function* watcherFetchOneMikadaWeeklyMaterialReports() {
  yield takeLatest(MikadaWeeklyMaterialReportActionTypes.FETCH_ONE_MIKADA_WEEKLY_MATERIAL_REPORT, fetchOneMikadaWeeklyMaterialReports);
}
