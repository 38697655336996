import { formatQuery } from "./../Utils";
import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { GoodIssueActionTypes } from "./GoodIssue.type";

export function* fetchAllGoodIssues(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/good-issue?${formatQuery(action)}`
    );
    yield put({
      type: GoodIssueActionTypes.FETCH_ALL_GOOD_ISSUE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GoodIssueActionTypes.FETCH_ALL_GOOD_ISSUE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchReportGoodIssues(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/good-issue/report?${formatQuery(action)}`
    );
    yield put({
      type: GoodIssueActionTypes.FETCH_REPORT_GOOD_ISSUE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GoodIssueActionTypes.FETCH_REPORT_GOOD_ISSUE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedGoodIssues(action: any): any {
  try {
    let query = "";

    const response = yield axios.get(
      `${API_BASE_URI}/good-issue/paged?${formatQuery(action)}`
    );
    yield put({
      type: GoodIssueActionTypes.FETCH_PAGED_GOOD_ISSUE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GoodIssueActionTypes.FETCH_PAGED_GOOD_ISSUE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneGoodIssues(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/good-issue/${action.payload}`
    );
    yield put({
      type: GoodIssueActionTypes.FETCH_ONE_GOOD_ISSUE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GoodIssueActionTypes.FETCH_ONE_GOOD_ISSUE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchVehicleReportGoodIssues(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/good-issue/vehicle-report?${formatQuery(action)}`
    );
    yield put({
      type: GoodIssueActionTypes.FETCH_VEHICLE_REPORT_GOOD_ISSUE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GoodIssueActionTypes.FETCH_VEHICLE_REPORT_GOOD_ISSUE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchFormanReportGoodIssues(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/good-issue/forman-report?${formatQuery(action)}`
    );
    yield put({
      type: GoodIssueActionTypes.FETCH_GOOD_ISSUE_FORMAN_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GoodIssueActionTypes.FETCH_GOOD_ISSUE_FORMAN_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllGoodIssues() {
  yield takeLatest(
    GoodIssueActionTypes.FETCH_ALL_GOOD_ISSUE,
    fetchAllGoodIssues
  );
}

export function* watcherFetchReportGoodIssues() {
  yield takeLatest(
    GoodIssueActionTypes.FETCH_REPORT_GOOD_ISSUE,
    fetchReportGoodIssues
  );
}

export function* watcherFetchPagedGoodIssues() {
  yield takeLatest(
    GoodIssueActionTypes.FETCH_PAGED_GOOD_ISSUE,
    fetchPagedGoodIssues
  );
}

export function* watcherFetchOneGoodIssues() {
  yield takeLatest(
    GoodIssueActionTypes.FETCH_ONE_GOOD_ISSUE,
    fetchOneGoodIssues
  );
}

export function* watcherFetchVehicleReportGoodIssue() {
  yield takeLatest(
    GoodIssueActionTypes.FETCH_VEHICLE_REPORT_GOOD_ISSUE,
    fetchVehicleReportGoodIssues
  );
}

export function* watcherFetchFormanReportGoodIssue() {
  yield takeLatest(
    GoodIssueActionTypes.FETCH_GOOD_ISSUE_FORMAN_REPORT,
    fetchFormanReportGoodIssues
  );
}
