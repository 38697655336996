import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { TravelRegistryActionTypes } from "./TravelRegistry.type";

export function* fetchAllTravelRegistry(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query =
        "?" + keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/travel-registry${query}`);
    yield put({
      type: TravelRegistryActionTypes.FETCH_ALL_TRAVEL_REGISTRY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TravelRegistryActionTypes.FETCH_ALL_TRAVEL_REGISTRY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneTravelRegistry(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/travel-registry/${action.payload}`
    );
    yield put({
      type: TravelRegistryActionTypes.FETCH_ONE_TRAVEL_REGISTRY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TravelRegistryActionTypes.FETCH_ONE_TRAVEL_REGISTRY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllTravelRegistry() {
  yield takeLatest(
    TravelRegistryActionTypes.FETCH_ALL_TRAVEL_REGISTRY,
    fetchAllTravelRegistry
  );
}

export function* watcherFetchOneTravelRegistry() {
  yield takeLatest(
    TravelRegistryActionTypes.FETCH_ONE_TRAVEL_REGISTRY,
    fetchOneTravelRegistry
  );
}
