import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PayableV2ActionTypes } from "./PayableV2.type";

export function* fetchAllPayableV2s(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/payable-v2?project_id=${action.payload?.project_id}`
    );
    yield put({
      type: PayableV2ActionTypes.FETCH_ALL_PAYABLE_V2_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PayableV2ActionTypes.FETCH_ALL_PAYABLE_V2_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePayableV2s(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/payable-v2/${action.payload}`
    );
    yield put({
      type: PayableV2ActionTypes.FETCH_ONE_PAYABLE_V2_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PayableV2ActionTypes.FETCH_ONE_PAYABLE_V2_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPayableV2s() {
  yield takeLatest(
    PayableV2ActionTypes.FETCH_ALL_PAYABLE_V2,
    fetchAllPayableV2s
  );
}

export function* watcherFetchOnePayableV2s() {
  yield takeLatest(
    PayableV2ActionTypes.FETCH_ONE_PAYABLE_V2,
    fetchOnePayableV2s
  );
}
