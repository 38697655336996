import {
    TelegraphicTransferStateTypes,
    TelegraphicTransferActionTypes,
  } from "./TelegraphicTransfer.type";
  import { resetApiCallState } from "../../Utils";
  
  const INITIAL_STATE: TelegraphicTransferStateTypes = {
    fetchAll: resetApiCallState([]),
    fetchOne: resetApiCallState({}),
  };
  
  const TelegraphicTransferReducer = (
    state: TelegraphicTransferStateTypes = INITIAL_STATE,
    action: any
  ): TelegraphicTransferStateTypes => {
    switch (action.type) {
      case TelegraphicTransferActionTypes.FETCH_ALL_TELEGRAPHIC_TRANSFER:
        return {
          ...state,
          fetchAll: {
            error: null,
            payload: [],
            isPending: true,
            isSuccessful: false,
          },
        };
      case TelegraphicTransferActionTypes.FETCH_ALL_TELEGRAPHIC_TRANSFER_RESET:
        return {
          ...state,
          fetchAll: resetApiCallState([]),
        };
      case TelegraphicTransferActionTypes.FETCH_ALL_TELEGRAPHIC_TRANSFER_FAILURE:
        return {
          ...state,
          fetchAll: {
            payload: [],
            isPending: false,
            isSuccessful: false,
            error: action.payload,
          },
        };
      case TelegraphicTransferActionTypes.FETCH_ALL_TELEGRAPHIC_TRANSFER_SUCCESS:
        return {
          ...state,
          fetchAll: {
            error: null,
            isPending: false,
            isSuccessful: true,
            payload: action.payload,
          },
        };
  
      case TelegraphicTransferActionTypes.FETCH_ONE_TELEGRAPHIC_TRANSFER:
        return {
          ...state,
          fetchOne: {
            error: null,
            payload: {},
            isPending: true,
            isSuccessful: false,
          },
        };
      case TelegraphicTransferActionTypes.FETCH_ONE_TELEGRAPHIC_TRANSFER_RESET:
        return {
          ...state,
          fetchAll: resetApiCallState([]),
        };
      case TelegraphicTransferActionTypes.FETCH_ONE_TELEGRAPHIC_TRANSFER_FAILURE:
        return {
          ...state,
          fetchOne: {
            payload: {},
            isPending: false,
            isSuccessful: false,
            error: action.payload,
          },
        };
      case TelegraphicTransferActionTypes.FETCH_ONE_TELEGRAPHIC_TRANSFER_SUCCESS:
        return {
          ...state,
          fetchOne: {
            error: null,
            isPending: false,
            isSuccessful: true,
            payload: action.payload,
          },
        };
  
      default:
        return state;
    }
  };
  
  export default TelegraphicTransferReducer;
  