import { Moment } from "moment";
import { ApiCallState } from "../Utils";

export type ProjectFuelRequest= {
    id: number | null;
    project_id:number | null;
    week:Moment;
    date_range:Moment;
    request_no:string;
    total_gasoil:number;
    total_benzene:number;
    fuel_request_details:FuelRequestDetail[];
    fuel_request_statuses: FuelRequestStatus[];
};

export type FuelRequestDetail= {
    id: number | null;
    fuel_request_id:number | null;
    type_of_equipment:string;
    plate_no:string;
    fuel_type:string;
    per_hour:number;
    per_kilometer:number;
    total_planned_working:number;
    total_planned_fuel:number;
    planned_type_of_work:string;
    remark:string;  
}

export type FuelRequestStatus= {
    fuel_request_id:number;
    type: "View" | "Check" | "Approve";
    status: number;
    assigned_by: number;
    user_id: number;
    id: number;
}

export type ProjectFuelRequestStateTypes = {
  fetchAll: ApiCallState<ProjectFuelRequest[]>;
  fetchOne: ApiCallState<ProjectFuelRequest | {}>;
};

export const ProjectFuelRequestActionTypes = {
  FETCH_ALL_PROJECT_FUEL_REQUEST: "FETCH_ALL_PROJECT_FUEL_REQUEST",
  FETCH_ALL_PROJECT_FUEL_REQUEST_RESET: "FETCH_ALL_PROJECT_FUEL_REQUEST_RESET",
  FETCH_ALL_PROJECT_FUEL_REQUEST_FAILURE: "FETCH_ALL_PROJECT_FUEL_REQUEST_FAILURE",
  FETCH_ALL_PROJECT_FUEL_REQUEST_SUCCESS: "FETCH_ALL_PROJECT_FUEL_REQUEST_SUCCESS",

  FETCH_ONE_PROJECT_FUEL_REQUEST: "FETCH_ONE_PROJECT_FUEL_REQUEST",
  FETCH_ONE_PROJECT_FUEL_REQUEST_RESET: "FETCH_ONE_PROJECT_FUEL_REQUEST_RESET",
  FETCH_ONE_PROJECT_FUEL_REQUEST_FAILURE: "FETCH_ONE_PROJECT_FUEL_REQUEST_FAILURE",
  FETCH_ONE_PROJECT_FUEL_REQUEST_SUCCESS: "FETCH_ONE_PROJECT_FUEL_REQUEST_SUCCESS",
};
