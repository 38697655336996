import { InitPagedData } from "./../Utils";
import {
  EmployeeTrainingStateTypes,
  EmployeeTrainingActionTypes,
} from "./EmployeeTraining.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: EmployeeTrainingStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchPaged: resetApiCallState(InitPagedData),
};

const EmployeeTrainingReducer = (
  state: EmployeeTrainingStateTypes = INITIAL_STATE,
  action: any
): EmployeeTrainingStateTypes => {
  switch (action.type) {
    case EmployeeTrainingActionTypes.FETCH_ALL_EMPLOYEE_TRAINING:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case EmployeeTrainingActionTypes.FETCH_ALL_EMPLOYEE_TRAINING_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case EmployeeTrainingActionTypes.FETCH_ALL_EMPLOYEE_TRAINING_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case EmployeeTrainingActionTypes.FETCH_ALL_EMPLOYEE_TRAINING_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };
    case EmployeeTrainingActionTypes.FETCH_PAGED_EMPLOYEE_TRAINING:
      return {
        ...state,
        fetchPaged: {
          error: null,
          payload: InitPagedData,
          isPending: true,
          isSuccessful: false,
        },
      };
    case EmployeeTrainingActionTypes.FETCH_PAGED_EMPLOYEE_TRAINING_RESET:
      return {
        ...state,
        fetchPaged: resetApiCallState(InitPagedData),
      };
    case EmployeeTrainingActionTypes.FETCH_PAGED_EMPLOYEE_TRAINING_FAILURE:
      return {
        ...state,
        fetchPaged: {
          payload: InitPagedData,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case EmployeeTrainingActionTypes.FETCH_PAGED_EMPLOYEE_TRAINING_SUCCESS:
      return {
        ...state,
        fetchPaged: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case EmployeeTrainingActionTypes.FETCH_ONE_EMPLOYEE_TRAINING:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case EmployeeTrainingActionTypes.FETCH_ONE_EMPLOYEE_TRAINING_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case EmployeeTrainingActionTypes.FETCH_ONE_EMPLOYEE_TRAINING_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case EmployeeTrainingActionTypes.FETCH_ONE_EMPLOYEE_TRAINING_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default EmployeeTrainingReducer;
