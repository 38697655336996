import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { DepartmentRelationShipActionTypes } from "./DepartmentRelationShip.type";

export function* fetchAllDepartmentRelationShips(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => {
        if(action.payload[key]){
          return `${key}=${action.payload[key]}`
        }
      }).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/department-relationship?${query}`);
    yield put({
      type: DepartmentRelationShipActionTypes.FETCH_ALL_DEPARTMENT_RELATION_SHIP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DepartmentRelationShipActionTypes.FETCH_ALL_DEPARTMENT_RELATION_SHIP_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneDepartmentRelationShips(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/department-relationship/${action.payload}`
    );
    yield put({
      type: DepartmentRelationShipActionTypes.FETCH_ONE_DEPARTMENT_RELATION_SHIP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DepartmentRelationShipActionTypes.FETCH_ONE_DEPARTMENT_RELATION_SHIP_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllDepartmentRelationShips() {
  yield takeLatest(DepartmentRelationShipActionTypes.FETCH_ALL_DEPARTMENT_RELATION_SHIP, fetchAllDepartmentRelationShips);
}

export function* watcherFetchOneDepartmentRelationShips() {
  yield takeLatest(DepartmentRelationShipActionTypes.FETCH_ONE_DEPARTMENT_RELATION_SHIP, fetchOneDepartmentRelationShips);
}
