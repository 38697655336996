import { Moment } from "moment";
import { ApiCallState, PagedData } from "../Utils";

export type EmployeeTraining = {
  id?: number;
  key: number;
  date: Moment;
  description: string;
  institution: string;
};

export type EmployeeTrainingStateTypes = {
  fetchAll: ApiCallState<EmployeeTraining[]>;
  fetchOne: ApiCallState<EmployeeTraining | {}>;
  fetchPaged: ApiCallState<PagedData<EmployeeTraining[]>>;
};

export const EmployeeTrainingActionTypes = {
  FETCH_ALL_EMPLOYEE_TRAINING: "FETCH_ALL_EMPLOYEE_TRAINING",
  FETCH_ALL_EMPLOYEE_TRAINING_RESET: "FETCH_ALL_EMPLOYEE_TRAINING_RESET",
  FETCH_ALL_EMPLOYEE_TRAINING_FAILURE: "FETCH_ALL_EMPLOYEE_TRAINING_FAILURE",
  FETCH_ALL_EMPLOYEE_TRAINING_SUCCESS: "FETCH_ALL_EMPLOYEE_TRAINING_SUCCESS",

  FETCH_PAGED_EMPLOYEE_TRAINING: "FETCH_PAGED_EMPLOYEE_TRAINING",
  FETCH_PAGED_EMPLOYEE_TRAINING_RESET: "FETCH_PAGED_EMPLOYEE_TRAINING_RESET",
  FETCH_PAGED_EMPLOYEE_TRAINING_FAILURE:
    "FETCH_PAGED_EMPLOYEE_TRAINING_FAILURE",
  FETCH_PAGED_EMPLOYEE_TRAINING_SUCCESS:
    "FETCH_PAGED_EMPLOYEE_TRAINING_SUCCESS",

  FETCH_ONE_EMPLOYEE_TRAINING: "FETCH_ONE_EMPLOYEE_TRAINING",
  FETCH_ONE_EMPLOYEE_TRAINING_RESET: "FETCH_ONE_EMPLOYEE_TRAINING_RESET",
  FETCH_ONE_EMPLOYEE_TRAINING_FAILURE: "FETCH_ONE_EMPLOYEE_TRAINING_FAILURE",
  FETCH_ONE_EMPLOYEE_TRAINING_SUCCESS: "FETCH_ONE_EMPLOYEE_TRAINING_SUCCESS",
};
