import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { formatQuery } from "../Utils";
import { DocumentTransmitalActionTypes } from "./DocumentTransmital.type";

export function* fetchAllDocumentTransmital(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/document-transmital?${formatQuery(action)}`
    );
    yield put({
      type: DocumentTransmitalActionTypes.FETCH_ALL_DOCUMENT_TRANSMITAL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DocumentTransmitalActionTypes.FETCH_ALL_DOCUMENT_TRANSMITAL_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneDocumentTransmital(action: any): any {
  try {
    let query: any = "";

    if (action?.payload && Object.keys(action?.payload).length > 0) {
      query += Object.keys(action?.payload)
        .map((key) => `${key}=${action?.payload[key]}`)
        .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/document-transmital?${query}`
    );
    yield put({
      type: DocumentTransmitalActionTypes.FETCH_ONE_DOCUMENT_TRANSMITAL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DocumentTransmitalActionTypes.FETCH_ONE_DOCUMENT_TRANSMITAL_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllDocumentTransmital() {
  yield takeLatest(
    DocumentTransmitalActionTypes.FETCH_ALL_DOCUMENT_TRANSMITAL,
    fetchAllDocumentTransmital
  );
}

export function* watcherFetchOneDocumentTransmitals() {
  yield takeLatest(
    DocumentTransmitalActionTypes.FETCH_ONE_DOCUMENT_TRANSMITAL,
    fetchOneDocumentTransmital
  );
}
