import { Boq } from "../Boq/Boq.type";
import { ApiCallState } from "../Utils";

export type Rebar = {
  id: number | null;
  key: number | null;
  project_id: number | null;
  boq_id: number | null;
  location: string;
  structural_member_axis: string;
  length: number;
  bar_diameter: number;
  no_of_member: number;
  cc: number;
  no_of_bar: number;
  total: number;
  approval: number;
  sheet_name: string;
  pid: number;
  date: string;
  boq: Boq | null;
  super_title: string;
  type: "sub-contract" | "progress" | "variation";
};

export type RebarStateTypes = {
  fetchAll: ApiCallState<Rebar[]>;
};

export const RebarActionTypes = {
  FETCH_ALL_REBAR: "FETCH_ALL_REBAR",
  FETCH_ALL_REBAR_RESET: "FETCH_ALL_REBAR_RESET",
  FETCH_ALL_REBAR_FAILURE: "FETCH_ALL_REBAR_FAILURE",
  FETCH_ALL_REBAR_SUCCESS: "FETCH_ALL_REBAR_SUCCESS",
};
