import { Moment } from "moment";
import { ApiCallState } from "../Utils";

export type Task= {
  id: number | null;
  project_id:number;
  task_no:string;
  task_name:string;
  due_date:Moment;
  date:Moment;
  assigned_to:string;
  created_by:number;
  task_details:TaskDetail[];
};

export type TaskDetail ={
    id: number | null;
    task_id:number;
    check:boolean;
    description:string;
    remark:string;
}

export type TaskStateTypes = {
  fetchAll: ApiCallState<Task[]>;
  fetchOne: ApiCallState<Task | {}>;
};

export const TaskActionTypes = {
  FETCH_ALL_TASK: "FETCH_ALL_TASK",
  FETCH_ALL_TASK_RESET: "FETCH_ALL_TASK_RESET",
  FETCH_ALL_TASK_FAILURE: "FETCH_ALL_TASK_FAILURE",
  FETCH_ALL_TASK_SUCCESS: "FETCH_ALL_TASK_SUCCESS",

  FETCH_ONE_TASK: "FETCH_ONE_TASK",
  FETCH_ONE_TASK_RESET: "FETCH_ONE_TASK_RESET",
  FETCH_ONE_TASK_FAILURE: "FETCH_ONE_TASK_FAILURE",
  FETCH_ONE_TASK_SUCCESS: "FETCH_ONE_TASK_SUCCESS",
};
