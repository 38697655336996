import { BudgetStateTypes, BudgetActionTypes } from "./Budget.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: BudgetStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const BudgetReducer = (
  state: BudgetStateTypes = INITIAL_STATE,
  action: any
): BudgetStateTypes => {
  switch (action.type) {
    case BudgetActionTypes.FETCH_ALL_BUDGET:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case BudgetActionTypes.FETCH_ALL_BUDGET_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case BudgetActionTypes.FETCH_ALL_BUDGET_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case BudgetActionTypes.FETCH_ALL_BUDGET_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case BudgetActionTypes.FETCH_ONE_BUDGET:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case BudgetActionTypes.FETCH_ONE_BUDGET_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case BudgetActionTypes.FETCH_ONE_BUDGET_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case BudgetActionTypes.FETCH_ONE_BUDGET_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default BudgetReducer;
