import { MikadaAnnualReportStateTypes, MikadaAnnualReportActionTypes } from "./MikadaAnnualReport.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: MikadaAnnualReportStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const MikadaAnnualReportReducer = (
  state: MikadaAnnualReportStateTypes = INITIAL_STATE,
  action: any
): MikadaAnnualReportStateTypes => {
  switch (action.type) {
    case MikadaAnnualReportActionTypes.FETCH_ALL_MIKADA_ANNUAL_REPORT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MikadaAnnualReportActionTypes.FETCH_ALL_MIKADA_ANNUAL_REPORT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MikadaAnnualReportActionTypes.FETCH_ALL_MIKADA_ANNUAL_REPORT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MikadaAnnualReportActionTypes.FETCH_ALL_MIKADA_ANNUAL_REPORT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MikadaAnnualReportActionTypes.FETCH_ONE_MIKADA_ANNUAL_REPORT:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case MikadaAnnualReportActionTypes.FETCH_ONE_MIKADA_ANNUAL_REPORT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MikadaAnnualReportActionTypes.FETCH_ONE_MIKADA_ANNUAL_REPORT_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MikadaAnnualReportActionTypes.FETCH_ONE_MIKADA_ANNUAL_REPORT_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default MikadaAnnualReportReducer;
