import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";
import { formatQuery } from "../Utils";
import { API_BASE_URI } from "../ApiCall";
import { GoodReturnActionTypes } from "./GoodReturn.type";

export function* fetchAllGoodReturn(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/good-return?${formatQuery(action)}`
    );
    yield put({
      type: GoodReturnActionTypes.FETCH_ALL_GOOD_RETURN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GoodReturnActionTypes.FETCH_ALL_GOOD_RETURN_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneGoodReturn(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/good-return/${action.payload}`
    );
    yield put({
      type: GoodReturnActionTypes.FETCH_ONE_GOOD_RETURN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GoodReturnActionTypes.FETCH_ONE_GOOD_RETURN_FAILURE,
      payload: error,
    });
  }
}

export function* fetchReportGoodReturn(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/good-return/report?${formatQuery(action)}`
    );
    yield put({
      type: GoodReturnActionTypes.FETCH_REPORT_GOOD_RETURN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: GoodReturnActionTypes.FETCH_REPORT_GOOD_RETURN_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllGoodReturn() {
  yield takeLatest(
    GoodReturnActionTypes.FETCH_ALL_GOOD_RETURN,
    fetchAllGoodReturn
  );
}

export function* watcherFetchOneGoodReturn() {
  yield takeLatest(
    GoodReturnActionTypes.FETCH_ONE_GOOD_RETURN,
    fetchOneGoodReturn
  );
}

export function* watcherFetchReportGoodReturn() {
  yield takeLatest(
    GoodReturnActionTypes.FETCH_REPORT_GOOD_RETURN,
    fetchReportGoodReturn
  );
}
