import { FC, useEffect } from "react";
import { connect } from "react-redux";
import {
  AssessmentSummaryPropType,
  formatAssessmentSummary,
  formatEvaluator,
} from "./util/AssessmentSummary.util";
import { useParams } from "react-router-dom";
import { fetchOneApplicantRegistry } from "../../../redux/ApplicantRegistry/ApplicantRegistry.action";
import { Button, Card, DatePicker, Form, Select, Table } from "antd";
import Title from "antd/lib/typography/Title";
import moment from "moment";
import { useState } from "react";
import { fetchAllDepartments } from "../../../redux/Department/Department.action";
import PrintAssessmentSummary from "./Component/PrintAssessmentSummary";
import { ApplicantRegistry } from "../../../redux/ApplicantRegistry/ApplicantRegistry.type";
import { PrinterOutlined } from "@ant-design/icons";
import { format, formatNumber } from "../../../utilities/utilities";
const AssessmentSummary: FC<AssessmentSummaryPropType> = ({
  applicantRegistry,
  departments,
  fetchApplicantRegistry,
  fetchDepartments,
}) => {
  const [date, setDate] = useState(moment());
  const [department, setDepartment] = useState();
  const [selected, setSelected] = useState<ApplicantRegistry | null>();
  const [is_visible, setVisible] = useState(false);
  const params: any = useParams();
  useEffect(() => {
    fetchApplicantRegistry(params.id);
    fetchDepartments();
  }, []);

  return (
    <>
      <Card className="assessment hidden-print">
        <div className="row">
          <div className="col-md-12">
            <div className="row d-flex justify-content-center align-items-center">
              <Title level={5}>Candidate assessment summary</Title>
            </div>
          </div>

          <div className="col-md-3">
            <Form.Item label="Department">
              <Select value={department} onChange={(val) => setDepartment(val)}>
                {departments.payload.map((department) => (
                  <Select.Option value={department.name}>
                    {department.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item label="Position">
              {applicantRegistry?.payload?.vacancy_item?.position}
            </Form.Item>
          </div>
          <div className="col-md-2">
            <Form.Item label="Date">
              <DatePicker
                value={date}
                onChange={(val) => setDate(val!)}
                allowClear={false}
              />
            </Form.Item>
          </div>
          <div className="col-md-2"></div>
          <div className="col-md-1">
            <Button
              type="primary"
              icon={<PrinterOutlined />}
              onClick={() => {
                setSelected(applicantRegistry.payload);
                setVisible(true);
              }}
            >
              Print
            </Button>
          </div>
          <div className="col-md-12">
            <Table
              columns={[
                {
                  title: "Name of candidates",
                  dataIndex: "name",
                  width: "15%",
                },
                {
                  title: "Educational Level (30%)",
                  dataIndex: "educational_level",
                  width: "8%",
                  render: (record) => format(record),
                },
                {
                  title: "Work Experience (35%)",
                  dataIndex: "work_experience",
                  width: "8%",
                  render: (record) => format(record),
                },
                {
                  title: "Understand & Responsibility (15%)",
                  dataIndex: "understand_responsibility",
                  width: "8%",
                  render: (record) => format(record),
                },
                {
                  title: "Problem Solving Ability (10%)",
                  dataIndex: "problem_solving_ability",
                  width: "8%",
                  render: (record) => format(record),
                },
                {
                  title: "Personality (10%)",
                  dataIndex: "personality",
                  width: "8%",
                  render: (record) => format(record),
                },
                {
                  title: "Special Skill (20%)",
                  dataIndex: "special_skill",
                  width: "8%",
                  render: (record) => format(record),
                },
                {
                  title: "Technical Skill (20%)",
                  dataIndex: "technical_skill",
                  width: "8%",
                  render: (record) => format(record),
                },
                {
                  title: "Result (100-200)",
                  dataIndex: "total",
                  width: "8%",
                  render: (record) => format(record),
                },
                {
                  title: "Average Result (100%)",
                  dataIndex: "average_result",
                  width: "8%",
                  render: (record) => format(record),
                },
                {
                  title: "Rank",
                  dataIndex: "rank",
                  render: (record) => (
                    <div className="font-weight-bold">{record}</div>
                  ),
                },
                {
                  title: "Status",
                  dataIndex: "status",
                  render: (record) => (
                    <div style={{ color: "green" }}>{record}</div>
                  ),
                },
              ]}
              pagination={false}
              bordered
              loading={departments.isPending || applicantRegistry.isPending}
              dataSource={formatAssessmentSummary(
                applicantRegistry?.payload ?? {}
              )}
            />
          </div>
          <div className="col-md-12 mt-4">
            <Title level={5}>Committee: </Title>
            <div className="pl-4">
              {formatEvaluator(applicantRegistry?.payload ?? {}).map(
                (evaluator) => (
                  <div>{evaluator}</div>
                )
              )}
            </div>
          </div>
        </div>
      </Card>
      <PrintAssessmentSummary
        department={department!}
        date={date?.format("YYYY-MM-DD")}
        dataAction={[selected, setSelected]}
        visibilityAction={[is_visible, setVisible]}
      />
    </>
  );
};

/**
 * Map State to Props
 *
 * @param state
 */
const mapStateToProps = (state: any) => ({
  applicantRegistry: state.applicant_registry.fetchOne,
  departments: state.department.fetchAll,
});

/**
 * Map Dispatch to Props
 *
 * @param dispatch
 */
const mapDispatchToProps = (dispatch: any) => ({
  fetchApplicantRegistry: (action: any) =>
    dispatch(fetchOneApplicantRegistry(action)),
  fetchDepartments: () => dispatch(fetchAllDepartments()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentSummary);
