import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { TransferRequestActionTypes } from "./TransferRequest.type";

export function* fetchAllTransferRequests(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/transfer-request`);
    yield put({
      type: TransferRequestActionTypes.FETCH_ALL_TRANSFER_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TransferRequestActionTypes.FETCH_ALL_TRANSFER_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneTransferRequests(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/transfer-request/${action.payload}`
    );
    yield put({
      type: TransferRequestActionTypes.FETCH_ONE_TRANSFER_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TransferRequestActionTypes.FETCH_ONE_TRANSFER_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllTransferRequests() {
  yield takeLatest(TransferRequestActionTypes.FETCH_ALL_TRANSFER_REQUEST, fetchAllTransferRequests);
}

export function* watcherFetchOneTransferRequests() {
  yield takeLatest(TransferRequestActionTypes.FETCH_ONE_TRANSFER_REQUEST, fetchOneTransferRequests);
}
