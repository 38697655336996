import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ProjectPermanentAttendanceActionTypes } from "./ProjectPermanentAttendance.type";

export function* fetchAllProjectPermanentAttendances(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/project-permanent-attendance`);
    yield put({
      type: ProjectPermanentAttendanceActionTypes.FETCH_ALL_PROJECT_PERMANENT_ATTENDANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectPermanentAttendanceActionTypes.FETCH_ALL_PROJECT_PERMANENT_ATTENDANCE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneProjectPermanentAttendances(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/project-permanent-attendance/${action.payload}`
    );
    yield put({
      type: ProjectPermanentAttendanceActionTypes.FETCH_ONE_PROJECT_PERMANENT_ATTENDANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectPermanentAttendanceActionTypes.FETCH_ONE_PROJECT_PERMANENT_ATTENDANCE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllProjectPermanentAttendances() {
  yield takeLatest(ProjectPermanentAttendanceActionTypes.FETCH_ALL_PROJECT_PERMANENT_ATTENDANCE, fetchAllProjectPermanentAttendances);
}

export function* watcherFetchOneProjectPermanentAttendances() {
  yield takeLatest(ProjectPermanentAttendanceActionTypes.FETCH_ONE_PROJECT_PERMANENT_ATTENDANCE, fetchOneProjectPermanentAttendances);
}
