import { InitPagedData } from "./../Utils";
import {
  MaterialSubCategoryStateTypes,
  MaterialSubCategoryActionTypes,
} from "./MaterialSubCategory.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: MaterialSubCategoryStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchAllForm: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchPaged: resetApiCallState(InitPagedData),
};

const MaterialSubCategoryReducer = (
  state: MaterialSubCategoryStateTypes = INITIAL_STATE,
  action: any
): MaterialSubCategoryStateTypes => {
  switch (action.type) {
    case MaterialSubCategoryActionTypes.FETCH_ALL_MATERIAL_SUB_CATEGORY:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaterialSubCategoryActionTypes.FETCH_ALL_MATERIAL_SUB_CATEGORY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MaterialSubCategoryActionTypes.FETCH_ALL_MATERIAL_SUB_CATEGORY_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaterialSubCategoryActionTypes.FETCH_ALL_MATERIAL_SUB_CATEGORY_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    // ---------------------------------

    case MaterialSubCategoryActionTypes.FETCH_ALL_FORM_MATERIAL_SUB_CATEGORY:
      return {
        ...state,
        fetchAllForm: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaterialSubCategoryActionTypes.FETCH_ALL_FORM_MATERIAL_SUB_CATEGORY_RESET:
      return {
        ...state,
        fetchAllForm: resetApiCallState([]),
      };
    case MaterialSubCategoryActionTypes.FETCH_ALL_FORM_MATERIAL_SUB_CATEGORY_FAILURE:
      return {
        ...state,
        fetchAllForm: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaterialSubCategoryActionTypes.FETCH_ALL_FORM_MATERIAL_SUB_CATEGORY_SUCCESS:
      return {
        ...state,
        fetchAllForm: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    // ---------------------------------

    case MaterialSubCategoryActionTypes.FETCH_PAGED_MATERIAL_SUB_CATEGORY:
      return {
        ...state,
        fetchPaged: {
          error: null,
          payload: InitPagedData,
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaterialSubCategoryActionTypes.FETCH_PAGED_MATERIAL_SUB_CATEGORY_RESET:
      return {
        ...state,
        fetchPaged: resetApiCallState(InitPagedData),
      };
    case MaterialSubCategoryActionTypes.FETCH_PAGED_MATERIAL_SUB_CATEGORY_FAILURE:
      return {
        ...state,
        fetchPaged: {
          payload: InitPagedData,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaterialSubCategoryActionTypes.FETCH_PAGED_MATERIAL_SUB_CATEGORY_SUCCESS:
      return {
        ...state,
        fetchPaged: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MaterialSubCategoryActionTypes.FETCH_ONE_MATERIAL_SUB_CATEGORY:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaterialSubCategoryActionTypes.FETCH_ONE_MATERIAL_SUB_CATEGORY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MaterialSubCategoryActionTypes.FETCH_ONE_MATERIAL_SUB_CATEGORY_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaterialSubCategoryActionTypes.FETCH_ONE_MATERIAL_SUB_CATEGORY_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default MaterialSubCategoryReducer;
