import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { BriefingFormActionTypes } from "./BriefingForm.type";

export function* fetchAllBriefingForms(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/briefing-form?project_id=${action.payload?.project_id}`);
    yield put({
      type: BriefingFormActionTypes.FETCH_ALL_BRIEFING_FORM_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BriefingFormActionTypes.FETCH_ALL_BRIEFING_FORM_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneBriefingForms(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/briefing-form/${action.payload}`
    );
    yield put({
      type: BriefingFormActionTypes.FETCH_ONE_BRIEFING_FORM_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BriefingFormActionTypes.FETCH_ONE_BRIEFING_FORM_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllBriefingForms() {
  yield takeLatest(BriefingFormActionTypes.FETCH_ALL_BRIEFING_FORM, fetchAllBriefingForms);
}

export function* watcherFetchOneBriefingForms() {
  yield takeLatest(BriefingFormActionTypes.FETCH_ONE_BRIEFING_FORM, fetchOneBriefingForms);
}
