import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { OfferActionTypes } from "./Offer.type";

export function* fetchAllOffers(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/offer?${query}`);
    yield put({
      type: OfferActionTypes.FETCH_ALL_OFFER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: OfferActionTypes.FETCH_ALL_OFFER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneOffers(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/offer/${action.payload}`);
    yield put({
      type: OfferActionTypes.FETCH_ONE_OFFER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: OfferActionTypes.FETCH_ONE_OFFER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllOffers() {
  yield takeLatest(OfferActionTypes.FETCH_ALL_OFFER, fetchAllOffers);
}

export function* watcherFetchOneOffers() {
  yield takeLatest(OfferActionTypes.FETCH_ONE_OFFER, fetchOneOffers);
}
