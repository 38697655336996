import Menu from "antd/lib/menu";
import { useHistory,useLocation } from "react-router-dom";
import { RouteConstants } from "../../../router/Constants";
import { useEffect, useState } from "react";
import { HRTabs, MODULE } from "../../../constants/Constants";
import { checkAuthorization, checkModuleAuthorization } from "../../../utilities/utilities";
import { ItemType } from "antd/lib/menu/hooks/useItems";

const HeaderMenuComponent = () => {
  // const history = useHistory();
  // const [selected, setSelected]: any = useState();
  // const [openKeys, setOpenKeys] = useState(["project"]);
  // const rootSubmenuKeys = ["project", "sub_contract"];

  const history = useHistory();
  const location = useLocation();
  const [items, setItems] = useState<ItemType[]>([]);
  const [active, setActive] = useState<string>("");

  useEffect(() => {
    let found = location.pathname.split("/")[1];

    if (found) {
      setActive(RouteConstants[found.split("-").join("_").toUpperCase()]);
    }
  }, [location]);

  useEffect(() => {
    let authorized_items: ItemType[] = [];
    for (let module in MODULE) {
      if (checkModuleAuthorization(MODULE[module], true))
        authorized_items.push({
          key: RouteConstants[module],
          label: MODULE[module],
        });
    }

    setItems(authorized_items);
  }, []);

  // useEffect(() => {
  //   if (
  //     checkAuthorization(RouteConstants.REGISTER_PROJECT) ||
  //     checkAuthorization(RouteConstants.PROJECT_LIST)
  //   ) {
  //     setSelected(RouteConstants.PROJECTS);
  //     history.push(RouteConstants.PROJECTS);
  //   } else if (checkAuthorization(RouteConstants.HUMAN_RESOURCE)) {
  //     setSelected("/hr/" + HRTabs.ORG_STRUCTURE.toLowerCase());
  //     history.push("/hr/" + HRTabs.ORG_STRUCTURE.toLowerCase());
  //   } else if (checkAuthorization(RouteConstants.OPERATION)) {
  //     setSelected(RouteConstants.OPERATION);
  //     history.push(RouteConstants.OPERATION);
  //   } else if (checkAuthorization(RouteConstants.FINANCE)) {
  //     setSelected(RouteConstants.FINANCE);
  //     history.push(RouteConstants.FINANCE);
  //   } else if (checkAuthorization(RouteConstants.PROCUREMENT)) {
  //     setSelected(RouteConstants.PROCUREMENT);
  //     history.push(RouteConstants.PROCUREMENT);
  //   }else if (checkAuthorization(RouteConstants.INVENTORY)) {
  //     setSelected(RouteConstants.INVENTORY);
  //     history.push(RouteConstants.INVENTORY);
  //   }else if (checkAuthorization(RouteConstants.FIXED_ASSET)) {
  //     setSelected(RouteConstants.FIXED_ASSET);
  //     history.push(RouteConstants.FIXED_ASSET);
  //   }
  // }, []);

  // const onOpenChange = (keys: any) => {
  //   const latestOpenKey = keys.find((key: any) => openKeys.indexOf(key) === -1);

  //   if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
  //     setOpenKeys(keys);
  //   } else {
  //     setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  //   }
  // };


  return (
    <div className="hidden-print header-menu">
      <Menu
        activeKey={active}
        mode="horizontal"
        onSelect={(e) => {
          history.push(e.key.toString());
        }}
        theme="light"
        items={items}
      />
    </div>
  );

  // return (
  //   <div className="hidden-print header-menu">
  //     <Menu
  //       {...(!localStorage.getItem("/")
  //         ? { openKeys: openKeys, onOpenChange: onOpenChange }
  //         : null)}
  //       mode="horizontal"
  //       selectedKeys={[selected]}
  //       defaultSelectedKeys={["project"]}
  //       onSelect={(e: any) => {
  //         setSelected(e.key.toString());
  //         history.replace(e.key.toString());
  //       }}
  //       theme="light"
  //       // className="side_bar"
  //     >
  //       {checkAuthorization(RouteConstants.REGISTER_PROJECT) ? (
  //         <Menu.Item key={RouteConstants.PROJECTS} title="Project">
  //           Project
  //         </Menu.Item>
  //       ) : checkAuthorization(RouteConstants.PROJECT_LIST) ? (
  //         <Menu.Item
  //           key={RouteConstants.PROJECTS}
  //           // icon={<AppstoreAddOutlined />}
  //           title="Project"
  //         >
  //           Projects
  //         </Menu.Item>
  //       ) : null}

  //       {checkAuthorization(RouteConstants.HUMAN_RESOURCE) ? (
  //         <Menu.Item
  //           key={"/hr/" + HRTabs.ORG_STRUCTURE.toLowerCase()}
  //           // icon={<UserOutlined />}
  //           title="Human Resource"
  //         >
  //           Human Resource
  //         </Menu.Item>
  //       ) : null}

  //       {checkAuthorization(RouteConstants.OPERATION) ? (
  //         <Menu.Item key={RouteConstants.OPERATION} title="Operation">
  //           Operation
  //         </Menu.Item>
  //       ) : null}

  //       {checkAuthorization(RouteConstants.FINANCE) ? (
  //         <Menu.Item key={RouteConstants.FINANCE} title="Finance">
  //           Finance
  //         </Menu.Item>
  //       ) : null}

  //       {checkAuthorization(RouteConstants.PROCUREMENT) ? (
  //         <Menu.Item key={RouteConstants.PROCUREMENT} title="Procurement">
  //           Procurement
  //         </Menu.Item>
  //       ) : null}

  //       {checkAuthorization(RouteConstants.INVENTORY) ? (
  //         <Menu.Item key={RouteConstants.INVENTORY} title="Inventory">
  //           Inventory
  //         </Menu.Item>
  //       ) : null}

  //       {checkAuthorization(RouteConstants.FIXED_ASSET) ? (
  //         <Menu.Item key={RouteConstants.FIXED_ASSET} title="Fixed Asset">
  //           Fixed Asset
  //         </Menu.Item>
  //       ) : null}
  //     </Menu>
  //   </div>
  // );
};
export default HeaderMenuComponent;
