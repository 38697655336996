import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { OilLubricantCostActionTypes } from "./OilLubricantCost.type";

export function* fetchAllOilLubricantCosts(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/oil-lubricant-cost?project_id=${action.payload.id}`);
    yield put({
      type: OilLubricantCostActionTypes.FETCH_ALL_OIL_LUBRICANT_COST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: OilLubricantCostActionTypes.FETCH_ALL_OIL_LUBRICANT_COST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneOilLubricantCosts(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/oil-lubricant-cost/date?project_id=${action.payload?.project_id}&date=${action.payload?.date}`
    );
    yield put({
      type: OilLubricantCostActionTypes.FETCH_ONE_OIL_LUBRICANT_COST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: OilLubricantCostActionTypes.FETCH_ONE_OIL_LUBRICANT_COST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllOilLubricantCosts() {
  yield takeLatest(OilLubricantCostActionTypes.FETCH_ALL_OIL_LUBRICANT_COST, fetchAllOilLubricantCosts);
}

export function* watcherFetchOneOilLubricantCosts() {
  yield takeLatest(OilLubricantCostActionTypes.FETCH_ONE_OIL_LUBRICANT_COST, fetchOneOilLubricantCosts);
}
