import { ApiCallState } from "../Utils";

export type TimeUtilization = {
  id: number | null;
  description: string;
  plateNumber: string;
  supplierName: string;
  plannedHour: number;
  availableHours: number;
  utilizedHours: number;
  engineeringHour: number;
  availabilityEfficiency: number;
  utilizationEfficiency: number;
  overallEfficiency: number;
  remark: string;
  own_vehicle_id: number;
};

export type UpdatedTimeUtilizationReport = {
  id: number;
  start_date: string;
  end_date: string;
  report_date: string;
  prepared_by: string;
  items: TimeUtilization[];
};

export type TimeUtilizationReportStateTypes = {
  fetchAll: ApiCallState<UpdatedTimeUtilizationReport[]>;
  fetchReport: ApiCallState<TimeUtilization[]>;
};

export const TimeUtilizationReportActionTypes = {
  FETCH_ALL_TIME_UTILIZATION_REPORT: "FETCH_ALL_TIME_UTILIZATION_REPORT",
  FETCH_ALL_TIME_UTILIZATION_REPORT_RESET:
    "FETCH_ALL_TIME_UTILIZATION_REPORT_RESET",
  FETCH_ALL_TIME_UTILIZATION_REPORT_FAILURE:
    "FETCH_ALL_TIME_UTILIZATION_REPORT_FAILURE",
  FETCH_ALL_TIME_UTILIZATION_REPORT_SUCCESS:
    "FETCH_ALL_FUEL_UTILIZATION_REPORT_SUCCESS",

  FETCH_TIME_UTILIZATION_REPORT: "FETCH_TIME_UTILIZATION_REPORT",
  FETCH_TIME_UTILIZATION_REPORT_RESET: "FETCH_TIME_UTILIZATION_REPORT_RESET",
  FETCH_TIME_UTILIZATION_REPORT_FAILURE:
    "FETCH_TIME_UTILIZATION_REPORT_FAILURE",
  FETCH_TIME_UTILIZATION_REPORT_SUCCESS:
    "FETCH_FUEL_UTILIZATION_REPORT_SUCCESS",
};
