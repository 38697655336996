import { CheckPaymentVoucherStateTypes, CheckPaymentVoucherActionTypes } from "./CheckPaymentVoucher.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: CheckPaymentVoucherStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const CheckPaymentVoucherReducer = (
  state: CheckPaymentVoucherStateTypes = INITIAL_STATE,
  action: any
): CheckPaymentVoucherStateTypes => {
  switch (action.type) {
    case CheckPaymentVoucherActionTypes.FETCH_ALL_CHECK_PAYMENT_VOUCHER:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case CheckPaymentVoucherActionTypes.FETCH_ALL_CHECK_PAYMENT_VOUCHER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case CheckPaymentVoucherActionTypes.FETCH_ALL_CHECK_PAYMENT_VOUCHER_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case CheckPaymentVoucherActionTypes.FETCH_ALL_CHECK_PAYMENT_VOUCHER_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case CheckPaymentVoucherActionTypes.FETCH_ONE_CHECK_PAYMENT_VOUCHER:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case CheckPaymentVoucherActionTypes.FETCH_ONE_CHECK_PAYMENT_VOUCHER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case CheckPaymentVoucherActionTypes.FETCH_ONE_CHECK_PAYMENT_VOUCHER_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case CheckPaymentVoucherActionTypes.FETCH_ONE_CHECK_PAYMENT_VOUCHER_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default CheckPaymentVoucherReducer;
