import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { StaffPlaceOfWorkActionTypes } from "./StaffPlaceOfWork.type";

export function* fetchAllStaffPlaceOfWorks(action: any): any {
  try {
    let query = "";

    if (action.payload && Object.keys(action.payload).length > 0) {
      query =
        "?" +
        Object.keys(action.payload)
          .map((key) => `${key}=${action.payload[key]}`)
          .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/staff-place-of-work${query}`
    );
    yield put({
      type: StaffPlaceOfWorkActionTypes.FETCH_ALL_STAFF_PLACE_OF_WORK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffPlaceOfWorkActionTypes.FETCH_ALL_STAFF_PLACE_OF_WORK_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllStaffPlaceOfWorks() {
  yield takeLatest(
    StaffPlaceOfWorkActionTypes.FETCH_ALL_STAFF_PLACE_OF_WORK,
    fetchAllStaffPlaceOfWorks
  );
}
