import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { BoqActivityPlanActionTypes } from "./BoqActivityPlan.type";

export function* fetchAllBoqActivityPlans(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/boq_activity_plan?project_id=${action.payload.project_id}&date=${action.payload.date}&date_type=${action.payload.date_type}`
    );
    yield put({
      type: BoqActivityPlanActionTypes.FETCH_ALL_BOQ_ACTIVITY_PLAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BoqActivityPlanActionTypes.FETCH_ALL_BOQ_ACTIVITY_PLAN_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllBoqActivityPlans() {
  yield takeLatest(
    BoqActivityPlanActionTypes.FETCH_ALL_BOQ_ACTIVITY_PLAN,
    fetchAllBoqActivityPlans
  );
}
