import { Material } from "../Material/Material.type";
import { MaterialRequisition } from "../MaterialRequisition/MaterialRequisition.type";
import { Proforma } from "../Proforma/Proforma.type";
import { Store } from "../Store/Store.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type PurchaseRequisition = {
  user_id: number;
  material_requisition_id: number;
  service_request_id: number;
  requested_to: string;
  department_id?: number;
  project_id?: number;
  pr_requested_by: Store;
  pr_requested_to: Store;
  requested_by_string: string;
  purpose: string;
  date: string;
  budget_line: string;
  work_line: string;
  delivery_time: string;
  delivery_type: string;
  source_of_finance: string;
  is_void: boolean;
  id: number;
  remark: string;
  pad_ref: string;
  purchase_place: string;
  purchase_requisition_items: PurchaseRequisitionItem[];
  requested_by: string;
  has_sr: boolean;
  pr_prepared_by?: User;
  pr_approved_by?: User;
  pr_checked_by?: User;
  purchase_requisition_statuses: PurchaseRequisitionStatus[];
  is_done: boolean;
  status: PurchaseRequisitionStatus[];
  material_requisition: MaterialRequisition;
  updatedAt: Date;
  received_date?: Date;
  purchaser_id?: number;
  purchaser?: User;
  type: string;
  proformas: Proforma[];
  prepared_by?: number;
  checked_by?: number;
  approved_by?: number;
};

export type PurchaseRequisitionStatus = {
  purchase_requisition_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
  user: User;
};

export type PurchaseRequisitionItem = {
  id: any;
  material_id: number;
  material: Material;
  quantity: number;
  unit: string;
  unit_price: number;
  estimate_cost: number;
  required_specification: string;
  model_no: string;
  serial_no: string;
  plate_no: string;
  part_no: string;
  equipment_code: string;
  engine_no: string;
  chassis_no: string;
  detail_specification: string;
  remark: string;
};

export type PRTracking = {
  purchaser: string;
  lpr_date: string;
  pls_received_date: string;
  lpr_no: string;
  quantity: number;
  description: string;
  supplier: string;
  po_issued: "Y" | "N";
  po_no: string;
  actual_price: number;
  po_date: string;
  receipt_by_phison_date: string;
  date_delivered_to_site: string;
  current_status: "Open" | "Closed";
  eta_phison: string;
  eta_site: string;
  lpr_date_TO_pls_received_date: number;
  pls_received_date_TO_po_date: number;
  po_date_TO_po_received_date: number;
  receipt_TO_site_delivery: number;
  total_pr_time: number;
};

export type PurchaseRequisitionStateTypes = {
  fetchAll: ApiCallState<PurchaseRequisition[]>;
  fetchOne: ApiCallState<PurchaseRequisition | {}>;
  fetchReport: ApiCallState<any[]>;
  fetchPRTracking: ApiCallState<PRTracking[]>;
};

export const PurchaseRequisitionActionTypes = {
  FETCH_ALL_PURCHASE_REQUISITION: "FETCH_ALL_PURCHASE_REQUISITION",
  FETCH_ALL_PURCHASE_REQUISITION_RESET: "FETCH_ALL_PURCHASE_REQUISITION_RESET",
  FETCH_ALL_PURCHASE_REQUISITION_FAILURE:
    "FETCH_ALL_PURCHASE_REQUISITION_FAILURE",
  FETCH_ALL_PURCHASE_REQUISITION_SUCCESS:
    "FETCH_ALL_PURCHASE_REQUISITION_SUCCESS",

  FETCH_ONE_PURCHASE_REQUISITION: "FETCH_ONE_PURCHASE_REQUISITION",
  FETCH_ONE_PURCHASE_REQUISITION_RESET: "FETCH_ONE_PURCHASE_REQUISITION_RESET",
  FETCH_ONE_PURCHASE_REQUISITION_FAILURE:
    "FETCH_ONE_PURCHASE_REQUISITION_FAILURE",
  FETCH_ONE_PURCHASE_REQUISITION_SUCCESS:
    "FETCH_ONE_PURCHASE_REQUISITION_SUCCESS",

  FETCH_REPORT_PURCHASE_REQUISITION: "FETCH_REPORT_PURCHASE_REQUISITION",
  FETCH_REPORT_PURCHASE_REQUISITION_RESET:
    "FETCH_REPORT_PURCHASE_REQUISITION_RESET",
  FETCH_REPORT_PURCHASE_REQUISITION_FAILURE:
    "FETCH_REPORT_PURCHASE_REQUISITION_FAILURE",
  FETCH_REPORT_PURCHASE_REQUISITION_SUCCESS:
    "FETCH_REPORT_PURCHASE_REQUISITION_SUCCESS",

  FETCH_PURCHASE_REQUISITION_TRACKING: "FETCH_PURCHASE_REQUISITION_TRACKING",
  FETCH_PURCHASE_REQUISITION_TRACKING_RESET:
    "FETCH_PURCHASE_REQUISITION_TRACKING_RESET",
  FETCH_PURCHASE_REQUISITION_TRACKING_FAILURE:
    "FETCH_PURCHASE_REQUISITION_TRACKING_FAILURE",
  FETCH_PURCHASE_REQUISITION_TRACKING_SUCCESS:
    "FETCH_PURCHASE_REQUISITION_TRACKING_SUCCESS",
};
