import { ApiCallState, PagedData } from "../Utils";

export type Boq = {
  id: number;
  project_id: number;
  item_group: string;
  is_super_title: boolean;
  is_title: boolean;
  description: string;
  item_no: string;
  unit: string;
  quantity: number;
  unit_price: number;
  sheet_name: string;
  is_selected: boolean;
  sub_contract_payments: SubContractPayment[]
};

export type PreContractLS = {
  quantity: number;
  unit_price: number;
  overhead_percentage: number;
  profit_percentage: number;
  direct_cost: number;
  id?: number;
};

export const init_boq = {
  project_id: null,
  id: null,
  super_title: "",
  title: null,
  description: "",
  item_group: "",
  sub_title: null,
  task_name: "",
  item_no: "",
  unit_price: 0,
  reference_id: null,
  remark: "",
  quantity: 0,
  total: 0,
  unit: "",
  current_quantity: 0,
  previous_quantity: 0,
  sheet_name: "",
  key: Date.now(),
  variation: null,
  rebars: [],
  take_offs: [],
  aggregate_takeoff: null,
  road_take_offs: [],
  is_variation: false,
  price_escalations: [],
  sub_contract_payments: [],
  order_id: null
};

export type RoadTakeoff = {
  key: number;
  id: any;
  boq_id: any;
  description: string;
  design_quantity: number;
  road_take_off_items: RoadTakeoffItem[];
};

export type RoadTakeoffItem = {
  id: any;
  quantity: number;
  date: Date;
  project_id: number;
  pid: any;
  type: "sub-contract" | "progress" | "variation";
};

export type AggregateTakeoff = {
  key: number;
  id: number | null;
  boq_id: number | null;
  aggregate_take_off_items: AggregateTakeoffItem[];
  boq: Boq;
};
export type AggregateTakeoffItem = {
  id: any;
  quantity: number;
  approval: number;
  type: "sub-contract" | "progress" | "variation" | "pre-contract";
  pid: number;
  date: Date;
  aggregate_takeoff: AggregateTakeoff;
};

export type BoqStateTypes = {
  fetchAll: ApiCallState<Boq[]>;
  fetchOne: ApiCallState<Boq | null>;
  fetchDetail: ApiCallState<Boq[]>;
  fetchSubContract: ApiCallState<Boq[]>;
  fetchPaged: ApiCallState<PagedData<Boq[]>>;
  fetchSheetName: ApiCallState<String[]>;
  fetchTotal: ApiCallState<{ amount: number }>;
};

export type SubContractPayment = {
  quantity: number;
  date: string;
  id: number;
};

export const BoqActionTypes = {
  FETCH_ALL_BOQ: "FETCH_ALL_BOQ",
  FETCH_ALL_BOQ_RESET: "FETCH_ALL_BOQ_RESET",
  FETCH_ALL_BOQ_FAILURE: "FETCH_ALL_BOQ_FAILURE",
  FETCH_ALL_BOQ_SUCCESS: "FETCH_ALL_BOQ_SUCCESS",

  FETCH_ONE_BOQ: "FETCH_ONE_BOQ",
  FETCH_ONE_BOQ_RESET: "FETCH_ONE_BOQ_RESET",
  FETCH_ONE_BOQ_FAILURE: "FETCH_ONE_BOQ_FAILURE",
  FETCH_ONE_BOQ_SUCCESS: "FETCH_ONE_BOQ_SUCCESS",

  FETCH_DETAIL_BOQ: "FETCH_DETAIL_BOQ",
  FETCH_DETAIL_BOQ_RESET: "FETCH_DETAIL_BOQ_RESET",
  FETCH_DETAIL_BOQ_FAILURE: "FETCH_DETAIL_BOQ_FAILURE",
  FETCH_DETAIL_BOQ_SUCCESS: "FETCH_DETAIL_BOQ_SUCCESS",

  FETCH_SUB_CONTRACT_BOQ: "FETCH_SUB_CONTRACT_BOQ",
  FETCH_SUB_CONTRACT_BOQ_RESET: "FETCH_SUB_CONTRACT_BOQ_RESET",
  FETCH_SUB_CONTRACT_BOQ_FAILURE: "FETCH_SUB_CONTRACT_BOQ_FAILURE",
  FETCH_SUB_CONTRACT_BOQ_SUCCESS: "FETCH_SUB_CONTRACT_BOQ_SUCCESS",

  FETCH_BOQ_TOTAL: "FETCH_BOQ_TOTAL",
  FETCH_BOQ_TOTAL_RESET: "FETCH_BOQ_TOTAL_RESET",
  FETCH_BOQ_TOTAL_FAILURE: "FETCH_BOQ_TOTAL_FAILURE",
  FETCH_BOQ_TOTAL_SUCCESS: "FETCH_BOQ_TOTAL_SUCCESS",

  FETCH_UNIT_BOQ: "FETCH_UNIT_BOQ",
  FETCH_UNIT_BOQ_RESET: "FETCH_UNIT_BOQ_RESET",
  FETCH_UNIT_BOQ_FAILURE: "FETCH_UNIT_BOQ_FAILURE",
  FETCH_UNIT_BOQ_SUCCESS: "FETCH_UNIT_BOQ_SUCCESS",

  FETCH_SHEET_NAME: "FETCH_SHEET_NAME",
  FETCH_SHEET_NAME_RESET: "FETCH_SHEET_NAME_RESET",
  FETCH_SHEET_NAME_FAILURE: "FETCH_SHEET_NAME_FAILURE",
  FETCH_SHEET_NAME_SUCCESS: "FETCH_SHEET_NAME_SUCCESS",
  
  FETCH_PAGED_BOQ: "FETCH_PAGED_BOQ",
  FETCH_PAGED_BOQ_RESET: "FETCH_PAGED_BOQ_RESET",
  FETCH_PAGED_BOQ_FAILURE: "FETCH_PAGED_BOQ_FAILURE",
  FETCH_PAGED_BOQ_SUCCESS: "FETCH_PAGED_BOQ_SUCCESS",
};
