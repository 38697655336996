import { Material } from "../Material/Material.type";
import { User } from "../User/User.type";
import { ApiCallState, PagedData } from "../Utils";

export type Dispatch = {
  id: number;
  user_id: number;
  date: string;
  plate_no: string;
  transfer_no: string;
  from: string;
  to: string;
  reason: string;
  driver_name: string;
  received_by: string;
  approved_by: number;
  dispatch_remarks: DispatchRemark[];
  dispatch_statuses: DispatchStatus[];
  status: DispatchStatus[];
  dispatch_items: DispatchItem[];
  dispatch_prepared_by: User;
  dispatch_approved_by: User;
  dispatch_received_by: User;
  updatedAt: string;
  is_void: boolean;
};

export type DispatchReport = {
  approved_by?: any;
  code: string;
  date: string;
  description: string;
  from: string;
  to: string;
  item_category: string;
  id: number;
  major_category: string;
  material_requisition_id: string;
  pad_ref?: any;
  prepared_by: string;
  quantity: number;
  received_by?: any;
  remark: string;
  total: number;
  unit: string;
};

export type DispatchRemark = {
  time: string;
  remark: string;
  user_id: number;
  document?: Document;
};

export type DispatchStatus = {
  dispatch_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type DispatchItem = {
  id?: number;
  key: number;
  material_id?: number;
  serial_number: string;
  mfg_part_no: string;
  unit: string;
  unit_price: number;
  quantity: number;
  remark: string;
  material?: Material;
};

export type DispatchStateTypes = {
  fetchAll: ApiCallState<Dispatch[]>;
  fetchOne: ApiCallState<Dispatch | {}>;
  fetchPaged: ApiCallState<PagedData<Dispatch[]>>;
  fetchReport: ApiCallState<DispatchReport[]>;
};

export const DispatchActionTypes = {
  FETCH_ALL_DISPATCH: "FETCH_ALL_DISPATCH",
  FETCH_ALL_DISPATCH_RESET: "FETCH_ALL_DISPATCH_RESET",
  FETCH_ALL_DISPATCH_FAILURE: "FETCH_ALL_DISPATCH_FAILURE",
  FETCH_ALL_DISPATCH_SUCCESS: "FETCH_ALL_DISPATCH_SUCCESS",

  FETCH_PAGED_DISPATCH: "FETCH_PAGED_DISPATCH",
  FETCH_PAGED_DISPATCH_RESET: "FETCH_PAGED_DISPATCH_RESET",
  FETCH_PAGED_DISPATCH_FAILURE: "FETCH_PAGED_DISPATCH_FAILURE",
  FETCH_PAGED_DISPATCH_SUCCESS: "FETCH_PAGED_DISPATCH_SUCCESS",

  FETCH_ONE_DISPATCH: "FETCH_ONE_DISPATCH",
  FETCH_ONE_DISPATCH_RESET: "FETCH_ONE_DISPATCH_RESET",
  FETCH_ONE_DISPATCH_FAILURE: "FETCH_ONE_DISPATCH_FAILURE",
  FETCH_ONE_DISPATCH_SUCCESS: "FETCH_ONE_DISPATCH_SUCCESS",

  FETCH_REPORT_DISPATCH: "FETCH_REPORT_DISPATCH",
  FETCH_REPORT_DISPATCH_RESET: "FETCH_REPORT_DISPATCH_RESET",
  FETCH_REPORT_DISPATCH_FAILURE: "FETCH_REPORT_DISPATCH_FAILURE",
  FETCH_REPORT_DISPATCH_SUCCESS: "FETCH_REPORT_DISPATCH_SUCCESS",
};
