import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { StaffDisciplineActionTypes } from "./StaffDiscipline.type";

export function* fetchAllStaffDisciplines(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/staff-discipline?staff_id=${action.payload?.staff_id}`);
    yield put({
      type: StaffDisciplineActionTypes.FETCH_ALL_STAFF_DISCIPLINE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffDisciplineActionTypes.FETCH_ALL_STAFF_DISCIPLINE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneStaffDisciplines(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/staff-discipline/${action.payload}`
    );
    yield put({
      type: StaffDisciplineActionTypes.FETCH_ONE_STAFF_DISCIPLINE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffDisciplineActionTypes.FETCH_ONE_STAFF_DISCIPLINE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllStaffDisciplines() {
  yield takeLatest(StaffDisciplineActionTypes.FETCH_ALL_STAFF_DISCIPLINE, fetchAllStaffDisciplines);
}

export function* watcherFetchOneStaffDisciplines() {
  yield takeLatest(StaffDisciplineActionTypes.FETCH_ONE_STAFF_DISCIPLINE, fetchOneStaffDisciplines);
}
