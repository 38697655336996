import _ from "lodash";

class ProjectPermanentAttendance {
  data: any[];
  sheet_name: string;
  type: any;
  parsed: any[];

  constructor(data: any, sheet_name: string) {
    this.data = data;
    this.type = "";
    this.parsed = [];
    this.sheet_name = sheet_name;
  }

  parseBoq() {
    let start = -1;
    this.data.forEach((col: any[], index) => {
      if (this.isTableState(col)) start = index;
      else if (start !== -1) {
        if (col[0] && col[1] && col[2]) {
          this.parsed.push({
            name: col[1],
            position: col[2] ? col[2]:"",
            working_day: col[3] ? col[3]:0,
            perdiem: col[4] ? col[4]:0 ,
            desert_allowance: col[5] ? col[5]:0,
            normal: col[6] ? col[6]:0,
            sunday:col[7] ? col[7]:0,
            holyday:col[8] ? col[8]:0,
            remark: col[9] ? col[9]:"",
            key: this.parsed.length,
          });
        }
      }
    });
    return this.parsed;
  }

  private isTableState(col: any[]) {
    return (
      _.isString(col[0]) &&
      _.isString(col[1]) &&
      _.isString(col[2]) &&
      _.isString(col[3]) &&
      _.isString(col[4]) &&
      _.isString(col[5]) &&
      _.isString(col[6]) &&
      _.isString(col[9]) 
    );
  }
}
export default ProjectPermanentAttendance;
