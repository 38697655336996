import { ApiCallState } from "../Utils";
export type MonthlyProgressReport = {
  id: number;
  date: string;
  revised_contract_period: number;
  approved_variation_amount: number;
  remaining_contract_period: number;
  total_elapsed_date: number; 
  price_adjustment: number;
  to_date_executed_amount: number;
  month_year_progress: number;
  remark: string;
  project_id: number;
}; 

export type MonthlyProgressReportStateTypes = {
  fetchAll: ApiCallState<MonthlyProgressReport[]>;
  fetchOne: ApiCallState<MonthlyProgressReport | {}>;
};

export const MonthlyProgressReportActionTypes = {
  FETCH_ALL_MONTHLY_PROGRESS_REPORT: "FETCH_ALL_MONTHLY_PROGRESS_REPORT",
  FETCH_ALL_MONTHLY_PROGRESS_REPORT_RESET:
    "FETCH_ALL_MONTHLY_PROGRESS_REPORT_RESET",
  FETCH_ALL_MONTHLY_PROGRESS_REPORT_FAILURE:
    "FETCH_ALL_MONTHLY_PROGRESS_REPORT_FAILURE",
  FETCH_ALL_MONTHLY_PROGRESS_REPORT_SUCCESS:
    "FETCH_ALL_MONTHLY_PROGRESS_REPORT_SUCCESS",

  FETCH_ONE_MONTHLY_PROGRESS_REPORT: "FETCH_ONE_MONTHLY_PROGRESS_REPORT",
  FETCH_ONE_MONTHLY_PROGRESS_REPORT_RESET:
    "FETCH_ONE_MONTHLY_PROGRESS_REPORT_RESET",
  FETCH_ONE_MONTHLY_PROGRESS_REPORT_FAILURE:
    "FETCH_ONE_MONTHLY_PROGRESS_REPORT_FAILURE",
  FETCH_ONE_MONTHLY_PROGRESS_REPORT_SUCCESS:
    "FETCH_ONE_MONTHLY_PROGRESS_REPORT_SUCCESS",
};
