import { Material } from "../Material/Material.type";

import { PurchaseOrder } from "../PurchaseOrder/PurchaseOrder.type";
import { PurchaseRequisition } from "../PurchaseRequisition/PurchaseRequisition.type";
import { Store } from "../Store/Store.type";
import { Supplier } from "../Supplier/Supplier.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type FixedAssetReceived = {
  id: number;
  type: string;
  supplier_id: number;
  purchase_order?: PurchaseOrder;
  supplier?: Supplier;
  purchase_order_id: number;
  supplier_invoice: string;
  purchase_requisition_id: number;
  purchase_requisition: PurchaseRequisition;
  received_by: string;
  far_receiving_warehouse: Store;
  prepared_by: string;
  authorized_by: string;
  delivered_by: string;
  date: string;
  inventory_type: string;
  purchase_type: string;
  store_id: number;
  fixed_asset_received_type: string;
  fixed_asset_received_items: FixedAssetReceivedItem[];
  is_done: boolean;
  far_prepared_by?: User;
  fixed_asset_received_statuses: FixedAssetReceivedStatus[];
  status: FixedAssetReceivedStatus[];
  store: Store;
  approved_by: string;
  pad_ref: string;
  employee: string;
  department: string;
  far_checked_by: User;
  far_approved_by: User;

  has_vat: boolean;
  has_withhold: boolean;
  waybill_no: string;
  updatedAt: string;
  is_void: boolean;
};

export type FixedAssetReceivedStatus = {
  fixed_asset_out_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type FixedAssetReceivedReport = {
  approved_by?: any;
  code: string;
  date: string;
  description: string;
  from: string;
  to: string;
  item_category: string;
  id: number;
  major_category: string;
  purchase_requisition_id: string;
  pad_ref: string;
  prepared_by: string;
  quantity: number;
  received_by?: any;
  remark: string;
  total: number;
  unit: string;
};

export type FixedAssetReceivedItem = {
  material: Material;
  material_id: number;
  type: string;
  plate_number: string;
  engine_number: string;
  chassis_number: string;
  serial_number: string;
  part_number: string;
  unit_price: number;
  quantity: number;
  model: string;
  brand: string;
  equipment_code: string;
  condition: string;
  unit: string;
  identification_number: number;
  id: any;
};

export type FixedAssetReceivedStateTypes = {
  fetchAll: ApiCallState<FixedAssetReceived[]>;
  fetchReport: ApiCallState<FixedAssetReceived[]>;
  fetchPlateNumberList: ApiCallState<FixedAssetReceivedItem[]>;
};

export const FixedAssetReceivedActionTypes = {
  FETCH_ALL_FIXED_ASSET_RECEIVED: "FETCH_ALL_FIXED_ASSET_RECEIVED",
  FETCH_ALL_FIXED_ASSET_RECEIVED_RESET: "FETCH_ALL_FIXED_ASSET_RECEIVED_RESET",
  FETCH_ALL_FIXED_ASSET_RECEIVED_FAILURE:
    "FETCH_ALL_FIXED_ASSET_RECEIVED_FAILURE",
  FETCH_ALL_FIXED_ASSET_RECEIVED_SUCCESS:
    "FETCH_ALL_FIXED_ASSET_RECEIVED_SUCCESS",

  FETCH_FIXED_ASSET_RECEIVED_REPORT: "FETCH_FIXED_ASSET_RECEIVED_REPORT",
  FETCH_FIXED_ASSET_RECEIVED_REPORT_RESET:
    "FETCH_FIXED_ASSET_RECEIVED_REPORT_RESET",
  FETCH_FIXED_ASSET_RECEIVED_REPORT_FAILURE:
    "FETCH_FIXED_ASSET_RECEIVED_REPORT_FAILURE",
  FETCH_FIXED_ASSET_RECEIVED_REPORT_SUCCESS:
    "FETCH_FIXED_ASSET_RECEIVED_REPORT_SUCCESS",

  FETCH_FIXED_PLATE_NUMBER_LIST: "FETCH_FIXED_PLATE_NUMBER_LIST",
  FETCH_FIXED_PLATE_NUMBER_LIST_RESET: "FETCH_FIXED_PLATE_NUMBER_LIST_RESET",
  FETCH_FIXED_PLATE_NUMBER_LIST_FAILURE:
    "FETCH_FIXED_PLATE_NUMBER_LIST_FAILURE",
  FETCH_FIXED_PLATE_NUMBER_LIST_SUCCESS:
    "FETCH_FIXED_PLATE_NUMBER_LIST_SUCCESS",
};
