import { BudgetPlanningItemStateTypes, BudgetPlanningItemActionTypes } from "./BudgetPlanningItem.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: BudgetPlanningItemStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const BudgetPlanningItemReducer = (
  state: BudgetPlanningItemStateTypes = INITIAL_STATE,
  action: any
): BudgetPlanningItemStateTypes => {
  switch (action.type) {
    case BudgetPlanningItemActionTypes.FETCH_ALL_BUDGET_PLANNING_ITEM:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case BudgetPlanningItemActionTypes.FETCH_ALL_BUDGET_PLANNING_ITEM_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case BudgetPlanningItemActionTypes.FETCH_ALL_BUDGET_PLANNING_ITEM_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case BudgetPlanningItemActionTypes.FETCH_ALL_BUDGET_PLANNING_ITEM_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case BudgetPlanningItemActionTypes.FETCH_ONE_BUDGET_PLANNING_ITEM:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case BudgetPlanningItemActionTypes.FETCH_ONE_BUDGET_PLANNING_ITEM_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case BudgetPlanningItemActionTypes.FETCH_ONE_BUDGET_PLANNING_ITEM_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case BudgetPlanningItemActionTypes.FETCH_ONE_BUDGET_PLANNING_ITEM_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default BudgetPlanningItemReducer;
