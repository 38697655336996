import { Document } from "../Document/Document.type";
import { ApiCallState } from "../Utils";

export type Archive= {
  id: number;
  date:string;
  description:string;
  type:string;
  column_no:number;
  row_no:number;
  box_file:number;
  item_no:string;
  document:Document;
  user_id:number;
};

export type ArchiveStateTypes = {
  fetchAll: ApiCallState<Archive[]>;
  fetchOne: ApiCallState<Archive | {}>;
};

export const ArchiveActionTypes = {
  FETCH_ALL_ARCHIVE: "FETCH_ALL_ARCHIVE",
  FETCH_ALL_ARCHIVE_RESET: "FETCH_ALL_ARCHIVE_RESET",
  FETCH_ALL_ARCHIVE_FAILURE: "FETCH_ALL_ARCHIVE_FAILURE",
  FETCH_ALL_ARCHIVE_SUCCESS: "FETCH_ALL_ARCHIVE_SUCCESS",

  FETCH_ONE_ARCHIVE: "FETCH_ONE_ARCHIVE",
  FETCH_ONE_ARCHIVE_RESET: "FETCH_ONE_ARCHIVE_RESET",
  FETCH_ONE_ARCHIVE_FAILURE: "FETCH_ONE_ARCHIVE_FAILURE",
  FETCH_ONE_ARCHIVE_SUCCESS: "FETCH_ONE_ARCHIVE_SUCCESS",
};
