import { MasterList } from "../MasterList/MasterList.type";
import { ApiCallState } from "../Utils";

export type Insurance = {
  id: number;
  user_id: number;

  master_list_id: number;

  insurance_company: string;
  insurance_policy: string;
  purchase_amount: number;
  sum_insured_amount: number;

  own_damage_policy: string;
  own_damage_from: string;
  own_damage_to: string;

  political_violence_policy: string;
  political_violence_from: string;
  political_violence_to: string;

  third_party_policy: string;
  third_party_from: string;
  third_party_to: string;

  location: string;
  location_status: string;

  master_list: MasterList;
};

export type InsuranceStateTypes = {
  fetchAll: ApiCallState<Insurance[]>;
  fetchOne: ApiCallState<Insurance | {}>;
};

export const InsuranceActionTypes = {
  FETCH_ALL_INSURANCE: "FETCH_ALL_INSURANCE",
  FETCH_ALL_INSURANCE_RESET: "FETCH_ALL_INSURANCE_RESET",
  FETCH_ALL_INSURANCE_FAILURE: "FETCH_ALL_INSURANCE_FAILURE",
  FETCH_ALL_INSURANCE_SUCCESS: "FETCH_ALL_INSURANCE_SUCCESS",

  FETCH_ONE_INSURANCE: "FETCH_ONE_INSURANCE",
  FETCH_ONE_INSURANCE_RESET: "FETCH_ONE_INSURANCE_RESET",
  FETCH_ONE_INSURANCE_FAILURE: "FETCH_ONE_INSURANCE_FAILURE",
  FETCH_ONE_INSURANCE_SUCCESS: "FETCH_ONE_INSURANCE_SUCCESS",
};
