import { Material } from "../Material/Material.type";
import { MaterialRequisition } from "../MaterialRequisition/MaterialRequisition.type";
import { Store } from "../Store/Store.type";

import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type FixedAssetOut = {
  id: number;
  key?: any;
  date: string;
  fixed_asset_out_dispatching_warehouse: Store;
  fixed_asset_out_receiving_warehouse: Store;
  user_id: number;
  reason: string;
  fixed_asset_out_items: FixedAssetOutItem[];
  fao_prepared_by?: User;
  fao_issued_by?: User;
  fao_inspected_by?: User;
  fao_approved_by?: User;
  is_done: boolean;
  material_requisition_id: number;
  fixed_asset_out_statuses: FixedAssetOutStatus[];
  status: FixedAssetOutStatus[];
  received_by: string;
  approved_by: string;
  pad_ref: string;
  employee: string;
  department: string;
  updatedAt: string;
  is_void: boolean;
};

export type FixedAssetOutStatus = {
  fixed_asset_out_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type FixedAssetOutItem = {
  id: number;
  material_requisition_id: number;
  material_id: number;
  material: Material;
  material_requisition: MaterialRequisition;
  serial_number: string;
  mfg_part_no: string;
  quantity_requested: number;
  quantity: number;
  identification_number: number;
  unit: string;
};

export type FixedAssetOutReport = {
  approved_by?: any;
  code: string;
  date: string;
  description: string;
  from: string;
  to: string;
  item_category: string;
  id: number;
  major_category: string;
  material_requisition_id: any;
  pad_ref: string;
  prepared_by: string;
  quantity: number;
  received_by?: any;
  remark: string;
  total: number;
  unit: string;
};

export type FixedAssetOutStateTypes = {
  fetchAll: ApiCallState<FixedAssetOut[]>;
  fetchReport: ApiCallState<any[]>;
};

export const FixedAssetOutActionTypes = {
  FETCH_ALL_FIXED_ASSET_OUT: "FETCH_ALL_FIXED_ASSET_OUT",
  FETCH_ALL_FIXED_ASSET_OUT_RESET: "FETCH_ALL_FIXED_ASSET_OUT_RESET",
  FETCH_ALL_FIXED_ASSET_OUT_FAILURE: "FETCH_ALL_FIXED_ASSET_OUT_FAILURE",
  FETCH_ALL_FIXED_ASSET_OUT_SUCCESS: "FETCH_ALL_FIXED_ASSET_OUT_SUCCESS",

  FETCH_REPORT_FIXED_ASSET_OUT: "FETCH_REPORT_FIXED_ASSET_OUT",
  FETCH_REPORT_FIXED_ASSET_OUT_RESET: "FETCH_REPORT_FIXED_ASSET_OUT_RESET",
  FETCH_REPORT_FIXED_ASSET_OUT_FAILURE: "FETCH_REPORT_FIXED_ASSET_OUT_FAILURE",
  FETCH_REPORT_FIXED_ASSET_OUT_SUCCESS: "FETCH_REPORT_FIXED_ASSET_OUT_SUCCESS",
};
