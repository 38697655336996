import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { StoreRequisitionFollowUpActionTypes } from "./StoreRequisitionFollowUp.type";

export function* fetchAllStoreRequisitionFollowUps(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/store-requisition-follow-up`
    );
    yield put({
      type: StoreRequisitionFollowUpActionTypes.FETCH_ALL_STORE_REQUISITION_FOLLOW_UP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StoreRequisitionFollowUpActionTypes.FETCH_ALL_STORE_REQUISITION_FOLLOW_UP_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneStoreRequisitionFollowUps(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/store-requisition-follow-up/${action.payload}`
    );
    yield put({
      type: StoreRequisitionFollowUpActionTypes.FETCH_ONE_STORE_REQUISITION_FOLLOW_UP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StoreRequisitionFollowUpActionTypes.FETCH_ONE_STORE_REQUISITION_FOLLOW_UP_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllStoreRequisitionFollowUps() {
  yield takeLatest(
    StoreRequisitionFollowUpActionTypes.FETCH_ALL_STORE_REQUISITION_FOLLOW_UP,
    fetchAllStoreRequisitionFollowUps
  );
}

export function* watcherFetchOneStoreRequisitionFollowUps() {
  yield takeLatest(
    StoreRequisitionFollowUpActionTypes.FETCH_ONE_STORE_REQUISITION_FOLLOW_UP,
    fetchOneStoreRequisitionFollowUps
  );
}
