import { ApiCallState } from "../Utils";
import { Moment } from "moment";


export type BudgetShiftingRequest= {
    project_id: number | null;
    id: number | null;
    date:Moment;
    reason_for_budget_shift:string;
    request_no:string;
    material:string;
    description:string;
    unit:string;
    quantity:number;
    rate:number;
    amount:number;
    remark:string;
};

export type BudgetShiftingRequestStateTypes = {
  fetchAll: ApiCallState<BudgetShiftingRequest[]>;
  fetchOne: ApiCallState<BudgetShiftingRequest | {}>;
};

export type BudgetShiftingRequestActionType = {
    project_id: number;
};

export const BudgetShiftingRequestActionTypes = {
  FETCH_ALL_BUDGET_SHIFTING_REQUEST: "FETCH_ALL_BUDGET_SHIFTING_REQUEST",
  FETCH_ALL_BUDGET_SHIFTING_REQUEST_RESET: "FETCH_ALL_BUDGET_SHIFTING_REQUEST_RESET",
  FETCH_ALL_BUDGET_SHIFTING_REQUEST_FAILURE: "FETCH_ALL_BUDGET_SHIFTING_REQUEST_FAILURE",
  FETCH_ALL_BUDGET_SHIFTING_REQUEST_SUCCESS: "FETCH_ALL_BUDGET_SHIFTING_REQUEST_SUCCESS",

  FETCH_ONE_BUDGET_SHIFTING_REQUEST: "FETCH_ONE_BUDGET_SHIFTING_REQUEST",
  FETCH_ONE_BUDGET_SHIFTING_REQUEST_RESET: "FETCH_ONE_BUDGET_SHIFTING_REQUEST_RESET",
  FETCH_ONE_BUDGET_SHIFTING_REQUEST_FAILURE: "FETCH_ONE_BUDGET_SHIFTING_REQUEST_FAILURE",
  FETCH_ONE_BUDGET_SHIFTING_REQUEST_SUCCESS: "FETCH_ONE_BUDGET_SHIFTING_REQUEST_SUCCESS",
};
