import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { EquipmentRequestActionTypes } from "./EquipmentRequest.type";

export function* fetchAllEquipmentRequests(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/equipment-request?project_id=${action.payload.project_id}`);
    yield put({
      type: EquipmentRequestActionTypes.FETCH_ALL_EQUIPMENT_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EquipmentRequestActionTypes.FETCH_ALL_EQUIPMENT_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneEquipmentRequests(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/equipment-request/${action.payload}`
    );
    yield put({
      type: EquipmentRequestActionTypes.FETCH_ONE_EQUIPMENT_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EquipmentRequestActionTypes.FETCH_ONE_EQUIPMENT_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllEquipmentRequests() {
  yield takeLatest(EquipmentRequestActionTypes.FETCH_ALL_EQUIPMENT_REQUEST, fetchAllEquipmentRequests);
}

export function* watcherFetchOneEquipmentRequests() {
  yield takeLatest(EquipmentRequestActionTypes.FETCH_ONE_EQUIPMENT_REQUEST, fetchOneEquipmentRequests);
}
