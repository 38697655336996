import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type CanteenDeduction= {
  id: number;
  date:string;
  staff_id:number;
  amount:number;
  user_id:number;
  staff:Staff;
};

export type CanteenDeductionStateTypes = {
  fetchAll: ApiCallState<CanteenDeduction[]>;
  fetchOne: ApiCallState<CanteenDeduction | {}>;
};

export const CanteenDeductionActionTypes = {
  FETCH_ALL_CANTEEN_DEDUCTION: "FETCH_ALL_CANTEEN_DEDUCTION",
  FETCH_ALL_CANTEEN_DEDUCTION_RESET: "FETCH_ALL_CANTEEN_DEDUCTION_RESET",
  FETCH_ALL_CANTEEN_DEDUCTION_FAILURE: "FETCH_ALL_CANTEEN_DEDUCTION_FAILURE",
  FETCH_ALL_CANTEEN_DEDUCTION_SUCCESS: "FETCH_ALL_CANTEEN_DEDUCTION_SUCCESS",

  FETCH_ONE_CANTEEN_DEDUCTION: "FETCH_ONE_CANTEEN_DEDUCTION",
  FETCH_ONE_CANTEEN_DEDUCTION_RESET: "FETCH_ONE_CANTEEN_DEDUCTION_RESET",
  FETCH_ONE_CANTEEN_DEDUCTION_FAILURE: "FETCH_ONE_CANTEEN_DEDUCTION_FAILURE",
  FETCH_ONE_CANTEEN_DEDUCTION_SUCCESS: "FETCH_ONE_CANTEEN_DEDUCTION_SUCCESS",
};
