import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { TemporaryAttendanceActionTypes } from "./TemporaryAttendance.type";

export function* fetchAllTemporaryAttendances(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/temporary-attendance`);
    yield put({
      type: TemporaryAttendanceActionTypes.FETCH_ALL_TEMPORARY_ATTENDANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TemporaryAttendanceActionTypes.FETCH_ALL_TEMPORARY_ATTENDANCE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneTemporaryAttendance(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/temporary-attendance/${action.payload}`
    );
    yield put({
      type: TemporaryAttendanceActionTypes.FETCH_ONE_TEMPORARY_ATTENDANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TemporaryAttendanceActionTypes.FETCH_ONE_TEMPORARY_ATTENDANCE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllTemporaryAttendances() {
  yield takeLatest(TemporaryAttendanceActionTypes.FETCH_ALL_TEMPORARY_ATTENDANCE, fetchAllTemporaryAttendances);
}

export function* watcherFetchOneTemporaryAttendance() {
  yield takeLatest(TemporaryAttendanceActionTypes.FETCH_ONE_TEMPORARY_ATTENDANCE, fetchOneTemporaryAttendance);
}
