import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { OwnVehicleActionTypes } from "./OwnVehicle.type";

export function* fetchAllOwnVehicles(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/own-vehicle?${query}`);
    yield put({
      type: OwnVehicleActionTypes.FETCH_ALL_OWN_VEHICLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: OwnVehicleActionTypes.FETCH_ALL_OWN_VEHICLE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchAllProjectOwnVehicles(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/own-vehicle?${query}`);
    yield put({
      type: OwnVehicleActionTypes.FETCH_ALL_PROJECT_OWN_VEHICLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: OwnVehicleActionTypes.FETCH_ALL_PROJECT_OWN_VEHICLE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedOwnVehicles(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/own-vehicle?${query}`);
    yield put({
      type: OwnVehicleActionTypes.FETCH_PAGED_OWN_VEHICLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: OwnVehicleActionTypes.FETCH_PAGED_OWN_VEHICLE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneOwnVehicles(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/own-vehicle/${action.payload}`
    );
    yield put({
      type: OwnVehicleActionTypes.FETCH_ONE_OWN_VEHICLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: OwnVehicleActionTypes.FETCH_ONE_OWN_VEHICLE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllOwnVehicles() {
  yield takeLatest(
    OwnVehicleActionTypes.FETCH_ALL_OWN_VEHICLE,
    fetchAllOwnVehicles
  );
}

export function* watcherFetchProjectAllOwnVehicles() {
  yield takeLatest(
    OwnVehicleActionTypes.FETCH_ALL_PROJECT_OWN_VEHICLE,
    fetchAllProjectOwnVehicles
  );
}

export function* watcherFetchPagedOwnVehicles() {
  yield takeLatest(
    OwnVehicleActionTypes.FETCH_PAGED_OWN_VEHICLE,
    fetchPagedOwnVehicles
  );
}

export function* watcherFetchOneOwnVehicles() {
  yield takeLatest(
    OwnVehicleActionTypes.FETCH_ONE_OWN_VEHICLE,
    fetchOneOwnVehicles
  );
}
