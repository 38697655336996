import { EquipmentCostStateTypes, EquipmentCostActionTypes } from "./EquipmentCost.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: EquipmentCostStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const EquipmentCostReducer = (
  state: EquipmentCostStateTypes = INITIAL_STATE,
  action: any
): EquipmentCostStateTypes => {
  switch (action.type) {
    case EquipmentCostActionTypes.FETCH_ALL_EQUIPMENT_COST:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case EquipmentCostActionTypes.FETCH_ALL_EQUIPMENT_COST_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case EquipmentCostActionTypes.FETCH_ALL_EQUIPMENT_COST_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case EquipmentCostActionTypes.FETCH_ALL_EQUIPMENT_COST_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case EquipmentCostActionTypes.FETCH_ONE_EQUIPMENT_COST:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case EquipmentCostActionTypes.FETCH_ONE_EQUIPMENT_COST_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case EquipmentCostActionTypes.FETCH_ONE_EQUIPMENT_COST_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case EquipmentCostActionTypes.FETCH_ONE_EQUIPMENT_COST_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default EquipmentCostReducer;
