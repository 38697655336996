import { Store } from "../Store/Store.type";
import { Supplier } from "../Supplier/Supplier.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";
import { PurchaseRequisition } from "../PurchaseRequisition/PurchaseRequisition.type";

export type PaymentRequisition = {
  purchase_requisition_id: number;
  user_id: number;
  prv_requested_by: Store;
  prv_requested_by_project: Store;
  date: string;
  id: number;
  supplier_id: number;
  tax: number;
  items: any[];
  remark: string;
  purpose: string;
  pad_ref: string;
  prepared_by?: number;
  checked_by?: number;
  approved_by?: number;
  requested_by: number;
  requested_by_project: number;
  pr_prepared_by?: User;
  pr_approved_by?: User;
  pr_checked_by?: User;
  supplier?: Supplier;
  purchase_requisition?: PurchaseRequisition;
  payment_requisition_statuses: PaymentRequisitionStatus[];
  createdAt: Date;
  updatedAt: Date;
};

export type PaymentRequisitionStatus = {
  payment_requisition_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
  user: User;
};

export type PaymentRequisitionStateTypes = {
  fetchAll: ApiCallState<PaymentRequisition[]>;
  fetchOne: ApiCallState<PaymentRequisition | {}>;
};

export const PaymentRequisitionActionTypes = {
  FETCH_ALL_PAYMENT_REQUISITION: "FETCH_ALL_PAYMENT_REQUISITION",
  FETCH_ALL_PAYMENT_REQUISITION_RESET: "FETCH_ALL_PAYMENT_REQUISITION_RESET",
  FETCH_ALL_PAYMENT_REQUISITION_FAILURE:
    "FETCH_ALL_PAYMENT_REQUISITION_FAILURE",
  FETCH_ALL_PAYMENT_REQUISITION_SUCCESS:
    "FETCH_ALL_PAYMENT_REQUISITION_SUCCESS",

  FETCH_ONE_PAYMENT_REQUISITION: "FETCH_ONE_PAYMENT_REQUISITION",
  FETCH_ONE_PAYMENT_REQUISITION_RESET: "FETCH_ONE_PAYMENT_REQUISITION_RESET",
  FETCH_ONE_PAYMENT_REQUISITION_FAILURE:
    "FETCH_ONE_PAYMENT_REQUISITION_FAILURE",
  FETCH_ONE_PAYMENT_REQUISITION_SUCCESS:
    "FETCH_ONE_PAYMENT_REQUISITION_SUCCESS"
};
