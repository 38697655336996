import {
  IncomeExpenseStateTypes,
  IncomeExpenseActionTypes,
} from "./IncomeExpense.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: IncomeExpenseStateTypes = {
  fetchReport: resetApiCallState({}),
  fetchProgressSummary: resetApiCallState([]),
  fetchProjectIncome: resetApiCallState({}),
  fetchBillSummary: resetApiCallState([]),
  fetchIncomeExpense: resetApiCallState({}),
};

const IncomeExpenseReducer = (
  state: IncomeExpenseStateTypes = INITIAL_STATE,
  action: any
): IncomeExpenseStateTypes => {
  switch (action.type) {
    case IncomeExpenseActionTypes.FETCH_INCOME_EXPENSE_REPORT:
      return {
        ...state,
        fetchReport: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case IncomeExpenseActionTypes.FETCH_INCOME_EXPENSE_REPORT_RESET:
      return {
        ...state,
        fetchReport: resetApiCallState([]),
      };
    case IncomeExpenseActionTypes.FETCH_INCOME_EXPENSE_REPORT_FAILURE:
      return {
        ...state,
        fetchReport: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case IncomeExpenseActionTypes.FETCH_INCOME_EXPENSE_REPORT_SUCCESS:
      return {
        ...state,
        fetchReport: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case IncomeExpenseActionTypes.FETCH_PROGRESS_SUMMARY:
      return {
        ...state,
        fetchProgressSummary: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case IncomeExpenseActionTypes.FETCH_PROGRESS_SUMMARY_RESET:
      return {
        ...state,
        fetchProgressSummary: resetApiCallState([]),
      };
    case IncomeExpenseActionTypes.FETCH_PROGRESS_SUMMARY_FAILURE:
      return {
        ...state,
        fetchProgressSummary: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case IncomeExpenseActionTypes.FETCH_PROGRESS_SUMMARY_SUCCESS:
      return {
        ...state,
        fetchProgressSummary: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case IncomeExpenseActionTypes.FETCH_PROJECT_INCOME:
      return {
        ...state,
        fetchProjectIncome: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case IncomeExpenseActionTypes.FETCH_PROJECT_INCOME_RESET:
      return {
        ...state,
        fetchProjectIncome: resetApiCallState([]),
      };
    case IncomeExpenseActionTypes.FETCH_PROJECT_INCOME_FAILURE:
      return {
        ...state,
        fetchProjectIncome: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case IncomeExpenseActionTypes.FETCH_PROJECT_INCOME_SUCCESS:
      return {
        ...state,
        fetchProjectIncome: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case IncomeExpenseActionTypes.FETCH_BILL_SUMMARY:
      return {
        ...state,
        fetchBillSummary: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case IncomeExpenseActionTypes.FETCH_BILL_SUMMARY_RESET:
      return {
        ...state,
        fetchBillSummary: resetApiCallState([]),
      };
    case IncomeExpenseActionTypes.FETCH_BILL_SUMMARY_FAILURE:
      return {
        ...state,
        fetchBillSummary: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case IncomeExpenseActionTypes.FETCH_BILL_SUMMARY_SUCCESS:
      return {
        ...state,
        fetchBillSummary: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case IncomeExpenseActionTypes.FETCH_INCOME_EXPENSE:
      return {
        ...state,
        fetchIncomeExpense: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case IncomeExpenseActionTypes.FETCH_INCOME_EXPENSE_RESET:
      return {
        ...state,
        fetchIncomeExpense: resetApiCallState([]),
      };
    case IncomeExpenseActionTypes.FETCH_INCOME_EXPENSE_FAILURE:
      return {
        ...state,
        fetchIncomeExpense: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case IncomeExpenseActionTypes.FETCH_INCOME_EXPENSE_SUCCESS:
      return {
        ...state,
        fetchIncomeExpense: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default IncomeExpenseReducer;
