import { MasterList } from "../MasterList/MasterList.type";
import { ApiCallState } from "../Utils";

export type OperationStandard= {
  id: number;
  key:any;
  master_list_type_id:number;
  master_list_plate_id:number;
  standard_fuel_consumption:number;
  standard_fuel_consumption_unit: string;
  standard_productivity:number;
  unit: string;
  master_list_type:MasterList;
  master_list_plate:MasterList;
  user_id:number;
};

export type OperationStandardStateTypes = {
  fetchAll: ApiCallState<OperationStandard[]>;
  fetchOne: ApiCallState<OperationStandard | {}>;
};

export const OperationStandardActionTypes = {
  FETCH_ALL_OPERATION_STANDARD: "FETCH_ALL_OPERATION_STANDARD",
  FETCH_ALL_OPERATION_STANDARD_RESET: "FETCH_ALL_OPERATION_STANDARD_RESET",
  FETCH_ALL_OPERATION_STANDARD_FAILURE: "FETCH_ALL_OPERATION_STANDARD_FAILURE",
  FETCH_ALL_OPERATION_STANDARD_SUCCESS: "FETCH_ALL_OPERATION_STANDARD_SUCCESS",

  FETCH_ONE_OPERATION_STANDARD: "FETCH_ONE_OPERATION_STANDARD",
  FETCH_ONE_OPERATION_STANDARD_RESET: "FETCH_ONE_OPERATION_STANDARD_RESET",
  FETCH_ONE_OPERATION_STANDARD_FAILURE: "FETCH_ONE_OPERATION_STANDARD_FAILURE",
  FETCH_ONE_OPERATION_STANDARD_SUCCESS: "FETCH_ONE_OPERATION_STANDARD_SUCCESS",
};
