import { ApiCallState } from "../Utils";

export type StaffPerformance= {
    id: number;
    staff_id:number;
    date:string;
    type:string;
    description:string;
    action_taken:string;
    user_id:number;
};

export type StaffPerformanceStateTypes = {
  fetchAll: ApiCallState<StaffPerformance[]>;
  fetchOne: ApiCallState<StaffPerformance | {}>;
};

export const StaffPerformanceActionTypes = {
  FETCH_ALL_STAFF_PERFORMANCE: "FETCH_ALL_STAFF_PERFORMANCE",
  FETCH_ALL_STAFF_PERFORMANCE_RESET: "FETCH_ALL_STAFF_PERFORMANCE_RESET",
  FETCH_ALL_STAFF_PERFORMANCE_FAILURE: "FETCH_ALL_STAFF_PERFORMANCE_FAILURE",
  FETCH_ALL_STAFF_PERFORMANCE_SUCCESS: "FETCH_ALL_STAFF_PERFORMANCE_SUCCESS",

  FETCH_ONE_STAFF_PERFORMANCE: "FETCH_ONE_STAFF_PERFORMANCE",
  FETCH_ONE_STAFF_PERFORMANCE_RESET: "FETCH_ONE_STAFF_PERFORMANCE_RESET",
  FETCH_ONE_STAFF_PERFORMANCE_FAILURE: "FETCH_ONE_STAFF_PERFORMANCE_FAILURE",
  FETCH_ONE_STAFF_PERFORMANCE_SUCCESS: "FETCH_ONE_STAFF_PERFORMANCE_SUCCESS",
};
