import {
  PaymentRequisitionStateTypes,
  PaymentRequisitionActionTypes,
} from "./PaymentRequisition.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: PaymentRequisitionStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({})
};

const PaymentRequisitionReducer = (
  state: PaymentRequisitionStateTypes = INITIAL_STATE,
  action: any
): PaymentRequisitionStateTypes => {
  switch (action.type) {
    case PaymentRequisitionActionTypes.FETCH_ALL_PAYMENT_REQUISITION:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case PaymentRequisitionActionTypes.FETCH_ALL_PAYMENT_REQUISITION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case PaymentRequisitionActionTypes.FETCH_ALL_PAYMENT_REQUISITION_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PaymentRequisitionActionTypes.FETCH_ALL_PAYMENT_REQUISITION_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case PaymentRequisitionActionTypes.FETCH_ONE_PAYMENT_REQUISITION:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case PaymentRequisitionActionTypes.FETCH_ONE_PAYMENT_REQUISITION_RESET:
      return {
        ...state,
        fetchOne: resetApiCallState({}),
      };
    case PaymentRequisitionActionTypes.FETCH_ONE_PAYMENT_REQUISITION_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PaymentRequisitionActionTypes.FETCH_ONE_PAYMENT_REQUISITION_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default PaymentRequisitionReducer;
