import { Staff } from "../Staff/Staff.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type TravelRegistry = {
  id: number;
  user_id: number;
  staff_id: number;

  allowance_calculated: boolean;

  current_place: string;
  to_place: string;

  travel_date: string;
  start_date: string;
  return_date: string;

  start_date_delayed_reason: string;

  staff: Staff;
  user: User;
};

export type TravelRegistryStateTypes = {
  fetchAll: ApiCallState<TravelRegistry[]>;
  fetchOne: ApiCallState<TravelRegistry | {}>;
};

export const TravelRegistryActionTypes = {
  FETCH_ALL_TRAVEL_REGISTRY: "FETCH_ALL_TRAVEL_REGISTRY",
  FETCH_ALL_TRAVEL_REGISTRY_RESET: "FETCH_ALL_TRAVEL_REGISTRY_RESET",
  FETCH_ALL_TRAVEL_REGISTRY_FAILURE: "FETCH_ALL_TRAVEL_REGISTRY_FAILURE",
  FETCH_ALL_TRAVEL_REGISTRY_SUCCESS: "FETCH_ALL_TRAVEL_REGISTRY_SUCCESS",

  FETCH_ONE_TRAVEL_REGISTRY: "FETCH_ONE_TRAVEL_REGISTRY",
  FETCH_ONE_TRAVEL_REGISTRY_RESET: "FETCH_ONE_TRAVEL_REGISTRY_RESET",
  FETCH_ONE_TRAVEL_REGISTRY_FAILURE: "FETCH_ONE_TRAVEL_REGISTRY_FAILURE",
  FETCH_ONE_TRAVEL_REGISTRY_SUCCESS: "FETCH_ONE_TRAVEL_REGISTRY_SUCCESS",
};
