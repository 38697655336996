import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type MikadaDailyReport = {
  id: number;
  date: string;
  remark: string;
  project_id: number;
  user_id: number;
};

export type MikadaDailyReportStateTypes = {
  fetchAll: ApiCallState<MikadaDailyReport[]>;
  fetchOne: ApiCallState<MikadaDailyReport | {}>;
};

export const MikadaDailyReportActionTypes = {
  FETCH_ALL_MIKADA_DAILY_REPORT: "FETCH_ALL_MIKADA_DAILY_REPORT",
  FETCH_ALL_MIKADA_DAILY_REPORT_RESET: "FETCH_ALL_MIKADA_DAILY_REPORT_RESET",
  FETCH_ALL_MIKADA_DAILY_REPORT_FAILURE: "FETCH_ALL_MIKADA_DAILY_REPORT_FAILURE",
  FETCH_ALL_MIKADA_DAILY_REPORT_SUCCESS: "FETCH_ALL_MIKADA_DAILY_REPORT_SUCCESS",

  FETCH_ONE_MIKADA_DAILY_REPORT: "FETCH_ONE_MIKADA_DAILY_REPORT",
  FETCH_ONE_MIKADA_DAILY_REPORT_RESET: "FETCH_ONE_MIKADA_DAILY_REPORT_RESET",
  FETCH_ONE_MIKADA_DAILY_REPORT_FAILURE: "FETCH_ONE_MIKADA_DAILY_REPORT_FAILURE",
  FETCH_ONE_MIKADA_DAILY_REPORT_SUCCESS: "FETCH_ONE_MIKADA_DAILY_REPORT_SUCCESS",

  FETCH_COUNT_MIKADA_DAILY_REPORT: "FETCH_COUNT_MIKADA_DAILY_REPORT",
  FETCH_COUNT_MIKADA_DAILY_REPORT_RESET: "FETCH_COUNT_MIKADA_DAILY_REPORT_RESET",
  FETCH_COUNT_MIKADA_DAILY_REPORT_FAILURE: "FETCH_COUNT_MIKADA_DAILY_REPORT_FAILURE",
  FETCH_COUNT_MIKADA_DAILY_REPORT_SUCCESS: "FETCH_COUNT_MIKADA_DAILY_REPORT_SUCCESS",
};
