import { PayableV2Payment } from "../PayableV2Payment/PayableV2Payment.type";
import { ApiCallState } from "../Utils";

export type PayableV2 = {
  id: any;
  lender_name: string;
  item_name: string;
  borrower_name: string;
  date: string;
  unit: string;
  quantity: number;
  amount: number;
  user_id: number;
  remark: string;
  createdAt: string;
  updatedAt: string;
  payable_v2_payments: PayableV2Payment[];
};

export type PayableV2StateTypes = {
  fetchAll: ApiCallState<PayableV2[]>;
  fetchOne: ApiCallState<PayableV2 | {}>;
};

export const PayableV2ActionTypes = {
  FETCH_ALL_PAYABLE_V2: "FETCH_ALL_PAYABLE_V2",
  FETCH_ALL_PAYABLE_V2_RESET: "FETCH_ALL_PAYABLE_V2_RESET",
  FETCH_ALL_PAYABLE_V2_FAILURE: "FETCH_ALL_PAYABLE_V2_FAILURE",
  FETCH_ALL_PAYABLE_V2_SUCCESS: "FETCH_ALL_PAYABLE_V2_SUCCESS",

  FETCH_ONE_PAYABLE_V2: "FETCH_ONE_PAYABLE_V2",
  FETCH_ONE_PAYABLE_V2_RESET: "FETCH_ONE_PAYABLE_V2_RESET",
  FETCH_ONE_PAYABLE_V2_FAILURE: "FETCH_ONE_PAYABLE_V2_FAILURE",
  FETCH_ONE_PAYABLE_V2_SUCCESS: "FETCH_ONE_PAYABLE_V2_SUCCESS",
};
