import { ApiCallState } from "../Utils";

export type SubContractAgreement = {
  id: number;
  date: string;
  name: string;
  address: string;
  phone: string;
  project_id: number;
  user_id: number;
  agreement_date: string;
  sub_contract_agreement_items: SubContractAgreementItem[];
  sub_contract_agreement_statuses: any[]
};

type SubContractAgreementItem = {
  id: number;
  description: string; 
  unit: string;
  amount: number;
  sub_contract_agreement_id: number;
};

export type SubContractAgreementStateTypes = {
  fetchAll: ApiCallState<SubContractAgreement[]>;
  fetchOne: ApiCallState<SubContractAgreement | {}>;
};

export const SubContractAgreementActionTypes = {
  FETCH_ALL_SUB_CONTRACT_AGREEMENT: "FETCH_ALL_SUB_CONTRACT_AGREEMENT",
  FETCH_ALL_SUB_CONTRACT_AGREEMENT_RESET:
    "FETCH_ALL_SUB_CONTRACT_AGREEMENT_RESET",
  FETCH_ALL_SUB_CONTRACT_AGREEMENT_FAILURE:
    "FETCH_ALL_SUB_CONTRACT_AGREEMENT_FAILURE",
  FETCH_ALL_SUB_CONTRACT_AGREEMENT_SUCCESS:
    "FETCH_ALL_SUB_CONTRACT_AGREEMENT_SUCCESS",

  FETCH_ONE_SUB_CONTRACT_AGREEMENT: "FETCH_ONE_SUB_CONTRACT_AGREEMENT",
  FETCH_ONE_SUB_CONTRACT_AGREEMENT_RESET:
    "FETCH_ONE_SUB_CONTRACT_AGREEMENT_RESET",
  FETCH_ONE_SUB_CONTRACT_AGREEMENT_FAILURE:
    "FETCH_ONE_SUB_CONTRACT_AGREEMENT_FAILURE",
  FETCH_ONE_SUB_CONTRACT_AGREEMENT_SUCCESS:
    "FETCH_ONE_SUB_CONTRACT_AGREEMENT_SUCCESS",
};
