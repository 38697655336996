import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { StaffMealActionTypes } from "./StaffMeal.type";

export function* fetchAllStaffMeals(action: any): any {
  try {
    let keys: any[] = Object.keys(action?.payload);

    let assigns: any[] = keys.map(
      (key: any) => `${key}=${action?.payload[key]}`
    );
    let query: string = assigns.join("&");

    const response = yield axios.get(`${API_BASE_URI}/staff-meal?${query}`);
    yield put({
      type: StaffMealActionTypes.FETCH_ALL_STAFF_MEAL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffMealActionTypes.FETCH_ALL_STAFF_MEAL_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneStaffMeals(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/staff-meal/${action.payload}`
    );
    yield put({
      type: StaffMealActionTypes.FETCH_ONE_STAFF_MEAL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffMealActionTypes.FETCH_ONE_STAFF_MEAL_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllStaffMeals() {
  yield takeLatest(
    StaffMealActionTypes.FETCH_ALL_STAFF_MEAL,
    fetchAllStaffMeals
  );
}

export function* watcherFetchOneStaffMeals() {
  yield takeLatest(
    StaffMealActionTypes.FETCH_ONE_STAFF_MEAL,
    fetchOneStaffMeals
  );
}
