import { HRRequest } from "../HRRequest/HRRequest.type";
import { ApiCallState } from "../Utils";

export const VacancyActionTypes = {
  FETCH_ALL_VACANCY: "FETCH_ALL_VACANCY",
  FETCH_ALL_VACANCY_FAILURE: "FETCH_ALL_VACANCY_FAILURE",
  FETCH_ALL_VACANCY_RESET: "FETCH_ALL_VACANCY_RESET",
  FETCH_ALL_VACANCY_SUCCESS: "FETCH_ALL_VACANCY_SUCCESS",

  FETCH_ONE_VACANCY: "FETCH_ONE_VACANCY",
  FETCH_ONE_VACANCY_RESET: "FETCH_ONE_VACANCY_RESET",
  FETCH_ONE_VACANCY_FAILURE: "FETCH_ONE_VACANCY_FAILURE",
  FETCH_ONE_VACANCY_SUCCESS: "FETCH_ONE_VACANCY_SUCCESS",

  FETCH_ALL_BY_ATTRIBUTES: "FETCH_ALL_BY_ATTRIBUTES",
  FETCH_ALL_BY_ATTRIBUTES_FAILURE: "FETCH_ALL_BY_ATTRIBUTES_FAILURE",
  FETCH_ALL_BY_ATTRIBUTES_RESET: "FETCH_ALL_BY_ATTRIBUTES_RESET",
  FETCH_ALL_BY_ATTRIBUTES_SUCCESS: "FETCH_ALL_BY_ATTRIBUTES_SUCCESS",

  FETCH_ALL_BY_JOB_ID: "FETCH_ALL_BY_JOB_ID",
  FETCH_ALL_BY_JOB_ID_FAILURE: "FETCH_ALL_BY_JOB_ID_FAILURE",
  FETCH_ALL_BY_JOB_ID_RESET: "FETCH_ALL_BY_JOB_ID_RESET",
  FETCH_ALL_BY_JOB_ID_SUCCESS: "FETCH_ALL_BY_JOB_ID_SUCCESS",
};

export type Vacancy = {
  id: number;
  date: string;
  working_days: string;
  hr_requests: string;
  position: string;
  vacancy_items: VacancyItem[],
  vacancy_statuses: any[],
  hr_request: HRRequest 
};

export type VacancyItem = {
  id: number,
  position: string,
  required_no: number,
  qualification: string,
  profession:string,
  experience: string,
  place_of_work: string,
}

export type VacancyStateTypes = {
  fetchAll: ApiCallState<Vacancy[]>;
  fetchOne: ApiCallState<Vacancy | {}>;
};
