import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PenalityActionTypes } from "./Penality.type";

export function* fetchAllPenalitys(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/penality`);
    yield put({
      type: PenalityActionTypes.FETCH_ALL_PENALITY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PenalityActionTypes.FETCH_ALL_PENALITY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePenalitys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/penality/${action.payload}`
    );
    yield put({
      type: PenalityActionTypes.FETCH_ONE_PENALITY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PenalityActionTypes.FETCH_ONE_PENALITY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPenalitys() {
  yield takeLatest(PenalityActionTypes.FETCH_ALL_PENALITY, fetchAllPenalitys);
}

export function* watcherFetchOnePenalitys() {
  yield takeLatest(PenalityActionTypes.FETCH_ONE_PENALITY, fetchOnePenalitys);
}
