import { Document } from "../Document/Document.type";
import { Material } from "../Material/Material.type";
import { Store } from "../Store/Store.type";
import { User } from "../User/User.type";
import { GoodIssue } from "../GoodIssue/GoodIssue.type";
import { ApiCallState, PagedData } from "../Utils";

export type MaterialRequisition = {
  mr_number: number;
  requested_to: number;
  date: string;
  id: any;
  remark: string;
  is_planned: boolean;
  type: string;
  division_block: string;
  material_requisition_items: MaterialRequisitionItem[];
  material_requisition_documents: MaterialRequisitionDocument[];
  mr_requested_by: Store;
  mr_requested_to: Store;
  requested_by: number;
  mr_prepared_by: User | null;
  mr_checked_by: User;
  mr_approved_by: User;
  purpose: string;
  is_done: boolean;
  material_requisition_statuses: MaterialRequisitionStatus[];
  material_requisition_remarks: MaterialRequisitionRemark[];
  delivery_time: string;
  status: MaterialRequisitionStatus[];
  pad_ref: string;
  user_id: number;
  updatedAt: string;
  is_void: boolean;
  apartment_unit: string;
  good_issues: GoodIssue[];
};

export type MaterialRequisitionRemark = {
  time: string;
  remark: string;
  user_id: number;
  document?: Document;
};

export type MaterialRequisitionStatus = {
  material_requisition_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type MaterialRequisitionItem = {
  material: Material;
  quantity: number;
  estimate_cost: number;
  required_specification: string;
  additional_quantity: number;
  received: number;
  material_id: number;
  id: number;
  unit: string;
  part_no: string;
  equipment_code: string;
  model_no: string;
  engine_no: string;
  chassis_no: string;
  availability: number;
  remark: string;
  specification: string;
};

export type MaterialRequisitionDocument = {
  id: number;
  material_requisition_id: number;
  document_id: number;
  description: string;
  document: Document;
};

export type MaterialRequisitionStateTypes = {
  fetchList: ApiCallState<{ id: number; date: string }[]>;
  fetchAll: ApiCallState<MaterialRequisition[]>;
  fetchPaged: ApiCallState<PagedData<MaterialRequisition[]>>;
  fetchOne: ApiCallState<MaterialRequisition | {}>;
  fetchPreviousItemList: ApiCallState<{
    previous: number;
    total: number;
  } | null>;
};

export const MaterialRequisitionActionTypes = {
  FETCH_ALL_MATERIAL_REQUISITION: "FETCH_ALL_MATERIAL_REQUISITION",
  FETCH_ALL_MATERIAL_REQUISITION_RESET: "FETCH_ALL_MATERIAL_REQUISITION_RESET",
  FETCH_ALL_MATERIAL_REQUISITION_FAILURE:
    "FETCH_ALL_MATERIAL_REQUISITION_FAILURE",
  FETCH_ALL_MATERIAL_REQUISITION_SUCCESS:
    "FETCH_ALL_MATERIAL_REQUISITION_SUCCESS",

  FETCH_ONE_MATERIAL_REQUISITION: "FETCH_ONE_MATERIAL_REQUISITION",
  FETCH_ONE_MATERIAL_REQUISITION_RESET: "FETCH_ONE_MATERIAL_REQUISITION_RESET",
  FETCH_ONE_MATERIAL_REQUISITION_FAILURE:
    "FETCH_ONE_MATERIAL_REQUISITION_FAILURE",
  FETCH_ONE_MATERIAL_REQUISITION_SUCCESS:
    "FETCH_ONE_MATERIAL_REQUISITION_SUCCESS",

  FETCH_MATERIAL_REQUISITION_ITEM_PREVIOUS_LIST:
    "FETCH_MATERIAL_REQUISITION_ITEM_PREVIOUS_LIST",
  FETCH_MATERIAL_REQUISITION_ITEM_PREVIOUS_LIST_RESET:
    "FETCH_MATERIAL_REQUISITION_ITEM_PREVIOUS_LIST_RESET",
  FETCH_MATERIAL_REQUISITION_ITEM_PREVIOUS_LIST_FAILURE:
    "FETCH_MATERIAL_REQUISITION_ITEM_PREVIOUS_LIST_FAILURE",
  FETCH_MATERIAL_REQUISITION_ITEM_PREVIOUS_LIST_SUCCESS:
    "FETCH_MATERIAL_REQUISITION_ITEM_PREVIOUS_LIST_SUCCESS",

  FETCH_PAGED_MATERIAL_REQUISITION: "FETCH_PAGED_MATERIAL_REQUISITION",
  FETCH_PAGED_MATERIAL_REQUISITION_RESET:
    "FETCH_PAGED_MATERIAL_REQUISITION_RESET",
  FETCH_PAGED_MATERIAL_REQUISITION_FAILURE:
    "FETCH_PAGED_MATERIAL_REQUISITION_FAILURE",
  FETCH_PAGED_MATERIAL_REQUISITION_SUCCESS:
    "FETCH_PAGED_MATERIAL_REQUISITION_SUCCESS",

  FETCH_LIST_MATERIAL_REQUISITION: "FETCH_LIST_MATERIAL_REQUISITION",
  FETCH_LIST_MATERIAL_REQUISITION_RESET:
    "FETCH_LIST_MATERIAL_REQUISITION_RESET",
  FETCH_LIST_MATERIAL_REQUISITION_FAILURE:
    "FETCH_LIST_MATERIAL_REQUISITION_FAILURE",
  FETCH_LIST_MATERIAL_REQUISITION_SUCCESS:
    "FETCH_LIST_MATERIAL_REQUISITION_SUCCESS",
};
