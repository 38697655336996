import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { EquipmentProductivityActionTypes } from "./EquipmentProductivity.type";

export function* fetchAllEquipmentProductivitys(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/equipment-productivity?project_id=${action.payload.project_id}`);
    yield put({
      type: EquipmentProductivityActionTypes.FETCH_ALL_EQUIPMENT_PRODUCTIVITY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EquipmentProductivityActionTypes.FETCH_ALL_EQUIPMENT_PRODUCTIVITY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneEquipmentProductivitys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/equipment-productivity/${action.payload}`
    );
    yield put({
      type: EquipmentProductivityActionTypes.FETCH_ONE_EQUIPMENT_PRODUCTIVITY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EquipmentProductivityActionTypes.FETCH_ONE_EQUIPMENT_PRODUCTIVITY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllEquipmentProductivitys() {
  yield takeLatest(EquipmentProductivityActionTypes.FETCH_ALL_EQUIPMENT_PRODUCTIVITY, fetchAllEquipmentProductivitys);
}

export function* watcherFetchOneEquipmentProductivitys() {
  yield takeLatest(EquipmentProductivityActionTypes.FETCH_ONE_EQUIPMENT_PRODUCTIVITY, fetchOneEquipmentProductivitys);
}
