import { Boq } from "../Boq/Boq.type";
import { ApiCallState } from "../Utils";

export type PlanExecution= {
  id: number;
  date: string,
  daily_plan_execution_items: PlanExecutionItem[],
  boq: Boq
};

export type PlanExecutionItem = {
    id?: number;
    item_no: string;
    description: string;
    unit: string;
    unit_rate: number;
    planned_quantity: number;
    planned_amount: number;
    executed_quantity: number;
    executed_amount: number;
    planned_executed_percentage: number;
    justification_for_unexecuted_work: string;
    is_title: boolean
}

export type PlanExecutionStateTypes = {
  fetchAll: ApiCallState<PlanExecution[]>;
  fetchOne: ApiCallState<PlanExecution | {}>;
};

export const PlanExecutionActionTypes = {
  FETCH_ALL_PLAN_EXECUTION: "FETCH_ALL_PLAN_EXECUTION",
  FETCH_ALL_PLAN_EXECUTION_RESET: "FETCH_ALL_PLAN_EXECUTION_RESET",
  FETCH_ALL_PLAN_EXECUTION_FAILURE: "FETCH_ALL_PLAN_EXECUTION_FAILURE",
  FETCH_ALL_PLAN_EXECUTION_SUCCESS: "FETCH_ALL_PLAN_EXECUTION_SUCCESS",

  FETCH_ONE_PLAN_EXECUTION: "FETCH_ONE_PLAN_EXECUTION",
  FETCH_ONE_PLAN_EXECUTION_RESET: "FETCH_ONE_PLAN_EXECUTION_RESET",
  FETCH_ONE_PLAN_EXECUTION_FAILURE: "FETCH_ONE_PLAN_EXECUTION_FAILURE",
  FETCH_ONE_PLAN_EXECUTION_SUCCESS: "FETCH_ONE_PLAN_EXECUTION_SUCCESS",
};
