import { Material } from "../Material/Material.type";
import { Store } from "../Store/Store.type";
import { ApiCallState } from "../Utils";

export type GatePass = {
  id: number;
  ref_no: number;
  date: string;
  store_id: number;
  material_going_to: string;
  car_plate: string;
  received_driver: string;
  issued_by: number;
  approved_by: number;
  store: Store;
  gate_pass_items:GatePassItem[];
  gate_pass_statuses:GatePassStatus[];
  user_id: number;
};

export type GatePassItem = {
  id: number;
  gate_pass_id: number;
  material_id: number;
  quantity: number;
  reason: string;
  material: Material;
};

export type GatePassStatus = {
  gate_pass_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type GatePassStateTypes = {
  fetchAll: ApiCallState<GatePass[]>;
  fetchOne: ApiCallState<GatePass | {}>;
};

export const GatePassActionTypes = {
  FETCH_ALL_GATE_PASS: "FETCH_ALL_GATE_PASS",
  FETCH_ALL_GATE_PASS_RESET: "FETCH_ALL_GATE_PASS_RESET",
  FETCH_ALL_GATE_PASS_FAILURE: "FETCH_ALL_GATE_PASS_FAILURE",
  FETCH_ALL_GATE_PASS_SUCCESS: "FETCH_ALL_GATE_PASS_SUCCESS",

  FETCH_ONE_GATE_PASS: "FETCH_ONE_GATE_PASS",
  FETCH_ONE_GATE_PASS_RESET: "FETCH_ONE_GATE_PASS_RESET",
  FETCH_ONE_GATE_PASS_FAILURE: "FETCH_ONE_GATE_PASS_FAILURE",
  FETCH_ONE_GATE_PASS_SUCCESS: "FETCH_ONE_GATE_PASS_SUCCESS",
};
