import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MonthlyProgressReportActionTypes } from "./MonthlyProgressReport.type";

export function* fetchAllMonthlyProgressReports(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/monthly-progress-report?date=${action.payload?.date}`);
    yield put({
      type: MonthlyProgressReportActionTypes.FETCH_ALL_MONTHLY_PROGRESS_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MonthlyProgressReportActionTypes.FETCH_ALL_MONTHLY_PROGRESS_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMonthlyProgressReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/monthly-progress-report/previous?date=${action.payload?.date}`
    );
    yield put({
      type: MonthlyProgressReportActionTypes.FETCH_ONE_MONTHLY_PROGRESS_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MonthlyProgressReportActionTypes.FETCH_ONE_MONTHLY_PROGRESS_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMonthlyProgressReports() {
  yield takeLatest(MonthlyProgressReportActionTypes.FETCH_ALL_MONTHLY_PROGRESS_REPORT, fetchAllMonthlyProgressReports);
}

export function* watcherFetchOneMonthlyProgressReports() {
  yield takeLatest(MonthlyProgressReportActionTypes.FETCH_ONE_MONTHLY_PROGRESS_REPORT, fetchOneMonthlyProgressReports);
}
