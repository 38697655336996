import { ProblemEncounteredStateTypes, ProblemEncounteredActionTypes } from "./ProblemEncountered.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ProblemEncounteredStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const ProblemEncounteredReducer = (
  state: ProblemEncounteredStateTypes = INITIAL_STATE,
  action: any
): ProblemEncounteredStateTypes => {
  switch (action.type) {
    case ProblemEncounteredActionTypes.FETCH_ALL_PROBLEM_ENCOUNTERED:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ProblemEncounteredActionTypes.FETCH_ALL_PROBLEM_ENCOUNTERED_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ProblemEncounteredActionTypes.FETCH_ALL_PROBLEM_ENCOUNTERED_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ProblemEncounteredActionTypes.FETCH_ALL_PROBLEM_ENCOUNTERED_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ProblemEncounteredActionTypes.FETCH_ONE_PROBLEM_ENCOUNTERED:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ProblemEncounteredActionTypes.FETCH_ONE_PROBLEM_ENCOUNTERED_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ProblemEncounteredActionTypes.FETCH_ONE_PROBLEM_ENCOUNTERED_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ProblemEncounteredActionTypes.FETCH_ONE_PROBLEM_ENCOUNTERED_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ProblemEncounteredReducer;
