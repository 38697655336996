import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SelfAttendingUserActionTypes } from "./SelfAttendingUser.type";

export function* fetchAllSelfAttendingUsers(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/self-attending-user`);
    yield put({
      type: SelfAttendingUserActionTypes.FETCH_ALL_SELF_ATTENDING_USER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SelfAttendingUserActionTypes.FETCH_ALL_SELF_ATTENDING_USER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSelfAttendingUsers(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/self-attending-user/${action.payload}`
    );
    yield put({
      type: SelfAttendingUserActionTypes.FETCH_ONE_SELF_ATTENDING_USER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SelfAttendingUserActionTypes.FETCH_ONE_SELF_ATTENDING_USER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSelfAttendingUsers() {
  yield takeLatest(
    SelfAttendingUserActionTypes.FETCH_ALL_SELF_ATTENDING_USER,
    fetchAllSelfAttendingUsers
  );
}

export function* watcherFetchOneSelfAttendingUsers() {
  yield takeLatest(
    SelfAttendingUserActionTypes.FETCH_ONE_SELF_ATTENDING_USER,
    fetchOneSelfAttendingUsers
  );
}
