import {
  StoreCommunicationStateTypes,
  StoreCommunicationActionTypes,
} from "./StoreCommunication.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: StoreCommunicationStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchReport: resetApiCallState([]),
};

const StoreCommunicationReducer = (
  state: StoreCommunicationStateTypes = INITIAL_STATE,
  action: any
): StoreCommunicationStateTypes => {
  switch (action.type) {
    case StoreCommunicationActionTypes.FETCH_ALL_STORE_COMMUNICATION:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case StoreCommunicationActionTypes.FETCH_ALL_STORE_COMMUNICATION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case StoreCommunicationActionTypes.FETCH_ALL_STORE_COMMUNICATION_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case StoreCommunicationActionTypes.FETCH_ALL_STORE_COMMUNICATION_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    // ---

    case StoreCommunicationActionTypes.FETCH_STORE_COMMUNICATION_REPORT:
      return {
        ...state,
        fetchReport: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case StoreCommunicationActionTypes.FETCH_STORE_COMMUNICATION_REPORT_RESET:
      return {
        ...state,
        fetchReport: resetApiCallState([]),
      };
    case StoreCommunicationActionTypes.FETCH_STORE_COMMUNICATION_REPORT_FAILURE:
      return {
        ...state,
        fetchReport: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case StoreCommunicationActionTypes.FETCH_STORE_COMMUNICATION_REPORT_SUCCESS:
      return {
        ...state,
        fetchReport: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default StoreCommunicationReducer;
