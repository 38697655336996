import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { FinishedProductActionTypes } from "./FinishedProduct.type";
import { formatQuery } from "../Utils";

export function* fetchAllFinishedProducts(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/finished-product?${formatQuery(action)}`
    );
    yield put({
      type: FinishedProductActionTypes.FETCH_ALL_FINISHED_PRODUCT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FinishedProductActionTypes.FETCH_ALL_FINISHED_PRODUCT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchReportFinishedProducts(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/finished-product/report?${formatQuery(action)}`
    );
    yield put({
      type: FinishedProductActionTypes.FETCH_REPORT_FINISHED_PRODUCT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FinishedProductActionTypes.FETCH_REPORT_FINISHED_PRODUCT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedFinishedProducts(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/finished-product?${query}`);
    yield put({
      type: FinishedProductActionTypes.FETCH_PAGED_FINISHED_PRODUCT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FinishedProductActionTypes.FETCH_PAGED_FINISHED_PRODUCT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneFinishedProducts(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/finished-product/${action.payload}`
    );
    yield put({
      type: FinishedProductActionTypes.FETCH_ONE_FINISHED_PRODUCT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FinishedProductActionTypes.FETCH_ONE_FINISHED_PRODUCT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllFinishedProducts() {
  yield takeLatest(FinishedProductActionTypes.FETCH_ALL_FINISHED_PRODUCT, fetchAllFinishedProducts);
}

export function* watcherFetchReportFinishedProducts() {
  yield takeLatest(FinishedProductActionTypes.FETCH_REPORT_FINISHED_PRODUCT, fetchReportFinishedProducts);
}

export function* watcherFetchPagedFinishedProducts() {
  yield takeLatest(FinishedProductActionTypes.FETCH_PAGED_FINISHED_PRODUCT, fetchPagedFinishedProducts);
}

export function* watcherFetchOneFinishedProducts() {
  yield takeLatest(FinishedProductActionTypes.FETCH_ONE_FINISHED_PRODUCT, fetchOneFinishedProducts);
}
