import _ from "lodash";
import { Department } from "../../../../redux/Department/Department.type";
import { ApplicantRegistry } from "../../../../redux/ApplicantRegistry/ApplicantRegistry.type"
import { ApplicantRegistryItem } from "../../../../redux/ApplicantRegistryItem/ApplicantRegistryItem.type";
import { ApiCallState } from "../../../../redux/Utils"

export type AssessmentSummaryPropType = {
    applicantRegistry: ApiCallState<ApplicantRegistry>,
    departments: ApiCallState<Department[]>,
    fetchApplicantRegistry: Function,
    fetchDepartments: Function
};

export const formatAssessmentSummary = (applicantRegistry?: ApplicantRegistry) => {
    const formatted: any[] = [];
    const rank = getWinner(applicantRegistry).rank;
    applicantRegistry?.applicant_registry_items?.forEach((applicantRegistryItem, i) => {
        const totalEval = calculateTotalEval(applicantRegistryItem);
        let educationLevel = 0;
        let workExperience = 0;
        let understandResponsibility = 0;
        let problemSolvingAbility = 0;
        let personality = 0;
        let specialSkill = 0;
        let technicalSkill = 0;
        let total = 0;
        const assessmentLen = applicantRegistryItem?.assessments?.length ?? 0;
        applicantRegistryItem?.assessments?.forEach((assessment, index) => {
            const totalAssessment = assessment?.assessment_evaluations.reduce((sum, item) => sum += item.point_given, 0);
            educationLevel += assessment?.assessment_evaluations?.find((elem) => elem?.criteria === "Educational Level")?.point_given ?? 0;
            workExperience += assessment?.assessment_evaluations?.find((elem) => elem?.criteria === "Work Experience")?.point_given ?? 0;
            understandResponsibility += assessment?.assessment_evaluations?.find((elem) => elem?.criteria === "Understand & respond ability")?.point_given ?? 0;
            problemSolvingAbility += assessment?.assessment_evaluations?.find((elem) => elem?.criteria === "Problem solving ability")?.point_given ?? 0;
            personality += assessment?.assessment_evaluations?.find((elem) => elem?.criteria === "Personality")?.point_given ?? 0;
            specialSkill += assessment?.assessment_evaluations?.find((elem) => elem?.criteria === "Special skill")?.point_given ?? 0;
            technicalSkill += assessment?.assessment_evaluations?.find((elem) => elem?.criteria === "Technical skill")?.point_given ?? 0;
            total += totalAssessment;
        });
        formatted.push({
            name: applicantRegistryItem.name,
            educational_level: educationLevel / assessmentLen,
            work_experience: workExperience / assessmentLen,
            understand_responsibility: understandResponsibility / assessmentLen,
            problem_solving_ability: problemSolvingAbility / assessmentLen,
            personality: personality / assessmentLen,
            special_skill: specialSkill / assessmentLen,
            technical_skill: technicalSkill / assessmentLen,
            total: total / assessmentLen,
            average_result: (total / assessmentLen) / 2,
            rank: rank[i],
            status: (total / assessmentLen) === getWinner(applicantRegistry).winner ? "Winner" : null
        })
    });

    return formatted;
}

const getWinner = (applicantRegistry?: ApplicantRegistry) => {
    const formatted: number[] = [];
    applicantRegistry?.applicant_registry_items?.forEach((applicantRegistryItem) => {
        const totalEval = calculateTotalEval(applicantRegistryItem)
        const assessmentLen = applicantRegistryItem?.assessments?.length ?? 0;
        formatted.push(totalEval / (assessmentLen === 0 ? 1: assessmentLen))
    });

    return {
        rank: rankings(formatted),
        winner: Math.max(...formatted)
    }
};


const rankings = (array: number[]) => {
    return array
      .map((v, i) => [v, i])
      .sort((a, b) => b[0] - a[0])
      .map((a, i) => [...a, i + 1])
      .sort((a, b) => a[1] - b[1])
      .map(a => a[2]);
}

export const formatEvaluator = (applicantRegistry?: ApplicantRegistry) => {
    const formatted: any[] = [];
    applicantRegistry?.applicant_registry_items?.forEach((applicantRegistryItem) => {
        applicantRegistryItem.assessments?.forEach((assessment) => {
            if (assessment?.user?.full_name) formatted.push(assessment?.user?.full_name);
        })
    });

    return _.uniq(formatted);
}

const calculateTotalEval = (applicantRegistryItem: ApplicantRegistryItem) => {
    let total = 0;
    applicantRegistryItem.assessments?.forEach((assessment) => {
        assessment.assessment_evaluations.forEach((evals) => {
            total += evals.point_given
        })
    });
    return total;
}
