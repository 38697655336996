import _, { toString } from "lodash";
import { Units } from "../../constants/Constants";

class BuildingSiteAttendance {
  data: any[];
  sheet_name: string;
  type: any;
  parsed: any[];

  constructor(data: any, sheet_name: string) {
    this.data = data;
    this.type = "";
    this.parsed = [];
    this.sheet_name = sheet_name;
  }

  parseBoq() {
    const previous_type = [];
    let start = -1;

    for (let index = 0; index < this.data.length; index++) {
      const col = this.data[index];
      if (this.isTableState(col)) start = index;
      else if (start !== -1) {
        if (col[1] && col[2] && col[3]) {
          this.parsed.push({
            id_no: col[1],
            title:"",
            full_name: col[2],
            position: col[3],
            daily_wage:
              _.isNil(col[4]) || col[4] === "-" ? 0 : _.toNumber(col[4]),
            working_days:
              _.isNil(col[5]) || col[5] === "-" ? 0 : _.toNumber(col[5]),
            normal: _.isNil(col[6]) || col[6] === "-" ? 0 : _.toNumber(col[6]),
            sunday: _.isNil(col[7]) || col[7] === "-" ? 0 : _.toNumber(col[7]),
            holyday: _.isNil(col[8]) || col[8] === "-" ? 0 : _.toNumber(col[8]),
            is_title:false,
            key: this.parsed.length,
          });
        }
        if (col[0] && !col[2] && !col[3] && !col[4] && !col[5] && !col[6]) {
          this.parsed.push({
            id_no: "",
            title:col[0],
            full_name: "",
            position: "",
            daily_wage:0,
            working_days:0,
            normal:0,
            sunday: 0,
            holyday: 0,
            is_title:true,
            key: this.parsed.length,
          });
        }
      }
      if (this.type) previous_type.push(this.type);
    }

    return this.parsed;
  }

  private isTableState(col: any[]) {
    return (
      _.isString(col[0]) &&
      _.isString(col[1]) &&
      _.isString(col[2]) &&
      _.isString(col[3]) &&
      _.isString(col[4]) &&
      _.isString(col[5]) &&
      _.isString(col[6]) 
    );
  }
}
export default BuildingSiteAttendance;
