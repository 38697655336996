import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ServiceRequestActionTypes } from "./ServiceRequest.type";
import { formatQuery } from "../Utils";

export function* fetchAllServiceRequests(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/service-request?${formatQuery(action)}`
    );
    yield put({
      type: ServiceRequestActionTypes.FETCH_ALL_SERVICE_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ServiceRequestActionTypes.FETCH_ALL_SERVICE_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneServiceRequests(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/service-request/${action.payload}`
    );
    yield put({
      type: ServiceRequestActionTypes.FETCH_ONE_SERVICE_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ServiceRequestActionTypes.FETCH_ONE_SERVICE_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllServiceRequests() {
  yield takeLatest(
    ServiceRequestActionTypes.FETCH_ALL_SERVICE_REQUEST,
    fetchAllServiceRequests
  );
}

export function* watcherFetchOneServiceRequests() {
  yield takeLatest(
    ServiceRequestActionTypes.FETCH_ONE_SERVICE_REQUEST,
    fetchOneServiceRequests
  );
}
