import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { DailyTaskActionTypes } from "./DailyTask.type";

export function* fetchAllDailyTasks(action: any): any {
  try {
    let query = "";

    if (action.payload && Object.keys(action.payload).length > 0) {
      query = `?${Object.keys(action.payload)
        .map((key) => `${key}=${action.payload[key]}`)
        .join("&")}`;
    }

    const response = yield axios.get(`${API_BASE_URI}/daily-task${query}`);
    yield put({
      type: DailyTaskActionTypes.FETCH_ALL_DAILY_TASK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DailyTaskActionTypes.FETCH_ALL_DAILY_TASK_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneDailyTasks(action: any): any {
  try {
    let query = "";

    if (action.payload && Object.keys(action.payload).length > 0) {
      query = `?${Object.keys(action.payload)
        .map((key) => `${key}=${action.payload[key]}`)
        .join("&")}`;
    }

    const response = yield axios.get(`${API_BASE_URI}/daily-task/one${query}`);
    yield put({
      type: DailyTaskActionTypes.FETCH_ONE_DAILY_TASK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DailyTaskActionTypes.FETCH_ONE_DAILY_TASK_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllDailyTasks() {
  yield takeLatest(
    DailyTaskActionTypes.FETCH_ALL_DAILY_TASK,
    fetchAllDailyTasks
  );
}

export function* watcherFetchOneDailyTasks() {
  yield takeLatest(
    DailyTaskActionTypes.FETCH_ONE_DAILY_TASK,
    fetchOneDailyTasks
  );
}
