import { ApiCallState } from "../Utils";

export type Penality= {
  id: number;
  description: string;
  amount: number;
  date: string;
  staff_id: number;
  user_id: number;
};

export type PenalityStateTypes = {
  fetchAll: ApiCallState<Penality[]>;
  fetchOne: ApiCallState<Penality | {}>;
};

export const PenalityActionTypes = {
  FETCH_ALL_PENALITY: "FETCH_ALL_PENALITY",
  FETCH_ALL_PENALITY_RESET: "FETCH_ALL_PENALITY_RESET",
  FETCH_ALL_PENALITY_FAILURE: "FETCH_ALL_PENALITY_FAILURE",
  FETCH_ALL_PENALITY_SUCCESS: "FETCH_ALL_PENALITY_SUCCESS",

  FETCH_ONE_PENALITY: "FETCH_ONE_PENALITY",
  FETCH_ONE_PENALITY_RESET: "FETCH_ONE_PENALITY_RESET",
  FETCH_ONE_PENALITY_FAILURE: "FETCH_ONE_PENALITY_FAILURE",
  FETCH_ONE_PENALITY_SUCCESS: "FETCH_ONE_PENALITY_SUCCESS",
};
