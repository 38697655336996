import { ApiCallState } from "../Utils";

export type Alert = {
  id: number;

  user_id: number;

  description: string;
  date: string;
  remind_before: number;
  assigned_to: number[];

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type AlertStateTypes = {
  fetchAll: ApiCallState<Alert[]>;
  fetchOne: ApiCallState<Alert | {}>;
};

export const AlertActionTypes = {
  FETCH_ALL_ALERT: "FETCH_ALL_ALERT",
  FETCH_ALL_ALERT_RESET: "FETCH_ALL_ALERT_RESET",
  FETCH_ALL_ALERT_FAILURE: "FETCH_ALL_ALERT_FAILURE",
  FETCH_ALL_ALERT_SUCCESS: "FETCH_ALL_ALERT_SUCCESS",

  FETCH_ONE_ALERT: "FETCH_ONE_ALERT",
  FETCH_ONE_ALERT_RESET: "FETCH_ONE_ALERT_RESET",
  FETCH_ONE_ALERT_FAILURE: "FETCH_ONE_ALERT_FAILURE",
  FETCH_ONE_ALERT_SUCCESS: "FETCH_ONE_ALERT_SUCCESS",
};
