import {
  FixedAssetOutStateTypes,
  FixedAssetOutActionTypes,
} from "./FixedAssetOut.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: FixedAssetOutStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchReport: resetApiCallState([]),
};

const FixedAssetOutReducer = (
  state: FixedAssetOutStateTypes = INITIAL_STATE,
  action: any
): FixedAssetOutStateTypes => {
  switch (action.type) {
    case FixedAssetOutActionTypes.FETCH_ALL_FIXED_ASSET_OUT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case FixedAssetOutActionTypes.FETCH_ALL_FIXED_ASSET_OUT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case FixedAssetOutActionTypes.FETCH_ALL_FIXED_ASSET_OUT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FixedAssetOutActionTypes.FETCH_ALL_FIXED_ASSET_OUT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case FixedAssetOutActionTypes.FETCH_REPORT_FIXED_ASSET_OUT:
      return {
        ...state,
        fetchReport: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case FixedAssetOutActionTypes.FETCH_REPORT_FIXED_ASSET_OUT_RESET:
      return {
        ...state,
        fetchReport: resetApiCallState([]),
      };
    case FixedAssetOutActionTypes.FETCH_REPORT_FIXED_ASSET_OUT_FAILURE:
      return {
        ...state,
        fetchReport: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FixedAssetOutActionTypes.FETCH_REPORT_FIXED_ASSET_OUT_SUCCESS:
      return {
        ...state,
        fetchReport: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default FixedAssetOutReducer;
