import { ApiCallState } from "../Utils";

export type ContractorFacilityCost= {
  id: number;
  start_date: string;
  end_date: string;
  project_id: number;
  user_id: number;
  contractor_camp_facilities: ContractorCampFacility[],
  contractor_kitchen_tool_equipments: ContractorKitchenToolEquipment[],
  contractor_mobile_card_allowances: ContractorMobileCardAllowance[],
  contractor_office_furnitures: ContractorOfficeFurniture[],
  contractor_office_supplies_facilities: ContractorOfficeSuppliesFacility[],
  contractor_other_facilities: ContractorOtherFacility[],
  contractor_temporary_office_rents: ContractorTemporaryOfficeRent[]
};

export type ContractorCampFacility = {
  id: number;
  description: string;
  unit: string;
  quantity: number;
  delivery_date: string;
  purchasing_price_per_unit: number;
  previous_cost: number;
  this_period_cost: number;
  to_date_cost: number;
  contractor_facility_cost_id: number;
}

export type ContractorKitchenToolEquipment = {
  id: number;
  description: string;
  unit: string;
  quantity: number;
  delivery_date: string;
  purchasing_price_per_unit: number;
  previous_cost: number;
  this_period_cost: number;
  to_date_cost: number;
  contractor_facility_cost_id: number;
};

export type ContractorMobileCardAllowance = {
  id: number;
  description: string;
  previous_amount: number;
  this_period_amount: number;
  to_date_amount: number;
  contractor_facility_cost_id: number;
}

export type ContractorOfficeFurniture = {
  id: number;
  description: string;
  unit: string;
  quantity: number;
  delivery_date: string;
  purchasing_price_per_unit: number;
  previous_cost: number;
  this_period_cost: number;
  to_date_cost: number;
  contractor_facility_cost_id: number;
}

export type ContractorOfficeSuppliesFacility = {
  id: number;
  description: string;
  unit: string;
  quantity: number;
  delivery_date: string;
  purchasing_price_per_unit: number;
  previous_cost: number;
  this_period_cost: number;
  to_date_cost: number;
  contractor_facility_cost_id: number;
}

export type ContractorOtherFacility = {
  id: number;
  description: string;
  previous_amount: number;
  this_period_amount: number;
  to_date_amount: number;
  contractor_facility_cost_id: number;
}

export type ContractorTemporaryOfficeRent = {
  id: number;
  description: string;
  previous_amount: number;
  this_period_amount: number;
  to_date_amount: number;
  contractor_facility_cost_id: number;
}

export type ContractorFacilityCostStateTypes = {
  fetchAll: ApiCallState<ContractorFacilityCost[]>;
  fetchOne: ApiCallState<ContractorFacilityCost | {}>;
};

export const ContractorFacilityCostActionTypes = {
  FETCH_ALL_CONTRACTOR_FACILITY_COST: "FETCH_ALL_CONTRACTOR_FACILITY_COST",
  FETCH_ALL_CONTRACTOR_FACILITY_COST_RESET: "FETCH_ALL_CONTRACTOR_FACILITY_COST_RESET",
  FETCH_ALL_CONTRACTOR_FACILITY_COST_FAILURE: "FETCH_ALL_CONTRACTOR_FACILITY_COST_FAILURE",
  FETCH_ALL_CONTRACTOR_FACILITY_COST_SUCCESS: "FETCH_ALL_CONTRACTOR_FACILITY_COST_SUCCESS",

  FETCH_ONE_CONTRACTOR_FACILITY_COST: "FETCH_ONE_CONTRACTOR_FACILITY_COST",
  FETCH_ONE_CONTRACTOR_FACILITY_COST_RESET: "FETCH_ONE_CONTRACTOR_FACILITY_COST_RESET",
  FETCH_ONE_CONTRACTOR_FACILITY_COST_FAILURE: "FETCH_ONE_CONTRACTOR_FACILITY_COST_FAILURE",
  FETCH_ONE_CONTRACTOR_FACILITY_COST_SUCCESS: "FETCH_ONE_CONTRACTOR_FACILITY_COST_SUCCESS",
};
