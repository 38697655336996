import { ApiCallState } from "../Utils";

export type StaffTaxInformation= {
  id: number;
  staff_id:number;
  saving_account_no:string;
  tin_no:string;
  pension_no:string;
  pension:boolean;
  user_id:number;
};

export type StaffTaxInformationStateTypes = {
  fetchAll: ApiCallState<StaffTaxInformation[]>;
  fetchOne: ApiCallState<StaffTaxInformation | {}>;
};

export const StaffTaxInformationActionTypes = {
  FETCH_ALL_STAFF_TAX_INFORMATION: "FETCH_ALL_STAFF_TAX_INFORMATION",
  FETCH_ALL_STAFF_TAX_INFORMATION_RESET: "FETCH_ALL_STAFF_TAX_INFORMATION_RESET",
  FETCH_ALL_STAFF_TAX_INFORMATION_FAILURE: "FETCH_ALL_STAFF_TAX_INFORMATION_FAILURE",
  FETCH_ALL_STAFF_TAX_INFORMATION_SUCCESS: "FETCH_ALL_STAFF_TAX_INFORMATION_SUCCESS",

  FETCH_ONE_STAFF_TAX_INFORMATION: "FETCH_ONE_STAFF_TAX_INFORMATION",
  FETCH_ONE_STAFF_TAX_INFORMATION_RESET: "FETCH_ONE_STAFF_TAX_INFORMATION_RESET",
  FETCH_ONE_STAFF_TAX_INFORMATION_FAILURE: "FETCH_ONE_STAFF_TAX_INFORMATION_FAILURE",
  FETCH_ONE_STAFF_TAX_INFORMATION_SUCCESS: "FETCH_ONE_STAFF_TAX_INFORMATION_SUCCESS",
};
