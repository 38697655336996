import { ApiCallState } from "../Utils";

export type ActivityWaitage = {
  id: number;
  date: string;
  project_id: number;
  activity_waitage_items: ActivityWaitageItem[];
};

type ActivityWaitageItem = {
  id: number;
  description: string;
  percentage: number;
};

export type ActivityWaitageStateTypes = {
  fetchAll: ApiCallState<ActivityWaitage[]>;
  fetchOne: ApiCallState<ActivityWaitage | {}>;
};

export const ActivityWaitageActionTypes = {
  FETCH_ALL_ACTIVITY_WAITAGE: "FETCH_ALL_ACTIVITY_WAITAGE",
  FETCH_ALL_ACTIVITY_WAITAGE_RESET: "FETCH_ALL_ACTIVITY_WAITAGE_RESET",
  FETCH_ALL_ACTIVITY_WAITAGE_FAILURE: "FETCH_ALL_ACTIVITY_WAITAGE_FAILURE",
  FETCH_ALL_ACTIVITY_WAITAGE_SUCCESS: "FETCH_ALL_ACTIVITY_WAITAGE_SUCCESS",

  FETCH_ONE_ACTIVITY_WAITAGE: "FETCH_ONE_ACTIVITY_WAITAGE",
  FETCH_ONE_ACTIVITY_WAITAGE_RESET: "FETCH_ONE_ACTIVITY_WAITAGE_RESET",
  FETCH_ONE_ACTIVITY_WAITAGE_FAILURE: "FETCH_ONE_ACTIVITY_WAITAGE_FAILURE",
  FETCH_ONE_ACTIVITY_WAITAGE_SUCCESS: "FETCH_ONE_ACTIVITY_WAITAGE_SUCCESS",
};
