import { ApiCallState, PagedData } from "../Utils";

export type UserSeen = {
  type: string;
  time: string;
  parent_id: number;
};

export type UserSeenStateTypes = {
  fetchAll: ApiCallState<UserSeen[]>;
  fetchOne: ApiCallState<UserSeen | {}>;
};

export const UserSeenActionTypes = {
  FETCH_ALL_USER_SEEN: "FETCH_ALL_USER_SEEN",
  FETCH_ALL_USER_SEEN_RESET: "FETCH_ALL_USER_SEEN_RESET",
  FETCH_ALL_USER_SEEN_FAILURE: "FETCH_ALL_USER_SEEN_FAILURE",
  FETCH_ALL_USER_SEEN_SUCCESS: "FETCH_ALL_USER_SEEN_SUCCESS",

  FETCH_ONE_USER_SEEN: "FETCH_ONE_USER_SEEN",
  FETCH_ONE_USER_SEEN_RESET: "FETCH_ONE_USER_SEEN_RESET",
  FETCH_ONE_USER_SEEN_FAILURE: "FETCH_ONE_USER_SEEN_FAILURE",
  FETCH_ONE_USER_SEEN_SUCCESS: "FETCH_ONE_USER_SEEN_SUCCESS",

  SET_USER_SEEN: "SET_USER_SEEN",
  SET_USER_SEEN_RESET: "SET_USER_SEEN_RESET",
  SET_USER_SEEN_FAILURE: "SET_USER_SEEN_FAILURE",
  SET_USER_SEEN_SUCCESS: "SET_USER_SEEN_SUCCESS",
};
