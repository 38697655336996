import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { FuelRequestVoucherActionTypes } from "./FuelRequestVoucher.type";

export function* fetchAllFuelRequestVouchers(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/fuel-request-voucher`);
    yield put({
      type: FuelRequestVoucherActionTypes.FETCH_ALL_FUEL_REQUEST_VOUCHER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FuelRequestVoucherActionTypes.FETCH_ALL_FUEL_REQUEST_VOUCHER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneFuelRequestVouchers(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/fuel-request-voucher/${action.payload}`
    );
    yield put({
      type: FuelRequestVoucherActionTypes.FETCH_ONE_FUEL_REQUEST_VOUCHER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FuelRequestVoucherActionTypes.FETCH_ONE_FUEL_REQUEST_VOUCHER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllFuelRequestVouchers() {
  yield takeLatest(FuelRequestVoucherActionTypes.FETCH_ALL_FUEL_REQUEST_VOUCHER, fetchAllFuelRequestVouchers);
}

export function* watcherFetchOneFuelRequestVouchers() {
  yield takeLatest(FuelRequestVoucherActionTypes.FETCH_ONE_FUEL_REQUEST_VOUCHER, fetchOneFuelRequestVouchers);
}
