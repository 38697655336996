import { BillSummary } from "../BillSummary/BillSummary.type";
import {
  ConsultantAccomodation,
  ConsultantCampFacility,
  ConsultantKicthenToolEquipment,
  ConsultantOfficeFurniture,
  ConsultantOfficeSuppliesFacility,
  ConsultantOtherFacilityForEngineer,
  ConsultantTemporaryOfficeRent,
} from "../ConsultantFacilityCost/ConsultantFacilityCost.type";
import {
  ContractorCampFacility,
  ContractorKitchenToolEquipment,
  ContractorMobileCardAllowance,
  ContractorOfficeFurniture,
  ContractorOfficeSuppliesFacility,
  ContractorOtherFacility,
  ContractorTemporaryOfficeRent,
} from "../ContractorFacilityCost/ContractorFacilityCost.type";
import { DetailReportActivityItem } from "../DetailReportActivity/DetailReportActivity.type";
import { EquipmentCostItem } from "../EquipmentCost/EquipmentCost.type";
import { ProjectIncome } from "../IncomeExpense/IncomeExpense.type";
import {
  LaboratoryEquipmentCostItem,
  LaboratoryEquipmentCostOther,
} from "../LaboratoryEquipmentCost/LaboratoryEquipmentCost.type";
import { OtherExpenseItem } from "../OtherExpense/OtherExpense.type";
import {
  SurveyingEquipmentCostItem,
  SurveyingEquipmentCostOther,
} from "../SurveyingEquipmentCost/SurveyingEquipmentCost.type";
import { ApiCallState } from "../Utils";

export type MonthlySiteReportStateTypes = {
  fetchSurveyingEquipmentCostReport: ApiCallState<{
    items: SurveyingEquipmentCostItem[];
    other: SurveyingEquipmentCostOther[];
  }>;
  fetchLaboratoryEquipmentCostReport: ApiCallState<{
    items: LaboratoryEquipmentCostItem[];
    other: LaboratoryEquipmentCostOther[];
  }>;
  fetchConsultantFacilityCostReport: ApiCallState<MonthlyConsultantFacilityCost>;
  fetchContractorFacilityCostReport: ApiCallState<MonthlyContractorFacilityCost>;
  fetchEquipmentCostReport: ApiCallState<EquipmentCostItem[]>;
  fetchOtherExpenseReport: ApiCallState<OtherExpenseItem[]>;
  fetchSummaryOfCost: ApiCallState<SummaryOfCostItem[]>;
  fetchDetailReportOfActivity: ApiCallState<DetailReportActivityItem[]>;
  fetchTotalProjectExpenseReport: ApiCallState<TotalProjectExpenseType[]>;
  fetchIncomeExpenseReport: ApiCallState<BillSummary[]>;
  fetchTotalProjectIncomeReport: ApiCallState<ProjectIncome | {}>;
  fetchProgressBillSummary: ApiCallState<BillSummary[]>;
  fetchCriticalIssueReport: ApiCallState<CriticalIssueReport[]>;
};

export type CriticalIssueReport = {
  equipment_name: string;
  plate_number: string;
  standard_fuel_consumption_rate: number;
  actual_fuel_consumption_rate: number;
  percentage: number;
  standard_productivity: number;
  actual_productivity: number;
  productivity_percentage: number;
};

export type SummaryOfCostItem = {
  id: number;
  type: string;
  actual_previous_amount: number;
  actual_this_period_amount: number;
  actual_to_date_amount: number;
  description: string;
  remark: string;
  this_period_plan: number;
};

export type MonthlyContractorFacilityCost = {
  contractor_camp_facilities: ContractorCampFacility[];
  contractor_kitchen_tool_equipments: ContractorKitchenToolEquipment[];
  contractor_mobile_card_allowances: ContractorMobileCardAllowance[];
  contractor_office_furnitures: ContractorOfficeFurniture[];
  contractor_office_supplies_facilities: ContractorOfficeSuppliesFacility[];
  contractor_other_facilities: ContractorOtherFacility[];
  contractor_temporary_office_rents: ContractorTemporaryOfficeRent[];
};

export type MonthlyConsultantFacilityCost = {
  consultant_accomodations: ConsultantAccomodation[];
  consultant_camp_facilities: ConsultantCampFacility[];
  consultant_kitchen_tool_equipments: ConsultantKicthenToolEquipment[];
  consultant_office_furnitures: ConsultantOfficeFurniture[];
  consultant_office_supplies_facilitys: ConsultantOfficeSuppliesFacility[];
  consultant_other_facility_for_engineers: ConsultantOtherFacilityForEngineer[];
  consultant_temporary_office_rents: ConsultantTemporaryOfficeRent[];
};

export type TotalProjectExpenseType = {
  description: string;
  budget_plan_amount: number;
  previous_total: number;
  this_period: number;
  to_date: number;
};

export const MonthlySiteReportActionTypes = {
  FETCH_SURVEYING_EQUIPMENT_COST_REPORT:
    "FETCH_SURVEYING_EQUIPMENT_COST_REPORT",
  FETCH_SURVEYING_EQUIPMENT_COST_REPORT_RESET:
    "FETCH_SURVEYING_EQUIPMENT_COST_REPORT_RESET",
  FETCH_SURVEYING_EQUIPMENT_COST_REPORT_FAILURE:
    "FETCH_SURVEYING_EQUIPMENT_COST_REPORT_FAILURE",
  FETCH_SURVEYING_EQUIPMENT_COST_REPORT_SUCCESS:
    "FETCH_SURVEYING_EQUIPMENT_COST_REPORT_SUCCESS",

  FETCH_LABORATORY_EQUIPMENT_COST_REPORT:
    "FETCH_LABORATORY_EQUIPMENT_COST_REPORT",
  FETCH_LABORATORY_EQUIPMENT_COST_REPORT_RESET:
    "FETCH_LABORATORY_EQUIPMENT_COST_REPORT_RESET",
  FETCH_LABORATORY_EQUIPMENT_COST_REPORT_FAILURE:
    "FETCH_LABORATORY_EQUIPMENT_COST_REPORT_FAILURE",
  FETCH_LABORATORY_EQUIPMENT_COST_REPORT_SUCCESS:
    "FETCH_LABORATORY_EQUIPMENT_COST_REPORT_SUCCESS",

  FETCH_CONSULTANT_FACILITY_COST_REPORT:
    "FETCH_CONSULTANT_FACILITY_COST_REPORT",
  FETCH_CONSULTANT_FACILITY_COST_REPORT_RESET:
    "FETCH_CONSULTANT_FACILITY_COST_REPORT_RESET",
  FETCH_CONSULTANT_FACILITY_COST_REPORT_FAILURE:
    "FETCH_CONSULTANT_FACILITY_COST_REPORT_FAILURE",
  FETCH_CONSULTANT_FACILITY_COST_REPORT_SUCCESS:
    "FETCH_CONSULTANT_FACILITY_COST_REPORT_SUCCESS",

  FETCH_CONTRACTOR_FACILITY_COST_REPORT:
    "FETCH_CONTRACTOR_FACILITY_COST_REPORT",
  FETCH_CONTRACTOR_FACILITY_COST_REPORT_RESET:
    "FETCH_CONTRACTOR_FACILITY_COST_REPORT_RESET",
  FETCH_CONTRACTOR_FACILITY_COST_REPORT_FAILURE:
    "FETCH_CONTRACTOR_FACILITY_COST_REPORT_FAILURE",
  FETCH_CONTRACTOR_FACILITY_COST_REPORT_SUCCESS:
    "FETCH_CONTRACTOR_FACILITY_COST_REPORT_SUCCESS",

  FETCH_EQUIPMENT_COST_REPORT: "FETCH_EQUIPMENT_COST_REPORT",
  FETCH_EQUIPMENT_COST_REPORT_RESET: "FETCH_EQUIPMENT_COST_REPORT_RESET",
  FETCH_EQUIPMENT_COST_REPORT_FAILURE: "FETCH_EQUIPMENT_COST_REPORT_FAILURE",
  FETCH_EQUIPMENT_COST_REPORT_SUCCESS: "FETCH_EQUIPMENT_COST_REPORT_SUCCESS",

  FETCH_OTHER_EXPENSE_REPORT: "FETCH_OTHER_EXPENSE_REPORT",
  FETCH_OTHER_EXPENSE_REPORT_RESET: "FETCH_OTHER_EXPENSE_REPORT_RESET",
  FETCH_OTHER_EXPENSE_REPORT_FAILURE: "FETCH_OTHER_EXPENSE_REPORT_FAILURE",
  FETCH_OTHER_EXPENSE_REPORT_SUCCESS: "FETCH_OTHER_EXPENSE_REPORT_SUCCESS",

  FETCH_SUMMARY_OF_COST: "FETCH_SUMMARY_OF_COST",
  FETCH_SUMMARY_OF_COST_RESET: "FETCH_SUMMARY_OF_COST_RESET",
  FETCH_SUMMARY_OF_COST_FAILURE: "FETCH_SUMMARY_OF_COST_FAILURE",
  FETCH_SUMMARY_OF_COST_SUCCESS: "FETCH_SUMMARY_OF_COST_SUCCESS",

  FETCH_DETAIL_REPORT_OF_ACTIVITY: "FETCH_DETAIL_REPORT_OF_ACTIVITY",
  FETCH_DETAIL_REPORT_OF_ACTIVITY_RESET:
    "FETCH_DETAIL_REPORT_OF_ACTIVITY_RESET",
  FETCH_DETAIL_REPORT_OF_ACTIVITY_FAILURE:
    "FETCH_DETAIL_REPORT_OF_ACTIVITY_FAILURE",
  FETCH_DETAIL_REPORT_OF_ACTIVITY_SUCCESS:
    "FETCH_DETAIL_REPORT_OF_ACTIVITY_SUCCESS",

  FETCH_TOTAL_PROJECT_EXPENSE_REPORT: "FETCH_TOTAL_PROJECT_EXPENSE_REPORT",
  FETCH_TOTAL_PROJECT_EXPENSE_REPORT_RESET:
    "FETCH_TOTAL_PROJECT_EXPENSE_REPORT_RESET",
  FETCH_TOTAL_PROJECT_EXPENSE_REPORT_FAILURE:
    "FETCH_TOTAL_PROJECT_EXPENSE_REPORT_FAILURE",
  FETCH_TOTAL_PROJECT_EXPENSE_REPORT_SUCCESS:
    "FETCH_TOTAL_PROJECT_EXPENSE_REPORT_SUCCESS",

  FETCH_MONTHLY_INCOME_EXPENES_REPORT: "FETCH_MONTHLY_INCOME_EXPENES_REPORT",
  FETCH_MONTHLY_INCOME_EXPENES_REPORT_RESET:
    "FETCH_MONTHLY_INCOME_EXPENES_REPORT_RESET",
  FETCH_MONTHLY_INCOME_EXPENES_REPORT_FAILURE:
    "FETCH_MONTHLY_INCOME_EXPENES_REPORT_FAILURE",
  FETCH_MONTHLY_INCOME_EXPENES_REPORT_SUCCESS:
    "FETCH_MONTHLY_INCOME_EXPENES_REPORT_SUCCESS",

  FETCH_TOTAL_PROJECT_INCOME_REPORT: "FETCH_TOTAL_PROJECT_INCOME_REPORT",
  FETCH_TOTAL_PROJECT_INCOME_REPORT_RESET:
    "FETCH_TOTAL_PROJECT_INCOME_REPORT_RESET",
  FETCH_TOTAL_PROJECT_INCOME_REPORT_FAILURE:
    "FETCH_TOTAL_PROJECT_INCOME_REPORT_FAILURE",
  FETCH_TOTAL_PROJECT_INCOME_REPORT_SUCCESS:
    "FETCH_TOTAL_PROJECT_INCOME_REPORT_SUCCESS",

  FETCH_PROGRESS_BILL_SUMMARY: "FETCH_PROGRESS_BILL_SUMMARY",
  FETCH_PROGRESS_BILL_SUMMARY_RESET: "FETCH_PROGRESS_BILL_SUMMARY_RESET",
  FETCH_PROGRESS_BILL_SUMMARY_FAILURE: "FETCH_PROGRESS_BILL_SUMMARY_FAILURE",
  FETCH_PROGRESS_BILL_SUMMARY_SUCCESS: "FETCH_PROGRESS_BILL_SUMMARY_SUCCESS",

  FETCH_CRITICAL_ISSUE_REPORT: "FETCH_CRITICAL_ISSUE_REPORT",
  FETCH_CRITICAL_ISSUE_REPORT_RESET: "FETCH_CRITICAL_ISSUE_REPORT_RESET",
  FETCH_CRITICAL_ISSUE_REPORT_FAILURE: "FETCH_CRITICAL_ISSUE_REPORT_FAILURE",
  FETCH_CRITICAL_ISSUE_REPORT_SUCCESS: "FETCH_CRITICAL_ISSUE_REPORT_SUCCESS",
};
