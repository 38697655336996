import { Department } from "../Department/Department.type";
import { HRAllowance } from "../HRAllowance/HRAllowance.type";
import { PlaceOfWork } from "../PlaceOfWork/PlaceOfWork.type";
import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type Payroll = {
  id: number;
  date: string;
  departments: Department[];
  place_of_works:PlaceOfWork[];
  payroll_items: PayrollItem[];
  createdAt: Date;
};

export type PayrollItem = {
  id: number;
  staff_id: number;
  job_title: string;
  work_location:string;
  working_days: number;
  basic_salary: number;
  overtime: number;
  gross_salary: number;
  taxable_income: number;
  income_tax: number;
  pension7: number;
  pension11: number;
  absent: number;
  penality: number;
  loan: number;
  social_commitment: number;
  canteen_deduction: number;
  total_deduction: number;
  net_pay: number;
  payroll_allowances: PayrollAllowance[];
  staff: Staff;
};

export type CalculatePayroll = {
  staff_id: number;
  department_id: number;
  name: string;
  job_title: string;
  work_location:string;
  account: string;
  basic_salary: number;
  overtime: number;
  working_days: number;
  penality: number;
  loan: number;
  social_commitment: number;
  canteen_deduction: number;
  total_taxable_allowance: number;
  gross_salary: number;
  pension7: number;
  pension11: number;
  taxable_income: number;
  total_deduction: number;
  net_pay: number;
};

export type PayrollAllowance = {
  id: number;
  allowance_id: string;
  amount: number;
  hr_allowance: HRAllowance;
};

export type PayrollReport = {
  name: string;
  terms_of_employment: string;
  basic_salary: number;
  total_allowance: number;
  overtime: number;
  gross_salary: number;
  taxable_income: number;
  income_tax: number;
  pension7: number;
  pension11: number;
  penality: number;
  social_commitment: number;
  loan: number;
  canteen_deduction: number;
  total_deduction: number;
  net_pay: number;
};

export type PayrollByDate = {
  department: string,
  payroll: PayrollItem[]
}

export type StaffPayrollReport = {
  name: string;
  bank_account: string;
  net_pay: number;
};

export type PayrollStateTypes = {
  fetchAll: ApiCallState<Payroll[]>;
  calculate: ApiCallState<CalculatePayroll[]>;
  report: ApiCallState<PayrollReport[]>;
  staff_report: ApiCallState<StaffPayrollReport[]>;
  fetchByDate: ApiCallState<PayrollByDate[]>
};

export const PayrollActionTypes = {
  FETCH_ALL_PAYROLL: "FETCH_ALL_PAYROLL",
  FETCH_ALL_PAYROLL_RESET: "FETCH_ALL_PAYROLL_RESET",
  FETCH_ALL_PAYROLL_FAILURE: "FETCH_ALL_PAYROLL_FAILURE",
  FETCH_ALL_PAYROLL_SUCCESS: "FETCH_ALL_PAYROLL_SUCCESS",

  FETCH_BY_DATE_PAYROLL: "FETCH_BY_DATE_PAYROLL",
  FETCH_BY_DATE_PAYROLL_RESET: "FETCH_BY_DATE_PAYROLL_RESET",
  FETCH_BY_DATE_PAYROLL_FAILURE: "FETCH_BY_DATE_PAYROLL_FAILURE",
  FETCH_BY_DATE_PAYROLL_SUCCESS: "FETCH_BY_DATE_PAYROLL_SUCCESS",

  CALCULATE_PAYROLL: "CALCULATE_PAYROLL",
  CALCULATE_PAYROLL_RESET: "CALCULATE_PAYROLL_RESET",
  CALCULATE_PAYROLL_FAILURE: "CALCULATE_PAYROLL_FAILURE",
  CALCULATE_PAYROLL_SUCCESS: "CALCULATE_PAYROLL_SUCCESS",

  PAYROLL_REPORT: "PAYROLL_REPORT",
  PAYROLL_REPORT_RESET: "PAYROLL_REPORT_RESET",
  PAYROLL_REPORT_FAILURE: "PAYROLL_REPORT_FAILURE",
  PAYROLL_REPORT_SUCCESS: "PAYROLL_REPORT_SUCCESS",

  STAFF_PAYROLL_REPORT: "STAFF_PAYROLL_REPORT",
  STAFF_PAYROLL_REPORT_RESET: "STAFF_PAYROLL_REPORT_RESET",
  STAFF_PAYROLL_REPORT_FAILURE: "STAFF_PAYROLL_REPORT_FAILURE",
  STAFF_PAYROLL_REPORT_SUCCESS: "STAFF_PAYROLL_REPORT_SUCCESS",
};
