import { ApiCallState } from "../Utils";
import { User } from "../User/User.type";
import { StoreRequisitionFollowUp } from "../StoreRequisitionFollowUp/StoreRequisitionFollowUp.type";
import { Department } from "../Department/Department.type";
import { MasterList } from "../MasterList/MasterList.type";

export type StoreRequisition = {
  id: number;
  user_id: number;
  master_list_id: number;
  department_id: number;

  request_number: string;

  requisition_reason: string;
  previous_pm_reading: string;
  current_pm_reading: string;
  pr_number: string;

  date: string;

  requested_by_id: number;

  checked_by_id: number;
  is_checked: boolean;
  checked_date: string;

  verified_by_id: number;
  is_verified: boolean;
  verified_date: string;

  approved_by_id: number;
  is_approved: boolean;
  approved_date: string;

  user: User;

  department: Department;
  requested_by: User;
  checked_by: User;
  verified_by: User;
  approved_by: User;
  master_list: MasterList;

  store_requisition_follow_ups: StoreRequisitionFollowUp[];
};

export type StoreRequisitionStateTypes = {
  fetchAll: ApiCallState<StoreRequisition[]>;
  fetchOne: ApiCallState<StoreRequisition | {}>;
};

export const StoreRequisitionActionTypes = {
  FETCH_ALL_STORE_REQUISITION: "FETCH_ALL_STORE_REQUISITION",
  FETCH_ALL_STORE_REQUISITION_RESET: "FETCH_ALL_STORE_REQUISITION_RESET",
  FETCH_ALL_STORE_REQUISITION_FAILURE: "FETCH_ALL_STORE_REQUISITION_FAILURE",
  FETCH_ALL_STORE_REQUISITION_SUCCESS: "FETCH_ALL_STORE_REQUISITION_SUCCESS",

  FETCH_ONE_STORE_REQUISITION: "FETCH_ONE_STORE_REQUISITION",
  FETCH_ONE_STORE_REQUISITION_RESET: "FETCH_ONE_STORE_REQUISITION_RESET",
  FETCH_ONE_STORE_REQUISITION_FAILURE: "FETCH_ONE_STORE_REQUISITION_FAILURE",
  FETCH_ONE_STORE_REQUISITION_SUCCESS: "FETCH_ONE_STORE_REQUISITION_SUCCESS",
};
