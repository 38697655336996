import { ApiCallState } from "../Utils";

export type SupplementaryBoq= {
    id: number;
    project_id:number;
    date:string;
    description:string;
    total_amount:number;
    supplementary_boq_items:SupplementaryBoqItem[];
};

export type SupplementaryBoqItem={
    id: number;
    supplementary_boq_id: number;
    description: string;
    item_no: string;
    unit: string;
    quantity: number;
    unit_price: number;
    sheet_name: string;
}

export type SupplementaryBoqStateTypes = {
  fetchAll: ApiCallState<SupplementaryBoq[]>;
  fetchOne: ApiCallState<SupplementaryBoq | {}>;
};

export const SupplementaryBoqActionTypes = {
  FETCH_ALL_SUPPLEMENTARY_BOQ: "FETCH_ALL_SUPPLEMENTARY_BOQ",
  FETCH_ALL_SUPPLEMENTARY_BOQ_RESET: "FETCH_ALL_SUPPLEMENTARY_BOQ_RESET",
  FETCH_ALL_SUPPLEMENTARY_BOQ_FAILURE: "FETCH_ALL_SUPPLEMENTARY_BOQ_FAILURE",
  FETCH_ALL_SUPPLEMENTARY_BOQ_SUCCESS: "FETCH_ALL_SUPPLEMENTARY_BOQ_SUCCESS",

  FETCH_ONE_SUPPLEMENTARY_BOQ: "FETCH_ONE_SUPPLEMENTARY_BOQ",
  FETCH_ONE_SUPPLEMENTARY_BOQ_RESET: "FETCH_ONE_SUPPLEMENTARY_BOQ_RESET",
  FETCH_ONE_SUPPLEMENTARY_BOQ_FAILURE: "FETCH_ONE_SUPPLEMENTARY_BOQ_FAILURE",
  FETCH_ONE_SUPPLEMENTARY_BOQ_SUCCESS: "FETCH_ONE_SUPPLEMENTARY_BOQ_SUCCESS",
};
