import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ContractorFacilityCostActionTypes } from "./ContractorFacilityCost.type";

export function* fetchAllContractorFacilityCosts(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/contractor-facility-cost?project_id=${action.payload?.project_id}`);
    yield put({
      type: ContractorFacilityCostActionTypes.FETCH_ALL_CONTRACTOR_FACILITY_COST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ContractorFacilityCostActionTypes.FETCH_ALL_CONTRACTOR_FACILITY_COST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneContractorFacilityCosts(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/contractor-facility-cost/${action.payload}`
    );
    yield put({
      type: ContractorFacilityCostActionTypes.FETCH_ONE_CONTRACTOR_FACILITY_COST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ContractorFacilityCostActionTypes.FETCH_ONE_CONTRACTOR_FACILITY_COST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllContractorFacilityCosts() {
  yield takeLatest(ContractorFacilityCostActionTypes.FETCH_ALL_CONTRACTOR_FACILITY_COST, fetchAllContractorFacilityCosts);
}

export function* watcherFetchOneContractorFacilityCosts() {
  yield takeLatest(ContractorFacilityCostActionTypes.FETCH_ONE_CONTRACTOR_FACILITY_COST, fetchOneContractorFacilityCosts);
}
