import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { AccidentFollowUpActionTypes } from "./AccidentFollowUp.type";

export function* fetchAllAccidentFollowUps(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/accident-follow-up`);
    yield put({
      type: AccidentFollowUpActionTypes.FETCH_ALL_ACCIDENT_FOLLOW_UP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AccidentFollowUpActionTypes.FETCH_ALL_ACCIDENT_FOLLOW_UP_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneAccidentFollowUps(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/accident-follow-up/${action.payload}`
    );
    yield put({
      type: AccidentFollowUpActionTypes.FETCH_ONE_ACCIDENT_FOLLOW_UP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AccidentFollowUpActionTypes.FETCH_ONE_ACCIDENT_FOLLOW_UP_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllAccidentFollowUps() {
  yield takeLatest(
    AccidentFollowUpActionTypes.FETCH_ALL_ACCIDENT_FOLLOW_UP,
    fetchAllAccidentFollowUps
  );
}

export function* watcherFetchOneAccidentFollowUps() {
  yield takeLatest(
    AccidentFollowUpActionTypes.FETCH_ONE_ACCIDENT_FOLLOW_UP,
    fetchOneAccidentFollowUps
  );
}
