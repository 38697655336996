import { ApiCallState } from "../Utils";

export type AttendanceLocation = {
  id: number;

  location: string;
  radius: number;

  longitude: number;
  latitude: number;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type AttendanceLocationStateTypes = {
  fetchAll: ApiCallState<AttendanceLocation[]>;
  fetchOne: ApiCallState<AttendanceLocation | {}>;
};

export const AttendanceLocationActionTypes = {
  FETCH_ALL_ATTENDANCE_LOCATION: "FETCH_ALL_ATTENDANCE_LOCATION",
  FETCH_ALL_ATTENDANCE_LOCATION_RESET: "FETCH_ALL_ATTENDANCE_LOCATION_RESET",
  FETCH_ALL_ATTENDANCE_LOCATION_FAILURE:
    "FETCH_ALL_ATTENDANCE_LOCATION_FAILURE",
  FETCH_ALL_ATTENDANCE_LOCATION_SUCCESS:
    "FETCH_ALL_ATTENDANCE_LOCATION_SUCCESS",

  FETCH_ONE_ATTENDANCE_LOCATION: "FETCH_ONE_ATTENDANCE_LOCATION",
  FETCH_ONE_ATTENDANCE_LOCATION_RESET: "FETCH_ONE_ATTENDANCE_LOCATION_RESET",
  FETCH_ONE_ATTENDANCE_LOCATION_FAILURE:
    "FETCH_ONE_ATTENDANCE_LOCATION_FAILURE",
  FETCH_ONE_ATTENDANCE_LOCATION_SUCCESS:
    "FETCH_ONE_ATTENDANCE_LOCATION_SUCCESS",
};
