import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MaterialDeliveryActionTypes } from "./MaterialDelivery.type";

export function* fetchAllMaterialDeliveries(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/material-delivery-item?${query}`
    );
    yield put({
      type: MaterialDeliveryActionTypes.FETCH_ALL_MATERIAL_DELIVERY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialDeliveryActionTypes.FETCH_ALL_MATERIAL_DELIVERY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchMaterialDeliveries(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/material-delivery-material?${query}`
    );
    yield put({
      type: MaterialDeliveryActionTypes.FETCH_MATERIAL_DELIVERY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialDeliveryActionTypes.FETCH_MATERIAL_DELIVERY_FAILURE,
      payload: error,
    });
  }
}


export function* watcherFetchAllMaterialDeliveries() {
  yield takeLatest(
    MaterialDeliveryActionTypes.FETCH_ALL_MATERIAL_DELIVERY,
    fetchAllMaterialDeliveries
  );
}

export function* watcherFetchMaterialDeliveries() {
  yield takeLatest(
    MaterialDeliveryActionTypes.FETCH_MATERIAL_DELIVERY,
    fetchMaterialDeliveries
  );
}
