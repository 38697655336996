import { ApiCallState } from "../Utils";

export type BriefingForm= {
  id?: number;
  date: string;
  project_id: number;
  user_id: number;
  briefing_form_items?: BriefingFormItem[],
  briefing_form_statuses?: { id: number; type: "View" | "Check" | "Approve"; status: number; assigned_by: number; user_id: number; }[]
};

export type BriefingFormItem = {
    id?: number,
    description: string,
    current_status: string,
    key_considerations: string,
    responsible_person: string,
    progress_narrative: string,
    progress_percentage: number,
    recommendations: string,
    briefing_form_id?: number
}

export type BriefingFormStatus = {
  id: number,
  user_id: number,
  briefing_form_item_id?: number,
  status: number,
  type: string,
  assigned_by: string,
}

export type BriefingFormStateTypes = {
  fetchAll: ApiCallState<BriefingForm[]>;
  fetchOne: ApiCallState<BriefingForm | {}>;
};

export const BriefingFormActionTypes = {
  FETCH_ALL_BRIEFING_FORM: "FETCH_ALL_BRIEFING_FORM",
  FETCH_ALL_BRIEFING_FORM_RESET: "FETCH_ALL_BRIEFING_FORM_RESET",
  FETCH_ALL_BRIEFING_FORM_FAILURE: "FETCH_ALL_BRIEFING_FORM_FAILURE",
  FETCH_ALL_BRIEFING_FORM_SUCCESS: "FETCH_ALL_BRIEFING_FORM_SUCCESS",

  FETCH_ONE_BRIEFING_FORM: "FETCH_ONE_BRIEFING_FORM",
  FETCH_ONE_BRIEFING_FORM_RESET: "FETCH_ONE_BRIEFING_FORM_RESET",
  FETCH_ONE_BRIEFING_FORM_FAILURE: "FETCH_ONE_BRIEFING_FORM_FAILURE",
  FETCH_ONE_BRIEFING_FORM_SUCCESS: "FETCH_ONE_BRIEFING_FORM_SUCCESS",
};
