import { BankAccount } from "../BankAccount/BankAccount.type";
import { ApiCallState } from "../Utils";

export type CheckPaymentVoucher = {
  id: number;
  date: string;
  voucher_number: number;
  bank_name: string;
  bank_account_id: number;
  branch: string;
  cheque_number: number;
  project: string;
  pay_to: string;
  amount: number;
  purpose: string;
  is_void: boolean;
  user_id: number;
  bank_account: BankAccount;
  check_payment_voucher_items: CheckPaymentVoucherItem[];
  check_payment_voucher_statuses: CheckPaymentVoucherStatus[];
  check_type?: string;
};

export type CheckPaymentVoucherItem = {
  id: number;
  check_payment_voucher_id: number;
  account_no: string;
  account_description: string;
  debit: number;
  credit: number;
};

export type CheckPaymentVoucherStatus = {
  check_payment_voucher_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type CheckPaymentVoucherStateTypes = {
  fetchAll: ApiCallState<CheckPaymentVoucher[]>;
  fetchOne: ApiCallState<CheckPaymentVoucher | {}>;
};

export const CheckPaymentVoucherActionTypes = {
  FETCH_ALL_CHECK_PAYMENT_VOUCHER: "FETCH_ALL_CHECK_PAYMENT_VOUCHER",
  FETCH_ALL_CHECK_PAYMENT_VOUCHER_RESET:
    "FETCH_ALL_CHECK_PAYMENT_VOUCHER_RESET",
  FETCH_ALL_CHECK_PAYMENT_VOUCHER_FAILURE:
    "FETCH_ALL_CHECK_PAYMENT_VOUCHER_FAILURE",
  FETCH_ALL_CHECK_PAYMENT_VOUCHER_SUCCESS:
    "FETCH_ALL_CHECK_PAYMENT_VOUCHER_SUCCESS",

  FETCH_ONE_CHECK_PAYMENT_VOUCHER: "FETCH_ONE_CHECK_PAYMENT_VOUCHER",
  FETCH_ONE_CHECK_PAYMENT_VOUCHER_RESET:
    "FETCH_ONE_CHECK_PAYMENT_VOUCHER_RESET",
  FETCH_ONE_CHECK_PAYMENT_VOUCHER_FAILURE:
    "FETCH_ONE_CHECK_PAYMENT_VOUCHER_FAILURE",
  FETCH_ONE_CHECK_PAYMENT_VOUCHER_SUCCESS:
    "FETCH_ONE_CHECK_PAYMENT_VOUCHER_SUCCESS",
};
