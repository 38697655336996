import {
  MaterialInspectionRepairStateTypes,
  MaterialInspectionRepairActionTypes,
} from "./MaterialInspectionRepair.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: MaterialInspectionRepairStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchNumber: resetApiCallState(null),
};

const MaterialInspectionRepairReducer = (
  state: MaterialInspectionRepairStateTypes = INITIAL_STATE,
  action: any
): MaterialInspectionRepairStateTypes => {
  switch (action.type) {
    case MaterialInspectionRepairActionTypes.FETCH_ALL_MATERIAL_INSPECTION_REPAIR:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaterialInspectionRepairActionTypes.FETCH_ALL_MATERIAL_INSPECTION_REPAIR_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MaterialInspectionRepairActionTypes.FETCH_ALL_MATERIAL_INSPECTION_REPAIR_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaterialInspectionRepairActionTypes.FETCH_ALL_MATERIAL_INSPECTION_REPAIR_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MaterialInspectionRepairActionTypes.FETCH_ONE_MATERIAL_INSPECTION_REPAIR:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaterialInspectionRepairActionTypes.FETCH_ONE_MATERIAL_INSPECTION_REPAIR_RESET:
      return {
        ...state,
        fetchOne: resetApiCallState({}),
      };
    case MaterialInspectionRepairActionTypes.FETCH_ONE_MATERIAL_INSPECTION_REPAIR_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaterialInspectionRepairActionTypes.FETCH_ONE_MATERIAL_INSPECTION_REPAIR_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    // ---------------------------

    case MaterialInspectionRepairActionTypes.FETCH_MATERIAL_INSPECTION_REPAIR_NUMBER:
      return {
        ...state,
        fetchNumber: {
          error: null,
          payload: null,
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaterialInspectionRepairActionTypes.FETCH_MATERIAL_INSPECTION_REPAIR_NUMBER_RESET:
      return {
        ...state,
        fetchNumber: resetApiCallState(null),
      };
    case MaterialInspectionRepairActionTypes.FETCH_MATERIAL_INSPECTION_REPAIR_NUMBER_FAILURE:
      return {
        ...state,
        fetchNumber: {
          payload: null,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaterialInspectionRepairActionTypes.FETCH_MATERIAL_INSPECTION_REPAIR_NUMBER_SUCCESS:
      return {
        ...state,
        fetchNumber: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    // ---------------------------

    default:
      return state;
  }
};

export default MaterialInspectionRepairReducer;
