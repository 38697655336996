import { ManningCategoryStateTypes, ManningCategoryActionTypes } from "./ManningCategory.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ManningCategoryStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const ManningCategoryReducer = (
  state: ManningCategoryStateTypes = INITIAL_STATE,
  action: any
): ManningCategoryStateTypes => {
  switch (action.type) {
    case ManningCategoryActionTypes.FETCH_ALL_MANNING_CATEGORY:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ManningCategoryActionTypes.FETCH_ALL_MANNING_CATEGORY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ManningCategoryActionTypes.FETCH_ALL_MANNING_CATEGORY_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ManningCategoryActionTypes.FETCH_ALL_MANNING_CATEGORY_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ManningCategoryActionTypes.FETCH_ONE_MANNING_CATEGORY:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ManningCategoryActionTypes.FETCH_ONE_MANNING_CATEGORY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ManningCategoryActionTypes.FETCH_ONE_MANNING_CATEGORY_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ManningCategoryActionTypes.FETCH_ONE_MANNING_CATEGORY_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ManningCategoryReducer;
