import { OwnVehicle } from "../OwnVehicle/OwnVehicle.type";
import { Project } from "../Project/Project.type";
import { ApiCallState, PagedData } from "../Utils";

export type MaintenanceCost = {
  id?: number;
  key: number;
  project_id: any;
  own_vehicle_id: any;
  date: string;
  km_reading: number;
  part_changed: string;
  part_no: string;
  quantity: number;
  unit_price: number;
  man_power_cost: number;
  fs_no: string;
  grn_no: string;
  ro_no: string;
  remark: string;
  flag?: string;
  own_vehicle?: OwnVehicle;
  project?: Project;
  issue_number: string;
};

export type MaintenanceCostList = {
  own_vehicle_id: number;
  own_vehicle: OwnVehicle;
};

export type MaintenanceCostStateTypes = {
  fetchAll: ApiCallState<MaintenanceCost[]>;
  fetchOne: ApiCallState<MaintenanceCost | {}>;
  fetchPaged: ApiCallState<PagedData<MaintenanceCost[]>>;
  fetchList: ApiCallState<MaintenanceCostList[]>;
};

export const MaintenanceCostActionTypes = {
  FETCH_ALL_MAINTENANCE_COST: "FETCH_ALL_MAINTENANCE_COST",
  FETCH_ALL_MAINTENANCE_COST_RESET: "FETCH_ALL_MAINTENANCE_COST_RESET",
  FETCH_ALL_MAINTENANCE_COST_FAILURE: "FETCH_ALL_MAINTENANCE_COST_FAILURE",
  FETCH_ALL_MAINTENANCE_COST_SUCCESS: "FETCH_ALL_MAINTENANCE_COST_SUCCESS",

  FETCH_PAGED_MAINTENANCE_COST: "FETCH_PAGED_MAINTENANCE_COST",
  FETCH_PAGED_MAINTENANCE_COST_RESET: "FETCH_PAGED_MAINTENANCE_COST_RESET",
  FETCH_PAGED_MAINTENANCE_COST_FAILURE: "FETCH_PAGED_MAINTENANCE_COST_FAILURE",
  FETCH_PAGED_MAINTENANCE_COST_SUCCESS: "FETCH_PAGED_MAINTENANCE_COST_SUCCESS",

  FETCH_ONE_MAINTENANCE_COST: "FETCH_ONE_MAINTENANCE_COST",
  FETCH_ONE_MAINTENANCE_COST_RESET: "FETCH_ONE_MAINTENANCE_COST_RESET",
  FETCH_ONE_MAINTENANCE_COST_FAILURE: "FETCH_ONE_MAINTENANCE_COST_FAILURE",
  FETCH_ONE_MAINTENANCE_COST_SUCCESS: "FETCH_ONE_MAINTENANCE_COST_SUCCESS",

  FETCH_LIST_MAINTENANCE_COST: "FETCH_LIST_MAINTENANCE_COST",
  FETCH_LIST_MAINTENANCE_COST_RESET: "FETCH_LIST_MAINTENANCE_COST_RESET",
  FETCH_LIST_MAINTENANCE_COST_FAILURE: "FETCH_LIST_MAINTENANCE_COST_FAILURE",
  FETCH_LIST_MAINTENANCE_COST_SUCCESS: "FETCH_LIST_MAINTENANCE_COST_SUCCESS",
};
