import { OtherExpenseStateTypes, OtherExpenseActionTypes } from "./OtherExpense.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: OtherExpenseStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const OtherExpenseReducer = (
  state: OtherExpenseStateTypes = INITIAL_STATE,
  action: any
): OtherExpenseStateTypes => {
  switch (action.type) {
    case OtherExpenseActionTypes.FETCH_ALL_OTHER_EXPENSE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case OtherExpenseActionTypes.FETCH_ALL_OTHER_EXPENSE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case OtherExpenseActionTypes.FETCH_ALL_OTHER_EXPENSE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case OtherExpenseActionTypes.FETCH_ALL_OTHER_EXPENSE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case OtherExpenseActionTypes.FETCH_ONE_OTHER_EXPENSE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case OtherExpenseActionTypes.FETCH_ONE_OTHER_EXPENSE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case OtherExpenseActionTypes.FETCH_ONE_OTHER_EXPENSE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case OtherExpenseActionTypes.FETCH_ONE_OTHER_EXPENSE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default OtherExpenseReducer;
