import { OwnVehicle } from "./../OwnVehicle/OwnVehicle.type";
import { ApiCallState, PagedData } from "../Utils";
import { User } from "../User/User.type";

export type OperationDailyReport = {
  id?: number;
  key: number;
  project_id: number;
  date: string;
  fuel_rate: number;
  own_vehicle_id: any;
  own_vehicle?: OwnVehicle;
  start: number;
  end: number;
  total_idle: number;
  total_work: number;
  total_work_hour: number;
  total_breakdown: number;
  fuel_consumed: number;
  fuel_out: number;
  fuel_balance: number;
  executed: number;
  start_work_station?: string;
  end_work_station?: string;
  l_dimension?: number;
  w_dimension?: number;
  d_dimension?: number;
  total_executed: number;
  remark: string;
  flag?: string;
  unit_price: number;
  user?: User;
};

export type OperationDailyReportStateTypes = {
  fetchAll: ApiCallState<OperationDailyReport[]>;
  fetchOne: ApiCallState<OperationDailyReport | {}>;
  fetchSelector: ApiCallState<OperationDailyReport[]>;
  fetchPaged: ApiCallState<PagedData<OperationDailyReport[]>>;
};

export const OperationDailyReportActionTypes = {
  FETCH_ALL_OPERATION_DAILY_REPORT: "FETCH_ALL_OPERATION_DAILY_REPORT",
  FETCH_ALL_OPERATION_DAILY_REPORT_RESET:
    "FETCH_ALL_OPERATION_DAILY_REPORT_RESET",
  FETCH_ALL_OPERATION_DAILY_REPORT_FAILURE:
    "FETCH_ALL_OPERATION_DAILY_REPORT_FAILURE",
  FETCH_ALL_OPERATION_DAILY_REPORT_SUCCESS:
    "FETCH_ALL_OPERATION_DAILY_REPORT_SUCCESS",

  FETCH_SELECTOR_OPERATION_DAILY_REPORT:
    "FETCH_SELECTOR_OPERATION_DAILY_REPORT",
  FETCH_SELECTOR_OPERATION_DAILY_REPORT_RESET:
    "FETCH_SELECTOR_OPERATION_DAILY_REPORT_RESET",
  FETCH_SELECTOR_OPERATION_DAILY_REPORT_FAILURE:
    "FETCH_SELECTOR_OPERATION_DAILY_REPORT_FAILURE",
  FETCH_SELECTOR_OPERATION_DAILY_REPORT_SUCCESS:
    "FETCH_SELECTOR_OPERATION_DAILY_REPORT_SUCCESS",

  FETCH_PAGED_OPERATION_DAILY_REPORT: "FETCH_PAGED_OPERATION_DAILY_REPORT",
  FETCH_PAGED_OPERATION_DAILY_REPORT_RESET:
    "FETCH_PAGED_OPERATION_DAILY_REPORT_RESET",
  FETCH_PAGED_OPERATION_DAILY_REPORT_FAILURE:
    "FETCH_PAGED_OPERATION_DAILY_REPORT_FAILURE",
  FETCH_PAGED_OPERATION_DAILY_REPORT_SUCCESS:
    "FETCH_PAGED_OPERATION_DAILY_REPORT_SUCCESS",

  FETCH_ONE_OPERATION_DAILY_REPORT: "FETCH_ONE_OPERATION_DAILY_REPORT",
  FETCH_ONE_OPERATION_DAILY_REPORT_RESET:
    "FETCH_ONE_OPERATION_DAILY_REPORT_RESET",
  FETCH_ONE_OPERATION_DAILY_REPORT_FAILURE:
    "FETCH_ONE_OPERATION_DAILY_REPORT_FAILURE",
  FETCH_ONE_OPERATION_DAILY_REPORT_SUCCESS:
    "FETCH_ONE_OPERATION_DAILY_REPORT_SUCCESS",
};
