import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { WorkEvaluationActionTypes } from "./WorkEvaluation.type";

export function* fetchAllWorkEvaluations(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/work-evaluation?project_id=${action.payload?.project_id}&date=${action.payload?.date}`);
    yield put({
      type: WorkEvaluationActionTypes.FETCH_ALL_WORK_EVALUATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WorkEvaluationActionTypes.FETCH_ALL_WORK_EVALUATION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneWorkEvaluations(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/work-evaluation/one?project_id=${action.payload?.project_id}&date=${action.payload?.date}`
    );
    yield put({
      type: WorkEvaluationActionTypes.FETCH_ONE_WORK_EVALUATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WorkEvaluationActionTypes.FETCH_ONE_WORK_EVALUATION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllWorkEvaluations() {
  yield takeLatest(WorkEvaluationActionTypes.FETCH_ALL_WORK_EVALUATION, fetchAllWorkEvaluations);
}

export function* watcherFetchOneWorkEvaluations() {
  yield takeLatest(WorkEvaluationActionTypes.FETCH_ONE_WORK_EVALUATION, fetchOneWorkEvaluations);
}
