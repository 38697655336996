import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { TakeoffActionTypes } from "./Takeoff.type";

export function* fetchAllTakeoffs(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/takeoff?project_id=${action.payload.project_id}&approval=${action.payload.approval}&type=${action.payload.type}`
    );
    yield put({
      type: TakeoffActionTypes.FETCH_ALL_TAKEOFF_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: TakeoffActionTypes.FETCH_ALL_TAKEOFF_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllTakeoffs() {
  yield takeLatest(TakeoffActionTypes.FETCH_ALL_TAKEOFF, fetchAllTakeoffs);
}
