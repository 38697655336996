import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { StaffPerformanceActionTypes } from "./StaffPerformance.type";

export function* fetchAllStaffPerformances(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/staff-performance?staff_id=${action.payload?.staff_id}`);
    yield put({
      type: StaffPerformanceActionTypes.FETCH_ALL_STAFF_PERFORMANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffPerformanceActionTypes.FETCH_ALL_STAFF_PERFORMANCE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneStaffPerformances(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/staff-performance/${action.payload}`
    );
    yield put({
      type: StaffPerformanceActionTypes.FETCH_ONE_STAFF_PERFORMANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffPerformanceActionTypes.FETCH_ONE_STAFF_PERFORMANCE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllStaffPerformances() {
  yield takeLatest(StaffPerformanceActionTypes.FETCH_ALL_STAFF_PERFORMANCE, fetchAllStaffPerformances);
}

export function* watcherFetchOneStaffPerformances() {
  yield takeLatest(StaffPerformanceActionTypes.FETCH_ONE_STAFF_PERFORMANCE, fetchOneStaffPerformances);
}
