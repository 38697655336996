import { ApiCallState, PagedData } from "../Utils";

export type OwnVehicle = {
  id?: number;
  key: number;
  description: string;
  type: string;
  agreement_number: string;
  agreement_date: string;
  year: string;
  project_id: number;
  model: string;
  fuel_type: string;
  plate_number: string;
  purchase_date: Date;
  chassis_number: number;
  engine_number: number;
  rental_price: number;
  rental_unit: string;
  assigned_operator: string;
  phone_number: string;
  standard_consumption_rate: string;
  purchase_amount: number;
  assigned_project: string;
  supplier_name: string;
  unit: string;
  status: string;
  category:string; 
  hr_power:number;
  capacity:number; 
  depreciation:number;
};

export type OwnVehicleStateTypes = {
  fetchAll: ApiCallState<OwnVehicle[]>;
  fetchAllProject: ApiCallState<OwnVehicle[]>;
  fetchOne: ApiCallState<OwnVehicle | {}>;
  fetchPaged: ApiCallState<PagedData<OwnVehicle[]>>;
};

export const OwnVehicleActionTypes = {
  FETCH_ALL_OWN_VEHICLE: "FETCH_ALL_OWN_VEHICLE",
  FETCH_ALL_OWN_VEHICLE_RESET: "FETCH_ALL_OWN_VEHICLE_RESET",
  FETCH_ALL_OWN_VEHICLE_FAILURE: "FETCH_ALL_OWN_VEHICLE_FAILURE",
  FETCH_ALL_OWN_VEHICLE_SUCCESS: "FETCH_ALL_OWN_VEHICLE_SUCCESS",

  FETCH_ALL_PROJECT_OWN_VEHICLE: "FETCH_ALL_PROJECT_OWN_VEHICLE",
  FETCH_ALL_PROJECT_OWN_VEHICLE_RESET: "FETCH_ALL_PROJECT_OWN_VEHICLE_RESET",
  FETCH_ALL_PROJECT_OWN_VEHICLE_FAILURE:
    "FETCH_ALL_PROJECT_OWN_VEHICLE_FAILURE",
  FETCH_ALL_PROJECT_OWN_VEHICLE_SUCCESS:
    "FETCH_ALL_PROJECT_OWN_VEHICLE_SUCCESS",

  FETCH_PAGED_OWN_VEHICLE: "FETCH_PAGED_OWN_VEHICLE",
  FETCH_PAGED_OWN_VEHICLE_RESET: "FETCH_PAGED_OWN_VEHICLE_RESET",
  FETCH_PAGED_OWN_VEHICLE_FAILURE: "FETCH_PAGED_OWN_VEHICLE_FAILURE",
  FETCH_PAGED_OWN_VEHICLE_SUCCESS: "FETCH_PAGED_OWN_VEHICLE_SUCCESS",

  FETCH_ONE_OWN_VEHICLE: "FETCH_ONE_OWN_VEHICLE",
  FETCH_ONE_OWN_VEHICLE_RESET: "FETCH_ONE_OWN_VEHICLE_RESET",
  FETCH_ONE_OWN_VEHICLE_FAILURE: "FETCH_ONE_OWN_VEHICLE_FAILURE",
  FETCH_ONE_OWN_VEHICLE_SUCCESS: "FETCH_ONE_OWN_VEHICLE_SUCCESS",
};
