import { ApiCallState } from "../Utils";

export type OilLubricantCost = {
  id: number;
  date: string;
  project_id: number;
  oil_lubricant_cost_items: OilLubricantCostItem[];
};

export type OilLubricantCostItem = {
  id: number;
  equipment: string;
  plate_no: string;
  owner: string;

  pq_15w40_engine_oil: number;
  pq_hydrolic_oil_10w: number;
  pq_46w: number;
  pq_transmission_oil: number;
  pq_gear_box_oil_90w: number;
  pq_differential_oil_140w: number;
  pq_grease: number;
  pq_brake_fluid: number;

  tpq_15w40_engine_oil: number;
  tpq_hydrolic_oil_10w: number;
  tpq_46w: number;
  tpq_transmission_oil: number;
  tpq_gear_box_oil_90w: number;
  tpq_differential_oil_140w: number;
  tpq_grease: number;
  tpq_brake_fluid: number;

  tdq_15w40_engine_oil: number;
  tdq_hydrolic_oil_10w: number;
  tdq_46w: number;
  tdq_transmission_oil: number;
  tdq_gear_box_oil_90w: number;
  tdq_differential_oil_140w: number;
  tdq_grease: number;
  tdq_brake_fluid: number;

  pa_15w40_engine_oil: number;
  pa_hydrolic_oil_10w: number;
  pa_46w: number;
  pa_transmission_oil: number;
  pa_gear_box_oil_90w: number;
  pa_differential_oil_140w: number;
  pa_grease: number;
  pa_brake_fluid: number;

  up_15w40_engine_oil: number;
  up_hydrolic_oil_10w: number;
  up_46w: number;
  up_transmission_oil: number;
  up_gear_box_oil_90w: number;
  up_differential_oil_140w: number;
  up_grease: number;
  up_brake_fluid: number;

  tpa_15w40_engine_oil: number;
  tpa_hydrolic_oil_10w: number;
  tpa_46w: number;
  tpa_transmission_oil: number;
  tpa_gear_box_oil_90w: number;
  tpa_differential_oil_140w: number;
  tpa_grease: number;
  tpa_brake_fluid: number;

  tda_15w40_engine_oil: number;
  tda_hydrolic_oil_10w: number;
  tda_46w: number;
  tda_transmission_oil: number;
  tda_gear_box_oil_90w: number;
  tda_differential_oil_140w: number;
  tda_grease: number;
  tda_brake_fluid: number;
};

export type OilLubricantCostStateTypes = {
  fetchAll: ApiCallState<OilLubricantCost[]>;
  fetchOne: ApiCallState<OilLubricantCost | {}>;
};

export const OilLubricantCostActionTypes = {
  FETCH_ALL_OIL_LUBRICANT_COST: "FETCH_ALL_OIL_LUBRICANT_COST",
  FETCH_ALL_OIL_LUBRICANT_COST_RESET: "FETCH_ALL_OIL_LUBRICANT_COST_RESET",
  FETCH_ALL_OIL_LUBRICANT_COST_FAILURE: "FETCH_ALL_OIL_LUBRICANT_COST_FAILURE",
  FETCH_ALL_OIL_LUBRICANT_COST_SUCCESS: "FETCH_ALL_OIL_LUBRICANT_COST_SUCCESS",

  FETCH_ONE_OIL_LUBRICANT_COST: "FETCH_ONE_OIL_LUBRICANT_COST",
  FETCH_ONE_OIL_LUBRICANT_COST_RESET: "FETCH_ONE_OIL_LUBRICANT_COST_RESET",
  FETCH_ONE_OIL_LUBRICANT_COST_FAILURE: "FETCH_ONE_OIL_LUBRICANT_COST_FAILURE",
  FETCH_ONE_OIL_LUBRICANT_COST_SUCCESS: "FETCH_ONE_OIL_LUBRICANT_COST_SUCCESS",
};
