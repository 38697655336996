import { InitPagedData } from "../Utils";
import { DispatchStateTypes, DispatchActionTypes } from "./Dispatch.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: DispatchStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchReport: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchPaged: resetApiCallState(InitPagedData),
};

const DispatchReducer = (
  state: DispatchStateTypes = INITIAL_STATE,
  action: any
): DispatchStateTypes => {
  switch (action.type) {
    case DispatchActionTypes.FETCH_ALL_DISPATCH:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case DispatchActionTypes.FETCH_ALL_DISPATCH_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case DispatchActionTypes.FETCH_ALL_DISPATCH_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case DispatchActionTypes.FETCH_ALL_DISPATCH_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };
    ///////////

    case DispatchActionTypes.FETCH_REPORT_DISPATCH:
      return {
        ...state,
        fetchReport: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case DispatchActionTypes.FETCH_REPORT_DISPATCH_RESET:
      return {
        ...state,
        fetchReport: resetApiCallState([]),
      };
    case DispatchActionTypes.FETCH_REPORT_DISPATCH_FAILURE:
      return {
        ...state,
        fetchReport: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case DispatchActionTypes.FETCH_REPORT_DISPATCH_SUCCESS:
      return {
        ...state,
        fetchReport: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    //////////
    case DispatchActionTypes.FETCH_PAGED_DISPATCH:
      return {
        ...state,
        fetchPaged: {
          error: null,
          payload: InitPagedData,
          isPending: true,
          isSuccessful: false,
        },
      };
    case DispatchActionTypes.FETCH_PAGED_DISPATCH_RESET:
      return {
        ...state,
        fetchPaged: resetApiCallState(InitPagedData),
      };
    case DispatchActionTypes.FETCH_PAGED_DISPATCH_FAILURE:
      return {
        ...state,
        fetchPaged: {
          payload: InitPagedData,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case DispatchActionTypes.FETCH_PAGED_DISPATCH_SUCCESS:
      return {
        ...state,
        fetchPaged: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case DispatchActionTypes.FETCH_ONE_DISPATCH:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case DispatchActionTypes.FETCH_ONE_DISPATCH_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case DispatchActionTypes.FETCH_ONE_DISPATCH_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case DispatchActionTypes.FETCH_ONE_DISPATCH_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default DispatchReducer;
