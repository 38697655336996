import axios from "axios";
import { API_BASE_URI } from "../../../../redux/ApiCall";
import { UserAccess, UserRole } from "../../../../redux/UserRole/UserRole.type";
import { ApiCallState } from "../../../../redux/Utils";
import { MaterialMajorCategory } from "../../../../redux/MaterialMajorCategory/MaterialMajorCategory.type";
import { Store } from "../../../../redux/Store/Store.type";

export type UserRolePropType = {
  user_roles: ApiCallState<UserRole[]>;
  fetchUserRoles: Function;
  fetchAllMaterialMajorCategory: Function;
  material_major_categories: ApiCallState<MaterialMajorCategory[]>;
};

export type AddUserRolePropType = {
  fetchUserRoles: Function;
  id?: number;
  fetchUserRole: Function;
  user_role: ApiCallState<UserRole>;
  fetchAllMaterialMajorCategory: Function;
  material_major_categories: ApiCallState<MaterialMajorCategory[]>;
  stores: ApiCallState<Store[]>;
  fetchAllStore: Function;
};

export type ViewUserRolePropType = {
  id: number;
  fetchUserRole: Function;
  user_role: ApiCallState<UserRole>;
  fetchAllMaterialMajorCategory: Function;
  material_major_categories: ApiCallState<MaterialMajorCategory[]>;
};

export const POST = (data: any) =>
  axios.post(`${API_BASE_URI}/user-role`, data);

export const DELETE = (id: number) =>
  axios.delete(`${API_BASE_URI}/user-role/${id}`);

export const PUT = (data: any) => axios.put(`${API_BASE_URI}/user-role`, data);

export const getRoles = (user_accesses: UserAccess[]) => {
  let data: UserAccess[] = [];

  USER_ACCESSES.forEach((e) => {
    let found = user_accesses?.find(
      (user_access) => user_access.feature === e.name
    );
    if (found) {
      data.push({
        ...found,
        feature: e.name,
        key: data.length,
      });
    } else {
      data.push({
        feature: e.name,
        key: data.length,
        delete: false,
        edit: false,
        read: false,
        write: false,
        approve: false,
        check: false,
        approve_special: [],
        entry_special: [],
      });
    }
  });

  return data;
};

export const USER_ACCESSES = [
  {
    name: "Project",
    url: "/project",
    group: "Project",
  },
  {
    name: "Dashboard",
    url: "/hr/dashboard",
    group: "Human Resource",
  },
  {
    name: "Department",
    url: "/hr/data-entry/department",
    group: "Human Resource",
  },
  {
    name: "Staff",
    url: "/hr/data-entry/staff",
    group: "Human Resource",
  },
  {
    name: "Benefits",
    url: "/hr/data-entry/benefits",
    group: "Human Resource",
  },
  {
    name: "Leave Status",
    url: "/hr/data-entry/leave-status",
    group: "Human Resource",
  },
  {
    name: "Payroll",
    url: "/hr/data-entry/payroll",
    group: "Human Resource",
  },
  {
    name: "HR Policy",
    url: "/hr/data-entry/hr-policy",
    group: "Human Resource",
  },
  {
    name: "Review",
    url: "/hr/data-entry/review",
    group: "Human Resource",
  },
  {
    name: "Training",
    url: "/hr/data-entry/training",
    group: "Human Resource",
  },
  {
    name: "Message",
    url: "/hr/data-entry/message",
    group: "Human Resource",
  },
  {
    name: "Request and Vacancy",
    url: "/hr/data-entry/request-and-vacancy",
    group: "Human Resource",
  },
  {
    name: "Document",
    url: "/hr/data-entry/document",
    group: "Human Resource",
  },
  {
    name: "Grade and Scale",
    url: "/hr/data-entry/grade-and-scale",
    group: "Human Resource",
  },
  {
    name: "Allowance",
    url: "/hr/data-entry/allowance",
    group: "Human Resource",
  },
  {
    name: "Work Location and Request",
    url: "/hr/data-entry/work-location-and-request",
    group: "Human Resource",
  },
  {
    name: "Reports",
    url: "/hr/reports",
    group: "Human Resource",
  },
  {
    name: "Sub Contract Followup",
    url: "/operation",
    group: "Operation",
  },
  {
    name: "Accident Follow UP",
    url: "/operation",
    group: "Operation",
  },
  {
    name: "Maintenance",
    url: "/operation",
    group: "Operation",
  },
  {
    name: "Insurance",
    url: "/operation",
    group: "Operation",
  },
  {
    name: "Daily Report",
    url: "/operation",
    group: "Operation",
  },
  {
    name: "Maintenance Report",
    url: "/operation",
    group: "Operation",
  },
  {
    name: "Status Report",
    url: "/operation",
    group: "Operation",
  },
  {
    name: "Fuel Utilization Report",
    url: "/operation",
    group: "Operation",
  },
  {
    name: "Time Utilization Report",
    url: "/operation",
    group: "Operation",
  },
  {
    name: "Material Delivery Report",
    url: "/operation",
    group: "Operation",
  },
  {
    name: "Owned Equipment",
    url: "/operation",
    group: "Operation",
  },
  {
    name: "Rental Equipment",
    url: "/operation",
    group: "Operation",
  },
  {
    name: "Operation Cost Report",
    url: "/operation",
    group: "Operation",
  },
  {
    name: "External Repair Order",
    url: "/operation",
    group: "Operation",
  },
  {
    name: "Contract Administration",
    url: "/finance",
    group: "Finance",
  },
  {
    name: "Bank Account",
    url: "/finance",
    group: "Finance",
  },
  {
    name: "Petty Cash",
    url: "/finance",
    group: "Finance",
  },
  {
    name: "Check Payment Voucher",
    url: "/finance",
    group: "Finance",
  },
  {
    name: "Archive",
    url: "/finance",
    group: "Finance",
  },
  {
    name: "Illegal Receipts",
    url: "/finance",
    group: "Finance",
  },
  {
    name: "Loan",
    url: "/finance",
    group: "Finance",
  },
  {
    name: "Post Date Voucher",
    url: "/finance",
    group: "Finance",
  },
  {
    name: "Telegraphice Transfer",
    url: "/finance",
    group: "Finance",
  },
  {
    name: "RTGS",
    url: "/finance",
    group: "Finance",
  },
  {
    name: "Journal Voucher",
    url: "/finance",
    group: "Finance",
  },
  {
    name: "Charts of Accounts",
    url: "/finance",
    group: "Finance",
  },
  {
    name: "Material",
    url: "/procurement",
    group: "Procurement",
  },
  {
    name: "Service List",
    url: "/procurement",
    group: "Procurement",
  },
  {
    name: "Material Starting Balance",
    url: "/procurement",
    group: "Procurement",
  },
  {
    name: "Location",
    url: "/procurement",
    group: "Procurement",
  },
  {
    name: "Supplier",
    url: "/procurement",
    group: "Procurement",
  },
  {
    name: "Store Requisition",
    url: "/procurement",
    group: "Procurement",
  },
  {
    name: "Purchase Requisition",
    url: "/procurement",
    group: "Procurement",
  },
  {
    name: "Payment Requisition",
    url: "/procurement",
    group: "Procurement",
  },
  {
    name: "Bid Analysis",
    url: "/procurement",
    group: "Procurement",
  },
  {
    name: "Purchase Order",
    url: "/procurement",
    group: "Procurement",
  },
  {
    name: "Follow Up",
    url: "/procurement",
    group: "Procurement",
  },
  {
    name: "Fuel Request",
    url: "/procurement",
    group: "Procurement",
  },
  {
    name: "Tire and Battery Request",
    url: "/procurement",
    group: "Procurement",
  },
  {
    name: "Manufacturing Order",
    url: "/procurement",
    group: "Procurement",
  },
  {
    name: "PR Tracking",
    url: "/procurement",
    group: "Procurement",
  },
  {
    name: "Service Request",
    url: "/procurement",
    group: "Procurement",
  },
  {
    name: "Trend Analysis",
    url: "/procurement",
    group: "Procurement",
  },
  {
    name: "Inventory",
    url: "/inventory",
    group: "Inventory",
  },
  {
    name: "Inventory Count",
    url: "/inventory",
    group: "Inventory",
  },
  {
    name: "Goods Received",
    url: "/inventory",
    group: "Inventory",
  },
  {
    name: "Store Communication",
    url: "/inventory",
    group: "Inventory",
  },
  {
    name: "Goods Return Note",
    url: "/inventory",
    group: "Inventory",
  },
  {
    name: "Inter Store Transfer Out",
    url: "/inventory",
    group: "Inventory",
  },
  {
    name: "Inter Store Transfer In",
    url: "/inventory",
    group: "Inventory",
  },
  {
    name: "Store Issue",
    url: "/inventory",
    group: "Inventory",
  },
  {
    name: "Dispatch",
    url: "/inventory",
    group: "Inventory",
  },
  {
    name: "Damage Return",
    url: "/inventory",
    group: "Inventory",
  },
  {
    name: "Stock Card",
    url: "/inventory",
    group: "Inventory",
  },
  {
    name: "Stock Report",
    url: "/inventory",
    group: "Inventory",
  },
  {
    name: "Bin Card",
    url: "/inventory",
    group: "Inventory",
  },
  {
    name: "Temporary Delivery Note",
    url: "/inventory",
    group: "Inventory",
  },
  {
    name: "Material Inspection and Repair",
    url: "/inventory",
    group: "Inventory",
  },
  {
    name: "Report",
    url: "/inventory",
    group: "Inventory",
  },
  {
    name: "Transmital",
    url: "/inventory",
    group: "Inventory",
  },
  {
    name: "Outsourcing Maintenance Order",
    url: "/inventory",
    group: "Inventory",
  },
  {
    name: "Fuel Receive",
    url: "/inventory",
    group: "Inventory",
  },
  {
    name: "Fuel and Lubicon Request",
    url: "/inventory",
    group: "Inventory",
  },
  {
    name: "Gate Pass",
    url: "/inventory",
    group: "Inventory",
  },
  {
    name: "Vehicle Report",
    url: "/inventory",
    group: "Inventory",
  },
  {
    name: "Forman Report",
    url: "/inventory",
    group: "Inventory",
  },
  {
    name: "Finished Product",
    url: "/inventory",
    group: "Inventory",
  },
  {
    name: "Fixed Asset Received",
    url: "/fixed-asset",
    group: "Fixed Asset",
  },
  {
    name: "Fixed Asset Return",
    url: "/fixed-asset",
    group: "Fixed Asset",
  },
  {
    name: "Fixed Asset Transfer Out",
    url: "/fixed-asset",
    group: "Fixed Asset",
  },
  {
    name: "Fixed Asset Transfer In",
    url: "/fixed-asset",
    group: "Fixed Asset",
  },
  {
    name: "Fixed Asset Issue",
    url: "/fixed-asset",
    group: "Fixed Asset",
  },
  {
    name: "FIxed Asset Transfer (E-E)",
    url: "/fixed-asset",
    group: "Fixed Asset",
  },
  {
    name: "Fuel Issue",
    url: "/fixed-asset",
    group: "Fixed Asset",
  },
  {
    name: "Fuel Received",
    url: "/fixed-asset",
    group: "Fixed Asset",
  },
  {
    name: "Fixed Asset Report",
    url: "/fixed-asset",
    group: "Fixed Asset",
  },
  {
    name: "Fixed Asset Status",
    url: "/fixed-asset",
    group: "Fixed Asset",
  },

  {
    name: "Acquiring Status",
    url: "/fixed-asset",
    group: "Fixed Asset",
  },
  {
    name: "Master List",
    url: "/fixed-asset",
    group: "Fixed Asset",
  },
];
