import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SiteVisitActionTypes } from "./SiteVisit.type";

export function* fetchAllSiteVisits(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/site-visit?project_id=${action.payload?.project_id}`);
    yield put({
      type: SiteVisitActionTypes.FETCH_ALL_SITE_VISIT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SiteVisitActionTypes.FETCH_ALL_SITE_VISIT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSiteVisits(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/site-visit/${action.payload}`
    );
    yield put({
      type: SiteVisitActionTypes.FETCH_ONE_SITE_VISIT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SiteVisitActionTypes.FETCH_ONE_SITE_VISIT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSiteVisits() {
  yield takeLatest(SiteVisitActionTypes.FETCH_ALL_SITE_VISIT, fetchAllSiteVisits);
}

export function* watcherFetchOneSiteVisits() {
  yield takeLatest(SiteVisitActionTypes.FETCH_ONE_SITE_VISIT, fetchOneSiteVisits);
}
