import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { EmployeeRequestActionTypes } from "./EmployeeRequest.type";

export function* fetchAllEmployeeRequests(action: any): any {
  try {
    let query = "";

    if (action.payload && Object.keys(action.payload).length > 0) {
      query =
        "?" +
        Object.keys(action.payload)
          .map((key) => `${key}=${action.payload[key]}`)
          .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/employee_request${query}`
    );
    yield put({
      type: EmployeeRequestActionTypes.FETCH_ALL_EMPLOYEE_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EmployeeRequestActionTypes.FETCH_ALL_EMPLOYEE_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneEmployeeRequests(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/employee_request/${action.payload}`
    );
    yield put({
      type: EmployeeRequestActionTypes.FETCH_ONE_EMPLOYEE_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EmployeeRequestActionTypes.FETCH_ONE_EMPLOYEE_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchEmployeeLeaveRequestState(action: any): any {
  try {
    let query = "";

    if (action?.payload && Object.keys(action.payload).length > 0) {
      let keys = Object.keys(action.payload);
      query += keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/employee_request/leave-state?${query}`
    );
    yield put({
      type: EmployeeRequestActionTypes.FETCH_CURRENT_EMPLOYEE_LEAVE_REQUEST_STATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EmployeeRequestActionTypes.FETCH_CURRENT_EMPLOYEE_LEAVE_REQUEST_STATE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchEmployeeHealthBenefitRequestState(action: any): any {
  try {
    let query = "";

    if (action?.payload && Object.keys(action.payload).length > 0) {
      let keys = Object.keys(action.payload);
      query += keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/employee_request/health-benefit-state?${query}`
    );
    yield put({
      type: EmployeeRequestActionTypes.FETCH_YEARLY_EMPLOYEE_HEALTH_BENEFIT_REQUEST_STATE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EmployeeRequestActionTypes.FETCH_YEARLY_EMPLOYEE_HEALTH_BENEFIT_REQUEST_STATE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllEmployeeRequests() {
  yield takeLatest(
    EmployeeRequestActionTypes.FETCH_ALL_EMPLOYEE_REQUEST,
    fetchAllEmployeeRequests
  );
}

export function* watcherFetchOneEmployeeRequests() {
  yield takeLatest(
    EmployeeRequestActionTypes.FETCH_ONE_EMPLOYEE_REQUEST,
    fetchOneEmployeeRequests
  );
}

export function* watcherFetchEmployeeLeaveRequestState() {
  yield takeLatest(
    EmployeeRequestActionTypes.FETCH_CURRENT_EMPLOYEE_LEAVE_REQUEST_STATE,
    fetchEmployeeLeaveRequestState
  );
}

export function* watcherFetchEmployeeHealthBenefitRequestState() {
  yield takeLatest(
    EmployeeRequestActionTypes.FETCH_YEARLY_EMPLOYEE_HEALTH_BENEFIT_REQUEST_STATE,
    fetchEmployeeHealthBenefitRequestState
  );
}
