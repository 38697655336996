import { Project } from "../Project/Project.type";
import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type ProjectTeam = {
  id: number;
  project_id: number;
  staff_id: number;

  project: Project;
  staff: Staff;
};

export type ProjectTeamStateTypes = {
  fetchAll: ApiCallState<ProjectTeam[]>;
  fetchOne: ApiCallState<ProjectTeam | {}>;
};

export const ProjectTeamActionTypes = {
  FETCH_ALL_PROJECT_TEAM: "FETCH_ALL_PROJECT_TEAM",
  FETCH_ALL_PROJECT_TEAM_RESET: "FETCH_ALL_PROJECT_TEAM_RESET",
  FETCH_ALL_PROJECT_TEAM_FAILURE: "FETCH_ALL_PROJECT_TEAM_FAILURE",
  FETCH_ALL_PROJECT_TEAM_SUCCESS: "FETCH_ALL_PROJECT_TEAM_SUCCESS",

  FETCH_ONE_PROJECT_TEAM: "FETCH_ONE_PROJECT_TEAM",
  FETCH_ONE_PROJECT_TEAM_RESET: "FETCH_ONE_PROJECT_TEAM_RESET",
  FETCH_ONE_PROJECT_TEAM_FAILURE: "FETCH_ONE_PROJECT_TEAM_FAILURE",
  FETCH_ONE_PROJECT_TEAM_SUCCESS: "FETCH_ONE_PROJECT_TEAM_SUCCESS",
};
