import { Material } from "../Material/Material.type";
import { Store } from "../Store/Store.type";
import { ApiCallState } from "../Utils";

export type OutsourcingMaintenance = {
  id: number;
  date: string;
  order_no: number;
  store_id: number;
  material_id: number;
  requested_by_id: number;
  prepared_by: number;
  checked_by: number;
  approved_by: number;
  delivered_by: number;
  confirmation: string;
  verified_by: number;
  material: Material;
  store: Store;
  requested_by: Store;
  user_id: number;
  outsourcing_maintenance_items: OutsourcingMaintenanceItem[];
  outsourcing_maintenance_statuses:OutsourcingMaintenanceStatus[];
};

export type OutsourcingMaintenanceItem = {
  id: number;
  outsourcing_maintenance_id: number;
  problem: string;
};

export type OutsourcingMaintenanceStatus = {
  outsourcing_maintenance_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type OutsourcingMaintenanceStateTypes = {
  fetchAll: ApiCallState<OutsourcingMaintenance[]>;
  fetchOne: ApiCallState<OutsourcingMaintenance | {}>;
};

export const OutsourcingMaintenanceActionTypes = {
  FETCH_ALL_OUTSOURCING_MAINTENANCE: "FETCH_ALL_OUTSOURCING_MAINTENANCE",
  FETCH_ALL_OUTSOURCING_MAINTENANCE_RESET:
    "FETCH_ALL_OUTSOURCING_MAINTENANCE_RESET",
  FETCH_ALL_OUTSOURCING_MAINTENANCE_FAILURE:
    "FETCH_ALL_OUTSOURCING_MAINTENANCE_FAILURE",
  FETCH_ALL_OUTSOURCING_MAINTENANCE_SUCCESS:
    "FETCH_ALL_OUTSOURCING_MAINTENANCE_SUCCESS",

  FETCH_ONE_OUTSOURCING_MAINTENANCE: "FETCH_ONE_OUTSOURCING_MAINTENANCE",
  FETCH_ONE_OUTSOURCING_MAINTENANCE_RESET:
    "FETCH_ONE_OUTSOURCING_MAINTENANCE_RESET",
  FETCH_ONE_OUTSOURCING_MAINTENANCE_FAILURE:
    "FETCH_ONE_OUTSOURCING_MAINTENANCE_FAILURE",
  FETCH_ONE_OUTSOURCING_MAINTENANCE_SUCCESS:
    "FETCH_ONE_OUTSOURCING_MAINTENANCE_SUCCESS",
};
