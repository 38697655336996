import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { FeedBackResponseActionTypes } from "./FeedBackResponse.type";

export function* fetchAllFeedBackResponses(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      query =
        "?" +
        Object.keys(action.payload)
          .map((key) => `${key}=${action.payload[key]}`)
          .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/feed-back-response${query}`
    );
    yield put({
      type: FeedBackResponseActionTypes.FETCH_ALL_FEED_BACK_RESPONSE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FeedBackResponseActionTypes.FETCH_ALL_FEED_BACK_RESPONSE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneFeedBackResponses(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      query =
        "?" +
        Object.keys(action.payload)
          .map((key) => `${key}=${action.payload[key]}`)
          .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/feed-back-response/one${query}`
    );
    yield put({
      type: FeedBackResponseActionTypes.FETCH_ONE_FEED_BACK_RESPONSE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FeedBackResponseActionTypes.FETCH_ONE_FEED_BACK_RESPONSE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllFeedBackResponses() {
  yield takeLatest(
    FeedBackResponseActionTypes.FETCH_ALL_FEED_BACK_RESPONSE,
    fetchAllFeedBackResponses
  );
}

export function* watcherFetchOneFeedBackResponses() {
  yield takeLatest(
    FeedBackResponseActionTypes.FETCH_ONE_FEED_BACK_RESPONSE,
    fetchOneFeedBackResponses
  );
}
