import { ApplicantRegistryItem } from "../ApplicantRegistryItem/ApplicantRegistryItem.type";
import { ApiCallState } from "../Utils";

export type WorkExperienceSummary= {
  id: number;
  applicant_registry_item_id: number;
  total_experience:string;
  remark:string;
  is_approved: boolean | null;
  prepared_by:number;
  checked_by:number;
  approved_by:number;
  work_experience_summary_items:WorkExperienceSummaryItem[];
  applicant_registry_item:ApplicantRegistryItem;
};

export type WorkExperienceSummaryItem= {
  id:number;
  work_experience_summary_id:number;
  education:string;
  field_of_study:string;
  title:string;
  organization:string;
  working_year:string;
  experience:string;
  start_date?:string;
  end_date?:string;
  reason_of_unemployment?:string;
}

export type WorkExperienceSummaryStateTypes = {
  fetchAll: ApiCallState<WorkExperienceSummary[]>;
  fetchOne: ApiCallState<WorkExperienceSummary | {}>;
};

export const WorkExperienceSummaryActionTypes = {
  FETCH_ALL_WORK_EXPERIENCE_SUMMARY: "FETCH_ALL_WORK_EXPERIENCE_SUMMARY",
  FETCH_ALL_WORK_EXPERIENCE_SUMMARY_RESET: "FETCH_ALL_WORK_EXPERIENCE_SUMMARY_RESET",
  FETCH_ALL_WORK_EXPERIENCE_SUMMARY_FAILURE: "FETCH_ALL_WORK_EXPERIENCE_SUMMARY_FAILURE",
  FETCH_ALL_WORK_EXPERIENCE_SUMMARY_SUCCESS: "FETCH_ALL_WORK_EXPERIENCE_SUMMARY_SUCCESS",

  FETCH_ONE_WORK_EXPERIENCE_SUMMARY: "FETCH_ONE_WORK_EXPERIENCE_SUMMARY",
  FETCH_ONE_WORK_EXPERIENCE_SUMMARY_RESET: "FETCH_ONE_WORK_EXPERIENCE_SUMMARY_RESET",
  FETCH_ONE_WORK_EXPERIENCE_SUMMARY_FAILURE: "FETCH_ONE_WORK_EXPERIENCE_SUMMARY_FAILURE",
  FETCH_ONE_WORK_EXPERIENCE_SUMMARY_SUCCESS: "FETCH_ONE_WORK_EXPERIENCE_SUMMARY_SUCCESS",
};
