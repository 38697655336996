import { ApiCallState } from "../Utils";

export type StaffDiscipline= {
  id: number;
  staff_id:number;
  date:string;
  type:string;
  description:string;
  measures_taken:string;
  user_id:number;
};

export type StaffDisciplineStateTypes = {
  fetchAll: ApiCallState<StaffDiscipline[]>;
  fetchOne: ApiCallState<StaffDiscipline | {}>;
};

export const StaffDisciplineActionTypes = {
  FETCH_ALL_STAFF_DISCIPLINE: "FETCH_ALL_STAFF_DISCIPLINE",
  FETCH_ALL_STAFF_DISCIPLINE_RESET: "FETCH_ALL_STAFF_DISCIPLINE_RESET",
  FETCH_ALL_STAFF_DISCIPLINE_FAILURE: "FETCH_ALL_STAFF_DISCIPLINE_FAILURE",
  FETCH_ALL_STAFF_DISCIPLINE_SUCCESS: "FETCH_ALL_STAFF_DISCIPLINE_SUCCESS",

  FETCH_ONE_STAFF_DISCIPLINE: "FETCH_ONE_STAFF_DISCIPLINE",
  FETCH_ONE_STAFF_DISCIPLINE_RESET: "FETCH_ONE_STAFF_DISCIPLINE_RESET",
  FETCH_ONE_STAFF_DISCIPLINE_FAILURE: "FETCH_ONE_STAFF_DISCIPLINE_FAILURE",
  FETCH_ONE_STAFF_DISCIPLINE_SUCCESS: "FETCH_ONE_STAFF_DISCIPLINE_SUCCESS",
};
