import { SurveyingEquipmentCostStateTypes, SurveyingEquipmentCostActionTypes } from "./SurveyingEquipmentCost.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: SurveyingEquipmentCostStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const SurveyingEquipmentCostReducer = (
  state: SurveyingEquipmentCostStateTypes = INITIAL_STATE,
  action: any
): SurveyingEquipmentCostStateTypes => {
  switch (action.type) {
    case SurveyingEquipmentCostActionTypes.FETCH_ALL_SURVEYING_EQUIPMENT_COST:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case SurveyingEquipmentCostActionTypes.FETCH_ALL_SURVEYING_EQUIPMENT_COST_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SurveyingEquipmentCostActionTypes.FETCH_ALL_SURVEYING_EQUIPMENT_COST_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SurveyingEquipmentCostActionTypes.FETCH_ALL_SURVEYING_EQUIPMENT_COST_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case SurveyingEquipmentCostActionTypes.FETCH_ONE_SURVEYING_EQUIPMENT_COST:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case SurveyingEquipmentCostActionTypes.FETCH_ONE_SURVEYING_EQUIPMENT_COST_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SurveyingEquipmentCostActionTypes.FETCH_ONE_SURVEYING_EQUIPMENT_COST_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SurveyingEquipmentCostActionTypes.FETCH_ONE_SURVEYING_EQUIPMENT_COST_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default SurveyingEquipmentCostReducer;
