import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { BillSummaryActionTypes } from "./BillSummary.type";

export function* fetchAllBillSummarys(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/bill-summary?project_id=${action.payload?.project_id}`);
    yield put({
      type: BillSummaryActionTypes.FETCH_ALL_BILL_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BillSummaryActionTypes.FETCH_ALL_BILL_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneBillSummarys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/bill-summary/${action.payload}`
    );
    yield put({
      type: BillSummaryActionTypes.FETCH_ONE_BILL_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BillSummaryActionTypes.FETCH_ONE_BILL_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllBillSummarys() {
  yield takeLatest(BillSummaryActionTypes.FETCH_ALL_BILL_SUMMARY, fetchAllBillSummarys);
}

export function* watcherFetchOneBillSummarys() {
  yield takeLatest(BillSummaryActionTypes.FETCH_ONE_BILL_SUMMARY, fetchOneBillSummarys);
}
