import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type SocialCommitment= {
  id: number;
  date:string;
  staff_id:number;
  amount:number;
  user_id:number;
  staff:Staff;
};

export type SocialCommitmentStateTypes = {
  fetchAll: ApiCallState<SocialCommitment[]>;
  fetchOne: ApiCallState<SocialCommitment | {}>;
};

export const SocialCommitmentActionTypes = {
  FETCH_ALL_SOCIAL_COMMITMENT: "FETCH_ALL_SOCIAL_COMMITMENT",
  FETCH_ALL_SOCIAL_COMMITMENT_RESET: "FETCH_ALL_SOCIAL_COMMITMENT_RESET",
  FETCH_ALL_SOCIAL_COMMITMENT_FAILURE: "FETCH_ALL_SOCIAL_COMMITMENT_FAILURE",
  FETCH_ALL_SOCIAL_COMMITMENT_SUCCESS: "FETCH_ALL_SOCIAL_COMMITMENT_SUCCESS",

  FETCH_ONE_SOCIAL_COMMITMENT: "FETCH_ONE_SOCIAL_COMMITMENT",
  FETCH_ONE_SOCIAL_COMMITMENT_RESET: "FETCH_ONE_SOCIAL_COMMITMENT_RESET",
  FETCH_ONE_SOCIAL_COMMITMENT_FAILURE: "FETCH_ONE_SOCIAL_COMMITMENT_FAILURE",
  FETCH_ONE_SOCIAL_COMMITMENT_SUCCESS: "FETCH_ONE_SOCIAL_COMMITMENT_SUCCESS",
};
