import { ApiCallState } from "../Utils";

export type OtherExpense = {
  id: number;
  start_date: string;
  end_date: string;
  project_id: number;
  user_id: number;
  other_expense_items: OtherExpenseItem[];
};

export type OtherExpenseItem = {
  id: number;
  no: string;
  description: string;
  unit: string;
  possession_date: string;
  purchase_date: string;
  purchase_price_per_unit: string;
  quantity: number;
  cost_per_unit: number;
  previous_cost: number;
  this_period_cost: number;
  to_date_cost: number;
  remark: string;
  type: string;
  other_expense_id: number;
};

export type OtherExpenseStateTypes = {
  fetchAll: ApiCallState<OtherExpense[]>;
  fetchOne: ApiCallState<OtherExpense | {}>;
};

export const OtherExpenseActionTypes = {
  FETCH_ALL_OTHER_EXPENSE: "FETCH_ALL_OTHER_EXPENSE",
  FETCH_ALL_OTHER_EXPENSE_RESET: "FETCH_ALL_OTHER_EXPENSE_RESET",
  FETCH_ALL_OTHER_EXPENSE_FAILURE: "FETCH_ALL_OTHER_EXPENSE_FAILURE",
  FETCH_ALL_OTHER_EXPENSE_SUCCESS: "FETCH_ALL_OTHER_EXPENSE_SUCCESS",

  FETCH_ONE_OTHER_EXPENSE: "FETCH_ONE_OTHER_EXPENSE",
  FETCH_ONE_OTHER_EXPENSE_RESET: "FETCH_ONE_OTHER_EXPENSE_RESET",
  FETCH_ONE_OTHER_EXPENSE_FAILURE: "FETCH_ONE_OTHER_EXPENSE_FAILURE",
  FETCH_ONE_OTHER_EXPENSE_SUCCESS: "FETCH_ONE_OTHER_EXPENSE_SUCCESS",
};
