import { ApiCallState } from "../Utils";

export type EquipmentOID = {
  id: number;
  start_date: string;
  end_date: string;
  project_id: number;
  user_id: number;
  weekly_site_report_id: number;

  equipment_oid_items: EquipmentOIDItem[];
};

export type EquipmentOIDItem = {
  id: number;
  equipment_type: string;
  total_no_of_equipment: number;
  no_of_operational_equipment: number;
  equipment_oid_id: number;

  equipment_oid_details: EquipmentOIDDetail[];
};

export type EquipmentOIDDetail = {
  id: number;
  plate_no: string;
  ownership: string;
  operational_hour: number;
  idle_hr: number;
  down_hr: number;
  reason_for_idle: string;
  reason_for_down: string;
  remark: string;
  equipment_oid_item_id: number;
};

export type EquipmentOIDStateTypes = {
  fetchAll: ApiCallState<EquipmentOID[]>;
  fetchOne: ApiCallState<EquipmentOID | {}>;
};

export const EquipmentOIDActionTypes = {
  FETCH_ALL_EQUIPMENT_OID: "FETCH_ALL_EQUIPMENT_OID",
  FETCH_ALL_EQUIPMENT_OID_RESET: "FETCH_ALL_EQUIPMENT_OID_RESET",
  FETCH_ALL_EQUIPMENT_OID_FAILURE: "FETCH_ALL_EQUIPMENT_OID_FAILURE",
  FETCH_ALL_EQUIPMENT_OID_SUCCESS: "FETCH_ALL_EQUIPMENT_OID_SUCCESS",

  FETCH_ONE_EQUIPMENT_OID: "FETCH_ONE_EQUIPMENT_OID",
  FETCH_ONE_EQUIPMENT_OID_RESET: "FETCH_ONE_EQUIPMENT_OID_RESET",
  FETCH_ONE_EQUIPMENT_OID_FAILURE: "FETCH_ONE_EQUIPMENT_OID_FAILURE",
  FETCH_ONE_EQUIPMENT_OID_SUCCESS: "FETCH_ONE_EQUIPMENT_OID_SUCCESS",
};
