import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { StaffEducationActionTypes } from "./StaffEducation.type";

export function* fetchAllStaffEducations(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/staff-education?staff_id=${action.payload?.staff_id}`);
    yield put({
      type: StaffEducationActionTypes.FETCH_ALL_STAFF_EDUCATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffEducationActionTypes.FETCH_ALL_STAFF_EDUCATION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllStaffEducations() {
  yield takeLatest(
    StaffEducationActionTypes.FETCH_ALL_STAFF_EDUCATION,
    fetchAllStaffEducations
  );
}
