import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { RGTSActionTypes } from "./RGTS.type";

export function* fetchAllRGTSs(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/rgts?${query}`);
    yield put({
      type: RGTSActionTypes.FETCH_ALL_RGTS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: RGTSActionTypes.FETCH_ALL_RGTS_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedRGTSs(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/rgts?${query}`);
    yield put({
      type: RGTSActionTypes.FETCH_PAGED_RGTS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: RGTSActionTypes.FETCH_PAGED_RGTS_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneRGTSs(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/rgts/${action.payload}`);

    yield put({
      type: RGTSActionTypes.FETCH_ONE_RGTS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: RGTSActionTypes.FETCH_ONE_RGTS_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllRGTSs() {
  yield takeLatest(RGTSActionTypes.FETCH_ALL_RGTS, fetchAllRGTSs);
}

export function* watcherFetchPagedRGTSs() {
  yield takeLatest(RGTSActionTypes.FETCH_PAGED_RGTS, fetchPagedRGTSs);
}

export function* watcherFetchOneRGTSs() {
  yield takeLatest(RGTSActionTypes.FETCH_ONE_RGTS, fetchOneRGTSs);
}
