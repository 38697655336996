import { Staff } from "../Staff/Staff.type";
import { ApiCallState, PagedData } from "../Utils";

export type PermanentEmployeeAttendance = {
  id: number;
  month: string;
  permanent_employee_attendance_items:PermanentEmployeeAttendanceItem[];
};

export type PermanentEmployeeAttendanceItem = {
  id:number;
  permanent_employee_attendance_id:number;
  staff_id: number;
  position: string;
  working_days:number;
  normal_ot: number;
  night_ot:number;
  sunday_ot: number;
  holiday_ot: number;
  staff:Staff;
}

export type PermanentEmployeeAttendanceStateTypes = {
  fetchAll: ApiCallState<PermanentEmployeeAttendance[]>;
  fetchOne: ApiCallState<PermanentEmployeeAttendance | {}>;
  fetchPaged: ApiCallState<PagedData<PermanentEmployeeAttendance[]>>;
};

export const PermanentEmployeeAttendanceActionTypes = {
  FETCH_ALL_PERMANENT_EMPLOYEE_ATTENDANCE:
    "FETCH_ALL_PERMANENT_EMPLOYEE_ATTENDANCE",
  FETCH_ALL_PERMANENT_EMPLOYEE_ATTENDANCE_RESET:
    "FETCH_ALL_PERMANENT_EMPLOYEE_ATTENDANCE_RESET",
  FETCH_ALL_PERMANENT_EMPLOYEE_ATTENDANCE_FAILURE:
    "FETCH_ALL_PERMANENT_EMPLOYEE_ATTENDANCE_FAILURE",
  FETCH_ALL_PERMANENT_EMPLOYEE_ATTENDANCE_SUCCESS:
    "FETCH_ALL_PERMANENT_EMPLOYEE_ATTENDANCE_SUCCESS",

  FETCH_PAGED_PERMANENT_EMPLOYEE_ATTENDANCE:
    "FETCH_PAGED_PERMANENT_EMPLOYEE_ATTENDANCE",
  FETCH_PAGED_PERMANENT_EMPLOYEE_ATTENDANCE_RESET:
    "FETCH_PAGED_PERMANENT_EMPLOYEE_ATTENDANCE_RESET",
  FETCH_PAGED_PERMANENT_EMPLOYEE_ATTENDANCE_FAILURE:
    "FETCH_PAGED_PERMANENT_EMPLOYEE_ATTENDANCE_FAILURE",
  FETCH_PAGED_PERMANENT_EMPLOYEE_ATTENDANCE_SUCCESS:
    "FETCH_PAGED_PERMANENT_EMPLOYEE_ATTENDANCE_SUCCESS",

  FETCH_ONE_PERMANENT_EMPLOYEE_ATTENDANCE:
    "FETCH_ONE_PERMANENT_EMPLOYEE_ATTENDANCE",
  FETCH_ONE_PERMANENT_EMPLOYEE_ATTENDANCE_RESET:
    "FETCH_ONE_PERMANENT_EMPLOYEE_ATTENDANCE_RESET",
  FETCH_ONE_PERMANENT_EMPLOYEE_ATTENDANCE_FAILURE:
    "FETCH_ONE_PERMANENT_EMPLOYEE_ATTENDANCE_FAILURE",
  FETCH_ONE_PERMANENT_EMPLOYEE_ATTENDANCE_SUCCESS:
    "FETCH_ONE_PERMANENT_EMPLOYEE_ATTENDANCE_SUCCESS",
};
