import {
  AttendanceLocationStateTypes,
  AttendanceLocationActionTypes,
} from "./AttendanceLocation.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: AttendanceLocationStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const AttendanceLocationReducer = (
  state: AttendanceLocationStateTypes = INITIAL_STATE,
  action: any
): AttendanceLocationStateTypes => {
  switch (action.type) {
    case AttendanceLocationActionTypes.FETCH_ALL_ATTENDANCE_LOCATION:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case AttendanceLocationActionTypes.FETCH_ALL_ATTENDANCE_LOCATION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case AttendanceLocationActionTypes.FETCH_ALL_ATTENDANCE_LOCATION_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case AttendanceLocationActionTypes.FETCH_ALL_ATTENDANCE_LOCATION_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case AttendanceLocationActionTypes.FETCH_ONE_ATTENDANCE_LOCATION:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case AttendanceLocationActionTypes.FETCH_ONE_ATTENDANCE_LOCATION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case AttendanceLocationActionTypes.FETCH_ONE_ATTENDANCE_LOCATION_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case AttendanceLocationActionTypes.FETCH_ONE_ATTENDANCE_LOCATION_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default AttendanceLocationReducer;
