import axios from "axios";
import { isNil } from "lodash";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { AccountTransactionActionTypes } from "./AccountTransaction.type";

export function* fetchAllAccountTransactions(action: any): any {
  try {
    let query = "";
    if (!isNil(action.payload)) {
      let keys = Object.keys(action.payload);

      let mapped: any = [];

      keys.forEach((key) => mapped.push(`${key}=${action.payload[key]}`));

      query = mapped.join("&&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/account_transaction?${query}`
    );
    yield put({
      type: AccountTransactionActionTypes.FETCH_ALL_ACCOUNT_TRANSACTION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: AccountTransactionActionTypes.FETCH_ALL_ACCOUNT_TRANSACTION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllAccountTransactions() {
  yield takeLatest(
    AccountTransactionActionTypes.FETCH_ALL_ACCOUNT_TRANSACTION,
    fetchAllAccountTransactions
  );
}
