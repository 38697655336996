import { PlaceOfWork } from "../PlaceOfWork/PlaceOfWork.type";
import { ApiCallState } from "../Utils";

export type ProjectAllowance= {
  id: number;
  project: string;
  type: string;
  percentage: number;
  place_of_work_id:number;
  place_of_work:PlaceOfWork;
};

export type ProjectAllowanceStateTypes = {
  fetchAll: ApiCallState<ProjectAllowance[]>;
  fetchOne: ApiCallState<ProjectAllowance | {}>;
};

export const ProjectAllowanceActionTypes = {
  FETCH_ALL_PROJECT_ALLOWANCE: "FETCH_ALL_PROJECT_ALLOWANCE",
  FETCH_ALL_PROJECT_ALLOWANCE_RESET: "FETCH_ALL_PROJECT_ALLOWANCE_RESET",
  FETCH_ALL_PROJECT_ALLOWANCE_FAILURE: "FETCH_ALL_PROJECT_ALLOWANCE_FAILURE",
  FETCH_ALL_PROJECT_ALLOWANCE_SUCCESS: "FETCH_ALL_PROJECT_ALLOWANCE_SUCCESS",

  FETCH_ONE_PROJECT_ALLOWANCE: "FETCH_ONE_PROJECT_ALLOWANCE",
  FETCH_ONE_PROJECT_ALLOWANCE_RESET: "FETCH_ONE_PROJECT_ALLOWANCE_RESET",
  FETCH_ONE_PROJECT_ALLOWANCE_FAILURE: "FETCH_ONE_PROJECT_ALLOWANCE_FAILURE",
  FETCH_ONE_PROJECT_ALLOWANCE_SUCCESS: "FETCH_ONE_PROJECT_ALLOWANCE_SUCCESS",
};
