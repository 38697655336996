import { ApiCallState } from "../Utils";

export type Holiday = {
  id: number;

  description: string;
  date: string;
  type: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type HolidayStateTypes = {
  fetchAll: ApiCallState<Holiday[]>;
  fetchOne: ApiCallState<Holiday | {}>;
};

export const HolidayActionTypes = {
  FETCH_ALL_HOLIDAY: "FETCH_ALL_HOLIDAY",
  FETCH_ALL_HOLIDAY_RESET: "FETCH_ALL_HOLIDAY_RESET",
  FETCH_ALL_HOLIDAY_FAILURE: "FETCH_ALL_HOLIDAY_FAILURE",
  FETCH_ALL_HOLIDAY_SUCCESS: "FETCH_ALL_HOLIDAY_SUCCESS",

  FETCH_ONE_HOLIDAY: "FETCH_ONE_HOLIDAY",
  FETCH_ONE_HOLIDAY_RESET: "FETCH_ONE_HOLIDAY_RESET",
  FETCH_ONE_HOLIDAY_FAILURE: "FETCH_ONE_HOLIDAY_FAILURE",
  FETCH_ONE_HOLIDAY_SUCCESS: "FETCH_ONE_HOLIDAY_SUCCESS",
};
