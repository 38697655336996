import { ApiCallState } from "../Utils";

export type MikadaAnnualReport= {
  id: number;
  date: string;
  remark: string;
  project_id: number;
  user_id: number;
};

export type MikadaAnnualReportStateTypes = {
  fetchAll: ApiCallState<MikadaAnnualReport[]>;
  fetchOne: ApiCallState<MikadaAnnualReport | {}>;
};

export const MikadaAnnualReportActionTypes = {
  FETCH_ALL_MIKADA_ANNUAL_REPORT: "FETCH_ALL_MIKADA_ANNUAL_REPORT",
  FETCH_ALL_MIKADA_ANNUAL_REPORT_RESET: "FETCH_ALL_MIKADA_ANNUAL_REPORT_RESET",
  FETCH_ALL_MIKADA_ANNUAL_REPORT_FAILURE: "FETCH_ALL_MIKADA_ANNUAL_REPORT_FAILURE",
  FETCH_ALL_MIKADA_ANNUAL_REPORT_SUCCESS: "FETCH_ALL_MIKADA_ANNUAL_REPORT_SUCCESS",

  FETCH_ONE_MIKADA_ANNUAL_REPORT: "FETCH_ONE_MIKADA_ANNUAL_REPORT",
  FETCH_ONE_MIKADA_ANNUAL_REPORT_RESET: "FETCH_ONE_MIKADA_ANNUAL_REPORT_RESET",
  FETCH_ONE_MIKADA_ANNUAL_REPORT_FAILURE: "FETCH_ONE_MIKADA_ANNUAL_REPORT_FAILURE",
  FETCH_ONE_MIKADA_ANNUAL_REPORT_SUCCESS: "FETCH_ONE_MIKADA_ANNUAL_REPORT_SUCCESS",
};
