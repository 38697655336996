import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { WeeklyProgressSummaryActionTypes } from "./WeeklyProgressSummary.type";

export function* fetchAllWeeklyProgressSummarys(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/weekly-progress-summary?date=${action.payload?.date}`);
    yield put({
      type: WeeklyProgressSummaryActionTypes.FETCH_ALL_WEEKLY_PROGRESS_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WeeklyProgressSummaryActionTypes.FETCH_ALL_WEEKLY_PROGRESS_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneWeeklyProgressSummarys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/weekly-progress-summary/${action.payload}`
    );
    yield put({
      type: WeeklyProgressSummaryActionTypes.FETCH_ONE_WEEKLY_PROGRESS_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WeeklyProgressSummaryActionTypes.FETCH_ONE_WEEKLY_PROGRESS_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllWeeklyProgressSummarys() {
  yield takeLatest(WeeklyProgressSummaryActionTypes.FETCH_ALL_WEEKLY_PROGRESS_SUMMARY, fetchAllWeeklyProgressSummarys);
}

export function* watcherFetchOneWeeklyProgressSummarys() {
  yield takeLatest(WeeklyProgressSummaryActionTypes.FETCH_ONE_WEEKLY_PROGRESS_SUMMARY, fetchOneWeeklyProgressSummarys);
}
