import { Department } from "../Department/Department.type";
import { ManningCategory } from "../ManningCategory/ManningCategory.type";
import { ApiCallState } from "../Utils";

export type HRRequest = {
  id: number;
  date: string;
  manning_category_id: number;
  requested_by:number; 
  checked_by:number;
  hr_request_items: HRRequestItem[];
  hr_request_statuses: any;
  department: Department;
  manning_category: ManningCategory;
};

type HRRequestItem = {
  id: number;
  vacancy: string;
  education_level: string;
  profession: string;
  experience: string;
  work_place: string;
  in_no: number;
};

export type HRRequestStateTypes = {
  fetchAll: ApiCallState<HRRequest[]>;
  fetchOne: ApiCallState<HRRequest | {}>;
};

export const HRRequestActionTypes = {
  FETCH_ALL_HR_REQUEST: "FETCH_ALL_HR_REQUEST",
  FETCH_ALL_HR_REQUEST_RESET: "FETCH_ALL_HR_REQUEST_RESET",
  FETCH_ALL_HR_REQUEST_FAILURE: "FETCH_ALL_HR_REQUEST_FAILURE",
  FETCH_ALL_HR_REQUEST_SUCCESS: "FETCH_ALL_HR_REQUEST_SUCCESS",

  FETCH_ONE_HR_REQUEST: "FETCH_ONE_HR_REQUEST",
  FETCH_ONE_HR_REQUEST_RESET: "FETCH_ONE_HR_REQUEST_RESET",
  FETCH_ONE_HR_REQUEST_FAILURE: "FETCH_ONE_HR_REQUEST_FAILURE",
  FETCH_ONE_HR_REQUEST_SUCCESS: "FETCH_ONE_HR_REQUEST_SUCCESS",
};
