import { Project } from "../Project/Project.type";
import { ApiCallState } from "../Utils";

export type FuelRequestVoucher= {
  id: number;
  date: string;
  plate_no: string;
  remark: string;
  prepared_by_id: number;
  checked_by_id: number;
  approved_by_id: number;
  project_id: number;
  fuel_request_voucher_items: FuelRequestVoucherItems[],
  project: Project,
  is_checked: boolean,
  is_approved: boolean,
};

export type FuelRequestVoucherItems = {
    id?:number,
    description: string,
    unit: string,
    quantity: number,
    remark: string
}

export type FuelRequestVoucherStateTypes = {
  fetchAll: ApiCallState<FuelRequestVoucher[]>;
  fetchOne: ApiCallState<FuelRequestVoucher | {}>;
};

export const FuelRequestVoucherActionTypes = {
  FETCH_ALL_FUEL_REQUEST_VOUCHER: "FETCH_ALL_FUEL_REQUEST_VOUCHER",
  FETCH_ALL_FUEL_REQUEST_VOUCHER_RESET: "FETCH_ALL_FUEL_REQUEST_VOUCHER_RESET",
  FETCH_ALL_FUEL_REQUEST_VOUCHER_FAILURE: "FETCH_ALL_FUEL_REQUEST_VOUCHER_FAILURE",
  FETCH_ALL_FUEL_REQUEST_VOUCHER_SUCCESS: "FETCH_ALL_FUEL_REQUEST_VOUCHER_SUCCESS",

  FETCH_ONE_FUEL_REQUEST_VOUCHER: "FETCH_ONE_FUEL_REQUEST_VOUCHER",
  FETCH_ONE_FUEL_REQUEST_VOUCHER_RESET: "FETCH_ONE_FUEL_REQUEST_VOUCHER_RESET",
  FETCH_ONE_FUEL_REQUEST_VOUCHER_FAILURE: "FETCH_ONE_FUEL_REQUEST_VOUCHER_FAILURE",
  FETCH_ONE_FUEL_REQUEST_VOUCHER_SUCCESS: "FETCH_ONE_FUEL_REQUEST_VOUCHER_SUCCESS",
};
