import { MikadaWeeklyProgressStateTypes, MikadaWeeklyProgressActionTypes } from "./MikadaWeeklyProgress.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: MikadaWeeklyProgressStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const MikadaWeeklyProgressReducer = (
  state: MikadaWeeklyProgressStateTypes = INITIAL_STATE,
  action: any
): MikadaWeeklyProgressStateTypes => {
  switch (action.type) {
    case MikadaWeeklyProgressActionTypes.FETCH_ALL_MIKADA_WEEKLY_PROGRESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MikadaWeeklyProgressActionTypes.FETCH_ALL_MIKADA_WEEKLY_PROGRESS_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MikadaWeeklyProgressActionTypes.FETCH_ALL_MIKADA_WEEKLY_PROGRESS_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MikadaWeeklyProgressActionTypes.FETCH_ALL_MIKADA_WEEKLY_PROGRESS_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MikadaWeeklyProgressActionTypes.FETCH_ONE_MIKADA_WEEKLY_PROGRESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case MikadaWeeklyProgressActionTypes.FETCH_ONE_MIKADA_WEEKLY_PROGRESS_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MikadaWeeklyProgressActionTypes.FETCH_ONE_MIKADA_WEEKLY_PROGRESS_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MikadaWeeklyProgressActionTypes.FETCH_ONE_MIKADA_WEEKLY_PROGRESS_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default MikadaWeeklyProgressReducer;
