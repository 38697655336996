import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MaterialDailySiteReportActionTypes } from "./MaterialDailySiteReport.type";

export function* fetchAllMaterialDailySiteReports(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/material-daily-site-report?project_id=${action.payload?.project_id}&date=${action.payload?.date}`);
    yield put({
      type: MaterialDailySiteReportActionTypes.FETCH_ALL_MATERIAL_DAILY_SITE_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialDailySiteReportActionTypes.FETCH_ALL_MATERIAL_DAILY_SITE_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMaterialDailySiteReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/material-daily-site-report/date?project_id=${action.payload?.project_id}&date=${action.payload?.date}`
    );
    yield put({
      type: MaterialDailySiteReportActionTypes.FETCH_ONE_MATERIAL_DAILY_SITE_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialDailySiteReportActionTypes.FETCH_ONE_MATERIAL_DAILY_SITE_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMaterialDailySiteReports() {
  yield takeLatest(MaterialDailySiteReportActionTypes.FETCH_ALL_MATERIAL_DAILY_SITE_REPORT, fetchAllMaterialDailySiteReports);
}

export function* watcherFetchOneMaterialDailySiteReports() {
  yield takeLatest(MaterialDailySiteReportActionTypes.FETCH_ONE_MATERIAL_DAILY_SITE_REPORT, fetchOneMaterialDailySiteReports);
}
