import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MaterialLimitActionTypes } from "./MaterialLimit.type";

export function* fetchAllMaterialLimits(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/material-limit?project_id=${action.payload?.project_id}`);
    yield put({
      type: MaterialLimitActionTypes.FETCH_ALL_MATERIAL_LIMIT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialLimitActionTypes.FETCH_ALL_MATERIAL_LIMIT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMaterialLimits(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/material-limit/${action.payload}`
    );
    yield put({
      type: MaterialLimitActionTypes.FETCH_ONE_MATERIAL_LIMIT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialLimitActionTypes.FETCH_ONE_MATERIAL_LIMIT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMaterialLimits() {
  yield takeLatest(MaterialLimitActionTypes.FETCH_ALL_MATERIAL_LIMIT, fetchAllMaterialLimits);
}

export function* watcherFetchOneMaterialLimits() {
  yield takeLatest(MaterialLimitActionTypes.FETCH_ONE_MATERIAL_LIMIT, fetchOneMaterialLimits);
}
