import { ApiCallState } from "../Utils";

export type BoqItemGroup = {
  id: number;
  name: string;
};

export type BoqItemGroupStateTypes = {
  fetchAll: ApiCallState<BoqItemGroup[]>;
  fetchOne: ApiCallState<BoqItemGroup | {}>;
};

export const BoqItemGroupActionTypes = {
  FETCH_ALL_BOQ_ITEM_GROUP: "FETCH_ALL_BOQ_ITEM_GROUP",
  FETCH_ALL_BOQ_ITEM_GROUP_RESET: "FETCH_ALL_BOQ_ITEM_GROUP_RESET",
  FETCH_ALL_BOQ_ITEM_GROUP_FAILURE: "FETCH_ALL_BOQ_ITEM_GROUP_FAILURE",
  FETCH_ALL_BOQ_ITEM_GROUP_SUCCESS: "FETCH_ALL_BOQ_ITEM_GROUP_SUCCESS",

  FETCH_ONE_BOQ_ITEM_GROUP: "FETCH_ONE_BOQ_ITEM_GROUP",
  FETCH_ONE_BOQ_ITEM_GROUP_RESET: "FETCH_ONE_BOQ_ITEM_GROUP_RESET",
  FETCH_ONE_BOQ_ITEM_GROUP_FAILURE: "FETCH_ONE_BOQ_ITEM_GROUP_FAILURE",
  FETCH_ONE_BOQ_ITEM_GROUP_SUCCESS: "FETCH_ONE_BOQ_ITEM_GROUP_SUCCESS",
};
