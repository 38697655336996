import { DepartmentActionTypes } from "./Department.type";

/**
 * Fetch All Departments
 *
 * @param payload
 */
export const fetchAllDepartments = (payload?: any) => ({
  type: DepartmentActionTypes.FETCH_ALL_DEPARTMENT,
  payload: payload,
});

/**
 * Reset Fetch Departments State
 *
 * @param payload
 */
export const fetchAllDepartmentsReset = (payload?: any) => ({
  type: DepartmentActionTypes.FETCH_ALL_DEPARTMENT_RESET,
  payload: payload,
});
