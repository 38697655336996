import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { BudgetPlanningActionTypes } from "./BudgetPlanning.type";

export function* fetchAllBudgetPlannings(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/budget-planning?project_id=${action.payload?.project_id}`);
    yield put({
      type: BudgetPlanningActionTypes.FETCH_ALL_BUDGET_PLANNING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BudgetPlanningActionTypes.FETCH_ALL_BUDGET_PLANNING_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneBudgetPlannings(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/budget-planning/${action.payload}`
    );
    yield put({
      type: BudgetPlanningActionTypes.FETCH_ONE_BUDGET_PLANNING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BudgetPlanningActionTypes.FETCH_ONE_BUDGET_PLANNING_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllBudgetPlannings() {
  yield takeLatest(BudgetPlanningActionTypes.FETCH_ALL_BUDGET_PLANNING, fetchAllBudgetPlannings);
}

export function* watcherFetchOneBudgetPlannings() {
  yield takeLatest(BudgetPlanningActionTypes.FETCH_ONE_BUDGET_PLANNING, fetchOneBudgetPlannings);
}
