
import { Material } from "../Material/Material.type";
import { ApiCallState } from "../Utils";

export type UnitBreakdown = {
  id: number | null;
  boq_id: number;
  quantity: number;
  labour_output: number;
  equipment_output: number;
  overhead_cost: number;
  profit_cost: number;
  material_breakdowns: MaterialBreakdown[];
  equipment_breakdowns: EquipmentBreakdown[];
  labour_breakdowns: LabourBreakdown[];
  project_id: any;
  unit_price: number;
  key?: number;
};

export type MaterialBreakdown = {
  id: number;
  material_id: number;
  quantity: number;
  unit_breakdown_id: number;
  unit_price: number;
  material: Material;
  key?: number;
};
export type EquipmentBreakdown = {
  id: number;
  material_id: number;
  unit_breakdown_id: number;
  quantity: number;
  uf: number;
  rental_rate: number;
  material: Material;
  key?: number;
};
export type LabourBreakdown = {
  id: number;
  unit_breakdown_id: number;
  description: number;
  quantity: number;
  uf: number;
  hourly_cost: number;
  key?: number;
};

export type UnitBreakdownStateTypes = {
  fetchAllPre: ApiCallState<UnitBreakdown[]>;
  fetchAllPost: ApiCallState<UnitBreakdown[]>;
  fetchOnePre: ApiCallState<UnitBreakdown | {}>;
};

export const UnitBreakdownActionTypes = {
  FETCH_ALL_UNIT_BREAK_DOWN_PRE: "FETCH_ALL_UNIT_BREAK_DOWN_PRE",
  FETCH_ALL_UNIT_BREAK_DOWN_PRE_RESET: "FETCH_ALL_UNIT_BREAK_DOWN_PRE_RESET",
  FETCH_ALL_UNIT_BREAK_DOWN_PRE_FAILURE: "FETCH_ALL_UNIT_BREAK_DOWN_PRE_FAILURE",
  FETCH_ALL_UNIT_BREAK_DOWN_PRE_SUCCESS: "FETCH_ALL_UNIT_BREAK_DOWN_PRE_SUCCESS",

  FETCH_ALL_UNIT_BREAK_DOWN_POST: "FETCH_ALL_UNIT_BREAK_DOWN_POST",
  FETCH_ALL_UNIT_BREAK_DOWN_POST_RESET: "FETCH_ALL_UNIT_BREAK_DOWN_POST_RESET",
  FETCH_ALL_UNIT_BREAK_DOWN_POST_FAILURE: "FETCH_ALL_UNIT_BREAK_DOWN_POST_FAILURE",
  FETCH_ALL_UNIT_BREAK_DOWN_POST_SUCCESS: "FETCH_ALL_UNIT_BREAK_DOWN_POST_SUCCESS",

  FETCH_ONE_UNIT_BREAK_DOWN_PRE: "FETCH_ONE_UNIT_BREAK_DOWN_PRE",
  FETCH_ONE_UNIT_BREAK_DOWN_PRE_RESET: "FETCH_ONE_UNIT_BREAK_DOWN_PRE_RESET",
  FETCH_ONE_UNIT_BREAK_DOWN_PRE_FAILURE: "FETCH_ONE_UNIT_BREAK_DOWN_PRE_FAILURE",
  FETCH_ONE_UNIT_BREAK_DOWN_PRE_SUCCESS: "FETCH_ONE_UNIT_BREAK_DOWN_PRE_SUCCESS",
};
