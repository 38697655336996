import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { RebarActionTypes } from "./Rebar.type";

export function* fetchAllRebar(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/rebar?project_id=${action.payload.project_id}&approval=${action.payload.approval}&type=${action.payload.type}`
    );
    yield put({
      type: RebarActionTypes.FETCH_ALL_REBAR_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: RebarActionTypes.FETCH_ALL_REBAR_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllRebar() {
  yield takeLatest(RebarActionTypes.FETCH_ALL_REBAR, fetchAllRebar);
}
