import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PaymentFollowUpActionTypes } from "./PaymentFollowUp.type";

export function* fetchAllPaymentFollowUps(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/payment-follow-up`);
    yield put({
      type: PaymentFollowUpActionTypes.FETCH_ALL_PAYMENT_FOLLOW_UP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PaymentFollowUpActionTypes.FETCH_ALL_PAYMENT_FOLLOW_UP_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePaymentFollowUps(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/payment-follow-up/${action.payload}`
    );
    yield put({
      type: PaymentFollowUpActionTypes.FETCH_ONE_PAYMENT_FOLLOW_UP_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PaymentFollowUpActionTypes.FETCH_ONE_PAYMENT_FOLLOW_UP_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPaymentFollowUps() {
  yield takeLatest(PaymentFollowUpActionTypes.FETCH_ALL_PAYMENT_FOLLOW_UP, fetchAllPaymentFollowUps);
}

export function* watcherFetchOnePaymentFollowUps() {
  yield takeLatest(PaymentFollowUpActionTypes.FETCH_ONE_PAYMENT_FOLLOW_UP, fetchOnePaymentFollowUps);
}
