import { ApiCallState } from "../Utils";

export type StaffEducation = {
  id: number;

  user_id: number;
  staff_id: number;

  grade: string;
  school: string;
  place: string;
  field_of_study: string;

  year_from: string;
  year_to: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type StaffEducationStateTypes = {
  fetchAll: ApiCallState<StaffEducation[]>;
};

export const StaffEducationActionTypes = {
  FETCH_ALL_STAFF_EDUCATION: "FETCH_ALL_STAFF_EDUCATION",
  FETCH_ALL_STAFF_EDUCATION_RESET: "FETCH_ALL_STAFF_EDUCATION_RESET",
  FETCH_ALL_STAFF_EDUCATION_FAILURE: "FETCH_ALL_STAFF_EDUCATION_FAILURE",
  FETCH_ALL_STAFF_EDUCATION_SUCCESS: "FETCH_ALL_STAFF_EDUCATION_SUCCESS",
};
