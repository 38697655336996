import { ApiCallState } from "../Utils";

export type EquipmentCost= {
  id: number;
  start_date: string;
  end_date: string;
  project_id: number;
  equipment_cost_items: EquipmentCostItem[];
};

export type EquipmentCostItem = {
  equipment_type: string;
  plate_no: string;
  owner: string;
  hourly_rental_rate: number;
  previous_operational_hour: number;
  this_period_operational_hour: number;
  to_date_operational_hour: number;
  previous_payable_idle_hour: number;
  this_period_payable_idle_hour: number;
  to_date_payable_idle_hour: number;
  previous_non_payable_idle_hour: number;
  this_period_non_payable_idle_hour: number;
  to_date_non_payable_idle_hour: number;
  previous_down_hour: number;
  this_period_down_hour: number;
  to_date_down_hour: number;
  previous_equipment_cost: number;
  this_period_equipment_cost: number;
  to_date_equipment_cost: number;
}

export type EquipmentCostStateTypes = {
  fetchAll: ApiCallState<EquipmentCost[]>;
  fetchOne: ApiCallState<EquipmentCost | {}>;
};

export const EquipmentCostActionTypes = {
  FETCH_ALL_EQUIPMENT_COST: "FETCH_ALL_EQUIPMENT_COST",
  FETCH_ALL_EQUIPMENT_COST_RESET: "FETCH_ALL_EQUIPMENT_COST_RESET",
  FETCH_ALL_EQUIPMENT_COST_FAILURE: "FETCH_ALL_EQUIPMENT_COST_FAILURE",
  FETCH_ALL_EQUIPMENT_COST_SUCCESS: "FETCH_ALL_EQUIPMENT_COST_SUCCESS",

  FETCH_ONE_EQUIPMENT_COST: "FETCH_ONE_EQUIPMENT_COST",
  FETCH_ONE_EQUIPMENT_COST_RESET: "FETCH_ONE_EQUIPMENT_COST_RESET",
  FETCH_ONE_EQUIPMENT_COST_FAILURE: "FETCH_ONE_EQUIPMENT_COST_FAILURE",
  FETCH_ONE_EQUIPMENT_COST_SUCCESS: "FETCH_ONE_EQUIPMENT_COST_SUCCESS",
};
