import { InitPagedData } from "./../Utils";
import {
  TireRequestStateTypes,
  TireRequestActionTypes,
} from "./TireRequest.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: TireRequestStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchPaged: resetApiCallState(InitPagedData),
};

const TireRequestReducer = (
  state: TireRequestStateTypes = INITIAL_STATE,
  action: any
): TireRequestStateTypes => {
  switch (action.type) {
    case TireRequestActionTypes.FETCH_ALL_TIRE_REQUEST:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case TireRequestActionTypes.FETCH_ALL_TIRE_REQUEST_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case TireRequestActionTypes.FETCH_ALL_TIRE_REQUEST_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case TireRequestActionTypes.FETCH_ALL_TIRE_REQUEST_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };
    case TireRequestActionTypes.FETCH_PAGED_TIRE_REQUEST:
      return {
        ...state,
        fetchPaged: {
          error: null,
          payload: InitPagedData,
          isPending: true,
          isSuccessful: false,
        },
      };
    case TireRequestActionTypes.FETCH_PAGED_TIRE_REQUEST_RESET:
      return {
        ...state,
        fetchPaged: resetApiCallState(InitPagedData),
      };
    case TireRequestActionTypes.FETCH_PAGED_TIRE_REQUEST_FAILURE:
      return {
        ...state,
        fetchPaged: {
          payload: InitPagedData,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case TireRequestActionTypes.FETCH_PAGED_TIRE_REQUEST_SUCCESS:
      return {
        ...state,
        fetchPaged: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case TireRequestActionTypes.FETCH_ONE_TIRE_REQUEST:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case TireRequestActionTypes.FETCH_ONE_TIRE_REQUEST_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case TireRequestActionTypes.FETCH_ONE_TIRE_REQUEST_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case TireRequestActionTypes.FETCH_ONE_TIRE_REQUEST_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default TireRequestReducer;
