import { InitPagedData } from "./../Utils";
import { RGTSStateTypes, RGTSActionTypes } from "./RGTS.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: RGTSStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchPaged: resetApiCallState(InitPagedData),
};

const RGTSReducer = (
  state: RGTSStateTypes = INITIAL_STATE,
  action: any
): RGTSStateTypes => {
  switch (action.type) {
    case RGTSActionTypes.FETCH_ALL_RGTS:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case RGTSActionTypes.FETCH_ALL_RGTS_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case RGTSActionTypes.FETCH_ALL_RGTS_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case RGTSActionTypes.FETCH_ALL_RGTS_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };
    case RGTSActionTypes.FETCH_PAGED_RGTS:
      return {
        ...state,
        fetchPaged: {
          error: null,
          payload: InitPagedData,
          isPending: true,
          isSuccessful: false,
        },
      };
    case RGTSActionTypes.FETCH_PAGED_RGTS_RESET:
      return {
        ...state,
        fetchPaged: resetApiCallState(InitPagedData),
      };
    case RGTSActionTypes.FETCH_PAGED_RGTS_FAILURE:
      return {
        ...state,
        fetchPaged: {
          payload: InitPagedData,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case RGTSActionTypes.FETCH_PAGED_RGTS_SUCCESS:
      return {
        ...state,
        fetchPaged: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case RGTSActionTypes.FETCH_ONE_RGTS:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case RGTSActionTypes.FETCH_ONE_RGTS_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case RGTSActionTypes.FETCH_ONE_RGTS_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case RGTSActionTypes.FETCH_ONE_RGTS_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default RGTSReducer;
