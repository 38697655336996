import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PeridiumRequestActionTypes } from "./PeridiumRequest.type";

export function* fetchAllPeridiumRequests(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/peridium-request`);
    yield put({
      type: PeridiumRequestActionTypes.FETCH_ALL_PERIDIUM_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PeridiumRequestActionTypes.FETCH_ALL_PERIDIUM_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePeridiumRequests(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/peridium-request/${action.payload}`
    );
    yield put({
      type: PeridiumRequestActionTypes.FETCH_ONE_PERIDIUM_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PeridiumRequestActionTypes.FETCH_ONE_PERIDIUM_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPeridiumRequests() {
  yield takeLatest(PeridiumRequestActionTypes.FETCH_ALL_PERIDIUM_REQUEST, fetchAllPeridiumRequests);
}

export function* watcherFetchOnePeridiumRequests() {
  yield takeLatest(PeridiumRequestActionTypes.FETCH_ONE_PERIDIUM_REQUEST, fetchOnePeridiumRequests);
}
