import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../../ApiCall";
import { BankLoanPaymentScheduleActionTypes } from "./BankLoanPaymentSchedule.type";

export function* fetchAllBankLoanPaymentSchedules(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys
        .map((key) => {
          if (action.payload[key]) {
            return `${key}=${action.payload[key]}`;
          }
        })
        .join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/bank-loan-payment-schedule?${query}`);
    yield put({
      type: BankLoanPaymentScheduleActionTypes.FETCH_ALL_BANK_LOAN_PAYMENT_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BankLoanPaymentScheduleActionTypes.FETCH_ALL_BANK_LOAN_PAYMENT_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneBankLoanPaymentSchedule(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/bank-loan-payment-schedule/${action.payload}`
    );
    yield put({
      type: BankLoanPaymentScheduleActionTypes.FETCH_ONE_BANK_LOAN_PAYMENT_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BankLoanPaymentScheduleActionTypes.FETCH_ONE_BANK_LOAN_PAYMENT_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllBankLoanPaymentSchedules() {
  yield takeLatest(BankLoanPaymentScheduleActionTypes.FETCH_ALL_BANK_LOAN_PAYMENT_SCHEDULE, fetchAllBankLoanPaymentSchedules);
}

export function* watcherFetchOneBankLoanPaymentSchedules() {
  yield takeLatest(BankLoanPaymentScheduleActionTypes.FETCH_ONE_BANK_LOAN_PAYMENT_SCHEDULE, fetchOneBankLoanPaymentSchedule);
}
