import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type Dislocation= {
  id: number;
  year:string;
  month:string;
  user_id:number;
  dislocation_items:DislocationItem[];
};

export type DislocationItem ={
    id:number;
    dislocation_id:number;
    staff_id:number;
    dislocation_days:number;
    attendance:number;
    dislocation_allowance:number;
    staff:Staff;
}

export type DislocationStateTypes = {
  fetchAll: ApiCallState<Dislocation[]>;
  fetchOne: ApiCallState<Dislocation | {}>;
};

export const DislocationActionTypes = {
  FETCH_ALL_DISLOCATION: "FETCH_ALL_DISLOCATION",
  FETCH_ALL_DISLOCATION_RESET: "FETCH_ALL_DISLOCATION_RESET",
  FETCH_ALL_DISLOCATION_FAILURE: "FETCH_ALL_DISLOCATION_FAILURE",
  FETCH_ALL_DISLOCATION_SUCCESS: "FETCH_ALL_DISLOCATION_SUCCESS",

  FETCH_ONE_DISLOCATION: "FETCH_ONE_DISLOCATION",
  FETCH_ONE_DISLOCATION_RESET: "FETCH_ONE_DISLOCATION_RESET",
  FETCH_ONE_DISLOCATION_FAILURE: "FETCH_ONE_DISLOCATION_FAILURE",
  FETCH_ONE_DISLOCATION_SUCCESS: "FETCH_ONE_DISLOCATION_SUCCESS",
};
