import { SubContractRegistryStateTypes, SubContractRegistryActionTypes } from "./SubContractRegistry.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: SubContractRegistryStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const SubContractRegistryReducer = (
  state: SubContractRegistryStateTypes = INITIAL_STATE,
  action: any
): SubContractRegistryStateTypes => {
  switch (action.type) {
    case SubContractRegistryActionTypes.FETCH_ALL_SUB_CONTRACT_REGISTRY:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case SubContractRegistryActionTypes.FETCH_ALL_SUB_CONTRACT_REGISTRY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SubContractRegistryActionTypes.FETCH_ALL_SUB_CONTRACT_REGISTRY_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SubContractRegistryActionTypes.FETCH_ALL_SUB_CONTRACT_REGISTRY_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case SubContractRegistryActionTypes.FETCH_ONE_SUB_CONTRACT_REGISTRY:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case SubContractRegistryActionTypes.FETCH_ONE_SUB_CONTRACT_REGISTRY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SubContractRegistryActionTypes.FETCH_ONE_SUB_CONTRACT_REGISTRY_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SubContractRegistryActionTypes.FETCH_ONE_SUB_CONTRACT_REGISTRY_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default SubContractRegistryReducer;
