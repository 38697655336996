import { ApiCallState } from "../Utils";
import { Moment } from "moment";

export type FuelUtilization= {
    id: number | null;
    project_id:number | null;
    week:string;
    date_range:string;
    utilized_no:string;
    total_gasoil:number;
    total_benzene:number;
    fuel_utilization_details:FuelUtilizationDetail[];
};

export type FuelUtilizationDetail= {
    id: number | null;
    fuel_utilization_id:number | null;
    type_of_equipment:string;
    plate_no:string;
    fuel_type:string;
    planned_working:number;
    planned_fuel:number;
    actual_worked:number;
    actual_utilized_fuel:number;
    actual_executed_activity:string;
}

export type FuelUtilizationStateTypes = {
  fetchAll: ApiCallState<FuelUtilization[]>;
  fetchOne: ApiCallState<FuelUtilization | {}>;
};

export const FuelUtilizationActionTypes = {
  FETCH_ALL_FUEL_UTILIZATION: "FETCH_ALL_FUEL_UTILIZATION",
  FETCH_ALL_FUEL_UTILIZATION_RESET: "FETCH_ALL_FUEL_UTILIZATION_RESET",
  FETCH_ALL_FUEL_UTILIZATION_FAILURE: "FETCH_ALL_FUEL_UTILIZATION_FAILURE",
  FETCH_ALL_FUEL_UTILIZATION_SUCCESS: "FETCH_ALL_FUEL_UTILIZATION_SUCCESS",

  FETCH_ONE_FUEL_UTILIZATION: "FETCH_ONE_FUEL_UTILIZATION",
  FETCH_ONE_FUEL_UTILIZATION_RESET: "FETCH_ONE_FUEL_UTILIZATION_RESET",
  FETCH_ONE_FUEL_UTILIZATION_FAILURE: "FETCH_ONE_FUEL_UTILIZATION_FAILURE",
  FETCH_ONE_FUEL_UTILIZATION_SUCCESS: "FETCH_ONE_FUEL_UTILIZATION_SUCCESS",
};
