import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ChartsOfAccountActionTypes } from "./ChartsOfAccount.type";

export function* fetchAllChartsOfAccounts(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/charts-of-account`);
    yield put({
      type: ChartsOfAccountActionTypes.FETCH_ALL_CHARTS_OF_ACCOUNT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ChartsOfAccountActionTypes.FETCH_ALL_CHARTS_OF_ACCOUNT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneChartsOfAccounts(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/charts-of-account/${action.payload}`
    );
    yield put({
      type: ChartsOfAccountActionTypes.FETCH_ONE_CHARTS_OF_ACCOUNT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ChartsOfAccountActionTypes.FETCH_ONE_CHARTS_OF_ACCOUNT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllChartsOfAccounts() {
  yield takeLatest(ChartsOfAccountActionTypes.FETCH_ALL_CHARTS_OF_ACCOUNT, fetchAllChartsOfAccounts);
}

export function* watcherFetchOneChartsOfAccounts() {
  yield takeLatest(ChartsOfAccountActionTypes.FETCH_ONE_CHARTS_OF_ACCOUNT, fetchOneChartsOfAccounts);
}
