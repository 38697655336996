import { formatQuery } from "./../Utils";
import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { FuelIssueActionTypes } from "./FuelIssue.type";

export function* fetchAllFuelIssues(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/fuel-issue?${query}`);
    yield put({
      type: FuelIssueActionTypes.FETCH_ALL_FUEL_ISSUE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FuelIssueActionTypes.FETCH_ALL_FUEL_ISSUE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchReportFuelIssues(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/fuel-issue/report?${formatQuery(action)}`
    );
    yield put({
      type: FuelIssueActionTypes.FETCH_REPORT_FUEL_ISSUE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FuelIssueActionTypes.FETCH_REPORT_FUEL_ISSUE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedFuelIssues(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/fuel-issue?${query}`);
    yield put({
      type: FuelIssueActionTypes.FETCH_PAGED_FUEL_ISSUE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FuelIssueActionTypes.FETCH_PAGED_FUEL_ISSUE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneFuelIssues(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/fuel-issue/${action.payload}`
    );
    yield put({
      type: FuelIssueActionTypes.FETCH_ONE_FUEL_ISSUE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FuelIssueActionTypes.FETCH_ONE_FUEL_ISSUE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllFuelIssues() {
  yield takeLatest(
    FuelIssueActionTypes.FETCH_ALL_FUEL_ISSUE,
    fetchAllFuelIssues
  );
}

export function* watcherFetchReportFuelIssues() {
  yield takeLatest(
    FuelIssueActionTypes.FETCH_REPORT_FUEL_ISSUE,
    fetchReportFuelIssues
  );
}

export function* watcherFetchPagedFuelIssues() {
  yield takeLatest(
    FuelIssueActionTypes.FETCH_PAGED_FUEL_ISSUE,
    fetchPagedFuelIssues
  );
}

export function* watcherFetchOneFuelIssues() {
  yield takeLatest(
    FuelIssueActionTypes.FETCH_ONE_FUEL_ISSUE,
    fetchOneFuelIssues
  );
}
