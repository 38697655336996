import { Staff } from "../Staff/Staff.type";
import { ApiCallState } from "../Utils";

export type TransferRequest= {
  id: number;
  date:string;
  staff_id:number;
  transfer_to:string;
  reason:string;
  requested_by:number;
  requested_by_department:string;
  approved_by:number;
  authorized_by:number;
  user_id:number;
  staff:Staff;
  transfer_request_statuses: TransferRequestStatus[];
};

export type TransferRequestStatus = {
  transfer_request_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type TransferRequestStateTypes = {
  fetchAll: ApiCallState<TransferRequest[]>;
  fetchOne: ApiCallState<TransferRequest | {}>;
};

export const TransferRequestActionTypes = {
  FETCH_ALL_TRANSFER_REQUEST: "FETCH_ALL_TRANSFER_REQUEST",
  FETCH_ALL_TRANSFER_REQUEST_RESET: "FETCH_ALL_TRANSFER_REQUEST_RESET",
  FETCH_ALL_TRANSFER_REQUEST_FAILURE: "FETCH_ALL_TRANSFER_REQUEST_FAILURE",
  FETCH_ALL_TRANSFER_REQUEST_SUCCESS: "FETCH_ALL_TRANSFER_REQUEST_SUCCESS",

  FETCH_ONE_TRANSFER_REQUEST: "FETCH_ONE_TRANSFER_REQUEST",
  FETCH_ONE_TRANSFER_REQUEST_RESET: "FETCH_ONE_TRANSFER_REQUEST_RESET",
  FETCH_ONE_TRANSFER_REQUEST_FAILURE: "FETCH_ONE_TRANSFER_REQUEST_FAILURE",
  FETCH_ONE_TRANSFER_REQUEST_SUCCESS: "FETCH_ONE_TRANSFER_REQUEST_SUCCESS",
};
