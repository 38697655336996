import { ApiCallState } from "../../Utils";
import {BankLoan} from "../BankLoan/BankLoan.type";
import {BankLoanPayment} from "../BankLoanPayment/BankLoanPayment.type";

export type BankLoanPaymentSchedule = {
  id: number;
  is_title:boolean;
  year:string;
  payment_month: Date;
  principal: number;
  interest: number;
  outstanding_balance:number;
  paid: number;
  status: string;
  bank_loan_id: number;
  bank_loan?: BankLoan;
  bank_loan_payments?: BankLoanPayment[];
};

export type BankLoanPaymentScheduleStateTypes = {
  fetchAll: ApiCallState<BankLoanPaymentSchedule[]>;
  fetchOne: ApiCallState<BankLoanPaymentSchedule | {}>;
};

export const BankLoanPaymentScheduleActionTypes = {
  FETCH_ALL_BANK_LOAN_PAYMENT_SCHEDULE: "FETCH_ALL_BANK_LOAN_PAYMENT_SCHEDULE",
  FETCH_ALL_BANK_LOAN_PAYMENT_SCHEDULE_RESET: "FETCH_ALL_BANK_LOAN_PAYMENT_SCHEDULE_RESET",
  FETCH_ALL_BANK_LOAN_PAYMENT_SCHEDULE_FAILURE: "FETCH_ALL_BANK_LOAN_PAYMENT_SCHEDULE_FAILURE",
  FETCH_ALL_BANK_LOAN_PAYMENT_SCHEDULE_SUCCESS: "FETCH_ALL_BANK_LOAN_PAYMENT_SCHEDULE_SUCCESS",

  FETCH_ONE_BANK_LOAN_PAYMENT_SCHEDULE: "FETCH_ONE_BANK_LOAN_PAYMENT_SCHEDULE",
  FETCH_ONE_BANK_LOAN_PAYMENT_SCHEDULE_RESET: "FETCH_ONE_BANK_LOAN_PAYMENT_SCHEDULE_RESET",
  FETCH_ONE_BANK_LOAN_PAYMENT_SCHEDULE_FAILURE: "FETCH_ONE_BANK_LOAN_PAYMENT_SCHEDULE_FAILURE",
  FETCH_ONE_BANK_LOAN_PAYMENT_SCHEDULE_SUCCESS: "FETCH_ONE_BANK_LOAN_PAYMENT_SCHEDULE_SUCCESS",
};
