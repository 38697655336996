import {
  ContractAdministrationStateTypes,
  ContractAdministrationActionTypes,
} from "./ContractAdministration.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ContractAdministrationStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const ContractAdministrationReducer = (
  state: ContractAdministrationStateTypes = INITIAL_STATE,
  action: any
): ContractAdministrationStateTypes => {
  switch (action.type) {
    case ContractAdministrationActionTypes.FETCH_ALL_CONTRACT_ADMINISTRATION:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ContractAdministrationActionTypes.FETCH_ALL_CONTRACT_ADMINISTRATION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ContractAdministrationActionTypes.FETCH_ALL_CONTRACT_ADMINISTRATION_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ContractAdministrationActionTypes.FETCH_ALL_CONTRACT_ADMINISTRATION_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ContractAdministrationActionTypes.FETCH_ONE_CONTRACT_ADMINISTRATION:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ContractAdministrationActionTypes.FETCH_ONE_CONTRACT_ADMINISTRATION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ContractAdministrationActionTypes.FETCH_ONE_CONTRACT_ADMINISTRATION_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ContractAdministrationActionTypes.FETCH_ONE_CONTRACT_ADMINISTRATION_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ContractAdministrationReducer;
