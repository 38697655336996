import { ApiCallState } from "../Utils";

export type ManningCategory= {
  id: number;
  description: string
};

export type ManningCategoryStateTypes = {
  fetchAll: ApiCallState<ManningCategory[]>;
  fetchOne: ApiCallState<ManningCategory | {}>;
};

export const ManningCategoryActionTypes = {
  FETCH_ALL_MANNING_CATEGORY: "FETCH_ALL_MANNING_CATEGORY",
  FETCH_ALL_MANNING_CATEGORY_RESET: "FETCH_ALL_MANNING_CATEGORY_RESET",
  FETCH_ALL_MANNING_CATEGORY_FAILURE: "FETCH_ALL_MANNING_CATEGORY_FAILURE",
  FETCH_ALL_MANNING_CATEGORY_SUCCESS: "FETCH_ALL_MANNING_CATEGORY_SUCCESS",

  FETCH_ONE_MANNING_CATEGORY: "FETCH_ONE_MANNING_CATEGORY",
  FETCH_ONE_MANNING_CATEGORY_RESET: "FETCH_ONE_MANNING_CATEGORY_RESET",
  FETCH_ONE_MANNING_CATEGORY_FAILURE: "FETCH_ONE_MANNING_CATEGORY_FAILURE",
  FETCH_ONE_MANNING_CATEGORY_SUCCESS: "FETCH_ONE_MANNING_CATEGORY_SUCCESS",
};
