import { ApiCallState } from "../Utils";

export type StaffWorkExperience= {
  id: number;
  staff_id:number;
  company_name:string;
  location:string;
  role:string;
  start_date:Date;
  end_date:Date;
  reason_of_unemployment:string;
  user_id:number;
};

export type StaffWorkExperienceStateTypes = {
  fetchAll: ApiCallState<StaffWorkExperience[]>;
  fetchOne: ApiCallState<StaffWorkExperience | {}>;
};

export const StaffWorkExperienceActionTypes = {
  FETCH_ALL_STAFF_WORK_EXPERIENCE: "FETCH_ALL_STAFF_WORK_EXPERIENCE",
  FETCH_ALL_STAFF_WORK_EXPERIENCE_RESET: "FETCH_ALL_STAFF_WORK_EXPERIENCE_RESET",
  FETCH_ALL_STAFF_WORK_EXPERIENCE_FAILURE: "FETCH_ALL_STAFF_WORK_EXPERIENCE_FAILURE",
  FETCH_ALL_STAFF_WORK_EXPERIENCE_SUCCESS: "FETCH_ALL_STAFF_WORK_EXPERIENCE_SUCCESS",

  FETCH_ONE_STAFF_WORK_EXPERIENCE: "FETCH_ONE_STAFF_WORK_EXPERIENCE",
  FETCH_ONE_STAFF_WORK_EXPERIENCE_RESET: "FETCH_ONE_STAFF_WORK_EXPERIENCE_RESET",
  FETCH_ONE_STAFF_WORK_EXPERIENCE_FAILURE: "FETCH_ONE_STAFF_WORK_EXPERIENCE_FAILURE",
  FETCH_ONE_STAFF_WORK_EXPERIENCE_SUCCESS: "FETCH_ONE_STAFF_WORK_EXPERIENCE_SUCCESS",
};
