import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { formatQuery } from "../Utils";
import { FuelReceivingActionTypes } from "./FuelReceiving.type";

export function* fetchAllFuelReceiving(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/fuel-receiving?${formatQuery(action)}`
    );
    yield put({
      type: FuelReceivingActionTypes.FETCH_ALL_FUEL_RECEIVING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FuelReceivingActionTypes.FETCH_ALL_FUEL_RECEIVING_FAILURE,
      payload: error,
    });
  }
}

export function* fetchFuelReceivingReports(action: any): any {
  try {
    let query: any = "";

    if (action?.payload && Object.keys(action?.payload).length > 0) {
      query += Object.keys(action?.payload)
        .map((key) => `${key}=${action?.payload[key]}`)
        .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/fuel-receiving/report?${query}`
    );
    yield put({
      type: FuelReceivingActionTypes.FETCH_FUEL_RECEIVING_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FuelReceivingActionTypes.FETCH_FUEL_RECEIVING_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllFuelReceiving() {
  yield takeLatest(
    FuelReceivingActionTypes.FETCH_ALL_FUEL_RECEIVING,
    fetchAllFuelReceiving
  );
}

export function* watcherFetchFuelReceivingReports() {
  yield takeLatest(
    FuelReceivingActionTypes.FETCH_FUEL_RECEIVING_REPORT,
    fetchFuelReceivingReports
  );
}
