import { MasterList } from "../MasterList/MasterList.type";
import { Store } from "../Store/Store.type";
import { ApiCallState } from "../Utils";

export type FuelReceive = {
  id: number;

  user_id: number;
  store_id: number;
  master_list_id: number;
  project_manager_id: number;

  ref_number: number;
  date: Date;
  driver: string;
  file_number: string;
  vehicle_arrived_datetime: string;
  fuel_quantity: number;
  total_amount: number;

  has_valid_receipt: string;
  has_legal_calibration: string;
  has_leveled_over_hour: string;
  is_fuel_chemically_tested: string;
  is_fuel_quantity_measured: string;
  is_fuel_fully_offloaded: string;
  does_fuel_quantity_differ: string;
  has_committee_signed_receipt: string;

  committee_members: number[];

  readonly createdAt: Date;
  readonly updatedAt: Date;

  master_list: MasterList;
  store: Store;
  fuel_receive_statuses: FuelReceiveStatus[];
};

export type FuelReceiveStatus = {
  id: number;
  user_id: number;
  fuel_receive_id: number;
  status: number;
  type: "View" | "Check" | "Approve";
  assigned_by: number;
  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type FuelReceiveStateTypes = {
  fetchAll: ApiCallState<FuelReceive[]>;
  fetchOne: ApiCallState<FuelReceive | {}>;
  fetchNumber: ApiCallState<number | null>;
};

export const FuelReceiveActionTypes = {
  FETCH_ALL_FUEL_RECEIVE: "FETCH_ALL_FUEL_RECEIVE",
  FETCH_ALL_FUEL_RECEIVE_RESET: "FETCH_ALL_FUEL_RECEIVE_RESET",
  FETCH_ALL_FUEL_RECEIVE_FAILURE: "FETCH_ALL_FUEL_RECEIVE_FAILURE",
  FETCH_ALL_FUEL_RECEIVE_SUCCESS: "FETCH_ALL_FUEL_RECEIVE_SUCCESS",

  FETCH_ONE_FUEL_RECEIVE: "FETCH_ONE_FUEL_RECEIVE",
  FETCH_ONE_FUEL_RECEIVE_RESET: "FETCH_ONE_FUEL_RECEIVE_RESET",
  FETCH_ONE_FUEL_RECEIVE_FAILURE: "FETCH_ONE_FUEL_RECEIVE_FAILURE",
  FETCH_ONE_FUEL_RECEIVE_SUCCESS: "FETCH_ONE_FUEL_RECEIVE_SUCCESS",

  FETCH_FUEL_RECEIVE_NUMBER: "FETCH_FUEL_RECEIVE_NUMBER",
  FETCH_FUEL_RECEIVE_NUMBER_RESET: "FETCH_FUEL_RECEIVE_NUMBER_RESET",
  FETCH_FUEL_RECEIVE_NUMBER_FAILURE: "FETCH_FUEL_RECEIVE_NUMBER_FAILURE",
  FETCH_FUEL_RECEIVE_NUMBER_SUCCESS: "FETCH_FUEL_RECEIVE_NUMBER_SUCCESS",
};
