import { TemporaryDeliveryNoteStateTypes, TemporaryDeliveryNoteActionTypes } from "./TemporaryDeliveryNote.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: TemporaryDeliveryNoteStateTypes = {
  fetchOne: resetApiCallState({}),
  fetchAll: resetApiCallState([]),
  fetchReport: resetApiCallState([]),
};

const TemporaryDeliveryNoteReducer = (
  state: TemporaryDeliveryNoteStateTypes = INITIAL_STATE,
  action: any
): TemporaryDeliveryNoteStateTypes => {
  switch (action.type) {
    case TemporaryDeliveryNoteActionTypes.FETCH_ONE_TEMPORARY_DELIVERY_NOTE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case TemporaryDeliveryNoteActionTypes.FETCH_ONE_TEMPORARY_DELIVERY_NOTE_RESET:
      return {
        ...state,
        fetchOne: resetApiCallState({}),
      };
    case TemporaryDeliveryNoteActionTypes.FETCH_ONE_TEMPORARY_DELIVERY_NOTE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case TemporaryDeliveryNoteActionTypes.FETCH_ONE_TEMPORARY_DELIVERY_NOTE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };
    case TemporaryDeliveryNoteActionTypes.FETCH_ALL_TEMPORARY_DELIVERY_NOTE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case TemporaryDeliveryNoteActionTypes.FETCH_ALL_TEMPORARY_DELIVERY_NOTE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case TemporaryDeliveryNoteActionTypes.FETCH_ALL_TEMPORARY_DELIVERY_NOTE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case TemporaryDeliveryNoteActionTypes.FETCH_ALL_TEMPORARY_DELIVERY_NOTE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case TemporaryDeliveryNoteActionTypes.FETCH_REPORT_TEMPORARY_DELIVERY_NOTE:
      return {
        ...state,
        fetchReport: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case TemporaryDeliveryNoteActionTypes.FETCH_REPORT_TEMPORARY_DELIVERY_NOTE_RESET:
      return {
        ...state,
        fetchReport: resetApiCallState([]),
      };
    case TemporaryDeliveryNoteActionTypes.FETCH_REPORT_TEMPORARY_DELIVERY_NOTE_FAILURE:
      return {
        ...state,
        fetchReport: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case TemporaryDeliveryNoteActionTypes.FETCH_REPORT_TEMPORARY_DELIVERY_NOTE_SUCCESS:
      return {
        ...state,
        fetchReport: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default TemporaryDeliveryNoteReducer;
