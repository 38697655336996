import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { formatQuery } from "../Utils";
import { InventoryActionTypes } from "./Inventory.type";

export function* fetchAllInventories(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/inventory?${query}`);
    yield put({
      type: InventoryActionTypes.FETCH_ALL_INVENTORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: InventoryActionTypes.FETCH_ALL_INVENTORY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchAllQueriedInventories(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const material_ids = action?.payload?.material_ids || [];
      delete action.payload?.material_ids;

      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");

      if (material_ids.length > 0)
        query +=
          "&" +
          material_ids
            .map((material_id: any) => `material_ids=${material_id}`)
            .join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/inventory/queried?${query}`
    );
    yield put({
      type: InventoryActionTypes.FETCH_ALL_QUERIED_INVENTORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: InventoryActionTypes.FETCH_ALL_QUERIED_INVENTORY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneInventory(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/inventory/${action.payload}`
    );
    yield put({
      type: InventoryActionTypes.FETCH_ONE_INVENTORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: InventoryActionTypes.FETCH_ONE_INVENTORY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchAnalysisInventories(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/inventory/analysis?${formatQuery(action)}`
    );
    yield put({
      type: InventoryActionTypes.FETCH_ANALYSIS_INVENTORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: InventoryActionTypes.FETCH_ANALYSIS_INVENTORY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchUnpagedAnalysisInventories(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/inventory/analysis-unpaged?${formatQuery(action)}`
    );
    yield put({
      type: InventoryActionTypes.FETCH_UNPAGED_ANALYSIS_INVENTORY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: InventoryActionTypes.FETCH_UNPAGED_ANALYSIS_INVENTORY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchInventoryStock(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/inventory/stock-card?${formatQuery(action)}`
    );
    yield put({
      type: InventoryActionTypes.FETCH_INVENTORY_STOCK_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: InventoryActionTypes.FETCH_INVENTORY_STOCK_FAILURE,
      payload: error,
    });
  }
}
export function* fetchInventoryStockReport(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/inventory/stock-report?${formatQuery(action)}`
    );
    yield put({
      type: InventoryActionTypes.FETCH_INVENTORY_STOCK_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: InventoryActionTypes.FETCH_INVENTORY_STOCK_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllInventories() {
  yield takeLatest(
    InventoryActionTypes.FETCH_ALL_INVENTORY,
    fetchAllInventories
  );
}

export function* watcherFetchAllQueriedInventories() {
  yield takeLatest(
    InventoryActionTypes.FETCH_ALL_QUERIED_INVENTORY,
    fetchAllQueriedInventories
  );
}

export function* watcherFetchOneInventory() {
  yield takeLatest(InventoryActionTypes.FETCH_ONE_INVENTORY, fetchOneInventory);
}

export function* watcherFetchAnalysisInventory() {
  yield takeLatest(
    InventoryActionTypes.FETCH_ANALYSIS_INVENTORY,
    fetchAnalysisInventories
  );
}

export function* watcherUnpagedFetchAnalysisInventory() {
  yield takeLatest(
    InventoryActionTypes.FETCH_UNPAGED_ANALYSIS_INVENTORY,
    fetchUnpagedAnalysisInventories
  );
}

export function* watcherFetchInventoryStockCard() {
  yield takeLatest(
    InventoryActionTypes.FETCH_INVENTORY_STOCK_CARD,
    fetchInventoryStock
  );
}
export function* watcherFetchInventoryStockReport() {
  yield takeLatest(
    InventoryActionTypes.FETCH_INVENTORY_STOCK_REPORT,
    fetchInventoryStockReport
  );
}
