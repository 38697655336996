import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { DetailReportActivityActionTypes } from "./DetailReportActivity.type";

export function* fetchAllDetailReportActivitys(action: any): any {
  try { 
    const response = yield axios.get(`${API_BASE_URI}/detail-report-activity?project_id=${action.payload?.project_id}`);
    yield put({
      type: DetailReportActivityActionTypes.FETCH_ALL_DETAIL_REPORT_ACTIVITY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DetailReportActivityActionTypes.FETCH_ALL_DETAIL_REPORT_ACTIVITY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneDetailReportActivitys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/detail-report-activity/${action.payload}`
    );
    yield put({
      type: DetailReportActivityActionTypes.FETCH_ONE_DETAIL_REPORT_ACTIVITY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DetailReportActivityActionTypes.FETCH_ONE_DETAIL_REPORT_ACTIVITY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllDetailReportActivitys() {
  yield takeLatest(DetailReportActivityActionTypes.FETCH_ALL_DETAIL_REPORT_ACTIVITY, fetchAllDetailReportActivitys);
}

export function* watcherFetchOneDetailReportActivitys() {
  yield takeLatest(DetailReportActivityActionTypes.FETCH_ONE_DETAIL_REPORT_ACTIVITY, fetchOneDetailReportActivitys);
}
