import { ApiCallState } from "../Utils";

export type Offer = {
  id: number;
  name: string;
};

export type OfferStateTypes = {
  fetchAll: ApiCallState<Offer[]>;
  fetchOne: ApiCallState<Offer | {}>;
};

export const OfferActionTypes = {
  FETCH_ALL_OFFER: "FETCH_ALL_OFFER",
  FETCH_ALL_OFFER_RESET: "FETCH_ALL_OFFER_RESET",
  FETCH_ALL_OFFER_FAILURE: "FETCH_ALL_OFFER_FAILURE",
  FETCH_ALL_OFFER_SUCCESS: "FETCH_ALL_OFFER_SUCCESS",

  FETCH_ONE_OFFER: "FETCH_ONE_OFFER",
  FETCH_ONE_OFFER_RESET: "FETCH_ONE_OFFER_RESET",
  FETCH_ONE_OFFER_FAILURE: "FETCH_ONE_OFFER_FAILURE",
  FETCH_ONE_OFFER_SUCCESS: "FETCH_ONE_OFFER_SUCCESS",
};
