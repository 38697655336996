import { PartnerStateTypes, PartnerActionTypes } from "./Partner.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: PartnerStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const PartnerReducer = (
  state: PartnerStateTypes = INITIAL_STATE,
  action: any
): PartnerStateTypes => {
  switch (action.type) {
    case PartnerActionTypes.FETCH_ALL_PARTNER:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case PartnerActionTypes.FETCH_ALL_PARTNER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case PartnerActionTypes.FETCH_ALL_PARTNER_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PartnerActionTypes.FETCH_ALL_PARTNER_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case PartnerActionTypes.FETCH_ONE_PARTNER:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case PartnerActionTypes.FETCH_ONE_PARTNER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState({}),
      };
    case PartnerActionTypes.FETCH_ONE_PARTNER_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case PartnerActionTypes.FETCH_ONE_PARTNER_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default PartnerReducer;
