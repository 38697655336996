import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { EquipmentListActionTypes } from "./EquipmentList.type";

export function* fetchAllEquipmentLists(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/equipment-list`);
    yield put({
      type: EquipmentListActionTypes.FETCH_ALL_EQUIPMENT_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EquipmentListActionTypes.FETCH_ALL_EQUIPMENT_LIST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneEquipmentLists(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/equipment-list/${action.payload}`
    );
    yield put({
      type: EquipmentListActionTypes.FETCH_ONE_EQUIPMENT_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EquipmentListActionTypes.FETCH_ONE_EQUIPMENT_LIST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllEquipmentLists() {
  yield takeLatest(EquipmentListActionTypes.FETCH_ALL_EQUIPMENT_LIST, fetchAllEquipmentLists);
}

export function* watcherFetchOneEquipmentLists() {
  yield takeLatest(EquipmentListActionTypes.FETCH_ONE_EQUIPMENT_LIST, fetchOneEquipmentLists);
}
