import { OwnVehicle } from "../OwnVehicle/OwnVehicle.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type DailyStatusReportItem = {
  id: number;
  type: string;
  description: string;
  year: string;
  model: string;
  plate_no: string;
  supplier_name: string;
  status: string;
  down_date: string;
  problem_reason: string;
  idle_reason: string;
  driver_name: string;
  driver_phone_number: string;
  remark: string;
  own_vehicle_id: number;
  daily_status_report_id: number;
  own_vehicle: OwnVehicle;
};

export type DailyStatusDownReport = {
  id: number;
  type: string;
  description: string;
  year: string;
  model: string;
  plate_no: string;
  supplier_name: string;
  status: string;
  problem_reason: string;
  idle_reason: string;
  driver_name: string;
  driver_phone_number: string;
  remark: string;
  own_vehicle_id: number;
  daily_status_report_id: number;
  own_vehicle: OwnVehicle;
  down_date: string;
  down_days: number;
};

export type DailyStatusReport = {
  id: number;
  date: string;
  prepared_by_id: string;
  user: User;
  project_id: number;
  daily_status_report_items: DailyStatusReportItem[];
};

export const DailyStatusReportType = {
  machinery: "Machinery",
  vehicle: "Vehicle",
  auxiliary: "Auxiliary",
  plant: "Plant",
};

export type DailyStatusReportStateTypes = {
  fetchAll: ApiCallState<DailyStatusReport[]>;
  fetchReport: ApiCallState<DailyStatusDownReport[]>;
};

export const DailyStatusReportActionTypes = {
  FETCH_ALL_DAILY_STATUS_REPORT: "FETCH_ALL_DAILY_STATUS_REPORT",
  FETCH_ALL_DAILY_STATUS_REPORT_RESET: "FETCH_ALL_DAILY_STATUS_REPORT_RESET",
  FETCH_ALL_DAILY_STATUS_REPORT_FAILURE:
    "FETCH_ALL_DAILY_STATUS_REPORT_FAILURE",
  FETCH_ALL_DAILY_STATUS_REPORT_SUCCESS:
    "FETCH_ALL_DAILY_STATUS_REPORT_SUCCESS",

  FETCH_REPORT_DAILY_STATUS_REPORT: "FETCH_REPORT_DAILY_STATUS_REPORT",
  FETCH_REPORT_DAILY_STATUS_REPORT_RESET:
    "FETCH_REPORT_DAILY_STATUS_REPORT_RESET",
  FETCH_REPORT_DAILY_STATUS_REPORT_FAILURE:
    "FETCH_REPORT_DAILY_STATUS_REPORT_FAILURE",
  FETCH_REPORT_DAILY_STATUS_REPORT_SUCCESS:
    "FETCH_REPORT_DAILY_STATUS_REPORT_SUCCESS",
};
