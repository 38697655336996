import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { BoqPlanActionTypes } from "./BoqPlan.type";

export function* fetchAllBoqPlans(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/boq_plan?project_id=${action.payload.project_id}&date=${action.payload.date}&date_type=${action.payload.date_type}`
    );
    yield put({
      type: BoqPlanActionTypes.FETCH_ALL_BOQ_PLAN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BoqPlanActionTypes.FETCH_ALL_BOQ_PLAN_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllBoqPlans() {
  yield takeLatest(BoqPlanActionTypes.FETCH_ALL_BOQ_PLAN, fetchAllBoqPlans);
}
