import { ApiCallState } from "../Utils";

export type ProjectList = {
  id: number;
  contact_person: string;
  phone: string;
  remark: string;
  supplementary_contracts: SupplementaryContract[];
  payments_takens: PaymentsTaken[];
};

export type SupplementaryContract = {
  id?: number;
  description: string;
  amount: number;
};

export type PaymentsTaken = {
  id?: number;
  description: string;
  amount: number;
};

export type ProjectListStateTypes = {
  fetchAll: ApiCallState<ProjectList[]>;
  fetchOne: ApiCallState<ProjectList | {}>;
};

export const ProjectListActionTypes = {
  FETCH_ALL_PROJECT_LIST: "FETCH_ALL_PROJECT_LIST",
  FETCH_ALL_PROJECT_LIST_RESET: "FETCH_ALL_PROJECT_LIST_RESET",
  FETCH_ALL_PROJECT_LIST_FAILURE: "FETCH_ALL_PROJECT_LIST_FAILURE",
  FETCH_ALL_PROJECT_LIST_SUCCESS: "FETCH_ALL_PROJECT_LIST_SUCCESS",

  FETCH_ONE_PROJECT_LIST: "FETCH_ONE_PROJECT_LIST",
  FETCH_ONE_PROJECT_LIST_RESET: "FETCH_ONE_PROJECT_LIST_RESET",
  FETCH_ONE_PROJECT_LIST_FAILURE: "FETCH_ONE_PROJECT_LIST_FAILURE",
  FETCH_ONE_PROJECT_LIST_SUCCESS: "FETCH_ONE_PROJECT_LIST_SUCCESS",
};
