import {
  DailyWorkStatusStateTypes,
  DailyWorkStatusActionTypes,
} from "./DailyWorkStatus.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: DailyWorkStatusStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const DailyWorkStatusReducer = (
  state: DailyWorkStatusStateTypes = INITIAL_STATE,
  action: any
): DailyWorkStatusStateTypes => {
  switch (action.type) {
    case DailyWorkStatusActionTypes.FETCH_ALL_DAILY_WORK_STATUS:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case DailyWorkStatusActionTypes.FETCH_ALL_DAILY_WORK_STATUS_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case DailyWorkStatusActionTypes.FETCH_ALL_DAILY_WORK_STATUS_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case DailyWorkStatusActionTypes.FETCH_ALL_DAILY_WORK_STATUS_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case DailyWorkStatusActionTypes.FETCH_ONE_DAILY_WORK_STATUS:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case DailyWorkStatusActionTypes.FETCH_ONE_DAILY_WORK_STATUS_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case DailyWorkStatusActionTypes.FETCH_ONE_DAILY_WORK_STATUS_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case DailyWorkStatusActionTypes.FETCH_ONE_DAILY_WORK_STATUS_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default DailyWorkStatusReducer;
