import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { OvertimeRequest2ActionTypes } from "./OvertimeRequest2.type";

export function* fetchAllOvertimeRequest2s(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/overtime-request`);
    yield put({
      type: OvertimeRequest2ActionTypes.FETCH_ALL_OVERTIME_REQUEST2_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: OvertimeRequest2ActionTypes.FETCH_ALL_OVERTIME_REQUEST2_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneOvertimeRequest2s(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/overtime-request/${action.payload}`
    );
    yield put({
      type: OvertimeRequest2ActionTypes.FETCH_ONE_OVERTIME_REQUEST2_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: OvertimeRequest2ActionTypes.FETCH_ONE_OVERTIME_REQUEST2_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllOvertimeRequest2s() {
  yield takeLatest(
    OvertimeRequest2ActionTypes.FETCH_ALL_OVERTIME_REQUEST2,
    fetchAllOvertimeRequest2s
  );
}

export function* watcherFetchOneOvertimeRequest2s() {
  yield takeLatest(
    OvertimeRequest2ActionTypes.FETCH_ONE_OVERTIME_REQUEST2,
    fetchOneOvertimeRequest2s
  );
}
