import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MikadaWeeklySubContractReportActionTypes } from "./MikadaWeeklySubContractReport.type";

export function* fetchAllMikadaWeeklySubContractReports(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/mikada-weekly-sub-contract-report?date=${action.payload?.date}`);
    yield put({
      type: MikadaWeeklySubContractReportActionTypes.FETCH_ALL_MIKADA_WEEKLY_SUB_CONTRACT_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MikadaWeeklySubContractReportActionTypes.FETCH_ALL_MIKADA_WEEKLY_SUB_CONTRACT_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMikadaWeeklySubContractReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/mikada-sub-contract-report/${action.payload}`
    );
    yield put({
      type: MikadaWeeklySubContractReportActionTypes.FETCH_ONE_MIKADA_WEEKLY_SUB_CONTRACT_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MikadaWeeklySubContractReportActionTypes.FETCH_ONE_MIKADA_WEEKLY_SUB_CONTRACT_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMikadaWeeklySubContractReports() {
  yield takeLatest(MikadaWeeklySubContractReportActionTypes.FETCH_ALL_MIKADA_WEEKLY_SUB_CONTRACT_REPORT, fetchAllMikadaWeeklySubContractReports);
}

export function* watcherFetchOneMikadaWeeklySubContractReports() {
  yield takeLatest(MikadaWeeklySubContractReportActionTypes.FETCH_ONE_MIKADA_WEEKLY_SUB_CONTRACT_REPORT, fetchOneMikadaWeeklySubContractReports);
}
