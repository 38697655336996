import { ApiCallState } from "../Utils";
import { Moment } from "moment";

export type PaymentSummary= {
    id: number | null;
    month:Moment;
    discussionDate:Moment;
    project_name:string;
    planned_payment:number;
    expected_payment:number;
    duration_given:Moment;
    ipc_no:string;
    curret_payment_status:string;
};

export type PaymentSummaryStateTypes = {
  fetchAll: ApiCallState<PaymentSummary[]>;
  fetchOne: ApiCallState<PaymentSummary | {}>;
};

export type PaymentSummaryActionType = {
    month: string;
};

export const PaymentSummaryActionTypes = {
  FETCH_ALL_PAYMENT_SUMMARY: "FETCH_ALL_PAYMENT_SUMMARY",
  FETCH_ALL_PAYMENT_SUMMARY_RESET: "FETCH_ALL_PAYMENT_SUMMARY_RESET",
  FETCH_ALL_PAYMENT_SUMMARY_FAILURE: "FETCH_ALL_PAYMENT_SUMMARY_FAILURE",
  FETCH_ALL_PAYMENT_SUMMARY_SUCCESS: "FETCH_ALL_PAYMENT_SUMMARY_SUCCESS",

  FETCH_ONE_PAYMENT_SUMMARY: "FETCH_ONE_PAYMENT_SUMMARY",
  FETCH_ONE_PAYMENT_SUMMARY_RESET: "FETCH_ONE_PAYMENT_SUMMARY_RESET",
  FETCH_ONE_PAYMENT_SUMMARY_FAILURE: "FETCH_ONE_PAYMENT_SUMMARY_FAILURE",
  FETCH_ONE_PAYMENT_SUMMARY_SUCCESS: "FETCH_ONE_PAYMENT_SUMMARY_SUCCESS",
};
