import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ProjectTeamActionTypes } from "./ProjectTeam.type";

export function* fetchAllProjectTeams(action: any): any {
  try {
    let query = "";

    if (action?.payload && Object.keys(action.payload).length > 0) {
      query += Object.keys(action.payload)
        .map((key) => `${key}=${action.payload[key]}`)
        .join("&");
    }

    const response = yield axios.get(`${API_BASE_URI}/project-team?${query}`);
    yield put({
      type: ProjectTeamActionTypes.FETCH_ALL_PROJECT_TEAM_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectTeamActionTypes.FETCH_ALL_PROJECT_TEAM_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneProjectTeams(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/project-team/${action.payload}`
    );
    yield put({
      type: ProjectTeamActionTypes.FETCH_ONE_PROJECT_TEAM_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProjectTeamActionTypes.FETCH_ONE_PROJECT_TEAM_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllProjectTeams() {
  yield takeLatest(
    ProjectTeamActionTypes.FETCH_ALL_PROJECT_TEAM,
    fetchAllProjectTeams
  );
}

export function* watcherFetchOneProjectTeams() {
  yield takeLatest(
    ProjectTeamActionTypes.FETCH_ONE_PROJECT_TEAM,
    fetchOneProjectTeams
  );
}
