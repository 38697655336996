import {
  FuelReceiveStateTypes,
  FuelReceiveActionTypes,
} from "./FuelReceive.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: FuelReceiveStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchNumber: resetApiCallState(null),
};

const FuelReceiveReducer = (
  state: FuelReceiveStateTypes = INITIAL_STATE,
  action: any
): FuelReceiveStateTypes => {
  switch (action.type) {
    case FuelReceiveActionTypes.FETCH_ALL_FUEL_RECEIVE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case FuelReceiveActionTypes.FETCH_ALL_FUEL_RECEIVE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case FuelReceiveActionTypes.FETCH_ALL_FUEL_RECEIVE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FuelReceiveActionTypes.FETCH_ALL_FUEL_RECEIVE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case FuelReceiveActionTypes.FETCH_ONE_FUEL_RECEIVE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case FuelReceiveActionTypes.FETCH_ONE_FUEL_RECEIVE_RESET:
      return {
        ...state,
        fetchOne: resetApiCallState({}),
      };
    case FuelReceiveActionTypes.FETCH_ONE_FUEL_RECEIVE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FuelReceiveActionTypes.FETCH_ONE_FUEL_RECEIVE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    // ------------------

    case FuelReceiveActionTypes.FETCH_FUEL_RECEIVE_NUMBER:
      return {
        ...state,
        fetchNumber: {
          error: null,
          payload: null,
          isPending: true,
          isSuccessful: false,
        },
      };
    case FuelReceiveActionTypes.FETCH_FUEL_RECEIVE_NUMBER_RESET:
      return {
        ...state,
        fetchNumber: resetApiCallState(null),
      };
    case FuelReceiveActionTypes.FETCH_FUEL_RECEIVE_NUMBER_FAILURE:
      return {
        ...state,
        fetchNumber: {
          payload: null,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FuelReceiveActionTypes.FETCH_FUEL_RECEIVE_NUMBER_SUCCESS:
      return {
        ...state,
        fetchNumber: {
          payload: action.payload,
          isPending: false,
          isSuccessful: true,
          error: null,
        },
      };

    default:
      return state;
  }
};

export default FuelReceiveReducer;
