import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { CanteenDeductionActionTypes } from "./CanteenDeduction.type";

export function* fetchAllCanteenDeductions(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/canteen-deduction`);
    yield put({
      type: CanteenDeductionActionTypes.FETCH_ALL_CANTEEN_DEDUCTION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: CanteenDeductionActionTypes.FETCH_ALL_CANTEEN_DEDUCTION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneCanteenDeductions(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/canteen-deduction/${action.payload}`
    );
    yield put({
      type: CanteenDeductionActionTypes.FETCH_ONE_CANTEEN_DEDUCTION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: CanteenDeductionActionTypes.FETCH_ONE_CANTEEN_DEDUCTION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllCanteenDeductions() {
  yield takeLatest(CanteenDeductionActionTypes.FETCH_ALL_CANTEEN_DEDUCTION, fetchAllCanteenDeductions);
}

export function* watcherFetchOneCanteenDeductions() {
  yield takeLatest(CanteenDeductionActionTypes.FETCH_ONE_CANTEEN_DEDUCTION, fetchOneCanteenDeductions);
}
