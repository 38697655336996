import { MaterialMajorCategory } from "../MaterialMajorCategory/MaterialMajorCategory.type";
import { MaterialStartingBalance } from "../MaterialStartingBalance/MaterialStartingBalance.type";
import { MaterialSubCategory } from "../MaterialSubCategory/MaterialSubCategory.type";
import { ApiCallState, PagedData } from "../Utils";

export type Material = {
  id: any;
  major_category_id?: number;
  material_sub_category_id?: number;
  key: number;
  description: string;
  unit: string;
  unit_price: number;
  edited?: boolean;
  code_prefix?: string;
  code: number;
  deprecation: number;
  material_sub_category?: MaterialSubCategory;
  material_major_category?: MaterialMajorCategory;
  material_starting_balances?: MaterialStartingBalance[];
  material_unit_prices?: MaterialUnitPrice[];
  material_counts?: MaterialCount[];

  readonly createdAt?: Date;
};

export enum MATERIAL_TYPE {
  Service = "Service",
}

export type MaterialCount = {
  material_id: number;
  store_id: number;
  quantity: number;
};

export type MaterialUnitPrice = {
  id: number;
  material_id: number;
  unit_price: number;
  quantity: number;
  good_received_item_id: number;
  date: string;
};

export type MaterialStateTypes = {
  fetchAll: ApiCallState<Material[]>;
  fetchAllForm: ApiCallState<Material[]>;
  fetchOne: ApiCallState<Material | {}>;
  fetchPaged: ApiCallState<PagedData<Material[]>>;
  fetchNumber: ApiCallState<number | null>;
};

export const MaterialActionTypes = {
  FETCH_ALL_MATERIAL: "FETCH_ALL_MATERIAL",
  FETCH_ALL_MATERIAL_RESET: "FETCH_ALL_MATERIAL_RESET",
  FETCH_ALL_MATERIAL_FAILURE: "FETCH_ALL_MATERIAL_FAILURE",
  FETCH_ALL_MATERIAL_SUCCESS: "FETCH_ALL_MATERIAL_SUCCESS",

  FETCH_ALL_FORM_MATERIAL: "FETCH_ALL_FORM_MATERIAL",
  FETCH_ALL_FORM_MATERIAL_RESET: "FETCH_ALL_FORM_MATERIAL_RESET",
  FETCH_ALL_FORM_MATERIAL_FAILURE: "FETCH_ALL_FORM_MATERIAL_FAILURE",
  FETCH_ALL_FORM_MATERIAL_SUCCESS: "FETCH_ALL_FORM_MATERIAL_SUCCESS",

  FETCH_PAGED_MATERIAL: "FETCH_PAGED_MATERIAL",
  FETCH_PAGED_MATERIAL_RESET: "FETCH_PAGED_MATERIAL_RESET",
  FETCH_PAGED_MATERIAL_FAILURE: "FETCH_PAGED_MATERIAL_FAILURE",
  FETCH_PAGED_MATERIAL_SUCCESS: "FETCH_PAGED_MATERIAL_SUCCESS",

  FETCH_ONE_MATERIAL: "FETCH_ONE_MATERIAL",
  FETCH_ONE_MATERIAL_RESET: "FETCH_ONE_MATERIAL_RESET",
  FETCH_ONE_MATERIAL_FAILURE: "FETCH_ONE_MATERIAL_FAILURE",
  FETCH_ONE_MATERIAL_SUCCESS: "FETCH_ONE_MATERIAL_SUCCESS",

  FETCH_MATERIAL_NUMBER: "FETCH_MATERIAL_NUMBER",
  FETCH_MATERIAL_NUMBER_RESET: "FETCH_MATERIAL_NUMBER_RESET",
  FETCH_MATERIAL_NUMBER_FAILURE: "FETCH_MATERIAL_NUMBER_FAILURE",
  FETCH_MATERIAL_NUMBER_SUCCESS: "FETCH_MATERIAL_NUMBER_SUCCESS",
};
