import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ExternalRepairOrderActionTypes } from "./ExternalRepairOrder.type";
import { formatQuery } from "../Utils";

export function* fetchAllExternalRepairOrders(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/external-repair-order?${formatQuery(action)}`
    );
    yield put({
      type: ExternalRepairOrderActionTypes.FETCH_ALL_EXTERNAL_REPAIR_ORDER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ExternalRepairOrderActionTypes.FETCH_ALL_EXTERNAL_REPAIR_ORDER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneExternalRepairOrders(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/external-repair-order/${action.payload}`
    );
    yield put({
      type: ExternalRepairOrderActionTypes.FETCH_ONE_EXTERNAL_REPAIR_ORDER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ExternalRepairOrderActionTypes.FETCH_ONE_EXTERNAL_REPAIR_ORDER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllExternalRepairOrders() {
  yield takeLatest(
    ExternalRepairOrderActionTypes.FETCH_ALL_EXTERNAL_REPAIR_ORDER,
    fetchAllExternalRepairOrders
  );
}

export function* watcherFetchOneExternalRepairOrders() {
  yield takeLatest(
    ExternalRepairOrderActionTypes.FETCH_ONE_EXTERNAL_REPAIR_ORDER,
    fetchOneExternalRepairOrders
  );
}
