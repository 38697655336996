import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { OperationStandardActionTypes } from "./OperationStandard.type";

export function* fetchAllOperationStandards(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/operation-standard`);
    yield put({
      type: OperationStandardActionTypes.FETCH_ALL_OPERATION_STANDARD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: OperationStandardActionTypes.FETCH_ALL_OPERATION_STANDARD_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneOperationStandards(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/operation-standard/${action.payload}`
    );
    yield put({
      type: OperationStandardActionTypes.FETCH_ONE_OPERATION_STANDARD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: OperationStandardActionTypes.FETCH_ONE_OPERATION_STANDARD_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllOperationStandards() {
  yield takeLatest(OperationStandardActionTypes.FETCH_ALL_OPERATION_STANDARD, fetchAllOperationStandards);
}

export function* watcherFetchOneOperationStandards() {
  yield takeLatest(OperationStandardActionTypes.FETCH_ONE_OPERATION_STANDARD, fetchOneOperationStandards);
}
