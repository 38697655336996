import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PayrollActionTypes } from "./Payroll.type";

export function* fetchAllPayrolls(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/payroll`);
    yield put({
      type: PayrollActionTypes.FETCH_ALL_PAYROLL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PayrollActionTypes.FETCH_ALL_PAYROLL_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPayrollByDate(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/payroll/date/${action.payload?.date}`);
    yield put({
      type: PayrollActionTypes.FETCH_BY_DATE_PAYROLL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PayrollActionTypes.FETCH_BY_DATE_PAYROLL_FAILURE,
      payload: error,
    });
  }
}

export function* calculatePayroll(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/payroll/calculate?date=${action.payload?.date}&department_id=${action.payload?.department_id}`
    );
    yield put({
      type: PayrollActionTypes.CALCULATE_PAYROLL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PayrollActionTypes.CALCULATE_PAYROLL_FAILURE,
      payload: error,
    });
  }
}

export function* payrollReport(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/payroll/report?date=${action.payload?.date}`
    );
    yield put({
      type: PayrollActionTypes.PAYROLL_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PayrollActionTypes.PAYROLL_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* staffPayrollReport(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/payroll/staff-report?date=${action.payload?.date}`
    );
    yield put({
      type: PayrollActionTypes.STAFF_PAYROLL_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PayrollActionTypes.STAFF_PAYROLL_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPayrolls() {
  yield takeLatest(PayrollActionTypes.FETCH_ALL_PAYROLL, fetchAllPayrolls);
}

export function* watcherCalculatePayroll() {
  yield takeLatest(PayrollActionTypes.CALCULATE_PAYROLL, calculatePayroll);
}

export function* watcherPayrollReport() {
  yield takeLatest(PayrollActionTypes.PAYROLL_REPORT, payrollReport);
}

export function* watcherStaffPayrollReport() {
  yield takeLatest(PayrollActionTypes.STAFF_PAYROLL_REPORT, staffPayrollReport);
}

export function* watcherPayrollByDate() {
  yield takeLatest(PayrollActionTypes.FETCH_BY_DATE_PAYROLL, fetchPayrollByDate);
}
