import { ApplicantRegistryActionTypes } from "./ApplicantRegistry.type";

/**
 * Fetch All ApplicantRegistry
 *
 * @param payload
 */
export const fetchAllApplicantRegistry = (payload?: any) => ({
  type: ApplicantRegistryActionTypes.FETCH_ALL_APPLICANT_REGISTRY,
  payload: payload,
});

/**
 * Fetch All ApplicantRegistry
 *
 * @param payload
 */
export const fetchOneApplicantRegistry = (payload?: any) => ({
  type: ApplicantRegistryActionTypes.FETCH_ONE_APPLICANT_REGISTRY,
  payload: payload,
});

/**
 * Reset Fetch ApplicantRegistry State
 *
 * @param payload
 */
export const fetchAllApplicantRegistryReset = (payload?: any) => ({
  type: ApplicantRegistryActionTypes.FETCH_ALL_APPLICANT_REGISTRY_RESET,
  payload: payload,
});
