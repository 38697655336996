import { Material } from "../Material/Material.type";

import { Store } from "../Store/Store.type";
import { ApiCallState, PagedData } from "../Utils";

export interface Inventory extends Material {
  store?: Store;
  store_id: number;
  quantity: number;
  key: number;
  code: any;
  unit_price: number;
  count?: number;
  remark?: string;
}

export interface InventoryAnalysis {
  description: string;
  code: string;
  unit: string;
  total_planned: number;
  this_period_planned: number;
  this_period_requested: number;
  previous_requested: number;
  this_period_delivered: number;
  previous_delivered: number;
  this_period_returned: number;
  previous_returned: number;
  this_period_issued: number;
  previous_issued: number;
  previous_transferred_out: number;
  this_period_transferred_out: number;
  previous_transferred_in: number;
  this_period_transferred_in: number;
  unit_price: number;
  quantity: number;
}

export type StockCard = {
  material: Material;
  date: string;
  issued: number;
  key: number;
  received: number;
  returned: number;
  transferred: number;
  identification: string;
  unit_price: number;
  balance: number;
};
export type StockReport = {
  major_category_id: number;
  material_id: number;
  name: string;
  code: string;
  unit: string;
  bff: number;
  issued: number;
  received: number;
  returned: number;
  good_in: number;
  good_out: number;
  balance: number;
};

export type InventoryStateTypes = {
  fetchAll: ApiCallState<Inventory[]>;
  fetchAllQueried: ApiCallState<Inventory[]>;
  fetchOne: ApiCallState<Inventory[]>;
  fetchAnalysis: ApiCallState<PagedData<InventoryAnalysis[]>>;
  fetchUnpagedAnalysis: ApiCallState<InventoryAnalysis[]>;
  fetchStockReport: ApiCallState<
    PagedData<StockReport[]> & {
      summary: {
        totalIssued: number;
        totalReceived: number;
      };
    }
  >;
  fetchStockCard: ApiCallState<{
    stock_card: StockCard[];
    summery: {
      total_received: number;
      total_returned: number;
      total_transferred: number;
      total_issued: number;
      unit_price: number;
    };
  }>;
};

export const InventoryActionTypes = {
  FETCH_ALL_INVENTORY: "FETCH_ALL_INVENTORY",
  FETCH_ALL_INVENTORY_RESET: "FETCH_ALL_INVENTORY_RESET",
  FETCH_ALL_INVENTORY_FAILURE: "FETCH_ALL_INVENTORY_FAILURE",
  FETCH_ALL_INVENTORY_SUCCESS: "FETCH_ALL_INVENTORY_SUCCESS",

  FETCH_ALL_QUERIED_INVENTORY: "FETCH_ALL_QUERIED_INVENTORY",
  FETCH_ALL_QUERIED_INVENTORY_RESET: "FETCH_ALL_QUERIED_INVENTORY_RESET",
  FETCH_ALL_QUERIED_INVENTORY_FAILURE: "FETCH_ALL_QUERIED_INVENTORY_FAILURE",
  FETCH_ALL_QUERIED_INVENTORY_SUCCESS: "FETCH_ALL_QUERIED_INVENTORY_SUCCESS",

  FETCH_ONE_INVENTORY: "FETCH_ONE_INVENTORY",
  FETCH_ONE_INVENTORY_RESET: "FETCH_ONE_INVENTORY_RESET",
  FETCH_ONE_INVENTORY_FAILURE: "FETCH_ONE_INVENTORY_FAILURE",
  FETCH_ONE_INVENTORY_SUCCESS: "FETCH_ONE_INVENTORY_SUCCESS",

  FETCH_ANALYSIS_INVENTORY: "FETCH_ANALYSIS_INVENTORY",
  FETCH_ANALYSIS_INVENTORY_RESET: "FETCH_ANALYSIS_INVENTORY_RESET",
  FETCH_ANALYSIS_INVENTORY_FAILURE: "FETCH_ANALYSIS_INVENTORY_FAILURE",
  FETCH_ANALYSIS_INVENTORY_SUCCESS: "FETCH_ANALYSIS_INVENTORY_SUCCESS",

  FETCH_UNPAGED_ANALYSIS_INVENTORY: "FETCH_UNPAGED_ANALYSIS_INVENTORY",
  FETCH_UNPAGED_ANALYSIS_INVENTORY_RESET:
    "FETCH_UNPAGED_ANALYSIS_INVENTORY_RESET",
  FETCH_UNPAGED_ANALYSIS_INVENTORY_FAILURE:
    "FETCH_UNPAGED_ANALYSIS_INVENTORY_FAILURE",
  FETCH_UNPAGED_ANALYSIS_INVENTORY_SUCCESS:
    "FETCH_UNPAGED_ANALYSIS_INVENTORY_SUCCESS",

  FETCH_INVENTORY_STOCK_CARD: "FETCH_ANALYSIS_INVENTORY_STOCK_CARD",
  FETCH_INVENTORY_STOCK_RESET: "FETCH_ANALYSIS_INVENTORY_STOCK_CARD_RESET",
  FETCH_INVENTORY_STOCK_FAILURE: "FETCH_INVENTORY_STOCK_CARD_FAILURE",
  FETCH_INVENTORY_STOCK_SUCCESS: "FETCH_INVENTORY_STOCK_CARD_SUCCESS",

  FETCH_INVENTORY_STOCK_REPORT: "FETCH_ANALYSIS_INVENTORY_STOCK_REPORT",
  FETCH_INVENTORY_STOCK_REPORT_RESET:
    "FETCH_ANALYSIS_INVENTORY_STOCK_REPORT_RESET",
  FETCH_INVENTORY_STOCK_REPORT_FAILURE: "FETCH_INVENTORY_STOCK_REPORT_FAILURE",
  FETCH_INVENTORY_STOCK_REPORT_SUCCESS: "FETCH_INVENTORY_STOCK_REPORT_SUCCESS",
};
