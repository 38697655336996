import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ActivityWaitageActionTypes } from "./ActivityWaitage.type";

export function* fetchAllActivityWaitages(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/activity-waitage`);
    yield put({
      type: ActivityWaitageActionTypes.FETCH_ALL_ACTIVITY_WAITAGE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActivityWaitageActionTypes.FETCH_ALL_ACTIVITY_WAITAGE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneActivityWaitages(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/activity-waitage/date?project_id=${action.payload?.project_id}&date=${action.payload?.date}`
    );
    yield put({
      type: ActivityWaitageActionTypes.FETCH_ONE_ACTIVITY_WAITAGE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ActivityWaitageActionTypes.FETCH_ONE_ACTIVITY_WAITAGE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllActivityWaitages() {
  yield takeLatest(ActivityWaitageActionTypes.FETCH_ALL_ACTIVITY_WAITAGE, fetchAllActivityWaitages);
}

export function* watcherFetchOneActivityWaitages() {
  yield takeLatest(ActivityWaitageActionTypes.FETCH_ONE_ACTIVITY_WAITAGE, fetchOneActivityWaitages);
}
