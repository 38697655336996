import { ApiCallState } from "../Utils";

// export type IncomeExpense = {
//   id: number;
//   totalPlannedAmount: number;
//   totalExecutedAmount: number;
//   totalExpense: number;
// };

export type IncomeExpense = {
  id: number;
  planned_income: number;
  executed_income: number;
  actual_planned: number;
  total_expense_to_execute: number;
  amount_net_profit_loss: number;
  amount_net_profit_loss_percentage: number;
  status: string;
  daily_site_report_id: number;
  project_id: number;
  user_id: number;
};

export type ProgressSummary = {
  id: number;
  boq_item_group_id: number;
  description: string;
  contract_amount: number;
  to_date_certified: number;
  previous_executed: number;
  date_planned_amount: number;
  date_actual_amount: number;
  to_date_actual_amount: number;
};

export type ProjectIncome = {
  description: string;
  previous_planned_amount: number;
  this_period_planned_ammount: number;
  to_date_planned_amount: number;
  previous_income_amount: number;
  this_period_income_ammount: number;
  to_date_income_amount: number;
  previous_against_plan: number;
  this_period_against_plan: number;
  to_date_against_plan: number;
};

export type BillSummary = {
  description: string;
  contract_amount: number;
  previous_planned_amount: number;
  this_period_planned_ammount: number;
  to_date_planned_amount: number;
  previous_executed_amount: number;
  this_period_executed_ammount: number;
  to_date_executed_amount: number;
  previous_cerified_amount: number;
  this_period_certified_amount: number;
  to_date_certified_amount: number;
  previous_vs_contract: number;
  this_period_vs_contract: number;
  to_date_vs_contract: number;
  payment_previous_vs_contract: number;
  payment_this_period_vs_contract: number;
  payment_to_date_vs_contract: number;
};

export type IncomeExpenseType = {
  items: {
    description: string;
    this_period_planned_ammount: number;
    this_period_executed_ammount: number;
  }[];
  fuel: {
    fuelOwnCost: number;
    fuelRentalCost: number;
    oilLubricantOwnCost: number;
    oilLubricantRentalCost: number;
  };
  spare: {
    sparePartsOwn: number;
    sparePartsRental: number;
    maintainanceOwn: number;
    maintainanceRental: number;
  };
  material: {
    constructionExpense: number;
    stationaryExpense: number;
    electricalExpense: number;
    sanitoryExpense: number;
  };
  directPermanentManPower: number;
  inDirectPermanentManPower: number;
  directTemporaryManPower: number;
  inDirectTemporaryManPower: number;
  subContractExpense: number;
  otherExpense: number;
};

export type IncomeExpenseStateTypes = {
  fetchReport: ApiCallState<IncomeExpense[]>;
  fetchProgressSummary: ApiCallState<ProgressSummary[]>;
  fetchProjectIncome: ApiCallState<ProjectIncome | {}>;
  fetchBillSummary: ApiCallState<BillSummary[]>;
  fetchIncomeExpense: ApiCallState<IncomeExpenseType | {}>;
};

export const IncomeExpenseActionTypes = {
  FETCH_INCOME_EXPENSE_REPORT: "FETCH_INCOME_EXPENSE_REPORT",
  FETCH_INCOME_EXPENSE_REPORT_RESET: "FETCH_INCOME_EXPENSE_REPORT_RESET",
  FETCH_INCOME_EXPENSE_REPORT_FAILURE: "FETCH_INCOME_EXPENSE_REPORT_FAILURE",
  FETCH_INCOME_EXPENSE_REPORT_SUCCESS: "FETCH_INCOME_EXPENSE_REPORT_SUCCESS",

  FETCH_PROGRESS_SUMMARY: "FETCH_PROGRESS_SUMMARY",
  FETCH_PROGRESS_SUMMARY_RESET: "FETCH_PROGRESS_SUMMARY_RESET",
  FETCH_PROGRESS_SUMMARY_FAILURE: "FETCH_PROGRESS_SUMMARY_FAILURE",
  FETCH_PROGRESS_SUMMARY_SUCCESS: "FETCH_PROGRESS_SUMMARY_SUCCESS",

  FETCH_PROJECT_INCOME: "FETCH_PROJECT_INCOME",
  FETCH_PROJECT_INCOME_RESET: "FETCH_PROJECT_INCOME_RESET",
  FETCH_PROJECT_INCOME_FAILURE: "FETCH_PROJECT_INCOME_FAILURE",
  FETCH_PROJECT_INCOME_SUCCESS: "FETCH_PROJECT_INCOME_SUCCESS",

  FETCH_BILL_SUMMARY: "FETCH_BILL_SUMMARY",
  FETCH_BILL_SUMMARY_RESET: "FETCH_BILL_SUMMARY_RESET",
  FETCH_BILL_SUMMARY_FAILURE: "FETCH_BILL_SUMMARY_FAILURE",
  FETCH_BILL_SUMMARY_SUCCESS: "FETCH_BILL_SUMMARY_SUCCESS",

  FETCH_INCOME_EXPENSE: "FETCH_INCOME_EXPENSE",
  FETCH_INCOME_EXPENSE_RESET: "FETCH_INCOME_EXPENSE_RESET",
  FETCH_INCOME_EXPENSE_FAILURE: "FETCH_INCOME_EXPENSE_FAILURE",
  FETCH_INCOME_EXPENSE_SUCCESS: "FETCH_INCOME_EXPENSE_SUCCESS",
};
