import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { PostDateVoucherActionTypes } from "./PostDateVoucher.type";

export function* fetchAllPostDateVouchers(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/post-date-voucher`);
    yield put({
      type: PostDateVoucherActionTypes.FETCH_ALL_POST_DATE_VOUCHER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PostDateVoucherActionTypes.FETCH_ALL_POST_DATE_VOUCHER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePostDateVouchers(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/post-date-voucher/${action.payload}`
    );
    yield put({
      type: PostDateVoucherActionTypes.FETCH_ONE_POST_DATE_VOUCHER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PostDateVoucherActionTypes.FETCH_ONE_POST_DATE_VOUCHER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPostDateVouchers() {
  yield takeLatest(PostDateVoucherActionTypes.FETCH_ALL_POST_DATE_VOUCHER, fetchAllPostDateVouchers);
}

export function* watcherFetchOnePostDateVouchers() {
  yield takeLatest(PostDateVoucherActionTypes.FETCH_ONE_POST_DATE_VOUCHER, fetchOnePostDateVouchers);
}
