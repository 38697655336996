import { Material } from "../Material/Material.type";

import { PurchaseOrder } from "../PurchaseOrder/PurchaseOrder.type";
import { PurchaseRequisition } from "../PurchaseRequisition/PurchaseRequisition.type";
import { Store } from "../Store/Store.type";
import { Supplier } from "../Supplier/Supplier.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type FuelReceiving = {
  checked_by: string;
  id: number;
  type: string;
  supplier_id: number;
  purchase_order?: PurchaseOrder;
  supplier?: Supplier;
  purchase_order_id: number;
  supplier_invoice: string;
  purchase_requisition_id: number;
  purchase_requisition: PurchaseRequisition;
  received_by: string;
  gr_receiving_warehouse: Store;
  prepared_by: string;
  authorized_by: string;
  driver_name: string;
  delivered_by: string;
  date: string;
  inventory_type: string;
  purchase_type: string;
  store_id: number;
  fuel_receiving_type: string;
  fuel_receiving_items: FuelReceivingItem[];
  is_done: boolean;
  gr_prepared_by?: User;
  fuel_receiving_status: FuelReceivingStatus[];
  status: FuelReceivingStatus[];
  project: Store;
  approved_by: string;
  pad_ref: string;
  updatedAt:string;
};

export type FuelReceivingStatus = {
  good_out_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type FuelReceivingItem = {
  material?: Material;
  material_id: number;
  unit_price: number;
  quantity: number;
  unit: string;
  id: any;
};

export type FuelReceivingStateTypes = {
  fetchAll: ApiCallState<FuelReceiving[]>;
  fetchReport: ApiCallState<FuelReceiving[]>;
};

export const FuelReceivingActionTypes = {
  FETCH_ALL_FUEL_RECEIVING: "FETCH_ALL_FUEL_RECEIVING",
  FETCH_ALL_FUEL_RECEIVING_RESET: "FETCH_ALL_FUEL_RECEIVING_RESET",
  FETCH_ALL_FUEL_RECEIVING_FAILURE: "FETCH_ALL_FUEL_RECEIVING_FAILURE",
  FETCH_ALL_FUEL_RECEIVING_SUCCESS: "FETCH_ALL_FUEL_RECEIVING_SUCCESS",

  FETCH_FUEL_RECEIVING_REPORT: "FETCH_FUEL_RECEIVING_REPORT",
  FETCH_FUEL_RECEIVING_REPORT_RESET: "FETCH_FUEL_RECEIVING_REPORT_RESET",
  FETCH_FUEL_RECEIVING_REPORT_FAILURE: "FETCH_FUEL_RECEIVING_REPORT_FAILURE",
  FETCH_FUEL_RECEIVING_REPORT_SUCCESS: "FETCH_FUEL_RECEIVING_REPORT_SUCCESS",
};
