/**
 * Routes
 */
export const RouteConstants:ConstantProps = {
  /**
   * Dashboard
   */
  DASHBOARD: "/",
  DASHBOARDS: "/dashboard",
  PROJECTS: "/project",
  PROJECT_LIST: "/project/:type",
  REGISTER_PROJECT: "/register-project",
  PROJECT_SUMMARY: "/project/:id/:tab",
  SUMMARY: "/summary",
  REPORT: "/report",
  MIRAB_REPORT: "/mirab-report",
  DOCUMENT: "/document",
  FIXED_ASSET: "/fixed-asset",
  INVENTORY: "/inventory",
  HUMAN_RESOURCE_INDEX: "/hr/:id",
  HUMAN_RESOURCE: "/hr",
  HUMAN_RESOURCE_DATA_ENTRY: "/hr/data-entry/:tab",
  PROCUREMENT: "/procurement",
  SUB_CONTRACT_LIST: "/sub-contract-list",
  REGISTER_SUB_CONTRACT: "/register-sub-contract",
  FINANCE: "/finance",
  TASK: "/task",
  OPERATION: "/operation",
  LOGIN: "/login",
  PRE_CONTRACT: "/pre-contract",
  REGISTER_PRE_CONTRACT: "/register",
  PRE_CONTRACT_SUMMARY: "/pre-contract/summary",
  PAYMENT: "/payment",
  PAYMENT_SUMMARY: "/payment/:id/:tab",

  STANDARDS: "/standard",

  RESET: "/reset",
  GOOGLE_AUTH_FORM: "/google-auth/:userId",
  EMAIL_CONFIRMATION: "/confirmation",
  CHECKER: "/checker/:hash",
  GOOGLE_AUTH: "/google/auth/:token/:userData",
  CONSULTANT_SUMMARY: "/consultant-summary/:id/:tab",
  CONTRACTOR_PAYMENT: "/contractor-payment/:id/:tab",

  CONTRACTOR_SUMMARY: "/contractor-summary/:id/:tab",
  CLIENT_SUMMARY: "/client-summary/:id/:tab",

  CONTRACT_SUMMARY: "/contract-summary",
  CONTRACT_BOQ: "/contract-boq",
  VARIATION: "/variation",
  WORK_ITEM: "/work-item",
  MASTER_SCHEDULE: "/master-schedule",
  MASTER_SCHEDULE_SUMMARY: "/master-schedule/:id/:tab",
  PLANS: "/plans",
  REPORTS: "/reports",
  CASH_FLOW: "/cash-flow",
  PRICE_ESCALATION: "/price-escalation",
  EXECUTED_SUMMARY: "/executed-summary",
  MEASUREMENT_CERTIFICATE: "/measurement-certificate",
  PAYMENT_CERTIFICATE: "/payment-certificate",
  REQUESTED_PAYMENTS: "/requested-payments",
  ANALYTICS: "/analytics",
  EDIT: "/edit",

  EMPLOYEE_APPLICATION: "/welcome/:jobId",

  EMPLOYEE_APPLICATION2: "/apply/:jobId",

  EMPLOYEE_APPLICATION_SUCCESS: "/application/success",

  /**
   * Error
   */
  INTERNAL_SERVER_ERROR: "500",

  SIGN_UP: "/sign_up",
  HOME_PAGE: "/home",

  FEEDBACK: "/feed-back-form/:feed_back_form_id/:token",
  FEEDBACK_SUCCESS: "/feed-back-response-success",
  FEEDBACK_EXIST: "/feed-back-response-exist",

  EMPLOYEE: "/employee/:id/:tab",
  HR_ASSESSMENT_SUMMARY:"/hr/data-entry/applicant-registry/assessment-summary/:id",
  HR_ASSESSMENT:"/hr/data-entry/applicant-registry/assessment/:id/:vacancyId",
  HR_WORK_EXPERIENCE_SUMMARY:"/hr/data-entry/applicant-registry/work-experience-summary/:id",
  USER_MANAGEMENT: "/user-management/:tab",
};

export interface ConstantProps {
  [state: string]: string; //indexer
}

export const ACCOUNT_ROUTE = [
  RouteConstants.DASHBOARD,
  RouteConstants.DASHBOARDS,
  RouteConstants.FINANCE,
  RouteConstants.STANDARDS,
];
export const HR_ROUTE = [
  RouteConstants.DASHBOARD,
  RouteConstants.DASHBOARDS,
  RouteConstants.HUMAN_RESOURCE,
  RouteConstants.HUMAN_RESOURCE_INDEX,
  RouteConstants.HUMAN_RESOURCE_DATA_ENTRY,
  RouteConstants.STANDARDS,
];
// RouteConstants.DASHBOARD,
// RouteConstants.DASHBOARDS,
export const PROCUREMENT_ROUTE = [
  RouteConstants.PROCUREMENT,
  RouteConstants.INVENTORY,
  RouteConstants.FIXED_ASSET,
  RouteConstants.STANDARDS,
];

// RouteConstants.DASHBOARD,
// RouteConstants.DASHBOARDS,
export const PROJECT_MANAGER_ROUTE = [
  RouteConstants.SUMMARY,
  RouteConstants.REGISTER_PROJECT,
  RouteConstants.SUB_CONTRACT_LIST,
  RouteConstants.REGISTER_SUB_CONTRACT,
  RouteConstants.PROJECT_LIST,
  RouteConstants.PROJECTS,
  RouteConstants.PROJECT_SUMMARY,
  RouteConstants.REPORT,
  RouteConstants.DOCUMENT,
  RouteConstants.REGISTER_PRE_CONTRACT,
  RouteConstants.PRE_CONTRACT,
  RouteConstants.STANDARDS,
];

// RouteConstants.DASHBOARD,
// RouteConstants.DASHBOARDS,

export const CLIENT_ROUTE = [
  RouteConstants.CLIENT_SUMMARY,
  RouteConstants.REGISTER_PROJECT,
  RouteConstants.SUB_CONTRACT_LIST,
  RouteConstants.REGISTER_SUB_CONTRACT,
  RouteConstants.PROJECT_LIST,
  RouteConstants.PROJECTS,
  RouteConstants.PROJECT_SUMMARY,
  RouteConstants.DOCUMENT,
  RouteConstants.REGISTER_PRE_CONTRACT,
  RouteConstants.PRE_CONTRACT,
  RouteConstants.STANDARDS,
  RouteConstants.INVENTORY,
  RouteConstants.FINANCE,
  RouteConstants.PROCUREMENT,
  RouteConstants.CONTRACTOR_PAYMENT,
  RouteConstants.HUMAN_RESOURCE,
  RouteConstants.HUMAN_RESOURCE_INDEX,
  RouteConstants.HUMAN_RESOURCE_DATA_ENTRY,
  RouteConstants.MASTER_SCHEDULE_SUMMARY,
  RouteConstants.FIXED_ASSET,
  RouteConstants.MIRAB_REPORT,
  RouteConstants.REPORT,
  RouteConstants.OPERATION,
  RouteConstants.TASK,
];

export const CONSULTANT_ROUTE = [
  RouteConstants.PROJECT_LIST,
  RouteConstants.PROJECTS,
  RouteConstants.PAYMENT,
  RouteConstants.PAYMENT_SUMMARY,
  RouteConstants.CONSULTANT_SUMMARY,
  RouteConstants.PROJECT_SUMMARY,
  RouteConstants.INVENTORY,
  RouteConstants.MASTER_SCHEDULE_SUMMARY,
  RouteConstants.TASK,
];

export const CONTRACTOR_ROUTE = [
  RouteConstants.PROJECTS,
  RouteConstants.PROJECT_LIST,
  RouteConstants.CONTRACTOR_SUMMARY,
  RouteConstants.REGISTER_PROJECT,
  RouteConstants.SUB_CONTRACT_LIST,
  RouteConstants.REGISTER_SUB_CONTRACT,
  RouteConstants.PROJECT_SUMMARY,
  RouteConstants.REPORT,
  RouteConstants.DOCUMENT,
  RouteConstants.REGISTER_PRE_CONTRACT,
  RouteConstants.PRE_CONTRACT,
  RouteConstants.STANDARDS,
  RouteConstants.INVENTORY,
  RouteConstants.FIXED_ASSET,
  RouteConstants.HUMAN_RESOURCE,
  RouteConstants.HUMAN_RESOURCE_INDEX,
  RouteConstants.HUMAN_RESOURCE_DATA_ENTRY,
  RouteConstants.CONTRACTOR_PAYMENT,
  RouteConstants.MASTER_SCHEDULE_SUMMARY,
  RouteConstants.TASK,
];