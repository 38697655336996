import { Material } from "../Material/Material.type";
import { Store } from "../Store/Store.type";
import { User } from "../User/User.type";
import { ApiCallState, PagedData } from "../Utils";

export type DamageReturn = {
  id: number;
  date: string;
  is_void: boolean;
  damage_return_items: DamageReturnItem[];
  fixed_asset_in_prepared_by: User;
  fixed_asset_in_remarks: DamageReturnRemark[];
  damage_return_statuses: DamageReturnStatus[];
  damage_return_prepared_by: User;
  status: DamageReturnStatus[];
};

export type DamageReturnReport = {
  approved_by?: any;
  code: string;
  date: string;
  description: string;
  from: string;
  to: string;
  item_category: string;
  id: number;
  major_category: string;
  material_requisition_id: string;
  pad_ref?: any;
  prepared_by: string;
  quantity: number;
  received_by?: any;
  remark: string;
  total: number;
  unit: string;
};

export type DamageReturnRemark = {
  time: string;
  remark: string;
  user_id: number;
  document?: Document;
};

export type DamageReturnStatus = {
  fixed_asset_in_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type DamageReturnItem = {
  id?: number;
  key: number;
  material_id?: number;
  damage_return_id?: number;
  store?: Store;
  return_from?: number;
  returned_by: string;
  damaged_part: string;
  unit: string;
  quantity: number;
  available: number;
  remark: string;
  material?: Material;
};

export type DamageReturnStateTypes = {
  fetchAll: ApiCallState<DamageReturn[]>;
  fetchOne: ApiCallState<DamageReturn | {}>;
  fetchPaged: ApiCallState<PagedData<DamageReturn[]>>;
  fetchReport: ApiCallState<DamageReturnReport[]>;
};

export const DamageReturnActionTypes = {
  FETCH_ALL_DAMAGE_RETURN: "FETCH_ALL_DAMAGE_RETURN",
  FETCH_ALL_DAMAGE_RETURN_RESET: "FETCH_ALL_DAMAGE_RETURN_RESET",
  FETCH_ALL_DAMAGE_RETURN_FAILURE: "FETCH_ALL_DAMAGE_RETURN_FAILURE",
  FETCH_ALL_DAMAGE_RETURN_SUCCESS: "FETCH_ALL_DAMAGE_RETURN_SUCCESS",

  FETCH_PAGED_DAMAGE_RETURN: "FETCH_PAGED_DAMAGE_RETURN",
  FETCH_PAGED_DAMAGE_RETURN_RESET: "FETCH_PAGED_DAMAGE_RETURN_RESET",
  FETCH_PAGED_DAMAGE_RETURN_FAILURE: "FETCH_PAGED_DAMAGE_RETURN_FAILURE",
  FETCH_PAGED_DAMAGE_RETURN_SUCCESS: "FETCH_PAGED_DAMAGE_RETURN_SUCCESS",

  FETCH_ONE_DAMAGE_RETURN: "FETCH_ONE_DAMAGE_RETURN",
  FETCH_ONE_DAMAGE_RETURN_RESET: "FETCH_ONE_DAMAGE_RETURN_RESET",
  FETCH_ONE_DAMAGE_RETURN_FAILURE: "FETCH_ONE_DAMAGE_RETURN_FAILURE",
  FETCH_ONE_DAMAGE_RETURN_SUCCESS: "FETCH_ONE_DAMAGE_RETURN_SUCCESS",

  FETCH_REPORT_DAMAGE_RETURN: "FETCH_REPORT_DAMAGE_RETURN",
  FETCH_REPORT_DAMAGE_RETURN_RESET: "FETCH_REPORT_DAMAGE_RETURN_RESET",
  FETCH_REPORT_DAMAGE_RETURN_FAILURE: "FETCH_REPORT_DAMAGE_RETURN_FAILURE",
  FETCH_REPORT_DAMAGE_RETURN_SUCCESS: "FETCH_REPORT_DAMAGE_RETURN_SUCCESS",
};
