import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { DraftActionTypes } from "./Draft.type";

export function* fetchAllDrafts(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/draft`);
    yield put({
      type: DraftActionTypes.FETCH_ALL_DRAFT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DraftActionTypes.FETCH_ALL_DRAFT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneDrafts(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/draft/${action.payload}`);
    yield put({
      type: DraftActionTypes.FETCH_ONE_DRAFT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DraftActionTypes.FETCH_ONE_DRAFT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllDrafts() {
  yield takeLatest(DraftActionTypes.FETCH_ALL_DRAFT, fetchAllDrafts);
}

export function* watcherFetchOneDrafts() {
  yield takeLatest(DraftActionTypes.FETCH_ONE_DRAFT, fetchOneDrafts);
}
