import { UploadDocument } from "../UploadDocument/UploadDocument.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type SharedDocument = {
  id: number;
  user_id: number;
  document_id: number;
  shared_to_id: number;

  user: User;
  shared_to: User;
  upload_document: UploadDocument;
};

export type SharedDocumentStateTypes = {
  fetchAll: ApiCallState<SharedDocument[]>;
  fetchUsers: ApiCallState<User[]>;
};

export const SharedDocumentActionTypes = {
  FETCH_ALL_SHARED_DOCUMENT: "FETCH_ALL_SHARED_DOCUMENT",
  FETCH_ALL_SHARED_DOCUMENT_RESET: "FETCH_ALL_SHARED_DOCUMENT_RESET",
  FETCH_ALL_SHARED_DOCUMENT_FAILURE: "FETCH_ALL_SHARED_DOCUMENT_FAILURE",
  FETCH_ALL_SHARED_DOCUMENT_SUCCESS: "FETCH_ALL_SHARED_DOCUMENT_SUCCESS",

  FETCH_ALL_SHARED_USER: "FETCH_ALL_SHARED_USER",
  FETCH_ALL_SHARED_USER_RESET: "FETCH_ALL_SHARED_USER_RESET",
  FETCH_ALL_SHARED_USER_FAILURE: "FETCH_ALL_SHARED_USER_FAILURE",
  FETCH_ALL_SHARED_USER_SUCCESS: "FETCH_ALL_SHARED_USER_SUCCESS",
};
