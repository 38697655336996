import { ApiCallState } from "../Utils";

export type Draft = {
  id: number;

  user_id: number;
  type: string;
  description: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type DraftStateTypes = {
  fetchAll: ApiCallState<Draft[]>;
  fetchOne: ApiCallState<Draft | {}>;
};

export const DraftActionTypes = {
  FETCH_ALL_DRAFT: "FETCH_ALL_DRAFT",
  FETCH_ALL_DRAFT_RESET: "FETCH_ALL_DRAFT_RESET",
  FETCH_ALL_DRAFT_FAILURE: "FETCH_ALL_DRAFT_FAILURE",
  FETCH_ALL_DRAFT_SUCCESS: "FETCH_ALL_DRAFT_SUCCESS",

  FETCH_ONE_DRAFT: "FETCH_ONE_DRAFT",
  FETCH_ONE_DRAFT_RESET: "FETCH_ONE_DRAFT_RESET",
  FETCH_ONE_DRAFT_FAILURE: "FETCH_ONE_DRAFT_FAILURE",
  FETCH_ONE_DRAFT_SUCCESS: "FETCH_ONE_DRAFT_SUCCESS",
};
