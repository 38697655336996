import { HolidayStateTypes, HolidayActionTypes } from "./Holiday.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: HolidayStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const HolidayReducer = (
  state: HolidayStateTypes = INITIAL_STATE,
  action: any
): HolidayStateTypes => {
  switch (action.type) {
    case HolidayActionTypes.FETCH_ALL_HOLIDAY:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case HolidayActionTypes.FETCH_ALL_HOLIDAY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case HolidayActionTypes.FETCH_ALL_HOLIDAY_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case HolidayActionTypes.FETCH_ALL_HOLIDAY_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case HolidayActionTypes.FETCH_ONE_HOLIDAY:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case HolidayActionTypes.FETCH_ONE_HOLIDAY_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState({}),
      };
    case HolidayActionTypes.FETCH_ONE_HOLIDAY_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case HolidayActionTypes.FETCH_ONE_HOLIDAY_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default HolidayReducer;
