import { InitPagedData } from "../Utils";
import {
  ManufacturingOrderStateTypes,
  ManufacturingOrderActionTypes,
} from "./ManufacturingOrder.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ManufacturingOrderStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchReport: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchPaged: resetApiCallState(InitPagedData),
};

const ManufacturingOrderReducer = (
  state: ManufacturingOrderStateTypes = INITIAL_STATE,
  action: any
): ManufacturingOrderStateTypes => {
  switch (action.type) {
    case ManufacturingOrderActionTypes.FETCH_ALL_MANUFACTURING_ORDER:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ManufacturingOrderActionTypes.FETCH_ALL_MANUFACTURING_ORDER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ManufacturingOrderActionTypes.FETCH_ALL_MANUFACTURING_ORDER_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ManufacturingOrderActionTypes.FETCH_ALL_MANUFACTURING_ORDER_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };
    ///////////

    case ManufacturingOrderActionTypes.FETCH_REPORT_MANUFACTURING_ORDER:
      return {
        ...state,
        fetchReport: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ManufacturingOrderActionTypes.FETCH_REPORT_MANUFACTURING_ORDER_RESET:
      return {
        ...state,
        fetchReport: resetApiCallState([]),
      };
    case ManufacturingOrderActionTypes.FETCH_REPORT_MANUFACTURING_ORDER_FAILURE:
      return {
        ...state,
        fetchReport: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ManufacturingOrderActionTypes.FETCH_REPORT_MANUFACTURING_ORDER_SUCCESS:
      return {
        ...state,
        fetchReport: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    //////////
    case ManufacturingOrderActionTypes.FETCH_PAGED_MANUFACTURING_ORDER:
      return {
        ...state,
        fetchPaged: {
          error: null,
          payload: InitPagedData,
          isPending: true,
          isSuccessful: false,
        },
      };
    case ManufacturingOrderActionTypes.FETCH_PAGED_MANUFACTURING_ORDER_RESET:
      return {
        ...state,
        fetchPaged: resetApiCallState(InitPagedData),
      };
    case ManufacturingOrderActionTypes.FETCH_PAGED_MANUFACTURING_ORDER_FAILURE:
      return {
        ...state,
        fetchPaged: {
          payload: InitPagedData,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ManufacturingOrderActionTypes.FETCH_PAGED_MANUFACTURING_ORDER_SUCCESS:
      return {
        ...state,
        fetchPaged: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ManufacturingOrderActionTypes.FETCH_ONE_MANUFACTURING_ORDER:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ManufacturingOrderActionTypes.FETCH_ONE_MANUFACTURING_ORDER_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ManufacturingOrderActionTypes.FETCH_ONE_MANUFACTURING_ORDER_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ManufacturingOrderActionTypes.FETCH_ONE_MANUFACTURING_ORDER_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ManufacturingOrderReducer;
