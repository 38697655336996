import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { CriticalIssueActionTypes } from "./CriticalIssue.type";

export function* fetchAllCriticalIssues(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/critical-issue?project_id=${action.payload?.project_id}`);
    yield put({
      type: CriticalIssueActionTypes.FETCH_ALL_CRITICAL_ISSUE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: CriticalIssueActionTypes.FETCH_ALL_CRITICAL_ISSUE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneCriticalIssues(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/critical-issue/detail?project_id=${action.payload?.project_id}&date=${action.payload?.date}`
    );
    yield put({
      type: CriticalIssueActionTypes.FETCH_ONE_CRITICAL_ISSUE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: CriticalIssueActionTypes.FETCH_ONE_CRITICAL_ISSUE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllCriticalIssues() {
  yield takeLatest(CriticalIssueActionTypes.FETCH_ALL_CRITICAL_ISSUE, fetchAllCriticalIssues);
}

export function* watcherFetchOneCriticalIssues() {
  yield takeLatest(CriticalIssueActionTypes.FETCH_ONE_CRITICAL_ISSUE, fetchOneCriticalIssues);
}
