import { ApiCallState } from "../Utils";
import { Document } from "../Document/Document.type";

export type ContractAdministration = {
  id: number | null;
  project_id?: number;
  date: string;
  reference_no: string;
  name: string;
  type: string;
  uploaded_by: number;
  sub_contractor_name: string;
  sub_contractor_amount: number;
  expiry_date: string;
  amount: number;
  contract_administration_type: "project" | "sub-contract";
  bank_insurance: string;
  bond_type: string;
  document: Document;
  project_name?: string;
  contract_administration_extensions: ContractAdministrationExtension[];
  sum_insured:number;
  cpv_no:string;
  client:string;
  type_of_machinaryies:string;
  plate_no:string;
  chassis_no:string;
  engine_no:string;
  horse_power:string;
  year_of_mfg:string;
};

export type ContractAdministrationExtension = {
  id: number;
  contract_administration_id: number;
  extended_date: string;
  expiration_date: string;
  payment: number;
};

export type ContractAdministrationStateTypes = {
  fetchAll: ApiCallState<ContractAdministration[]>;
  fetchOne: ApiCallState<ContractAdministration | {}>;
};

export const ContractAdministrationActionTypes = {
  FETCH_ALL_CONTRACT_ADMINISTRATION: "FETCH_ALL_CONTRACT_ADMINISTRATION",
  FETCH_ALL_CONTRACT_ADMINISTRATION_RESET:
    "FETCH_ALL_CONTRACT_ADMINISTRATION_RESET",
  FETCH_ALL_CONTRACT_ADMINISTRATION_FAILURE:
    "FETCH_ALL_CONTRACT_ADMINISTRATION_FAILURE",
  FETCH_ALL_CONTRACT_ADMINISTRATION_SUCCESS:
    "FETCH_ALL_CONTRACT_ADMINISTRATION_SUCCESS",

  FETCH_ONE_CONTRACT_ADMINISTRATION: "FETCH_ONE_CONTRACT_ADMINISTRATION",
  FETCH_ONE_CONTRACT_ADMINISTRATION_RESET:
    "FETCH_ONE_CONTRACT_ADMINISTRATION_RESET",
  FETCH_ONE_CONTRACT_ADMINISTRATION_FAILURE:
    "FETCH_ONE_CONTRACT_ADMINISTRATION_FAILURE",
  FETCH_ONE_CONTRACT_ADMINISTRATION_SUCCESS:
    "FETCH_ONE_CONTRACT_ADMINISTRATION_SUCCESS",
};
