import { ActivityWaitageStateTypes, ActivityWaitageActionTypes } from "./ActivityWaitage.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ActivityWaitageStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const ActivityWaitageReducer = (
  state: ActivityWaitageStateTypes = INITIAL_STATE,
  action: any
): ActivityWaitageStateTypes => {
  switch (action.type) {
    case ActivityWaitageActionTypes.FETCH_ALL_ACTIVITY_WAITAGE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ActivityWaitageActionTypes.FETCH_ALL_ACTIVITY_WAITAGE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ActivityWaitageActionTypes.FETCH_ALL_ACTIVITY_WAITAGE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ActivityWaitageActionTypes.FETCH_ALL_ACTIVITY_WAITAGE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ActivityWaitageActionTypes.FETCH_ONE_ACTIVITY_WAITAGE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ActivityWaitageActionTypes.FETCH_ONE_ACTIVITY_WAITAGE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ActivityWaitageActionTypes.FETCH_ONE_ACTIVITY_WAITAGE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ActivityWaitageActionTypes.FETCH_ONE_ACTIVITY_WAITAGE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ActivityWaitageReducer;
