import { ApiCallState } from "../Utils";

export type MikadaWeeklyProgress= {
  id: number;
  date: string;
  remark: string;
  project_id: number;
  user_id: number;
};

export type MikadaWeeklyProgressStateTypes = {
  fetchAll: ApiCallState<MikadaWeeklyProgress[]>;
  fetchOne: ApiCallState<MikadaWeeklyProgress | {}>;
};

export const MikadaWeeklyProgressActionTypes = {
  FETCH_ALL_MIKADA_WEEKLY_PROGRESS: "FETCH_ALL_MIKADA_WEEKLY_PROGRESS",
  FETCH_ALL_MIKADA_WEEKLY_PROGRESS_RESET: "FETCH_ALL_MIKADA_WEEKLY_PROGRESS_RESET",
  FETCH_ALL_MIKADA_WEEKLY_PROGRESS_FAILURE: "FETCH_ALL_MIKADA_WEEKLY_PROGRESS_FAILURE",
  FETCH_ALL_MIKADA_WEEKLY_PROGRESS_SUCCESS: "FETCH_ALL_MIKADA_WEEKLY_PROGRESS_SUCCESS",

  FETCH_ONE_MIKADA_WEEKLY_PROGRESS: "FETCH_ONE_MIKADA_WEEKLY_PROGRESS",
  FETCH_ONE_MIKADA_WEEKLY_PROGRESS_RESET: "FETCH_ONE_MIKADA_WEEKLY_PROGRESS_RESET",
  FETCH_ONE_MIKADA_WEEKLY_PROGRESS_FAILURE: "FETCH_ONE_MIKADA_WEEKLY_PROGRESS_FAILURE",
  FETCH_ONE_MIKADA_WEEKLY_PROGRESS_SUCCESS: "FETCH_ONE_MIKADA_WEEKLY_PROGRESS_SUCCESS",
};
