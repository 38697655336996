import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { StaffReligionActionTypes } from "./StaffReligion.type";

export function* fetchAllStaffReligions(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/staff-religion?staff_id=${action.payload?.staff_id}`);
    yield put({
      type: StaffReligionActionTypes.FETCH_ALL_STAFF_RELIGION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffReligionActionTypes.FETCH_ALL_STAFF_RELIGION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneStaffReligions(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/staff-religion/${action.payload}`
    );
    yield put({
      type: StaffReligionActionTypes.FETCH_ONE_STAFF_RELIGION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffReligionActionTypes.FETCH_ONE_STAFF_RELIGION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllStaffReligions() {
  yield takeLatest(StaffReligionActionTypes.FETCH_ALL_STAFF_RELIGION, fetchAllStaffReligions);
}

export function* watcherFetchOneStaffReligions() {
  yield takeLatest(StaffReligionActionTypes.FETCH_ONE_STAFF_RELIGION, fetchOneStaffReligions);
}
