import { InitPagedData } from "../Utils";
import {
  FixedAssetInStateTypes,
  FixedAssetInActionTypes,
} from "./FixedAssetIn.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: FixedAssetInStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchReport: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchPaged: resetApiCallState(InitPagedData),
};

const FixedAssetInReducer = (
  state: FixedAssetInStateTypes = INITIAL_STATE,
  action: any
): FixedAssetInStateTypes => {
  switch (action.type) {
    case FixedAssetInActionTypes.FETCH_ALL_FIXED_ASSET_IN:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case FixedAssetInActionTypes.FETCH_ALL_FIXED_ASSET_IN_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case FixedAssetInActionTypes.FETCH_ALL_FIXED_ASSET_IN_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FixedAssetInActionTypes.FETCH_ALL_FIXED_ASSET_IN_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };
    ///////////

    case FixedAssetInActionTypes.FETCH_REPORT_FIXED_ASSET_IN:
      return {
        ...state,
        fetchReport: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case FixedAssetInActionTypes.FETCH_REPORT_FIXED_ASSET_IN_RESET:
      return {
        ...state,
        fetchReport: resetApiCallState([]),
      };
    case FixedAssetInActionTypes.FETCH_REPORT_FIXED_ASSET_IN_FAILURE:
      return {
        ...state,
        fetchReport: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FixedAssetInActionTypes.FETCH_REPORT_FIXED_ASSET_IN_SUCCESS:
      return {
        ...state,
        fetchReport: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    //////////
    case FixedAssetInActionTypes.FETCH_PAGED_FIXED_ASSET_IN:
      return {
        ...state,
        fetchPaged: {
          error: null,
          payload: InitPagedData,
          isPending: true,
          isSuccessful: false,
        },
      };
    case FixedAssetInActionTypes.FETCH_PAGED_FIXED_ASSET_IN_RESET:
      return {
        ...state,
        fetchPaged: resetApiCallState(InitPagedData),
      };
    case FixedAssetInActionTypes.FETCH_PAGED_FIXED_ASSET_IN_FAILURE:
      return {
        ...state,
        fetchPaged: {
          payload: InitPagedData,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FixedAssetInActionTypes.FETCH_PAGED_FIXED_ASSET_IN_SUCCESS:
      return {
        ...state,
        fetchPaged: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case FixedAssetInActionTypes.FETCH_ONE_FIXED_ASSET_IN:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case FixedAssetInActionTypes.FETCH_ONE_FIXED_ASSET_IN_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case FixedAssetInActionTypes.FETCH_ONE_FIXED_ASSET_IN_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FixedAssetInActionTypes.FETCH_ONE_FIXED_ASSET_IN_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default FixedAssetInReducer;
