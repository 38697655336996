import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { BudgetShiftingRequestActionTypes } from "./BudgetShiftingRequest.type";

export function* fetchAllBudgetShiftingRequests(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/budget-shifting-request?project_id=${action.payload.project_id}`);
    yield put({
      type: BudgetShiftingRequestActionTypes.FETCH_ALL_BUDGET_SHIFTING_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BudgetShiftingRequestActionTypes.FETCH_ALL_BUDGET_SHIFTING_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneBudgetShiftingRequests(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/budget-shifting-request/${action.payload}`
    );
    yield put({
      type: BudgetShiftingRequestActionTypes.FETCH_ONE_BUDGET_SHIFTING_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BudgetShiftingRequestActionTypes.FETCH_ONE_BUDGET_SHIFTING_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllBudgetShiftingRequests() {
  yield takeLatest(BudgetShiftingRequestActionTypes.FETCH_ALL_BUDGET_SHIFTING_REQUEST, fetchAllBudgetShiftingRequests);
}

export function* watcherFetchOneBudgetShiftingRequests() {
  yield takeLatest(BudgetShiftingRequestActionTypes.FETCH_ONE_BUDGET_SHIFTING_REQUEST, fetchOneBudgetShiftingRequests);
}
