import { ApiCallState } from "../Utils";

export type AttendanceLocationUser = {
  id: number;

  attendance_location_ids: number[];
  user_id: number;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type AttendanceLocationUserStateTypes = {
  fetchAll: ApiCallState<AttendanceLocationUser[]>;
  fetchOne: ApiCallState<AttendanceLocationUser | {}>;
};

export const AttendanceLocationUserActionTypes = {
  FETCH_ALL_ATTENDANCE_LOCATION_USER: "FETCH_ALL_ATTENDANCE_LOCATION_USER",
  FETCH_ALL_ATTENDANCE_LOCATION_USER_RESET:
    "FETCH_ALL_ATTENDANCE_LOCATION_USER_RESET",
  FETCH_ALL_ATTENDANCE_LOCATION_USER_FAILURE:
    "FETCH_ALL_ATTENDANCE_LOCATION_USER_FAILURE",
  FETCH_ALL_ATTENDANCE_LOCATION_USER_SUCCESS:
    "FETCH_ALL_ATTENDANCE_LOCATION_USER_SUCCESS",

  FETCH_ONE_ATTENDANCE_LOCATION_USER: "FETCH_ONE_ATTENDANCE_LOCATION_USER",
  FETCH_ONE_ATTENDANCE_LOCATION_USER_RESET:
    "FETCH_ONE_ATTENDANCE_LOCATION_USER_RESET",
  FETCH_ONE_ATTENDANCE_LOCATION_USER_FAILURE:
    "FETCH_ONE_ATTENDANCE_LOCATION_USER_FAILURE",
  FETCH_ONE_ATTENDANCE_LOCATION_USER_SUCCESS:
    "FETCH_ONE_ATTENDANCE_LOCATION_USER_SUCCESS",
};
