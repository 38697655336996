import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { VehicleListActionTypes } from "./VehicleList.type";

export function* fetchAllVehicleLists(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      query = Object.keys(action.payload)
        .map((key) => `${key}=${action.payload[key]}`)
        .join("&");
    }

    const response = yield axios.get(`${API_BASE_URI}/vehicle-list?${query}`);
    yield put({
      type: VehicleListActionTypes.FETCH_ALL_VEHICLE_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: VehicleListActionTypes.FETCH_ALL_VEHICLE_LIST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneVehicleLists(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/vehicle-list/${action.payload}`
    );
    yield put({
      type: VehicleListActionTypes.FETCH_ONE_VEHICLE_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: VehicleListActionTypes.FETCH_ONE_VEHICLE_LIST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllVehicleLists() {
  yield takeLatest(
    VehicleListActionTypes.FETCH_ALL_VEHICLE_LIST,
    fetchAllVehicleLists
  );
}

export function* watcherFetchOneVehicleLists() {
  yield takeLatest(
    VehicleListActionTypes.FETCH_ONE_VEHICLE_LIST,
    fetchOneVehicleLists
  );
}
