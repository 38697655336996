import { OwnVehicle } from "../OwnVehicle/OwnVehicle.type";
import { Staff } from "../Staff/Staff.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type ExternalRepairOrder = {
  id: number;
  date: string;
  staff_id: number;
  phone_no: string;
  engine_no: string;
  own_vehicle_id: number;
  requested_by_id: number;
  requested_by_date: string;
  approved_by_id: number;
  approved_by_date: string;
  status:string;
  is_approved:boolean;
  own_vehicle: OwnVehicle;
  requested_by: User;
  approved_by: User;
  staff: Staff;
  external_repair_order_items: ExternalRepairOrderItem[];
};

export type ExternalRepairOrderItem = {
  id: number;
  external_repair_order_id: number;
  description: string;
};

export type ExternalRepairOrderStateTypes = {
  fetchAll: ApiCallState<ExternalRepairOrder[]>;
  fetchOne: ApiCallState<ExternalRepairOrder | {}>;
};

export const ExternalRepairOrderActionTypes = {
  FETCH_ALL_EXTERNAL_REPAIR_ORDER: "FETCH_ALL_EXTERNAL_REPAIR_ORDER",
  FETCH_ALL_EXTERNAL_REPAIR_ORDER_RESET:
    "FETCH_ALL_EXTERNAL_REPAIR_ORDER_RESET",
  FETCH_ALL_EXTERNAL_REPAIR_ORDER_FAILURE:
    "FETCH_ALL_EXTERNAL_REPAIR_ORDER_FAILURE",
  FETCH_ALL_EXTERNAL_REPAIR_ORDER_SUCCESS:
    "FETCH_ALL_EXTERNAL_REPAIR_ORDER_SUCCESS",

  FETCH_ONE_EXTERNAL_REPAIR_ORDER: "FETCH_ONE_EXTERNAL_REPAIR_ORDER",
  FETCH_ONE_EXTERNAL_REPAIR_ORDER_RESET:
    "FETCH_ONE_EXTERNAL_REPAIR_ORDER_RESET",
  FETCH_ONE_EXTERNAL_REPAIR_ORDER_FAILURE:
    "FETCH_ONE_EXTERNAL_REPAIR_ORDER_FAILURE",
  FETCH_ONE_EXTERNAL_REPAIR_ORDER_SUCCESS:
    "FETCH_ONE_EXTERNAL_REPAIR_ORDER_SUCCESS",
};
