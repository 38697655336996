import { ApiCallState } from "../Utils";

export type MikadaWeeklyMaterialReport= {
  id: number;
  date: string;
  remark: string;
  material_id: number;
  user_id: number;
};

export type MikadaWeeklyMaterialReportStateTypes = {
  fetchAll: ApiCallState<MikadaWeeklyMaterialReport[]>;
  fetchOne: ApiCallState<MikadaWeeklyMaterialReport | {}>;
};

export const MikadaWeeklyMaterialReportActionTypes = {
  FETCH_ALL_MIKADA_WEEKLY_MATERIAL_REPORT: "FETCH_ALL_MIKADA_WEEKLY_MATERIAL_REPORT",
  FETCH_ALL_MIKADA_WEEKLY_MATERIAL_REPORT_RESET: "FETCH_ALL_MIKADA_WEEKLY_MATERIAL_REPORT_RESET",
  FETCH_ALL_MIKADA_WEEKLY_MATERIAL_REPORT_FAILURE: "FETCH_ALL_MIKADA_WEEKLY_MATERIAL_REPORT_FAILURE",
  FETCH_ALL_MIKADA_WEEKLY_MATERIAL_REPORT_SUCCESS: "FETCH_ALL_MIKADA_WEEKLY_MATERIAL_REPORT_SUCCESS",

  FETCH_ONE_MIKADA_WEEKLY_MATERIAL_REPORT: "FETCH_ONE_MIKADA_WEEKLY_MATERIAL_REPORT",
  FETCH_ONE_MIKADA_WEEKLY_MATERIAL_REPORT_RESET: "FETCH_ONE_MIKADA_WEEKLY_MATERIAL_REPORT_RESET",
  FETCH_ONE_MIKADA_WEEKLY_MATERIAL_REPORT_FAILURE: "FETCH_ONE_MIKADA_WEEKLY_MATERIAL_REPORT_FAILURE",
  FETCH_ONE_MIKADA_WEEKLY_MATERIAL_REPORT_SUCCESS: "FETCH_ONE_MIKADA_WEEKLY_MATERIAL_REPORT_SUCCESS",
};
