import axios from "axios";
import { put, takeLatest } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { FuelReceiveActionTypes } from "./FuelReceive.type";
import { formatQuery } from "../Utils";

export function* fetchAllFuelReceives(action: any): any {
  try {
    const query = formatQuery(action);

    const response = yield axios.get(`${API_BASE_URI}/fuel-receive?${query}`);

    yield put({
      type: FuelReceiveActionTypes.FETCH_ALL_FUEL_RECEIVE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FuelReceiveActionTypes.FETCH_ALL_FUEL_RECEIVE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneFuelReceives(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/fuel-receive/${action.payload}`
    );
    yield put({
      type: FuelReceiveActionTypes.FETCH_ONE_FUEL_RECEIVE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FuelReceiveActionTypes.FETCH_ONE_FUEL_RECEIVE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchFuelReceiveNumber(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/fuel-receive/number`);
    yield put({
      type: FuelReceiveActionTypes.FETCH_FUEL_RECEIVE_NUMBER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FuelReceiveActionTypes.FETCH_FUEL_RECEIVE_NUMBER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllFuelReceives() {
  yield takeLatest(
    FuelReceiveActionTypes.FETCH_ALL_FUEL_RECEIVE,
    fetchAllFuelReceives
  );
}

export function* watcherFetchOneFuelReceives() {
  yield takeLatest(
    FuelReceiveActionTypes.FETCH_ONE_FUEL_RECEIVE,
    fetchOneFuelReceives
  );
}

export function* watcherFetchFuelReceiveNumber() {
  yield takeLatest(
    FuelReceiveActionTypes.FETCH_FUEL_RECEIVE_NUMBER,
    fetchFuelReceiveNumber
  );
}
