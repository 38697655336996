import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ProgressSummaryActionTypes } from "./ProgressSummary.type";

export function* fetchAllProgressSummarys(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/progress-summary?project_id=${action.payload?.project_id}&date=${action.payload?.date}`);
    yield put({
      type: ProgressSummaryActionTypes.FETCH_ALL_PROGRESS_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProgressSummaryActionTypes.FETCH_ALL_PROGRESS_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneProgressSummarys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/progress-summary/${action.payload}`
    );
    yield put({
      type: ProgressSummaryActionTypes.FETCH_ONE_PROGRESS_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ProgressSummaryActionTypes.FETCH_ONE_PROGRESS_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllProgressSummarys() {
  yield takeLatest(ProgressSummaryActionTypes.FETCH_ALL_PROGRESS_SUMMARY, fetchAllProgressSummarys);
}

export function* watcherFetchOneProgressSummarys() {
  yield takeLatest(ProgressSummaryActionTypes.FETCH_ONE_PROGRESS_SUMMARY, fetchOneProgressSummarys);
}
