import { SMSMessageStateTypes, SMSMessageActionTypes } from "./SMSMessage.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: SMSMessageStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const SMSMessageReducer = (
  state: SMSMessageStateTypes = INITIAL_STATE,
  action: any
): SMSMessageStateTypes => {
  switch (action.type) {
    case SMSMessageActionTypes.FETCH_ALL_SMS_MESSAGE:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case SMSMessageActionTypes.FETCH_ALL_SMS_MESSAGE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case SMSMessageActionTypes.FETCH_ALL_SMS_MESSAGE_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SMSMessageActionTypes.FETCH_ALL_SMS_MESSAGE_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case SMSMessageActionTypes.FETCH_ONE_SMS_MESSAGE:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case SMSMessageActionTypes.FETCH_ONE_SMS_MESSAGE_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState({}),
      };
    case SMSMessageActionTypes.FETCH_ONE_SMS_MESSAGE_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case SMSMessageActionTypes.FETCH_ONE_SMS_MESSAGE_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default SMSMessageReducer;
