import { AutoCompleteProps, InputNumberProps, StatisticProps } from "antd";
import moment, { Moment } from "moment";
import { find, groupBy, isArray, isNil, toNumber } from "lodash";
import axios, { AxiosError } from "axios";
import { User } from "../redux/User/User.type";
import convert from "convert-units";
import xlsx, { WorkBook } from "xlsx";
import {
  ApprovalValue,
  BoqSuperTitle,
  ETHIOPIAN_MONTHS,
  ProjectTypes,
  WeightRebar,
  UNITS,
  ConversionRate,
  REBAR_LENGTH,
  TITLES,
  NotificationType,
  Message,
  MODULE,
} from "../constants/Constants";
import * as XLSX from "xlsx";
import BuildingBoQ from "./excel/BuildingBoQ";
import { BoQRegistrationStructure } from "../components/Project/ProjectRegistration/components/BoQ/BoQ.util";
import { getDateValue } from "../components/common/EthiopianDatePicker/EthiopianDatePicker.util";
import { RuleObject } from "antd/lib/form";
import { Boq } from "../redux/Boq/Boq.type";
import { ApiCallState } from "../redux/Utils";
import { MasterSchedule } from "../redux/MasterSchedule/MasterSchedule.type";
import MasterScheduleExcel from "./excel/MasterSchedule";
import { Material, MaterialUnitPrice } from "../redux/Material/Material.type";
import { MaterialInventory } from "../redux/MaterialInventory/MaterialInventory.type";
import { Project } from "../redux/Project/Project.type";
import { isNumber } from "lodash";
import BillSummary from "./excel/BillSummary";
import { SelectProps } from "antd/lib/select";
import { API_BASE_URI } from "../redux/ApiCall";
import { Document } from "../redux/Document/Document.type";
import { OpenNotification } from "../components/common/Notification/Notification.component";
import BuildingSiteAttendance from "./excel/BuildingSiteAttendance";
import BuildingBudget from "./excel/BuildingBudget";
import BuildingIllegalReceipt from "./excel/BuildingIllegalReceipt";
import BuildingStandardsBoQ from "../constants/BuildingStandardsBoq";
import BuildingChartsOfAccount from "./excel/BuildingChartsOfAccount";
/////
import ProjectPermanentAttendance from "./excel/ProjectPermanentAttendance";
import { UserAccess } from "../redux/UserRole/UserRole.type";
import { USER_ACCESSES } from "../components/User/UserRole/utils/UserRole.util";
let ethiopic = require("ethiopic-js");

export const EtRegEx = /^(^\+251|^251|^0)?9\d{8}$/;
export const NumRegEx = /^[0-9]+$/;
export const WordsRegEx = /^[a-zA-Z_ ]*$/;

// export const formatNumber = (x: any) => {
//   return x?.toLocaleString("en", {
//     minimumFractionDigits: 2,
//     maximumFractionDigits: 2,
//   });
//   // return x;
// };

export const formatNumberDate = (noOfDate: string) => {
  const startingDate = moment()
    .date(1)
    .month(0)
    .year(1900)
    .add(parseInt(noOfDate, 10) - 2, "days");
  return startingDate;
};

export const onChangeHandler = (
  key: number,
  name: string,
  value: any,
  data: any[],
  setData: Function
) => {
  const newData = [...data];

  const index = newData.findIndex((e) => e.key === key);
  if (index !== -1) {
    let item = newData[index];
    item = {
      ...item,
      [name]: value,
    };
    newData.splice(index, 1, item);
    setData(newData);
  }
};

export const getUrl = (key: string) => {
  return key?.toLocaleLowerCase()?.split(" ").join("-");
};

export const formatSheetDate = (sheetDate: string) => {
  const divided = sheetDate?.trim()?.split(" _ ");
  const unformattedStartDate = divided?.[0];
  const unformattedEndDate = divided?.[1];

  const startDate = unformattedStartDate?.split(":- ")?.[1]?.split(" ")?.[0];
  const startMonth = unformattedStartDate?.split(":- ")?.[1]?.split(" ")?.[1];

  const endDate = unformattedEndDate?.split(" ")?.[0];
  const endMonth = unformattedEndDate?.split(" ")?.[1];
  const endYear = unformattedEndDate?.split(" ")?.[2];

  return {
    start_date: moment(
      `${startDate}-${startMonth}-${endYear}`,
      "DD-MMMM-YYYY"
    )?.format("YYYY-MM-DD"),
    end_date: moment(
      `${endDate}-${endMonth}-${endYear}`,
      "DD-MMMM-YYYY"
    )?.format("YYYY-MM-DD"),
  };
};

export const searchProp: SelectProps = {
  showSearch: true,
  optionFilterProp: "children",
  filterOption: (input: any, option: any) =>
    (option.props.children ?? "")
      .toString()
      .toLowerCase()
      .indexOf((input ?? "").toString().toLowerCase()) >= 0,
  className: "w-100",
};

export const getUniqueListBy = (arr: any[], key: string) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export const handleDeletedChange = (
  data: any,
  index: any,
  state: any,
  setState: any
) => {
  let st = data;
  st.isEdited = true;
  st.isDeleted = true;
  state[index] = st;
  setState([...state]);
};

export const handleEditedChange = (
  value: any,
  key: string,
  data: any,
  index: any,
  state: any,
  setState: any
) => {
  let st = data;
  st[key] = value;
  st.isEdited = true;
  state[index] = st;
  setState([...state]);
};

export const getLastNo = (data: any[]) => {
  if (data) {
    const length = data?.length;
    if (length === 0) return 1;
    else return data[length - 1].no + 1;
  } else return 1;
};

export const MirabReportType = {
  DAILY: "Daily",
  WEEKLY: "Weekly summary report",
  MONTHLY: "Monthly summary report",
  ANNUAL: "Annual summary report",
  WEEKLY_PROGRESS_SUMMARY: "Weekly progress summary",
};

export const statisticProp: StatisticProps = {
  valueStyle: { fontSize: 16, fontFamily: "Campton-Medium" },
};

export const formatNumber = (x: string | number) => {
  if (isNil(x)) {
    return 0;
  } else {
    var val = Math.round(Number(x!) * 100) / 100;
    var parts = val.toString().split(".");
    var num =
      parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
      (parts[1] ? "." + parts[1] : "");
    return num;
  }
};

export const formatReportNumber = (x: string | number) => {
  if (isNil(x) || x === "") {
    return "";
  } else {
    var val = Math.round(Number(x!) * 100) / 100;
    var parts = val.toString().split(".");
    var num =
      parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
      (parts[1] ? "." + parts[1] : "");
    return num;
  }
};

export const convertToNumber = (num: number | string | undefined) => {
  if (num) {
    if (isNumber(num)) {
      return num;
    } else {
      return parseFloat(num);
    }
  } else {
    return 0;
  }
};

export const ParseDistributionLabel = (label: string) => {
  if (
    label === "estimate" ||
    label === "percentage" ||
    label === "other" ||
    label === "total"
  ) {
    return label.charAt(0).toUpperCase() + label.slice(1);
  } else {
    return label;
  }
};

export const handleChange = (
  value: any,
  key: any,
  data: any,
  index: any,
  state: any,
  setState: Function
) => {
  let st = data;
  st[key] = value;
  state[index] = st;
  setState([...state]);
};

export const generatePassword = () => {
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var result = "";
  var charactersLength = characters.length;

  for (var i = 0; i < 10; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getCompany = (
  access_type: "consultant" | "contractor" | "client",
  project: Project
) => {
  if (access_type === "consultant") return project.consultant?.name;
  else if (access_type === "client") return project.client?.name;
  else if (access_type === "contractor") return project.contractor?.name;
};

export const standardDeviation = (values: number[]) => {
  var avg = average(values);

  var squareDiffs = values.map((value) => {
    var diff = value - avg;
    var sqrDiff = diff * diff;
    return sqrDiff;
  });

  var avgSquareDiff = average(squareDiffs);

  var stdDev = Math.sqrt(avgSquareDiff);
  return stdDev;
};

const average = (data: number[]) => {
  var sum = data.reduce((sum, value) => {
    return sum + value;
  }, 0);

  var avg = sum / data.length;
  return avg;
};

export const generateRandomStr = (length: number = 10) => {
  const characters =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let result = "";
  let charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const format = (
  data: any,
  type?: boolean,
  isPercent?: boolean
): string => {
  if (data || type) {
    return data === 0
      ? type
        ? "0"
        : "-"
      : `${eEnglish(toNumber(toNumber(data).toFixed(2)))} ${
          isPercent ? " %" : ""
        }`;
  } else if (data === "-") return "";
  else return "-";
};

export const autoCompleteProp: AutoCompleteProps = {
  filterOption: (inputValue, option: any) =>
    option!.value?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1,
  className: "w-100",
};

export const calculateBonus = (val: number, salary: any) => {
  let monthlyBonus = val / 12;
  let aggregate = monthlyBonus + salary;
  let aggregateTax = calculateIncomeTax(aggregate);
  let annualAggregateTax = aggregateTax * 12;
  let basicSalaryTax = calculateIncomeTax(salary);
  let aggregateBasicSalaryTax = 12 * basicSalaryTax;
  let bonusTax = annualAggregateTax - aggregateBasicSalaryTax;
  // let net = val - bonusTax;
  return bonusTax;
  // setTax(bonusTax);
  // setNet(net);
};

const eEnglish = (x: number) => {
  return x.toLocaleString("en-US");
};

export const zeroPad = (num: any, length: number = 4): string =>
  String(num).padStart(length, "0");

export const getRevisionNumber = (
  data: any[],
  id: number | null,
  ref: number | null
) => {
  let filtered = data?.filter((e) => e.ref === ref);

  if (id) {
    const found = data.find((e) => e.id === id);
    if (found && found.ref)
      return (
        filtered.filter((e) => moment(e.date).isBefore(moment(found.date)))
          .length + 1
      );
    else return 0;
  } else {
    return (data ? data?.filter((e) => e.ref === ref).length : 0) + 1;
  }
};

export const toEthiopianCalender = (date: Moment, type?: string) => {
  switch (type) {
    case "YYYY":
      return { format: "", ethiopian_calender: "" };
    case "MMMM-YYYY":
      return {
        format: ``,
        ethiopian_calender: ``,
      };
    case "dddd":
      return {
        format: ``,
        ethiopian_calender: ``,
      };
    default:
      return {
        format: ``,
        ethiopian_calender: ``,
      };
  }
};

export const parseBillSummaryExcel = (
  workbook: WorkBook | undefined,
  sheet_names: string[]
) => {
  const parsed: BoQRegistrationStructure[] = [];
  if (workbook) {
    sheet_names.forEach((sheet_name) => {
      const ws = workbook.Sheets[sheet_name];
      const data = xlsx.utils.sheet_to_json(ws, { header: 1 });
      const BuildingParsed = new BillSummary(data, sheet_name);
      parsed.push(...BuildingParsed.parseBoq());
    });
  }
  return parsed;
};

export const parseBuildingBudgetExcel = (
  workbook: WorkBook | undefined,
  sheet_names: string[]
) => {
  const parsed: BoQRegistrationStructure[] = [];
  if (workbook) {
    sheet_names.forEach((sheet_name) => {
      const ws = workbook.Sheets[sheet_name];
      const data = xlsx.utils.sheet_to_json(ws, { header: 1 });
      const BuildingParsed = new BuildingBudget(data, sheet_name);
      parsed.push(...BuildingParsed.parseBoq());
    });
  }
  return parsed;
};

export const parseBuildingIllegalReceiptExcel = (
  workbook: WorkBook | undefined,
  sheet_names: string[]
) => {
  const parsed: BoQRegistrationStructure[] = [];
  if (workbook) {
    sheet_names.forEach((sheet_name) => {
      const ws = workbook.Sheets[sheet_name];
      const data = xlsx.utils.sheet_to_json(ws, { header: 1 });
      const BuildingParsed = new BuildingIllegalReceipt(data, sheet_name);
      parsed.push(...BuildingParsed.parseBoq());
    });
  }
  return parsed;
};

export const parseProjectPermanentAttendanceExcel = (
  workbook: WorkBook | undefined,
  sheet_names: string[]
) => {
  const parsed: BoQRegistrationStructure[] = [];
  if (workbook) {
    sheet_names.forEach((sheet_name) => {
      const ws = workbook.Sheets[sheet_name];
      const data = xlsx.utils.sheet_to_json(ws, { header: 1 });
      const BuildingParsed = new ProjectPermanentAttendance(data, sheet_name);
      parsed.push(...BuildingParsed.parseBoq());
    });
  }
  return parsed;
};

export const parseBuildingChartsOfAccountExcel = (
  workbook: WorkBook | undefined,
  sheet_names: string[]
) => {
  const parsed: BoQRegistrationStructure[] = [];
  if (workbook) {
    sheet_names.forEach((sheet_name) => {
      const ws = workbook.Sheets[sheet_name];
      const data = xlsx.utils.sheet_to_json(ws, { header: 1 });
      const BuildingParsed = new BuildingChartsOfAccount(data, sheet_name);
      parsed.push(...BuildingParsed.parseBoq());
    });
  }
  return parsed;
};

export const getPlannedQty = (
  master_schedule: MasterSchedule,
  date: Moment,
  date_type: "week" | "month" = "month"
) => {
  if (master_schedule && !master_schedule.is_title) {
    let start_date = moment(master_schedule?.start_date);
    let end_date = moment(master_schedule?.end_date);

    let total_duration = end_date.diff(start_date, "day");

    if (
      end_date.isSame(date, date_type) &&
      start_date.isSame(date, date_type)
    ) {
      return master_schedule?.quantity;
    } else if (
      end_date.isSame(date, date_type) &&
      start_date.isBefore(date, date_type)
    ) {
      let duration = end_date.diff(date.startOf(date_type), "days");
      return (duration / total_duration) * master_schedule?.quantity;
    } else if (
      end_date.isAfter(date, date_type) &&
      start_date.isSame(date, date_type)
    ) {
      let duration = date.endOf(date_type).diff(start_date, "days");
      return (duration / total_duration) * master_schedule?.quantity;
    } else if (date.isBetween(start_date, end_date, date_type)) {
      let duration = date_type === "month" ? date.daysInMonth() : 7;
      return (duration / total_duration) * master_schedule?.quantity;
    } else return 0;
  } else {
    return 0;
  }
};

export const getPlannedAmount = (
  master_schedule: MasterSchedule,
  date: Moment,
  date_type: "week" | "month" = "month"
) => {
  if (master_schedule && !master_schedule.is_title) {
    let start_date = moment(master_schedule?.start_date);
    let end_date = moment(master_schedule?.end_date);

    let total_duration = end_date.diff(start_date, "day");

    if (
      end_date.isSame(date, date_type) &&
      start_date.isSame(date, date_type)
    ) {
      return master_schedule?.amount;
    } else if (
      end_date.isSame(date, date_type) &&
      start_date.isBefore(date, date_type)
    ) {
      let duration = end_date.diff(date.startOf(date_type), "days");
      return (duration / total_duration) * master_schedule?.amount;
    } else if (
      end_date.isAfter(date, date_type) &&
      start_date.isSame(date, date_type)
    ) {
      let duration = date.endOf(date_type).diff(start_date, "days");
      return (duration / total_duration) * master_schedule?.amount;
    } else if (date.isBetween(start_date, end_date, date_type)) {
      let duration = date_type === "month" ? date.daysInMonth() : 7;
      return (duration / total_duration) * master_schedule?.amount;
    } else return 0;
  } else {
    return 0;
  }
};

export const getToDatePlannedAmount = (
  master_schedule: MasterSchedule,
  date: Moment
) => {
  if (master_schedule && !master_schedule.is_title) {
    let start_date = moment(master_schedule?.start_date);
    let end_date = moment(master_schedule?.end_date);

    let total_duration = end_date.diff(start_date, "day");

    if (end_date.isBefore(date, "date") && start_date.isBefore(date, "date")) {
      return master_schedule?.amount;
    } else if (
      end_date.isAfter(date, "date") &&
      start_date.isBefore(date, "date")
    ) {
      let duration = end_date.diff(date, "days");
      return (duration / total_duration) * master_schedule?.amount;
    } else return 0;
  } else {
    return 0;
  }
};

export const formatMoney = (money: number) => {
  return money
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const DataFormat = (size: any): string => {
  let i = Math.floor(Math.log(size) / Math.log(1024));
  return (
    (size / Math.pow(1024, i)).toFixed(2) +
    " " +
    ["B", "kB", "MB", "GB", "TB"][i]
  );
};

export const toGC = (ethiopian_date: string): Moment => {
  const date = getDateValue(ethiopian_date);
  const parsed = ethiopic.toGregorian(date?.year, date?.month, date?.day);
  //
  return moment(`${parsed[0]}-${parsed[1]}-${parsed[2]}`, "YYYY-MM-DD");
};

export const toET = (gregorian_date: Moment): string => {
  const date = moment(gregorian_date).date();
  const month = moment(gregorian_date).month();
  const year = moment(gregorian_date).year();
  const parsed = ethiopic.toEthiopic(year, month + 1, date);
  return `${parsed[0]}-${parsed[1]}-${parsed[2]}`;
};

export const authHeader = () => {
  return {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };
};

export const multiPartHeader = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "content-type": "multipart/form-data",
    },
  };
};

export const getToday = (): string => {
  const date = moment().date();
  const month = moment().month();
  const year = moment().year();
  const parsed = ethiopic.toEthiopic(year, month + 1, date);
  return `${parsed[0]}-${parsed[1]}-${parsed[2]}`;
};

export const getTodayData = (): {
  month: number;
  day: number;
  year: number;
  date: string;
} => {
  const d = moment().date();
  const m = moment().month();
  const y = moment().year();
  const parsed = ethiopic.toEthiopic(y, m + 1, d);

  return {
    date: `${parsed[0]}-${parsed[1]}-${parsed[2]}`,
    month: parsed[1],
    day: parsed[2],
    year: parsed[0],
  };
};

export const isSameDate = (
  date_1: string,
  date_2: string,
  date_type: "month" | "date" | "year"
): boolean => {
  const parsed_date_1 = getDateValue(date_1);
  const parsed_date_2 = getDateValue(date_2);

  switch (date_type) {
    case "date":
      return (
        parsed_date_1.day === parsed_date_2.day &&
        parsed_date_1.month === parsed_date_2.month &&
        parsed_date_1.year === parsed_date_2.year
      );
    case "month":
      return (
        parsed_date_1.month === parsed_date_2.month &&
        parsed_date_1.year === parsed_date_2.year
      );
    case "year":
      return parsed_date_1.year === parsed_date_2.year;
  }
};

export const endOFDate = (
  date: string,
  date_type: "month" | "year"
): string => {
  const { month, year } = getDateValue(date);
  const is_leap = ethiopic.isLeapEthiopicYear(year);

  switch (date_type) {
    case "month":
      if (month === 13) {
        if (is_leap) return `${year}-${month}-${6}`;
        else return `${year}-${month}-${5}`;
      } else return `${year}-${month}-${30}`;
    case "year":
      if (is_leap) {
        return `${year}-${13}-${6}`;
      } else {
        return `${year}-${13}-${5}`;
      }
  }
};

export const startOFDate = (
  date: string,
  date_type: "month" | "year"
): string => {
  const { month, year } = getDateValue(date);

  switch (date_type) {
    case "month":
      return `${year}-${month}-${1}`;
    case "year":
      return `${year}-${1}-${1}`;
  }
};

export const NumberValidator = (rule: RuleObject, value: number) => {
  return new Promise((resolve, reject) => {
    if (!value) reject("Required!");
    else if (value < 0) reject("Positive Number Only!");
    else resolve(null);
  });
};

export const parseMaterialInventory = (
  material_inventories: MaterialInventory[]
) => {
  const parsed = [];
  let material_grouped = groupBy(
    material_inventories,
    (e) => e.material_inventory.material_id
  );

  for (let material_id in material_grouped) {
    let project_grouped = groupBy(
      material_grouped[material_id],
      (e) => e.project_id
    );

    for (let project_id in project_grouped) {
      let quantity = 0;
      quantity += project_grouped[project_id].reduce(
        (total, current) => total + current.quantity,
        0
      );
      parsed.push({ ...project_grouped[project_id][0], quantity });
    }
  }
  return parsed;
};

export const PhoneValidator = (rule: RuleObject, value: string) => {
  return new Promise((resolve, reject) => {
    var phone_number_regx = /^(^\+251|^251|^0)?9\d{8}$/;
    if (!value) reject("Phone Number Required!");
    else if (!value.match(phone_number_regx))
      reject("Incorrect Phone Number Format!");
    else resolve(null);
  });
};

export const NotRequiredPhoneValidator = (rule: RuleObject, value: string) => {
  return new Promise((resolve, reject) => {
    var phone_number_regx = /^(^\+251|^251|^0)?9\d{8}$/;
    if (value && !value.match(phone_number_regx))
      reject("Incorrect Phone Number Format!");
    else resolve(null);
  });
};

export const ErrorHandler = (error: AxiosError) => {
  const errors: { message: string; type: number | undefined }[] = [];

  if (error.response) {
    if (isArray(error.response.data.errors))
      error.response.data.errors.forEach((e: any) => {
        errors.push({ message: e.message, type: error.response?.status });
      });
    else errors.push({ message: "Unknown Error", type: undefined });
  } else if (error.request) {
    errors.push({ message: "Connection Error", type: error.request?.status });
  } else {
    errors.push({ message: "Unknown Error", type: undefined });
  }

  return errors;
};

export const DownloadErrorHandler = (error: AxiosError) => {
  const errors: { message: string; type: number | undefined }[] = [];
  if (error.response) {
    errors.push({ message: "Nothing to Export", type: 400 });
  } else if (error.request) {
    errors.push({ message: "Connection Error", type: error.request?.status });
  }
  return errors;
};

export const Download = (id: any) =>
  axios.get(API_BASE_URI + `/document/download/${id}`, {
    responseType: "blob",
  });

export const DownloadFile = (documents: Document) => {
  Download(documents.id)
    .then((response) => {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([response.data], {
            type: response.headers["content-type"],
          }),
          documents.name
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: response.headers["content-type"],
          })
        );
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute(
          "download",
          documents.url
            ?.split("-")
            ?.filter((e, index) => index > 0)
            ?.join("-")
        );
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
      }
    })
    .catch((error) => {
      OpenNotification(
        NotificationType.ERROR,
        Message.DOCUMENT_DOWNLOAD_FAILED,
        ""
      );
    });
};

export const formatDate = (
  date: string,
  format: "MMMM-YYYY" | "MMMM" | "YYYY" | "DD-MM-YYYY"
): string => {
  const { day, month, year } = getDateValue(date);

  switch (format) {
    case "MMMM-YYYY":
      return `${ETHIOPIAN_MONTHS[month - 1]}-${year}`;
    case "MMMM":
      return ETHIOPIAN_MONTHS[month - 1];
    case "YYYY":
      return `${year}`;
    case "DD-MM-YYYY":
      return `${day}-${month}-${year}`;
  }
};

export const getEtMonth = (date: string) => {
  const splitted = date.split("-");
  return `${splitted[0]}-${splitted[1]}`;
};

export const handlePayrollChange = (
  value: any,
  key: any,
  data: any,
  staffId: any,
  state: any,
  setState: Function
) => {
  let st = data;
  st[key] = value;
  const formattedState = state.filter((elem: any) => {
    if (elem.staff_id === staffId) return st;
    return elem;
  });
  setState([...formattedState]);
};

export const formatterNumber = (val: any) => {
  if (!val) return "0";
  return `${val}`
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    .replace(/\.(?=\d{0,2}$)/g, ",");
};

export const parserNumber = (val: any) => {
  if (!val) return 0;
  return Number.parseFloat(
    val.replace(/\$\s?|(\.*)/g, "").replace(/(,{1})/g, ".")
  ).toFixed(2);
};

export const commaFormatterNumber = (val: any) => {
  if (!val) return "0";
  return `${val}`
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    .replace(/\.(?=\d{0,2}$)/g, ",");
};

export const commaParserNumber = (val: any) => {
  if (!val) return 0;
  return Number.parseFloat(val.replace(/\$\s?|(,*)/g, "")).toFixed(2);
};

export const isSameOrAfter = (
  after_date: string,
  before_date: string,
  date_type: "date" | "month" | "year"
): boolean => {
  const parsed_date_1 = getDateValue(after_date);
  const parsed_date_2 = getDateValue(before_date);

  switch (date_type) {
    case "date":
      return (
        parsed_date_1.day >= parsed_date_2.day &&
        parsed_date_1.month >= parsed_date_2.month &&
        parsed_date_1.year >= parsed_date_2.year
      );
    case "month":
      return (
        parsed_date_1.month >= parsed_date_2.month &&
        parsed_date_1.year >= parsed_date_2.year
      );
    case "year":
      return parsed_date_1.year >= parsed_date_2.year;
  }
};

export const daysInMonth = (date: string): number => {
  const { month, year } = getDateValue(date);
  const is_leap = ethiopic.isLeapEthiopicYear(year);

  if (month === 13) {
    if (is_leap) return 6;
    else return 5;
  } else return 30;
};

export const isValidEthiopian = (date: string): boolean => {
  const { month, year, day } = getDateValue(date);
  return ethiopic.isValidEthiopicDate(year, month, day);
};

export const saveUserData = (data: any) => {
  localStorage.setItem(
    "data",
    JSON.stringify({
      email: data?.email,
      full_name: data?.name,
      id: data?.id,
      company: data?.company,
      role: data?.role,
      access_type: data?.access_type,
      is_super_user: data?.is_super_user,
      user_role: data?.user_role,
      position: data?.position,
    })
  );
  localStorage.setItem("token", data?.token);
  localStorage.setItem("user_id", data?.id);

  localStorage.setItem("expiresIn", moment.now().toString());
};

export const parseBoQExcelStandards = (
  workbook: XLSX.WorkBook | undefined,
  sheet_names: string[]
) => {
  const parsed: BoQRegistrationStructure[] = [];
  if (workbook) {
    sheet_names.forEach((sheet_name) => {
      const ws = workbook.Sheets[sheet_name];
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      const BuildingParsed = new BuildingStandardsBoQ(data, sheet_name);
      parsed.push(...BuildingParsed.parseBoq());
    });
  }
  return parsed;
};

export const saveProjectRegistration = (project: any, boq: any) => {
  localStorage.setItem("project_registration", JSON.stringify(project));
  localStorage.setItem("boq_registration", JSON.stringify(boq));
};

export const clearProjectData = () => {
  localStorage.removeItem("project_registration");
  localStorage.removeItem("boq_registration");
};

export const from24HourToEthiopianTime = (time: string) => {
  let timeArr: string[] = time.split(":");
  let etHour;
  let secDesc;
  let timDesc;
  const hour = parseInt(timeArr[0]);
  if ((hour >= 0 && hour <= 5) || (hour >= 18 && hour <= 23)) {
    secDesc = "ምሽት";
    if (hour >= 0 && hour <= 5) {
      etHour = hour + 6;
    } else {
      if (hour === 18) etHour = 12;
      else etHour = hour - 18;
    }
  } else {
    secDesc = "ቀን";
    hour === 6 ? (etHour = 6) : (etHour = hour - 6);
    (etHour >= 1 && etHour <= 6) || etHour === 12
      ? (timDesc = "ጠዋት")
      : (timDesc = "ከሰዓት");
  }
  timeArr[0] = etHour.toString();
  // format 1:00,ጠዋት || 6:32,ምሽት
  return timDesc
    ? timeArr[0].length === 2
      ? timeArr[0]
      : `0${timeArr[0]}:${timeArr[1]},${timDesc}`
    : timeArr[0].length === 2
    ? timeArr[0]
    : `0${timeArr[0]}:${timeArr[1]},${secDesc}`;
};

export const getUserData = (): User => {
  const temp: any = localStorage.getItem("data");
  if (temp) return JSON.parse(temp);
  else
    return {
      full_name: "",
      id: 1,
      phone_number: "",
      chat_id: "",
      company: {
        name: "",
        address: "",
        category: "",
        country: "",
        id: 1,
        type: "",
      },
      email: "",
      role: "",
      access_type: [],
      modules_subscribed: [],
      status: "Active",
      signature: null,
      last_seen: null,
      is_super_user: false,
      position: "",
      user_role: { name: "", user_accesses: [], id: 0 },
      approve: false,
    };
};

export const getProjectRegistrationData = (project_type: any) => {
  const building_boq = [
    {
      is_super_title: true,
      is_title: true,
      is_sub_title: false,
      description: BoqSuperTitle.SUBSTRUCTURE,
      item_no: "",
      key: Date.now(),
      amount: 0,
      quantity: 0,
      unit: "",
      unit_price: 0,
      sheet_name: "sheet 1",
      reference_id: null,
      remark: "",
    },
    {
      is_super_title: false,
      is_title: true,
      is_sub_title: false,
      description: "",
      item_no: "",
      key: Date.now() + 1,
      amount: 0,
      quantity: 0,
      unit: "",
      unit_price: 0,
      sheet_name: "sheet 1",
      reference_id: null,
      remark: "",
    },
    {
      is_super_title: false,
      is_title: false,
      is_sub_title: false,
      description: "",
      item_no: "",
      key: Date.now() + 2,
      amount: 0,
      quantity: 0,
      unit: "",
      unit_price: 0,
      sheet_name: "sheet 1",
      reference_id: null,
      remark: "",
    },
    {
      is_super_title: true,
      is_title: true,
      is_sub_title: false,
      description: BoqSuperTitle.SUPERSTRUCTURE,
      item_no: "",
      key: Date.now() + 3,
      amount: 0,
      quantity: 0,
      unit: "",
      unit_price: 0,
      sheet_name: "sheet 1",
      reference_id: null,
      remark: "",
    },
    {
      is_super_title: false,
      is_title: true,
      is_sub_title: false,
      description: "",
      item_no: "",
      key: Date.now() + 4,
      amount: 0,
      quantity: 0,
      unit: "",
      unit_price: 0,
      sheet_name: "sheet 1",
      reference_id: null,
      remark: "",
    },
    {
      is_super_title: false,
      is_title: false,
      is_sub_title: false,
      description: "",
      item_no: "",
      key: Date.now() + 5,
      amount: 0,
      quantity: 0,
      unit: "",
      unit_price: 0,
      sheet_name: "sheet 1",
      reference_id: null,
      remark: "",
    },
  ];

  const road_boq = [
    {
      is_super_title: true,
      is_title: true,
      is_sub_title: false,
      description: "",
      item_no: "",
      key: Date.now(),
      amount: 0,
      quantity: 0,
      unit: "",
      unit_price: 0,
      sheet_name: "sheet 1",
      reference_id: null,
      remark: "",
    },
    {
      is_super_title: false,
      is_title: true,
      is_sub_title: false,
      description: "",
      item_no: "",
      key: Date.now() + 1,
      amount: 0,
      quantity: 0,
      unit: "",
      unit_price: 0,
      sheet_name: "sheet 1",
      reference_id: null,
      remark: "",
    },
  ];

  return project_type === ProjectTypes.ROAD ? road_boq : building_boq;
};

export const calculateIncomeTax = (salary: number) => {
  if (salary <= 600) {
    return salary * 0;
  } else if (salary > 600 && salary <= 1650) {
    return salary * 0.1 - 60;
  } else if (salary > 1650 && salary <= 3200) {
    return salary * 0.15 - 142.5;
  } else if (salary > 3200 && salary <= 5250) {
    return salary * 0.2 - 302.5;
  } else if (salary > 5250 && salary <= 7800) {
    return salary * 0.25 - 565;
  } else if (salary > 7800 && salary <= 10900) {
    return salary * 0.3 - 955;
  } else {
    return salary * 0.35 - 1500;
  }
};
export const isLoggedIn = () => {
  const token = localStorage.getItem("token");
  const expiresIn = localStorage.getItem("expiresIn");

  if (!token) {
    logout();
    return false;
  }

  if (expiresIn) {
    const expiresInDate = moment(parseInt(expiresIn, 10));

    const duration = moment.duration(moment().diff(expiresInDate));

    if (duration.asDays() >= 1) {
      logout();
      return false;
    }

    return true;
  }

  logout();
  return false;
};

export const getAction = (user_access: any, action: any) => {
  return user_access[action];
};

export const checkAuthorization = (path: string): boolean => {
  const access_type = getUserData().access_type;

  if (access_type) {
    return find(access_type, (e) => e === path) ? true : false;
  } else return false;
};

export const checkModuleAuthorization = (
  module: string,
  isGroup: boolean,
  action?: string
): boolean => {
  const user_access = getUserAccess(getUserData());
  if (getUserData().is_super_user || module === MODULE.PROJECTS) {
    return true;
  } else {
    if (isGroup)
      return (
        user_access?.findIndex(
          (e) => e?.group?.toLocaleLowerCase() === module?.toLocaleLowerCase()
        ) !== -1
      );
    else
      return (
        user_access?.findIndex((e) => {
          return (
            e.feature?.toLocaleLowerCase() === module?.toLocaleLowerCase() &&
            (!action || getAction(e, action))
          );
        }) !== -1
      );
  }
};

interface UserAccessType extends UserAccess {
  url: string;
  group: string;
}

export const getUserAccess = (user: User) => {
  let parsed: UserAccessType[] = [];
  user.user_role?.user_accesses?.forEach((e) => {
    if (e.read || e.delete || e.write || e.edit) {
      let found = USER_ACCESSES.find(
        (user_access) => user_access.name === e.feature
      );
      if (found) parsed.push({ ...found, ...e });
    }
  });

  return parsed;
};

export const logout = (): void => {
  localStorage.setItem("data", "");
  localStorage.setItem("token", "");
  localStorage.setItem("expiresIn", "");
};

export const groupOption = (data: any[], item: string) => {
  const parsed = [];
  const grouped = groupBy(data, (e) => e[item]);
  for (const x in grouped) {
    parsed.push(x);
  }
  return parsed;
};

export const groupOptionGrouped = (data: any[], item: string) => {
  const parsed: { data: any[]; title: string }[] = [];
  const grouped = groupBy(data, (e) => e[item]);
  for (const x in grouped) {
    parsed.push({ data: grouped[x], title: x });
  }
  return parsed;
};

export const groupOptionAll = (data: any[], item: string) => {
  const parsed = [];
  const grouped = groupBy(data, (e) => e.material[item]);

  for (const x in grouped) {
    parsed.push(x);
  }

  return parsed;
};

export const groupOptionMaterial = (data: any[], item: string) => {
  const parsed = [];
  const grouped = groupBy(data, (e) => e.material[item]);

  for (const x in grouped) {
    parsed.push(grouped[x]);
  }

  return parsed;
};

export const groupMaterial = (data: any[], item: string) => {
  const parsed = [];
  const grouped = groupBy(data, (e) => e[item]);

  for (const x in grouped) {
    parsed.push(grouped[x][0]);
  }

  return parsed;
};

export const groupFilterOption = (data: any[], item: string): any[] => {
  const parsed = [];
  const grouped = groupBy(data, (e) => e[item]);
  for (const x in grouped) {
    if (item === "title") {
      parsed.push({ [item]: x, super_title: grouped[x][0].super_title });
    } else {
      parsed.push({ [item]: x, super_title: x });
    }
  }

  return parsed;
};

export const getDescriptionType = (col: any[]) => {
  let is_title: boolean = false;
  let is_sub_title: boolean = true;
  let description: string = "";
  let split = col[1].trim().split(".");

  if (
    ((split[0].charCodeAt(0) > 46 && split[0].charCodeAt(0) < 58) ||
      TITLES.findIndex((e) => e.toLocaleLowerCase() === split[1]?.trim())) &&
    (split[0].length < 3 || col[0]?.toString()?.split(".")?.length === 1)
  ) {
    if (col[0]?.toString()?.split(".")?.length === 1) description = col[1];
    else description = split[1]?.trim();
    is_title = true;
    is_sub_title = false;
  } else description = col[1];

  return { is_title, is_sub_title, description };
};

export const isUpperCase = (str: any) => {
  let result = str
    .split("")
    .map((letter: any) => !/[a-z]/.test(letter))
    .reduce((a: any, b: any) => a + b);

  return result === str.length;
};

// export const inWords = (num: any) => {};

const arr = (x: any) => Array.from(x);
const num = (x: any) => Number(x) || 0;
const isEmpty = (xs: any) => xs.length === 0;
const take = (n: any) => (xs: any) => xs.slice(0, n);
const drop = (n: any) => (xs: any) => xs.slice(n);
const reverse = (xs: any) => xs.slice(0).reverse();
const comp = (f: any) => (g: any) => (x: any) => f(g(x));
const not = (x: any) => !x;
const chunk =
  (n: any) =>
  (xs: any): any =>
    isEmpty(xs) ? [] : [take(n)(xs), ...chunk(n)(drop(n)(xs))];

export const inWords = (n: any): String => {
  let a = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];

  let b = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];

  let g = [
    "",
    "Thousand",
    "Million",
    "Billion",
    "Trillion",
    "Quadrillion",
    "Quintillion",
    "Sextillion",
    "Septillion",
    "Octillion",
    "Nonillion",
  ];

  let makeGroup = ([ones, tens, huns]: any) => {
    return [
      num(huns) === 0 ? "" : a[huns] + " Hundred ",
      num(ones) === 0 ? b[tens] : (b[tens] && b[tens] + "-") || "",
      a[tens + ones] || a[ones],
    ].join("");
  };

  let thousand = (group: any, i: any) =>
    group === "" ? group : `${group} ${g[i]}`;

  if (typeof n === "number") return inWords(String(n));
  else if (n === "0") return "Zero";
  else
    return comp(chunk(3))(reverse)(arr(n))
      .map(makeGroup)
      .map(thousand)
      .filter(comp(not)(isEmpty))
      .reverse()
      .join(" ");
};

const inWordsamh = (n: any): String => {
  let a = [
    "",
    "አንድ",
    "ሁለት",
    "ሶስት",
    "አራት",
    "አምስት",
    "ስድስት",
    "ሰባት",
    "ስምንት",
    "ዘጠኝ",
    "አስር",
    "አስራ አንድ",
    "አስራ ሁለት",
    "አስራ ሶስት",
    "አስራ አራት",
    "አስራ አምስት",
    "አስራ ስድስት",
    "አስራ ሰባት",
    "አስራ ስምንት",
    "አስራ ዘጠኝ",
  ];

  let b = ["", "", "ሃያ", "ሰላሳ", "አርባ", "ሃምሳ", "ስልሳ", "ሰባ", "ሰማንያ", "ዘጠና"];

  let g = [
    "",
    "ሺህ",
    "ሚልየን",
    "ቢልየን",
    "ትሪልየን",
    "ኳድሪልየን",
    "ኩንቲልየን",
    "ሴክስቲልዮን",
    "ሴፕቲልዮን",
    "ኦክቴልየን",
    "ኖኒልየን",
  ];

  let makeGroup = ([ones, tens, huns]: any) => {
    return [
      num(huns) === 0 ? "" : a[huns] + " መቶ" + " ",
      num(ones) === 0 ? b[tens] : (b[tens] && b[tens] + "-") || "",
      a[tens + ones] || a[ones],
    ].join("");
  };

  let thousand = (group: any, i: any) =>
    group === "" ? group : `${group} ${g[i]}`;

  if (typeof n === "number") return inWordsamh(String(n));
  else if (n === "0") return "ዜሮ";
  else
    return comp(chunk(3))(reverse)(arr(n))
      .map(makeGroup)
      .map(thousand)
      .filter(comp(not)(isEmpty))
      .reverse()
      .join(" ");
};

export const NumberToWord = (value: number) => {
  if (!value) {
    return "";
  }

  let split = value.toString().split(".");
  if (split.length > 1) {
    const birrPart = `${inWords(parseInt(split[0]))} ብር`;
    const centPart = `${inWords(parseInt(split[1]))} Cent`;
    return `${birrPart} and ${centPart}`;
  } else {
    const words = inWords(value);
    return `${words} ብር`;
  }
};

export const NumberToWordamh = (value: number) => {
  let split = value?.toString()?.split(".");
  if (split.length > 1) {
    return `${inWordsamh(toNumber(split[0]))} እና ${inWordsamh(
      toNumber(split[1])
    )} ሳንቲም`;
  } else {
    return inWordsamh(value);
  }
};

export const initAxios = (token: any) => {
  axios.defaults.headers.common = {
    Authorization: `Bearer ${token ? token : localStorage.getItem("token")}`,
  };
};

export const minimizeNumber = (number: any) => {
  return Intl.NumberFormat("en-GB", {
    notation: "compact",
    compactDisplay: "short",
  }).format(parseInt(number));
};

export const readExcel = (file: any) => {
  return new Promise<xlsx.WorkBook>((resolve, reject) => {
    var reader = new FileReader();

    reader.onload = (event: any) => {
      var data = event.target.result;

      var workbook = xlsx.read(data, { type: "binary" });

      resolve(workbook);
    };
    reader.readAsBinaryString(file);
  });
};

export const parseBoQExcel = (
  workbook: WorkBook | undefined,
  sheet_names: string[]
) => {
  const parsed: BoQRegistrationStructure[] = [];
  if (workbook) {
    sheet_names.forEach((sheet_name) => {
      const ws = workbook.Sheets[sheet_name];
      const data = xlsx.utils.sheet_to_json(ws, { header: 1 });
      const BuildingParsed = new BuildingBoQ(data, sheet_name);
      parsed.push(...BuildingParsed.parseBoq());
    });
  }
  return parsed;
};

export const parseSiteAttendanceExcel = (
  workbook: WorkBook | undefined,
  sheet_names: string[]
) => {
  const parsed: BoQRegistrationStructure[] = [];
  if (workbook) {
    sheet_names.forEach((sheet_name) => {
      const ws = workbook.Sheets[sheet_name];
      const data = xlsx.utils.sheet_to_json(ws, { header: 1 });
      const BuildingParsed = new BuildingSiteAttendance(data, sheet_name);
      parsed.push(...BuildingParsed.parseBoq());
    });
  }
  return parsed;
};

export const parseMasterScheduleExcel = (
  workbook: WorkBook | undefined,
  sheet_names: string[],
  project_id: number
) => {
  const parsed: MasterSchedule[] = [];
  if (workbook) {
    sheet_names.forEach((sheet_name) => {
      const ws = workbook.Sheets[sheet_name];
      const data = xlsx.utils.sheet_to_json(ws, { header: 1 });
      const masterScheduleExcel = new MasterScheduleExcel(
        data,
        sheet_name,
        project_id
      );
      parsed.push(...masterScheduleExcel.parse());
    });
  }
  return parsed;
};

export const parseMaterialOnSiteExcel = (
  workbook: WorkBook | undefined,
  sheet_names: string[],
  project_id: number
) => {
  const parsed: any[] = [];
  if (workbook) {
    sheet_names.forEach((sheet_name) => {
      const ws = workbook.Sheets[sheet_name];
      const data = xlsx.utils.sheet_to_json(ws, { header: 1 });
      parsed.push(...parseMaterialOnSiteData(data));
    });
  }
  return parsed.length > 0 ? parsed : [{ material: "", quantity: 0, unit: "" }];
};

const parseMaterialOnSiteData = (data: any[]) => {
  const sliced = data.splice(9).filter((data) => data.length === 14);
  const formatted = sliced.map((data) => ({
    material: data[1],
    unit: data[2]?.toString()?.toLowerCase(),
    quantity: data[13],
  }));
  return formatted.filter(
    (data) =>
      !isNil(data.material) && !isNil(data.quantity) && !isNil(data.unit)
  );
};

export const getExecuted = (boq: Boq, pid?: number) => {
  let current_quantity = 0;
  let previous_quantity = 0;

  // boq.take_offs.forEach((e) => {
  //   if (pid) {
  //     if (pid > e.pid && e.pid !== 0) {
  //       previous_quantity += e.total;
  //     } else if (pid === e.pid && e.pid !== 0) {
  //       current_quantity += e.total;
  //     }
  //   } else {
  //     if (ApprovalValue.PENDING === e.approval) {
  //       current_quantity += e.total;
  //     } else {
  //       previous_quantity += e.total;
  //     }
  //   }
  // });

  // boq.rebars.forEach((e) => {
  //   if (pid) {
  //     console.log(
  //       pid > e.pid && e.pid !== 0,
  //       pid === e.pid && e.pid !== 0,
  //       e.pid,
  //       pid
  //     );
  //     if (pid > e.pid && e.pid !== 0) {
  //       previous_quantity += e.total;
  //     } else if (pid === e.pid && e.pid !== 0) {
  //       current_quantity += e.total;
  //     }
  //   } else {
  //     if (ApprovalValue.PENDING === e.approval) {
  //       current_quantity += e.total;
  //     } else {
  //       previous_quantity += e.total;
  //     }
  //   }
  // });

  // boq.aggregate_takeoff?.aggregate_take_off_items.forEach((e) => {
  //   if (pid) {
  //     console.log(
  //       pid > e.pid && e.pid !== 0,
  //       pid === e.pid && e.pid !== 0,
  //       e.pid,
  //       pid
  //     );
  //     if (pid > e.pid && e.pid !== 0) {
  //       previous_quantity += e.quantity;
  //     } else if (pid === e.pid && e.pid !== 0) {
  //       current_quantity += e.quantity;
  //     }
  //   } else {
  //     if (ApprovalValue.PENDING === e.approval) {
  //       current_quantity += e.quantity;
  //     } else {
  //       previous_quantity += e.quantity;
  //     }
  //   }
  // });

  // boq.road_take_offs.forEach((road) => {
  //   road.road_take_off_items.forEach((e) => {
  //     if (pid) {
  //       if (pid > e.pid && e.pid !== 0) {
  //         previous_quantity += e.quantity;
  //       } else if (pid === e.pid && e.pid !== 0) {
  //         current_quantity += e.quantity;
  //       }
  //     } else {
  //       if (e.pid === 0) {
  //         current_quantity += e.quantity;
  //       } else {
  //         previous_quantity += e.quantity;
  //       }
  //     }
  //   });
  // });

  return { current_quantity, previous_quantity };
};

export const checkStatus = (
  data: ApiCallState<any>
): { status: "validating" | "error" | "warning"; message: string } => {
  if (data.isPending) {
    return { status: "validating", message: "Loading" };
  } else if (data.error)
    return { status: "error", message: "Failed to Fetch Data" };
  else return { status: "warning", message: "Select Item" };
};

export const SelectorFeedBack = (
  selected_item: any,
  data: ApiCallState<any>
) => {
  if (selected_item) {
    return {
      hasFeedback: false,
    };
  } else {
    const { status, message } = checkStatus(data);
    return {
      hasFeedback: true,
      validateStatus: status,
      help: message,
    };
  }
};

export const getLastId = (data: any[]) => {
  if (data) {
    const length = data?.length;
    if (length === 0) return 1;
    else return data[length - 1].id + 1;
  } else return 1;
};

export const parseRawBoQ = (data: RawBoQType[]) => {
  let parsed: any[] = [];

  let super_title: any = null;
  let title: any = null;
  let remark: any = "";
  let sub_title: any = null;
  for (let i = 0; i < data.length; i++) {
    let e = data[i];
    if (e.is_super_title) {
      super_title = e.description;
      title = null;
      sub_title = null;
    } else if (e.is_title) {
      title = e.description;
      remark = e.remark;

      sub_title = null;
    } else if (e.is_sub_title) {
      sub_title = (sub_title ? sub_title + "\n" : "") + e.description;
    } else {
      parsed.push({
        id: e.id,
        super_title,
        remark,
        title,
        sub_title,
        item_no: e.item_no,
        task_name: e.description,
        unit: e.unit,
        quantity: e.quantity,
        unit_price: e.unit_price,
        total: e.amount,
        sheet_name: e.sheet_name,
        reference_id: e.reference_id,
      });
      remark = null;

      if (data[i + 1]?.is_sub_title) sub_title = null;
    }
  }

  return parsed;
};

export type RawBoQType = {
  id?: number;
  is_super_title: boolean;
  is_title: boolean;
  is_sub_title: boolean;
  description: string;
  item_no: string;
  key: number;
  amount: number;
  quantity: number;
  unit: string;
  unit_price: number;
  sheet_name: string;
  reference_id: number | null;
  remark: any;
};
export const boqToRawParser = (boqs: Boq[], tab: string) => {
  const parsed: RawBoQType[] = [];
  const super_titles = groupBy(
    boqs.filter((e) => e.sheet_name === tab),
    (boq) => boq.sheet_name
  );
  for (const super_title in super_titles) {
    if (super_title !== null && super_title !== "null")
      parsed.push({
        key: parsed.length,
        description: super_title,
        item_no: "",
        unit: "",
        unit_price: 0,
        amount: 0,
        reference_id: null,
        is_sub_title: false,
        is_super_title: true,
        is_title: false,
        quantity: 0,
        sheet_name: tab,
        remark: null,
      });
    let titles = groupBy(super_titles[super_title], (boq) => boq.description);
    for (const title in titles) {
      let title_total = { current: 0, previous: 0, total: 0 };
      if (title !== null && title !== "null")
        parsed.push({
          key: parsed.length,
          description: title,
          item_no: "",
          unit: "",
          unit_price: 0,
          amount: 0,
          reference_id: null,
          is_sub_title: false,
          is_super_title: false,
          is_title: true,
          quantity: 0,
          sheet_name: tab,
          remark: null,
        });
      let sub_titles = groupBy(titles[title], (boq) => boq.sheet_name);
      for (const sub_title in sub_titles) {
        if (sub_title !== null && sub_title !== "null")
          parsed.push({
            key: parsed.length,
            description: sub_title,
            item_no: "",
            unit: "",
            unit_price: 0,
            amount: 0,
            reference_id: null,
            is_sub_title: true,
            is_super_title: false,
            is_title: false,
            quantity: 0,
            sheet_name: tab,
            remark: null,
          });
        sub_titles[sub_title].forEach((e) =>
          parsed.push({
            id: e.id,
            key: parsed.length,
            description: e.description,
            item_no: e.item_no,
            unit: e.unit,
            unit_price: e.unit_price,
            amount: e.quantity * e.unit_price,
            reference_id: null,
            is_sub_title: false,
            is_super_title: false,
            is_title: false,
            quantity: e.quantity,
            sheet_name: e.sheet_name,
            remark: null,
          })
        );
      }
      titles[title].forEach((e) => {
        title_total.total += e.quantity * e.unit_price;
      });
    }
  }

  return parsed;
};

export const removeHandler = (key: number, data: any[], setData: Function) => {
  const newData = [...data];
  const index = newData.findIndex((e) => e.key === key);
  if (index !== -1 && data.length > 1) {
    newData.splice(index, 1);
    setData(newData);
  }
};

export const parseUnit = (unit: any) => {
  let parsed = unit;
  UNITS.forEach((e) => {
    if (e.value === unit) parsed = e.name;
  });
  return parsed;
};

export const getUnits = (material?: Material) => {
  if (material) {
    if (material.material_sub_category?.description === "Re-Bar") {
      return [
        { name: "KG", value: "kg", type: "mass" },
        { name: "PCS", value: "pcs", type: "no" },
        { name: "Berga", value: "berga", type: "no" },
      ];
    } else return UNITS.filter((e) => e.type === getUnitType(material.unit));
  }
  return UNITS;
};

export const getUnitType = (unit: string) => {
  let found_type = "mass";
  let found = UNITS.find((e) => e.value === unit);
  if (found) {
    return found.type;
  }
  return found_type;
};

export const getInitials = (full_name: string) => {
  if (full_name) {
    let split = full_name?.toUpperCase().split(" ");
    if (split.length === 1) {
      return `${split[0].charAt(0)}${split[0].charAt(1)}`;
    } else {
      return `${split[0].charAt(0)}${split[1].charAt(0)}`;
    }
  }
  return "";
};

export const getRebarDiameter = (material: Material) => {
  var matches = material.description.match(/(\d+)/);
  if (matches) return matches[0];
  return null;
};

export const convertUnit = (
  from: any,
  to: any,
  quantity: number | undefined,
  material?: Material
): any => {
  // console.log({ from, to, quantity, material });
  if (from && to && material && quantity) {
    from = from?.toLowerCase();
    to = to?.toLowerCase();
    if (material.material_sub_category?.description === "Re-Bar") {
      const diameter = getRebarDiameter(material);
      if (diameter) {
        const weight_per_pcs = WeightRebar[toNumber(diameter)] * REBAR_LENGTH;
        if (from === "kg" && (to === "pcs" || to === "berga"))
          return toNumber((quantity / weight_per_pcs).toFixed(0));
        else if ((from === "pcs" || from === "berga") && to === "kg")
          return toNumber((quantity * weight_per_pcs).toFixed(0));
        else return quantity;
      } else {
        return quantity;
      }
    } else if (
      from === "quintal" ||
      to === "quintal" ||
      to === "ton" ||
      from === "ton" ||
      from === "ከርጢት" ||
      to === "ከርጢት"
    ) {
      return ConversionRate[from][to] * quantity;
    } else if (
      UNITS.find((unit) => unit.value === material.unit)?.type === "no"
    )
      return quantity;
    else return convert(quantity).from(from).to(to);
  } else return quantity;
};

export const getQuantity = (boqs: Boq[], pid: number) => {
  let quantity = 0;
  boqs.forEach((e) => {
    // if (e.take_offs.length > 0) {
    //   quantity += e.take_offs.reduce(
    //     (total, current) => total + (pid === current.pid ? current.total : 0),
    //     0
    //   );
    // } else if (e.rebars.length > 0) {
    //   quantity += e.rebars.reduce(
    //     (total, current) => total + (pid === current.pid ? current.total : 0),
    //     0
    //   );
    // } else if (e.aggregate_takeoff) {
    //   quantity += e.aggregate_takeoff.aggregate_take_off_items.reduce(
    //     (total, current) =>
    //       total + (pid === current.pid ? current.quantity : 0),
    //     0
    //   );
    // } else if (e.road_take_offs.length > 0) {
    //   e.road_take_offs.forEach((e) => {
    //     quantity += e.road_take_off_items.reduce(
    //       (total, current) =>
    //         total + (pid === current.pid ? current.quantity : 0),
    //       0
    //     );
    //   });
    // }
  });

  return quantity;
};

/** Employee Allowance Scale Projection
 * (Number) initialValue
 * (Number) round - Number of rounds to calculate the result
 * (Number) percentage - Incremental Percentage
 * --------------
 * Return number
 */
export const CalculateProjection = (
  initialValue: number,
  round: number,
  percentage: number
): number => {
  let value = initialValue;
  for (let x = 1; x <= round; x++) {
    value = value * (percentage / 100) + value;
  }
  return value;
};

export const getUnitPrice = (material_unit_prices?: MaterialUnitPrice[]) => {
  if (material_unit_prices) {
    let total_quantity = 0;
    let total = 0;

    material_unit_prices?.forEach((item) => {
      total_quantity += item.quantity;
      total += item.quantity * item.unit_price;
    });

    if (total_quantity === 0) return 0;
    else return toNumber((total / total_quantity).toFixed(2));
  } else return 0;
};

export const inputNumberProps: InputNumberProps = {
  parser: parserNumber,
  formatter: formatterNumber,
};

export const commaInputNumberProps: InputNumberProps = {
  parser: commaParserNumber,
  formatter: commaFormatterNumber,
};

export const handleNotDeletedChange = (
  data: any,
  index: any,
  state: any,
  setState: any
) => {
  let st = data;
  st.isEdited = true;
  st.isDeleted = false;
  state[index] = st;
  setState([...state]);
};

type WithStatus<T = number> = {
  status: T;
};
export const isNotCheckedOrApproved = (statusItems: WithStatus[]) => {
  return statusItems.findIndex((statusItem) => statusItem.status === 1) === -1;
};

export const isVoucherApproved = (
  payloads: {
    status: number;
    type: "Approve" | "Check" | "View";
  }[]
): boolean => {
  const approved = payloads.find((e) => e.type === "Approve" && e.status === 1);

  if (approved) return true;

  return false;
};

export const getLastItem = (data: any[], property: string) => {
  if (data) {
    const length = data?.length;
    if (length === 0) return 1;
    else return toNumber(data[length - 1][property]) + 1;
  } else return 1;
};

export const isApproved = (
  payloads: {
    status: number;
    type: "Approve" | "Check" | "View";
  }[]
): boolean => {
  return true;
};

export const generateRandom4DigitNumber = () => {
  const min = 1000;
  const max = 9999;
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const leftOuterJoin = (
  obj1: any[],
  obj2: any[],
  attribute: string = "id"
): any => {
  let map: any = {};
  for (var i = 0; i < obj1.length; i++) {
    map[obj1[i][attribute]] = 1;
  }
  var result = [];
  for (i = 0; i < obj2.length; i++) {
    if (!(obj2[i].id in map)) {
      result.push(obj2[i]);
    }
  }
  return result;
};
