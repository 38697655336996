import { ApiCallState, PagedData } from "../Utils";

export type CVBank = {
  id?: number;
  name: string;
  role: string;
  no_of_service_years: number;
  expiry_date: Date;
  document: any;
};

export type CVBankStateTypes = {
  fetchAll: ApiCallState<CVBank[]>;
  fetchOne: ApiCallState<CVBank | {}>;
  fetchPaged: ApiCallState<PagedData<CVBank[]>>;
};

export const CVBankActionTypes = {
  FETCH_ALL_CV_BANK: "FETCH_ALL_CV_BANK",
  FETCH_ALL_CV_BANK_RESET: "FETCH_ALL_CV_BANK_RESET",
  FETCH_ALL_CV_BANK_FAILURE: "FETCH_ALL_CV_BANK_FAILURE",
  FETCH_ALL_CV_BANK_SUCCESS: "FETCH_ALL_CV_BANK_SUCCESS",

  FETCH_PAGED_CV_BANK: "FETCH_PAGED_CV_BANK",
  FETCH_PAGED_CV_BANK_RESET: "FETCH_PAGED_CV_BANK_RESET",
  FETCH_PAGED_CV_BANK_FAILURE: "FETCH_PAGED_CV_BANK_FAILURE",
  FETCH_PAGED_CV_BANK_SUCCESS: "FETCH_PAGED_CV_BANK_SUCCESS",

  FETCH_ONE_CV_BANK: "FETCH_ONE_CV_BANK",
  FETCH_ONE_CV_BANK_RESET: "FETCH_ONE_CV_BANK_RESET",
  FETCH_ONE_CV_BANK_FAILURE: "FETCH_ONE_CV_BANK_FAILURE",
  FETCH_ONE_CV_BANK_SUCCESS: "FETCH_ONE_CV_BANK_SUCCESS",
};
