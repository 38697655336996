import {
  FeedBackFormStateTypes,
  FeedBackFormActionTypes,
} from "./FeedBackForm.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: FeedBackFormStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const FeedBackFormReducer = (
  state: FeedBackFormStateTypes = INITIAL_STATE,
  action: any
): FeedBackFormStateTypes => {
  switch (action.type) {
    case FeedBackFormActionTypes.FETCH_ALL_FEED_BACK_FORM:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case FeedBackFormActionTypes.FETCH_ALL_FEED_BACK_FORM_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case FeedBackFormActionTypes.FETCH_ALL_FEED_BACK_FORM_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FeedBackFormActionTypes.FETCH_ALL_FEED_BACK_FORM_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case FeedBackFormActionTypes.FETCH_ONE_FEED_BACK_FORM:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case FeedBackFormActionTypes.FETCH_ONE_FEED_BACK_FORM_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState({}),
      };
    case FeedBackFormActionTypes.FETCH_ONE_FEED_BACK_FORM_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FeedBackFormActionTypes.FETCH_ONE_FEED_BACK_FORM_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default FeedBackFormReducer;
