import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { OperationMonthlyReportActionTypes } from "./OperationMonthlyReport.type";

export function* fetchAllOperationMonthlyReports(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/operation-monthly-report?${query}`
    );
    yield put({
      type: OperationMonthlyReportActionTypes.FETCH_ALL_OPERATION_MONTHLY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: OperationMonthlyReportActionTypes.FETCH_ALL_OPERATION_MONTHLY_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedOperationMonthlyReports(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(
      `${API_BASE_URI}/operation-monthly-report?${query}`
    );
    yield put({
      type: OperationMonthlyReportActionTypes.FETCH_PAGED_OPERATION_MONTHLY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: OperationMonthlyReportActionTypes.FETCH_PAGED_OPERATION_MONTHLY_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneOperationMonthlyReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/operation-monthly-report/${action.payload}`
    );
    yield put({
      type: OperationMonthlyReportActionTypes.FETCH_ONE_OPERATION_MONTHLY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: OperationMonthlyReportActionTypes.FETCH_ONE_OPERATION_MONTHLY_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllOperationMonthlyReports() {
  yield takeLatest(
    OperationMonthlyReportActionTypes.FETCH_ALL_OPERATION_MONTHLY_REPORT,
    fetchAllOperationMonthlyReports
  );
}

export function* watcherFetchPagedOperationMonthlyReports() {
  yield takeLatest(
    OperationMonthlyReportActionTypes.FETCH_PAGED_OPERATION_MONTHLY_REPORT,
    fetchPagedOperationMonthlyReports
  );
}

export function* watcherFetchOneOperationMonthlyReports() {
  yield takeLatest(
    OperationMonthlyReportActionTypes.FETCH_ONE_OPERATION_MONTHLY_REPORT,
    fetchOneOperationMonthlyReports
  );
}
