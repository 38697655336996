import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { WorkExperienceSummaryActionTypes } from "./WorkExperienceSummary.type";

export function* fetchAllWorkExperienceSummarys(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys.map((key) => `${key}=${action.payload[key]}`).join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/work-experience-summary?${query}`);
    yield put({
      type: WorkExperienceSummaryActionTypes.FETCH_ALL_WORK_EXPERIENCE_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WorkExperienceSummaryActionTypes.FETCH_ALL_WORK_EXPERIENCE_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneWorkExperienceSummarys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/work-experience-summary/find-one/${action.payload}`
    );
    yield put({
      type: WorkExperienceSummaryActionTypes.FETCH_ONE_WORK_EXPERIENCE_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: WorkExperienceSummaryActionTypes.FETCH_ONE_WORK_EXPERIENCE_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllWorkExperienceSummarys() {
  yield takeLatest(WorkExperienceSummaryActionTypes.FETCH_ALL_WORK_EXPERIENCE_SUMMARY, fetchAllWorkExperienceSummarys);
}

export function* watcherFetchOneWorkExperienceSummarys() {
  yield takeLatest(WorkExperienceSummaryActionTypes.FETCH_ONE_WORK_EXPERIENCE_SUMMARY, fetchOneWorkExperienceSummarys);
}
