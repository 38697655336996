import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SummaryActionTypes } from "./Summary.type";

export function* fetchAllSummarys(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/summary?project_id=${action.payload?.project_id}`);
    yield put({
      type: SummaryActionTypes.FETCH_ALL_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SummaryActionTypes.FETCH_ALL_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSummarys(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/summary/${action.payload}`
    );
    yield put({
      type: SummaryActionTypes.FETCH_ONE_SUMMARY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SummaryActionTypes.FETCH_ONE_SUMMARY_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSummarys() {
  yield takeLatest(SummaryActionTypes.FETCH_ALL_SUMMARY, fetchAllSummarys);
}

export function* watcherFetchOneSummarys() {
  yield takeLatest(SummaryActionTypes.FETCH_ONE_SUMMARY, fetchOneSummarys);
}
