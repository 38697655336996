import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ConsultantFacilityCostActionTypes } from "./ConsultantFacilityCost.type";

export function* fetchAllConsultantFacilityCosts(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/consultant-facility-cost?project_id=${action.payload.project_id}`);
    yield put({
      type: ConsultantFacilityCostActionTypes.FETCH_ALL_CONSULTANT_FACILITY_COST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ConsultantFacilityCostActionTypes.FETCH_ALL_CONSULTANT_FACILITY_COST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneConsultantFacilityCosts(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/consultant-facility-cost/${action.payload}`
    );
    yield put({
      type: ConsultantFacilityCostActionTypes.FETCH_ONE_CONSULTANT_FACILITY_COST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ConsultantFacilityCostActionTypes.FETCH_ONE_CONSULTANT_FACILITY_COST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllConsultantFacilityCosts() {
  yield takeLatest(ConsultantFacilityCostActionTypes.FETCH_ALL_CONSULTANT_FACILITY_COST, fetchAllConsultantFacilityCosts);
}

export function* watcherFetchOneConsultantFacilityCosts() {
  yield takeLatest(ConsultantFacilityCostActionTypes.FETCH_ONE_CONSULTANT_FACILITY_COST, fetchOneConsultantFacilityCosts);
}
