import { ProbationReviewStateTypes, ProbationReviewActionTypes } from "./ProbationReview.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ProbationReviewStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const ProbationReviewReducer = (
  state: ProbationReviewStateTypes = INITIAL_STATE,
  action: any
): ProbationReviewStateTypes => {
  switch (action.type) {
    case ProbationReviewActionTypes.FETCH_ALL_PROBATION_REVIEW:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ProbationReviewActionTypes.FETCH_ALL_PROBATION_REVIEW_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ProbationReviewActionTypes.FETCH_ALL_PROBATION_REVIEW_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ProbationReviewActionTypes.FETCH_ALL_PROBATION_REVIEW_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ProbationReviewActionTypes.FETCH_ONE_PROBATION_REVIEW:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ProbationReviewActionTypes.FETCH_ONE_PROBATION_REVIEW_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ProbationReviewActionTypes.FETCH_ONE_PROBATION_REVIEW_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ProbationReviewActionTypes.FETCH_ONE_PROBATION_REVIEW_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ProbationReviewReducer;
