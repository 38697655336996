import { ApiCallState } from "../Utils";
import { Department } from "../Department/Department.type";

export type EmployeePerformanceForm = {
  id: number;
  user_id: number;

  name: string;

  readonly createdAt?: Date;
  readonly updatedAt?: Date;

  employee_performance_form_items: EmployeePerformanceFormItem[];
  employee_performance_form_departments: EmployeePerformanceFormDepartment[];
};

export type EmployeePerformanceFormItem = {
  id: number;
  employee_performance_form_id: number;
  parent_id: number;

  is_title: boolean;
  weight: number;
  description: string;

  readonly createdAt?: Date;
  readonly updatedAt?: Date;
};

export type EmployeePerformanceFormDepartment = {
  id: number;
  employee_performance_form_id: number;
  department_id: number;

  readonly createdAt?: Date;
  readonly updatedAt?: Date;

  department: Department;
};

export type EmployeePerformanceFormStateTypes = {
  fetchAll: ApiCallState<EmployeePerformanceForm[]>;
  fetchOne: ApiCallState<EmployeePerformanceForm | {}>;
};

export const EmployeePerformanceFormActionTypes = {
  FETCH_ALL_EMPLOYEE_PERFORMANCE_FORM: "FETCH_ALL_EMPLOYEE_PERFORMANCE_FORM",
  FETCH_ALL_EMPLOYEE_PERFORMANCE_FORM_RESET:
    "FETCH_ALL_EMPLOYEE_PERFORMANCE_FORM_RESET",
  FETCH_ALL_EMPLOYEE_PERFORMANCE_FORM_FAILURE:
    "FETCH_ALL_EMPLOYEE_PERFORMANCE_FORM_FAILURE",
  FETCH_ALL_EMPLOYEE_PERFORMANCE_FORM_SUCCESS:
    "FETCH_ALL_EMPLOYEE_PERFORMANCE_FORM_SUCCESS",

  FETCH_ONE_EMPLOYEE_PERFORMANCE_FORM: "FETCH_ONE_EMPLOYEE_PERFORMANCE_FORM",
  FETCH_ONE_EMPLOYEE_PERFORMANCE_FORM_RESET:
    "FETCH_ONE_EMPLOYEE_PERFORMANCE_FORM_RESET",
  FETCH_ONE_EMPLOYEE_PERFORMANCE_FORM_FAILURE:
    "FETCH_ONE_EMPLOYEE_PERFORMANCE_FORM_FAILURE",
  FETCH_ONE_EMPLOYEE_PERFORMANCE_FORM_SUCCESS:
    "FETCH_ONE_EMPLOYEE_PERFORMANCE_FORM_SUCCESS",
};
