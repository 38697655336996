import { ApiCallState } from "../Utils";

export type FeedBackForm = {
  id: number;
  user_id: number;

  title: string;

  has_item: boolean;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  feed_back_form_questions: FeedBackFormQuestion[];
};

export type FeedBackFormQuestion = {
  id: number;
  feedback_form_id: number;

  question: string;
  type: string;
  required: boolean;

  has_item: boolean;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  feedback_form_question_options: FeedBackFormQuestionOption[];
};

export type FeedBackFormQuestionOption = {
  id: number;
  feedback_form_id: number;

  option: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type FeedBackFormStateTypes = {
  fetchAll: ApiCallState<FeedBackForm[]>;
  fetchOne: ApiCallState<FeedBackForm | {}>;
};

export const FeedBackFormActionTypes = {
  FETCH_ALL_FEED_BACK_FORM: "FETCH_ALL_FEED_BACK_FORM",
  FETCH_ALL_FEED_BACK_FORM_RESET: "FETCH_ALL_FEED_BACK_FORM_RESET",
  FETCH_ALL_FEED_BACK_FORM_FAILURE: "FETCH_ALL_FEED_BACK_FORM_FAILURE",
  FETCH_ALL_FEED_BACK_FORM_SUCCESS: "FETCH_ALL_FEED_BACK_FORM_SUCCESS",

  FETCH_ONE_FEED_BACK_FORM: "FETCH_ONE_FEED_BACK_FORM",
  FETCH_ONE_FEED_BACK_FORM_RESET: "FETCH_ONE_FEED_BACK_FORM_RESET",
  FETCH_ONE_FEED_BACK_FORM_FAILURE: "FETCH_ONE_FEED_BACK_FORM_FAILURE",
  FETCH_ONE_FEED_BACK_FORM_SUCCESS: "FETCH_ONE_FEED_BACK_FORM_SUCCESS",
};
