import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { formatQuery } from "../Utils";
import { StoreCommunicationActionTypes } from "./StoreCommunication.type";

export function* fetchAllStoreCommunication(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/store-communication?${formatQuery(action)}`
    );
    yield put({
      type: StoreCommunicationActionTypes.FETCH_ALL_STORE_COMMUNICATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StoreCommunicationActionTypes.FETCH_ALL_STORE_COMMUNICATION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchStoreCommunicationReports(action: any): any {
  try {
    let query: any = "";

    if (action?.payload && Object.keys(action?.payload).length > 0) {
      query += Object.keys(action?.payload)
        .map((key) => `${key}=${action?.payload[key]}`)
        .join("&");
    }

    const response = yield axios.get(
      `${API_BASE_URI}/store-communication/report?${query}`
    );
    yield put({
      type: StoreCommunicationActionTypes.FETCH_STORE_COMMUNICATION_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StoreCommunicationActionTypes.FETCH_STORE_COMMUNICATION_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllStoreCommunication() {
  yield takeLatest(
    StoreCommunicationActionTypes.FETCH_ALL_STORE_COMMUNICATION,
    fetchAllStoreCommunication
  );
}

export function* watcherFetchStoreCommunicationReports() {
  yield takeLatest(
    StoreCommunicationActionTypes.FETCH_STORE_COMMUNICATION_REPORT,
    fetchStoreCommunicationReports
  );
}
