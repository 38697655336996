import { ApiCallState } from "../Utils";

export type MikadaWeeklyLabourReport= {
  id: number;
  date: string;
  remark: string;
  labour_id: number;
  user_id: number;
};

export type MikadaWeeklyLabourReportStateTypes = {
  fetchAll: ApiCallState<MikadaWeeklyLabourReport[]>;
  fetchOne: ApiCallState<MikadaWeeklyLabourReport | {}>;
};

export const MikadaWeeklyLabourReportActionTypes = {
  FETCH_ALL_MIKADA_WEEKLY_LABOUR_REPORT: "FETCH_ALL_MIKADA_WEEKLY_LABOUR_REPORT",
  FETCH_ALL_MIKADA_WEEKLY_LABOUR_REPORT_RESET: "FETCH_ALL_MIKADA_WEEKLY_LABOUR_REPORT_RESET",
  FETCH_ALL_MIKADA_WEEKLY_LABOUR_REPORT_FAILURE: "FETCH_ALL_MIKADA_WEEKLY_LABOUR_REPORT_FAILURE",
  FETCH_ALL_MIKADA_WEEKLY_LABOUR_REPORT_SUCCESS: "FETCH_ALL_MIKADA_WEEKLY_LABOUR_REPORT_SUCCESS",

  FETCH_ONE_MIKADA_WEEKLY_LABOUR_REPORT: "FETCH_ONE_MIKADA_WEEKLY_LABOUR_REPORT",
  FETCH_ONE_MIKADA_WEEKLY_LABOUR_REPORT_RESET: "FETCH_ONE_MIKADA_WEEKLY_LABOUR_REPORT_RESET",
  FETCH_ONE_MIKADA_WEEKLY_LABOUR_REPORT_FAILURE: "FETCH_ONE_MIKADA_WEEKLY_LABOUR_REPORT_FAILURE",
  FETCH_ONE_MIKADA_WEEKLY_LABOUR_REPORT_SUCCESS: "FETCH_ONE_MIKADA_WEEKLY_LABOUR_REPORT_SUCCESS",
};
