import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { FuelRecievingVoucherActionTypes } from "./FuelRecievingVoucher.type";

export function* fetchAllFuelRecievingVouchers(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/fuel-recieving-voucher`);
    yield put({
      type: FuelRecievingVoucherActionTypes.FETCH_ALL_FUEL_RECIEVING_VOUCHER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FuelRecievingVoucherActionTypes.FETCH_ALL_FUEL_RECIEVING_VOUCHER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneFuelRecievingVouchers(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/fuel-recieving-voucher/${action.payload}`
    );
    yield put({
      type: FuelRecievingVoucherActionTypes.FETCH_ONE_FUEL_RECIEVING_VOUCHER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: FuelRecievingVoucherActionTypes.FETCH_ONE_FUEL_RECIEVING_VOUCHER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllFuelRecievingVouchers() {
  yield takeLatest(FuelRecievingVoucherActionTypes.FETCH_ALL_FUEL_RECIEVING_VOUCHER, fetchAllFuelRecievingVouchers);
}

export function* watcherFetchOneFuelRecievingVouchers() {
  yield takeLatest(FuelRecievingVoucherActionTypes.FETCH_ONE_FUEL_RECIEVING_VOUCHER, fetchOneFuelRecievingVouchers);
}
