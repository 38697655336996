import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { ServiceVoucherActionTypes } from "./ServiceVoucher.type";

export function* fetchAllServiceVouchers(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/service_voucher`);
    yield put({
      type: ServiceVoucherActionTypes.FETCH_ALL_SERVICE_VOUCHER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ServiceVoucherActionTypes.FETCH_ALL_SERVICE_VOUCHER_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneServiceVouchers(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/service_voucher/${action.payload}`
    );
    yield put({
      type: ServiceVoucherActionTypes.FETCH_ONE_SERVICE_VOUCHER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: ServiceVoucherActionTypes.FETCH_ONE_SERVICE_VOUCHER_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllServiceVouchers() {
  yield takeLatest(
    ServiceVoucherActionTypes.FETCH_ALL_SERVICE_VOUCHER,
    fetchAllServiceVouchers
  );
}

export function* watcherFetchOneServiceVouchers() {
  yield takeLatest(
    ServiceVoucherActionTypes.FETCH_ONE_SERVICE_VOUCHER,
    fetchOneServiceVouchers
  );
}
