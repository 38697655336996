import { ApiCallState, PagedData } from "../Utils";

export type ContractAmountType = {
  id?: number;
  date: Date;
  contract_amount: number;
};
export type PaymentType = {
  id?: number;
  submitted_date: Date;
  amount: number;
  status: string;
};
export type VariationType = {
  id?: number;
  amount: number;
  description: Date;
};
export type SubContractFollowUp = {
  id?: number;
  date: Date;
  type:string;
  sub_contractor: string;
  project: string;
  work_type: string;
  contract_amount: ContractAmountType[];
  payment: PaymentType[];
  variation: VariationType[];
};

export type SubContractFollowUpStateTypes = {
  fetchAll: ApiCallState<SubContractFollowUp[]>;
  fetchOne: ApiCallState<SubContractFollowUp | {}>;
  fetchPaged: ApiCallState<PagedData<SubContractFollowUp[]>>;
};

export const SubContractFollowUpActionTypes = {
  FETCH_ALL_SUBCONTRACT_FOLLOWUP: "FETCH_ALL_SUBCONTRACT_FOLLOWUP",
  FETCH_ALL_SUBCONTRACT_FOLLOWUP_RESET: "FETCH_ALL_SUBCONTRACT_FOLLOWUP_RESET",
  FETCH_ALL_SUBCONTRACT_FOLLOWUP_FAILURE:
    "FETCH_ALL_SUBCONTRACT_FOLLOWUP_FAILURE",
  FETCH_ALL_SUBCONTRACT_FOLLOWUP_SUCCESS:
    "FETCH_ALL_SUBCONTRACT_FOLLOWUP_SUCCESS",

  FETCH_PAGED_SUBCONTRACT_FOLLOWUP: "FETCH_PAGED_SUBCONTRACT_FOLLOWUP",
  FETCH_PAGED_SUBCONTRACT_FOLLOWUP_RESET:
    "FETCH_PAGED_SUBCONTRACT_FOLLOWUP_RESET",
  FETCH_PAGED_SUBCONTRACT_FOLLOWUP_FAILURE:
    "FETCH_PAGED_SUBCONTRACT_FOLLOWUP_FAILURE",
  FETCH_PAGED_SUBCONTRACT_FOLLOWUP_SUCCESS:
    "FETCH_PAGED_SUBCONTRACT_FOLLOWUP_SUCCESS",

  FETCH_ONE_SUBCONTRACT_FOLLOWUP: "FETCH_ONE_SUBCONTRACT_FOLLOWUP",
  FETCH_ONE_SUBCONTRACT_FOLLOWUP_RESET: "FETCH_ONE_SUBCONTRACT_FOLLOWUP_RESET",
  FETCH_ONE_SUBCONTRACT_FOLLOWUP_FAILURE:
    "FETCH_ONE_SUBCONTRACT_FOLLOWUP_FAILURE",
  FETCH_ONE_SUBCONTRACT_FOLLOWUP_SUCCESS:
    "FETCH_ONE_SUBCONTRACT_FOLLOWUP_SUCCESS",
};
