import { Material } from "../Material/Material.type";

import { PurchaseOrder } from "../PurchaseOrder/PurchaseOrder.type";
import { PurchaseRequisition } from "../PurchaseRequisition/PurchaseRequisition.type";
import { Store } from "../Store/Store.type";
import { Supplier } from "../Supplier/Supplier.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type StoreCommunication = {
  id: number;
  type: string;
  supplier_id: number;
  purchase_order?: PurchaseOrder;
  supplier?: Supplier;
  purchase_order_id: number;
  supplier_invoice: string;
  purchase_requisition_id: number;
  purchase_requisition: PurchaseRequisition;
  received_by: string;
  gr_receiving_warehouse: Store;
  prepared_by: string;
  authorized_by: string;
  driver_name: string;
  delivered_by: string;
  date: string;
  inventory_type: string;
  purchase_type: string;
  store_id: number;
  store_communication_type: string;
  store_communication_items: StoreCommunicationItem[];
  is_done: boolean;
  gr_prepared_by?: User;
  gr_inspected_by?: User;
  gr_approved_by?: User;
  store_communication_statuses: StoreCommunicationStatus[];
  status: StoreCommunicationStatus[];
  store: Store;
  approved_by: string;
  pad_ref: string;
  has_vat: boolean;
  has_withhold: boolean;
  updatedAt: Date;
  receiving_type: string;
  donation: string;
  waybill_no: string;
  ref_no: string;
  converted_to_grn: boolean;
  is_void: boolean;
  user_id: number;
};

export type StoreCommunicationReport = {
  approved_by?: any;
  code: string;
  date: string;
  description: string;
  from: string;
  to: string;
  item_category: string;
  id: number;
  major_category: string;
  purchase_requisition_id: string;
  pad_ref: string;
  prepared_by: string;
  quantity: number;
  received_by?: any;
  remark: string;
  total: number;
  unit: string;
};

export type StoreCommunicationStatus = {
  store_communication_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type StoreCommunicationItem = {
  material: Material;
  material_id: number;
  unit_price: number;
  quantity: number;
  damaged_quantity: number;
  unit: string;
  identification_number: number;
  id: any;
};

export type StoreCommunicationStateTypes = {
  fetchAll: ApiCallState<StoreCommunication[]>;
  fetchReport: ApiCallState<StoreCommunicationReport[]>;
};

export const StoreCommunicationActionTypes = {
  FETCH_ALL_STORE_COMMUNICATION: "FETCH_ALL_STORE_COMMUNICATION",
  FETCH_ALL_STORE_COMMUNICATION_RESET: "FETCH_ALL_STORE_COMMUNICATION_RESET",
  FETCH_ALL_STORE_COMMUNICATION_FAILURE:
    "FETCH_ALL_STORE_COMMUNICATION_FAILURE",
  FETCH_ALL_STORE_COMMUNICATION_SUCCESS:
    "FETCH_ALL_STORE_COMMUNICATION_SUCCESS",

  FETCH_STORE_COMMUNICATION_REPORT: "FETCH_STORE_COMMUNICATION_REPORT",
  FETCH_STORE_COMMUNICATION_REPORT_RESET:
    "FETCH_STORE_COMMUNICATION_REPORT_RESET",
  FETCH_STORE_COMMUNICATION_REPORT_FAILURE:
    "FETCH_STORE_COMMUNICATION_REPORT_FAILURE",
  FETCH_STORE_COMMUNICATION_REPORT_SUCCESS:
    "FETCH_STORE_COMMUNICATION_REPORT_SUCCESS",
};
