import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { DislocationActionTypes } from "./Dislocation.type";

export function* fetchAllDislocations(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/dislocation`);
    yield put({
      type: DislocationActionTypes.FETCH_ALL_DISLOCATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DislocationActionTypes.FETCH_ALL_DISLOCATION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneDislocations(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/dislocation/single?year=${action.payload?.year}&?month=${action.payload?.month}`
    );
    yield put({
      type: DislocationActionTypes.FETCH_ONE_DISLOCATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DislocationActionTypes.FETCH_ONE_DISLOCATION_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllDislocations() {
  yield takeLatest(DislocationActionTypes.FETCH_ALL_DISLOCATION, fetchAllDislocations);
}

export function* watcherFetchOneDislocations() {
  yield takeLatest(DislocationActionTypes.FETCH_ONE_DISLOCATION, fetchOneDislocations);
}
