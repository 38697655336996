import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import AccountReducer from "./Account/Account.reducer";
import AttendanceReducer from "./Attendance/Attendance.reducer";
import BoqReducer from "./Boq/Boq.reducer";
import BoqActivityPlanReducer from "./BoqActivityPlan/BoqActivityPlan.reducer";
import BoqPlanReducer from "./BoqPlan/BoqPlan.reducer";
import ConsultantReducer from "./Consultant/Consultant.reducer";
import ContractReducer from "./Contract/Contract.reducer";
import ContractorReducer from "./Contractor/Contractor.reducer";
import CustomerReducer from "./Customer/Customer.reducer";
import DepartmentReducer from "./Department/Department.reducer";
import DocumentReducer from "./Document/Document.reducer";
import EmployeeExtraReducer from "./EmployeeExtra/EmployeeExtra.reducer";
import EmployeeRequestReducer from "./EmployeeRequest/EmployeeRequest.reducer";
import EquipmentInventoryReducer from "./EquipmentInventory/EquipmentInventory.reducer";
import EquipmentPlanReducer from "./EquipmentPlan/EquipmentPlan.reducer";
import EquipmentTransferReducer from "./EquipmentTransfer/EquipmentTransfer.reducer";
import EquipmentUsageReducer from "./EquipmentUsage/EquipmentUsage.reducer";
import EquipmentUsagePlanReducer from "./EquipmentUsagePlan/EquipmentUsagePlan.reducer";
import ExpenseReducer from "./Expense/Expense.reducer";
import InvoiceReducer from "./Invoice/Invoice.reducer";
import LabourReducer from "./Labour/Labour.reducer";
import LabourPlanReducer from "./LabourPlan/LabourPlan.reducer";
import LabourUsageReducer from "./LabourUsage/LabourUsage.reducer";
import ManpowerOvertimeReducer from "./ManpowerOvertime/ManpowerOvertime.reducer";
import MaterialReducer from "./Material/Material.reducer";
import MaterialInventoryReducer from "./MaterialInventory/MaterialInventory.reducer";
import MaterialPlanReducer from "./MaterialPlan/MaterialPlan.reducer";
import MaterialTransferReducer from "./MaterialTransfer/MaterialTransfer.reducer";
import PaymentReducer from "./Payment/Payment.reducer";
import PaymentRetentionReducer from "./PaymentRetention/PaymentRetention.reducer";
import PayrollReducer from "./Payroll/Payroll.reducer";

import ProjectReducer from "./Project/Project.reducer";
import ProjectStaffReducer from "./ProjectStaff/ProjectStaff.reducer";
import RebarReducer from "./Rebar/Rebar.reducer";
import ScheduleReducer from "./Schedule/Schedule.reducer";
import ServiceReducer from "./Service/Service.reducer";
import StaffReducer from "./Staff/Staff.reducer";
import StaffPlanReducer from "./StaffPlan/StaffPlan.reducer";
import SubContractReducer from "./SubContract/SubContract.reducer";
import SubContractPlanReducer from "./SubContractPlan/SubContractPlan.reducer";
import SupplierReducer from "./Supplier/Supplier.reducer";
import TakeoffReducer from "./Takeoff/Takeoff.reducer";
import TourReducer from "./Tour/Tour.reducer";
import UnitBreakdownReducer from "./UnitBreakdown/UnitBreakdown.reducer";
import UsageReducer from "./Usage/Usage.reducer";
import UserReducer from "./User/User.reducer";
import VariationReducer from "./Variation/Variation.reducer";
import IdReducer from "./ID/Id.reducer";
import MaterialRequisitionReducer from "./MaterialRequisition/MaterialRequisition.reducer";
import PurchaseRequisitionReducer from "./PurchaseRequisition/PurchaseRequisition.reducer";
import PurchaseOrderReducer from "./PurchaseOrder/PurchaseOrder.reducer";
import PurchaseBillingReducer from "./PurchaseBilling/PurchaseBilling.reducer";
import GoodReceivedReducer from "./GoodReceived/GoodReceived.reducer";
import RenterReducer from "./Renter/Renter.reducer";
import GoodOutReducer from "./GoodOut/GoodOut.reducer";
import GoodReturnReducer from "./GoodReturn/GoodReturn.reducer";
import EmployeeHistoryReducer from "./EmployeeHistory/EmployeeHistory.reducer";
import DocumentWorkOrderReducer from "./DocumentWorkOrder/DocumentWorkOrder.reducer";
import EquipmentWOReducer from "./EquipmentWO/EquipmentWO.reducer";
import HRPolicyReducer from "./HRPolicy/HRPolicy.reducer";
import AbsenceReducer from "./Absence/Absence.reducer";
import EmployeeDocumentReducer from "./EmployeeDocument/EmployeeDocument.reducer";
import LogReducer from "./Log/Log.reducer";
import ClientReducer from "./Client/Client.reducer";
import EmployeeAccommodationPlanReducer from "./EmployeeAccommodationPlan/EmployeeAccommodationPlan.reducer";
import AllowanceReducer from "./Allowance/Allowance.reducer";
import AllowancePolicyReducer from "./AllowancePolicy/AllowancePolicy.reducer";
import StandardReducer from "./Standard/Standard.reducer";
import BoqStandardReducer from "./BoqStandard/BoqStandard.reducer";
import ResourceReducer from "./Resource/Resource.reducer";
import SiteDiaryReducer from "./SiteDiary/SiteDiary.reducer";
import WeekReportReducer from "./WeekReport/WeekReport.reducer";
import MasterScheduleReducer from "./MasterSchedule/MasterSchedule.reducer";
import StaffAttendanceReducer from "./StaffAttendance/StaffAttendance.reducer";
import EmployeeUserReducer from "./EmployeeUser/EmployeeUser.reducer";
import ReviewFormReducer from "./ReviewForm/ReviewForm.reducer";
import EmployeeReviewReducer from "./EmployeeReview/EmployeeReview.reducer";
import BonusReducer from "./Bonus/Bonus.reducer";
import VacancyReducer from "./Vacancy/vacancy.reducer";
import ApplicationReducer from "./Application/Application.reducer";
import MediaReducer from "./Media/Media.reducer";
import SHEReducer from "./SHE/SHE.reducer";

import SharedDocumentReducer from "./SharedDocument/SharedDocument.reducer";
import BankAccountReducer from "./BankAccount/BankAccount.reducer";
import AccountTransactionReducer from "./AccountTransaction/AccountTransaction.reducer";
import RentContractReducer from "./RentContract/RentContract.reducer";
import ReceivableReducer from "./Receivable/Receivable.reducer";
import AttachmentReducer from "./Attachment/Attachment.reducer";
import PettyCashReducer from "./PettyCash/PettyCash.reducer";
import ReplenishmentReducer from "./Replenish/Replenishment.reducer";
import ReplenishmentTransactionReducer from "./ReplenishTransaction/ReplenishmentTransaction.reducer";
import ReplenishmentRequestReducer from "./ReplenishRequest/ReplenishmentRequest.reducer";

import VariationFileReducer from "./VariationFile/VariationFile.reducer";
import CRVPaymentReducer from "./CRVPayment/CRVPayment.reducer";
import PostCheckReducer from "./PostCheck/PostCheck.reducer";
import DailyReportReducer from "./DailyReport/DailyReport.reducer";
import MaterialUsageReducer from "./MaterialUsage/MaterialUsage.reducer";
import PayableReducer from "./Payable/Payable.reducer";
import BuildingReducer from "./Building/Building.reducer";
import ApartmentReducer from "./Apartment/Apartment.reducer";
import ApartmentExpenseReducer from "./ApartmentExpense/ApartmentExpense.reducer";
import ContractSalesReducer from "./ContractSales/ContractSales.reducer";
import EstimatedBuildingCostReducer from "./EstimatedBuildingCost/EstimatedBuildingCost.reducer";
import ProformaReducer from "./Proforma/Proforma.reducer";
import CurrencyReducer from "./Currency/Currency.reducer";
import ProcurementPlanReducer from "./ProcurementPlan/ProcurementPlan.reducer";
import PaymentSummaryReducer from "./PaymentSummary/PaymentSummary.reducer";
import EquipmentRequestReducer from "./EquipmentRequest/EquipmentRequest.reducer";
import EquipmentProductivityReducer from "./EquipmentProductivity/EquipmentProductivity.reducer";
import JustificationReducer from "./Justification/Justification.reducer";
import BriefingReducer from "./Briefing/Briefing.reducer";
import CriticalIssueReducer from "./CriticalIssue/CriticalIssue.reducer";
import BudgetRequestReducer from "./BudgetRequest/BudgetRequest.reducer";
import BudgetShiftingRequestReducer from "./BudgetShiftingRequest/BudgetShiftingRequest.reducer";
import BudgetUtilizationReducer from "./BudgetUtilization/BudgetUtilization.reducer";
import FuelRequestReducer from "./FuelRequest/FuelRequest.reducer";
import FuelUtilizationReducer from "./FuelUtilization/FuelUtilization.reducer";
import FinanceDailyReportReducer from "./FinanceDailyReport/FinanceDailyReport.reducer";
import MonthlyProgressReportReducer from "./MonthlyProgressReport/MonthlyProgressReport.reducer";
import SubContractorPerformanceReducer from "./SubContractorPerformance/SubContractorPerformance.reducer";
import PaymentFollowUpReducer from "./PaymentFollowUp/PaymentFollowUp.reducer";
import StoreReducer from "./Store/Store.reducer";
import ProformaComparisonReducer from "./ProformaComparison/ProformaComparison.reducer";
import SubContractorPaymentReducer from "./SubContractorPayment/SubContractorPayment.reducer";
import WeeklyProgressSummaryReducer from "./WeeklyProgressSummary/WeeklyProgressSummary.reducer";
import UncollectedIpcReducer from "./UncollectedIpc/UncollectedIpc.reducer";
import ProjectListReducer from "./ProjectList/ProjectList.reducer";
import MasterListReducer from "./MasterList/MasterList.reducer";
import FuelRequestVoucherReducer from "./FuelRequestVoucher/FuelRequestVoucher.reducer";
import FuelRecievingVoucherReducer from "./FuelRecievingVoucher/FuelRecievingVoucher.reducer";
import FuelIssueVoucherReducer from "./FuelIssueVoucher/FuelIssueVoucher.reducer";
import PayableV2Reducer from "./PayableV2/PayableV2.reducer";
import PaymentV2PaymentReducer from "./PayableV2Payment/PayableV2Payment.reducer";
import TaskReducer from "./Task/Task.reducer";
import BiddingScheduleReducer from "./BiddingSchedule/BiddingSchedule.reducer";
import UploadDocumentReducer from "./UploadDocument/UploadDocument.reducer";
import MikadaDailyReportReducer from "./MikadaDailyReport/MikadaDailyReport.reducer";
import BriefingFormReducer from "./BriefingForm/BriefingForm.reducer";
import MikadaWeeklyProgressReducer from "./MikadaWeeklyProgress/MikadaWeeklyProgress.reducer";
import MikadaWeeklyEquipmentReportReducer from "./MikadaWeeklyEquipmentReport/MikadaWeeklyEquipmentReport.reducer";
import MikadaWeeklyLabourReportReducer from "./MikadaWeeklyLabourReport/MikadaWeeklyLabourReport.reducer";
import MikadaWeeklySubContractReportReducer from "./MikadaWeeklySubContractReport/MikadaWeeklySubContractReport.reducer";
import MikadaWeeklyMaterialReportReducer from "./MikadaWeeklyMaterialReport/MikadaWeeklyMaterialReport.reducer";
import WorkEvaluationReducer from "./WorkEvaluation/WorkEvaluation.reducer";
import MikadaMonthlyReportReducer from "./MikadaMonthlyReport/MikadaMonthlyReport.reducer";
import MikadaAnnualReportReducer from "./MikadaAnnualReport/MikadaAnnualReport.reducer";
import AdditionBoqReducer from "./AdditionBoq/AdditionBoq.reducer";
import BillSummaryReducer from "./BillSummary/BillSummary.reducer";
import OmissionBoqReducer from "./OmissionBoq/OmissionBoq.reducer";
import SummaryReducer from "./Summary/Summary.reducer";
import SupplementaryBoqReducer from "./SupplementaryBoq/SupplementaryBoq.reducer";
import SiteVisitReducer from "./SiteVisit/SiteVisit.reducer";
import SubContractRegistryReducer from "./SubContractRegistry/SubContractRegistry.reducer";
import SubContractPaymentUpdateReducer from "./SubContractPaymentUpdate/SubContractPaymentUpdate.reducer";
import BudgetPlanningReducer from "./BudgetPlanning/BudgetPlanning.reducer";
import SubContractAgreementReducer from "./SubContractAgreement/SubContractAgreement.reducer";
import BudgetPlanningItemReducer from "./BudgetPlanningItem/BudgetPlanningItem.reducer";
import BoqItemGroupReducer from "./BoqItemGroup/BoqItemGroup.reducer";
import ManningReducer from "./Manning/Manning.reducer";
import HRRequestReducer from "./HRRequest/HRRequest.reducer";
import ApplicantRegistryReducer from "./ApplicantRegistry/ApplicantRegistry.reducer";
import AssessmentReducer from "./Assessment/Assessment.reducer";
import ApplicantRegistryItemReducer from "./ApplicantRegistryItem/ApplicantRegistryItem.reducer";
import CommentReducer from "./Comment/Comment.reducer";
import MaintenanceRequestReducer from "./MaintenanceRequest/MaintenanceRequest.reducer";
import MaintenanceVoucherReducer from "./MaintenanceVoucher/MaintenanceVoucher.reducer";
import AccidentFollowUpReducer from "./AccidentFollowUp/AccidentFollowUp.reducer";
import DailyWorkStatusReducer from "./DailyWorkStatus/DailyWorkStatus.reducer";
import InsuranceReducer from "./Insurance/Insurance.reducer";
import StoreRequisitionReducer from "./StoreRequisition/StoreRequisition.reducer";
import StoreRequisitionFollowUpReducer from "./StoreRequisitionFollowUp/StoreRequisitionFollowUp.reducer";
import ServiceVoucherReducer from "./ServiceVoucher/ServiceVoucher.reducer";
import ProjectAllowanceReducer from "./ProjectAllowance/ProjectAllowance.reducer";
import ProbationReviewReducer from "./ProbationReview/ProbationReview.reducer";
import GradeReducer from "./Grade/Grade.reducer";
import ScaleReducer from "./Scale/Scale.reducer";
import ScaleItemReducer from "./ScaleItem/ScaleItem.reducer";
import AllowableScaleReducer from "./AllowableScale/AllowableScale.reducer";
import EmployeeAllowanceReducer from "./EmployeeAllowance/EmployeeAllowance.reducer";
import DepartmentRelationShipReducer from "./DepartmentRelationShip/DepartmentRelationShip.reducer";
import ManningCategoryReducer from "./ManningCategory/ManningCategory.reducer";
import PaymentFileReducer from "./PaymentFile/PaymentFile.reducer";
import PriceEscalationReducer from "./PriceEscalation/PriceEscalation.reducer";
import PriceEscalationFileReducer from "./PriceEscalationFile/PriceEscalationFile.reducer";
import DetailReportActivityReducer from "./DetailReportActivity/DetailReportActivity.reducer";
import EquipmentCostReducer from "./EquipmentCost/EquipmentCost.reducer";
import FuelCostReducer from "./FuelCost/FuelCost.reducer";
import OilLubricantCostReducer from "./OilLubricantCost/OilLubricantCost.reducer";
import ProjectTeamReducer from "./ProjectTeam/ProjectTeam.reducer";
import TravelRegistryReducer from "./TravelRegistry/TravelRegistry.reducer";
import ResignationReducer from "./Resignation/Resignation.reducer";
import StaffBioReducer from "./StaffBio/StaffBio.reducer";
import LoanReducer from "./Loan/Loan.reducer";
import LoanExtensionReducer from "./LoanExtension/LoanExtension.reducer";
import LoanRequestReducer from "./LoanRequest/LoanRequest.reducer";
import EmployeePerformanceFormReducer from "./EmployeePerformanceForm/EmployeePerformanceForm.reducer";
import EmployeePerformanceReducer from "./EmployeePerformance/EmployeePerformance.reducer";
import DailyTaskReducer from "./DailyTask/DailyTask.reducer";
import SelfAttendingUserReducer from "./SelfAttendingUser/SelfAttendingUser.reducer";
import NewsAndMotivationReducer from "./NewsAndMotivation/NewsAndMotivation.reducer";
import AutomaticMessageReducer from "./AutomaticMessage/AutomaticMessage.reducer";
import TrainingReducer from "./Training/Training.reducer";
import AttendanceLocationUserReducer from "./AttendanceLocationUser/AttendanceLocationUser.reducer";

import AlertReducer from "./Alert/Alert.reducer";
import DisciplineReducer from "./Discipline/Discipline.reducer";
import SMSMessageReducer from "./SMSMessage/SMSMessage.reducer";
import EarlyLeaveRequestReducer from "./EarlyLeaveRequest/EarlyLeaveRequest.reducer";
import DraftReducer from "./Draft/Draft.reducer";
import PerformancePointPolicyReducer from "./PerformancePointPolicy/PerformancePointPolicy.reducer";
import PromotionReducer from "./Promotion/Promotion.reducer";
import PartnerReducer from "./Partner/Partner.reducer";
import OvertimeRequest2Reducer from "./OvertimeRequest2/OvertimeRequest2.reducer";
import PartnerMessageReducer from "./PartnerMessage/PartnerMessage.reducer";
import HolidayReducer from "./Holiday/Holiday.reducer";
import FeedBackFormReducer from "./FeedBackForm/FeedBackForm.reducer";
import FeedBackResponseReducer from "./FeedBackResponse/FeedBackResponse.reducer";
import AttendanceLocationReducer from "./AttendanceLocation/AttendanceLocation.reducer";
import SubContractSecurityReducer from "./SubContractSecurity/SubContractSecurity.reducer";
import DailyProductivityReportReducer from "./DailyProductivityReport/DailyProductivityReport.reducer";
import SparePartCostReducer from "./SparePartCost/SparePartCost.reducer";
import ConsultantFacilityCostReducer from "./ConsultantFacilityCost/ConsultantFacilityCost.reducer";
import ContractorFacilityCostReducer from "./ContractorFacilityCost/ContractorFacilityCost.reducer";
import LaboratoryEquipmentCostReducer from "./LaboratoryEquipmentCost/LaboratoryEquipmentCost.reducer";
import SurveyingEquipmentCostReducer from "./SurveyingEquipmentCost/SurveyingEquipmentCost.reducer";
import OtherExpenseReducer from "./OtherExpense/OtherExpense.reducer";
import PlanExecutionReducer from "./PlanExecution/PlanExecution.reducer";
import StaffMealReducer from "./StaffMeal/StaffMeal.reducer";
import FamilyAllotmentReducer from "./FamilyAllotment/FamilyAllotment.reducer";

import ActivityWaitageReducer from "./ActivityWaitage/ActivityWaitage.reducer";
import ProblemEncounteredReducer from "./ProblemEncountered/ProblemEncountered.reducer";
import IncomeExpenseReducer from "./IncomeExpense/IncomeExpense.reducer";
import ProgressSummaryReducer from "./ProgressSummary/ProgressSummary.reducer";
import MonthlySiteReportReducer from "./MonthlySiteReport/MonthlySiteReport.reducer";
import SummaryOfCostReducer from "./SummaryOfCost/SummaryOfCost.reducer";
import OperationStandardReducer from "./OperationStandard/OperationStandard.reducer";

import StaffPlaceOfWorkReducer from "./StaffPlaceOfWork/StaffPlaceOfWork.reducer";
import StaffLanguageReducer from "./StaffLanguage/StaffLanguage.reducer";
import StaffEducationReducer from "./StaffEducation/StaffEducation.reducer";
import MaterialMajorCategoryReducer from "./MaterialMajorCategory/MaterialMajorCategory.reducer";
import MaterialSubCategoryReducer from "./MaterialSubCategory/MaterialSubCategory.reducer";
import MaterialStartingBalanceReducer from "./MaterialStartingBalance/MaterialStartingBalance.reducer";
import MaterialLimitReducer from "./MaterialLimit/MaterialLimit.reducer";

import OvertimeReducer from "./Overtime/Overtime.reducer";
import PlaceOfWorkReducer from "./PlaceOfWork/PlaceOfWork.reducer";
import PeridiumRulesReducer from "./PeridiumRules/PeridiumRules.reducer";

import TelegraphicTransferReducer from "./TelegraphicTransfer/TelegraphicTransfer/TelegraphicTransfer.reducer";
import BankLoanReducer from "./BankLoan/BankLoan/BankLoan.reducer";
import BankLoanPaymentScheduleReducer from "./BankLoan/BankLoanPaymentSchedule/BankLoanPaymentSchedule.reducer";

import DislocationReducer from "./Dislocation/Dislocation.reducer";
import PeridiumRequestReducer from "./PeridiumRequest/PeridiumRequest.reducer";
import StaffWorkExperienceReducer from "./StaffWorkExperience/StaffWorkExperience.reducer";
import StaffFamilyReducer from "./StaffFamily/StaffFamily.reducer";
import StaffDisciplineReducer from "./StaffDiscipline/StaffDiscipline.reducer";
import StaffEmergencyContactReducer from "./StaffEmergencyContact/StaffEmergencyContact.reducer";
import StaffTaxInformationReducer from "./StaffTaxInformation/StaffTaxInformation.reducer";
import OfferReducer from "./Offer/Offer.reducer";
import ProjectTypeReducer from "./ProjectType/ProjectType.reducer";
import StaffReligionReducer from "./StaffReligion/StaffReligion.reducer";
import StaffTransferReducer from "./StaffTransfer/StaffTransfer.reducer";
import SiteAttendanceReducer from "./SiteAttendance/SiteAttendance.reducer";
import StaffSalaryAdjustmentReducer from "./StaffSalaryAdjustment/StaffSalaryAdjustment.reducer";
import HRAllowanceReducer from "./HRAllowance/HRAllowance.reducer";
import DailySiteReportReducer from "./DailySiteReport/DailySiteReport.reducer";
import WeeklySiteReportReducer from "./WeeklySiteReport/WeeklySiteReport.reducer";
import MaterialDailySiteReportReducer from "./MaterialDailySiteReport/MaterialDailySiteReport.reducer";
import EquipmentOIDReducer from "./EquipmentOID/EquipmentOID.reducer";
import CheckPaymentVoucherReducer from "./CheckPaymentVoucher/CheckPaymentVoucher.reducer";
import BudgetReducer from "./Budget/Budget.reducer";
import ArchiveReducer from "./Archive/Archive.reducer";
import ContractAdministrationReducer from "./ContractAdministration/ContractAdministration.reducer";
import IllegalReceiptReducer from "./IllegalReceipt/IllegalReceipt.reducer";
import CVBankReducer from "./CVBank/CVBank.reducer";
import PermanentEmployeeAttendanceReducer from "./PermanentEmployeeAttendance/PermanentEmployeeAttendance.reducer";
import EmployeeTrainingReducer from "./EmployeeTraining/EmployeeTraining.reducer";
import PostDateVoucherReducer from "./PostDateVoucher/PostDateVoucher.reducer";
import RGTSReducer from "./RGTS/RGTS.reducer";
import JournalVoucherReducer from "./JournalVoucher/JournalVoucher.reducer";
import TransferRequestReducer from "./TransferRequest/TransferRequest.reducer";
import CanteenDeductionReducer from "./CanteenDeduction/CanteenDeduction.reducer";
import SocialCommitmentReducer from "./SocialCommitment/SocialCommitment.reducer";
import PenalityReducer from "./Penality/Penality.reducer";
import BankContactReducer from "./BankContact/BankContact.reducer";
import StaffPerformanceReducer from "./StaffPerformance/StaffPerformance.reducer";
import ChartsOfAccountReducer from "./ChartsOfAccount/ChartsOfAccount.reducer";
import UserSeenReducer from "./UserSeen/UserSeen.reducer";
import TireRequestReducer from "./TireRequest/TireRequest.reducer";
import ManufacturingOrderReducer from "./ManufacturingOrder/ManufacturingOrder.reducer";
import InventoryReducer from "./Inventory/Inventory.reducer";
import StoreCommunicationReducer from "./StoreCommunication/StoreCommunication.reducer";
import GoodInReducer from "./GoodIn/GoodIn.reducer";
import GoodIssueReducer from "./GoodIssue/GoodIssue.reducer";
import DispatchReducer from "./Dispatch/Dispatch.reducer";
import DamageReturnReducer from "./DamageReturn/DamageReturn.reducer";
import TemporaryDeliveryNoteReducer from "./TemporaryDeliveryNote/TemporaryDeliveryNote.reducer";
import MaterialInspectionRepairReducer from "./MaterialInspectionRepair/MaterialInspectionRepair.reducer";
import DocumentTransmitalReducer from "./DocumentTransmital/DocumentTransmital.reducer";
import OutsourcingMaintenanceReducer from "./OutsourcingMaintenance/OutsourcingMaintenance.reducer";
import FuelReceiveReducer from "./FuelReceive/FuelReceive.reducer";
import FuelAndLubiconRequestReducer from "./FuelAndLubiconRequest/FuelAndLubiconRequest.reducer";
import GatePassReducer from "./GatePass/GatePass.reducer";
import FixedAssetReceivedReducer from "./FixedAssetReceived/FixedAssetReceived.reducer";
import FixedAssetReturnReducer from "./FixedAssetReturn/FixedAssetReturn.reducer";
import FixedAssetIssueReducer from "./FixedAssetIssue/FixedAssetIssue.reducer";
import FixedAssetOutReducer from "./FixedAssetOut/FixedAssetOut.reducer";
import FixedAssetInReducer from "./FixedAssetIn/FixedAssetIn.reducer";
import FixedAssetTransferReducer from "./FixedAssetTransfer/FixedAssetTransfer.reducer";
import FuelIssueReducer from "./FuelIssue/FuelIssue.reducer";
import FuelReceivingReducer from "./FuelReceiving/FuelReceiving.reducer";
import ProjectFuelRequestReducer from "./ProjectFuelRequest/ProjectFuelRequest.reducer";
import ServiceRequestReducer from "./ServiceRequest/ServiceRequest.reducer";
import FinishedProductReducer from "./FinishedProduct/FinishedProduct.reducer";
import SubContractFollowUpReducer from "./SubContractFollowUp/SubContractFollowUp.reducer";
import OperationDailyReportReducer from "./OperationDailyReport/OperationDailyReport.reducer";
import OwnVehicleReducer from "./OwnVehicle/OwnVehicle.reducer";
import MaintenanceCostReducer from "./MaintenanceCost/MaintenanceCost.reducer";
import DailyStatusReportReducer from "./DailyStatusReport/DailyStatusReport.reducer";
import FuelUtilizationReportReducer from "./FuelUtilizationReport/FuelUtilizationReport.reducer";
import TimeUtilizationReportReducer from "./TimeUtilizationReport/TimeUtilizationReport.reducer";
import MaterialDeliveryReducer from "./MaterialDelivery/MaterialDelivery.reducer";
import OperationMonthlyReportReducer from "./OperationMonthlyReport/OperationMonthlyReport.reducer";
import ProjectPermanentAttendanceReducer from "./ProjectPermanentAttendance/ProjectPermanentAttendance.reducer";
import WorkExperienceSummaryReducer from "./WorkExperienceSummary/WorkExperienceSummary.reducer";
import UserRoleReducer from "./UserRole/UserRole.reducer";
import DiemPolicyReducer from "./DiemPolicy/DiemPolicy.reducer";
import PaymentRequisitionReducer from "./PaymentRequisition/PaymentRequisition.reducer";
import EquipmentListReducer from "./EquipmentList/EquipmentList.reducer";
import VehicleListReducer from "./VehicleList/VehicleList.reducer";
import TemporaryAttendanceReducer from "./TemporaryAttendance/TemporaryAttendance.reducer";
import ExternalRepairOrderReducer from "./ExternalRepairOrder/ExternalRepairOrder.reducer";

const PersistConfig = {
  key: "root",
  storage,
  whitelist: [],
};

const RootReducer = combineReducers({
  bank_contact: BankContactReducer,
  penality: PenalityReducer,
  project_type: ProjectTypeReducer,
  offer: OfferReducer,
  place_of_work: PlaceOfWorkReducer,
  staff_place_of_work: StaffPlaceOfWorkReducer,
  staff_language: StaffLanguageReducer,
  staff_education: StaffEducationReducer,
  project: ProjectReducer,
  contractor: ContractorReducer,
  consultant: ConsultantReducer,
  takeoff: TakeoffReducer,
  rebar: RebarReducer,
  boq: BoqReducer,
  boq_plan: BoqPlanReducer,
  boq_activity_plan: BoqActivityPlanReducer,
  sub_contract_plan: SubContractPlanReducer,
  material: MaterialReducer,
  supplier: SupplierReducer,
  material_inventory: MaterialInventoryReducer,
  material_transfer: MaterialTransferReducer,
  equipment_inventory: EquipmentInventoryReducer,
  equipment_transfer: EquipmentTransferReducer,
  usage: UsageReducer,
  labour: LabourReducer,
  equipment_usage: EquipmentUsageReducer,
  labour_usage: LabourUsageReducer,
  equipment_plan: EquipmentPlanReducer,
  equipment_usage_plan: EquipmentUsagePlanReducer,
  material_plan: MaterialPlanReducer,
  labour_plan: LabourPlanReducer,
  department: DepartmentReducer,
  staff: StaffReducer,
  staff_plan: StaffPlanReducer,
  account: AccountReducer,
  customer: CustomerReducer,
  service: ServiceReducer,
  unit_breakdown: UnitBreakdownReducer,
  variation: VariationReducer,
  sub_contract: SubContractReducer,
  invoice: InvoiceReducer,
  expense: ExpenseReducer,
  payment: PaymentReducer,
  payment_retention: PaymentRetentionReducer,
  document: DocumentReducer,
  project_staff: ProjectStaffReducer,
  manpower_overtime: ManpowerOvertimeReducer,
  payroll: PayrollReducer,
  tour: TourReducer,
  schedule: ScheduleReducer,
  employee_extra: EmployeeExtraReducer,
  user: UserReducer,
  attendance: AttendanceReducer,
  contract: ContractReducer,
  employee_request: EmployeeRequestReducer,
  id: IdReducer,
  material_requisition: MaterialRequisitionReducer,
  purchase_requisition: PurchaseRequisitionReducer,
  purchase_order: PurchaseOrderReducer,
  purchase_billing: PurchaseBillingReducer,
  good_received: GoodReceivedReducer,
  renter: RenterReducer,
  good_out: GoodOutReducer,
  good_return: GoodReturnReducer,
  employee_history: EmployeeHistoryReducer,
  document_work_order: DocumentWorkOrderReducer,
  equipment_work_order: EquipmentWOReducer,
  hr_policy: HRPolicyReducer,
  absence: AbsenceReducer,
  employee_document: EmployeeDocumentReducer,
  employee_accommodation_plan: EmployeeAccommodationPlanReducer,
  client: ClientReducer,
  allowance: AllowanceReducer,
  allowance_policy: AllowancePolicyReducer,
  standard: StandardReducer,
  log: LogReducer,
  boq_standard: BoqStandardReducer,
  resource: ResourceReducer,
  site_diary: SiteDiaryReducer,
  week_report: WeekReportReducer,
  master_schedule: MasterScheduleReducer,
  staff_attendance: StaffAttendanceReducer,
  employee_user: EmployeeUserReducer,
  review_form: ReviewFormReducer,
  employee_review: EmployeeReviewReducer,
  bonus: BonusReducer,
  vacancies: VacancyReducer,
  applications: ApplicationReducer,
  media: MediaReducer,
  she: SHEReducer,
  sharedDocument: SharedDocumentReducer,
  bankAccount: BankAccountReducer,
  accountTransaction: AccountTransactionReducer,
  rentContract: RentContractReducer,
  receivable: ReceivableReducer,
  attachment: AttachmentReducer,
  petty_cash: PettyCashReducer,
  replenishment: ReplenishmentReducer,
  replenishment_transaction: ReplenishmentTransactionReducer,
  replenishment_request: ReplenishmentRequestReducer,
  variation_file: VariationFileReducer,
  crv_payment: CRVPaymentReducer,
  post_check: PostCheckReducer,
  daily_report: DailyReportReducer,
  material_usage: MaterialUsageReducer,
  payable: PayableReducer,
  building: BuildingReducer,
  apartment: ApartmentReducer,
  apartment_expense: ApartmentExpenseReducer,
  contract_sales: ContractSalesReducer,
  estimated_building_cost: EstimatedBuildingCostReducer,
  proforma: ProformaReducer,
  currency: CurrencyReducer,
  procurement_plan: ProcurementPlanReducer,
  payment_summary: PaymentSummaryReducer,
  equipment_request: EquipmentRequestReducer,
  equipment_productivity: EquipmentProductivityReducer,
  justification: JustificationReducer,
  briefing: BriefingReducer,
  critical_issue: CriticalIssueReducer,
  budget_request: BudgetRequestReducer,
  budget_shifting_request: BudgetShiftingRequestReducer,
  budget_utilization: BudgetUtilizationReducer,
  fuel_request: FuelRequestReducer,
  fuel_utilization: FuelUtilizationReducer,
  finance_daily_report: FinanceDailyReportReducer,
  payment_follow_up: PaymentFollowUpReducer,
  monthly_progress_report: MonthlyProgressReportReducer,
  sub_contractor_performance: SubContractorPerformanceReducer,
  store: StoreReducer,
  proforma_comparison: ProformaComparisonReducer,
  sub_contractor_payment: SubContractorPaymentReducer,
  weekly_progress_summary: WeeklyProgressSummaryReducer,
  uncollected_ipc: UncollectedIpcReducer,
  project_list: ProjectListReducer,
  master_list: MasterListReducer,
  fuel_request_voucher: FuelRequestVoucherReducer,
  fuel_recieving_voucher: FuelRecievingVoucherReducer,
  fuel_issue_voucher: FuelIssueVoucherReducer,
  payable_v2: PayableV2Reducer,
  payable_v2_payment: PaymentV2PaymentReducer,
  task: TaskReducer,
  bidding_schedule: BiddingScheduleReducer,
  upload_document: UploadDocumentReducer,
  mikada_daily_report: MikadaDailyReportReducer,
  briefing_form: BriefingFormReducer,
  mikada_weekly_progress: MikadaWeeklyProgressReducer,
  mikada_weekly_equipment_report: MikadaWeeklyEquipmentReportReducer,
  mikada_weekly_material_report: MikadaWeeklyMaterialReportReducer,
  mikada_weekly_sub_contract_report: MikadaWeeklySubContractReportReducer,
  mikada_weekly_labour_report: MikadaWeeklyLabourReportReducer,
  work_evaluation: WorkEvaluationReducer,
  mikada_monthly_report: MikadaMonthlyReportReducer,
  mikada_annual_report: MikadaAnnualReportReducer,
  addition_boq: AdditionBoqReducer,
  bill_summary: BillSummaryReducer,
  omission_boq: OmissionBoqReducer,
  summary: SummaryReducer,
  supplementary_boq: SupplementaryBoqReducer,
  site_visit: SiteVisitReducer,
  sub_contract_registry: SubContractRegistryReducer,
  sub_contract_payment_update: SubContractPaymentUpdateReducer,
  budget_planning: BudgetPlanningReducer,
  sub_contract_agreement: SubContractAgreementReducer,
  budget_planning_item: BudgetPlanningItemReducer,
  boq_item_group: BoqItemGroupReducer,
  manning: ManningReducer,
  hr_request: HRRequestReducer,
  applicant_registry: ApplicantRegistryReducer,
  assessment: AssessmentReducer,
  applicant_registry_item: ApplicantRegistryItemReducer,
  comment: CommentReducer,
  maintenance_request: MaintenanceRequestReducer,
  maintenance_voucher: MaintenanceVoucherReducer,
  accident_follow_up: AccidentFollowUpReducer,
  daily_work_status: DailyWorkStatusReducer,
  insurance: InsuranceReducer,
  store_requisition: StoreRequisitionReducer,
  store_requisition_follow_up: StoreRequisitionFollowUpReducer,
  service_voucher: ServiceVoucherReducer,
  project_allowance: ProjectAllowanceReducer,
  probation_review: ProbationReviewReducer,
  grade: GradeReducer,
  scale: ScaleReducer,
  scale_item: ScaleItemReducer,
  allowable_scale: AllowableScaleReducer,
  employee_allowance: EmployeeAllowanceReducer,
  department_relationship: DepartmentRelationShipReducer,
  manning_category: ManningCategoryReducer,
  payment_file: PaymentFileReducer,
  price_escalation_file: PriceEscalationFileReducer,
  price_escalation: PriceEscalationReducer,
  detail_report_activity: DetailReportActivityReducer,
  equipment_cost: EquipmentCostReducer,
  fuel_cost: FuelCostReducer,
  oil_lubricant_cost: OilLubricantCostReducer,
  project_team: ProjectTeamReducer,

  // ============================================================ \\

  travel_registry: TravelRegistryReducer,
  resignation: ResignationReducer,
  staff_bio: StaffBioReducer,
  loan: LoanReducer,
  loan_extension: LoanExtensionReducer,
  loan_request: LoanRequestReducer,
  employee_performance_form: EmployeePerformanceFormReducer,
  employee_performance: EmployeePerformanceReducer,
  daily_task: DailyTaskReducer,
  self_attending_user: SelfAttendingUserReducer,
  news_and_motivation: NewsAndMotivationReducer,
  automatic_message: AutomaticMessageReducer,
  training: TrainingReducer,
  attendance_location: AttendanceLocationReducer,
  attendance_location_user: AttendanceLocationUserReducer,
  alert: AlertReducer,
  discipline: DisciplineReducer,
  sms_message: SMSMessageReducer,
  early_leave_request: EarlyLeaveRequestReducer,
  draft: DraftReducer,
  performance_point_policy: PerformancePointPolicyReducer,
  promotion: PromotionReducer,
  partner: PartnerReducer,
  overtime_request: OvertimeRequest2Reducer,

  partner_message: PartnerMessageReducer,
  holiday: HolidayReducer,
  feed_back_form: FeedBackFormReducer,
  feed_back_response: FeedBackResponseReducer,
  sub_contract_security: SubContractSecurityReducer,
  daily_productivity_report: DailyProductivityReportReducer,
  spare_part_cost: SparePartCostReducer,
  consultant_facility_cost: ConsultantFacilityCostReducer,
  contractor_facility_cost: ContractorFacilityCostReducer,
  laboratory_equipment_cost: LaboratoryEquipmentCostReducer,
  surveying_equipment_cost: SurveyingEquipmentCostReducer,
  other_expense: OtherExpenseReducer,
  plan_execution: PlanExecutionReducer,
  staff_meal: StaffMealReducer,
  family_allotment: FamilyAllotmentReducer,
  activity_waitage: ActivityWaitageReducer,
  problem_encountered: ProblemEncounteredReducer,
  income_expense: IncomeExpenseReducer,
  progress_summary: ProgressSummaryReducer,
  summary_of_cost: SummaryOfCostReducer,
  monthly_site_report: MonthlySiteReportReducer,
  operation_standard: OperationStandardReducer,
  material_major_category: MaterialMajorCategoryReducer,
  material_sub_category: MaterialSubCategoryReducer,
  material_starting_balance: MaterialStartingBalanceReducer,
  material_limit: MaterialLimitReducer,
  overtime: OvertimeReducer,
  dislocation: DislocationReducer,
  diem_policy: DiemPolicyReducer,
  peridium_rules: PeridiumRulesReducer,
  peridium_request: PeridiumRequestReducer,
  staff_work_experience: StaffWorkExperienceReducer,
  staff_family: StaffFamilyReducer,
  staff_discipline: StaffDisciplineReducer,
  staff_emergency_contact: StaffEmergencyContactReducer,
  staff_tax_information: StaffTaxInformationReducer,
  staff_religion: StaffReligionReducer,
  staff_transfer: StaffTransferReducer,
  site_attendance: SiteAttendanceReducer,
  temporary_attendance: TemporaryAttendanceReducer,
  staff_salary_adjustment: StaffSalaryAdjustmentReducer,
  hr_allowance: HRAllowanceReducer,
  daily_site_report: DailySiteReportReducer,
  weekly_site_report: WeeklySiteReportReducer,
  material_daily_site_report: MaterialDailySiteReportReducer,
  equipment_oid: EquipmentOIDReducer,
  archive: ArchiveReducer,
  contract_administration: ContractAdministrationReducer,
  check_payment_voucher: CheckPaymentVoucherReducer,
  budget: BudgetReducer,
  illegal_recpeit: IllegalReceiptReducer,
  bank_loan: BankLoanReducer,
  telegraphic_transfer: TelegraphicTransferReducer,
  bank_loan_payment_schedule: BankLoanPaymentScheduleReducer,
  cv_bank: CVBankReducer,
  permanent_employee_attendance: PermanentEmployeeAttendanceReducer,
  employee_training: EmployeeTrainingReducer,
  post_date_voucher: PostDateVoucherReducer,
  rgts: RGTSReducer,
  journal_voucher: JournalVoucherReducer,
  transfer_request: TransferRequestReducer,
  canteen_deduction: CanteenDeductionReducer,
  social_commitment: SocialCommitmentReducer,
  staff_performance: StaffPerformanceReducer,
  charts_of_account: ChartsOfAccountReducer,
  user_seen: UserSeenReducer,
  tire_request: TireRequestReducer,
  manufacturing_order: ManufacturingOrderReducer,
  inventory: InventoryReducer,
  store_communication: StoreCommunicationReducer,
  good_in: GoodInReducer,
  good_issue: GoodIssueReducer,
  dispatch: DispatchReducer,
  damage_return: DamageReturnReducer,
  temporary_delivery_note: TemporaryDeliveryNoteReducer,
  material_inspection_repair: MaterialInspectionRepairReducer,
  document_transmital: DocumentTransmitalReducer,
  outsourcing_maintenance: OutsourcingMaintenanceReducer,
  fuel_receive: FuelReceiveReducer,
  fuel_and_lubicon_request: FuelAndLubiconRequestReducer,
  gate_pass: GatePassReducer,
  fixed_asset_received: FixedAssetReceivedReducer,
  fixed_asset_return: FixedAssetReturnReducer,
  fixed_asset_issue: FixedAssetIssueReducer,
  fixed_asset_out: FixedAssetOutReducer,
  fixed_asset_in: FixedAssetInReducer,
  fixed_asset_transfer: FixedAssetTransferReducer,
  fuel_issue: FuelIssueReducer,
  fuel_receiving: FuelReceivingReducer,
  project_fuel_request: ProjectFuelRequestReducer,
  service_request: ServiceRequestReducer,
  finished_product: FinishedProductReducer,
  sub_contract_followup: SubContractFollowUpReducer,
  operation_daily_report: OperationDailyReportReducer,
  own_vehicle: OwnVehicleReducer,
  maintenance_cost: MaintenanceCostReducer,
  daily_status_report: DailyStatusReportReducer,
  fuel_utilization_report: FuelUtilizationReportReducer,
  time_utilization_report: TimeUtilizationReportReducer,
  material_delivery: MaterialDeliveryReducer,
  operation_monthly_report: OperationMonthlyReportReducer,
  project_permanent_attendance: ProjectPermanentAttendanceReducer,
  work_experience_summary: WorkExperienceSummaryReducer,
  user_role: UserRoleReducer,
  payment_requisition: PaymentRequisitionReducer,
  equipment_list: EquipmentListReducer,
  vehicle_list: VehicleListReducer,
  external_repair_order:ExternalRepairOrderReducer,
});

export default persistReducer(PersistConfig, RootReducer);
