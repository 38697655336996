import { Boq } from "../Boq/Boq.type";
import { ApiCallState } from "../Utils";

export type DetailReportActivity= {
  id: number;
  start_date: string;
  end_date: string;
  project_id: number;
  detail_report_Activity_items: DetailReportActivityItem[];
};

export type DetailReportActivityItem = {
  id: number;
  planned_this_period_quantity: number;
  planned_this_period_amount: number;
  previous_executed_quantity: number;
  this_period_executed_quantity: number;
  to_date_executed_quantity: number;
  previous_remaining_quantity: number;
  to_date_remaining_quantity: number;
  previous_executed_amount: number;
  this_period_executed_amount: number;
  to_date_executed_amount: number;
  previous_quantity_payment_progress: number;
  previous_amount_payment_progress: number;
  this_period_quantity_payment_progress: number;
  this_period_amount_payment_progress: number;
  to_date_quantity_payment_progress: number;
  to_date_amount_payment_progress: number;
  previous_vs_contract_execution_accomplishment: number;
  this_period_vs_plan_execution_accomplishment: number;
  this_period_vs_contract_execution_accomplishment: number;
  to_date_vs_contract_execution_accomplishment: number;
  previous_vs_contract_payment_accomplishment: number;
  this_period_vs_contract_payment_accomplishment: number;
  to_date_vs_contract_payment_accomplishment: number;
  boq: Boq
}

export type DetailReportActivityStateTypes = {
  fetchAll: ApiCallState<DetailReportActivity[]>;
  fetchOne: ApiCallState<DetailReportActivity | {}>;
};

export const DetailReportActivityActionTypes = {
  FETCH_ALL_DETAIL_REPORT_ACTIVITY: "FETCH_ALL_DETAIL_REPORT_ACTIVITY",
  FETCH_ALL_DETAIL_REPORT_ACTIVITY_RESET: "FETCH_ALL_DETAIL_REPORT_ACTIVITY_RESET",
  FETCH_ALL_DETAIL_REPORT_ACTIVITY_FAILURE: "FETCH_ALL_DETAIL_REPORT_ACTIVITY_FAILURE",
  FETCH_ALL_DETAIL_REPORT_ACTIVITY_SUCCESS: "FETCH_ALL_DETAIL_REPORT_ACTIVITY_SUCCESS",

  FETCH_ONE_DETAIL_REPORT_ACTIVITY: "FETCH_ONE_DETAIL_REPORT_ACTIVITY",
  FETCH_ONE_DETAIL_REPORT_ACTIVITY_RESET: "FETCH_ONE_DETAIL_REPORT_ACTIVITY_RESET",
  FETCH_ONE_DETAIL_REPORT_ACTIVITY_FAILURE: "FETCH_ONE_DETAIL_REPORT_ACTIVITY_FAILURE",
  FETCH_ONE_DETAIL_REPORT_ACTIVITY_SUCCESS: "FETCH_ONE_DETAIL_REPORT_ACTIVITY_SUCCESS",
};
