import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { BriefingActionTypes } from "./Briefing.type";

export function* fetchAllBriefings(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/briefing?project_id=${action.payload.project_id}&date=${action.payload.date}`);
    yield put({
      type: BriefingActionTypes.FETCH_ALL_BRIEFING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BriefingActionTypes.FETCH_ALL_BRIEFING_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneBriefings(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/briefing/${action.payload}`
    );
    yield put({
      type: BriefingActionTypes.FETCH_ONE_BRIEFING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: BriefingActionTypes.FETCH_ONE_BRIEFING_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllBriefings() {
  yield takeLatest(BriefingActionTypes.FETCH_ALL_BRIEFING, fetchAllBriefings);
}

export function* watcherFetchOneBriefings() {
  yield takeLatest(BriefingActionTypes.FETCH_ONE_BRIEFING, fetchOneBriefings);
}
