import { DepartmentRelationShipStateTypes, DepartmentRelationShipActionTypes } from "./DepartmentRelationShip.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: DepartmentRelationShipStateTypes = {
  fetchAll: resetApiCallState({count: 0, rows: []}),
  fetchOne: resetApiCallState({}),
};

const DepartmentRelationShipReducer = (
  state: DepartmentRelationShipStateTypes = INITIAL_STATE,
  action: any
): DepartmentRelationShipStateTypes => {
  switch (action.type) {
    case DepartmentRelationShipActionTypes.FETCH_ALL_DEPARTMENT_RELATION_SHIP:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: {count: 0, rows: []},
          isPending: true,
          isSuccessful: false,
        },
      };
    case DepartmentRelationShipActionTypes.FETCH_ALL_DEPARTMENT_RELATION_SHIP_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState({count: 0, rows: []}),
      };
    case DepartmentRelationShipActionTypes.FETCH_ALL_DEPARTMENT_RELATION_SHIP_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload:{count: 0, rows: []},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case DepartmentRelationShipActionTypes.FETCH_ALL_DEPARTMENT_RELATION_SHIP_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case DepartmentRelationShipActionTypes.FETCH_ONE_DEPARTMENT_RELATION_SHIP:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case DepartmentRelationShipActionTypes.FETCH_ONE_DEPARTMENT_RELATION_SHIP_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState({count: 0, rows: []}),
      };
    case DepartmentRelationShipActionTypes.FETCH_ONE_DEPARTMENT_RELATION_SHIP_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case DepartmentRelationShipActionTypes.FETCH_ONE_DEPARTMENT_RELATION_SHIP_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default DepartmentRelationShipReducer;
