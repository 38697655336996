import { ApiCallState } from "../Utils";

export type Training = {
  id: number;
  date: string;
  duration: string[];
  trainer: string;
  name: string;
  departments: string[];
};

export type TrainingStateTypes = {
  fetchAll: ApiCallState<Training[]>;
  fetchOne: ApiCallState<Training | {}>;
};

export const TrainingActionTypes = {
  FETCH_ALL_TRAINING: "FETCH_ALL_TRAINING",
  FETCH_ALL_TRAINING_RESET: "FETCH_ALL_TRAINING_RESET",
  FETCH_ALL_TRAINING_FAILURE: "FETCH_ALL_TRAINING_FAILURE",
  FETCH_ALL_TRAINING_SUCCESS: "FETCH_ALL_TRAINING_SUCCESS",

  FETCH_ONE_TRAINING: "FETCH_ONE_TRAINING",
  FETCH_ONE_TRAINING_RESET: "FETCH_ONE_TRAINING_RESET",
  FETCH_ONE_TRAINING_FAILURE: "FETCH_ONE_TRAINING_FAILURE",
  FETCH_ONE_TRAINING_SUCCESS: "FETCH_ONE_TRAINING_SUCCESS",
};
