import { ApiCallState } from "../Utils";

export type MikadaWeeklySubContractReport= {
  id: number;
  date: string;
  remark: string;
  sub_contract_id: number;
  user_id: number;
};

export type MikadaWeeklySubContractReportStateTypes = {
  fetchAll: ApiCallState<MikadaWeeklySubContractReport[]>;
  fetchOne: ApiCallState<MikadaWeeklySubContractReport | {}>;
};

export const MikadaWeeklySubContractReportActionTypes = {
  FETCH_ALL_MIKADA_WEEKLY_SUB_CONTRACT_REPORT: "FETCH_ALL_MIKADA_WEEKLY_SUB_CONTRACT_REPORT",
  FETCH_ALL_MIKADA_WEEKLY_SUB_CONTRACT_REPORT_RESET: "FETCH_ALL_MIKADA_WEEKLY_SUB_CONTRACT_REPORT_RESET",
  FETCH_ALL_MIKADA_WEEKLY_SUB_CONTRACT_REPORT_FAILURE: "FETCH_ALL_MIKADA_WEEKLY_SUB_CONTRACT_REPORT_FAILURE",
  FETCH_ALL_MIKADA_WEEKLY_SUB_CONTRACT_REPORT_SUCCESS: "FETCH_ALL_MIKADA_WEEKLY_SUB_CONTRACT_REPORT_SUCCESS",

  FETCH_ONE_MIKADA_WEEKLY_SUB_CONTRACT_REPORT: "FETCH_ONE_MIKADA_WEEKLY_SUB_CONTRACT_REPORT",
  FETCH_ONE_MIKADA_WEEKLY_SUB_CONTRACT_REPORT_RESET: "FETCH_ONE_MIKADA_WEEKLY_SUB_CONTRACT_REPORT_RESET",
  FETCH_ONE_MIKADA_WEEKLY_SUB_CONTRACT_REPORT_FAILURE: "FETCH_ONE_MIKADA_WEEKLY_SUB_CONTRACT_REPORT_FAILURE",
  FETCH_ONE_MIKADA_WEEKLY_SUB_CONTRACT_REPORT_SUCCESS: "FETCH_ONE_MIKADA_WEEKLY_SUB_CONTRACT_REPORT_SUCCESS",
};
