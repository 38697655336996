import { RentContractItem } from "../RentContract/RentContract.type";
import { ApiCallState } from "../Utils";

export type VehicleList = {
  id: number;
  user_id: number;
  location: string;
  equipment_type: string;
  plate_number: string;
  make: string;
  model: string;
  engine_number: string;
  serial_chassis_number: string;
  hr_power: string;
  capacity: string;
  manufacture_year: string;
  purchase_year: string;
  purchase_price: number;
  purchase_currency: string;
  depreciation_percent: number;
  status: string;
  driver_name: string;
  tel_phone_number: string;
  remark:string;
  received_by:string;
  km_reading:number;
  tire_condition:string;
  motor:string;
  battery:string;
  mirror:string;
  side_back_mirror:string;
  lumps:string;
  body:string;
  image:string;
  safety_items:string;
  contract_items: RentContractItem[];
  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type VehicleListStateTypes = {
  fetchAll: ApiCallState<VehicleList[]>;
  fetchOne: ApiCallState<VehicleList | {}>;
};

export const VehicleListActionTypes = {
  FETCH_ALL_VEHICLE_LIST: "FETCH_ALL_VEHICLE_LIST",
  FETCH_ALL_VEHICLE_LIST_RESET: "FETCH_ALL_VEHICLE_LIST_RESET",
  FETCH_ALL_VEHICLE_LIST_FAILURE: "FETCH_ALL_VEHICLE_LIST_FAILURE",
  FETCH_ALL_VEHICLE_LIST_SUCCESS: "FETCH_ALL_VEHICLE_LIST_SUCCESS",

  FETCH_ONE_VEHICLE_LIST: "FETCH_ONE_VEHICLE_LIST",
  FETCH_ONE_VEHICLE_LIST_RESET: "FETCH_ONE_VEHICLE_LIST_RESET",
  FETCH_ONE_VEHICLE_LIST_FAILURE: "FETCH_ONE_VEHICLE_LIST_FAILURE",
  FETCH_ONE_VEHICLE_LIST_SUCCESS: "FETCH_ONE_VEHICLE_LIST_SUCCESS",
};
