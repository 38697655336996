import { TakeoffStateTypes, TakeoffActionTypes } from "./Takeoff.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: TakeoffStateTypes = {
  fetchAll: resetApiCallState([]),
};

const TakeoffReducer = (
  state: TakeoffStateTypes = INITIAL_STATE,
  action: any
): TakeoffStateTypes => {
  switch (action.type) {
    case TakeoffActionTypes.FETCH_ALL_TAKEOFF:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case TakeoffActionTypes.FETCH_ALL_TAKEOFF_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case TakeoffActionTypes.FETCH_ALL_TAKEOFF_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case TakeoffActionTypes.FETCH_ALL_TAKEOFF_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default TakeoffReducer;
