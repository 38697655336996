import { InitPagedData } from "../Utils";
import {
  DamageReturnStateTypes,
  DamageReturnActionTypes,
} from "./DamageReturn.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: DamageReturnStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchReport: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchPaged: resetApiCallState(InitPagedData),
};

const DamageReturnReducer = (
  state: DamageReturnStateTypes = INITIAL_STATE,
  action: any
): DamageReturnStateTypes => {
  switch (action.type) {
    case DamageReturnActionTypes.FETCH_ALL_DAMAGE_RETURN:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case DamageReturnActionTypes.FETCH_ALL_DAMAGE_RETURN_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case DamageReturnActionTypes.FETCH_ALL_DAMAGE_RETURN_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case DamageReturnActionTypes.FETCH_ALL_DAMAGE_RETURN_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };
    ///////////

    case DamageReturnActionTypes.FETCH_REPORT_DAMAGE_RETURN:
      return {
        ...state,
        fetchReport: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case DamageReturnActionTypes.FETCH_REPORT_DAMAGE_RETURN_RESET:
      return {
        ...state,
        fetchReport: resetApiCallState([]),
      };
    case DamageReturnActionTypes.FETCH_REPORT_DAMAGE_RETURN_FAILURE:
      return {
        ...state,
        fetchReport: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case DamageReturnActionTypes.FETCH_REPORT_DAMAGE_RETURN_SUCCESS:
      return {
        ...state,
        fetchReport: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    //////////
    case DamageReturnActionTypes.FETCH_PAGED_DAMAGE_RETURN:
      return {
        ...state,
        fetchPaged: {
          error: null,
          payload: InitPagedData,
          isPending: true,
          isSuccessful: false,
        },
      };
    case DamageReturnActionTypes.FETCH_PAGED_DAMAGE_RETURN_RESET:
      return {
        ...state,
        fetchPaged: resetApiCallState(InitPagedData),
      };
    case DamageReturnActionTypes.FETCH_PAGED_DAMAGE_RETURN_FAILURE:
      return {
        ...state,
        fetchPaged: {
          payload: InitPagedData,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case DamageReturnActionTypes.FETCH_PAGED_DAMAGE_RETURN_SUCCESS:
      return {
        ...state,
        fetchPaged: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case DamageReturnActionTypes.FETCH_ONE_DAMAGE_RETURN:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case DamageReturnActionTypes.FETCH_ONE_DAMAGE_RETURN_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case DamageReturnActionTypes.FETCH_ONE_DAMAGE_RETURN_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case DamageReturnActionTypes.FETCH_ONE_DAMAGE_RETURN_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default DamageReturnReducer;
