import { ApiCallState } from "../Utils";

export type PaymentFollowUp= {
  id: number | null;
  project_id:number;
  project_name:string;
  type_of_payment:string;
  payment_no:string;
  net_amount_with_vat:number;
  payment_status:string;
  total_certified_payment_with_vat:number;
  project_contract_amount_with_vat:number;
  remaing_amount_with_vat:number;
  advance_repaid:number;
  outstanding_advance:number;
  retention:number;
  provisionally_accepted_on:string;
  remark:string;
};

export type PaymentFollowUpStateTypes = {
  fetchAll: ApiCallState<PaymentFollowUp[]>;
  fetchOne: ApiCallState<PaymentFollowUp | {}>;
};

export const PaymentFollowUpActionTypes = {
  FETCH_ALL_PAYMENT_FOLLOW_UP: "FETCH_ALL_PAYMENT_FOLLOW_UP",
  FETCH_ALL_PAYMENT_FOLLOW_UP_RESET: "FETCH_ALL_PAYMENT_FOLLOW_UP_RESET",
  FETCH_ALL_PAYMENT_FOLLOW_UP_FAILURE: "FETCH_ALL_PAYMENT_FOLLOW_UP_FAILURE",
  FETCH_ALL_PAYMENT_FOLLOW_UP_SUCCESS: "FETCH_ALL_PAYMENT_FOLLOW_UP_SUCCESS",

  FETCH_ONE_PAYMENT_FOLLOW_UP: "FETCH_ONE_PAYMENT_FOLLOW_UP",
  FETCH_ONE_PAYMENT_FOLLOW_UP_RESET: "FETCH_ONE_PAYMENT_FOLLOW_UP_RESET",
  FETCH_ONE_PAYMENT_FOLLOW_UP_FAILURE: "FETCH_ONE_PAYMENT_FOLLOW_UP_FAILURE",
  FETCH_ONE_PAYMENT_FOLLOW_UP_SUCCESS: "FETCH_ONE_PAYMENT_FOLLOW_UP_SUCCESS",
};
