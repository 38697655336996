import { InitPagedData } from "./../Utils";
import {
  MaintenanceCostStateTypes,
  MaintenanceCostActionTypes,
} from "./MaintenanceCost.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: MaintenanceCostStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchPaged: resetApiCallState(InitPagedData),
  fetchList: resetApiCallState([]),
};

const MaintenanceCostReducer = (
  state: MaintenanceCostStateTypes = INITIAL_STATE,
  action: any
): MaintenanceCostStateTypes => {
  switch (action.type) {
    case MaintenanceCostActionTypes.FETCH_ALL_MAINTENANCE_COST:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaintenanceCostActionTypes.FETCH_ALL_MAINTENANCE_COST_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MaintenanceCostActionTypes.FETCH_ALL_MAINTENANCE_COST_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaintenanceCostActionTypes.FETCH_ALL_MAINTENANCE_COST_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MaintenanceCostActionTypes.FETCH_LIST_MAINTENANCE_COST:
      return {
        ...state,
        fetchList: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaintenanceCostActionTypes.FETCH_LIST_MAINTENANCE_COST_RESET:
      return {
        ...state,
        fetchList: resetApiCallState([]),
      };
    case MaintenanceCostActionTypes.FETCH_LIST_MAINTENANCE_COST_FAILURE:
      return {
        ...state,
        fetchList: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaintenanceCostActionTypes.FETCH_LIST_MAINTENANCE_COST_SUCCESS:
      return {
        ...state,
        fetchList: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MaintenanceCostActionTypes.FETCH_PAGED_MAINTENANCE_COST:
      return {
        ...state,
        fetchPaged: {
          error: null,
          payload: InitPagedData,
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaintenanceCostActionTypes.FETCH_PAGED_MAINTENANCE_COST_RESET:
      return {
        ...state,
        fetchPaged: resetApiCallState(InitPagedData),
      };
    case MaintenanceCostActionTypes.FETCH_PAGED_MAINTENANCE_COST_FAILURE:
      return {
        ...state,
        fetchPaged: {
          payload: InitPagedData,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaintenanceCostActionTypes.FETCH_PAGED_MAINTENANCE_COST_SUCCESS:
      return {
        ...state,
        fetchPaged: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MaintenanceCostActionTypes.FETCH_ONE_MAINTENANCE_COST:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaintenanceCostActionTypes.FETCH_ONE_MAINTENANCE_COST_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MaintenanceCostActionTypes.FETCH_ONE_MAINTENANCE_COST_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaintenanceCostActionTypes.FETCH_ONE_MAINTENANCE_COST_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default MaintenanceCostReducer;
