import { ApiCallState, PagedData } from "../Utils";

export type DiemPolicy = {
  id: number;
  key?: number;
  date: string;
  diem_policy_items: DiemPolicyItem[];
};

export type DiemPolicyItem = {
  key?: number;
  id?: number;
  diem_policy_id?: number;
  salary_range_from: string;
  job_title: string[];
  per_diem_amount: number;
  for_bed: number;
  remark: string;
};

export type DiemPolicyStateTypes = {
  fetchAll: ApiCallState<DiemPolicy[]>;
  fetchOne: ApiCallState<DiemPolicy | {}>;
  fetchPaged: ApiCallState<PagedData<DiemPolicy[]>>;
};

export const DiemPolicyActionTypes = {
  FETCH_ALL_DIEM_POLICY: "FETCH_ALL_DIEM_POLICY",
  FETCH_ALL_DIEM_POLICY_RESET: "FETCH_ALL_DIEM_POLICY_RESET",
  FETCH_ALL_DIEM_POLICY_FAILURE: "FETCH_ALL_DIEM_POLICY_FAILURE",
  FETCH_ALL_DIEM_POLICY_SUCCESS: "FETCH_ALL_DIEM_POLICY_SUCCESS",

  FETCH_PAGED_DIEM_POLICY: "FETCH_PAGED_DIEM_POLICY",
  FETCH_PAGED_DIEM_POLICY_RESET: "FETCH_PAGED_DIEM_POLICY_RESET",
  FETCH_PAGED_DIEM_POLICY_FAILURE: "FETCH_PAGED_DIEM_POLICY_FAILURE",
  FETCH_PAGED_DIEM_POLICY_SUCCESS: "FETCH_PAGED_DIEM_POLICY_SUCCESS",

  FETCH_ONE_DIEM_POLICY: "FETCH_ONE_DIEM_POLICY",
  FETCH_ONE_DIEM_POLICY_RESET: "FETCH_ONE_DIEM_POLICY_RESET",
  FETCH_ONE_DIEM_POLICY_FAILURE: "FETCH_ONE_DIEM_POLICY_FAILURE",
  FETCH_ONE_DIEM_POLICY_SUCCESS: "FETCH_ONE_DIEM_POLICY_SUCCESS",
};
