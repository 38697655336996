import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { DailyProductivityReportActionTypes } from "./DailyProductivityReport.type";

export function* fetchAllDailyProductivityReports(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/daily-productivity-report?project_id=${action.payload?.project_id}&start_date=${action.payload?.start_date}&end_date=${action.payload?.end_date}`);
    yield put({
      type: DailyProductivityReportActionTypes.FETCH_ALL_DAILY_PRODUCTIVITY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DailyProductivityReportActionTypes.FETCH_ALL_DAILY_PRODUCTIVITY_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneDailyProductivityReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/daily-productivity-report/date?project_id=${action.payload?.project_id}&date=${action.payload?.date}`
    );
    yield put({
      type: DailyProductivityReportActionTypes.FETCH_ONE_DAILY_PRODUCTIVITY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: DailyProductivityReportActionTypes.FETCH_ONE_DAILY_PRODUCTIVITY_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllDailyProductivityReports() {
  yield takeLatest(DailyProductivityReportActionTypes.FETCH_ALL_DAILY_PRODUCTIVITY_REPORT, fetchAllDailyProductivityReports);
}

export function* watcherFetchOneDailyProductivityReports() {
  yield takeLatest(DailyProductivityReportActionTypes.FETCH_ONE_DAILY_PRODUCTIVITY_REPORT, fetchOneDailyProductivityReports);
}
