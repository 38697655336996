import { Material } from "../Material/Material.type";
import { Project } from "../Project/Project.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type MaterialInspectionRepair = {
  id: number;

  user_id: number;
  project_id: number;
  sent_to_project_id: number;

  material_id: number;

  requested_by_id: number;
  inspected_by_id: number;
  supervised_by_id: number;
  delivered_by_id: number;
  withdrew_by_id: number;
  received_by_id: number;
  store_keeper_id: number;

  date: Date;
  inspection_number: number;

  version: string;
  other_identification_number: string;
  model: string;
  reason_for_repair: string;
  shipped_equipment_condition: string;
  missing_part: string;
  damaged_part: string;
  received_date_for_delivery: Date;
  delivery_date_of_arrival: Date;
  other_issues: string;
  received_date_for_repair: Date;
  returned_date: Date;
  sent_date: Date;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  material: Material;
  project: Project;
  sent_to_project: Project;

  requested_by: User;
  inspected_by: User;
  supervised_by: User;
  delivered_by: User;
  withdrew_by: User;
  received_by: User;
  store_keeper: User;
};

export type MaterialInspectionRepairStateTypes = {
  fetchAll: ApiCallState<MaterialInspectionRepair[]>;
  fetchOne: ApiCallState<MaterialInspectionRepair | {}>;
  fetchNumber: ApiCallState<number | null>;
};

export const MaterialInspectionRepairActionTypes = {
  FETCH_ALL_MATERIAL_INSPECTION_REPAIR: "FETCH_ALL_MATERIAL_INSPECTION_REPAIR",
  FETCH_ALL_MATERIAL_INSPECTION_REPAIR_RESET:
    "FETCH_ALL_MATERIAL_INSPECTION_REPAIR_RESET",
  FETCH_ALL_MATERIAL_INSPECTION_REPAIR_FAILURE:
    "FETCH_ALL_MATERIAL_INSPECTION_REPAIR_FAILURE",
  FETCH_ALL_MATERIAL_INSPECTION_REPAIR_SUCCESS:
    "FETCH_ALL_MATERIAL_INSPECTION_REPAIR_SUCCESS",

  FETCH_ONE_MATERIAL_INSPECTION_REPAIR: "FETCH_ONE_MATERIAL_INSPECTION_REPAIR",
  FETCH_ONE_MATERIAL_INSPECTION_REPAIR_RESET:
    "FETCH_ONE_MATERIAL_INSPECTION_REPAIR_RESET",
  FETCH_ONE_MATERIAL_INSPECTION_REPAIR_FAILURE:
    "FETCH_ONE_MATERIAL_INSPECTION_REPAIR_FAILURE",
  FETCH_ONE_MATERIAL_INSPECTION_REPAIR_SUCCESS:
    "FETCH_ONE_MATERIAL_INSPECTION_REPAIR_SUCCESS",

  FETCH_MATERIAL_INSPECTION_REPAIR_NUMBER:
    "FETCH_MATERIAL_INSPECTION_REPAIR_NUMBER",
  FETCH_MATERIAL_INSPECTION_REPAIR_NUMBER_RESET:
    "FETCH_MATERIAL_INSPECTION_REPAIR_NUMBER_RESET",
  FETCH_MATERIAL_INSPECTION_REPAIR_NUMBER_FAILURE:
    "FETCH_MATERIAL_INSPECTION_REPAIR_NUMBER_FAILURE",
  FETCH_MATERIAL_INSPECTION_REPAIR_NUMBER_SUCCESS:
    "FETCH_MATERIAL_INSPECTION_REPAIR_NUMBER_SUCCESS",
};
