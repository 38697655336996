import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { SubContractorPerformanceActionTypes } from "./SubContractorPerformance.type";

export function* fetchAllSubContractorPerformances(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/sub-contractor-performance?project_id=${action.payload?.project_id}`
    );
    yield put({
      type: SubContractorPerformanceActionTypes.FETCH_ALL_SUB_CONTRACTOR_PERFORMANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubContractorPerformanceActionTypes.FETCH_ALL_SUB_CONTRACTOR_PERFORMANCE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneSubContractorPerformances(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/sub-contractor-performance/${action.payload}`
    );
    yield put({
      type: SubContractorPerformanceActionTypes.FETCH_ONE_SUB_CONTRACTOR_PERFORMANCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: SubContractorPerformanceActionTypes.FETCH_ONE_SUB_CONTRACTOR_PERFORMANCE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllSubContractorPerformances() {
  yield takeLatest(
    SubContractorPerformanceActionTypes.FETCH_ALL_SUB_CONTRACTOR_PERFORMANCE,
    fetchAllSubContractorPerformances
  );
}

export function* watcherFetchOneSubContractorPerformances() {
  yield takeLatest(
    SubContractorPerformanceActionTypes.FETCH_ONE_SUB_CONTRACTOR_PERFORMANCE,
    fetchOneSubContractorPerformances
  );
}
