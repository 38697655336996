import { EquipmentInventory } from "../EquipmentInventory/EquipmentInventory.type";
import { Document } from "../Document/Document.type";
import { ApiCallState } from "../Utils";
import { Customer } from "../Customer/Customer.type";

export type RentContract = {
  id: number;
  customer_id: number;
  document_id: number;

  type: string;

  customer: string;
  project: string;
  advance_payment_amount: number;
  retention: number;
  date: Date;

  contract_items: RentContractItem[];
  document: Document;
  customer_obj: Customer;
};

export type RentContractItem = {
  id: number;
  contract_id: number;
  master_list_id?: number;
  equipment_inventory_id: number; // license plate number
  plate_number: string;
  price_per_unit_value: any;
  operator: string; // operator staff
  vehicle_type: string;
  price_per_hour: number;

  with_hold_deductible: boolean;
  fuel_deductible: boolean;
  oil_and_lubricant_deductible: boolean;
  maintenance_deductible: boolean;
  advance_deductible: boolean;
  other_deductible: boolean;

  equipment_inventory: EquipmentInventory;
  contract: RentContract;
};

export type RentContractStateTypes = {
  fetchAll: ApiCallState<RentContract[]>;
};

export const RentContractActionTypes = {
  FETCH_ALL_RENT_CONTRACT: "FETCH_ALL_RENT_CONTRACT",
  FETCH_ALL_RENT_CONTRACT_RESET: "FETCH_ALL_RENT_CONTRACT_RESET",
  FETCH_ALL_RENT_CONTRACT_FAILURE: "FETCH_ALL_RENT_CONTRACT_FAILURE",
  FETCH_ALL_RENT_CONTRACT_SUCCESS: "FETCH_ALL_RENT_CONTRACT_SUCCESS",
};
