import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { EquipmentOIDActionTypes } from "./EquipmentOID.type";

export function* fetchAllEquipmentOIDs(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/equipment-oid`);
    yield put({
      type: EquipmentOIDActionTypes.FETCH_ALL_EQUIPMENT_OID_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EquipmentOIDActionTypes.FETCH_ALL_EQUIPMENT_OID_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneEquipmentOIDs(action: any): any {
  console.log({action});
  console.log({url : `${API_BASE_URI}/equipment-oid/date?project_id=${action.payload?.project_id}&date=${action.payload?.date}`})
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/equipment-oid/date?project_id=${action.payload?.project_id}&date=${action.payload?.date}`
    );
    yield put({
      type: EquipmentOIDActionTypes.FETCH_ONE_EQUIPMENT_OID_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: EquipmentOIDActionTypes.FETCH_ONE_EQUIPMENT_OID_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllEquipmentOIDs() {
  yield takeLatest(EquipmentOIDActionTypes.FETCH_ALL_EQUIPMENT_OID, fetchAllEquipmentOIDs);
}

export function* watcherFetchOneEquipmentOIDs() {
  yield takeLatest(EquipmentOIDActionTypes.FETCH_ONE_EQUIPMENT_OID, fetchOneEquipmentOIDs);
}
