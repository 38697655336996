import { AlertStateTypes, AlertActionTypes } from "./Alert.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: AlertStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
};

const AlertReducer = (
  state: AlertStateTypes = INITIAL_STATE,
  action: any
): AlertStateTypes => {
  switch (action.type) {
    case AlertActionTypes.FETCH_ALL_ALERT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case AlertActionTypes.FETCH_ALL_ALERT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case AlertActionTypes.FETCH_ALL_ALERT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case AlertActionTypes.FETCH_ALL_ALERT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case AlertActionTypes.FETCH_ONE_ALERT:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case AlertActionTypes.FETCH_ONE_ALERT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState({}),
      };
    case AlertActionTypes.FETCH_ONE_ALERT_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case AlertActionTypes.FETCH_ONE_ALERT_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default AlertReducer;
