import { ApiCallState } from "../Utils";

export type Partner = {
  id: number;

  user_id: number;
  name: string;
  company: string;
  type: string;
  phone_number: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type PartnerStateTypes = {
  fetchAll: ApiCallState<Partner[]>;
  fetchOne: ApiCallState<Partner | {}>;
};

export const PartnerActionTypes = {
  FETCH_ALL_PARTNER: "FETCH_ALL_PARTNER",
  FETCH_ALL_PARTNER_RESET: "FETCH_ALL_PARTNER_RESET",
  FETCH_ALL_PARTNER_FAILURE: "FETCH_ALL_PARTNER_FAILURE",
  FETCH_ALL_PARTNER_SUCCESS: "FETCH_ALL_PARTNER_SUCCESS",

  FETCH_ONE_PARTNER: "FETCH_ONE_PARTNER",
  FETCH_ONE_PARTNER_RESET: "FETCH_ONE_PARTNER_RESET",
  FETCH_ONE_PARTNER_FAILURE: "FETCH_ONE_PARTNER_FAILURE",
  FETCH_ONE_PARTNER_SUCCESS: "FETCH_ONE_PARTNER_SUCCESS",
};
