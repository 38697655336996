import { ApiCallState } from "../Utils";

export type ProgressSummary= {
  id: number;
};

export type ProgressSummaryStateTypes = {
  fetchAll: ApiCallState<ProgressSummary[]>;
  fetchOne: ApiCallState<ProgressSummary | {}>;
};

export const ProgressSummaryActionTypes = {
  FETCH_ALL_PROGRESS_SUMMARY: "FETCH_ALL_PROGRESS_SUMMARY",
  FETCH_ALL_PROGRESS_SUMMARY_RESET: "FETCH_ALL_PROGRESS_SUMMARY_RESET",
  FETCH_ALL_PROGRESS_SUMMARY_FAILURE: "FETCH_ALL_PROGRESS_SUMMARY_FAILURE",
  FETCH_ALL_PROGRESS_SUMMARY_SUCCESS: "FETCH_ALL_PROGRESS_SUMMARY_SUCCESS",

  FETCH_ONE_PROGRESS_SUMMARY: "FETCH_ONE_PROGRESS_SUMMARY",
  FETCH_ONE_PROGRESS_SUMMARY_RESET: "FETCH_ONE_PROGRESS_SUMMARY_RESET",
  FETCH_ONE_PROGRESS_SUMMARY_FAILURE: "FETCH_ONE_PROGRESS_SUMMARY_FAILURE",
  FETCH_ONE_PROGRESS_SUMMARY_SUCCESS: "FETCH_ONE_PROGRESS_SUMMARY_SUCCESS",
};
