import { ApiCallState } from "../Utils";

export type Comment = {
  id: number;
  user_id: number;

  module: string;
  name: string;
  comment: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type CommentStateTypes = {
  fetchAll: ApiCallState<Comment[]>;
  fetchOne: ApiCallState<Comment | {}>;
};

export const CommentActionTypes = {
  FETCH_ALL_COMMENT: "FETCH_ALL_COMMENT",
  FETCH_ALL_COMMENT_RESET: "FETCH_ALL_COMMENT_RESET",
  FETCH_ALL_COMMENT_FAILURE: "FETCH_ALL_COMMENT_FAILURE",
  FETCH_ALL_COMMENT_SUCCESS: "FETCH_ALL_COMMENT_SUCCESS",

  FETCH_ONE_COMMENT: "FETCH_ONE_COMMENT",
  FETCH_ONE_COMMENT_RESET: "FETCH_ONE_COMMENT_RESET",
  FETCH_ONE_COMMENT_FAILURE: "FETCH_ONE_COMMENT_FAILURE",
  FETCH_ONE_COMMENT_SUCCESS: "FETCH_ONE_COMMENT_SUCCESS",
};
