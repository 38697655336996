import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";
import { API_BASE_URI } from "../ApiCall";
import { MaintenanceRequestActionTypes } from "./MaintenanceRequest.type";

export function* fetchAllMaintenanceRequests(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/maintenance-request`);
    yield put({
      type: MaintenanceRequestActionTypes.FETCH_ALL_MAINTENANCE_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaintenanceRequestActionTypes.FETCH_ALL_MAINTENANCE_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMaintenanceRequests(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/maintenance-request/${action.payload}`
    );
    yield put({
      type: MaintenanceRequestActionTypes.FETCH_ONE_MAINTENANCE_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaintenanceRequestActionTypes.FETCH_ONE_MAINTENANCE_REQUEST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMaintenanceRequests() {
  yield takeLatest(
    MaintenanceRequestActionTypes.FETCH_ALL_MAINTENANCE_REQUEST,
    fetchAllMaintenanceRequests
  );
}

export function* watcherFetchOneMaintenanceRequests() {
  yield takeLatest(
    MaintenanceRequestActionTypes.FETCH_ONE_MAINTENANCE_REQUEST,
    fetchOneMaintenanceRequests
  );
}
