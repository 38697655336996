import { Department } from "../Department/Department.type";
import { Document } from "../Document/Document.type";
import { VehicleList } from "../VehicleList/VehicleList.type";
import { EquipmentList } from "../EquipmentList/EquipmentList.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";
import { Staff } from "../Staff/Staff.type";

export type MaintenanceRequest = {
  id: number;
  user_id: number;

  requested_by_id: number;
  prepared_by_id: number;
  checked_by_id: number;
  approved_by_id: number;
  document: Document;

  department_id: number;
  vehicle_master_list_id: number;
  equipment_master_list_id: number;

  date: string;
  comment: string;
  priority: string;
  service_hour_reading: string;
  trailer_plate_no: string;
  items: MaintenanceItem[];

  is_checked: boolean | null;
  is_approved: boolean | null;

  prepared_by: User;
  requested_by: Staff;
  checked_by: User;
  approved_by: User;

  department: Department;
  vehicle_master_list: VehicleList;
  equipment_list: EquipmentList;
};

export type MaintenanceItem = {
  key: number;
  item: string,
  description: string
}

export type MaintenanceRequestStateTypes = {
  fetchAll: ApiCallState<MaintenanceRequest[]>;
  fetchOne: ApiCallState<MaintenanceRequest | {}>;
};

export const MaintenanceRequestActionTypes = {
  FETCH_ALL_MAINTENANCE_REQUEST: "FETCH_ALL_MAINTENANCE_REQUEST",
  FETCH_ALL_MAINTENANCE_REQUEST_RESET: "FETCH_ALL_MAINTENANCE_REQUEST_RESET",
  FETCH_ALL_MAINTENANCE_REQUEST_FAILURE:
    "FETCH_ALL_MAINTENANCE_REQUEST_FAILURE",
  FETCH_ALL_MAINTENANCE_REQUEST_SUCCESS:
    "FETCH_ALL_MAINTENANCE_REQUEST_SUCCESS",

  FETCH_ONE_MAINTENANCE_REQUEST: "FETCH_ONE_MAINTENANCE_REQUEST",
  FETCH_ONE_MAINTENANCE_REQUEST_RESET: "FETCH_ONE_MAINTENANCE_REQUEST_RESET",
  FETCH_ONE_MAINTENANCE_REQUEST_FAILURE:
    "FETCH_ONE_MAINTENANCE_REQUEST_FAILURE",
  FETCH_ONE_MAINTENANCE_REQUEST_SUCCESS:
    "FETCH_ONE_MAINTENANCE_REQUEST_SUCCESS",
};
