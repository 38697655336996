import {
  EmployeeRequestStateTypes,
  EmployeeRequestActionTypes,
} from "./EmployeeRequest.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: EmployeeRequestStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchLeaveState: resetApiCallState(null),
  fetchHealthBenefitState: resetApiCallState(null),
};

const EmployeeRequestReducer = (
  state: EmployeeRequestStateTypes = INITIAL_STATE,
  action: any
): EmployeeRequestStateTypes => {
  switch (action.type) {
    case EmployeeRequestActionTypes.FETCH_ALL_EMPLOYEE_REQUEST:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case EmployeeRequestActionTypes.FETCH_ALL_EMPLOYEE_REQUEST_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case EmployeeRequestActionTypes.FETCH_ALL_EMPLOYEE_REQUEST_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case EmployeeRequestActionTypes.FETCH_ALL_EMPLOYEE_REQUEST_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    // ----------------

    case EmployeeRequestActionTypes.FETCH_ONE_EMPLOYEE_REQUEST:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case EmployeeRequestActionTypes.FETCH_ONE_EMPLOYEE_REQUEST_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case EmployeeRequestActionTypes.FETCH_ONE_EMPLOYEE_REQUEST_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case EmployeeRequestActionTypes.FETCH_ONE_EMPLOYEE_REQUEST_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    // ----------------

    case EmployeeRequestActionTypes.FETCH_CURRENT_EMPLOYEE_LEAVE_REQUEST_STATE:
      return {
        ...state,
        fetchLeaveState: {
          error: null,
          payload: null,
          isPending: true,
          isSuccessful: false,
        },
      };
    case EmployeeRequestActionTypes.FETCH_CURRENT_EMPLOYEE_LEAVE_REQUEST_STATE_RESET:
      return {
        ...state,
        fetchLeaveState: resetApiCallState(null),
      };
    case EmployeeRequestActionTypes.FETCH_CURRENT_EMPLOYEE_LEAVE_REQUEST_STATE_FAILURE:
      return {
        ...state,
        fetchLeaveState: {
          payload: null,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case EmployeeRequestActionTypes.FETCH_CURRENT_EMPLOYEE_LEAVE_REQUEST_STATE_SUCCESS:
      return {
        ...state,
        fetchLeaveState: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    //----------------

    case EmployeeRequestActionTypes.FETCH_YEARLY_EMPLOYEE_HEALTH_BENEFIT_REQUEST_STATE:
      return {
        ...state,
        fetchHealthBenefitState: {
          error: null,
          payload: null,
          isPending: true,
          isSuccessful: false,
        },
      };
    case EmployeeRequestActionTypes.FETCH_YEARLY_EMPLOYEE_HEALTH_BENEFIT_REQUEST_STATE_RESET:
      return {
        ...state,
        fetchHealthBenefitState: resetApiCallState(null),
      };
    case EmployeeRequestActionTypes.FETCH_YEARLY_EMPLOYEE_HEALTH_BENEFIT_REQUEST_STATE_FAILURE:
      return {
        ...state,
        fetchHealthBenefitState: {
          payload: null,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case EmployeeRequestActionTypes.FETCH_YEARLY_EMPLOYEE_HEALTH_BENEFIT_REQUEST_STATE_SUCCESS:
      return {
        ...state,
        fetchHealthBenefitState: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default EmployeeRequestReducer;
