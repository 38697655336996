import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { StaffWorkExperienceActionTypes } from "./StaffWorkExperience.type";

export function* fetchAllStaffWorkExperiences(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/staff-work-experience?staff_id=${action.payload?.staff_id}`);
    yield put({
      type: StaffWorkExperienceActionTypes.FETCH_ALL_STAFF_WORK_EXPERIENCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffWorkExperienceActionTypes.FETCH_ALL_STAFF_WORK_EXPERIENCE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneStaffWorkExperiences(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/staff-work-experience/${action.payload}`
    );
    yield put({
      type: StaffWorkExperienceActionTypes.FETCH_ONE_STAFF_WORK_EXPERIENCE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: StaffWorkExperienceActionTypes.FETCH_ONE_STAFF_WORK_EXPERIENCE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllStaffWorkExperiences() {
  yield takeLatest(StaffWorkExperienceActionTypes.FETCH_ALL_STAFF_WORK_EXPERIENCE, fetchAllStaffWorkExperiences);
}

export function* watcherFetchOneStaffWorkExperiences() {
  yield takeLatest(StaffWorkExperienceActionTypes.FETCH_ONE_STAFF_WORK_EXPERIENCE, fetchOneStaffWorkExperiences);
}
