import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MikadaMonthlyReportActionTypes } from "./MikadaMonthlyReport.type";

export function* fetchAllMikadaMonthlyReports(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/mikada-monthly-report?date=${action.payload?.date}`);
    yield put({
      type: MikadaMonthlyReportActionTypes.FETCH_ALL_MIKADA_MONTHLY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MikadaMonthlyReportActionTypes.FETCH_ALL_MIKADA_MONTHLY_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMikadaMonthlyReports(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/mikada-monthly-report/${action.payload}`
    );
    yield put({
      type: MikadaMonthlyReportActionTypes.FETCH_ONE_MIKADA_MONTHLY_REPORT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MikadaMonthlyReportActionTypes.FETCH_ONE_MIKADA_MONTHLY_REPORT_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMikadaMonthlyReports() {
  yield takeLatest(MikadaMonthlyReportActionTypes.FETCH_ALL_MIKADA_MONTHLY_REPORT, fetchAllMikadaMonthlyReports);
}

export function* watcherFetchOneMikadaMonthlyReports() {
  yield takeLatest(MikadaMonthlyReportActionTypes.FETCH_ONE_MIKADA_MONTHLY_REPORT, fetchOneMikadaMonthlyReports);
}
