import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MikadaWeeklyProgressActionTypes } from "./MikadaWeeklyProgress.type";

export function* fetchAllMikadaWeeklyProgresss(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/mikada-weekly-progress?date=${action.payload?.date}`);
    yield put({
      type: MikadaWeeklyProgressActionTypes.FETCH_ALL_MIKADA_WEEKLY_PROGRESS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MikadaWeeklyProgressActionTypes.FETCH_ALL_MIKADA_WEEKLY_PROGRESS_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMikadaWeeklyProgresss(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/mikada-weekly-progress/${action.payload}`
    );
    yield put({
      type: MikadaWeeklyProgressActionTypes.FETCH_ONE_MIKADA_WEEKLY_PROGRESS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MikadaWeeklyProgressActionTypes.FETCH_ONE_MIKADA_WEEKLY_PROGRESS_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMikadaWeeklyProgresss() {
  yield takeLatest(MikadaWeeklyProgressActionTypes.FETCH_ALL_MIKADA_WEEKLY_PROGRESS, fetchAllMikadaWeeklyProgresss);
}

export function* watcherFetchOneMikadaWeeklyProgresss() {
  yield takeLatest(MikadaWeeklyProgressActionTypes.FETCH_ONE_MIKADA_WEEKLY_PROGRESS, fetchOneMikadaWeeklyProgresss);
}
