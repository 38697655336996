import { Document } from "../Document/Document.type";
import { PlaceOfWork } from "../PlaceOfWork/PlaceOfWork.type";
import { ApiCallState } from "../Utils";

export type TemporaryAttendance = {
  id?: number;
  date:string;
  period_start:string;
  period_end:string;
  place_of_work_id:number;
  user_id:number;
  start_date: string;
  data: Record<string, TemporaryAttendanceStructure | undefined>
  document?:Document;
  place_of_work?:PlaceOfWork;
};

export type TemporaryAttendanceStructure = {
  holidays: number[];
  start: number;
  end: number;
  days: Map<number, string>;
  groups: TemporaryAttendanceGroup[];
}

export type TemporaryAttendanceDataDetail = {
  value: string;
  hour: number;
  is_holiday: boolean;
  is_sunday: boolean;
  is_absent: boolean;
  on_leave: boolean;
};

export type TemporaryAttendanceData = {
  id?: number;
  id_no: string;
  full_name: string;
  position: string;
  daily_wage: number;

  working_days: number;
  normal_hours: number;

  normal_overtime: number;
  sunday_overtime: number;
  holiday_overtime: number;

  sign?: string;

  normal: Map<number, TemporaryAttendanceDataDetail>;
  overtime: Map<number, TemporaryAttendanceDataDetail>;

  key: number;
};

export type TemporaryAttendanceGroup = {
  name: string;
  data: TemporaryAttendanceData[];
};

export type TemporaryAttendanceStateTypes = {
  fetchAll: ApiCallState<TemporaryAttendance[]>;
  fetchOne: ApiCallState<TemporaryAttendance | {}>;
};

export const TemporaryAttendanceActionTypes = {
  FETCH_ALL_TEMPORARY_ATTENDANCE: "FETCH_ALL_TEMPORARY_ATTENDANCE",
  FETCH_ALL_TEMPORARY_ATTENDANCE_RESET: "FETCH_ALL_TEMPORARY_ATTENDANCE_RESET",
  FETCH_ALL_TEMPORARY_ATTENDANCE_FAILURE: "FETCH_ALL_TEMPORARY_ATTENDANCE_FAILURE",
  FETCH_ALL_TEMPORARY_ATTENDANCE_SUCCESS: "FETCH_ALL_TEMPORARY_ATTENDANCE_SUCCESS",

  FETCH_ONE_TEMPORARY_ATTENDANCE: "FETCH_ONE_TEMPORARY_ATTENDANCE",
  FETCH_ONE_TEMPORARY_ATTENDANCE_RESET: "FETCH_ONE_TEMPORARY_ATTENDANCE_RESET",
  FETCH_ONE_TEMPORARY_ATTENDANCE_FAILURE: "FETCH_ONE_TEMPORARY_ATTENDANCE_FAILURE",
  FETCH_ONE_TEMPORARY_ATTENDANCE_SUCCESS: "FETCH_ONE_TEMPORARY_ATTENDANCE_SUCCESS",
};
